/* eslint-disable max-len */
const programName = 'Hambedo'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 766 205">
      <title>{programName}</title>
      <defs>
        <polygon id="path-1" points="0.18 61.593 0.18 0.861 765.354 0.861 765.354 61.593" />
      </defs>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-106.000000, -111.000000)">
          <g id="hambedo" transform="translate(106.000000, 109.000000)">
            <g id="Group-4" transform="translate(0.000000, 138.000000)">
              <mask id="mask-2" fill="white">
                <use href="#path-1" />
              </mask>
              <g id="Clip-2" />
              <polyline id="Fill-1" fill="#1A1918" mask="url(#mask-2)" points="765.354 0.861 748.008 0.861 748.008 42.198 655.626 42.198 655.626 61.593 765.354 61.593 765.354 0.861" />
              <polyline id="Fill-3" fill="#1A1918" mask="url(#mask-2)" points="17.349 42.198 17.349 1.647 0 1.647 0 61.593 109.731 61.593 109.731 42.198 17.349 42.198" />
            </g>
            <path d="M101.724,130.35 L101.724,127.476 C110.889,125.499 111.069,124.245 111.069,113.82 L111.069,80.046 C111.069,67.473 105.498,62.625 98.133,62.625 C93.642,62.625 89.151,64.242 85.557,67.293 L85.557,113.82 C85.557,124.605 85.917,125.679 95.259,127.476 L95.259,130.35 L54.84,130.35 L54.84,127.476 C64.362,125.679 65.259,124.779 65.259,112.566 L65.259,27.951 C65.259,14.301 64.719,13.401 55.377,11.064 L55.377,8.553 C65.259,7.833 78.552,5.139 86.277,2.442 C86.097,3.879 85.557,13.581 85.557,25.977 L85.557,63.702 C91.665,57.591 99.57,52.206 109.092,52.206 C122.925,52.206 131.547,60.468 131.547,80.046 L131.547,112.566 C131.547,124.605 131.904,125.679 140.169,127.476 L140.169,130.35 L101.724,130.35" id="Fill-5" fill="#1A1918" />
            <path d="M189.567,89.211 C186.873,90.645 183.999,91.545 180.225,93.519 C173.577,97.116 170.526,100.35 170.526,106.638 C170.526,116.517 175.914,120.651 181.125,120.651 C183.636,120.651 186.333,120.291 189.387,117.774 C189.387,107.355 189.567,99.63 189.567,89.211 Z M204.117,132.507 C199.629,132.507 196.392,131.25 194.058,128.913 C192.081,126.936 190.644,124.065 189.927,121.008 C184.176,125.499 175.017,132.507 171.78,132.507 C159.027,132.507 150.405,121.728 150.405,111.129 C150.405,102.681 154.356,98.373 162.441,95.499 C172.14,91.902 185.256,88.674 189.567,85.437 L189.567,74.838 C189.567,63.702 182.742,58.491 177.531,58.491 C174.297,58.491 171.96,59.928 170.166,62.442 C168.909,64.242 167.649,67.473 166.032,74.301 C165.138,77.895 163.878,79.149 161.364,79.149 C155.613,79.149 152.022,74.481 152.022,70.887 C152.022,68.91 153.639,66.756 157.23,64.422 C166.212,58.671 177.531,53.463 184.356,52.206 C192.081,52.206 196.392,54.54 200.343,57.234 C207.711,62.982 209.508,70.167 209.508,80.586 L209.508,111.489 C209.508,118.854 212.199,121.371 215.436,121.371 C216.693,121.371 218.31,121.188 219.567,120.651 L220.464,123.525 L204.117,132.507 L204.117,132.507 Z" id="Fill-6" fill="#1A1918" />
            <path d="M316.392,130.35 L316.392,127.476 C325.014,125.859 325.374,124.779 325.374,112.566 L325.374,81.663 C325.374,67.293 320.166,63.882 313.338,63.882 C309.927,63.882 305.433,64.779 300.222,69.09 C300.582,71.604 300.942,74.301 300.942,77.535 L300.942,114.18 C300.942,124.779 302.559,126.036 309.567,127.476 L309.567,130.35 L272.022,130.35 L272.022,127.476 C280.644,126.036 280.824,124.959 280.824,114.18 L280.824,80.226 C280.824,67.293 275.793,63.882 268.788,63.882 C264.297,63.882 260.883,65.499 256.392,68.91 L256.392,114.18 C256.392,124.779 256.932,125.859 264.657,127.476 L264.657,130.35 L226.032,130.35 L226.032,127.476 C235.554,125.679 236.274,124.959 236.274,112.566 L236.274,75.558 C236.274,65.139 235.194,64.062 228.009,61.365 L228.009,59.028 C237.348,58.131 248.847,54.72 256.752,51.846 C256.572,54.54 256.392,60.468 256.392,64.779 C259.803,62.625 262.32,60.468 265.731,58.131 C272.022,54 276.153,52.206 281.004,52.206 C289.806,52.206 296.091,57.411 299.148,65.679 C302.919,62.805 306.513,59.928 310.824,57.054 C315.135,54.183 318.906,52.206 324.297,52.206 C338.31,52.206 345.495,62.805 345.495,80.586 L345.495,114 C345.495,125.139 346.032,125.859 354.117,127.476 L354.117,130.35 L316.392,130.35" id="Fill-7" fill="#1A1918" />
            <path d="M390.945,114.36 C390.945,116.517 391.302,118.674 392.022,120.111 C393.459,122.988 398.49,127.476 405.498,127.656 C415.014,127.833 421.302,115.257 421.302,93.342 C421.302,70.167 409.986,60.648 398.307,60.648 C395.79,60.648 393.639,61.005 390.945,61.725 L390.945,114.36 Z M398.307,55.44 C402.981,53.643 407.469,52.206 408.906,52.206 C431.004,52.206 443.937,68.91 443.937,88.494 C443.937,112.029 423.639,132.507 399.567,132.507 C390.585,132.507 381.6,131.067 370.104,126.756 C370.464,122.448 370.464,117.954 370.464,113.64 L370.464,26.694 C370.464,14.658 369.744,13.761 360.585,11.244 L360.585,8.553 C369.204,8.013 381.423,5.859 391.302,2.442 C391.125,4.419 390.945,20.769 390.945,27.414 L390.945,58.311 L398.307,55.44 L398.307,55.44 Z" id="Fill-8" fill="#1A1918" />
            <path d="M489.741,56.874 C483.096,56.874 477.885,64.062 476.988,81.123 C484.173,81.123 491.178,81.123 498.363,80.586 C501.057,80.406 502.674,80.226 502.674,78.075 C502.674,67.293 497.466,56.874 489.741,56.874 Z M523.152,112.566 C512.376,127.833 498.723,132.507 492.258,132.507 C469.8,132.507 456.51,114.72 456.51,95.139 C456.51,82.203 461.721,71.247 469.263,63.522 C477.345,55.08 486.327,52.206 494.772,52.206 C511.836,52.206 522.795,64.602 522.975,78.789 C522.975,81.663 522.795,82.743 522.075,82.923 C516.33,84 493.515,85.617 476.628,86.337 C476.805,108.435 489.384,118.674 501.777,118.674 C508.248,118.674 514.713,117.057 521.535,110.412 L523.152,112.566 L523.152,112.566 Z" id="Fill-9" fill="#1A1918" />
            <path d="M589.98,66.219 C586.923,59.748 579.921,56.874 574.71,56.874 C568.422,56.874 559.08,62.805 559.26,87.594 C559.437,110.949 570.579,122.088 580.638,122.088 C583.332,122.088 586.746,121.188 589.98,119.034 L589.98,66.219 Z M621.057,126.216 C617.64,126.756 602.556,129.093 589.62,133.584 C589.8,131.787 589.98,126.036 589.98,122.808 C586.566,124.959 581.895,127.833 577.944,129.63 C573.093,131.787 570.936,132.507 568.422,132.507 C554.049,132.507 535.908,120.111 535.908,94.239 C535.908,71.247 555.489,52.206 582.072,52.206 C584.049,52.206 587.823,52.383 589.98,52.746 L589.98,25.617 C589.98,14.478 589.08,13.401 577.764,10.704 L577.764,8.193 C589.62,7.296 604.353,4.599 610.998,2.262 C610.638,7.296 610.458,16.095 610.458,24.54 L610.458,108.435 C610.458,119.751 611.715,121.008 617.463,122.448 L621.057,123.345 L621.057,126.216 L621.057,126.216 Z" id="Fill-10" fill="#1A1918" />
            <path d="M666.867,56.334 C657.705,56.334 652.671,66.396 652.671,87.594 C652.671,111.309 659.502,128.733 670.458,128.733 C677.463,128.733 684.651,123.885 684.651,95.679 C684.651,70.707 677.463,56.334 666.867,56.334 Z M669.381,52.206 C692.736,52.206 708.006,70.707 708.006,91.185 C708.006,118.674 688.422,132.507 669.918,132.507 C640.821,132.507 629.142,111.309 629.142,92.982 C629.142,67.83 649.443,52.206 669.381,52.206 L669.381,52.206 Z" id="Fill-11" fill="#1A1918" />
            <polyline id="Fill-12" fill="#1A1918" points="207.756 172.587 210.888 172.587 220.143 200.784 229.785 172.68 233.784 172.68 221.733 206.661 218.505 206.661 209.25 179.283 199.947 206.661 196.767 206.661 184.713 172.68 188.859 172.68 198.501 200.784 207.756 172.587" />
            <path d="M254.604,175.62 C246.894,175.62 241.398,181.791 241.398,189.504 C241.398,197.214 246.99,203.484 254.7,203.484 C262.413,203.484 267.909,197.313 267.909,189.597 C267.909,181.887 262.32,175.62 254.604,175.62 Z M254.604,207 C244.338,207 237.447,198.951 237.447,189.597 C237.447,180.249 244.434,172.104 254.7,172.104 C264.969,172.104 271.863,180.15 271.863,189.504 C271.863,198.855 264.873,207 254.604,207 L254.604,207 Z" id="Fill-13" fill="#1A1918" />
            <path d="M294.126,176.196 L283.713,176.196 L283.713,189.888 L294.075,189.888 C299.139,189.888 302.754,187.287 302.754,182.898 C302.754,178.704 299.571,176.196 294.126,176.196 Z M303.141,206.424 L293.355,193.311 L283.713,193.311 L283.713,206.424 L279.906,206.424 L279.906,172.68 L294.414,172.68 C301.836,172.68 306.609,176.682 306.609,182.757 C306.609,188.397 302.754,191.673 297.45,192.636 L307.815,206.424 L303.141,206.424 L303.141,206.424 Z" id="Fill-14" fill="#1A1918" />
            <polyline id="Fill-15" fill="#1A1918" points="314.754 172.68 318.561 172.68 318.561 202.905 337.551 202.905 337.551 206.424 314.754 206.424 314.754 172.68" />
            <path d="M356.298,176.196 L348.393,176.196 L348.393,202.905 L356.298,202.905 C364.83,202.905 370.278,197.121 370.278,189.597 C370.278,182.079 364.83,176.196 356.298,176.196 Z M356.298,206.424 L344.586,206.424 L344.586,172.68 L356.298,172.68 C366.903,172.68 374.229,179.958 374.229,189.504 C374.229,199.047 366.903,206.424 356.298,206.424 L356.298,206.424 Z" id="Fill-16" fill="#1A1918" />
            <path d="M412.398,175.62 C404.688,175.62 399.195,181.791 399.195,189.504 C399.195,197.214 404.781,203.484 412.497,203.484 C420.207,203.484 425.706,197.313 425.706,189.597 C425.706,181.887 420.114,175.62 412.398,175.62 Z M412.398,207 C402.135,207 395.241,198.951 395.241,189.597 C395.241,180.249 402.228,172.104 412.497,172.104 C422.763,172.104 429.654,180.15 429.654,189.504 C429.654,198.855 422.667,207 412.398,207 L412.398,207 Z" id="Fill-17" fill="#1A1918" />
            <polyline id="Fill-18" fill="#1A1918" points="461.946 176.196 441.507 176.196 441.507 188.25 459.777 188.25 459.777 191.721 441.507 191.721 441.507 206.424 437.697 206.424 437.697 172.68 461.946 172.68 461.946 176.196" />
            <path d="M497.991,176.874 L490.278,194.082 L505.656,194.082 L497.991,176.874 Z M515.199,206.424 L511.107,206.424 L507.15,197.505 L488.787,197.505 L484.785,206.424 L480.882,206.424 L496.26,172.44 L499.821,172.44 L515.199,206.424 L515.199,206.424 Z" id="Fill-19" fill="#1A1918" />
            <path d="M536.067,176.196 L525.657,176.196 L525.657,189.888 L536.019,189.888 C541.077,189.888 544.692,187.287 544.692,182.898 C544.692,178.704 541.515,176.196 536.067,176.196 Z M545.079,206.424 L535.296,193.311 L525.657,193.311 L525.657,206.424 L521.85,206.424 L521.85,172.68 L536.355,172.68 C543.777,172.68 548.547,176.682 548.547,182.757 C548.547,188.397 544.692,191.673 539.391,192.636 L549.756,206.424 L545.079,206.424 L545.079,206.424 Z" id="Fill-20" fill="#1A1918" />
            <polyline id="Fill-21" fill="#1A1918" points="569.127 206.424 565.263 206.424 565.263 176.196 553.938 176.196 553.938 172.68 580.452 172.68 580.452 176.196 569.127 176.196 569.127 206.424" />
          </g>
        </g>
      </g>
    </svg>
  ),
}
