/* eslint-disable max-len */
const programName = 'Confident Living'
export default {
  programName,
  logo: (
    <svg className="confidentliving" viewBox="0 0 693.43 298.21">
      <title>{programName}</title>
      <path className="cls-1" d="M471,302.14c-24.2,0-36-19.77-36.86-39.37l-0.12-2.1,2.1-.32L458,257.2l2.49-.35,0.2,2.49c0.78,11.5,4.93,18.09,11.38,18.09,7.06,0,11.08-3.61,11.08-9.94,0-11-9-18.43-19.37-27.09-12.86-10.74-27.48-22.9-27.48-45,0-22.73,13.88-35.75,38-35.75,18.83,0,32,13.23,34.28,34.57l0.25,2.29-2.31.27-21.92,2.41-2.39.27-0.16-2.37c-0.92-11.08-6.64-12.75-10-12.75-7.58,0-9.18,5.91-9.18,10.88,0,9,8.58,16.33,18.52,24.78,13.26,11.27,28.3,24.06,28.3,45.45,0,22.63-14.86,36.68-38.74,36.68h0Z" transform="translate(-5.19 -4.06)" />
      <polygon className="cls-1" points="583.45 296.24 581.12 296.24 516.83 296.24 514.52 296.24 514.52 293.92 514.52 159.78 514.52 157.42 516.83 157.42 578.54 157.42 580.84 157.42 580.84 159.78 580.84 179.82 580.84 182.15 578.54 182.15 542.19 182.15 542.19 212.06 569.58 212.06 571.94 212.06 571.94 214.39 571.94 234.45 571.94 236.77 569.58 236.77 542.19 236.77 542.19 271.51 581.12 271.51 583.45 271.51 583.45 273.85 583.45 293.92 583.45 296.24 583.45 296.24" />
      <path className="cls-2" d="M42.81,146.57c-23.22,0-37.62-14.07-37.62-36.72V40.76c0-22.66,14.4-36.7,37.62-36.7C65,4.06,79.3,19.41,79.3,43.17V55.32H51.65V42.61c0-8.65-3.34-13.83-8.84-13.83-7.44,0-9.94,3.15-9.94,12.54v68c0,9.37,2.51,12.55,9.94,12.55,5.42,0,8.84-1.88,8.84-11.24V92.13H79.3v17.72c0,22.31-14.32,36.72-36.49,36.72h0Z" transform="translate(-5.19 -4.06)" />
      <path className="cls-2" d="M125.94,146.57c-23.21,0-37.6-14.07-37.6-36.72V40.76c0-22.66,14.39-36.7,37.6-36.7s37.62,14,37.62,36.7v69.09c0,22.65-14.39,36.72-37.62,36.72h0Zm0-117.79c-7.41,0-9.92,3.15-9.92,12.54v68c0,9.37,2.51,12.55,9.92,12.55s9.94-3.18,9.94-12.55v-68c0-9.39-2.49-12.54-9.94-12.54h0Z" transform="translate(-5.19 -4.06)" />
      <polygon className="cls-2" points="244.69 140.63 242.38 140.63 222.31 140.63 220.67 140.63 220.12 139.07 194.91 67.13 194.91 138.32 194.91 140.63 192.6 140.63 171.77 140.63 169.47 140.63 169.47 138.32 169.47 4.17 169.47 1.83 171.77 1.83 193.15 1.83 194.76 1.83 195.33 3.39 219.24 68.8 219.24 4.17 219.24 1.83 221.55 1.83 242.38 1.83 244.69 1.83 244.69 4.17 244.69 138.32 244.69 140.63 244.69 140.63" />
      <polygon className="cls-2" points="285.04 140.63 282.71 140.63 259.65 140.63 257.36 140.63 257.36 138.32 257.36 4.17 257.36 1.83 259.65 1.83 323.95 1.83 326.25 1.83 326.25 4.17 326.25 24.24 326.25 26.55 323.95 26.55 285.04 26.55 285.04 56.48 313.55 56.48 315.86 56.48 315.86 58.8 315.86 78.85 315.86 81.17 313.55 81.17 285.04 81.17 285.04 138.32 285.04 140.63 285.04 140.63" />
      <polygon className="cls-2" points="360.92 140.63 358.62 140.63 335.56 140.63 333.24 140.63 333.24 138.32 333.24 4.17 333.24 1.83 335.56 1.83 358.62 1.83 360.92 1.83 360.92 4.17 360.92 138.32 360.92 140.63 360.92 140.63" />
      <path className="cls-2" d="M416.85,144.7H380.32V5.9h36.52c25.7,0,38.75,14.43,38.75,42.84v53.13c0,28.42-13.05,42.82-38.75,42.82h0ZM408,121.08h5.69c11,0,14.19-3.66,14.19-16.42V45.95c0-12.75-3.16-16.45-14.19-16.45H408v91.59h0Z" transform="translate(-5.19 -4.06)" />
      <polygon className="cls-2" points="530.4 140.63 528.08 140.63 463.79 140.63 461.46 140.63 461.46 138.32 461.46 4.17 461.46 1.83 463.79 1.83 525.47 1.83 527.77 1.83 527.77 4.17 527.77 24.24 527.77 26.55 525.47 26.55 489.14 26.55 489.14 56.48 516.55 56.48 518.86 56.48 518.86 58.8 518.86 78.85 518.86 81.17 516.55 81.17 489.14 81.17 489.14 115.92 528.08 115.92 530.4 115.92 530.4 118.24 530.4 138.32 530.4 140.63 530.4 140.63" />
      <polygon className="cls-2" points="613.18 140.63 610.85 140.63 590.79 140.63 589.14 140.63 588.58 139.07 563.38 67.13 563.38 138.32 563.38 140.63 561.06 140.63 540.24 140.63 537.94 140.63 537.94 138.32 537.94 4.17 537.94 1.83 540.24 1.83 561.6 1.83 563.25 1.83 563.79 3.39 587.73 68.8 587.73 4.17 587.73 1.83 590.03 1.83 610.85 1.83 613.18 1.83 613.18 4.17 613.18 138.32 613.18 140.63 613.18 140.63" />
      <polygon className="cls-2" points="671.14 140.63 668.83 140.63 645.79 140.63 643.47 140.63 643.47 138.32 643.47 26.55 623.5 26.55 621.18 26.55 621.18 24.24 621.18 4.17 621.18 1.83 623.5 1.83 691.12 1.83 693.43 1.83 693.43 4.17 693.43 24.24 693.43 26.55 691.12 26.55 671.14 26.55 671.14 138.32 671.14 140.63 671.14 140.63" />
      <polygon className="cls-2" points="65.93 296.24 63.61 296.24 4.36 296.24 2.03 296.24 2.03 293.92 2.03 159.78 2.03 157.42 4.36 157.42 27.38 157.42 29.71 157.42 29.71 159.78 29.71 271.51 63.61 271.51 65.93 271.51 65.93 273.85 65.93 293.92 65.93 296.24 65.93 296.24" />
      <polygon className="cls-2" points="99.68 296.24 97.36 296.24 74.32 296.24 71.99 296.24 71.99 293.92 71.99 159.78 71.99 157.42 74.32 157.42 97.36 157.42 99.68 157.42 99.68 159.78 99.68 293.92 99.68 296.24 99.68 296.24" />
      <polygon className="cls-2" points="161.85 296.24 159.97 296.24 136.93 296.24 135.02 296.24 134.66 294.37 106.61 160.24 106.04 157.42 108.9 157.42 131.57 157.42 133.49 157.42 133.83 159.37 148.47 240.74 163.08 159.37 163.43 157.42 165.36 157.42 188.01 157.42 190.9 157.42 190.29 160.24 162.25 294.37 161.85 296.24 161.85 296.24" />
      <polygon className="cls-2" points="224.92 296.24 222.58 296.24 199.57 296.24 197.23 296.24 197.23 293.92 197.23 159.78 197.23 157.42 199.57 157.42 222.58 157.42 224.92 157.42 224.92 159.78 224.92 293.92 224.92 296.24 224.92 296.24" />
      <polygon className="cls-2" points="314.37 296.24 312.07 296.24 292 296.24 290.35 296.24 289.79 294.66 264.62 222.71 264.62 293.92 264.62 296.24 262.28 296.24 241.47 296.24 239.16 296.24 239.16 293.92 239.16 159.78 239.16 157.42 241.47 157.42 262.83 157.42 264.46 157.42 265.02 158.98 288.92 224.39 288.92 159.78 288.92 157.42 291.26 157.42 312.07 157.42 314.37 157.42 314.37 159.78 314.37 293.92 314.37 296.24 314.37 296.24" />
      <path className="cls-2" d="M363.81,302.14c-19.85,0-33.15-14.76-33.15-36.68V196.36c0-22.31,14.15-36.7,36.11-36.7,22.38,0,36.88,15.35,36.88,39.1v10.87H377.06V207.3c0-21.24-4.68-23-10.84-23-2.18,0-7.89,0-7.89,12.36v68.94c0,10.41,4.28,11.78,8.44,11.78,6.7,0,10.29-2.58,10.29-16.25V250.49H362.43V227.65h42.35v72.64h-15l-0.6-1.41-2.83-6.64c-6,6.45-11.92,9.91-22.52,9.91h0Z" transform="translate(-5.19 -4.06)" />
      <path className="cls-2" d="M429.15,293.52a8.76,8.76,0,1,1-8.75-8.74A8.77,8.77,0,0,1,429.15,293.52Z" transform="translate(-5.19 -4.06)" />
    </svg>
  ),
}
