/* eslint-disable max-len */
const programName = 'Bubbleroom'
export default {
  programName,
  logo: (<svg viewBox="0 0 1324 128">
    <title>{programName}</title>
    <g>
      <path d="M6.44,122.54V6.1h42.58c10.42,0,18.57,2.69,24.45,8.07c5.88,5.38,8.82,13.17,8.82,23.37c0,4.77-1,9.06-3,12.89
                                    c-2,3.83-4.82,6.69-8.48,8.57c11.64,4.88,17.47,14.36,17.47,28.45c0,11.2-3.38,19.85-10.15,25.95c-6.76,6.1-16.19,9.15-28.28,9.15
                                    H6.44z M28.07,51.34h19.79c4.66,0,8.09-1,10.31-2.99c2.22-2,3.33-5.21,3.33-9.65c0-4.43-1.14-7.65-3.41-9.64
                                    c-2.27-2-5.85-3-10.73-3h-19.3V51.34z M28.07,102.58h19.79c12.53,0,18.8-5.21,18.8-15.64c0-10.42-6.27-15.64-18.8-15.64H28.07
                                    V102.58z"
      />
      <path d="M134.52,78.29V6.1h21.62v72.52c0,16.86,7.26,25.28,21.79,25.28c14.53,0,21.79-8.42,21.79-25.28V6.1h21.62v72.19
                                    c0,14.75-3.85,26.04-11.56,33.85c-7.71,7.82-18.33,11.73-31.85,11.73c-13.53,0-24.15-3.91-31.85-11.73
                                    C138.37,104.33,134.52,93.04,134.52,78.29z"
      />
      <path d="M279.07,122.54V6.1h42.58c10.42,0,18.57,2.69,24.45,8.07c5.88,5.38,8.82,13.17,8.82,23.37c0,4.77-1,9.06-3,12.89
                                    c-2,3.83-4.82,6.69-8.48,8.57c11.64,4.88,17.47,14.36,17.47,28.45c0,11.2-3.38,19.85-10.15,25.95c-6.76,6.1-16.19,9.15-28.28,9.15
                                    H279.07z M300.69,51.34h19.79c4.66,0,8.09-1,10.31-2.99c2.22-2,3.33-5.21,3.33-9.65c0-4.43-1.14-7.65-3.41-9.64
                                    c-2.27-2-5.85-3-10.73-3h-19.3V51.34z M300.69,102.58h19.79c12.53,0,18.8-5.21,18.8-15.64c0-10.42-6.27-15.64-18.8-15.64h-19.79
                                    V102.58z"
      />
      <path d="M417.96,122.54V6.1h42.58c10.42,0,18.57,2.69,24.45,8.07c5.88,5.38,8.82,13.17,8.82,23.37c0,4.77-1,9.06-3,12.89
                                    c-2,3.83-4.82,6.69-8.48,8.57c11.64,4.88,17.47,14.36,17.47,28.45c0,11.2-3.38,19.85-10.15,25.95c-6.76,6.1-16.19,9.15-28.28,9.15
                                    H417.96z M439.58,51.34h19.79c4.66,0,8.09-1,10.31-2.99c2.22-2,3.33-5.21,3.33-9.65c0-4.43-1.14-7.65-3.41-9.64
                                    c-2.27-2-5.85-3-10.73-3h-19.3V51.34z M439.58,102.58h19.79c12.53,0,18.8-5.21,18.8-15.64c0-10.42-6.27-15.64-18.8-15.64h-19.79
                                    V102.58z"
      />
      <path d="M550.19,122.54V6.1h21.62v96.48h49.4v19.96H550.19z" />
      <path d="M670.12,122.54V6.1h72.03v19.96h-50.4v24.45h40.42v19.96h-40.42v32.1h52.06v19.96H670.12z" />
      <path d="M798.03,122.54V6.1h42.42c11.2,0,19.9,3,26.11,8.98c6.21,5.99,9.31,14.03,9.31,24.12c0,7.76-1.88,14.39-5.65,19.88
                                    c-3.77,5.49-9.15,9.29-16.14,11.4l32.27,52.06h-24.12L830.97,72.3h-11.31v50.24H798.03z M819.66,52.34h20.96
                                    c9.09,0,13.64-4.38,13.64-13.14c0-8.76-4.55-13.14-13.64-13.14h-20.96V52.34z"
      />
      <path d="M931.02,108.24c-10.37-10.42-15.55-25.06-15.55-43.92s5.18-33.49,15.55-43.91C941.38,9.98,954.5,4.77,970.36,4.77
                                    c15.86,0,28.97,5.21,39.34,15.64c10.37,10.42,15.55,25.06,15.55,43.91s-5.19,33.49-15.55,43.92
                                    c-10.37,10.42-23.48,15.63-39.34,15.63C954.5,123.87,941.38,118.66,931.02,108.24z M994.06,93.76c6.15-6.76,9.23-16.58,9.23-29.44
                                    c0-12.87-3.08-22.68-9.23-29.44c-6.15-6.76-14.05-10.14-23.7-10.14c-9.64,0-17.55,3.38-23.7,10.14
                                    c-6.15,6.77-9.23,16.58-9.23,29.44c0,12.86,3.08,22.68,9.23,29.44c6.15,6.77,14.05,10.14,23.7,10.14
                                    C980,103.91,987.91,100.53,994.06,93.76z"
      />
      <path d="M1079.39,108.24c-10.37-10.42-15.55-25.06-15.55-43.92s5.18-33.49,15.55-43.91c10.37-10.42,23.48-15.64,39.34-15.64
                                    c15.86,0,28.97,5.21,39.34,15.64c10.37,10.42,15.55,25.06,15.55,43.91s-5.19,33.49-15.55,43.92
                                    c-10.37,10.42-23.48,15.63-39.34,15.63C1102.87,123.87,1089.76,118.66,1079.39,108.24z M1142.43,93.76
                                    c6.15-6.76,9.23-16.58,9.23-29.44c0-12.87-3.08-22.68-9.23-29.44c-6.15-6.76-14.06-10.14-23.7-10.14c-9.64,0-17.55,3.38-23.7,10.14
                                    c-6.15,6.77-9.23,16.58-9.23,29.44c0,12.86,3.08,22.68,9.23,29.44c6.15,6.77,14.05,10.14,23.7,10.14
                                    C1128.37,103.91,1136.28,100.53,1142.43,93.76z"
      />
      <path d="M1214.54,122.54V6.1h24.29l27.44,72.69l27.45-72.69H1318v116.44h-21.62V50.68l-22.29,61.21h-15.64l-22.29-61.21v71.86
                                    H1214.54z"
      />
    </g>
  </svg>),
}
