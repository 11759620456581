/* eslint-disable max-len */
const programName = 'PhoneLife'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 140 23" fill="none">
      <title>{programName}</title>
      <g id="test">
        <path d="M31.3827 6.12109H26.5298V16.8788H28.5675V14.316H31.3827C34.2277 14.316 36.2655 12.6748 36.2655 10.212C36.2655 7.76224 34.2277 6.12109 31.3827 6.12109ZM31.3827 12.5748H28.5675V7.86223H31.3827C33.0156 7.86223 34.2438 8.79693 34.2438 10.212C34.2438 11.6271 33.0156 12.5748 31.3827 12.5748Z" fill="black" />
        <path d="M47.7791 10.6076H42.089V6.12109H40.0513V16.8788H42.089V12.3488H47.7791V16.8788H49.8168V6.12109H47.7791V10.6076Z" fill="black" />
        <path d="M59.4994 5.90808C56.2036 5.90808 53.6277 8.39915 53.6277 11.5271C53.6277 14.642 56.2036 17.0896 59.4994 17.0896C62.7953 17.0896 65.3689 14.6399 65.3689 11.5271C65.3712 8.39915 62.7953 5.90808 59.4994 5.90808ZM59.4994 15.2942C57.3582 15.2942 55.6792 13.6943 55.6792 11.5293C55.6792 9.36428 57.3559 7.72095 59.4994 7.72095C61.6407 7.72095 63.3173 9.3621 63.3173 11.5293C63.3196 13.6943 61.643 15.2942 59.4994 15.2942Z" fill="black" />
        <path d="M77.8024 11.4162L70.599 6.12109H69.1914V16.8788H71.2292V9.07951L77.8024 13.7226V16.8788H79.8401V6.12109H77.8024V11.4162Z" fill="black" />
        <path d="M86.149 12.3488H92.4692V10.6076H86.149V7.87528H92.575V6.12109H84.1135V16.8788H92.6348V15.1376H86.149V12.3488Z" fill="black" />
        <path d="M98.9435 6.12109H96.9058V16.8788H104.726V15.1376H98.9435V6.12109Z" fill="black" />
        <path d="M108.465 7.86223H111.011V15.1376H108.465V16.8788H115.595V15.1376H113.049V7.86223H115.595V6.12109H108.465V7.86223Z" fill="black" />
        <path d="M119.342 16.8788H121.377V12.3488H127.415V10.6076H121.377V7.86223H127.727V6.12109H119.342V16.8788Z" fill="black" />
        <path d="M133.516 15.1376V12.3488H139.837V10.6076H133.516V7.87528H139.94V6.12109H131.479V16.8788H140V15.1376H133.516Z" fill="black" />
      </g>
      <path d="M20.0003 12.5749H18.2386V2.98233C18.2386 1.339 16.8218 0 15.0807 0H5.26458C3.52352 0 2.10905 1.339 2.10905 2.98233V12.5749H0.344992H0V12.901V14.0117V14.3378H0.344992H5.59577C6.02586 14.3378 6.39845 14.0617 6.50195 13.6683L8.11651 7.73623L11.3042 18.8069C11.41 19.1808 11.7573 19.4438 12.1667 19.4612C12.1782 19.4612 12.192 19.4612 12.2081 19.4612C12.5991 19.4612 12.951 19.2264 13.0844 18.8787L14.8231 14.3356H20.0026H20.3476V14.0095V12.8988V12.5727H20.0003V12.5749ZM3.97431 12.5749V2.98233C3.97431 2.31065 4.55389 1.76288 5.26458 1.76288H15.0807C15.7914 1.76288 16.371 2.31065 16.371 2.98233V12.5749H14.1677C13.7767 12.5749 13.4248 12.8097 13.2891 13.1575L12.3392 15.6398L8.98589 3.75617C8.87779 3.37142 8.5075 3.10188 8.08661 3.10188H8.08431H8.07281C7.64962 3.10623 7.27933 3.38012 7.17813 3.77138L4.86668 12.5749H3.97431Z" fill="black" />
      <path d="M16.7161 15.4724H16.3711V15.7985V20.0176C16.3711 20.6893 15.7915 21.2371 15.0808 21.2371H5.26466C4.55397 21.2371 3.97439 20.6893 3.97439 20.0176V15.7985V15.4724H3.6294H2.45412H2.10913V15.7985V20.0176C2.10913 21.6631 3.5259 23 5.26466 23H15.0808C16.8219 23 18.2364 21.661 18.2364 20.0176V15.7985V15.4724H17.8914H16.7161Z" fill="black" />
    </svg>
  ),
}
