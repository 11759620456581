/* eslint-disable max-len */
const programName = 'Outnorth'
export default {
  programName,
  logo: (
    <svg className="outnorth" viewBox="0 0 980 243">
      <title>{programName}</title>
      <g>
        <g>
          <path d="M955,243c-310,0-620,0-930,0c-6.81-2.2-13.54-4.9-17.48-11.16C4.43,226.92,2.47,221.31,0,216C0,153,0,90,0,27
C3.3,13.34,11.04,3.79,25,0c310,0,620,0,930,0c6.81,2.2,13.54,4.9,17.48,11.16c3.09,4.91,5.06,10.53,7.52,15.84c0,63,0,126,0,189
C976.64,229.61,969,239.25,955,243z M242.66,112.18c-1.13-25.53-20.75-61.76-60.49-74.16c-38.14-11.9-78.75,3.01-100.39,36.54
c-20.58,31.88-15.88,71.99-0.02,93.39c4.01-2.66,8.35-4.95,11.97-8.08c7.46-6.46,15.27-8.66,24.44-4c2.61,1.33,4.84,0.55,7.17-1.4
c5.48-4.59,11.12-9.04,16.99-13.13c13.48-9.37,28.75-16.24,39.82-28.99c0.78-0.9,3.37-0.95,4.79-0.44
c9.08,3.23,18.12,6.62,27.07,10.21c3.12,1.25,5.51,1.45,7.99-1.23c3.74-4.04,8.58-6.12,14.01-6.97
C238.29,113.57,240.48,112.76,242.66,112.18z M827.07,58.11c0,38.94,0,77.37,0,116.14c8.26,0,16.33,0,24.9,0c0-2.08,0-3.87,0-5.66
c0-13.99-0.06-27.99,0.03-41.98c0.05-7.59,4.93-13.18,11.72-13.78c7.58-0.67,12.14,2.95,13.72,11.28
c0.55,2.91,0.52,5.96,0.53,8.95c0.05,13.63,0.02,27.26,0.02,41.2c8.49,0,16.56,0,24.67,0c0-18.41,0.05-36.53-0.06-54.65
c-0.01-2.28-0.79-4.59-1.43-6.82c-6.02-21.01-29.93-27.53-45.96-12.58c-0.87,0.81-1.82,1.52-3.45,2.87c0-15.58,0-30.17,0-44.98
C843.43,58.11,835.5,58.11,827.07,58.11z M282.83,134.46c-0.02,25.24,16.44,41.86,41.37,41.78c25.61-0.08,42.56-17.01,42.63-42.6
c0.07-24.64-16.71-41.56-41.26-41.58C299.88,92.04,282.85,108.93,282.83,134.46z M653.37,176.24
c25.78-0.1,42.73-17.28,42.56-43.15c-0.15-24.37-16.97-41.07-41.3-41.03c-25.91,0.05-42.81,17.02-42.74,42.94
C611.96,159.74,628.65,176.34,653.37,176.24z M601.97,174.25c0-15.79,0.07-31.24-0.05-46.7c-0.03-3.81-0.34-7.67-1.03-11.41
c-3.76-20.57-24.87-30.44-42.84-19.93c-3.6,2.11-6.6,5.23-10.04,8.02c-0.48-3.35-0.94-6.59-1.42-9.94c-7.23,0-14.14,0-20.85,0
c0,26.78,0,53.29,0,79.81c8.74,0,16.77,0,25.08,0c0-2.63,0-4.79,0-6.94c0.03-13.82-0.1-27.64,0.17-41.46
c0.14-7.05,5.61-12.46,12.19-12.9c7.21-0.48,11.5,2.95,12.95,10.87c0.62,3.41,0.69,6.94,0.71,10.42
c0.08,13.29,0.03,26.58,0.03,40.14C585.41,174.25,593.48,174.25,601.97,174.25z M427.23,94.19c0,2.23,0,4.03,0,5.82
c0,13.49,0.03,26.98-0.01,40.47c-0.03,8.99-4.75,14.91-12.21,15.45c-7.55,0.56-12.63-4.08-13.08-13.65
c-0.66-13.96-0.43-27.95-0.57-41.93c-0.02-1.94,0-3.88,0-5.91c-8.69,0-16.72,0-24.46,0c0,18.69-0.54,37,0.2,55.25
c0.47,11.66,5.7,21.55,17.86,25.12c12.4,3.64,23.74,1.35,32.75-8.74c0.58-0.64,1.31-1.15,2.35-2.04c0.49,3.69,0.9,6.8,1.36,10.23
c7.16,0,14.23,0,20.85,0c0-26.86,0-53.38,0-80.05C443.72,94.19,435.93,94.19,427.23,94.19z M472.23,112.96
c0,3.15-0.04,5.79,0.01,8.43c0.22,10.99-0.04,22.01,0.82,32.95c1,12.72,8.75,20.49,20.96,21.47c5.57,0.45,11.28-0.37,16.88-0.98
c1.19-0.13,3.08-1.8,3.14-2.85c0.31-5.56,0.15-11.14,0.15-16.8c-2.84,0.23-4.82,0.53-6.81,0.53c-6.05,0-9.62-2.34-9.9-8.24
c-0.54-11.41-0.16-22.87-0.16-34.87c5.83,0,11.42,0,17.17,0c0-6.44,0-12.31,0-18.68c-5.96,0-11.55,0-17.57,0
c0-7.26,0-14.02,0-21.5c-7.93,2.19-15.3,4.11-22.56,6.38c-1.01,0.32-1.95,2.41-2.04,3.74c-0.28,3.73-0.1,7.49-0.1,11.69
c-3.93,0-7.02,0-10.25,0c0,6.25,0,12.02,0,18.16C465.3,112.57,468.4,112.75,472.23,112.96z M763.19,94.1c0,6.51,0,12.27,0,18.27
c3.5,0.22,6.73,0.43,10.36,0.66c0,2.43-0.02,4.41,0,6.38c0.13,11.31-0.11,22.65,0.54,33.93c0.56,9.76,4.75,17.58,14.78,21.03
c8.02,2.76,16.09,1.92,24.13,0.3c0.95-0.19,2.28-1.69,2.32-2.62c0.22-5.57,0.11-11.16,0.11-16.89c-2.6,0.24-4.25,0.47-5.9,0.54
c-6.82,0.28-10.53-2.07-10.89-8.78c-0.6-11.22-0.16-22.5-0.16-34.46c5.92,0,11.62,0,17.24,0c0-6.46,0-12.22,0-18.61
c-6,0-11.71,0-17.86,0c0-7.36,0-14.09,0-21.36c-7.77,2.14-14.99,3.99-22.1,6.22c-1.02,0.32-2,2.4-2.1,3.74
c-0.28,3.74-0.1,7.51-0.1,11.66C769.68,94.1,766.57,94.1,763.19,94.1z M730.94,174.25c0-2.11,0-3.91,0-5.71
c0.02-12.32-0.11-24.64,0.12-36.95c0.17-9.22,6.11-15.17,15.31-15.79c2.76-0.19,5.54-0.03,8.76-0.03c0-4.07,0-7.86,0-11.65
c0-3.76,0-7.53,0-11.28c-6.34-1.66-11.85-0.76-16.32,2.61c-4.19,3.16-7.5,7.48-11.33,11.41c-0.29-3.93-0.61-8.15-0.93-12.51
c-7.33,0-14.21,0-20.84,0c0,26.76,0,53.17,0,79.88C714.33,174.25,722.4,174.25,730.94,174.25z M916.04,59.38
c-5.49-0.03-9.99,4.3-9.99,9.61c-0.01,5.27,4.59,9.69,9.99,9.59c5.29-0.1,9.6-4.22,9.76-9.35
C925.97,63.98,921.43,59.4,916.04,59.38z"
          />
          <path className="st0" d="M25,0C11.04,3.79,3.3,13.34,0,27C0,18,0,9,0,0C8.33,0,16.67,0,25,0z" />
          <path
            className="st0"
            d="M980,27c-2.47-5.31-4.43-10.92-7.52-15.84C968.54,4.9,961.81,2.2,955,0c8.25,0,16.51,0,25,0
C980,9,980,18,980,27z"
          />
          <path
            className="st0"
            d="M0,216c2.47,5.31,4.43,10.92,7.52,15.84C11.46,238.1,18.19,240.8,25,243c-8.33,0-16.67,0-25,0
C0,234,0,225,0,216z"
          />
          <path className="st0" d="M955,243c14-3.75,21.64-13.39,25-27c0,9,0,18,0,27C971.67,243,963.33,243,955,243z" />
          <path
            className="st0"
            d="M242.66,112.18c-2.17,0.58-4.37,1.39-6.64,1.75c-5.43,0.86-10.27,2.93-14.01,6.97
c-2.49,2.68-4.88,2.48-7.99,1.23c-8.95-3.59-17.98-6.97-27.07-10.21c-1.41-0.5-4.01-0.45-4.79,0.44
c-11.07,12.74-26.35,19.61-39.82,28.99c-5.87,4.08-11.5,8.54-16.99,13.13c-2.34,1.95-4.57,2.73-7.17,1.4
c-9.17-4.67-16.99-2.46-24.44,4c-3.61,3.13-7.96,5.42-11.97,8.08c-15.86-21.4-20.56-61.5,0.02-93.39
c21.64-33.53,62.25-48.44,100.39-36.54C221.9,50.42,241.53,86.65,242.66,112.18z"
          />
          <path
            className="st0"
            d="M827.07,58.11c8.42,0,16.35,0,24.68,0c0,14.81,0,29.4,0,44.98c1.63-1.35,2.59-2.07,3.45-2.87
c16.03-14.95,39.94-8.42,45.96,12.58c0.64,2.23,1.41,4.54,1.43,6.82c0.12,18.12,0.06,36.24,0.06,54.65c-8.11,0-16.18,0-24.67,0
c0-13.94,0.03-27.57-0.02-41.2c-0.01-2.99,0.02-6.03-0.53-8.95c-1.58-8.33-6.14-11.95-13.72-11.28
c-6.78,0.6-11.66,6.18-11.72,13.78c-0.09,13.99-0.03,27.98-0.03,41.98c0,1.79,0,3.58,0,5.66c-8.57,0-16.64,0-24.9,0
C827.07,135.48,827.07,97.04,827.07,58.11z"
          />
          <path
            className="st0"
            d="M282.83,134.46c0.02-25.53,17.05-42.42,42.75-42.4c24.54,0.02,41.33,16.94,41.26,41.58
c-0.07,25.59-17.02,42.52-42.63,42.6C299.27,176.32,282.81,159.7,282.83,134.46z M307.61,134.13c1.74,5.85,2.55,11.78,5.18,16.74
c5.39,10.16,18.82,10.05,24.12-0.11c5.64-10.81,5.67-22.19,0.03-33.02c-5.32-10.2-18.59-10.37-24.06-0.21
C310.21,122.46,309.36,128.37,307.61,134.13z"
          />
          <path
            className="st0"
            d="M653.37,176.24c-24.72,0.1-41.41-16.5-41.48-41.24c-0.07-25.92,16.83-42.89,42.74-42.94
c24.33-0.04,41.14,16.66,41.3,41.03C696.09,158.97,679.15,176.14,653.37,176.24z M671.03,133.98c-1.72-5.59-2.63-11.67-5.36-16.76
c-5.08-9.46-17.87-9.71-23.3-0.48c-6.51,11.09-6.62,23.01-0.45,34.24c5.67,10.32,19.15,9.82,24.37-0.8
C668.64,145.4,669.38,139.83,671.03,133.98z"
          />
          <path
            className="st0"
            d="M601.97,174.25c-8.5,0-16.57,0-25.09,0c0-13.56,0.05-26.85-0.03-40.14c-0.02-3.48-0.08-7.02-0.71-10.42
c-1.45-7.92-5.74-11.35-12.95-10.87c-6.58,0.44-12.05,5.85-12.19,12.9c-0.27,13.82-0.14,27.64-0.17,41.46
c-0.01,2.15,0,4.31,0,6.94c-8.31,0-16.34,0-25.08,0c0-26.52,0-53.03,0-79.81c6.71,0,13.62,0,20.85,0
c0.48,3.35,0.94,6.59,1.42,9.94c3.44-2.79,6.44-5.92,10.04-8.02c17.97-10.51,39.07-0.65,42.84,19.93c0.68,3.74,1,7.6,1.03,11.41
C602.04,143,601.97,158.46,601.97,174.25z"
          />
          <path
            className="st0"
            d="M427.23,94.19c8.7,0,16.49,0,25.03,0c0,26.67,0,53.2,0,80.05c-6.62,0-13.69,0-20.85,0
c-0.46-3.43-0.87-6.54-1.36-10.23c-1.04,0.9-1.78,1.4-2.35,2.04c-9,10.09-20.35,12.38-32.75,8.74
c-12.16-3.57-17.39-13.45-17.86-25.12c-0.74-18.25-0.2-36.55-0.2-55.25c7.74,0,15.77,0,24.46,0c0,2.04-0.02,3.97,0,5.91
c0.14,13.98-0.09,27.98,0.57,41.93c0.46,9.57,5.53,14.2,13.08,13.65c7.45-0.55,12.18-6.46,12.21-15.45
c0.04-13.49,0.01-26.98,0.01-40.47C427.23,98.22,427.23,96.42,427.23,94.19z"
          />
          <path
            className="st0"
            d="M472.23,112.96c-3.83-0.21-6.93-0.39-10.25-0.57c0-6.14,0-11.91,0-18.16c3.23,0,6.33,0,10.25,0
c0-4.2-0.18-7.96,0.1-11.69c0.1-1.33,1.04-3.42,2.04-3.74c7.25-2.28,14.62-4.19,22.56-6.38c0,7.48,0,14.24,0,21.5
c6.02,0,11.61,0,17.57,0c0,6.37,0,12.24,0,18.68c-5.75,0-11.34,0-17.17,0c0,12-0.38,23.46,0.16,34.87c0.28,5.9,3.85,8.24,9.9,8.24
c1.99,0,3.97-0.3,6.81-0.53c0,5.66,0.17,11.25-0.15,16.8c-0.06,1.05-1.96,2.72-3.14,2.85c-5.61,0.61-11.31,1.43-16.88,0.98
c-12.22-0.98-19.96-8.76-20.96-21.47c-0.86-10.94-0.61-21.96-0.82-32.95C472.19,118.75,472.23,116.11,472.23,112.96z"
          />
          <path
            className="st0"
            d="M763.19,94.1c3.38,0,6.49,0,10.36,0c0-4.15-0.18-7.92,0.1-11.66c0.1-1.34,1.08-3.42,2.1-3.74
c7.11-2.23,14.33-4.08,22.1-6.22c0,7.28,0,14,0,21.36c6.15,0,11.86,0,17.86,0c0,6.39,0,12.15,0,18.61c-5.62,0-11.32,0-17.24,0
c0,11.96-0.43,23.23,0.16,34.46c0.36,6.71,4.07,9.06,10.89,8.78c1.66-0.07,3.3-0.3,5.9-0.54c0,5.74,0.11,11.32-0.11,16.89
c-0.04,0.94-1.37,2.43-2.32,2.62c-8.04,1.62-16.11,2.47-24.13-0.3c-10.02-3.46-14.22-11.27-14.78-21.03
c-0.65-11.28-0.4-22.62-0.54-33.93c-0.02-1.97,0-3.94,0-6.38c-3.63-0.23-6.86-0.44-10.36-0.66
C763.19,106.38,763.19,100.62,763.19,94.1z"
          />
          <path
            className="st0"
            d="M730.94,174.25c-8.54,0-16.6,0-25.23,0c0-26.72,0-53.13,0-79.88c6.63,0,13.51,0,20.84,0
c0.32,4.35,0.64,8.58,0.93,12.51c3.83-3.94,7.14-8.25,11.33-11.41c4.47-3.38,9.99-4.27,16.32-2.61c0,3.75,0,7.51,0,11.28
c0,3.79,0,7.58,0,11.65c-3.21,0-6-0.16-8.76,0.03c-9.2,0.62-15.13,6.57-15.31,15.79c-0.23,12.31-0.1,24.64-0.12,36.95
C730.93,170.34,730.94,172.14,730.94,174.25z"
          />
          <path
            className="st0"
            d="M916.04,59.38c5.39,0.03,9.93,4.61,9.76,9.85c-0.17,5.13-4.47,9.26-9.76,9.35c-5.39,0.1-10-4.32-9.99-9.59
C906.05,63.68,910.55,59.35,916.04,59.38z M923.02,68.84c-0.08-4.26-3.13-7.32-7.24-7.28c-4.02,0.04-7.05,3.31-6.98,7.54
c0.07,4.27,3.07,7.26,7.26,7.23C920.14,76.29,923.11,73.1,923.02,68.84z"
          />
          <path d="M307.61,134.13c1.76-5.76,2.61-11.67,5.27-16.61c5.47-10.16,18.75-9.98,24.06,0.21c5.65,10.83,5.62,22.21-0.03,33.02
c-5.3,10.16-18.73,10.27-24.12,0.11C310.16,145.9,309.35,139.98,307.61,134.13z"
          />
          <path d="M671.03,133.98c-1.65,5.84-2.39,11.42-4.73,16.2c-5.22,10.62-18.7,11.13-24.37,0.8c-6.17-11.23-6.06-23.15,0.45-34.24
c5.42-9.24,18.22-8.98,23.3,0.48C668.4,122.32,669.31,128.39,671.03,133.98z"
          />
          <path d="M923.02,68.84c0.08,4.26-2.88,7.45-6.96,7.48c-4.19,0.03-7.2-2.96-7.26-7.23c-0.07-4.23,2.96-7.5,6.98-7.54
C919.9,61.52,922.94,64.58,923.02,68.84z M912.58,64.63c0,3.32,0,6.26,0,9.2c0.29,0.05,0.58,0.1,0.86,0.15
c0.66-1.27,1.31-2.53,2.19-4.22c1.37,1.71,2.44,3.04,3.51,4.37c0.69-1.92,0.05-3.26,0-4.61
C918.95,63.53,918.87,63.42,912.58,64.63z"
          />
          <path
            className="st0"
            d="M912.58,64.63c6.29-1.21,6.37-1.1,6.57,4.9c0.05,1.35,0.69,2.69,0,4.61c-1.07-1.33-2.14-2.67-3.51-4.37
c-0.88,1.69-1.53,2.95-2.19,4.22c-0.29-0.05-0.58-0.1-0.86-0.15C912.58,70.89,912.58,67.95,912.58,64.63z"
          />
        </g>
      </g>
    </svg>),
}
