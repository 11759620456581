/* eslint-disable max-len */
const programName = 'Bangerhead'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 185 32">
      <title>{programName}</title>
      <path d="M0 0.722h10.346c1.431 0 2.678 0.275 3.739 0.825s1.87 1.306 2.424 2.269 0.831 2.028 0.831 3.197c0 1.306-0.369 2.417-1.108 3.334s-1.766 1.558-3.082 1.925c1.616 0.321 2.862 0.985 3.739 1.994s1.316 2.291 1.316 3.85c0 1.283-0.3 2.452-0.9 3.506-0.577 1.031-1.408 1.845-2.493 2.44-1.085 0.573-2.355 0.859-3.809 0.859h-11.003v-24.198zM10.069 21.517c1.247 0 2.216-0.332 2.909-0.997 0.716-0.665 1.073-1.57 1.073-2.715s-0.358-2.062-1.073-2.75c-0.693-0.687-1.662-1.031-2.909-1.031h-5.983v7.493h5.983zM9.653 10.93c1.131 0 2.020-0.309 2.666-0.928 0.646-0.642 0.97-1.478 0.97-2.509 0-1.054-0.323-1.879-0.97-2.475s-1.558-0.894-2.735-0.894h-5.498v6.806h5.567z" />
      <path d="M25.635 25.332c-1.016 0-1.916-0.195-2.701-0.584-0.785-0.413-1.408-0.985-1.87-1.719-0.439-0.733-0.658-1.604-0.658-2.612 0-1.489 0.508-2.693 1.524-3.609 1.039-0.917 2.516-1.547 4.432-1.89l3.705-0.687c0.623-0.115 1.050-0.275 1.281-0.481s0.346-0.47 0.346-0.791c0-0.779-0.3-1.421-0.9-1.925-0.6-0.527-1.431-0.791-2.493-0.791s-1.962 0.275-2.701 0.825c-0.716 0.527-1.316 1.249-1.801 2.165l-3.185-1.512c0.462-1.031 1.062-1.902 1.801-2.612 0.739-0.733 1.604-1.283 2.597-1.65s2.089-0.55 3.289-0.55c1.477 0 2.759 0.264 3.843 0.791 1.108 0.527 1.962 1.272 2.562 2.234 0.6 0.94 0.9 2.028 0.9 3.265v11.721h-3.913v-4.331c-0.485 1.535-1.258 2.715-2.32 3.54-1.062 0.802-2.308 1.203-3.74 1.203zM26.985 22.101c0.946 0 1.777-0.286 2.493-0.859s1.258-1.295 1.627-2.165c0.392-0.894 0.589-1.776 0.589-2.647v-0.172c-0.185 0.138-0.427 0.252-0.727 0.344-0.3 0.069-0.635 0.137-1.004 0.206l-2.389 0.378c-0.923 0.16-1.697 0.447-2.32 0.859-0.6 0.39-0.9 0.997-0.9 1.822 0 0.687 0.242 1.237 0.727 1.65 0.485 0.389 1.119 0.584 1.904 0.584z" />
      <path d="M39.606 7.459h3.982v4.365c0.508-1.512 1.27-2.704 2.285-3.575 1.016-0.894 2.285-1.341 3.809-1.341 1.754 0 3.128 0.596 4.12 1.787 1.016 1.169 1.523 2.864 1.523 5.087v11.136h-4.017v-10.999c0-1.192-0.265-2.097-0.796-2.715-0.508-0.619-1.27-0.928-2.285-0.928-0.9 0-1.708 0.286-2.424 0.859-0.693 0.55-1.235 1.329-1.627 2.337-0.393 0.985-0.589 2.131-0.589 3.437v8.009h-3.982v-17.461z" />
      <path d="M67.316 32c-1.87 0-3.543-0.367-5.021-1.1s-2.631-1.764-3.463-3.093l2.909-2.062c0.646 0.917 1.443 1.615 2.389 2.097 0.946 0.504 1.997 0.756 3.151 0.756 1.039 0 1.893-0.183 2.562-0.55 0.669-0.344 1.166-0.859 1.489-1.547 0.346-0.665 0.519-1.501 0.519-2.509v-2.715c-0.646 1.054-1.489 1.879-2.528 2.475-1.016 0.596-2.158 0.894-3.428 0.894-1.5 0-2.839-0.367-4.017-1.1-1.154-0.756-2.054-1.799-2.701-3.128-0.646-1.352-0.969-2.887-0.969-4.606s0.323-3.254 0.969-4.606c0.646-1.352 1.547-2.406 2.701-3.162 1.177-0.756 2.516-1.134 4.017-1.134 1.27 0 2.412 0.298 3.428 0.894 1.039 0.596 1.881 1.432 2.528 2.509v-2.853h3.947v16.601c0 1.65-0.346 3.071-1.039 4.262-0.669 1.214-1.65 2.131-2.943 2.75-1.27 0.619-2.77 0.928-4.501 0.928zM67.074 21.448c0.969 0 1.812-0.229 2.528-0.687s1.27-1.111 1.662-1.959c0.393-0.848 0.589-1.845 0.589-2.99 0-1.764-0.427-3.151-1.281-4.159-0.831-1.008-1.997-1.512-3.497-1.512-1.477 0-2.655 0.504-3.532 1.512-0.854 1.008-1.281 2.395-1.281 4.159 0 1.146 0.196 2.143 0.589 2.99s0.947 1.501 1.662 1.959c0.739 0.458 1.593 0.687 2.562 0.687z" />
      <path d="M87.868 25.469c-1.801 0-3.382-0.389-4.744-1.169-1.339-0.802-2.366-1.902-3.082-3.3-0.715-1.421-1.073-3.025-1.073-4.812 0-1.764 0.346-3.346 1.039-4.743s1.708-2.498 3.047-3.3c1.339-0.825 2.909-1.237 4.709-1.237 1.731 0 3.209 0.39 4.432 1.169 1.223 0.756 2.147 1.833 2.77 3.231 0.646 1.375 0.969 2.99 0.969 4.846 0 0.367-0.012 0.687-0.035 0.962h-13.088c0.139 1.673 0.658 2.933 1.558 3.781s2.101 1.272 3.601 1.272c1.2 0 2.216-0.264 3.047-0.791s1.558-1.249 2.181-2.165l2.528 2.097c-0.946 1.375-2.066 2.417-3.359 3.128-1.27 0.687-2.77 1.031-4.501 1.031zM92.058 14.402c-0.092-1.375-0.519-2.418-1.281-3.128-0.739-0.733-1.743-1.1-3.012-1.1-1.293 0-2.366 0.367-3.22 1.1-0.831 0.71-1.373 1.753-1.627 3.128h9.141z" />
      <path d="M99.113 7.459h3.982v4.159c0.416-1.352 1.027-2.406 1.835-3.162 0.808-0.779 1.766-1.169 2.874-1.169 0.346 0 0.658 0.023 0.935 0.069 0.277 0.023 0.566 0.080 0.866 0.172v3.747c-0.577-0.16-1.177-0.241-1.801-0.241-1.431 0-2.574 0.55-3.428 1.65s-1.281 2.681-1.281 4.743v7.493h-3.982v-17.461z" />
      <path d="M111.792 0h3.947v11.824c0.485-1.512 1.235-2.704 2.251-3.575 1.016-0.894 2.285-1.341 3.809-1.341 1.777 0 3.163 0.596 4.155 1.787 0.993 1.169 1.489 2.864 1.489 5.087v11.136h-3.982v-10.999c0-1.192-0.266-2.097-0.797-2.715s-1.293-0.928-2.285-0.928c-0.923 0-1.743 0.286-2.458 0.859-0.693 0.55-1.235 1.329-1.627 2.337-0.369 0.985-0.554 2.131-0.554 3.437v8.009h-3.947v-24.919z" />
      <path d="M139.26 25.469c-1.8 0-3.381-0.389-4.743-1.169-1.339-0.802-2.367-1.902-3.082-3.3-0.716-1.421-1.073-3.025-1.073-4.812 0-1.764 0.346-3.346 1.039-4.743s1.708-2.498 3.048-3.3c1.339-0.825 2.908-1.237 4.708-1.237 1.732 0 3.209 0.39 4.432 1.169 1.224 0.756 2.148 1.833 2.771 3.231 0.647 1.375 0.969 2.99 0.969 4.846 0 0.367-0.012 0.687-0.035 0.962h-13.088c0.139 1.673 0.657 2.933 1.557 3.781 0.901 0.848 2.101 1.272 3.601 1.272 1.2 0 2.216-0.264 3.048-0.791 0.831-0.527 1.557-1.249 2.181-2.165l2.527 2.097c-0.947 1.375-2.065 2.417-3.359 3.128-1.269 0.687-2.769 1.031-4.501 1.031zM143.451 14.402c-0.093-1.375-0.52-2.418-1.281-3.128-0.739-0.733-1.743-1.1-3.013-1.1-1.292 0-2.365 0.367-3.22 1.1-0.831 0.71-1.373 1.753-1.627 3.128h9.141z" />
      <path d="M154.521 25.332c-1.015 0-1.916-0.195-2.7-0.584-0.785-0.413-1.408-0.985-1.871-1.719-0.439-0.733-0.657-1.604-0.657-2.612 0-1.489 0.508-2.693 1.524-3.609 1.039-0.917 2.516-1.547 4.432-1.89l3.704-0.687c0.624-0.115 1.051-0.275 1.281-0.481s0.347-0.47 0.347-0.791c0-0.779-0.3-1.421-0.9-1.925-0.6-0.527-1.432-0.791-2.493-0.791s-1.963 0.275-2.701 0.825c-0.715 0.527-1.315 1.249-1.8 2.165l-3.185-1.512c0.461-1.031 1.061-1.902 1.8-2.612 0.739-0.733 1.604-1.283 2.597-1.65 0.992-0.367 2.089-0.55 3.289-0.55 1.477 0 2.759 0.264 3.844 0.791 1.108 0.527 1.961 1.272 2.561 2.234 0.6 0.94 0.9 2.028 0.9 3.265v11.721h-3.912v-4.331c-0.485 1.535-1.259 2.715-2.32 3.54-1.061 0.802-2.308 1.203-3.74 1.203zM155.872 22.101c0.947 0 1.777-0.286 2.493-0.859s1.257-1.295 1.627-2.165c0.393-0.894 0.589-1.776 0.589-2.647v-0.172c-0.185 0.138-0.427 0.252-0.727 0.344-0.301 0.069-0.636 0.137-1.005 0.206l-2.388 0.378c-0.924 0.16-1.697 0.447-2.32 0.859-0.6 0.39-0.901 0.997-0.901 1.822 0 0.687 0.243 1.237 0.728 1.65 0.484 0.389 1.119 0.584 1.904 0.584z" />
      <path d="M175.105 25.469c-1.5 0-2.839-0.389-4.016-1.169-1.177-0.802-2.089-1.902-2.736-3.3-0.645-1.398-0.969-2.99-0.969-4.778 0-1.81 0.324-3.414 0.969-4.812 0.647-1.421 1.559-2.521 2.736-3.3 1.177-0.802 2.516-1.203 4.016-1.203 1.271 0 2.424 0.321 3.463 0.962 1.039 0.619 1.881 1.489 2.528 2.612v-10.483h3.948v24.919h-3.948v-2.99c-0.647 1.1-1.489 1.971-2.528 2.612-1.039 0.619-2.192 0.928-3.463 0.928zM176.283 22.101c0.971 0 1.812-0.229 2.528-0.687 0.739-0.481 1.304-1.169 1.697-2.062 0.392-0.894 0.588-1.936 0.588-3.128 0-1.856-0.427-3.311-1.281-4.365-0.853-1.054-2.031-1.581-3.532-1.581-1.477 0-2.655 0.527-3.531 1.581-0.855 1.054-1.281 2.509-1.281 4.365 0 1.191 0.196 2.234 0.588 3.128 0.393 0.894 0.947 1.581 1.663 2.062 0.739 0.458 1.592 0.687 2.561 0.687z" />
    </svg>
  ),
}
