/* eslint-disable max-len */
const programName = 'STIGA Sports'
export default {
  programName,
  logo: (
    <svg x="0px" y="0px" viewBox="0 0 611 137">
      <title>{programName}</title>
      <path d="M256.8,7.4h37.6V131h-37.6C256.8,131,256.8,7.4,256.8,7.4z M441.4,58.8c0.5,3.3,0.7,6.7,0.7,10.1c0,35-28.3,63.4-63.3,63.4
    S315.4,104,315.4,69s28.3-63.4,63.3-63.4c13.3,0,26.2,4.1,37,11.8l-20.2,26.1c-14-9.2-32.8-5.3-42,8.7s-5.3,32.8,8.7,42
    s32.7,5.3,41.9-8.6c0.6-0.9,1.2-1.9,1.7-2.9h-24.3V58.8L441.4,58.8L441.4,58.8z M5,94.2c0,0,14.8,4.1,56.2-47.5
    C97.8,1.2,128.5,6.9,131.4,6.9v38.2c0,0-24-3.5-55.6,42.4c-28.1,40.8-57.3,43.7-70.7,43.7L5,94.2z M148.4,7.4V45h24.9v86H211V45.1
    h24.9V7.5h-87.5V7.4z M565.2,59.4C542.9,28,531.1,7.5,510.7,7.5h-48.2V131h37.4v-25.3h56.7l1,1.4c10.7,15.6,28.5,24.7,47.4,24.1v-37
    C605,94.2,584.5,86.7,565.2,59.4z M499.8,73.4V45.1c6.4-0.3,12.4,2.8,16,8l15.8,20.2L499.8,73.4z"
      />
    </svg>),
}
