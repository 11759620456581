/* eslint-disable max-len */
const programName = 'Autoexperten'
export default {
  programName,
  logo: (
    <svg className="autoexperten" viewBox="0 0 962.59 209.16">
      <title>{programName}</title>
      <path className="cls-1" d="M824.57,50h23.94l-7.39,35.57H817.19L824.57,50Zm-11.06,53.31,51.28,0,7.39-35.55v0c4.07-19.6-4.89-35.5-20-35.5h-24c-15.09,0-30.65,15.92-34.72,35.54l-11.08,53.34c-4.05,19.59,4.87,35.51,20,35.52h51.2l3.67-17.79H806.13l7.38-35.55Z" />
      <path className="cls-1" d="M888.77,32.24,863.22,156.46h27.35L912.45,50h23.9L914.21,156.65h27.36L960,67.78l-.19,0H960c4.09-19.59-4.86-35.48-20-35.5H888.77Z" />
      <path className="cls-1" d="M144.07,32.59H116.71L98.26,121.47c-4.07,19.59,4.87,35.51,20,35.52v0h51.27l25.88-124.8H168l-22.19,107H121.93L144.07,32.59Z" />
      <path className="cls-1" d="M230.62,50.35h13.67L248,32.59H234.3L238,14.81H210.65L188.51,121.47c-4.07,19.58,4.86,35.51,20,35.52l0,0h17.09l3.7-17.79h-17.1l18.45-88.87Z" />
      <path className="cls-1" d="M55,23.71h24L66.47,85.92H42.11L55,23.71ZM58.71,6C43.61,6,28.06,21.86,24,41.47h0L0,157H27.35l11.07-53.35H62.88l-14.09,69.6H76.13L110,6Z" />
      <path className="cls-1" d="M276.1,50.35H300L281.6,139.22H257.66L276.1,50.35ZM303.72,32.6H279.78c-15.09,0-30.64,15.92-34.72,35.53h0L234,121.47h0c-4.08,19.58,4.86,35.51,20,35.52v0h23.92l.05-.06V157c15.06,0,30.6-15.93,34.69-35.54h0l11.07-53.35h0c4.07-19.6-4.87-35.51-20-35.51Z" />
      <polygon className="cls-1" points="339.85 198.93 448.68 122.56 461.13 157.03 493.48 157.03 475.06 106.05 456.83 114.66 557.97 0 461.81 69.36 448.58 32.73 416.23 32.73 435.23 85.34 444.54 84.1 339.85 198.93 339.85 198.93" />
      <path className="cls-1" d="M777.32,50.35H791l3.7-17.76H781l3.69-17.78H757.35L735.22,121.47c-4.08,19.58,4.85,35.51,19.95,35.52v0h17.09L776,139.22H758.87l18.45-88.87Z" />
      <path className="cls-1" d="M725.2,50.35h17l3.67-17.76h-17c-15.1,0-30.64,15.92-34.72,35.53h0L675.72,157h27.33L725.2,50.35Z" />
      <path className="cls-1" d="M547.88,50.35h24l-18.46,88.87H529.43l18.45-88.87ZM575.51,32.6H551.57c-15.09,0-30.64,15.92-34.72,35.53L498.39,157h0c-4.05,19.6,4.88,35.49,20,35.49L525.74,157h23.93l0,0v0c15.08,0,30.64-15.93,34.71-35.54h0l11.07-53.35h0c4.07-19.61-4.89-35.51-20-35.51Z" />
      <path className="cls-1" d="M364.75,50.35h23.94l-7.38,35.57H357.37l7.38-35.57Zm47.6,17.76c4.07-19.61-4.87-35.51-20-35.51H368.43c-15.1,0-30.64,15.92-34.71,35.53h0l-11.08,53.35h0c-4.06,19.59,4.86,35.51,20,35.52v0h28.8l9.65-17.79H346.31l7.38-35.56,51.27,0,7.39-35.57Z" />
      <path className="cls-1" d="M636.53,50.35h23.94l-7.38,35.57H629.16l7.37-35.57Zm47.61,17.76c4.07-19.61-4.86-35.51-20-35.51H640.23l0,0v0c-15.09,0-30.62,15.92-34.68,35.53h0l-11.07,53.35h0c-4.11,19.59,4.83,35.51,19.93,35.52l0,0h51.65l3.68-17.79H618.1l7.37-35.56,51.27,0,7.4-35.57Z" />
    </svg>
  ),
}
