/* eslint-disable max-len */
const programName = 'Hulténs'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 195.5 51">
      <title>{programName}</title>
      <g>
        <g>
          <path
            className="st0"
            d="M0.1,0C4,0,7.7,0,11.5,0c0,0.4,0,0.8,0,1.3c0,3.9,0,7.7,0,11.6c0,0.9,0.3,1.2,1.2,1.2c3.5,0,7,0,10.5,0
          c0.9,0,1.2-0.3,1.2-1.2c0-3.8,0-7.7,0-11.5c0-0.4,0-0.8,0-1.4c4,0,7.9,0,11.9,0c0,14.7,0,29.4,0,44.2c-3.9,0-7.8,0-11.8,0
          c0-0.4-0.1-0.8-0.1-1.2c0-5.8,0-11.6,0-17.5c0-1.2-0.4-1.5-1.5-1.5c-3.3,0.1-6.7,0.1-10,0c-1,0-1.4,0.3-1.4,1.3
          c0,5.2,0,10.4,0,15.6c0,0.8,0,1.7,0.2,2.4c0.3,1-0.1,1.5-0.9,2c-2.8,1.6-5.5,3.4-8.3,5c-0.3,0.2-0.5,0.3-1,0.5
          c-0.4-1.2-0.9-2.3-1-3.4C0.2,45.3,0,43,0,40.7C0,27.5,0,14.4,0,1.2C0,0.8,0.1,0.5,0.1,0z"
          />
          <path
            className="st0"
            d="M168.5,20.1c0-0.4,0.1-0.9,0.1-1.3c0-2.8,0-5.5,0-8.3c0-0.8,0.2-1.1,1-1.1c2.4,0,4.9,0,7.3,0c0.8,0,1,0.3,1,1
          c0,11.1,0.1,22.1,0.1,33.2c0,0.8-0.3,1-1,1c-2.4,0-4.9,0-7.3,0c-0.9,0-1.2-0.3-1.2-1.2c0-2.6,0-5.2,0-7.9c0-1.1-0.3-1.9-1.1-2.7
          c-2.6-2.7-5.2-5.5-7.8-8.3c-0.2-0.2-0.4-0.4-0.8-0.7c-0.1,0.6-0.2,1.1-0.2,1.5c0,5.9,0,11.8,0,17.7c0,1.5,0,1.5-1.6,1.5
          c-2.2,0-4.3,0-6.5,0c-1,0-1.4-0.3-1.4-1.4c0-10.9,0-21.8,0-32.7c0-1,0.3-1.2,1.2-1.2c2.4,0,4.9,0,7.3,0c0.5,0,1.2,0.3,1.6,0.7
          c2.8,3.1,5.5,6.3,8.2,9.5c0.2,0.2,0.3,0.5,0.4,0.7C168.2,20.2,168.3,20.2,168.5,20.1z"
          />
          <path
            className="st0"
            d="M55.9,8.9c-1.7,4.4-3.4,8.6-4.8,12.9c-0.7,2.2-1.1,4.5-1.4,6.8c-0.5,3.6,2,7,6,7c1.1,0,2.2-0.1,3.3,0
          c1,0,1.3-0.4,1.3-1.3c0-5.1,0-10.2,0-15.2c0-3.1,0-6.2,0-9.3c0-0.5,0-1,0.7-1c2.9,0,5.8,0,8.8,0c0,0.5,0,0.8,0,1.2
          c0,10.9,0,21.7,0,32.6c0,1-0.2,1.4-1.3,1.3c-5.8-0.1-11.7,0.1-17.5-0.3c-5.9-0.4-9.2-3.2-10.7-9.1c-1.2-4.7-0.7-9.3,0.9-13.8
          c1.3-3.6,2.9-7.1,4.3-10.6c0.4-1,1.1-1.4,2.3-1.4C50.5,9,53.2,8.9,55.9,8.9z"
          />
          <path
            className="st0"
            d="M145.8,36.5c-0.4,1.5-0.8,2.8-1.2,4.1c-0.2,0.8-0.6,1.6-0.7,2.4c-0.1,0.7-0.5,1-1.2,1c-7,0-14,0-21,0
          c-0.1,0-0.3-0.1-0.5-0.1c0-12,0-23.9,0-35.9c0.4,0,0.8-0.1,1.1-0.1c6.1,0,12.1,0,18.2,0c0.9,0,1.4,0.1,1.7,1.1
          c0.6,2.2,1.5,4.4,2.3,6.7c-0.5,0-0.8,0.1-1.1,0.1c-4,0.1-8,0.2-12,0.2c-1,0-1.4,0.4-1.3,1.4c0.1,1.2,0.1,2.5,0,3.7
          c0,1,0.3,1.3,1.3,1.3c3.4,0,6.9,0,10.3,0c0.4,0,0.8,0,1.5,0c-0.6,2.3-1.1,4.4-1.7,6.5c-0.1,0.3-0.7,0.5-1.1,0.6
          c-3,0-5.9,0.1-8.9,0c-1,0-1.4,0.3-1.4,1.3c0.1,1.5,0.1,3,0,4.6c0,0.9,0.3,1.1,1.1,1.1c4.3,0,8.7,0,13,0
          C144.8,36.5,145.2,36.5,145.8,36.5z"
          />
          <path
            className="st0"
            d="M89.8,16.1c0.7-2,1.3-3.8,1.9-5.5c0.3-0.9,0.6-2,1.2-2.5c0.7-0.4,1.8-0.1,2.8-0.1c6.8,0,13.5,0,20.3,0
          c0.5,0,0.9,0,1.5,0c0.8,2.7,1.5,5.3,2.3,8.1c-0.6,0-1,0.1-1.4,0.1c-2.4,0-4.8,0-7.2-0.1c-1.1,0-1.5,0.3-1.5,1.5
          c0,8.4,0,16.8,0,25.2c0,1.1-0.3,1.5-1.4,1.5c-2.7-0.1-5.4,0-8.2,0c0-0.6-0.1-1-0.1-1.5c0-8.4,0-16.7,0-25.1c0-1.1-0.3-1.5-1.4-1.5
          c-2.4,0.1-4.9,0-7.3,0C90.9,16.1,90.5,16.1,89.8,16.1z"
          />
          <path
            className="st0"
            d="M95.7,36.1c-0.5,1.8-0.8,3.5-1.3,5.1c-0.9,2.8-0.9,2.8-3.9,2.8c-4.9,0-9.9,0-14.8,0c-0.5,0-0.9,0-1.5,0
          c0-12.1,0-24,0-36c3,0,5.9,0,9.1,0c0,0.5,0,0.9,0,1.4c0,8.4,0,16.9,0,25.3c0,1.1,0.3,1.5,1.5,1.4c3.2-0.1,6.3,0,9.5,0
          C94.7,36.1,95.1,36.1,95.7,36.1z"
          />
          <path
            className="st0"
            d="M193.4,22.7c0.5,1.8,0.8,3.2,1.3,4.7c-1.7,0-3.1-0.1-4.6,0c-0.6,0-1.5,0.2-1.7,0.6c-0.2,0.5,0.1,1.4,0.4,1.8
          c1.5,1.9,3.1,3.7,4.5,5.6c0.7,0.9,1.2,2.1,1.7,3.2c1.3,3.1-0.6,5.2-3.2,5.5c-2.6,0.3-5.2,0.2-7.8,0.2c-0.7,0-1.1-0.3-1.3-0.9
          c-0.3-1.2-0.7-2.4-1.1-3.7c1.8,0,3.4,0,5.1,0c1.7,0,2.3-0.9,1.3-2.4c-1.1-1.6-2.4-3-3.5-4.5c-0.9-1.3-2-2.6-2.5-4
          c-0.9-2.3,0.6-5,3-5.4C187.7,23,190.6,23,193.4,22.7z"
          />
          <path
            className="st0"
            d="M132.2,6.8c1.1-2.3,2-4.4,3-6.5c0.1-0.2,0.4-0.3,0.6-0.3c1.8,0,3.7,0,5.7,0c-1.2,2.2-2.2,4.3-3.3,6.3
          c-0.1,0.2-0.5,0.5-0.7,0.5C135.8,6.8,134.1,6.8,132.2,6.8z"
          />
        </g>
      </g>
    </svg>
  ),
}
