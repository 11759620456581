/* eslint-disable max-len */
const programName = 'Hoka One One'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 99 26.9">
      <title>{programName}</title>
      <path d="M98,25.4C98.1,25.4,98.1,25.5,98,25.4c0,0.1,0,0.1-0.1,0.1h-0.1c-0.1,0-0.1,0-0.1-0.1l-0.4-0.7h-0.1l-0.1,0.6
c0,0.1,0,0.1-0.1,0.1h-0.1c-0.1,0-0.1,0-0.1-0.1v0l0.2-1.6c0-0.1,0-0.1,0.1-0.1h0.3c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.2,0.4
c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.1-0.3,0.1L98,25.4z M97.4,24.6h0.1c0.2,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.1,0.1-0.2
c0-0.1,0-0.2-0.1-0.2c-0.1,0-0.1,0-0.2,0h-0.1L97.4,24.6z M97.6,23.2c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4
c0.8,0,1.4-0.6,1.4-1.4C99,23.9,98.4,23.2,97.6,23.2L97.6,23.2z M97.6,25.9c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3
s1.3,0.6,1.3,1.3l0,0C98.8,25.4,98.2,25.9,97.6,25.9z M56.8,11.5c-0.6,0.4-1.2,0.7-1.8,1c-0.7,0.3-1.5,0.5-2.2,0.6
c-0.3,0.1-1.7,0.2-1.7,0.2c-0.4,0.1-0.7,0.1-1.2,0.2c-0.6,0.1-1.4,0.2-2.3,0.4l-0.3,0.1c-1.4,0.2-2.8,0.5-4.2,1.1
c-0.5,0.2-1.1,0.4-1.6,0.7l-0.1,0L40.8,16c-0.4,0.2-1.1,0.6-1.7,0.9c-0.2,0.1-0.5,0.2-0.8,0.1c-0.3,0-0.5-0.2-0.7-0.4
c-0.4-0.5-0.9-1.2-1.2-1.6c-0.2-0.2-0.3-0.4-0.4-0.5L36,14.5c-0.4-0.4-0.7-0.9-1.2-1.3c-1-1-2.1-1.9-3.4-2.7l-0.3-0.2
c-0.8-0.5-1.5-0.9-2-1.3l-1-0.7c0,0-1.2-0.7-1.5-0.9c-0.6-0.4-1.3-0.9-1.8-1.4C24.5,5.6,24,5,23.7,4.5c-0.1-0.1,0-0.2,0.1-0.3
c0.1,0,0.2-0.1,0.2-0.1c0,0,0.1,0,0.1,0c0,0,0.1,0.1,0.2,0.2c0.2,0.3,0.8,0.9,1.1,1.3c0.7,0.6,1.4,1.2,2.2,1.6l0.1,0.1
c0.2,0.1,0.8,0.5,1.1,0.6c0.7,0.3,1.5,0.7,2.2,1c0.7,0.3,1.5,0.6,2.1,0.9l0.1,0c0.9,0.5,1.7,1,2.5,1.6c0.8,0.6,1.6,1.1,2.5,1.5
c0.5,0.3,1.1,0.4,1.7,0.4c1-0.1,1.9-0.2,2.9-0.4c1-0.2,2-0.4,2.9-0.5h0.1c0.7,0,1.5,0,2.3,0.1c0.9,0.1,1.7,0,2.4,0
c0.3,0,1-0.1,1.2-0.1l0.2,0c0.9-0.1,1.8-0.3,2.7-0.6c0.5-0.2,1.2-0.6,1.6-0.7l0.2-0.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0.1,0.2,0.2
C56.9,11.3,56.9,11.4,56.8,11.5L56.8,11.5z M94.4,25.5L89.9,1.1c-0.1-0.4-0.4-0.7-0.9-0.7h-5.9c-1.1,0-1.2,0-1.5,0.6l-0.2,0.4
L70.6,20.5l-4.3-6.8l9.3-11.4C75.8,2,76,1.6,76.1,1.1c0-0.5-0.3-0.7-1.4-0.7h-4.9c-0.7,0-0.8,0-1.4,0.8l-8.6,10.5L61.6,2
c0.1-0.2,0.1-0.5,0.1-0.7c0-0.7-0.3-0.8-1.4-0.8h-3.9c-1.3,0-1.4,0-1.6,1.3l-1.6,9C52.6,4.2,47.7,0,40.4,0c-5.5,0-10.1,2.4-12.9,6.2
c0.2,0.1,0.3,0.2,0.5,0.3l0.2,0.1c0.1,0.1,0.7,0.4,1,0.6c0.6,0.3,1.4,0.7,2.2,1l0.1,0c0.7,0.3,1.5,0.6,2.2,0.9l0.1,0h0
c1.5-1.8,3.8-2.8,6.1-2.7c3.5,0,5.9,2.1,6.4,5.3c0.6,0,1.3,0,2,0.1h0.1c0.8,0,1.6,0,2.4,0c0.3,0,1-0.1,1.1-0.1l0.2,0
c0.9-0.1,1.7-0.3,2.5-0.6c0.4-0.2,1.2-0.5,1.5-0.7l0.2-0.1l0,0l0.1,0c0.2-0.1,0.4-0.1,0.6-0.1c0.3,0.1,0.6,0.3,0.8,0.6
c0.3,0.5,0.2,1.1-0.3,1.4c-0.6,0.4-1.3,0.8-2,1.1c-0.8,0.3-1.6,0.5-2.4,0.6c-0.2,0-0.9,0.1-1.5,0.2l-0.2,0c-0.4,0.1-0.8,0.1-1.3,0.2
c-0.6,0.1-1.3,0.2-2.3,0.3l-0.3,0.1c-0.5,0.1-0.9,0.2-1.3,0.2c-0.9,3.3-3.7,5.5-7.4,5.5c-4.2,0-6.9-2.8-6.6-6.9c0-0.5,0.1-1,0.2-1.4
c-0.3-0.2-0.7-0.5-1.2-0.8l-0.3-0.2c-0.8-0.5-1.5-0.9-2-1.3c-0.4-0.3-0.8-0.5-1.1-0.7l-0.2-0.1c-0.6-0.3-1.1-0.7-1.3-0.8
c-0.7-0.5-1.3-1-1.9-1.6c-0.5-0.6-1-1.2-1.4-1.8c-0.3-0.5-0.1-1.1,0.4-1.4c0.3-0.2,0.6-0.3,0.9-0.2c0.2,0,0.4,0.1,0.5,0.3
c0,0,0,0,0.1,0.1l0,0l0.1,0.1C25.2,4.1,25.7,4.7,26,5c0.3,0.3,0.6,0.5,0.9,0.8L27.6,2l0.1-0.7c0.1-0.6-0.3-0.8-1.4-0.8h-4.1
c-1.3,0-1.4,0-1.6,1.3l-1.5,8.2H9.8l1.4-8c0.1-0.2,0.1-0.5,0.1-0.7c0-0.7-0.3-0.8-1.4-0.8h-4c-1.3,0-1.4,0-1.6,1.3L0.1,25L0,25.7
c0,0.7,0.3,0.8,1.4,0.8h4c1.3,0,1.4,0,1.6-1.3l1.7-9.5h9.4L16.4,25c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.7,0.4,0.8,1.5,0.8h3.9
c1.3,0,1.4,0,1.6-1.3L25,16c0.6,6.6,5.7,10.9,13.3,10.9c4.9,0,8.9-1.8,11.5-5.2c0.7-1,1.4-2,1.9-3.1L50.6,25l-0.1,0.7
c-0.1,0.7,0.2,0.8,1.3,0.8h3.9c1.3,0,1.4,0,1.6-1.3l1.9-10.4c0.5,1.2,1.1,2.3,1.8,3.4l4.5,7.5c0.5,0.8,0.5,0.8,1.3,0.8h6.9
c1.1,0,1.2,0,1.5-0.6l0.2-0.4l2-3.7h9.2l0.6,4.1c0,0.4,0.4,0.6,0.7,0.6h5.7c0.5,0,0.8-0.4,0.8-0.8C94.4,25.6,94.4,25.5,94.4,25.5
L94.4,25.5z M80.3,16.5l4.2-8.3l1.1,8.3H80.3z"
      />
    </svg>
  ),
}
