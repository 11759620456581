/* eslint-disable max-len */
const programName = 'Eton'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 112 24">
      <title>{programName}</title>
      <path d="M85.6393.360043V1.94386l.7199.28792c2.7359 1.1039 2.7359 1.1039 2.7359 3.88773v6.47959l-.072 5.0395c-.0479 3.5757-.0479 3.5757-2.3758 4.0318l-.7199.1441v1.5838h9.2152v-1.5838l-.7198-.1441c-2.7599-.552-2.9037-.552-2.9519-4.0318l-.0719-5.0395V6.11951l14.687 17.27859h2.16v-12.239l.072-5.03959c.047-3.6238.047-3.6238 2.087-4.03179l.72-.14406V.360043h-8.639V1.94386l.72.14406c2.472.5039 2.616.5039 2.664 4.03179l.071 5.03959v6.4795L91.2549.360043h-5.6156zM67.6886 0c-6.7195 0-11.9991 4.96766-11.9991 12.0952 0 6.9834 5.0876 11.6632 11.6151 11.6632 6.7195 0 11.999-4.9677 11.999-12.0952C79.3038 4.67954 74.2161 0 67.6886 0zm-.072 21.5983c-4.6795 0-7.7514-3.9117-7.7514-9.8632 0-5.90351 2.9998-9.57525 7.5115-9.57525 4.6795 0 7.7515 3.91171 7.7515 9.86315-.0002 5.9036-2.9998 9.5753-7.5116 9.5753zM29.0516.360043l-.7198 7.199437h1.7278l.3601-.93587c1.6318-4.24777 1.6318-4.24778 4.6795-4.24778h2.5199V18.7905c0 2.2318 0 2.2318-3.2398 2.8797l-.7198.1441v1.5838h11.5191v-1.5838l-.7199-.1441c-3.2398-.6479-3.2398-.6479-3.2398-2.8797V2.37583h2.5199c3.1918 0 3.1918.00001 4.5357 4.46376l.216.71989h1.7279V.360043H29.0516zm-28.365724 0V1.94386l.719884.14406c2.30391.45595 2.30391.45595 2.30391 2.59182V19.0786c0 2.0398 0 2.0398-2.735882 2.5918l-.719882.1441v1.5838H20.2684l.7199-7.1994h-1.7279l-.3601.8639c-1.7278 4.1757-1.7278 4.1757-4.6795 4.1757H9.32507c-2.15985 0-2.15985 0-2.15985-2.1599V12.455h2.0158c2.85578 0 2.85578 0 3.31168 2.3039l.1441.7199h1.5838V7.55948h-1.5838l-.1441.71988c-.432 2.15984-.432 2.15984-3.31168 2.15984h-2.0158V2.37583h6.33548c3.4078 0 3.4078 0 4.5357 3.74368l.216.71988h1.7279V.360043H.685876z" fill="#0B1130" />
    </svg>
  ),
}
