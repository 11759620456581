/* eslint-disable max-len */
const programName = 'Andlight'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 694.35 82.57">
      <path d="M163.53,52.44V1H180.6V80.82H164.2L125.68,29.88v51H108.44V1.09h16.32Z" />
      <path d="M604.36,81H586.62V49.73h-36.3V81h-17.9V1.27h17.76V34.32h36.23V1.1h18Z" />
      <path d="M53.24,1.25H34.48L0,81.15H18.66c2.11-5.2,4.17-10.3,6.22-15.37h37C64,70.9,66.05,76,68.1,81H87.48ZM31.05,53.19l12.18-30.7L56,53.19Z" />
      <path d="M488.41,62.09V41h15.53V71.9c-15.76,15-47.76,14-63.6-2.05-15.17-15.43-15.22-41.5-.1-56.89,16.49-16.79,47.13-17.4,64.42-1-3.29,4.2-6.61,8.43-9.9,12.65-19.24-11.33-31.84-11.26-41.38.13a26.14,26.14,0,0,0,.45,33.54C462.17,67.82,476.3,69.47,488.41,62.09Z" />
      <path d="M627.75,1h66.6V16.08H670.26v64.7H652.1V16.4H627.75Z" />
      <path d="M329.08,64.87h34.26V80.58H311.19V1h17.89Z" />
      <path d="M385.61.9H403.4V80.65H385.61Z" />
      <path d="M257.63,2.53c-14.93-2.29-30.48-.46-45.8-.46v78.5c13,0,25.76.54,38.42-.13,20.7-1.09,35.6-15.91,37.43-36.23S277.64,5.59,257.63,2.53ZM244.39,65.58H230V16.45a40.54,40.54,0,0,1,8.58-1.58l1.23-.06.44,0c11.58-.31,21.51,4.93,26.24,13.94a25.76,25.76,0,0,1,2.11,5.54c.12.49.24,1,.33,1.51C272.09,52.51,261.36,65.57,244.39,65.58Z" />
    </svg>
  ),
}
