/* eslint-disable max-len */
const programName = 'Polarn O. Pyret'
export default {
  programName,
  logo: (
    <svg className="eb" viewBox="0 0 158 13">
      <title>{programName}</title>
      <defs>
        <path id="path-1" d="M7.966.073v12.186H.1V.073h7.866z" />
        <path id="path-3" d="M.114.042h13.284v12.722H.114z" />
      </defs>
      <g id="Top_Logo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(-109 -13)">
        <g transform="translate(109 13)" id="Logo-Copy-2">
          <g id="Group-2">
            <path d="M70.762 8.742v-.298c0-2.568.002-5.136-.005-7.704 0-.188.048-.237.235-.233.653.012 1.307.01 1.96.001.156-.001.22.02.219.202a4064.93 4064.93 0 000 12.097c0 .165-.053.196-.204.193-.42-.01-.842-.01-1.262 0a.401.401 0 01-.344-.155C68.954 10.1 66.543 7.36 64.133 4.62l-.084-.094c-.058.084-.034.167-.034.241-.002 2.583-.005 5.166.003 7.75 0 .21-.06.256-.26.252a65.442 65.442 0 00-1.96-.002c-.141.002-.193-.026-.193-.181.005-4.055.005-8.11 0-12.165 0-.151.044-.188.19-.184.435.009.87.008 1.307 0 .134-.003.217.045.303.143 2.392 2.727 4.788 5.45 7.183 8.174.044.05.09.097.174.188" id="Fill-1" fill="#231F20" />
            <path d="M139.5 6.612c0-1.972.003-3.943-.003-5.915 0-.17.035-.225.218-.224 2.134.007 4.268.006 6.402 0 .176 0 .228.041.224.221a42.26 42.26 0 000 1.67c.003.163-.048.198-.203.198-1.334-.006-2.669 0-4.004-.009-.193-.001-.233.053-.23.237.01.752.014 1.505-.002 2.257-.005.212.066.239.251.238 1.267-.008 2.534-.001 3.8-.008.163 0 .22.03.216.207a39.817 39.817 0 000 1.67c.003.165-.033.215-.208.213-1.274-.008-2.549 0-3.823-.009-.188-.001-.237.044-.235.233.01.956.01 1.912 0 2.868 0 .173.039.224.22.223 1.328-.008 2.655 0 3.982-.009.185 0 .242.04.237.232a42.31 42.31 0 00-.002 1.671c.003.15-.043.186-.19.186a1160.15 1160.15 0 00-6.469.002c-.183 0-.183-.08-.182-.214.003-1.98.002-3.959.002-5.938" id="Fill-3" fill="#231F20" />
            <path d="M114.005.476c.93 0 1.818.004 2.706-.003.128-.001.164.078.213.158l2.39 3.865.155.247 1.234-2.033c.416-.686.836-1.37 1.242-2.061.081-.138.17-.177.32-.176.864.007 1.729.003 2.598.003.002.104-.066.16-.109.225a3152.77 3152.77 0 01-4.029 6.236.689.689 0 00-.115.398c.004 1.731 0 3.463.007 5.194.001.187-.043.238-.231.234-.65-.012-1.3-.011-1.95 0-.177.003-.22-.047-.22-.223.007-1.716.003-3.432.007-5.148 0-.18-.04-.33-.137-.484-1.315-2.061-2.623-4.126-3.933-6.19-.043-.068-.084-.137-.148-.242" id="Fill-5" fill="#231F20" />
            <g id="Group-9" transform="translate(149.41 .236)">
              <mask id="mask-2" fill="#fff">
                <use href="#path-1" />
              </mask>
              <path d="M5.219 7.194c0 1.612-.004 3.224.005 4.836 0 .192-.055.232-.237.229a51.869 51.869 0 00-1.946 0c-.186.004-.216-.059-.215-.226.004-3.21.001-6.418.008-9.627 0-.208-.036-.278-.262-.273-.76.016-1.52.003-2.281.01-.142 0-.194-.03-.191-.184C.11 1.385.107.81.1.236.1.108.139.073.266.073c2.513.004 5.026.004 7.539 0 .126 0 .162.034.16.16-.006.583-.008 1.165.002 1.747.003.155-.062.162-.183.161-.783-.004-1.566.004-2.349-.006-.18-.003-.22.05-.219.223.006 1.612.004 3.224.004 4.836" id="Fill-7" fill="#231F20" mask="url(#mask-2)" />
            </g>
            <path d="M25.97 6.595c0-1.957.003-3.914-.006-5.87-.001-.213.062-.256.258-.252.626.012 1.252.012 1.878 0 .195-.004.258.037.257.251-.008 3.229-.002 6.457-.012 9.685 0 .238.067.277.282.275 1.17-.011 2.34-.003 3.51-.01.152 0 .203.032.2.195a45.5 45.5 0 000 1.693c.002.154-.032.202-.193.202-1.99-.007-3.98-.007-5.97 0-.166 0-.21-.039-.209-.21.007-1.986.004-3.973.004-5.96" id="Fill-10" fill="#231F20" />
            <path d="M98.19 11.334a1.402 1.402 0 01-1.4 1.43 1.408 1.408 0 01-1.432-1.425 1.418 1.418 0 011.427-1.412 1.409 1.409 0 011.406 1.407" id="Fill-12" fill="#231F20" />
            <path d="M4.072 6.07c-.524.082-1.052.04-1.578.056-.149.004-.123-.091-.123-.179V4.345c0-.534.004-1.068-.003-1.602-.002-.144.032-.194.183-.191.527.01 1.054-.01 1.576.086.966.178 1.45.753 1.44 1.722-.01.96-.537 1.561-1.495 1.71m3.86-2.538C7.606 2.022 6.744 1.003 5.227.628 4.749.51 4.258.478 3.767.477 2.511.472 1.256.474 0 .473v12.285c.706 0 1.412-.012 2.117.005.226.006.26-.066.259-.273-.01-1.347-.001-2.695-.01-4.042-.001-.195.04-.25.241-.25.816-.005 1.636.067 2.446-.092 1.32-.258 2.3-.945 2.738-2.28a4.346 4.346 0 00.14-2.294" id="Fill-14" fill="#231F20" />
            <path d="M88.623 10.684a3.941 3.941 0 01-1.051.136c-2.057-.004-3.76-1.513-4.08-3.615-.311-2.04.981-4.063 2.956-4.628 2.314-.662 4.733.837 5.167 3.2.407 2.224-.871 4.32-2.992 4.907m3.399-8.822C90.432.482 88.553.027 86.479.322c-3.18.454-5.535 3.159-5.519 6.332.005 1.259.312 2.439 1.039 3.474 1.587 2.262 3.83 3.133 6.527 2.805 3.234-.393 5.509-2.963 5.644-5.977.092-2.028-.604-3.755-2.148-5.094" id="Fill-16" fill="#231F20" />
            <g id="Group-20" transform="translate(9.913)">
              <mask id="mask-4" fill="#fff">
                <use href="#path-3" />
              </mask>
              <path d="M7.798 10.449a4.03 4.03 0 01-1.032.13c-2.062-.002-3.84-1.56-4.096-3.59-.26-2.057.907-3.943 2.826-4.567 2.33-.759 4.845.715 5.309 3.11.43 2.222-.865 4.34-3.007 4.917m3.543-8.673C9.596.194 7.501-.257 5.204.226c-4.033.85-6.248 5.22-4.47 8.882 1.198 2.468 3.324 3.543 6.022 3.656a7.278 7.278 0 002.126-.33c4.708-1.486 6.08-7.375 2.459-10.658" id="Fill-18" fill="#231F20" mask="url(#mask-4)" />
            </g>
            <path d="M131.483 6.028c-.501.122-1.011.091-1.52.1-.14.002-.107-.092-.107-.167V2.578c0-.076-.031-.167.11-.164.5.011 1.001-.024 1.497.089.931.21 1.417.794 1.422 1.723.005.951-.485 1.578-1.402 1.802m1.104 1.588c.016-.027.019-.036.023-.037.08-.021.16-.04.24-.062 1.108-.307 1.923-.949 2.307-2.069.195-.571.235-1.165.181-1.76-.113-1.24-.703-2.167-1.835-2.717-.722-.35-1.496-.48-2.287-.49-1.191-.014-2.383 0-3.575-.008-.153-.001-.182.048-.181.19.003 3.966.003 7.932 0 11.898 0 .151.029.204.192.202a63.56 63.56 0 012.001 0c.173.003.207-.047.206-.212-.007-1.505-.004-3.01-.004-4.515 0-.194.116-.274.29-.2.073.032.1.109.14.17 1.027 1.526 2.052 3.053 3.077 4.579.06.09.104.18.252.178.921-.009 1.843-.004 2.815-.004l-3.842-5.143" id="Fill-21" fill="#231F20" />
            <path d="M53.559 6.033c-.489.115-.983.083-1.477.094-.135.003-.115-.08-.115-.16V4.25c0-.55.004-1.099-.003-1.648-.002-.14.023-.196.18-.191.47.016.943-.018 1.41.088.953.215 1.438.795 1.439 1.734 0 .963-.496 1.58-1.434 1.8m1.115 1.555c.114-.028.2-.047.284-.071 1.109-.309 1.923-.95 2.307-2.07a4.16 4.16 0 00.19-1.671c-.1-1.326-.722-2.299-1.947-2.853-.687-.31-1.417-.432-2.163-.441-1.198-.016-2.398-.004-3.597-.01-.13 0-.18.021-.18.17.004 3.981.003 7.962 0 11.944 0 .136.033.178.173.176a71.87 71.87 0 012.024.002c.174.002.206-.052.205-.215-.006-1.505-.004-3.01-.003-4.516 0-.197.084-.258.271-.202.082.025.106.101.146.161l3.091 4.597c.05.075.077.173.208.172.936-.005 1.872-.002 2.854-.002l-3.863-5.17" id="Fill-23" fill="#231F20" />
            <path d="M42.166 8.244c-1.08-.01-2.16-.005-3.275-.005l1.769-4.36c.083.046.084.127.11.19.515 1.317 1.025 2.636 1.543 3.951.068.173.058.225-.147.224m4.54 4.364c-.103-.225-.19-.457-.284-.685-1.58-3.828-3.16-7.656-4.735-11.486-.06-.146-.13-.205-.293-.2-.466.013-.932.013-1.397 0-.167-.005-.232.06-.292.202-1.512 3.556-3.028 7.111-4.542 10.666-.231.542-.46 1.086-.702 1.655.807 0 1.585-.005 2.364.004.145.001.208-.05.262-.177.312-.746.635-1.488.946-2.235.056-.135.118-.193.276-.193 1.547.007 3.094.007 4.642 0 .154 0 .22.05.275.191.296.753.604 1.5.908 2.25.037.092.069.165.2.163.759-.007 1.517-.006 2.276-.001.138 0 .149-.037.095-.154" id="Fill-25" fill="#231F20" />
            <path d="M108.414 6.072c-.524.083-1.052.041-1.578.056-.146.004-.125-.088-.125-.177V4.35c0-.526.005-1.052-.004-1.578-.002-.16.028-.223.205-.218.519.013 1.038-.007 1.553.087.974.178 1.452.753 1.439 1.743-.013.945-.539 1.54-1.49 1.688m3.9-2.317c-.166-1.463-1.2-2.706-2.574-3.082-.535-.146-1.083-.19-1.634-.193-1.182-.005-2.363.002-3.545-.007-.18-.002-.234.037-.233.23.008 1.971.005 3.942.004 5.914 0 1.979.003 3.958-.004 5.937 0 .17.043.212.209.209a58.27 58.27 0 011.961 0c.181.004.217-.053.216-.224-.006-1.362 0-2.724-.008-4.086 0-.191.035-.252.24-.253.817-.004 1.637.068 2.447-.091 1.087-.213 1.984-.716 2.514-1.744.422-.82.51-1.7.407-2.61" id="Fill-27" fill="#231F20" />
          </g>
        </g>
      </g>
    </svg>
  ),
}
