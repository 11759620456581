/* eslint-disable max-len */
const programName = 'Cocopanda'
export default {
  programName,
  logo: (
    <svg className="cocopanda" x="0px" y="0px" viewBox="0 0 480 140">
      <title>{programName}</title>
      <g>
        <path
          className="st0"
          d="M88.1,2.3c5.5,4.8,11.5,23.4-7,36.4c-18.6,13.2-51.4,8.5-62.7,28.2c-12.4,21.7,6.2,30.7,6,30.5
        c-3.6-3.1-6-16.5,5.3-24.6c1.7-1.2,3.3-2.3,4.8-3.2c-2,1.9-3.8,4.3-5.5,7.4c-11.2,20.8,13.1,32.8,12.9,32.6
        c-3.9-2.2-14.3-12.7-6.3-26c7.2-12,15.9-15,30.7-16.3c22.8-2.1,33.3-19.7,36.6-26.6c0.7,2.2,2,6.2-3.2,15.5c-1.2,2,0.4,5.4,0.4,5.4
        l2.5,5c0,0,0.8,1.7-0.9,2.3c-1.7,0.6-3.4,1.2-3.7,2.9c0.6,0.9,1.5,1.4-0.4,2.1c-0.9,0.3-1.2,0.3-1.2,0.3s1.6,1.3,0.7,2.2
        c-0.7,0.5-1.5,0.1-2.1,1.1c-0.5,1.1-1.2,4.7-3.6,5C86.8,83.1,77,75.1,77,75.1c-12.2,7.5-8.7,26.8-4.4,38.6
        c5.7,15.6,19,24.4,18.8,24.1c-11.7-15.3-2.3-29,6.5-35.3c8.7-6.2,13.8-19.3,12.1-30.5c-1.6-11-0.8-11.1,4.1-21.6
        C123.4,31.3,87.3,1.6,88.1,2.3z"
        />
        <g>
          <path
            className="st1"
            d="M161,94.6c-3.5,3-8,4.6-13.6,4.6c-7.2,0-12.7-2.6-16.3-7.9c-3.1-4.5-4.7-10.6-4.7-18.2s1.6-13.8,4.9-18.5
            c3.7-5.3,9.2-8,16.6-8c5,0,9.3,1.4,12.7,4.1c2.5,2,4.2,4.4,5.3,7.2l-2.2,1.1c-2.3-6.9-7.7-10.4-16.2-10.4
            c-6.3,0-11.1,2.6-14.4,7.8c-2.8,4.4-4.1,9.9-4.1,16.6s1.3,12.2,4,16.4c3.2,5.2,7.9,7.7,14.2,7.7c5.1,0,9.2-1.4,12.3-4.1
            c2.4-2.1,4.1-4.8,4.8-8.2l2,1.1C165.4,89.4,163.6,92.3,161,94.6z"
          />
          <path
            className="st1"
            d="M175.2,66.7c2.8-3.8,6.9-5.8,12.2-5.8s9.4,1.9,12.3,5.8c2.5,3.4,3.8,7.8,3.8,13.4s-1.3,10-3.8,13.4
            c-2.9,3.7-7,5.6-12.3,5.6s-9.4-1.9-12.2-5.6c-2.5-3.4-3.8-7.9-3.8-13.4S172.6,70,175.2,66.7z M176.8,91.9c2.4,3.5,6,5.3,10.6,5.3
            s8.2-1.8,10.7-5.3c2.1-3,3.2-7,3.2-11.9s-1.1-8.8-3.2-11.9c-2.5-3.5-6-5.3-10.7-5.3s-8.2,1.8-10.6,5.3c-2.1,3-3.2,7-3.2,11.9
            C173.6,84.9,174.7,88.9,176.8,91.9z"
          />
          <path
            className="st1"
            d="M235.4,95.8c-2.6,2.2-5.9,3.3-9.9,3.3c-5.2,0-9.3-1.9-12.1-5.8c-2.5-3.5-3.7-7.9-3.7-13.3
            c0-5.5,1.3-10,3.8-13.4c2.8-3.8,6.9-5.8,12.2-5.8c6.6,0,10.9,2.6,13,7.9l-1.9,1.3c-0.6-1.8-1.7-3.3-3.4-4.7
            c-2.1-1.7-4.7-2.5-7.7-2.5c-4.7,0-8.2,1.8-10.6,5.3c-2.1,3-3.2,7-3.2,11.9s1.1,8.9,3.2,11.9c2.4,3.5,6,5.3,10.6,5.3
            c2.9,0,5.4-0.8,7.5-2.4s3.5-3.8,4.3-6.6l1.9,1.1C238.5,92,237.2,94.1,235.4,95.8z"
          />
          <path
            className="st1"
            d="M247.5,66.7c2.8-3.8,6.9-5.8,12.2-5.8c5.3,0,9.4,1.9,12.3,5.8c2.5,3.4,3.8,7.8,3.8,13.4s-1.3,10-3.8,13.4
            c-2.9,3.7-7,5.6-12.3,5.6c-5.3,0-9.4-1.9-12.2-5.6c-2.5-3.4-3.8-7.9-3.8-13.4S245,70,247.5,66.7z M249.1,91.9
            c2.4,3.5,6,5.3,10.6,5.3c4.6,0,8.2-1.8,10.7-5.3c2.1-3,3.2-7,3.2-11.9s-1.1-8.8-3.2-11.9c-2.5-3.5-6-5.3-10.7-5.3
            c-4.7,0-8.2,1.8-10.6,5.3c-2.1,3-3.2,7-3.2,11.9C246,84.9,247,88.9,249.1,91.9z"
          />
          <path
            className="st1"
            d="M282.4,61.7h2.2v8.4c2.4-6.1,7-9.1,13.8-9.1c5.4,0,9.5,1.9,12.3,5.7c2.5,3.4,3.8,7.8,3.8,13.4
            c0,5.5-1.3,10-3.8,13.4c-2.9,3.7-7,5.6-12.3,5.6c-6.8,0-11.4-2.9-13.8-8.8v22.3h-2.2V61.7z M287.8,91.9c2.4,3.5,6,5.3,10.6,5.3
            s8.2-1.8,10.7-5.3c2.1-3,3.2-7,3.2-11.9s-1.1-8.8-3.2-11.9c-2.5-3.5-6-5.3-10.7-5.3s-8.2,1.8-10.6,5.3c-2.1,3-3.2,7-3.2,11.9
            S285.7,88.9,287.8,91.9z"
          />
          <path
            className="st1"
            d="M337.1,99.1c-5.3,0-9.4-1.9-12.3-5.6c-2.5-3.4-3.8-7.9-3.8-13.4c0-5.6,1.3-10.1,3.8-13.4
            c2.8-3.8,6.9-5.7,12.3-5.7c6.8,0,11.4,3,13.8,9.1v-8.4h2.2v36.5h-2.2v-7.9C348.5,96.1,343.9,99.1,337.1,99.1z M326.4,91.9
            c2.4,3.5,6,5.3,10.7,5.3s8.2-1.8,10.6-5.3c2.1-3,3.2-7,3.2-11.9s-1.1-8.8-3.2-11.9c-2.4-3.5-5.9-5.3-10.6-5.3s-8.2,1.8-10.6,5.3
            c-2.2,3-3.2,7-3.2,11.9C323.2,84.9,324.3,88.9,326.4,91.9z"
          />
          <path
            className="st1"
            d="M361.4,61.7h2.2v6c2.3-4.5,6.4-6.7,12.3-6.7c8.3,0,12.5,4.4,12.5,13.1v24.2h-2.2V74.7c0-3.9-0.7-6.7-2.1-8.6
            c-1.6-2.1-4.3-3.2-8.1-3.2c-6.9,0-10.9,3.1-12,9.3c-0.1,0.7-0.2,1.6-0.2,2.6c-0.1,1.1-0.1,2.3-0.1,3.7V98h-2.2L361.4,61.7
            L361.4,61.7z"
          />
          <path
            className="st1"
            d="M427,98.1h-2.2V90c-2.4,6-7,9-13.8,9c-5.4,0-9.5-1.9-12.3-5.6c-2.5-3.4-3.8-7.8-3.8-13.4
            c0-5.5,1.3-10,3.8-13.4c2.9-3.8,7-5.8,12.3-5.8c6.9,0,11.5,2.9,13.8,8.8V47.7h2.2V98.1z M421.7,68.1c-2.4-3.5-5.9-5.3-10.6-5.3
            s-8.2,1.8-10.6,5.3c-2.2,3-3.2,7-3.2,11.9s1.1,8.9,3.2,11.9c2.4,3.5,6,5.3,10.7,5.3s8.2-1.8,10.6-5.3c2.1-3,3.2-7,3.2-11.9
            C424.9,75.1,423.8,71.2,421.7,68.1z"
          />
          <path
            className="st1"
            d="M449.9,99.1c-5.3,0-9.4-1.9-12.3-5.6c-2.5-3.4-3.8-7.9-3.8-13.4c0-5.6,1.3-10.1,3.8-13.4
            c2.8-3.8,6.9-5.7,12.3-5.7c6.8,0,11.4,3,13.8,9.1v-8.4h2.2v36.5h-2.2v-7.9C461.3,96.1,456.7,99.1,449.9,99.1z M439.2,91.9
            c2.4,3.5,6,5.3,10.7,5.3s8.2-1.8,10.6-5.3c2.1-3,3.2-7,3.2-11.9s-1.1-8.8-3.2-11.9c-2.4-3.5-5.9-5.3-10.6-5.3s-8.2,1.8-10.6,5.3
            c-2.2,3-3.2,7-3.2,11.9C436,84.9,437.1,88.9,439.2,91.9z"
          />
        </g>
      </g>
    </svg>
  ),
}
