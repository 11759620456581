/* eslint-disable max-len */
const programName = 'Grandpa'
export default {
  programName,
  logo: (
    <svg className="grandpa" x="0px" y="0px" viewBox="0 0 179 23">
      <title>{programName}</title>
      <g id="Layer_2">
        <g id="Lager_1">
          <path
            className="st0"
            d="M20.6,4.6l-2.4,2.2C12.7,3.3,8.3,3.6,6,7.5C3.8,11,4.9,15.5,8.4,17.7c3.5,2.1,7.1,1.2,9.2-2.5h-5.4v-3.7h9.7
            c0.7,5.2-1.9,9.2-6.6,10.5C10,23.8,4.3,21.3,2,16.2C-0.3,11.2,1.4,5,5.8,2.1S16.7,0.2,20.6,4.6z"
          />
          <path
            className="st0"
            d="M88.1,1.1l8.1,12.5l0.6-0.1v-12c1.3-0.2,2.1-0.1,3.7-0.2v20.6h-3.4l-8-12.3l-0.7,0.1v12.2l-3.7,0.2V1.2
            C85.5,1.2,86.7,1.2,88.1,1.1z"
          />
          <path
            className="st0"
            d="M112.4,1.1c5,0.2,10.2-1,13.7,4c2.7,4,2.8,8.5,0.6,12.7c-2,3.9-6.3,5-14.3,3.9L112.4,1.1z M116.1,18
            c2.9,0.5,5.4,0.6,7.2-1.9c1.5-2.1,1.5-6.3,0-8.6c-1.7-2.5-4.2-2.9-7.1-2.5L116.1,18L116.1,18z"
          />
          <path
            className="st0"
            d="M41.6,12.8c1.4,2.8,3.1,5.9,4.7,9.2c-1.6,0.1-2.8,0-4.1,0.1l-4.5-8.2h-0.5v8c-1.5,0.1-2.6,0-4,0.1V1.1
            c1.8,0.3,8.6-1.5,11.3,3.2c1.1,2.2,1,4.7-0.4,6.7C43.5,11.8,42.6,12.4,41.6,12.8z M37.3,9.9c2,0,3.9,0.3,4.5-1.7
            c0.3-1.3-0.1-2.9-1.9-3h-2.6C37.3,6.5,37.3,8.2,37.3,9.9L37.3,9.9z"
          />
          <path
            className="st0"
            d="M70.2,17.9c-0.7-0.1-1.2-0.2-1.8-0.2c-2,0-3.9,0-6.2,0l-2,4.2h-3.9l7.6-20.6c1.2-0.1,2.5,0,4.1-0.1L76,22
            h-3.8L70.2,17.9z M68.7,13.7l-2.2-6.1h-0.7l-2.3,6.1H68.7z"
          />
          <path
            className="st0"
            d="M172,17.7c-0.5,0-1.1,0-1.6,0c-2,0-3.9,0-6.2,0l-2,4.2h-3.9l7.6-20.6c1.2-0.1,2.5,0,4.1-0.1l8,20.8h-3.8
            C173.5,20.5,172.7,19,172,17.7z M170.7,13.7l-2.2-6.2h-0.7l-2.3,6.2H170.7z"
          />
          <path
            className="st0"
            d="M149.9,4.3c-2.3-4.5-7.2-3.3-11.2-3.4V22c1.4-0.1,2.5,0,4-0.1v-8h0.5c1.4-0.3,2.2-0.2,3.9-0.9
            C150.7,11.5,151.2,6.7,149.9,4.3z M142.5,9.9V5.3h2.5c1.3,0,2,0.5,1.9,2.4C147,10.4,143.9,9.9,142.5,9.9L142.5,9.9z"
          />
        </g>
      </g>
    </svg>
  ),
}
