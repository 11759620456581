/* eslint-disable max-len */
const programName = 'BGA'
export default {
  programName,
  logo: (
    <svg x="0px" y="0px" viewBox="0 0 500 500">
      <title>{programName}</title>
      <g>
        <rect x="48.5" y="58.1" className="st0" width="383.8" height="38.4" />
        <polygon className="st0" points="48.5,403.5 182.8,403.5 168,441.9 48.5,441.9" />
        <path
          className="st0"
          d="M322.9,173.2h61.4l-0.3,31.7c-10,9-28.6,17.6-49.7,17.6c-41.8,0-72.1-32.1-72.1-75.6c0-4.2,0.5-8.1,1.1-12.1
                    h-50.9c-0.3,3.9-0.6,8-0.6,12.1c0,72.5,55.2,119.4,122.5,119.4c41.8,0,74.5-17.3,98-43.5v-88H322.6L322.9,173.2z"
        />
        <polygon
          className="st0"
          points="400.4,309.3 352,309.3 370.1,359.9 294,359.9 312.3,309.3 263.9,309.3 212.6,441.9 266.4,441.9
                    280.9,402.2 383.6,402.2 398.1,441.9 451.5,441.9"
        />
        <path
          className="st0"
          d="M146.6,246.3c21.7-4.5,39.7-23.8,39.7-52.8c0-30.7-22.4-58.7-66.3-58.7H48.5v38.4h57.6
                    c16.6,0,30.1,14.4,30.1,28.6c0,14.8-13.6,29-30.1,29H48.5v38.3H111c19.3,0,29.7,12.4,29.7,26.9c0,16.6-6.4,30.7-25,30.7H48.5v38.3
                    h75.3c43.8,0,66.9-27.6,66.9-62.1C190.7,273.9,171.1,250.1,146.6,246.3z"
        />
      </g>
    </svg>
  ),
}
