/* eslint-disable max-len */
const programName = 'Värmepumpshopen'
export default {
  programName,
  logo: (
    <svg className="varmepumpshopen" x="0px" y="0px" viewBox="0 0 415.5 220.4">
      <title>{programName}</title>
      <g>
        <path
          id="Path_164"
          className="st0"
          d="M37.6,144.1h40.8c5.2,0,9.3,4.2,9.3,9.3v2.1c0,5.2-4.2,9.3-9.3,9.3l0,0H9.3
                    c-5.2,0-9.3-4.2-9.3-9.3l0,0v-0.7c0-1,0.3-2,0.9-2.7l48.3-62.2c0.5-0.7,0.4-1.7-0.3-2.2c-0.3-0.2-0.6-0.3-1-0.3H10.4
                    c-5.2,0-9.3-4.2-9.3-9.3V76c0-5.2,4.2-9.3,9.3-9.3l0,0h69.4c2.5,0,4.5,2,4.5,4.5l0,0v7.2c0,1-0.3,2-0.9,2.7l-46.9,60.6
                    c-0.5,0.7-0.4,1.7,0.3,2.2C36.9,144,37.2,144.1,37.6,144.1z"
        />
        <path
          id="Path_165"
          className="st0"
          d="M195.6,74.8v82.5c0,4.1-3.3,7.5-7.5,7.5h-10.3c-3.4,0-6.1-2.7-6.1-6.1l0,0l0,0
                    c0-0.4-0.3-0.7-0.7-0.7c-0.2,0-0.3,0-0.4,0.1c-8.9,6.4-19.6,9.8-30.6,9.8c-28.4,0-47.5-21-47.5-52.7c0.2-31.1,17.9-51.7,49.2-51.7
                    c15.9,0,31.8,1.3,47.5,3.8C192.9,68,195.6,71.1,195.6,74.8z M116,115.9c0,18.9,10.6,31.9,27.4,31.9c10-0.1,19.6-3.5,27.4-9.8
                    c0.7-0.5,1.1-1.3,1.1-2.1V87c0-0.9-0.7-1.7-1.6-1.8c-8.3-1-16.6-1.5-25-1.5C124.1,83.7,116,97.4,116,115.9L116,115.9z"
        />
        <path
          id="Path_166"
          className="st1"
          d="M245.5,159.3l-35.1-79.4c-2.1-4.7,0.1-10.2,4.8-12.3c1.2-0.5,2.5-0.8,3.8-0.8h5.7
                    c3.7,0,7.1,2.2,8.6,5.6l25.5,59c0.5,1.2,1.9,1.8,3.1,1.2c0.6-0.2,1-0.7,1.2-1.2l44.1-103c1.5-3.4,4.8-5.7,8.6-5.7h5.8
                    c5.2,0,9.3,4.2,9.3,9.3c0,1.3-0.3,2.6-0.8,3.8L276,159.3c-1.5,3.4-4.8,5.5-8.5,5.5h-13.4C250.4,164.8,247,162.7,245.5,159.3z"
        />
        <path
          id="Path_167"
          className="st0"
          d="M415.5,106.8c0,4.3-0.1,7-0.3,9.6c-0.4,4.8-4.5,8.5-9.3,8.5h-62.4c-0.9,0-1.6,0.7-1.6,1.6
                    c0,0.2,0,0.3,0.1,0.5c4.1,12.1,14.6,19.7,30.5,19.7c8.7-0.3,17.3-1.4,25.8-3.4c5.1-1,10,2.2,11,7.3c0,0.1,0,0.2,0.1,0.3l0.3,1.7
                    c0.8,4.7-2.1,9.3-6.8,10.6c-10.1,3-20.5,4.7-31,4.9c-36.9,0-56-22.8-56-52.3c0-31.9,22.2-52.3,51.7-52.3
                    C395.1,63.4,415.1,80.6,415.5,106.8z M342.4,106.5h49.8c0.9,0,1.6-0.7,1.6-1.5v-0.1c-1.3-13.8-11.7-22.6-25.8-22.6
                    c-13.4-0.5-25,9.2-27,22.4c-0.2,0.8,0.4,1.7,1.3,1.8H342.4L342.4,106.5z"
        />
      </g>
    </svg>
  ),
}
