/* eslint-disable max-len */
const programName = 'Webhallen'
export default {
  programName,
  logo: (
    <svg className="webbhallen" viewBox="0 0 392.93 111.54">
      <title>{programName}</title>
      <path d="M15.78,55.74a7.89,7.89,0,1,1-7.89-7.89h0a7.89,7.89,0,0,1,7.89,7.89" />
      <path d="M63.63,5.52A5.53,5.53,0,1,1,58.09,0h0a5.53,5.53,0,0,1,5.52,5.52" />
      <path d="M63.63,106a5.53,5.53,0,1,1-5.54-5.52h0A5.52,5.52,0,0,1,63.63,106" />
      <path d="M27.34,86.5a6.71,6.71,0,1,1-9.49,0h0a6.72,6.72,0,0,1,9.48,0" />
      <path d="M96.68,17.16a4.34,4.34,0,1,1-6.14,0,4.35,4.35,0,0,1,6.14,0" />
      <path d="M96.68,88.17a4.34,4.34,0,1,1-6.14,0,4.35,4.35,0,0,1,6.14,0" />
      <path d="M19,75a7.3,7.3,0,1,1-7.3-7.3A7.3,7.3,0,0,1,19,75h0" />
      <path d="M44.54,7a6.12,6.12,0,1,1-8-3.31,6.13,6.13,0,0,1,8,3.31h0" />
      <path d="M81.88,100.24a4.93,4.93,0,1,1-6.45-2.67,4.93,4.93,0,0,1,6.45,2.67h0" />
      <path d="M41.23,96.48a6.12,6.12,0,1,1-8,3.31,6.13,6.13,0,0,1,8-3.31h0" />
      <path d="M79.21,4.79a4.93,4.93,0,1,1-6.45,2.67,4.94,4.94,0,0,1,6.45-2.67" />
      <path d="M11.71,29.22a7.3,7.3,0,1,1-7.3,7.3,7.3,7.3,0,0,1,7.3-7.3" />
      <path d="M27.34,15.49a6.7,6.7,0,1,1-9.48,0,6.71,6.71,0,0,1,9.48,0h0" />
      <path d="M130.51,56.44c0-11.19-8.39-20.68-20.25-20.68-12.8,0-20.85,9.66-20.85,20.93,0,11.78,9.33,20.85,21.44,20.85,6.87,0,13.31-3.05,17-8.22l-7.12-5.68a12.49,12.49,0,0,1-9.91,4.41c-6,0-10-3.48-11.19-8.31h30.68A21.43,21.43,0,0,0,130.51,56.44Zm-30.59-4a10.66,10.66,0,0,1,10.25-7.21c5,0,9.24,3.14,9.83,7.21Z" />
      <path d="M157.38,35.76c-3.3,0-8.9.94-11.78,5.09V19.58H135.18v57.2H145.6V73.39a19,19,0,0,0,11.53,4.15,20.8,20.8,0,0,0,21-20.51C178.14,45.59,169.24,35.76,157.38,35.76Zm-.59,31.78c-6.19,0-11.19-4.41-11.19-10.51a11,11,0,0,1,10.72-11.27h.47A11.34,11.34,0,0,1,167.89,57c0,5.29-4.83,10.54-11.1,10.54Z" />
      <path d="M205.38,35.76c-4.58,0-9,1.36-11.78,5.68V19.58H183.09v57.2h10.76v-22c0-6,3.9-9.07,8.47-9.07,4.92,0,7.8,3.22,7.8,9v22h10.76V51.27c0-9.83-6.52-15.51-15.5-15.51" />
      <path d="M257.51,36.53v4.91c-2.46-3.73-6.11-5.68-12.46-5.68-9.32,0-19.57,8.56-19.57,21.19s10.42,20.59,19.57,20.59c5.93,0,9.41-1.86,12.2-4.92v4.16H268V36.53Zm-10.68,31c-6.1,0-11.1-5-11.1-10.59A11.14,11.14,0,1,1,258,56.2q0,.33,0,.66c0,5.68-5,10.68-11.17,10.68Z" />
      <rect x="275.49" y="19.58" width="10.76" height="57.2" />
      <rect x="293.65" y="19.58" width="10.76" height="57.2" />
      <path d="M350.47,56.44c0-11.19-8.39-20.68-20.25-20.68-12.8,0-20.85,9.66-20.85,20.93,0,11.78,9.32,20.85,21.44,20.85,6.86,0,13.3-3.05,16.95-8.22l-7.12-5.68a12.5,12.5,0,0,1-9.92,4.41c-6,0-10-3.48-11.18-8.31h30.67A21.39,21.39,0,0,0,350.47,56.44Zm-30.59-4a10.66,10.66,0,0,1,10.25-7.21c5,0,9.24,3.14,9.83,7.21Z" />
      <path d="M377.42,35.76c-4.57,0-9,1.36-11.78,5.68V36.53h-10.5V76.78H365.9v-22c0-6,3.9-9.07,8.47-9.07,4.92,0,7.8,3.22,7.8,9v22h10.76V51.27c0-9.83-6.53-15.51-15.51-15.51" />
      <polygon points="38.53 76.78 49.38 76.78 58.1 51.1 67.17 76.78 77.43 76.78 89.22 36.53 77.44 36.53 71.92 60.94 62.68 36.53 53.53 36.53 44.04 60.94 38.6 36.53 26.91 36.53 38.53 76.78" />
    </svg>
  ),
}
