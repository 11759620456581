/* eslint-disable max-len */
const programName = 'Soffadirekt'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 180 30">
      <title>{programName}</title>
      <g>
        <path
          className="st0"
          d="M149.214,25.063c-0.136,0.353-0.353,0.652-0.598,0.896c-0.244,0.244-0.543,0.462-0.896,0.598
c-0.353,0.136-0.706,0.217-1.087,0.217s-0.761-0.081-1.086-0.217c-0.353-0.136-0.652-0.326-0.896-0.598
c-0.244-0.244-0.462-0.543-0.598-0.896c-0.136-0.353-0.217-0.706-0.217-1.086s0.081-0.761,0.217-1.086
c0.136-0.353,0.353-0.652,0.598-0.896c0.244-0.244,0.543-0.462,0.896-0.598c0.353-0.136,0.706-0.217,1.086-0.217
s0.761,0.081,1.087,0.217c0.353,0.136,0.652,0.353,0.896,0.598c0.244,0.244,0.462,0.543,0.598,0.896s0.217,0.706,0.217,1.086
C149.431,24.33,149.377,24.737,149.214,25.063L149.214,25.063z M157.091,16.914c0.38,0.163,0.842,0.326,1.44,0.462
c0.57,0.136,1.195,0.272,1.874,0.435c0.652,0.163,1.277,0.38,1.874,0.706c0.57,0.326,1.059,0.761,1.44,1.331
c0.38,0.57,0.57,1.304,0.57,2.227c0,0.951-0.217,1.738-0.625,2.363c-0.407,0.625-0.951,1.114-1.603,1.494s-1.385,0.625-2.2,0.788
c-0.815,0.163-1.63,0.217-2.417,0.217c-1.032,0-2.092-0.136-3.151-0.435c-1.059-0.299-1.956-0.815-2.689-1.575l2.771-3.097
c0.435,0.516,0.896,0.924,1.412,1.195c0.516,0.272,1.114,0.407,1.82,0.407c0.516,0,1.005-0.081,1.44-0.217
c0.435-0.163,0.652-0.435,0.652-0.842c0-0.38-0.19-0.679-0.57-0.869c-0.38-0.19-0.842-0.353-1.44-0.489
c-0.57-0.136-1.195-0.272-1.874-0.435c-0.652-0.163-1.277-0.38-1.874-0.679c-0.57-0.299-1.059-0.733-1.44-1.304
c-0.38-0.57-0.57-1.304-0.57-2.227c0-0.869,0.19-1.63,0.543-2.254c0.353-0.625,0.815-1.141,1.412-1.548
c0.57-0.407,1.249-0.706,2.01-0.896c0.761-0.19,1.521-0.299,2.282-0.299c0.978,0,1.956,0.136,2.961,0.435
c1.005,0.272,1.847,0.788,2.526,1.521l-2.798,2.771c-0.679-0.842-1.548-1.249-2.662-1.249c-0.38,0-0.761,0.081-1.141,0.272
c-0.353,0.19-0.543,0.489-0.543,0.951C156.521,16.453,156.711,16.724,157.091,16.914L157.091,16.914z M168.88,20.581
c0.19,0.869,0.57,1.575,1.195,2.092c0.598,0.516,1.358,0.788,2.254,0.788c0.761,0,1.385-0.163,1.901-0.489
c0.516-0.326,0.978-0.761,1.358-1.304l3.205,2.336c-0.733,0.924-1.657,1.657-2.771,2.146c-1.114,0.516-2.282,0.761-3.477,0.761
c-1.141,0-2.2-0.19-3.232-0.543c-1.005-0.353-1.874-0.896-2.635-1.575c-0.733-0.679-1.331-1.494-1.766-2.445
c-0.435-0.951-0.652-2.01-0.652-3.205c0-1.168,0.217-2.227,0.652-3.205c0.435-0.951,1.005-1.766,1.766-2.445
c0.733-0.679,1.63-1.195,2.635-1.575c1.005-0.353,2.092-0.543,3.232-0.543c1.059,0,2.01,0.19,2.879,0.543
c0.869,0.353,1.603,0.896,2.2,1.575c0.598,0.679,1.059,1.494,1.385,2.445c0.326,0.951,0.489,2.01,0.489,3.205v1.44L168.88,20.581
L168.88,20.581z M174.149,15.502c-0.516-0.543-1.222-0.815-2.037-0.815c-0.516,0-0.951,0.081-1.331,0.244
c-0.38,0.163-0.706,0.38-1.005,0.625c-0.272,0.244-0.489,0.543-0.652,0.869s-0.244,0.679-0.272,1.059h6.03
C174.937,16.724,174.692,16.099,174.149,15.502L174.149,15.502z"
        />
        <g>
          <g>
            <path
              id="SVGID_4_"
              className="st0"
              d="M91.793,20.31c-0.543,1.358-1.277,2.526-2.227,3.45c-0.951,0.924-2.064,1.63-3.341,2.119
c-1.277,0.462-2.662,0.706-4.129,0.706h-7.877V4.963h6.926c1.684,0,3.232,0.217,4.618,0.625c1.412,0.407,2.608,1.059,3.64,1.901
c1.005,0.869,1.82,1.956,2.363,3.287c0.57,1.331,0.842,2.906,0.842,4.753C92.608,17.403,92.336,18.952,91.793,20.31L91.793,20.31
z M89.674,11.536c-0.462-1.114-1.086-2.01-1.901-2.716s-1.793-1.222-2.934-1.548c-1.141-0.326-2.39-0.489-3.776-0.489h-4.645
v17.873h5.351c1.249,0,2.39-0.19,3.422-0.598c1.059-0.38,1.956-0.978,2.716-1.738s1.358-1.711,1.793-2.879
c0.435-1.141,0.652-2.472,0.652-3.966S90.136,12.65,89.674,11.536L89.674,11.536z M94.129,26.557V10.939h2.119v15.646h-2.119
V26.557z M94.129,4.284h2.119v2.417h-2.119V4.284L94.129,4.284z M105.483,12.433c-0.244-0.027-0.516-0.027-0.788-0.027
c-0.706,0-1.331,0.163-1.847,0.516c-0.543,0.353-0.978,0.815-1.331,1.385c-0.353,0.57-0.625,1.249-0.788,2.01
c-0.163,0.761-0.272,1.548-0.272,2.363v7.904h-2.119V14.796c0-0.353,0-0.733,0-1.168c0-0.435,0-0.842-0.027-1.222
c0-0.38-0.027-0.706-0.027-1.005c0-0.272,0-0.435,0-0.516h1.929c0,0.027,0,0.217,0.027,0.516c0,0.299,0.027,0.652,0.054,1.032
c0,0.38,0.027,0.761,0.027,1.168c0.027,0.38,0.027,0.679,0.027,0.869h0.054c0.217-0.652,0.435-1.195,0.706-1.684
c0.272-0.489,0.598-0.869,0.978-1.195c0.38-0.299,0.815-0.543,1.277-0.706c0.489-0.163,1.032-0.244,1.657-0.244
c0.462,0,0.815,0.054,1.086,0.163v1.711C105.944,12.46,105.727,12.433,105.483,12.433L105.483,12.433z M107.737,19.169
c0,0.869,0.109,1.657,0.326,2.417c0.217,0.761,0.543,1.385,0.978,1.929s1.005,0.978,1.711,1.277
c0.679,0.326,1.494,0.489,2.472,0.489c1.222,0,2.227-0.272,3.069-0.788c0.842-0.516,1.412-1.222,1.738-2.119l1.956,0.353
c-0.163,0.435-0.407,0.896-0.733,1.385c-0.326,0.489-0.761,0.924-1.304,1.331c-0.543,0.407-1.195,0.733-1.983,1.005
c-0.788,0.272-1.711,0.407-2.825,0.407c-1.141,0-2.2-0.163-3.124-0.516c-0.924-0.353-1.738-0.869-2.39-1.575
c-0.652-0.706-1.141-1.548-1.521-2.58c-0.353-1.032-0.543-2.2-0.543-3.558c0-1.494,0.217-2.743,0.652-3.776
c0.435-1.032,1.005-1.847,1.711-2.472c0.706-0.625,1.494-1.086,2.417-1.331c0.896-0.272,1.82-0.407,2.743-0.407
c1.249,0,2.309,0.19,3.205,0.598c0.896,0.407,1.657,0.951,2.227,1.657c0.57,0.706,1.005,1.575,1.277,2.553
c0.272,0.978,0.407,2.064,0.407,3.232v0.462L107.737,19.169L107.737,19.169z M117.706,15.42
c-0.217-0.652-0.543-1.222-0.951-1.684c-0.407-0.462-0.924-0.815-1.521-1.086c-0.598-0.244-1.331-0.38-2.173-0.38
c-0.679,0-1.304,0.109-1.929,0.299c-0.625,0.19-1.168,0.516-1.657,0.924c-0.489,0.435-0.869,0.978-1.195,1.684
c-0.299,0.679-0.489,1.521-0.516,2.499h10.349C118.059,16.86,117.923,16.045,117.706,15.42L117.706,15.42z M134.845,26.557
h-2.526l-6.383-7.741l-2.173,1.956v5.786h-2.119V4.284h2.119v14.342l8.094-7.687h2.553l-7.008,6.519L134.845,26.557
L134.845,26.557z M138.621,24.601c0.326,0.38,0.815,0.57,1.494,0.57c0.272,0,0.57-0.027,0.896-0.054
c0.326-0.054,0.625-0.109,0.924-0.19v1.548c-0.407,0.109-0.815,0.19-1.249,0.244c-0.435,0.054-0.896,0.109-1.358,0.109
c-1.087,0-1.901-0.299-2.472-0.896c-0.543-0.598-0.842-1.494-0.842-2.716V12.568h-2.064v-1.63h2.119l0.679-3.585h1.385v3.585
h3.585v1.63h-3.585v10.267C138.105,23.651,138.268,24.248,138.621,24.601L138.621,24.601z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              id="SVGID_2_"
              className="st0"
              d="M11.229,9.2c-0.652-0.244-1.277-0.353-1.874-0.353c-0.353,0-0.706,0.054-1.059,0.109
C7.942,9.01,7.589,9.173,7.263,9.336c-0.326,0.163-0.598,0.407-0.788,0.679s-0.299,0.625-0.299,1.032
c0,0.652,0.244,1.141,0.733,1.494c0.489,0.353,1.114,0.625,1.847,0.869s1.548,0.489,2.39,0.733
c0.842,0.244,1.657,0.598,2.39,1.032c0.733,0.435,1.358,1.032,1.847,1.793s0.733,1.793,0.733,3.015
c0,1.195-0.217,2.227-0.679,3.124c-0.462,0.896-1.059,1.63-1.82,2.227c-0.761,0.598-1.657,1.032-2.662,1.304
s-2.064,0.435-3.178,0.435c-1.412,0-2.716-0.217-3.911-0.652c-1.222-0.38-2.336-1.086-3.368-2.037l3.395-3.667
c0.489,0.652,1.114,1.141,1.847,1.494s1.521,0.543,2.309,0.543c0.38,0,0.788-0.054,1.168-0.136
c0.407-0.081,0.761-0.217,1.059-0.407c0.326-0.19,0.57-0.407,0.761-0.706c0.19-0.272,0.299-0.625,0.299-1.005
c0-0.652-0.244-1.168-0.761-1.548c-0.489-0.38-1.114-0.706-1.874-0.951c-0.761-0.272-1.575-0.516-2.445-0.788
s-1.684-0.625-2.445-1.059c-0.761-0.435-1.385-1.032-1.874-1.766s-0.733-1.684-0.733-2.906c0-1.168,0.217-2.173,0.679-3.042
s1.059-1.603,1.82-2.2s1.63-1.032,2.635-1.331C7.345,4.61,8.377,4.474,9.409,4.474c1.222,0,2.39,0.163,3.531,0.516
s2.173,0.924,3.069,1.738l-3.259,3.558C12.424,9.798,11.881,9.445,11.229,9.2L11.229,9.2z M32.823,22.347
c-0.435,0.951-1.005,1.766-1.766,2.445c-0.733,0.679-1.63,1.195-2.635,1.575c-1.005,0.353-2.092,0.543-3.232,0.543
c-1.141,0-2.2-0.19-3.232-0.543c-1.005-0.353-1.874-0.896-2.635-1.575c-0.733-0.679-1.331-1.494-1.766-2.445
c-0.435-0.951-0.652-2.01-0.652-3.205c0-1.168,0.217-2.227,0.652-3.205c0.435-0.951,1.005-1.766,1.766-2.445
c0.733-0.679,1.63-1.195,2.635-1.575c1.005-0.353,2.092-0.543,3.232-0.543s2.2,0.19,3.232,0.543
c1.005,0.353,1.874,0.896,2.635,1.575c0.733,0.679,1.331,1.494,1.766,2.445s0.652,2.01,0.652,3.205
C33.448,20.391,33.258,21.396,32.823,22.347L32.823,22.347z M27.907,16.453c-0.652-0.679-1.548-1.032-2.689-1.032
c-1.168,0-2.064,0.353-2.689,1.032c-0.652,0.679-0.951,1.603-0.951,2.716s0.326,2.01,0.951,2.716
c0.652,0.679,1.548,1.032,2.689,1.032c1.168,0,2.064-0.353,2.689-1.032c0.652-0.679,0.951-1.603,0.951-2.716
C28.885,18.055,28.559,17.186,27.907,16.453L27.907,16.453z M44.938,7.353c-0.272-0.109-0.516-0.19-0.733-0.217
c-0.217-0.054-0.489-0.081-0.761-0.081c-0.733,0-1.277,0.163-1.657,0.462c-0.353,0.326-0.543,0.951-0.543,1.929v2.309h3.395v3.64
h-3.395V26.53h-4.59V15.393h-3.015v-3.613h3.015V9.173c0-0.815,0.081-1.575,0.217-2.309c0.136-0.706,0.407-1.358,0.815-1.901
s1.005-0.978,1.766-1.304s1.766-0.462,3.015-0.462c0.462,0,0.924,0.027,1.385,0.054c0.435,0.054,0.896,0.109,1.304,0.217
L44.938,7.353L44.938,7.353z M57.188,7.353c-0.272-0.109-0.516-0.19-0.733-0.217c-0.217-0.054-0.489-0.081-0.761-0.081
c-0.733,0-1.277,0.163-1.657,0.462c-0.353,0.326-0.543,0.951-0.543,1.929v2.309h3.395v3.64h-3.395V26.53h-4.59V15.393h-3.015
v-3.613h3.015V9.173c0-0.815,0.081-1.575,0.217-2.309c0.136-0.706,0.407-1.358,0.815-1.901s1.005-0.978,1.766-1.304
c0.761-0.326,1.766-0.462,3.015-0.462c0.462,0,0.924,0.027,1.385,0.054c0.435,0.054,0.896,0.109,1.304,0.217L57.188,7.353
L57.188,7.353z M67.184,26.557V24.71h-0.054c-0.516,0.788-1.195,1.358-2.037,1.711c-0.842,0.353-1.738,0.516-2.662,0.516
c-0.679,0-1.358-0.109-2.01-0.299c-0.652-0.19-1.195-0.489-1.684-0.869c-0.489-0.38-0.869-0.869-1.168-1.44
c-0.299-0.57-0.435-1.222-0.435-1.983c0-0.842,0.163-1.575,0.462-2.146c0.326-0.598,0.733-1.086,1.277-1.467
c0.543-0.38,1.168-0.679,1.847-0.896c0.679-0.217,1.412-0.38,2.146-0.462c0.733-0.109,1.494-0.163,2.227-0.19
c0.733-0.027,1.44-0.027,2.064-0.027c0-0.815-0.299-1.467-0.869-1.929c-0.57-0.489-1.277-0.706-2.064-0.706
c-0.761,0-1.44,0.163-2.064,0.462c-0.625,0.326-1.168,0.733-1.657,1.304l-2.445-2.499c0.842-0.788,1.847-1.385,2.988-1.766
c1.141-0.407,2.309-0.598,3.531-0.598c1.331,0,2.445,0.163,3.314,0.489c0.869,0.326,1.548,0.815,2.064,1.467
c0.516,0.652,0.869,1.44,1.086,2.39c0.19,0.951,0.299,2.037,0.299,3.259v7.497L67.184,26.557L67.184,26.557z M67.184,20.12
h-1.141c-0.353,0-0.788,0.027-1.304,0.054s-1.005,0.109-1.494,0.272c-0.489,0.136-0.896,0.353-1.222,0.625
c-0.326,0.272-0.516,0.679-0.516,1.195c0,0.543,0.244,0.951,0.706,1.222s0.951,0.407,1.467,0.407c0.435,0,0.869-0.054,1.304-0.19
c0.407-0.109,0.788-0.299,1.114-0.516c0.326-0.217,0.598-0.516,0.788-0.842c0.19-0.353,0.299-0.761,0.299-1.222L67.184,20.12
L67.184,20.12z"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
}
