const programName = 'Badmintonshoppen'
export default {
  programName,
  logo: (
    <svg className="badmintonshoppen" x="0px" y="0px" viewBox="0 0 767.9 92.1">
      <title>{programName}</title>
      <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-41.6907" y1="214.6078" x2="-40.9836" y2="213.9007">
        <stop offset="0" style={{ stopColor: '#51A55B' }} />
        <stop offset="0.9831" style={{ stopColor: '#000A00' }} />
      </linearGradient>
      <path
        className="st0"
        d="M47.8,68.5c0,2.6-1,4.9-2.9,6.8c-2,1.9-4.2,2.9-6.8,2.9H3.9v-39h14.6V49H38c2.6,0,4.9,1,6.8,2.9
    c2,1.9,2.9,4.2,2.9,6.8V68.5 M33.2,68.5v-9.7c0-1.3-0.5-2.4-1.5-3.4c-1-1-2.1-1.5-3.4-1.5h-9.8v19.4h9.8c1.3,0,2.4-0.5,3.4-1.5
    C32.7,70.8,33.2,69.7,33.2,68.5z"
      />
      <path
        className="st0"
        d="M96.5,68.5c0,2.6-1,4.9-2.9,6.8c-2,1.9-4.2,2.9-6.8,2.9H62.4c-2.6,0-4.9-1-6.8-2.9c-1.9-1.9-2.9-4.2-2.9-6.8
    c0-2.6,1-4.9,2.9-6.8c2-1.9,4.2-2.9,6.8-2.9h19.5c-0.2-1.3-0.7-2.4-1.5-3.4c-0.9-1-2.1-1.4-3.4-1.4H57.5V49h29.2
    c2.6,0,4.9,1,6.8,2.9c2,1.9,2.9,4.2,2.9,6.8V68.5 M81.9,68.5v-4.9h-9.8c-1.3,0-2.4,0.5-3.4,1.5c-1,1-1.5,2.1-1.5,3.4
    c0,1.3,0.5,2.4,1.5,3.4c1,1,2.1,1.5,3.4,1.5H77c1.3,0,2.4-0.5,3.4-1.5C81.4,70.8,81.9,69.7,81.9,68.5z"
      />
      <path
        className="st0"
        d="M145.2,78.2h-34.1c-2.6,0-4.9-1-6.8-2.9c-2-1.9-2.9-4.2-2.9-6.8v-9.7c0-2.6,1-4.9,2.9-6.8
    c2-1.9,4.2-2.9,6.8-2.9h19.5v-9.8h14.6V78.2 M130.6,73.3V53.9h-9.8c-1.3,0-2.4,0.5-3.4,1.5c-1,1-1.5,2.1-1.5,3.4v9.7
    c0,1.3,0.5,2.4,1.5,3.4c1,1,2.1,1.5,3.4,1.5H130.6z"
      />
      <path
        className="st0"
        d="M223.1,78.2h-14.6V58.7c0-1.3-0.5-2.4-1.5-3.4c-1-1-2.1-1.5-3.4-1.5h-4.9c-1.3,0-2.4,0.5-3.4,1.5
    c-1,1-1.5,2.1-1.5,3.4v19.5h-14.6V58.7c0-1.3-0.5-2.4-1.5-3.4c-1-1-2.1-1.5-3.4-1.5h-4.9c-1.3,0-2.4,0.5-3.4,1.5
    c-1,1-1.5,2.1-1.5,3.4v19.5h-14.6V58.7c0-2.6,1-4.9,2.9-6.8c1.9-1.9,4.2-2.9,6.8-2.9h53.5c2.6,0,4.9,1,6.8,2.9
    c1.9,1.9,2.9,4.2,2.9,6.8V78.2"
      />
      <path
        className="st0"
        d="M242.6,39.2c0,1.9-0.7,3.6-2.2,5.1c-1.5,1.5-3.2,2.2-5.1,2.2c-1.9,0-3.6-0.7-5.1-2.2c-1.5-1.5-2.2-3.2-2.2-5.1
    c0-1.9,0.7-3.7,2.2-5.1c1.5-1.5,3.2-2.2,5.1-2.2c1.9,0,3.7,0.7,5.1,2.2C241.9,35.6,242.6,37.3,242.6,39.2 M242.6,49v29.2H228V49
    H242.6z"
      />
      <path
        className="st0"
        d="M291.4,78.2h-14.6V58.7c0-1.3-0.5-2.4-1.5-3.4c-1-1-2.1-1.5-3.4-1.5h-9.8v24.3h-14.6V49h34.1
    c2.6,0,4.9,1,6.8,2.9c2,1.9,2.9,4.2,2.9,6.8V78.2"
      />
      <path
        className="st0"
        d="M330.3,78.2h-24.4c-2.6,0-4.9-1-6.8-2.9c-1.9-1.9-2.9-4.2-2.9-6.8V39.2h14.6V49h14.6v4.9h-14.6v14.6
    c0,1.3,0.5,2.4,1.5,3.4c1,1,2.1,1.5,3.4,1.5h14.6V78.2"
      />
      <path
        className="st0"
        d="M379,68.5c0,2.6-1,4.9-2.9,6.8c-1.9,1.9-4.2,2.9-6.8,2.9h-24.4c-2.6,0-4.9-1-6.8-2.9c-2-1.9-2.9-4.2-2.9-6.8
    v-9.7c0-2.6,1-4.9,2.9-6.8c2-1.9,4.2-2.9,6.8-2.9h24.4c2.6,0,4.9,1,6.8,2.9c1.9,1.9,2.9,4.2,2.9,6.8V68.5 M364.4,68.5v-9.7
    c0-1.3-0.5-2.4-1.5-3.4c-1-1-2.1-1.5-3.4-1.5h-4.9c-1.3,0-2.4,0.5-3.4,1.5c-1,1-1.5,2.1-1.5,3.4v9.7c0,1.3,0.5,2.4,1.5,3.4
    c1,1,2.1,1.5,3.4,1.5h4.9c1.3,0,2.4-0.5,3.4-1.5C363.9,70.8,364.4,69.7,364.4,68.5z"
      />
      <path
        className="st0"
        d="M427.7,78.2h-14.6V58.7c0-1.3-0.5-2.4-1.5-3.4c-1-1-2.1-1.5-3.4-1.5h-9.8v24.3h-14.6V49H418
    c2.6,0,4.9,1,6.8,2.9c1.9,1.9,2.9,4.2,2.9,6.8V78.2"
      />
      <path
        className="st1"
        d="M471.6,70.9c0,2.5-1.1,4.3-3.2,5.6c-1.8,1.1-3.9,1.7-6.6,1.7h-29.2v-4.9h21.9c0.6,0,1.2-0.2,1.7-0.7
    c0.5-0.5,0.7-1.1,0.7-1.7c0-1.3-2.1-2.6-6.3-3.8c-6.7-1.9-10.6-3.2-11.7-3.6c-4.2-1.9-6.3-4.3-6.3-7.1c0-2.5,1.1-4.3,3.2-5.6
    c1.8-1.1,3.9-1.7,6.6-1.7h29.2v4.9h-21.9c-0.7,0-1.2,0.2-1.7,0.7c-0.5,0.5-0.7,1.1-0.7,1.7c0,1.3,2.1,2.6,6.3,3.8
    c6.4,1.8,10.3,3.1,11.7,3.7C469.5,65.7,471.6,68,471.6,70.9"
      />
      <path
        className="st1"
        d="M520.3,78.2h-14.6V58.7c0-1.3-0.5-2.4-1.5-3.4c-1-1-2.1-1.5-3.4-1.5H491v24.3h-14.6v-39H491V49h19.5
    c2.6,0,4.9,1,6.8,2.9c1.9,1.9,2.9,4.2,2.9,6.8V78.2"
      />
      <path
        className="st1"
        d="M569,68.5c0,2.6-1,4.9-2.9,6.8c-1.9,1.9-4.2,2.9-6.8,2.9h-24.4c-2.6,0-4.9-1-6.8-2.9c-2-1.9-2.9-4.2-2.9-6.8
    v-9.7c0-2.6,1-4.9,2.9-6.8c2-1.9,4.2-2.9,6.8-2.9h24.4c2.6,0,4.9,1,6.8,2.9c2,1.9,2.9,4.2,2.9,6.8V68.5 M554.4,68.5v-9.7
    c0-1.3-0.5-2.4-1.5-3.4c-1-1-2.1-1.5-3.4-1.5h-4.9c-1.3,0-2.4,0.5-3.4,1.5c-1,1-1.5,2.1-1.5,3.4v9.7c0,1.3,0.5,2.4,1.5,3.4
    c1,1,2.1,1.5,3.4,1.5h4.9c1.3,0,2.4-0.5,3.4-1.5C553.9,70.8,554.4,69.7,554.4,68.5z"
      />
      <path
        className="st1"
        d="M617.7,68.5c0,2.6-1,4.9-2.9,6.8c-2,1.9-4.2,2.9-6.8,2.9h-19.5v9.7h-14.6V49H608c2.6,0,4.9,1,6.8,2.9
    c2,1.9,2.9,4.2,2.9,6.8V68.5 M603.1,68.5v-9.7c0-1.3-0.5-2.4-1.5-3.4c-1-1-2.1-1.5-3.4-1.5h-9.8v19.4h9.8c1.3,0,2.4-0.5,3.4-1.5
    C602.6,70.8,603.1,69.7,603.1,68.5z"
      />
      <path
        className="st1"
        d="M666.4,68.5c0,2.6-1,4.9-2.9,6.8c-2,1.9-4.2,2.9-6.8,2.9h-19.5v9.7h-14.6V49h34.1c2.6,0,4.9,1,6.8,2.9
    c2,1.9,2.9,4.2,2.9,6.8V68.5 M651.8,68.5v-9.7c0-1.3-0.5-2.4-1.5-3.4c-1-1-2.1-1.5-3.4-1.5h-9.8v19.4h9.8c1.3,0,2.4-0.5,3.4-1.5
    C651.3,70.8,651.8,69.7,651.8,68.5z"
      />
      <path
        className="st1"
        d="M715.1,68.5h-29.3c0.1,1.3,0.6,2.5,1.6,3.4c0.9,1,2,1.4,3.3,1.4h19.5v4.9H681c-2.6,0-4.9-1-6.8-2.9
    c-2-1.9-2.9-4.2-2.9-6.8v-9.7c0-2.6,1-4.9,2.9-6.8c2-1.9,4.2-2.9,6.8-2.9h24.4c2.6,0,4.9,1,6.8,2.9c2,1.9,2.9,4.2,2.9,6.8V68.5
     M700.5,63.6v-4.9c0-1.3-0.5-2.4-1.5-3.4c-1-1-2.1-1.5-3.4-1.5h-4.9c-1.3,0-2.4,0.5-3.4,1.5c-1,1-1.5,2.1-1.5,3.4v4.9H700.5z"
      />
      <path
        className="st1"
        d="M763.9,78.2h-14.6V58.7c0-1.3-0.5-2.4-1.5-3.4c-1-1-2.1-1.5-3.4-1.5h-9.8v24.3H720V49h34.1c2.6,0,4.9,1,6.8,2.9
    c2,1.9,2.9,4.2,2.9,6.8V78.2"
      />
      <path className="st2" d="M4.9,29c0,0,382.7-54.9,757.9,0" />
    </svg>
  ),
}
