/* eslint-disable max-len */
const programName = 'Babyland'
export default {
  programName,
  logo: (
    <svg className="babyland" x="0px" y="0px" viewBox="0 0 1332.3 1332.3">
      <title>{programName}</title>
      <g id="moln">
        <path
          className="st0"
          d="M1125.2,639.4c-0.3-0.7-0.7-1.5-1-2.2c2.1-9.2,4.3-19.3,4.9-22.5c1.2-7.1,2.7-18.4,2.7-31.9
        c0-21.9-3.8-49.7-18.8-75c-29.3-49.6-90.7-74.5-95.9-76.6c-3.9-1.7-41.7-18.1-98.2-19.7c-29.9-0.9-66,9.5-92.8,19.2
        c-12.7-15.5-69.8-79.6-150.5-79.8c-89.6-0.3-153.5,59-159.3,64.5c-0.8,0.7-1.7,1.4-2.6,2c-6.7-2.3-28-8.7-45.8-9
        c-16.5-0.2-39.6,1.1-54.6,5.4c-15.4,4.5-53.9,17.6-80.3,42.4c-30.1,27.9-41.6,74.5-42,76.4c-0.1,0.5-1.1,7.6-1.6,9.5
        c-5.2,1.7-20.1,7.1-35.9,15.1c-18.4,9.4-38.7,28.4-39.6,29.2c-0.2,0.1-0.3,0.3-0.5,0.5c-0.7,0.8-18,19.1-31.1,48.5
        c-2.5,5.6-4.6,11.4-6.4,17.1c-14.6,43.8-11.8,86.9-4.3,114.8c13,48.2,39.8,88.6,76.5,117.1c1.2,0.9,2.6,2,4.3,3.3c0,0,0,0,0.1,0
        c1.1,0.8,2.3,1.7,3.6,2.6c16.8,11.8,35.4,21.3,55.5,28c43.2,15.3,75.9,10,77.3,9.7c0.1,0,0.2,0,0.3,0c0.3-0.1,5.8-1.1,11.3-2.4
        c45.2,31.8,87.8,42.8,93.5,44.2c6.9,2.2,67,20.5,143.9,2.9c42.4-10.1,80.6-23.3,113.9-39.2c0,0,0,0,0,0
        c2.2,1.6,55.2,38.1,117.5,40.5c59.8,2.3,89.7-16.5,93.5-19c2.3-1.5,30.8-19.7,47.4-39.8c4.6-5.5,9.1-12.4,13.4-20.3
        c12.3-2.3,35.9-15.2,36.1-15.5c17.6-12.7,54.7-35.1,72.5-67.6C1174.9,733.7,1135.2,660.9,1125.2,639.4z M753.5,932.7
        C753.5,932.7,753.5,932.7,753.5,932.7c0.1,0,0.1-0.1,0.2-0.1C753.6,932.6,753.5,932.6,753.5,932.7z"
        />
      </g>
      <g id="moln_x5F_blått">
        <path
          className="st1"
          d="M1013.1,851.6c-0.9,1.6-23.2,63.9-95.3,80c-72,16.1-140.3-24.4-140.8-24.8c-0.5-0.4-0.9-1.6-0.7-2
        c0.3-0.4,6-4.4,6-4.4s29.1-18.3,37.8-25.4c8.7-7.2,29.7-25,29.7-25l23.2-24.5c0,0,2.9-3.4,0-5.5c-2.9-2.1-6.6,1.1-6.6,1.1
        s-35.2,28.8-67.1,48.2c-29.2,17.7-38.1,24.3-61.9,34.4c-23.9,10.1-52.5,21.9-84.6,26.1c-23.1,3-69.5,11.3-110.2,4.4
        c-40.7-6.9-75.5-15.1-111.6-36.5c-36.1-21.4-75.2-51.6-82.9-67.7c-8.9-14.6-14.7-6.6-14.1-4.5c0.6,2.1,2.9,6.8,2.9,6.8
        s16,41.3,64.4,77.4c48.3,36.1,96.1,46.9,96.1,46.9s60.4,20.6,138,2.8c35.1-8.4,75.2-20.8,113.4-39.2c6.8-3.1,9.9,1.9,9.9,1.9
        s51.6,36.1,111.1,38.4c59.5,2.3,86.5-17.1,86.5-17.1s28.8-18.1,44.8-37.4c15.9-19.3,26.7-51,26.7-51s2.6-7.3-3.9-7.2
        C1017.4,847.8,1014,850,1013.1,851.6z"
        />
        <path
          className="st1"
          d="M1138.6,717c-1.7-11.9-11-61.8-41.7-108.3c-32.4-48.9-81.1-84.1-81.1-84.1s-3.7-2.9-5.8-0.9
        c-2.1,1.9,0.9,5.1,0.9,5.1s46.6,37.1,71.6,89c25,51.9,27.7,102.6,27.8,109.1c-0.3,49.4-16.3,78-56.1,119.4
        c-5,5.1-12.6,14.6-17,18.8c-1.3,1.1-2.6,2.3-3.4,3.3c-1.3,1.6-1.8,3.1-0.2,4.5c2,1.7,22.6-8.5,29.7-13.4
        C1063.2,859.5,1150.6,812.5,1138.6,717z"
        />
        <path
          className="st1"
          d="M1103.2,511.9c-28.2-47.7-90.3-71.7-90.3-71.7s-37.3-17.2-93.9-18.8c-40.6-1.2-95,21.1-95.8,21.2
        c-0.8,0.1-3.6-3.4-3.6-3.4S761,361.1,676,360.8c-88.3-0.3-151.3,61.5-151.3,61.5s-5.3,4.6-8.5,5.7c-2.2,0.7-3.2-0.1-4.9-0.9
        c-1.7-0.8-25.3-8.5-43.2-8.7c-17.9-0.3-38.5,1.3-51.1,5c-12.6,3.7-50.6,16.2-75.5,39.7c-27.9,25.8-37.8,66.6-38.6,70.6
        c-0.9,4-2,10.3-3.1,14.6c-2.3,2.2-4.6,2.9-4.6,2.9C81.5,641.5,200.8,932.7,381,914.5c0,0,3.4,0,3.8-1.3c0.5-1.4-1.1-2.9-1.1-2.9
        l-8.3-8.3c0,0-5.7-5.5-8.3-6.3c-2.5-0.7-18.6-0.1-53.4-15c-17.8-8.1-34-17.7-45.6-28.2c-94.4-90.8-60.8-190.1-37-223.7
        c23.8-33.6,62.4-51.3,62.4-51.3s3.9-2.4,5.6-1.4c2.2,1.2,2.1,3.6,2.5,6c0.7,3.1,5,21.3,7.7,27.2c4.2,9.7,11.3,18.5,11.3,18.5
        s2.6,4.4,6.3,2.9c3.7-1.5,1.7-6.5,1.7-6.5s-9.2-19-4.8-57.3c3.9-33.9,23.9-59.7,23.9-59.7s20.5-28.4,49.5-45.8
        c28.9-17.4,63.8-18.8,63.8-18.8s18.2-1.3,31.7,0.5c12.3,1.6,7.6,4.5,7.6,4.5s-14.6,16.9-19.1,22.8c-4.5,6-14.7,20.2-14.7,20.2
        s-2.9,2.9,0.7,5.8c3.6,2.9,5.9-1,5.9-1s36.1-49,92.8-76.4c56.7-27.4,101.7-25.5,101.7-25.5s33.3,0,67.9,14.1
        c34.5,14.1,63.5,43.6,63.5,43.6s17.7,20.7,30.5,45.8c10.8,21,16.7,46.8,20.6,46.4c4.7-0.4,2.2-23.8-2.7-45
        c-4.2-18.1-11.9-31.7-10.4-33.8c3.3-4.8,22.4-14.9,80.4-16.4c26.3-1.3,63.6,5.9,78.8,13.3c95.5,42.2,94.8,123.3,94.8,123.3
        s20.7,27.8,21.9,32.7c2.8,2.4,3.5,0.4,3.5,0.4S1131.4,559.7,1103.2,511.9z"
        />
      </g>
      <g id="R">
        <g>
          <path
            className="st1"
            d="M1169.5,629.9c11.8,0,21.2,9.6,21.2,21.6c0,12.2-9.4,21.7-21.3,21.7c-11.8,0-21.4-9.5-21.4-21.7
            C1148,639.5,1157.6,629.9,1169.5,629.9L1169.5,629.9z M1169.4,633.2c-9.5,0-17.3,8.2-17.3,18.2c0,10.3,7.8,18.3,17.4,18.3
            c9.6,0.1,17.3-8.1,17.3-18.2C1186.8,641.4,1179.1,633.2,1169.4,633.2L1169.4,633.2z M1165.4,664h-3.8V640c2-0.3,3.9-0.6,6.8-0.6
            c3.6,0,6,0.8,7.5,1.8c1.4,1.1,2.2,2.7,2.2,5c0,3.2-2.1,5.1-4.7,5.9v0.2c2.1,0.4,3.6,2.3,4,5.9c0.6,3.7,1.2,5.2,1.5,5.9h-4
            c-0.6-0.8-1.2-3-1.6-6.1c-0.6-3.1-2.1-4.2-5.2-4.2h-2.7V664z M1165.4,650.7h2.8c3.2,0,5.9-1.2,5.9-4.1c0-2.1-1.5-4.2-5.9-4.2
            c-1.2,0-2.1,0.1-2.8,0.2V650.7z"
          />
        </g>
      </g>
      <g id="text_x5F_vitt">
        <g>
          <g>
            <path
              className="st0"
              d="M729.9,747.9c-0.3,0-0.5,0-0.8,0c-10.7-0.3-22.8-6.1-26.1-18.4l-0.4-1.6l-26.5-0.7c-0.2,0.5-0.4,1-0.6,1.5
                l0.1,0c-2.5,7.8-10.9,17-26.2,17c-0.3,0-0.6,0-0.9,0c-7.9-0.2-15.8-3.7-21.2-9.4c-4.8-5.1-7.4-11.6-7.2-18.4
                c0.2-7.5,3.9-17.1,7.5-26.4l0.1-0.2l0.1-0.3c0,0,33.4-70,35.4-74.3c2.3-5.8,9.1-22.3,28.9-22.3c0.3,0,0.6,0,1,0l0,0
                c20.2,0.4,26.6,17.5,28.7,23.4c25.1,61.9,30,73.9,31,76.6l0.1,0l0.2,0.5c3.1,9.4,6.2,19.2,6,26.7c-0.2,7.5-3.7,14.4-9.8,19.4
                C743.8,745.4,736.8,747.9,729.9,747.9C729.9,747.9,729.9,747.9,729.9,747.9z"
            />
            <path
              className="st0"
              d="M692.1,601.5c0.3,0,0.5,0,0.8,0l-0.3,0c14.1,0.1,19.5,10.1,22.7,18.9c0,0,30.9,76.2,30.9,76.2l0.2,0.5
                c2.9,8.9,5.9,18.1,5.7,24.4c-0.3,11.8-11.6,19.4-22.1,19.4c-0.2,0-0.4,0-0.6,0c-8-0.2-17.2-4.4-19.5-13.2c0,0-1.1-4-1.8-6.7
                c-4.5-0.1-32.4-0.9-36.9-1c-0.9,2.7-2.2,6.6-2.2,6.6c0,0,0,0,0,0c-2.7,8.4-11.6,12.1-19.5,12.1c-0.2,0-0.5,0-0.7,0
                c-6-0.2-12.1-2.9-16.3-7.2c-3.6-3.8-5.4-8.5-5.3-13.4c0.2-6.3,3.6-15.3,7-24.1c0,0,0.2-0.5,0.2-0.5c0,0,35.2-74,35.2-74
                C673,611.1,678.6,601.5,692.1,601.5 M700.3,688.1l-8.6-20.7l-9,20.2C687.8,687.8,695.1,688,700.3,688.1 M692.1,587.5L692.1,587.5
                L692.1,587.5c-9.7,0-18.3,3.4-24.9,9.9c-5.5,5.4-8.6,12-10.4,16.6c-2.8,5.8-35.1,73.6-35.1,73.6l-0.5,1l-0.2,0.5
                c-3.8,9.8-7.7,19.9-7.9,28.6c-0.2,8.6,3,16.9,9.1,23.4c6.7,7.1,16.2,11.3,26,11.6c0.3,0,0.7,0,1.1,0c6.5,0,13.1-1.7,18.5-4.7
                c5.9-3.3,10.3-8,13-13.7l3,0.1l13.5,0.4c5.3,13.4,19.3,19.8,31.5,20.1c0.3,0,0.6,0,1,0c8.5,0,17.1-3.1,23.7-8.4
                c7.7-6.3,12.1-15,12.4-24.6c0.2-8.7-3.1-19.1-6.4-29.1l-0.1-0.5l0,0c-0.7-2-3.5-8.9-15.8-39.1l-15.4-37.9
                c-1.7-4.8-4.7-11.7-10.3-17.4c-6.4-6.6-15-10.2-24.8-10.4l0,0C692.9,587.5,692.5,587.5,692.1,587.5L692.1,587.5z"
            />
          </g>
          <g>
            <path
              className="st0"
              d="M468.1,758.5c-15.4,0-26.9-10.4-29.8-27.1c-0.2-1.1-4.3-23.5-5.6-30.5c-6.4-6.9-28.1-28.4-33.1-33.4
                l-0.3-0.2l-0.6-0.7c-5.3-6.3-8.6-11.3-9.8-17.8c-0.1-0.8-0.3-1.6-0.3-2.4c-1.4-15.3,9.4-29.5,24.7-32.2c1.9-0.3,3.9-0.5,5.7-0.5
                c9.9,0,18.8,4.7,25.2,13.3c0.9,1.1,4.5,5,8.3,9.6c2.2-5.6,4.3-10.3,5.2-12.2c3.7-12.1,12.6-20.3,24.6-22.4
                c1.7-0.3,3.5-0.5,5.3-0.5c6.1,0,12,1.9,17.1,5.4c6.5,4.5,11,11.5,12.3,19.2c0.1,0.7,0.2,1.4,0.3,2.1c0.5,5.7-0.9,11.1-3.3,17.9
                l-0.1,0.3l-0.1,0.3c-6.8,14.4-17.3,36.9-20.2,43.4c1.3,7.2,5.5,30.1,5.6,30.3c0.2,1.2,0.4,2.4,0.5,3.5
                c1.7,17.6-8.2,30.9-25.2,33.9C472.3,758.3,470.2,758.5,468.1,758.5C468.1,758.5,468.1,758.5,468.1,758.5z"
            />
            <path
              className="st0"
              d="M487.6,608.5c4.7,0,9.2,1.4,13.1,4.1c5,3.5,8.4,8.8,9.5,14.7c0.1,0.5,0.2,1,0.2,1.5c0.4,4.4-0.8,8.8-3,14.9
                c0,0-20.5,43.7-21.1,45.6c0.3,1.8,5.9,32.5,5.9,32.5c0.2,1,0.3,2,0.4,2.9c1.3,13.8-6.1,23.9-19.5,26.3c-1.7,0.3-3.4,0.5-5,0.5
                c-11.8,0-20.6-8-22.9-21.3c0,0-5.6-30.6-5.9-32.5c-4.9-5.8-35.1-35.6-35.2-35.6c-4.7-5.6-7.3-9.5-8.2-14.5
                c-0.1-0.6-0.2-1.2-0.2-1.8c-1.1-11.7,7-22.5,18.9-24.6c1.5-0.3,3-0.4,4.5-0.4c7.7,0,14.6,3.7,19.8,10.7c0,0,10.5,11.7,16.2,19.6
                c3-10.6,9.3-24.1,9.3-24.1c2.8-9.9,9.7-16.3,19.2-18C484.9,608.6,486.2,608.5,487.6,608.5 M487.6,594.5c-2.2,0-4.4,0.2-6.5,0.6
                c-14.5,2.6-25.4,12.3-29.9,26.8c-0.2,0.4-0.4,0.9-0.7,1.5c-0.3-0.4-0.6-0.7-0.8-0.9c-7.7-10.2-18.6-15.8-30.6-15.8
                c-2.3,0-4.6,0.2-7,0.6c-9.2,1.6-17.4,6.6-23,14c-5.6,7.4-8.3,16.5-7.4,25.7c0.1,1,0.2,2,0.4,3c1.6,8.9,6.4,15.3,11.3,21l1,1.2
                l0.5,0.4c7,6.9,24.4,24.2,31.3,31.4c1.5,8.4,5,27.1,5.2,28.5c3.6,20,18,32.9,36.7,32.9c2.4,0,4.9-0.2,7.5-0.7
                c20.5-3.6,32.9-20.3,31-41.4c-0.1-1.3-0.3-2.7-0.6-4.1l0,0l0,0c-1.5-7.9-3.9-21.3-5.2-28.2c3.8-8.2,13.1-28.3,19.3-41.4l0.3-0.6
                l0.2-0.6c2.3-6.3,4.5-13.2,3.7-21c-0.1-0.9-0.2-1.8-0.4-2.7c-1.7-9.5-7.2-18.1-15.2-23.7C502.5,596.8,495.2,594.5,487.6,594.5
                L487.6,594.5z"
            />
          </g>
          <g>
            <path
              className="st0"
              d="M552.7,747c-8.8,0-16-2.4-21.4-7.1c-6.4-5.6-9.9-13.9-10.7-25.4l-5.7-82.5c-0.7-9.8,1.8-18.2,7.3-24.5
                c5.2-5.9,12.5-9.4,21.2-10c0.9-0.1,1.7-0.1,2.6-0.1c7.7,0,14.5,2.4,19.8,7.1c5.2,4.6,8.7,11.1,10.1,18.8c0.3,1.5,0.4,3,0.5,4.5
                c0,0,3.1,45.2,4.4,63.4l12.2-0.7c0.8-0.1,1.6-0.1,2.4-0.1c7.3,0,13.7,2.2,18.6,6.5c4.4,3.8,7.3,9.1,8.4,15.3
                c0.2,1.1,0.3,2.2,0.4,3.3c0.5,7.6-1.6,14.4-6.1,19.6c-4.8,5.5-11.7,8.7-20,9.3c-31.7,2-38.5,2.4-40.1,2.5l0,0
                C555.3,747,554,747,552.7,747z"
            />
            <path
              className="st0"
              d="M546,604.5c6,0,11.2,1.8,15.2,5.3c4,3.5,6.7,8.6,7.8,14.7c0.2,1.2,0.4,2.5,0.5,3.8c0,0,4.4,63.6,4.9,70.3
                c6.4-0.4,19.1-1.1,19.1-1.1c0.7,0,1.4-0.1,2-0.1c5.6,0,10.3,1.6,14,4.8c3.2,2.8,5.3,6.6,6.1,11.2c0.1,0.8,0.3,1.7,0.3,2.6
                c0.4,5.7-1.1,10.8-4.4,14.6c-3.5,4-8.8,6.4-15.2,6.9c0,0-40.1,2.5-40.1,2.5h0c-1.2,0.1-2.3,0.1-3.4,0.1c-7.2,0-12.7-1.8-16.8-5.4
                c-4.9-4.3-7.7-11-8.3-20.6l-5.7-82.5l0,0c-0.5-7.9,1.4-14.6,5.6-19.4c3.9-4.5,9.6-7.1,16.5-7.6
                C544.6,604.5,545.3,604.5,546,604.5 M546,590.5L546,590.5c-1,0-2,0-3.1,0.1c-10.6,0.7-19.6,5-26,12.4c-6.7,7.7-9.8,17.9-9,29.6
                l5.7,82.5c0.9,13.3,5.3,23.4,13.1,30.2c6.7,5.8,15.5,8.8,26,8.8c1.4,0,2.9-0.1,4.3-0.2l0,0c2.2-0.1,9.9-0.6,40-2.5l0,0l0,0
                c10.2-0.7,18.8-4.7,24.8-11.6c5.7-6.6,8.5-15.3,7.8-24.7c-0.1-1.4-0.3-2.7-0.5-4c-1.4-7.8-5.1-14.5-10.7-19.4
                c-6.2-5.4-14.2-8.2-23.1-8.2c-1,0-1.9,0-2.9,0.1l-5.2,0.3c-1.4-20.7-3.9-56.5-3.9-56.5c-0.1-1.8-0.3-3.5-0.6-5.2
                c-1.6-9.3-5.9-17.3-12.4-22.9C563.8,593.5,555.4,590.5,546,590.5L546,590.5z"
            />
          </g>
          <g>
            <path
              className="st0"
              d="M857.5,762.2c-1.6,0-3.1-0.1-4.7-0.4c-14.2-2.1-23.3-10.1-29.8-25.8l-10.2-24.7l-1.9,11.9
                c-2.6,17-13.5,27.2-29,27.2c-1.9,0-3.8-0.2-5.8-0.5c-16-2.5-25.1-17.6-22.1-36.7l13.7-88.5c1.2-8,4.4-14.7,9.3-19.5
                c5-4.9,11.5-7.5,18.7-7.5c2.6,0,5.4,0.4,8.1,1c11.6,2.9,19.6,10.1,24.3,21.9c1,2,7.8,16.5,14.3,30.8l2.3-15.1
                c2.5-16.4,12.9-26.6,27-26.6c1.9,0,3.9,0.2,6,0.6c14.8,2.8,24,17.7,21.4,34.6l-14.2,91.6C882.4,752.1,871.7,762.2,857.5,762.2
                C857.5,762.2,857.5,762.2,857.5,762.2z"
            />
            <path
              className="st0"
              d="M795.6,604.8c2,0,4.2,0.3,6.4,0.8c9.2,2.3,15.5,7.7,19.6,18c0,0,17.6,36.9,23.9,53
                c2.4-15.4,6.1-39.1,6.1-39.1c1.9-12.4,9.1-20.7,20.1-20.7c1.5,0,3,0.1,4.7,0.5c10.2,1.9,17.9,12.5,15.7,26.6l-14.2,91.6
                c-2,12.7-10,19.7-20.4,19.7c-1.2,0-2.4-0.1-3.7-0.3c-11-1.7-18.5-7.2-24.3-21.5c0,0-13.6-32.8-19.8-47.8
                c-2.3,14.6-5.7,36.6-5.7,36.6c-2.1,13.6-10.2,21.3-22.1,21.3c-1.5,0-3.1-0.1-4.7-0.4c-11.9-1.9-18.7-13.4-16.3-28.7l13.7-88.5
                C776.6,612.5,784.8,604.8,795.6,604.8 M795.6,590.8L795.6,590.8c-18.1,0-31.8,12.9-34.9,32.9L747,712.2
                c-3.5,22.8,8.2,41.6,28,44.6c2.3,0.4,4.7,0.5,6.9,0.5c15.4,0,27.4-8.4,33.1-22.5l1.6,3.8c7.4,18,18.6,27.6,35.2,30.1
                c1.9,0.3,3.9,0.4,5.8,0.4c17.8,0,31.3-12.4,34.2-31.5l14.2-91.6c1.5-9.9-0.3-19.7-5.2-27.5c-5-8-12.8-13.4-21.8-15.1
                c-2.5-0.5-4.9-0.7-7.3-0.7c-15.4,0-27.5,9.8-32.4,25.5c-2.5-5.3-4.3-9.2-4.9-10.4c-5.6-13.7-15.4-22.4-29-25.9
                C802.2,591.2,798.9,590.8,795.6,590.8L795.6,590.8L795.6,590.8z"
            />
          </g>
          <g>
            <path
              className="st0"
              d="M960.6,784.2c-6.2,0-12.7-0.9-19.4-2.6l-33.1-8.6c-10.6-2.7-18-7.8-22-15c-4-7.1-4.6-16.2-1.8-27l19.7-75.6
                c2.9-11.2,7.9-18.7,15.2-23c4.3-2.5,9.2-3.8,14.6-3.8c3.5,0,7.4,0.5,11.5,1.6l33.1,8.6c16.4,4.4,31.7,16.7,41.1,32.7
                c10.2,17.4,12.8,37.5,7.5,57.9c-5.4,20.6-16.2,35.5-33.2,45.5C983,781.1,972.2,784.2,960.6,784.2
                C960.6,784.2,960.6,784.2,960.6,784.2z M944.9,729.1c2.4,0.5,4.7,0.8,6.9,0.8c9.1,0,14.9-5,17.8-15.3c1.3-7,1-11.6-1-16.5
                l-0.1-0.2l-0.1-0.2c-1.3-3.5-6-6.7-13-8.8L944.9,729.1z"
            />
            <path
              className="st0"
              d="M933.8,635.5c3,0,6.2,0.5,9.7,1.4l33.1,8.6c15.3,4.2,28.8,15.8,36.8,29.5c8.4,14.4,12,32.5,6.7,52.6
                c-5.3,20.2-15.7,32.8-30,41.2c-9.1,5.3-18.6,8.4-29.5,8.4c-5.5,0-11.4-0.8-17.7-2.4l-33.1-8.6c-17.4-4.5-23.5-15.5-18.8-33.5
                l19.7-75.6c2.4-9.3,6.3-15.4,12-18.7C925.9,636.5,929.5,635.5,933.8,635.5 M951.7,736.9c12.4,0,21-7.2,24.7-20.7
                c1.6-8.1,1.4-14.3-1.4-20.8c-2.3-6.4-9.4-11.1-19.6-13.7c0,0-2.6-0.7-5-1.3c-1.6,6-12.5,47.8-14,53.8c2.5,0.6,5,1.3,5,1.3
                C945.1,736.4,948.5,736.9,951.7,736.9 M933.8,621.5c-6.7,0-12.8,1.6-18.2,4.7c-8.9,5.2-15.1,14.4-18.4,27.2l-19.7,75.6
                c-3.3,12.6-2.4,23.5,2.5,32.2c5,9,13.9,15.1,26.4,18.4l33.1,8.6c7.3,1.9,14.4,2.9,21.2,2.9c12.8,0,24.8-3.4,36.6-10.3
                c18.7-11,30.6-27.2,36.5-49.8c5.8-22.3,3-44.2-8.2-63.3c-10.3-17.6-27.2-31-45.2-35.9l-0.1,0l-0.1,0l-33.1-8.6
                C942.4,622.1,937.9,621.5,933.8,621.5L933.8,621.5z M953.8,722.7l5.3-20.5l1-3.8c1.1,0.7,1.6,1.3,1.7,1.6l0.1,0.4l0.2,0.4
                c1.3,3,1.8,6,0.6,12.1C961.5,717.3,959.6,721.9,953.8,722.7L953.8,722.7z"
            />
          </g>
          <g>
            <g>
              <path
                className="st0"
                d="M1027,800.9c-3.2,0-6.3-0.8-9.1-2.3c-4.4-2.4-7.6-6.4-9-11.3c-1.4-4.9-0.9-10.1,1.6-14.5
                    c3.3-6,9.8-9.8,16.8-9.8c3.2,0,6.3,0.8,9,2.3c4.4,2.4,7.6,6.5,9,11.4c1.4,4.9,0.9,10.1-1.5,14.5
                    C1040.4,797.2,1034,800.9,1027,800.9z"
              />
              <path
                className="st0"
                d="M1027.2,769.9c1.9,0,3.9,0.5,5.7,1.4c5.7,3.1,7.9,10.6,4.7,16.3c-2.2,4-6.4,6.2-10.6,6.2
                    c-2,0-3.9-0.5-5.7-1.5c-5.7-3.1-7.9-10.5-4.7-16.3C1018.7,772.2,1022.9,769.9,1027.2,769.9 M1027.2,755.9L1027.2,755.9
                    c-9.6,0-18.4,5.1-22.9,13.4c-3.4,6.1-4.1,13.2-2.1,19.9c2,6.7,6.4,12.2,12.4,15.5c3.8,2.1,8.1,3.2,12.5,3.2
                    c9.5,0,18.3-5.2,22.9-13.4c3.3-6,4.1-13.1,2.1-19.8c-1.9-6.7-6.3-12.3-12.4-15.6C1035.9,757,1031.6,755.9,1027.2,755.9
                    L1027.2,755.9z"
              />
            </g>
            <g>
              <path
                className="st0"
                d="M1027,807c-4.1,0-8.1-1-11.7-2.9l-0.2-0.1c-5.9-3.2-10.1-8.5-12-14.9c-1.9-6.5-1.2-13.3,2.1-19.1
                    c4.4-7.9,12.9-12.9,22.2-12.9c4.1,0,8.1,1,11.6,2.9l0.2,0.1c12,6.6,16.5,22.2,9.9,34.1C1044.6,802,1036.2,807,1027,807z"
              />
              <path
                className="st0"
                d="M1027.3,763.9c2.8,0,5.7,0.7,8.3,2l0.2,0.1c8.6,4.7,11.8,16,7.1,24.5c-3.3,5.9-9.5,9.3-15.9,9.3
                    c-2.9,0-5.8-0.7-8.4-2.1l-0.2-0.1c-4.2-2.3-7.2-6.1-8.6-10.7c-1.4-4.7-0.8-9.6,1.5-13.8C1014.5,767.3,1020.8,763.9,1027.3,763.9
                     M1027,787.9c2.1,0,4.3-1.1,5.3-3.1c1.5-2.8,0.4-6.6-2.4-8.1l-0.1,0c-0.8-0.4-1.8-0.7-2.7-0.7c-2.2,0-4.3,1.1-5.4,3.1
                    c-0.8,1.4-0.9,3-0.5,4.6c0.5,1.6,1.5,2.8,2.8,3.6l0.1,0C1025.1,787.7,1026.1,787.9,1027,787.9"
              />
            </g>
          </g>
          <g>
            <path
              className="st0"
              d="M340.7,785.6c-5.1,0-9.8-1.2-13.9-3.5c-7.4-4.1-12.6-11.5-15.7-22.6l-21.4-75.2c-6.1-21.5,2-36.7,22.9-42.6
                l25.4-6.5c8.3-2.4,15.3-3.5,21.5-3.5c7.8,0,14.7,1.8,21.5,5.6c9.1,5.1,15.3,13.2,18.4,24.2c2,6.9,3.9,15.7,2.1,23.8
                c10.3,4.9,18.3,15.4,22.3,29.2c3.5,12.3,2.1,24.9-4,35.3c-7.2,12.4-20.2,21.3-38.7,26.6l-28.1,7.3
                C348.5,785,344.5,785.6,340.7,785.6C340.7,785.6,340.7,785.6,340.7,785.6z M363.1,728.2c2.2-0.8,3.3-1.6,3.6-2
                c0-0.1,0-0.3-0.1-0.5c-0.1-0.2-0.1-0.3-0.2-0.4c-0.2-0.1-0.5-0.1-1.2-0.1c-0.6,0-1.6,0.1-2.9,0.4L363.1,728.2z"
            />
            <path
              className="st0"
              d="M359.5,638.7c6.9,0,12.6,1.6,18.1,4.7c7.4,4.1,12.5,10.8,15.1,20c2.1,7.4,3.5,14.9,1.8,21
                c-0.9,3.3-0.9,3.3-2.1,5.2c11.9,2.4,20.6,12.9,24.6,26.8c5.4,19-1.7,43-37.9,53.3l-28,7.3c-3.8,1.1-7.2,1.6-10.3,1.6
                c-4,0-7.4-0.9-10.5-2.6c-5.7-3.2-9.8-9.2-12.4-18.4l-21.4-75.2c-5.1-17.9,0.8-29.1,18.1-34l25.3-6.5
                C347.6,639.8,353.9,638.7,359.5,638.7 M345.6,694c4.7-1.3,4.9-1.5,4.9-1.5c3.5-1,5.4-3.1,6.1-5.5c0.4-1.4,0.4-2.9,0-4.2
                c-0.7-2.5-1.9-4.2-3.4-5.1c-0.9-0.5-1.9-0.7-3.2-0.7c-1.1,0-2.4,0.2-3.8,0.6c0,0-0.1,0.2-4.9,1.5
                C343.4,686.1,343.8,687.5,345.6,694 M358.3,737c5.6-1.6,5.2-1.6,5.2-1.6c4.7-1.3,7.9-3.4,9.3-5.9c0.2-0.4,0.4-0.9,0.5-1.3
                c0.4-1.3,0.3-2.8-0.1-4.4c-1.1-3.7-3.8-5.6-8.1-5.6c-1.8,0-3.9,0.3-6.3,1c0,0-0.4,0.3-5.2,1.6C355.7,727.8,356.4,730.4,358.3,737
                 M359.5,624.7c-6.9,0-14.3,1.2-23.3,3.7l-25.2,6.5l-0.2,0l-0.2,0c-12.4,3.5-21.1,9.9-26,19c-4.7,8.9-5.3,19.7-1.8,32.3l15.1,53.2
                l-13.1,3.7l14.5,1.2l4.9,17.1c3.6,12.8,10,21.8,19,26.8c5.2,2.9,11,4.3,17.3,4.3c4.4,0,9-0.7,14-2.1l27.9-7.2l0.2,0l0.2,0
                c20.2-5.8,34.6-15.8,42.8-29.8c7.1-12.1,8.7-26.6,4.7-40.8c-3.9-13.8-11.5-24.6-21.4-31c0.6-7.9-1.2-15.7-2.9-22
                c-3.6-12.7-11.1-22.5-21.7-28.4C376.6,626.9,368.4,624.7,359.5,624.7L359.5,624.7z"
            />
          </g>
          <g>
            <path
              className="st0"
              d="M1116.4,838.5c-8.7,0-17.9-1.9-27.3-5.6c0,0-0.8-0.3-1.1-0.5c-10.8-4.4-21.5-11.5-28.7-19
                c-10.4-10.8-13.7-22.6-9.3-33.3c4.6-11.1,14.7-18.3,25.9-18.3c2.2,0,4.4,0.3,6.6,0.8c-7.6-10-13.2-24.5-5.2-43.8
                c8.2-20.1,26-32.1,47.5-32.1c8.2,0,16.6,1.7,25,5l1,0.4c14.5,5.9,24.6,13.5,30.2,22.5c5.1,8.3,6,17.5,2.6,25.8
                c-4.3,10.5-13.7,17.1-24.5,17.1c-0.3,0-0.5,0-0.8,0c11.3,14.3,13.4,30.3,6.3,47.6C1156,826.4,1138.4,838.5,1116.4,838.5z"
            />
            <path
              className="st0"
              d="M1124.8,693.8c7.1,0,14.7,1.5,22.5,4.5c0.3,0.1,0.6,0.2,0.9,0.3c28.5,11.7,33.6,27.9,29,39.2
                c-3.3,8.1-10.3,12.7-18,12.7c-2.5,0-5-0.5-7.5-1.5l-0.3-0.1c-4.7-2.6-7.5-4.9-10.1-7.2c-2.6-2.3-6-4.8-8.7-6.5
                c-0.6-0.4-1.8-1-1.8-1c-0.5-0.2-0.9-0.3-1.4-0.3c-1.6,0-3.3,1.2-3.9,2.8c-0.1,1.8,1,2.9,3.3,4.5c2,1.6,3.9,2.7,6.2,4.5
                c2.1,1.6,3.9,3,6.5,5c2.1,1.7,4.7,3.9,7.4,6.5c8.5,8.9,18.3,23.3,9.3,45.2c-7.8,18.9-22.9,29-41.7,29c-7.7,0-16-1.7-24.8-5.1
                c-0.3-0.1-0.7-0.3-1-0.4c-17.2-7-41.6-25-34.2-43.2c3.6-8.8,11.3-14,19.5-14c2.4,0,4.9,0.5,7.3,1.4l0.3,0.1
                c3.8,1.6,6.9,4.4,10.2,7.4c3.9,3.6,7.9,7.5,14,10.2c0,0-0.1,0,1.4,0.7c1.4,0.6,2.8,1.3,4.2,1.3c0,0,0.1,0,0.1,0
                c1.9-0.2,2.8-1.6,3.4-2.4c0.3-0.6,1.3-2,0-3.7c-1.9-2.5-6.7-5.3-11.2-9c-1.6-1.3-3.2-2.6-4.9-4.1c-9.6-8-27.5-23-16.7-49.2
                C1091.1,703.7,1106.4,693.8,1124.8,693.8 M1124.8,679.8c-24.5,0-44.7,13.6-54,36.5c-5.6,13.6-5.8,27-0.7,39.1
                c-11.7,2.1-21.8,10.3-26.6,22.1c-7.4,18,3.5,33.3,10.7,40.8c7.8,8.2,19.5,15.9,31.1,20.7c0.3,0.1,0.7,0.3,1.1,0.4l0.1,0
                c10.3,4.1,20.4,6.1,30,6.1c24.9,0,44.9-13.7,54.7-37.7c5.3-12.8,6-25.3,2.3-37c-0.9-2.7-2-5.4-3.4-8c8.9-3.1,16.1-10,20-19.6
                c4.3-10.5,3.2-21.9-3.1-32.1c-6.4-10.3-17.6-18.8-33.5-25.3c-0.3-0.1-0.6-0.3-1-0.4l0,0l0,0
                C1143.1,681.7,1133.8,679.8,1124.8,679.8L1124.8,679.8z"
            />
          </g>
          <g>
            <path
              className="st0"
              d="M215.5,837.4c-3.9,0-7.8-0.7-11.3-2.2c-6.5-2.6-11.6-7.5-14.2-13.7c-2.9-6.9-3.6-17.2-4.2-27.1l0-0.8
                c0,0,1.2-77.4,1.3-82c-0.3-6.2-1.1-24.6,17.9-32.7l0,0c4-1.7,7.9-2.6,11.8-2.6c11,0,18.9,6.8,24,11.9
                c52.8,49.9,59.1,55.8,60,56.7l0,0l0.3,0.4c6.7,7.4,13.7,14.9,16.6,21.8c6.3,14.9-3.3,30.8-16.2,36.2c-4,1.7-8.3,2.6-12.5,2.6
                c0,0,0,0,0,0c-7.7,0-14.4-3-18.9-8.5l-1.1-1.3c0,0-20.3,8.6-24.4,10.3c0.1,0.6,0.1,1.2,0.1,1.6l0.1,0
                c1.5,12.6-7.7,22.5-17.6,26.7C223.7,836.5,219.6,837.4,215.5,837.4z"
            />
            <path
              className="st0"
              d="M216.8,683.4c8.1,0,14.3,5,19.1,9.9c0,0,59.8,56.5,59.8,56.5l0.3,0.4c6.3,6.9,12.9,14,15.3,19.8
                c4.7,11-2.7,22.9-12.5,27.1c-3,1.3-6.4,2-9.8,2c-5,0-10-1.7-13.5-5.9c0,0-2.6-3.2-4.4-5.3c-4.1,1.8-29.8,12.6-33.9,14.4
                c0.3,2.8,0.7,6.9,0.7,6.9l0,0c1.1,9.1-5.9,16.3-13.3,19.4c-2.9,1.2-6,1.8-9.1,1.8c-3,0-5.9-0.5-8.7-1.6c-4.8-1.9-8.5-5.5-10.4-10
                c-2.5-5.8-3.1-15.5-3.6-24.8c0,0,0-0.5,0-0.5c0,0,1.3-81.9,1.3-82c-0.5-9.3,0.7-20.6,13.6-26.1l-0.3,0.2
                C210.8,684,213.9,683.4,216.8,683.4 M233.3,768.4c4.8-2,11.5-4.9,16.3-6.9l-16.5-15.2C232.9,758,233.3,768.4,233.3,768.4
                 M216.8,669.4c-4.8,0-9.7,1.1-14.6,3.1l0,0c-9.2,3.9-16,10.6-19.4,19.4c-2.9,7.3-3,14.8-2.8,19.8c-0.1,6.3-1.3,80.7-1.3,81.5
                l0,0.5l0,0.5l0,0.6c0.6,10.5,1.3,21.4,4.7,29.4c3.4,7.9,9.8,14.1,18,17.5c4.3,1.7,9.1,2.7,13.9,2.7c5,0,10.1-1,14.6-2.9
                c11.3-4.8,21.9-15.8,22-30.3c3.6-1.5,7.7-3.3,11.6-4.9l3.5-1.5c5.7,5.4,13.3,8.3,21.8,8.3c5.1,0,10.4-1.1,15.2-3.1
                c7.9-3.4,14.7-9.7,18.7-17.5c4.6-9,5.1-19,1.3-28c-3.4-8-10.8-16.1-17.9-23.8l0,0l0,0l-0.3-0.4l0,0c-1.4-1.4-8.9-8.5-60.2-57
                C239.8,677.2,230.3,669.4,216.8,669.4L216.8,669.4z"
            />
          </g>
          <g>
            <path
              className="st0"
              d="M1213.1,892.5c-5.2,0-10.7-1.6-15.7-4.6l-0.3-0.2l-37.4-22.9c-13.8-8.4-17.9-24.4-10.1-38.9
                c6-11.1,39.5-66.5,47.9-78c5.5-8.2,14.2-13,24-13c5.4,0,10.8,1.5,15.5,4.3c8.2,5,21,13,36.1,22.6l0.2,0.1l0.2,0.1
                c7,5,11.2,10.4,13,16.4c2,7,0.9,14.4-3.1,21c-6.7,10.9-15,13.2-20.8,13.2c-1.4,0-2.9-0.1-4.4-0.4c0.8,1.4,1.4,2.9,1.9,4.5
                c2,7,0.9,14.5-3.1,21.1c-4.9,7.9-12.4,12.3-21.2,12.3c-0.9,0-1.8,0-2.8-0.1c2.4,2.8,4.1,5.8,5.1,9.1c2.2,6.9,1.2,14-2.9,20.7
                C1230.2,887.8,1222.1,892.5,1213.1,892.5z"
            />
            <path
              className="st0"
              d="M1221.4,742c4.1,0,8.2,1.1,11.9,3.3c11.9,7.3,29.7,18.5,36,22.5c4.9,3.5,8.9,7.6,10.3,12.7
                c1.5,5.1,0.6,10.5-2.4,15.4c-3.9,6.3-8.9,9.8-14.8,9.8c-3.3,0-7-1.1-10.9-3.4l-2.5-1.5l-16.7-10.1l-4.5,7.4l14.6,8.9
                c5.3,3.2,9.3,6.9,10.8,11.9c1.5,5.1,0.7,10.5-2.4,15.4c-3.7,6-9.1,8.9-15.2,8.9c-4,0-8.3-1.3-12.6-3.8l-1.4-0.7l-13.2-8.2l-4.9,8
                l17.2,10.5c10.9,6.7,14.6,17.2,8.4,27.3c-3.8,6.2-9.7,9.4-16.1,9.4c-3.9,0-8.1-1.2-12.1-3.6l-0.3-0.2l-37.3-22.8
                c-10.4-6.4-13.7-18.3-7.6-29.6c6.1-11.3,39.7-66.8,47.5-77.3C1207.5,745.3,1214.4,742,1221.4,742 M1221.4,728
                c-12,0-23.1,6-29.8,16c-8.7,12-42.3,67.6-48.3,78.7c-9.5,17.6-4.2,37.9,12.6,48.1l37.3,22.8l0.1,0l0.1,0l0.3,0.2
                c6.1,3.7,12.8,5.6,19.3,5.6c11.6,0,21.8-5.9,28.1-16.1c5.1-8.3,6.4-17.7,3.6-26.5c-0.1-0.4-0.2-0.7-0.4-1.1
                c7.5-2.2,14-7.2,18.4-14.4c4.3-7,5.9-14.7,4.8-22.3c6.4-1.2,14.8-5,21.5-16c5.1-8.2,6.4-17.7,3.9-26.6
                c-2.2-7.6-7.3-14.2-15.6-20.2l-0.3-0.2l-0.3-0.2c-15.5-9.8-27.9-17.6-36.1-22.6l0,0l0,0C1234.7,729.8,1228.1,728,1221.4,728
                L1221.4,728z"
            />
          </g>
          <g>
            <path
              className="st0"
              d="M119.1,893c-2.1,0-4.2-0.2-6.2-0.7c-8.3-2-15.2-7.7-21.2-17.5l-41-66.5c-11.7-19.1-8-35.8,10.5-47.2
                l22.7-13.2c12.2-7.5,22-10.9,31.6-10.9c3.4,0,6.8,0.4,10.4,1.3c10.1,2.4,18.3,8.6,24.3,18.3c3.7,6.1,8,14.1,8.4,22.3
                c11.3,1.9,21.8,9.8,29.4,22.1c6.7,10.9,8.7,23.4,5.7,35.1c-3.6,13.9-13.7,26-30,36.1l-25.1,14.6C131.6,890.9,125.3,893,119.1,893
                C119.1,893,119.1,893,119.1,893z M135.2,827.4c0.7,0.5,1.3,1,2,1.4c0.5-0.6,0.8-1,0.9-1.2c0-0.1-0.1-0.3-0.3-0.5
                c-0.1-0.2-0.2-0.3-0.2-0.3c0,0,0,0-0.1,0C137.3,826.8,136.6,826.8,135.2,827.4z"
            />
            <path
              className="st0"
              d="M115.5,743.9c2.9,0,5.8,0.4,8.7,1.1c8.2,2,14.9,7.1,19.9,15.2c4,6.6,7.4,13.4,7.4,19.7c0,3.4,0,3.4-0.6,5.5
                c0.7-0.1,1.3-0.1,2-0.1c11.3,0,21.8,7.6,29,19.3c10.4,16.8,10,41.8-22,61.5l-25,14.6c-5.7,3.5-10.9,5.3-15.8,5.3
                c-1.6,0-3.1-0.2-4.6-0.5c-6.4-1.5-11.9-6.2-16.9-14.4l-41-66.5c-9.8-15.9-7.1-28.2,8.2-37.6l22.6-13.1
                C98.8,746.8,107.3,743.9,115.5,743.9 M109.2,802.3c4.2-2.6,4.3-2.8,4.3-2.8c3.1-1.9,4.4-4.5,4.4-6.9c0-1.5-0.4-2.9-1.1-4.1
                c-1.4-2.2-2.9-3.6-4.6-4c-0.4-0.1-0.8-0.1-1.2-0.1c-1.6,0-3.4,0.6-5.5,1.9c0,0-0.1,0.2-4.3,2.8
                C104.8,795.3,105.6,796.5,109.2,802.3 M137.6,837.3c4.2-2.6,6.7-5.4,7.4-8.2c0.1-0.5,0.2-0.9,0.2-1.4c0-1.4-0.4-2.8-1.3-4.2
                c-1.5-2.5-3.6-3.7-6.3-3.7c-2.5,0-5.4,1-8.8,3.1c0,0-0.4,0.4-4.6,3C128,832.1,137.6,837.3,137.6,837.3 M115.5,729.9L115.5,729.9
                c-11,0-21.8,3.7-35.2,11.9l-22.5,13l-0.2,0.1l-0.2,0.1c-11,6.8-17.6,15.3-19.8,25.3c-2.1,9.8,0.2,20.4,7.1,31.5l41,66.5
                c7,11.3,15.6,18.3,25.6,20.6c2.5,0.6,5.2,0.9,7.8,0.9c7.5,0,15-2.4,23-7.3l24.9-14.5l0.1-0.1l0.1-0.1
                c17.9-11,29.1-24.6,33.1-40.3c3.5-13.6,1.2-28-6.5-40.5c-7.5-12.3-17.7-20.6-29-24.1c-1.5-7.7-5.4-14.7-8.8-20.3
                c-6.9-11.2-16.8-18.6-28.6-21.4C123.4,730.4,119.4,729.9,115.5,729.9L115.5,729.9z"
            />
          </g>
        </g>
      </g>
      <g id="text">
        <g>
          <g>
            <path
              className="st2"
              d="M752,721.5c0.2-6.3-2.8-15.5-5.7-24.4l-0.2-0.5c0,0-30.9-76.2-30.9-76.2c-3.2-8.8-8.6-18.8-22.7-18.9l0.3,0
                c-14.1-0.4-19.8,9.5-23.3,18.1c0,0-35.2,74-35.2,74c0,0-0.2,0.5-0.2,0.5c-3.4,8.7-6.8,17.8-7,24.1c-0.1,4.9,1.8,9.6,5.3,13.4
                c4.1,4.4,10.2,7.1,16.3,7.2c8,0.2,17.4-3.4,20.2-12.1c0,0,1.3-3.8,2.2-6.6c4.5,0.1,32.4,0.9,36.9,1c0.7,2.7,1.8,6.7,1.8,6.7
                c2.3,8.8,11.5,12.9,19.5,13.2C740,741.2,751.6,733.5,752,721.5z M700.3,688.1c-5.2-0.1-12.5-0.3-17.7-0.5l9-20.2L700.3,688.1z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M510.4,628.9c0-0.5-0.1-1-0.2-1.5c-1-5.8-4.5-11.2-9.5-14.7c-5-3.5-11.1-4.9-17.2-3.8
                c-9.6,1.7-16.4,8.1-19.2,18c0,0-6.3,13.6-9.3,24.1c-5.7-7.9-16.2-19.6-16.2-19.6c-6.1-8.3-14.7-12-24.3-10.3
                c-11.9,2.1-20,12.9-18.9,24.6c0.1,0.6,0.1,1.2,0.2,1.8c0.9,5,3.6,8.9,8.2,14.5c0,0,30.3,29.8,35.2,35.6
                c0.3,1.8,5.9,32.5,5.9,32.5c2.7,15.2,13.7,23.4,27.9,20.9c13.4-2.4,20.8-12.5,19.5-26.3c-0.1-1-0.2-1.9-0.4-2.9
                c0,0-5.6-30.6-5.9-32.5c0.7-1.8,21.1-45.6,21.1-45.6C509.6,637.7,510.8,633.3,510.4,628.9z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M609.5,702.2c-4-3.5-9.6-5.1-16-4.7c0,0-12.7,0.7-19.1,1.1c-0.5-6.6-4.9-70.3-4.9-70.3
                c-0.1-1.3-0.2-2.5-0.5-3.8c-1.1-6.2-3.7-11.2-7.8-14.7c-4.5-3.9-10.5-5.7-17.3-5.3c-6.8,0.5-12.5,3.1-16.5,7.6
                c-4.2,4.8-6.1,11.6-5.6,19.4l0,0l5.7,82.5c0.7,9.5,3.4,16.3,8.3,20.6c4.8,4.1,11.4,5.9,20.2,5.2c0,0,40.1-2.5,40.1-2.5
                c6.4-0.4,11.7-2.8,15.2-6.9c3.3-3.8,4.8-8.8,4.4-14.6c-0.1-0.9-0.2-1.7-0.3-2.6C614.7,708.8,612.7,705,609.5,702.2z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M876.4,617.3c-13.7-2.6-22.6,6.1-24.8,20.2c0,0-3.7,23.7-6.1,39.1c-6.3-16.1-23.9-53-23.9-53
                c-4.1-10.3-10.5-15.6-19.6-18c-13.9-3.5-25,4.4-27.5,20.2l-13.7,88.5c-2.4,15.3,4.4,26.8,16.3,28.7c14.4,2.3,24.5-5.6,26.9-20.9
                c0,0,3.4-22,5.7-36.6c6.2,15.1,19.8,47.8,19.8,47.8c5.9,14.4,13.3,19.9,24.3,21.5c12.1,1.8,21.9-5.2,24.1-19.4l14.2-91.6
                C894.3,629.8,886.6,619.2,876.4,617.3z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M1013.4,675c-8-13.7-21.5-25.3-36.8-29.5l-33.1-8.6c-8.6-2.2-15.4-1.8-20.8,1.4c-5.6,3.3-9.6,9.4-12,18.7
                L891,732.6c-4.7,18.1,1.5,29,18.8,33.5l33.1,8.6c18.8,4.9,33.5,2.1,47.2-6c14.4-8.4,24.8-21,30-41.2
                C1025.4,707.4,1021.8,689.3,1013.4,675z M941.4,735.4c0,0-2.6-0.7-5-1.3c1.6-6,12.5-47.8,14-53.8c2.5,0.6,5,1.3,5,1.3
                c10.2,2.6,17.3,7.3,19.6,13.7c2.8,6.6,3,12.7,1.4,20.8C971.7,733.1,959.5,740.1,941.4,735.4z"
            />
          </g>
          <g>
            <g>
              <path
                className="st2"
                d="M1032.9,771.4c5.7,3.1,7.9,10.6,4.7,16.3c-3.2,5.8-10.6,7.9-16.3,4.7c-5.7-3.1-7.9-10.5-4.7-16.3
                    C1019.7,770.4,1027.2,768.2,1032.9,771.4z"
              />
            </g>
            <g>
              <path
                className="st2"
                d="M1018.6,797.8l-0.2-0.1c-4.2-2.3-7.2-6.1-8.6-10.7c-1.4-4.7-0.8-9.6,1.5-13.8c4.7-8.5,15.8-11.8,24.3-7.2
                    l0.2,0.1c8.6,4.7,11.8,16,7.1,24.5C1038.1,799.2,1027.2,802.5,1018.6,797.8z M1029.9,776.6c-2.8-1.5-6.6-0.4-8.1,2.4
                    c-0.8,1.4-0.9,3-0.5,4.6c0.5,1.6,1.5,2.8,2.8,3.6l0.1,0c2.8,1.5,6.5,0.4,8.1-2.4C1033.9,782,1032.8,778.2,1029.9,776.6
                    L1029.9,776.6z"
              />
            </g>
          </g>
          <g>
            <path
              className="st2"
              d="M417,716.4c-4-13.9-12.7-24.5-24.6-26.8c1.2-1.9,1.2-1.9,2.1-5.2c1.7-6.1,0.3-13.5-1.8-21
                c-2.6-9.1-7.7-15.9-15.1-20c-10-5.6-20.5-6.4-37.8-1.5l-25.3,6.5c-17.3,4.9-23.2,16-18.1,34l21.4,75.2
                c2.6,9.2,6.7,15.2,12.4,18.4c5.5,3.1,12.3,3.4,20.9,1l28-7.3C415.3,759.4,422.4,735.4,417,716.4z M373.3,723.8
                c0.5,1.7,0.5,3.1,0.1,4.4c-0.1,0.5-0.3,0.9-0.5,1.3c-1.4,2.5-4.6,4.6-9.3,5.9c0,0,0.4,0-5.2,1.6c-1.9-6.7-2.6-9.2-4.6-16.3
                c4.8-1.4,5.2-1.6,5.2-1.6C366.9,716.9,371.7,718.4,373.3,723.8z M353.3,677.7c1.5,0.8,2.7,2.6,3.4,5.1c0.4,1.3,0.4,2.8,0,4.2
                c-0.7,2.3-2.6,4.5-6.1,5.5c0,0-0.1,0.2-4.9,1.5c-1.9-6.6-2.3-8-4.2-14.9c4.7-1.3,4.9-1.5,4.9-1.5
                C349.2,676.8,351.6,676.8,353.3,677.7z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M1091.6,826.4c-0.3-0.1-0.7-0.3-1-0.4c-17.2-7-41.6-25-34.2-43.2c4.7-11.3,16.2-16.7,26.8-12.5l0.3,0.1
                c3.8,1.6,6.9,4.4,10.2,7.4c3.9,3.6,7.9,7.5,14,10.2c0,0-0.1,0,1.4,0.7c1.5,0.6,2.9,1.3,4.3,1.3c1.9-0.2,2.8-1.6,3.4-2.4
                c0.3-0.6,1.3-2,0-3.7c-1.9-2.5-6.7-5.3-11.2-9c-1.6-1.3-3.2-2.6-4.9-4.1c-9.6-8-27.5-23-16.7-49.2c10.2-24.9,35.7-34.2,63.5-23.3
                c0.3,0.1,0.6,0.2,0.9,0.3c28.5,11.7,33.6,27.9,29,39.2c-4.4,10.6-15.1,15.4-25.5,11.2l-0.3-0.1c-4.7-2.6-7.5-4.9-10.1-7.2
                c-2.6-2.3-6-4.8-8.7-6.5c-0.6-0.4-1.8-1-1.8-1c-2-0.9-4.5,0.4-5.3,2.5c-0.1,1.8,1,2.9,3.3,4.5c2,1.6,3.9,2.7,6.2,4.5
                c2.1,1.6,3.9,3,6.5,5c2.1,1.7,4.7,3.9,7.4,6.5c8.5,8.9,18.3,23.3,9.3,45.2C1147.3,829.1,1121.8,838.3,1091.6,826.4z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M311.3,770c-2.5-5.8-9-12.9-15.3-19.8l-0.3-0.4c0,0-59.8-56.5-59.8-56.5c-6.5-6.7-15.6-13.5-28.5-7.8
                l0.3-0.2c-13,5.5-14.1,16.9-13.6,26.1c0,0-1.3,82-1.3,82c0,0,0,0.5,0,0.5c0.6,9.3,1.2,19,3.6,24.8c1.9,4.5,5.6,8,10.4,10
                c5.6,2.3,12.2,2.2,17.8-0.2c7.4-3.1,14.4-10.4,13.3-19.4c0,0-0.4-4-0.7-6.9c4.1-1.8,29.8-12.6,33.9-14.4c1.8,2.2,4.4,5.3,4.4,5.3
                c5.8,7,15.8,7,23.2,3.9C308.6,792.9,316,781,311.3,770z M249.6,761.5c-4.8,2-11.5,4.9-16.3,6.9c0,0-0.4-10.5-0.2-22.1
                L249.6,761.5z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M1200.9,881.9l-0.3-0.2l-37.3-22.8c-10.4-6.4-13.7-18.3-7.6-29.6c6.1-11.3,39.7-66.8,47.5-77.3
                c6.9-10.5,20.2-12.6,30.2-6.6c11.9,7.3,29.7,18.5,36,22.5c4.9,3.5,8.9,7.6,10.3,12.7c1.5,5.1,0.6,10.5-2.4,15.4
                c-6.1,9.9-14.9,12.8-25.7,6.4l-2.5-1.5l-16.7-10.1l-4.5,7.4l14.6,8.9c5.3,3.2,9.3,6.9,10.8,11.9c1.5,5.1,0.7,10.5-2.4,15.4
                c-6.1,9.9-17,11.5-27.8,5.1l-1.4-0.7l-13.2-8.2l-4.9,8l17.2,10.5c10.9,6.7,14.6,17.2,8.4,27.3
                C1223.1,886,1211.7,888.3,1200.9,881.9z"
            />
          </g>
          <g>
            <path
              className="st2"
              d="M181.9,804.5c-7.6-12.3-18.9-20.1-30.9-19.2c0.6-2.1,0.6-2.1,0.6-5.5c-0.1-6.3-3.4-13.1-7.4-19.7
                c-5-8.1-11.7-13.2-19.9-15.2c-11.2-2.7-21.4-0.6-36.8,8.8l-22.6,13.1c-15.3,9.4-18,21.7-8.2,37.6l41,66.5
                c5,8.2,10.5,12.9,16.9,14.4c6.1,1.5,12.8-0.1,20.3-4.7l25-14.6C191.9,846.4,192.3,821.4,181.9,804.5z M143.8,823.5
                c0.9,1.5,1.3,2.9,1.3,4.2c0,0.5-0.1,0.9-0.2,1.4c-0.7,2.8-3.2,5.7-7.4,8.2c0,0,0.4-0.1-4.6,3c-3.6-5.9-5-8.2-8.9-14.4
                c4.2-2.6,4.6-3,4.6-3C135.8,818.6,140.9,818.7,143.8,823.5z M112.1,784.6c1.7,0.4,3.3,1.7,4.6,4c0.7,1.2,1.2,2.6,1.1,4.1
                c0,2.4-1.3,5-4.4,6.9c0,0-0.1,0.2-4.3,2.8c-3.6-5.8-4.3-7-8.1-13.2c4.2-2.6,4.3-2.8,4.3-2.8C108,784.7,110.2,784.1,112.1,784.6z"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
}
