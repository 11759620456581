/* eslint-disable max-len */
const programName = 'Woodwick'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 308.70135 46.2598">
      <g id="Text_Elements" dataName="Text &amp; Elements" transform="translate(-25.65,-48.88)">
        <path className="cls-1" d="m 252.24,58.15 a 3.38,3.38 0 0 1 1,-2.44 3.34,3.34 0 0 1 2.43,-1 3.19,3.19 0 0 1 2.41,1 3.51,3.51 0 0 1 0,4.91 3.23,3.23 0 0 1 -2.41,1 3.45,3.45 0 0 1 -3.43,-3.49" id="path826" />
        <path className="cls-1" d="M 72.33,95.12 H 70.76 L 57.24,58.39 45.31,95.12 H 43.67 L 32.85,60.46 q -1.44,-4.74 -2.09,-6.3 A 5,5 0 0 0 29.08,51.86 6.5,6.5 0 0 0 25.65,51 V 49.49 H 42.49 V 51 c -2,0.12 -3.32,0.4 -3.9,0.86 a 2.24,2.24 0 0 0 -0.85,1.88 28.11,28.11 0 0 0 1.38,5.54 l 8,25.94 11.63,-35.73 h 2 l 13.02,35.77 6.78,-23.62 a 30.36,30.36 0 0 0 1.36,-6.27 c 0,-2.9 -1.54,-4.33 -4.63,-4.33 V 49.49 H 89.44 V 51 a 5.13,5.13 0 0 0 -4,2 c -0.88,1.2 -2,4.05 -3.34,8.58 z" id="path828" />
        <path className="cls-1" d="m 86.44,80.57 a 14,14 0 0 1 4.14,-10.31 14.65,14.65 0 0 1 20.4,0 14,14 0 0 1 4.14,10.28 c 0,4.52 -1.41,8.07 -4.2,10.66 a 14.49,14.49 0 0 1 -10.16,3.89 14.21,14.21 0 0 1 -10.14,-4 Q 86.43,87.16 86.44,80.5 m 5.51,0.16 q 0,7.14 2.32,10 a 8.91,8.91 0 0 0 13.06,0 q 2.28,-2.87 2.28,-10 0,-7.13 -2.26,-10.08 a 8.7,8.7 0 0 0 -13.13,0 Q 92,73.63 92,80.73" id="path830" />
        <path className="cls-1" d="m 120.11,80.57 a 14,14 0 0 1 4.14,-10.31 14.65,14.65 0 0 1 20.4,0 14,14 0 0 1 4.14,10.28 q 0,6.78 -4.21,10.66 a 14.42,14.42 0 0 1 -10.14,3.89 14.16,14.16 0 0 1 -10.14,-4 q -4.18,-3.93 -4.19,-10.59 m 5.51,0.16 q 0,7.14 2.33,10 a 8.89,8.89 0 0 0 13,0 q 2.28,-2.87 2.28,-10 0,-7.13 -2.23,-10.01 a 8.7,8.7 0 0 0 -13.13,0 q -2.27,3 -2.27,10.08" id="path832" />
        <path className="cls-1" d="M 176.65,70.12 V 59.51 c 0,-2.89 0,-4.71 -0.12,-5.45 a 2.37,2.37 0 0 0 -0.94,-1.73 5.48,5.48 0 0 0 -2.94,-0.58 H 172 v -1.44 a 35.7,35.7 0 0 0 8.7,-1.43 h 0.68 v 36.26 a 53.5,53.5 0 0 0 0.17,5.72 2,2 0 0 0 1.05,1.51 7.3,7.3 0 0 0 3.28,0.49 v 1.44 a 49.54,49.54 0 0 0 -8.47,0.82 21.29,21.29 0 0 1 -0.41,-4.89 18,18 0 0 1 -5,3.66 12.37,12.37 0 0 1 -5.33,1.23 11.76,11.76 0 0 1 -9,-4.19 14.62,14.62 0 0 1 -3.8,-10.17 14.66,14.66 0 0 1 3.87,-10.59 12.4,12.4 0 0 1 9.24,-4 13.53,13.53 0 0 1 5.15,1 15.4,15.4 0 0 1 4.61,3 m 0,1.92 a 17.56,17.56 0 0 0 -4.35,-2.82 10.68,10.68 0 0 0 -4.36,-0.94 7.84,7.84 0 0 0 -6.17,2.84 q -2.47,2.84 -2.48,9.3 c 0,4.35 0.85,7.58 2.54,9.64 a 7.79,7.79 0 0 0 6.28,3.11 9.26,9.26 0 0 0 4.52,-1.22 12.8,12.8 0 0 0 3.93,-3.6 z" id="path834" />
        <path className="cls-1" d="m 233,95.12 h -1.57 L 217.91,58.39 206,95.12 h -1.64 L 193.53,60.46 q -1.47,-4.74 -2.1,-6.3 a 5,5 0 0 0 -1.67,-2.3 6.58,6.58 0 0 0 -3.45,-0.82 v -1.55 h 16.84 V 51 c -2,0.12 -3.31,0.4 -3.87,0.86 a 2.17,2.17 0 0 0 -0.86,1.88 27.35,27.35 0 0 0 1.38,5.54 l 8,25.94 11.61,-35.77 h 2 l 13.06,35.77 6.79,-23.62 a 30.8,30.8 0 0 0 1.35,-6.27 c 0,-2.9 -1.54,-4.33 -4.62,-4.33 v -1.51 h 12.15 V 51 a 5.12,5.12 0 0 0 -4,2 c -0.9,1.2 -2,4.05 -3.34,8.58 z" id="path836" />
        <path className="cls-1" d="m 258.57,66.13 v 18.8 c 0,2.87 0,4.69 0.13,5.44 a 2.37,2.37 0 0 0 0.95,1.7 5.31,5.31 0 0 0 2.93,0.6 h 0.68 v 1.42 h -14.49 v -1.42 h 1.12 c 1.56,0 2.58,-0.23 3,-0.68 a 2.63,2.63 0 0 0 0.83,-1.62 c 0.07,-0.64 0.12,-2.46 0.12,-5.48 V 76.7 c 0,-3.18 -0.15,-5.11 -0.45,-5.81 a 2.13,2.13 0 0 0 -1.19,-1.33 8.44,8.44 0 0 0 -2.57,-0.26 h -0.9 v -1.43 a 41.1,41.1 0 0 0 9.08,-1.74 z" id="path838" />
        <path className="cls-1" d="m 290.44,87.46 h 1.43 q -2.24,7.65 -11.21,7.66 a 13.2,13.2 0 0 1 -9.68,-3.9 Q 267,87.34 267,80.89 a 14.18,14.18 0 0 1 4.23,-10.66 13.89,13.89 0 0 1 10,-4.1 13.75,13.75 0 0 1 6.42,1.51 q 2.91,1.52 2.91,3.69 a 2.5,2.5 0 0 1 -0.52,1.53 1.83,1.83 0 0 1 -1.55,0.71 c -1.43,0 -2.3,-0.84 -2.62,-2.52 a 4.86,4.86 0 0 0 -1.22,-2.64 5.24,5.24 0 0 0 -3.32,-0.75 7.65,7.65 0 0 0 -6.61,3.06 c -1.48,2 -2.23,5.11 -2.23,9.22 0,4.11 0.8,7.55 2.4,9.94 a 8,8 0 0 0 7.21,3.6 c 4.34,0 7.09,-2 8.29,-6" id="path840" />
        <path className="cls-1" d="M 311.12,67.15 H 322 v 1.42 q -3,0.09 -8,4.5 -1.61,1.4 -5,4.07 l 9.55,11.31 a 17.84,17.84 0 0 0 3.45,3.36 5.46,5.46 0 0 0 2.56,0.86 v 1.42 h -13.44 v -1.42 c 2.23,-0.06 3.35,-0.53 3.35,-1.46 0,-0.43 -0.74,-1.48 -2.23,-3.21 L 305.41,79.91 304,81 v 3.9 c 0,2.91 0,4.73 0.11,5.48 a 2.38,2.38 0 0 0 0.92,1.71 4.9,4.9 0 0 0 2.85,0.62 v 1.42 h -13.59 v -1.46 h 1 c 1.59,0 2.6,-0.23 3.08,-0.68 a 2.56,2.56 0 0 0 0.8,-1.62 c 0.07,-0.63 0.1,-2.46 0.1,-5.45 V 57.84 c 0,-2.63 -0.25,-4.28 -0.74,-5 -0.49,-0.72 -1.67,-1.09 -3.54,-1.09 v -1.43 a 28,28 0 0 0 4.61,-0.49 15.9,15.9 0 0 0 3.69,-0.95 H 304 v 30.3 l 2.83,-2 c 4.61,-3.29 6.93,-5.66 6.93,-7.09 0,-1 -0.89,-1.49 -2.62,-1.55 z" id="path842" />
        <path className="cls-1" d="m 334.35,91.33 a 3.71,3.71 0 1 1 -3.67,-3.61 3.63,3.63 0 0 1 3.67,3.61 m -6.49,0 a 2.81,2.81 0 0 0 2.82,2.88 2.76,2.76 0 0 0 2.75,-2.87 2.79,2.79 0 1 0 -5.57,0 m 2.22,1.87 h -0.83 v -3.62 a 10.07,10.07 0 0 1 1.38,-0.12 2,2 0 0 1 1.24,0.29 0.9,0.9 0 0 1 0.37,0.77 0.94,0.94 0 0 1 -0.75,0.86 v 0 c 0.36,0.12 0.53,0.41 0.65,0.89 a 3.25,3.25 0 0 0 0.25,0.89 h -0.88 a 3.08,3.08 0 0 1 -0.29,-0.89 c -0.06,-0.37 -0.27,-0.55 -0.74,-0.55 h -0.4 z m 0,-2 h 0.39 c 0.46,0 0.84,-0.14 0.84,-0.52 0,-0.38 -0.24,-0.55 -0.77,-0.55 a 2.11,2.11 0 0 0 -0.46,0 z" id="path844" />
      </g>
    </svg>
  ),
}
