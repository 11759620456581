/* eslint-disable max-len */
const programName = 'DPJ'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 254.6 50.1">
      <g id="Group_16628">
        <g id="Group_16627">
          <g id="Group_16626">
            <g id="Group_16625">
              <g id="Group_16548">
                <path
                  id="Path_33688"
                  d="M27.1,3.7c-0.7-0.7-1.2-0.5-1.2,0.5v14.7c-6.8-5.5-16.9-4.5-22.4,2.4S-1,38.1,5.9,43.7
                                s16.9,4.5,22.4-2.4c2.3-2.8,3.6-6.4,3.5-10V10.3c-0.1-1.1-0.5-2.2-1.2-3L27.1,3.7z M16.2,41.3c-5.5,0-10-4.5-10-10
                                s4.5-10,10-10s10,4.5,10,10S21.7,41.3,16.2,41.3z"
                />
                <path
                  id="Path_33689"
                  d="M80.5,9.6c-0.2-1-0.7-2-1.5-2.7l-3.3-3.2C75,3,74.5,3.3,74.6,4.2c0,0,0.4,13.4-0.1,17.7
                                C72,38.8,57.1,40.8,54.3,41.4c-1.5,0.4-0.9,3.4-0.9,3.4c0.6,2.7,1.7,2.3,1.7,2.3c15.1-1.8,25.2-13.7,25.4-27.4
                                C80.5,17.1,80.6,10.1,80.5,9.6z"
                />
                <path
                  id="Path_33690"
                  d="M69.1,19.2c0.2-8.8-6.7-16.1-15.5-16.3c-8.8-0.2-16.1,6.7-16.3,15.5c0,0.3,0,0.5,0,0.8v21
                                c0.1,1.1,0.5,2.2,1.2,3l3.5,3.5c0.7,0.7,1.2,0.5,1.2-0.5V31.6c6.8,5.5,16.9,4.5,22.4-2.4C67.9,26.4,69.1,22.8,69.1,19.2z
                                 M53.2,29.2c-5.5,0-10-4.5-10-10s4.5-10,10-10s10,4.5,10,10S58.7,29.2,53.2,29.2z"
                />
              </g>
              <path
                id="Path_33691"
                d="M144.7,17.9c-4.1,0-6.5,2.5-6.5,6.8v9.4h2.4v-9.4c0-2.9,1.4-4.5,4.1-4.5s4.1,1.6,4.1,4.5
                            v0.4h2.3v-0.3C151.1,20.5,148.8,17.9,144.7,17.9z M167,18.4h-3.3l-7.6,6.7V13.4h-2.4v20.8h2.4V27l8,7.1h3.4l-9.1-8.1L167,18.4z
                             M127.5,17.9c-4.6,0-8.3,3.6-8.4,8.2c0,4.6,3.6,8.3,8.2,8.4c4.6,0,8.3-3.6,8.4-8.2c0,0,0,0,0,0v-0.1c0.2-4.4-3.3-8.1-7.7-8.3
                            H127.5z M127.5,32.3c-3.3,0.1-6.1-2.5-6.2-5.8c-0.1-3.3,2.5-6.1,5.8-6.2c3.3-0.1,6,2.5,6.2,5.8v0.2c0.2,3.1-2.2,5.8-5.3,6
                            L127.5,32.3L127.5,32.3z M114.3,27.1c0,3.2-1.7,5.1-4.4,5.1s-4.4-1.9-4.4-5.1V13.4H103v13.7c0,3.1-1.7,5.1-4.4,5.1
                            s-4.4-1.9-4.4-5.1V13.4h-2.6v13.7c0,4.6,2.6,7.4,6.8,7.4c2.5,0.1,4.8-1.3,5.8-3.6c1,2.3,3.3,3.7,5.8,3.6c4.2,0,6.8-2.8,6.8-7.4
                            V13.4h-2.5L114.3,27.1L114.3,27.1z M191.3,17.9c-4.8,0-8.1,3.5-8.1,8.4V39h2.4v-7.7c1.3,2.1,3.7,3.3,6.1,3.2
                            c4.6,0,7.9-3.5,7.9-8.2c0.2-4.4-3.2-8.2-7.6-8.4c0,0-0.1,0-0.1,0L191.3,17.9z M191.3,32.3c-3.3,0.1-6.1-2.5-6.2-5.8
                            s2.5-6.1,5.8-6.2c3.3-0.1,6,2.5,6.2,5.8v0.2c0.2,3.1-2.2,5.8-5.3,6L191.3,32.3L191.3,32.3z M228,20.2c2.4-0.1,4.7,1.4,5.5,3.7
                            v0.1h2.4l-0.1-0.2c-0.9-3.6-4.1-6-7.8-5.9c-4.6-0.2-8.5,3.3-8.7,7.9s3.3,8.5,7.9,8.7c0.2,0,0.4,0,0.5,0h0.2
                            c3.7,0.1,6.9-2.3,7.8-5.9l0.1-0.2h-2.4v0.1c-0.8,2.3-3,3.9-5.5,3.7c-3.3,0-6-2.7-6-6C222,22.9,224.7,20.2,228,20.2z M254.7,26.2
                            c-0.1-4.5-3.8-8.1-8.3-8.1c-4.5,0.1-8.1,3.8-8.1,8.3c0.1,4.5,3.7,8.1,8.2,8.1c3.2,0.1,6.2-1.8,7.4-4.8l0.1-0.2h-2.6v0.1
                            c-1,1.8-3,2.8-5,2.7c-3,0.1-5.6-2.2-5.8-5.2h13.9L254.7,26.2z M240.8,24.9c0.6-3.1,3.7-5.2,6.8-4.6c2.3,0.5,4.1,2.3,4.6,4.6
                            H240.8z M209.7,17.9c-4.8,0-8.2,3.5-8.2,8.4c0,4.8,3.3,8.2,7.9,8.2c2.6,0.1,5-1.3,6.3-3.6v3.2h2.2v-7.8
                            C217.9,21.4,214.5,17.9,209.7,17.9z M209.7,32.3c-3.3,0.1-6.1-2.5-6.2-5.8s2.5-6.1,5.8-6.2c3.3-0.1,6,2.5,6.2,5.8
                            c0,0.1,0,0.1,0,0.2c0.2,3.1-2.2,5.8-5.3,6L209.7,32.3z M174.9,25.1h-0.3c-2-0.3-3.8-0.5-3.8-2.2c0-1.6,1.4-2.6,3.5-2.6
                            s3.5,1,3.6,2.6v0.2h2.3v-0.2c-0.1-2.8-2.4-4.8-5.9-4.8s-5.9,2-5.9,5c0,3.6,3.3,4,5.9,4.3h0.1c2.1,0.3,3.8,0.5,3.8,2.2
                            s-1.5,2.7-3.9,2.7s-3.8-1-3.9-2.7v-0.2h-2.4v0.2c0.1,2.9,2.6,4.9,6.3,4.9s6.3-2.1,6.3-5.1C180.7,25.8,177.2,25.3,174.9,25.1
                            L174.9,25.1z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>),
}
