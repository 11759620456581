/* eslint-disable max-len */
const programName = 'Mister Spex'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 236 46">
      <title>{programName}</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-344.000000, -66.000000)" fill="#1A1918">
          <g id="Group" transform="translate(344.000000, 66.000000)">
            <path d="M78.6821489,16.2717615 L78.5449007,16.2717615 L74.2613333,38.0234312 L72.2574255,38.0234312 L67.7914184,16.2717615 L67.7010355,16.2717615 L67.7010355,38.0234312 L64.1685674,38.0234312 L64.1685674,7.95377982 L69.2500993,7.95377982 L73.2600071,27.9511193 L73.3060355,27.9511193 L77.0874752,7.95377982 L82.6259433,7.95377982 L82.6259433,38.0234312 L78.6821489,38.0234312 L78.6821489,16.2717615" id="Fill-1" />
            <path d="M87.3902979,7.95377982 L91.3997872,7.95377982 L91.3997872,38.0234312 L87.3902979,38.0234312 L87.3902979,7.95377982 Z" id="Fill-2" />
            <path d="M95.7959149,31.5884954 L95.7959149,27.6759633 L99.5790284,27.6759633 L99.5790284,31.6809174 C99.5790284,33.7952294 100.58161,35.0819633 102.859177,35.0819633 C105.000752,35.0819633 105.957723,33.6568073 105.957723,31.6809174 L105.957723,30.2557615 C105.957723,28.1887156 105.182773,27.5835413 103.269667,25.7452294 L100.170702,22.6657615 C97.2537589,19.7719817 95.8888085,18.5679633 95.8888085,15.1677615 L95.8888085,14.0658716 C95.8888085,10.2964037 98.1667943,7.63093578 102.721929,7.63093578 C107.507206,7.63093578 109.55756,9.97482569 109.55756,14.2486055 L109.55756,17.1896514 L105.865248,17.1896514 L105.865248,14.1110275 C105.865248,11.9064037 104.817475,10.8939817 102.768376,10.8939817 C100.900461,10.8939817 99.6689929,11.9515596 99.6689929,14.0194495 L99.6689929,14.8921835 C99.6689929,16.8689174 100.763631,17.4736697 102.540326,19.2204037 L105.775284,22.4374495 C108.554142,25.2869174 109.92244,26.4436697 109.92244,29.8426055 L109.92244,31.4517615 C109.92244,35.4039633 107.734418,38.3433211 102.813567,38.3433211 C97.8927163,38.3433211 95.7959149,35.5415413 95.7959149,31.5884954" id="Fill-3" />
            <path d="M112.156489,7.95377982 L125.461617,7.95377982 L125.461617,11.2615596 L120.85878,11.2615596 L120.85878,38.0234312 L116.803681,38.0234312 L116.803681,11.2615596 L112.156489,11.2615596 L112.156489,7.95377982" id="Fill-4" />
            <path d="M128.648872,7.95377982 L140.542184,7.95377982 L140.542184,11.2615596 L132.660035,11.2615596 L132.660035,21.1038716 L138.355,21.1038716 L138.355,24.3664954 L132.660035,24.3664954 L132.660035,34.7139633 L140.542184,34.7139633 L140.542184,38.0234312 L128.648872,38.0234312 L128.648872,7.95377982" id="Fill-5" />
            <path d="M148.151092,11.2615596 L148.151092,21.5638716 L150.565908,21.5638716 C152.753092,21.5638716 153.756092,20.4602936 153.756092,18.4844037 L153.756092,14.2950275 C153.756092,12.2739817 152.753092,11.2615596 150.565908,11.2615596 L148.151092,11.2615596 L148.151092,11.2615596 Z M148.151092,23.5874495 L148.151092,38.0234312 L144.140766,38.0234312 L144.140766,7.95377982 L151.021589,7.95377982 C155.849965,7.95377982 157.764745,10.3432477 157.764745,14.4334495 L157.764745,18.1636697 C157.764745,21.7478716 156.260035,23.8166055 152.616262,24.2749174 L158.676943,38.0234312 L154.392957,38.0234312 L148.151092,23.5874495 L148.151092,23.5874495 Z" id="Fill-6" />
            <path d="M169.727518,31.5884954 L169.727518,27.6759633 L173.509794,27.6759633 L173.509794,31.6809174 C173.509794,33.7952294 174.511539,35.0819633 176.789106,35.0819633 C178.931936,35.0819633 179.888071,33.6568073 179.888071,31.6809174 L179.888071,30.2557615 C179.888071,28.1887156 179.113121,27.5835413 177.19834,25.7452294 L174.10105,22.6657615 C171.184525,19.7719817 169.818738,18.5679633 169.818738,15.1677615 L169.818738,14.0658716 C169.818738,10.2964037 172.096305,7.63093578 176.652695,7.63093578 C181.437972,7.63093578 183.486652,9.97482569 183.486652,14.2486055 L183.486652,17.1896514 L179.796851,17.1896514 L179.796851,14.1110275 C179.796851,11.9064037 178.750752,10.8939817 176.697887,10.8939817 C174.83039,10.8939817 173.599759,11.9515596 173.599759,14.0194495 L173.599759,14.8921835 C173.599759,16.8689174 174.693979,17.4736697 176.471511,19.2204037 L179.704376,22.4374495 C182.484908,25.2869174 183.851113,26.4436697 183.851113,29.8426055 L183.851113,31.4517615 C183.851113,35.4039633 181.664766,38.3433211 176.743496,38.3433211 C171.823482,38.3433211 169.727518,35.5415413 169.727518,31.5884954" id="Fill-7" />
            <path d="M191.465461,11.2615596 L191.465461,24.5057615 L194.017106,24.5057615 C196.205546,24.5057615 197.208965,23.4021835 197.208965,21.3798716 L197.208965,14.2950275 C197.208965,12.2739817 196.205546,11.2615596 194.017106,11.2615596 L191.465461,11.2615596 L191.465461,11.2615596 Z M187.456809,7.95377982 L194.473206,7.95377982 C199.302418,7.95377982 201.172426,10.3432477 201.172426,14.4334495 L201.172426,21.1034495 C201.172426,25.1472294 199.302418,27.6759633 194.473206,27.6759633 L191.465461,27.6759633 L191.465461,38.0234312 L187.456809,38.0234312 L187.456809,7.95377982 L187.456809,7.95377982 Z" id="Fill-8" />
            <path d="M204.886496,7.95377982 L216.777716,7.95377982 L216.777716,11.2615596 L208.895567,11.2615596 L208.895567,21.1038716 L214.59095,21.1038716 L214.59095,24.3664954 L208.895567,24.3664954 L208.895567,34.7139633 L216.777716,34.7139633 L216.777716,38.0234312 L204.886496,38.0234312 L204.886496,7.95377982" id="Fill-9" />
            <path d="M224.887078,22.0698716 L220.149085,7.95377982 L224.295823,7.95377982 L227.760085,18.7144037 L231.267447,7.95377982 L235.094915,7.95377982 L230.311312,22.2530275 L235.640979,38.0234312 L231.449468,38.0234312 L227.440397,25.7000734 L223.521291,38.0234312 L219.694241,38.0234312 L224.887078,22.0698716" id="Fill-10" />
            <path d="M39.7258298,34.9553578 C35.5451986,34.9553578 31.9746525,33.6593394 29.1087589,31.1010642 C27.4676383,29.6353945 25.9901277,28.0102018 24.7385745,26.5947523 C24.6938014,26.5728073 24.6615816,26.5453761 24.6368936,26.5158349 C24.0410355,25.8102202 23.3610709,25.5084771 22.5764965,25.5055229 C21.791922,25.5084771 21.1123759,25.8102202 20.5165177,26.5158349 C20.4914113,26.5453761 20.4596099,26.5728073 20.4148369,26.5947523 C19.1628652,28.0102018 17.685773,29.6353945 16.044234,31.1010642 C13.1787589,33.6593394 9.60779433,34.9553578 5.42716312,34.9553578 C3.7718156,34.9553578 2.00976596,34.7494128 0.190390071,34.3438532 L0.172397163,34.338789 L0.172397163,41.5105688 C0.172397163,42.6348257 1.01095035,43.2083486 2.18969504,43.5472294 C3.37053191,43.8852661 10.4748014,45.7041651 22.5764965,45.7041651 C34.6786099,45.7041651 41.782461,43.8852661 42.9632979,43.5472294 C44.142461,43.2083486 44.9810142,42.6348257 44.9810142,41.5105688 L44.9810142,34.338789 L44.9630213,34.3438532 C43.143227,34.7494128 41.3811773,34.9553578 39.7258298,34.9553578" id="Fill-11" />
            <path d="M31.1306596,16.4127156 C29.0192128,17.7918716 26.8801489,19.8091193 26.8801489,20.9975229 C26.8801489,21.1798349 26.8826596,21.343578 26.8901915,21.5001468 C26.8939574,21.5001468 26.8977234,21.5005688 26.9014894,21.5005688 C26.9609078,22.2066055 27.1868652,22.8197982 28.0396454,24.3462385 C28.0521986,24.3660734 28.0601489,24.3842202 28.0680993,24.403211 L28.0932057,24.4551193 C29.5359858,26.8315046 31.5327801,29.0635596 34.0308652,31.0879817 C35.8322482,32.5485872 38.134922,33.0841284 40.8744468,33.0841284 L40.8757021,33.0841284 C42.136461,33.0841284 43.5181489,32.9258716 44.9805957,32.614844 L44.9805957,23.3544954 L44.9810142,23.3549174 L44.9810142,13.5100734 C40.2543191,12.9386606 35.7648794,13.8789174 31.1306596,16.4127156" id="Fill-12" />
            <path d="M0.172815603,32.614844 C1.63484397,32.9258716 3.01695035,33.0841284 4.27770922,33.0841284 L4.27896454,33.0841284 C7.01848936,33.0841284 9.32074468,32.5485872 11.1225461,31.0879817 C13.6202128,29.0635596 15.6170071,26.8315046 17.0602057,24.4551193 L17.0853121,24.403211 C17.0932624,24.3842202 17.1007943,24.3660734 17.1133475,24.3462385 C17.9661277,22.8197982 18.1925035,22.2066055 18.2515035,21.5005688 C18.2556879,21.5005688 18.2594539,21.5001468 18.2632199,21.5001468 C18.2707518,21.343578 18.272844,21.1798349 18.272844,20.9975229 C18.272844,19.8091193 16.1341986,17.7918716 14.0223333,16.4127156 C9.38853191,13.8789174 4.8990922,12.9386606 0.172397163,13.5100734 L0.172397163,23.3549174 L0.172815603,23.3544954 L0.172815603,32.614844" id="Fill-13" />
            <path d="M4.56015603,11.0155229 C10.1082482,11.0155229 15.7973546,12.581633 20.1687943,15.3104037 L20.5181915,15.5193028 C21.2236809,15.9472294 22.0454965,16.3882385 22.5764965,16.3899266 C23.1074965,16.3882385 23.9293121,15.9472294 24.6348014,15.5193028 L24.9841986,15.3104037 C29.3560567,12.581633 35.0447447,11.0155229 40.5928369,11.0155229 C42.2799858,11.0155229 44.9680426,11.1961468 44.9680426,11.1961468 L44.9805957,4.42992661 C44.9805957,3.30651376 44.1152624,2.76084404 42.9632979,2.39453211 C41.810078,2.02779817 34.6380213,0.236752294 22.5764965,0.236752294 C10.5153901,0.236752294 3.34291489,2.02779817 2.18969504,2.39453211 C1.03814894,2.76084404 0.172815603,3.30651376 0.172815603,4.42992661 L0.184950355,11.1961468 C0.184950355,11.1961468 2.87342553,11.0155229 4.56015603,11.0155229" id="Fill-14" />
          </g>
        </g>
      </g>
    </svg>
  ),
}
