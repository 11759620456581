/* eslint-disable max-len */
const programName = 'Enemo'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 225 80">
      <title>{programName}</title>
      <defs>
        <linearGradient y1="100%" x2="0" y2="-6.52819012e-06%" id="3">
          <stop stopColor="#80b13d" offset="0%" />
          <stop stopColor="#5a7c2d" offset="69.611%" />
          <stop stopColor="#80b13d" offset="100%" />
        </linearGradient>
        <linearGradient x1="-50.5012685%" y1="84.0781323%" x2="160.522568%" y2="14.1258487%" id="2">
          <stop stopColor="#4b6725" offset="13.184%" />
          <stop stopColor="#80b13d" offset="47.23%" />
          <stop stopColor="#7cab3b" offset="57.303%" />
          <stop stopColor="#6f9a36" offset="70.85%" />
          <stop stopColor="#5b7e2e" offset="86.312%" />
          <stop stopColor="#5a7c2d" offset="87.225%" />
        </linearGradient>
        <linearGradient y1="100%" x2="0" y2="-1.88434887e-14%" id="0">
          <stop stopColor="#1e1f1d" offset="0%" />
          <stop stopColor="#494948" offset="100%" />
        </linearGradient>
        <linearGradient x1="-23.259248%" y1="81.3120967%" x2="148.926953%" y2="16.1867913%" id="1">
          <stop stopColor="#5a7c2d" offset="0%" />
          <stop stopColor="#80b13d" offset="43.461%" />
          <stop stopColor="#4b6725" offset="83.923%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g fill="url(#0)">
            <path d="m28.01 41.41c-2 2.916-5.355 4.834-9.154 4.834-5.492 0-10.05-4-10.949-9.245h29.708c.061-.617.094-1.241.094-1.874 0-10.413-8.441-18.853-18.854-18.853-10.413 0-18.854 8.441-18.854 18.853 0 10.413 8.441 18.854 18.854 18.854 8.206 0 15.167-5.252 17.758-12.57h-8.605m-9.154-17.403c4.747 0 8.797 2.995 10.389 7.191h-20.779c1.593-4.195 5.643-7.191 10.39-7.191" />
            <path d="m224.67 45.991c0-4.526-3.669-8.195-8.196-8.195-4.526 0-8.196 3.669-8.196 8.195 0 4.527 3.669 8.196 8.196 8.196 3.566 0 6.593-2.282 7.719-5.463h-3.74c-.871 1.267-2.328 2.102-3.979 2.102-2.387 0-4.37-1.742-4.759-4.02h12.913c.026-.269.041-.539.041-.815m-12.711-1.707c.692-1.824 2.451-3.126 4.516-3.126 2.064 0 3.823 1.303 4.516 3.126h-9.03m-28.08-20.263c6.145 0 11.145 5 11.145 11.144h7.752c0-10.437-8.46-18.897-18.898-18.897-8.665 0-15.949 5.837-18.18 13.788v-2.204c0-7.148-6.04-11.277-12.571-11.277-6.532 0-11.708 6.286-11.708 6.286 0 0-4.129-6.593-10.599-6.593-6.47 0-10.373 3.635-10.373 5.669v-4.19h-7.888v14.499c-1.388-9.04-9.18-15.977-18.612-15.977-10.413 0-18.854 8.441-18.854 18.853 0 10.413 8.441 18.854 18.854 18.854 8.206 0 15.167-5.252 17.758-12.57h-8.604c-2 2.916-5.355 4.834-9.154 4.834-5.492 0-10.05-4-10.948-9.245h29.56v15.753h7.888v-19.904c0-4.745 1.623-9.182 7.662-9.182 6.04 0 7.272 2.896 7.272 4.19v24.895h7.888v-22.369c0-4.806 5.3-6.717 7.949-6.717 2.65 0 6.594 1.417 6.594 4.19v24.895h7.888v-12.474c2.231 7.952 9.515 13.789 18.18 13.789v-7.753c-6.145 0-11.145-5-11.145-11.145 0-6.145 5-11.144 11.145-11.144m-100.32 7.173c1.593-4.195 5.643-7.191 10.39-7.191 4.747 0 8.797 2.995 10.389 7.191h-20.779m118.8 13.45c-2.197-.57-4.394-.809-4.394-2.484 0-1.344 1.594-1.583 2.46-1.583 1.307 0 2.484.448 2.746 2.063h3.116c-.368-3.442-2.88-4.757-5.653-4.757-2.461 0-5.653 1.047-5.653 4.428 0 3.142 2.146 4.04 4.345 4.577 2.17.567 4.34.777 4.421 2.572.078 1.767-1.884 2.034-3.01 2.034-1.595 0-2.903-.716-3.062-2.692h-2.982c.053 3.649 2.59 5.386 5.991 5.386 2.801 0 6.04-1.347 6.04-5.03 0-3.049-2.197-3.948-4.369-4.516m-12.152 5.359c-1.098 0-1.986.888-1.986 1.986 0 1.097.888 1.987 1.986 1.987 1.096 0 1.986-.89 1.986-1.987 0-1.097-.89-1.986-1.986-1.986" />
          </g>
          <path d="m48.722 58.25l-13.803-4.276c0 0 3.54-33.72-2.547-43.46-.411-.657 1.369-.82 14.378-3.944.799-.192 1.644 23.01 1.561 29.661-.082 6.655.411 22.02.411 22.02" fill="url(#1)" />
          <path d="m74.44 60.06c2.136 0 3.451-2.054 3.451-7.313 0-5.258-2.547-27.771-1.725-36.07.822-8.298 3.368-16.679 3.368-16.679 0 0-13.146 2.465-14.296 2.711-1.15.247-2.629 11.831-2.383 17.501.247 5.669 2.219 35.25 1.561 38.698l10.02 1.151" fill="url(#2)" />
          <path d="m75.1 60.06c-1.235 0-6.737-13.146-10.188-23.417-3.451-10.27-13.593-30.97-18.16-30.07-4.565.898-13.272 3.181-13.272 3.181 0 0 5.384 2.324 10.396 11.691 5.01 9.367 16.843 32.7 18.08 34.837 1.232 2.136 1.311 2.497 4.02 2.808 2.713.311 9.122.972 9.122.972" fill="url(#3)" />
        </g>
        <g transform="translate(0 64)" fill="#000">
          <path d="m2.01 2.795h-1.174v-.924h3.58c.998 0 1.555.044 2.142.323.778.367 1.291 1.1 1.291 2.172 0 1.365-.866 2.172-1.995 2.362v.029c1.335-.015 2.67.865 2.67 2.524 0 2.201-1.805 2.861-3.639 2.861h-4.05v-.925h1.115l.059-8.422m1.203 8.422h.954c1.247 0 3.01-.015 3.01-1.936 0-1.702-1.482-1.952-2.7-1.952h-1.232l-.029 3.888m.029-4.812h.734c1.291 0 2.612-.293 2.612-1.849 0-1.761-1.394-1.761-2.069-1.761h-1.247l-.029 3.61" />
          <path d="m11.219 5.495h-1.379v-.925h2.582l-.059 6.647h1.203v.925h-3.609v-.925h1.203l.059-5.722m.455-4.754c.499 0 .91.411.91.91 0 .499-.411.91-.91.91-.499 0-.91-.411-.91-.91 0-.499.411-.91.91-.91" />
          <path d="m15.826 1.782h-1.467v-.924h2.671l-.059 10.359h1.199v.924h-3.488v-.924h1.085z" />
          <path d="m20.433 1.782h-1.468v-.924h2.671l-.059 10.359h1.203v.924h-3.492v-.924h1.086z" />
          <path d="m25.04 5.495h-1.379v-.925h2.582l-.059 6.647h1.203v.925h-3.609v-.925h1.203l.059-5.722m.455-4.754c.499 0 .91.411.91.91 0 .499-.411.91-.91.91-.499 0-.91-.411-.91-.91 0-.499.411-.91.91-.91" />
          <path d="m36.1 5.495h-.675c-.205 0-.807-.059-1.012-.074.602.587.719 1.233.719 1.82 0 1.687-1.423 2.7-3.199 2.7-.308 0-1.365.176-1.365.719 0 .382.352.484 1.306.631l2.025.337c1.174.191 2.039.616 2.039 1.834 0 1.746-1.819 2.538-3.712 2.538-1.819 0-3.609-.587-3.609-2.069 0-1.144.924-1.643 1.907-1.863v-.029c-.602-.132-1.159-.47-1.159-1.174 0-.778.763-.983 1.379-1.145v-.029c-.513-.117-1.907-.631-1.907-2.553 0-1.57 1.291-2.744 3.14-2.744.411 0 .968.088 1.306.176h2.817v.925m-4.681 6.823c-.147 0-1.658.426-1.658 1.423 0 1.174 1.775 1.335 2.524 1.335.939 0 2.45-.279 2.45-1.497 0-.675-.616-.865-1.291-.968l-2.025-.293m-1.438-5.165c0 1.057.895 1.863 1.952 1.863 1.291 0 2.113-.851 2.113-1.907 0-.734-.557-1.79-2.01-1.79-1.188 0-2.054.719-2.054 1.834" />
          <path d="m38.37 6.8h-1.086v-1.819c.836-.338 1.731-.587 2.656-.587 1.952 0 2.993.719 2.993 2.729 0 .66-.088 2.729-.088 4.094h1.086v.925h-2.172v-.308c0-.44.088-1.042.147-1.335h-.029c-.455 1.218-1.203 1.819-2.538 1.819-.866 0-2.348-.323-2.348-2.172 0-2.113 2.26-2.318 4.798-2.318v-.543c0-1.423-.543-1.966-1.731-1.966-.425 0-1.144.074-1.687.308v1.174m3.419 1.864c-1.79 0-3.595 0-3.595 1.423 0 .851.646 1.306 1.423 1.306.822 0 2.172-.455 2.172-2.729" />
          <path d="m48.715 4.57h3.111v.925h-.91l1.746 4.857c.073.205.117.425.161.646h.029c.044-.22.088-.44.161-.646l1.79-4.857h-.983v-.925h3.02v.925h-.836l-2.612 6.647h-1.203l-2.626-6.647h-.851v-.925" />
          <path d="m58.809 5.495h-1.379v-.925h2.582l-.059 6.647h1.203v.925h-3.609v-.925h1.203l.059-5.722m.455-4.754c.499 0 .91.411.91.91 0 .499-.411.91-.91.91-.499 0-.91-.411-.91-.91 0-.499.411-.91.91-.91" />
          <path d="m62.15 4.57h1.218l.059-1.849 1.203-.073-.059 1.922h2.377v.925h-2.392c-.029 1.262-.059 2.538-.059 3.8 0 1.086 0 2.025 1.159 2.025.616 0 1.174-.176 1.497-.308l-.044.954c-.382.19-1.086.352-1.702.352-2.113 0-2.113-1.511-2.113-2.479 0-.925.044-3.243.059-4.343h-1.203v-.925" />
          <path d="m67.45 4.57h3.111v.925h-.91l1.746 4.857c.073.205.117.425.161.646h.029c.044-.22.088-.44.161-.646l1.79-4.857h-.983v-.925h3.02v.925h-.836l-2.612 6.647h-1.203l-2.626-6.647h-.851v-.925" />
          <path d="m77.852 6.8h-1.086v-1.819c.836-.338 1.731-.587 2.656-.587 1.952 0 2.993.719 2.993 2.729 0 .66-.088 2.729-.088 4.094h1.086v.925h-2.172v-.308c0-.44.088-1.042.147-1.335h-.029c-.455 1.218-1.203 1.819-2.538 1.819-.866 0-2.348-.323-2.348-2.172 0-2.113 2.26-2.318 4.798-2.318v-.543c0-1.423-.543-1.966-1.732-1.966-.425 0-1.144.074-1.687.308v1.174m3.419 1.864c-1.79 0-3.595 0-3.595 1.423 0 .851.646 1.306 1.423 1.306.822 0 2.172-.455 2.172-2.729" />
          <path d="m85.79 5.495h-1.291v-.925h2.494v.411c0 .44-.088 1.335-.161 1.951h.044c.293-1.115.689-2.494 2.568-2.494.103 0 .704.059.763.118l-.118 1.086c-.205-.073-.528-.117-.822-.117-1.555 0-2.245 1.526-2.275 2.861l-.058 2.832h1.276v.925h-3.551v-.925h1.071l.059-5.722" />
          <path d="m94.4 4.394c2.304 0 3.668 1.585 3.668 3.756 0 2.406-1.364 4.167-3.668 4.167-2.304 0-3.668-1.585-3.668-3.756 0-2.406 1.364-4.167 3.668-4.167m0 6.926c1.614 0 2.406-1.321 2.406-2.964 0-1.643-.793-2.964-2.406-2.964-1.614 0-2.407 1.32-2.407 2.964 0 1.643.793 2.964 2.407 2.964" />
          <path d="m100.58 5.495h-1.291v-.925h2.494v.411c0 .44-.088 1.335-.161 1.951h.044c.293-1.115.69-2.494 2.568-2.494.103 0 .704.059.763.118l-.118 1.086c-.205-.073-.528-.117-.822-.117-1.555 0-2.245 1.526-2.275 2.861l-.058 2.832h1.276v.925h-3.551v-.925h1.071l.059-5.722" />
          <path d="m117.84 9.544h1.203v1.394c.47.279 1.365.382 2.01.382 1.467 0 2.436-.675 2.436-1.907 0-2.744-5.634-1.115-5.634-4.798 0-1.247.734-2.92 3.8-2.92.748 0 2.113.191 2.758.484v2.069h-1.144v-1.276c-.382-.161-1.233-.279-1.717-.279-1.1 0-2.348.425-2.348 1.775 0 2.612 5.634.983 5.634 4.769 0 2.26-1.937 3.081-3.918 3.081-1.687 0-2.509-.396-3.081-.513v-2.26" />
          <path d="m131.51 11.217h.968c0-1.174.059-2.406.059-3.624 0-.734-.029-2.201-1.717-2.201-1.071 0-2.245.646-2.245 2.7l-.029 3.125h1.1v.925h-3.287v-.925h.968l.059-5.722h-1.218v-.925h2.421c0 .293-.014.704-.044.91l-.118.763h.029c.323-1.145 1.541-1.849 2.685-1.849 2.084 0 2.597 1.408 2.597 2.847 0 1.291-.058 2.802-.058 3.976h1.115v.925h-3.286v-.925" />
          <path d="m137.66 6.8h-1.086v-1.819c.836-.338 1.731-.587 2.656-.587 1.951 0 2.993.719 2.993 2.729 0 .66-.088 2.729-.088 4.094h1.086v.925h-2.172v-.308c0-.44.088-1.042.147-1.335h-.029c-.455 1.218-1.203 1.819-2.538 1.819-.866 0-2.347-.323-2.347-2.172 0-2.113 2.259-2.318 4.798-2.318v-.543c0-1.423-.543-1.966-1.732-1.966-.425 0-1.144.074-1.687.308v1.174m3.419 1.864c-1.79 0-3.595 0-3.595 1.423 0 .851.646 1.306 1.423 1.306.822 0 2.172-.455 2.172-2.729" />
          <path d="m144.88 1.782h-1.276v-.924h2.494l-.029 3.976c0 .338-.088 1.086-.147 1.409h.029c.308-1.057 1.35-1.849 2.685-1.849 2.098 0 3.052 1.643 3.052 3.654 0 1.804-.925 4.269-4.079 4.269-1.262 0-1.702-.235-2.788-.601l.058-9.933m1.145 9.185c.675.235 1.042.352 1.614.352 1.995 0 2.788-1.6 2.788-3.243 0-1.863-.939-2.685-2.113-2.685-1.071 0-2.186.646-2.23 2.7l-.059 2.876" />
          <path d="m153.58 1.782h-1.276v-.924h2.494l-.029 3.976c0 .338-.088 1.086-.147 1.409h.029c.308-1.057 1.35-1.849 2.685-1.849 2.098 0 3.052 1.643 3.052 3.654 0 1.804-.925 4.269-4.079 4.269-1.262 0-1.702-.235-2.788-.601l.058-9.933m1.145 9.185c.675.235 1.042.352 1.614.352 1.995 0 2.788-1.6 2.788-3.243 0-1.863-.939-2.685-2.113-2.685-1.071 0-2.186.646-2.23 2.7l-.059 2.876" />
          <path d="m166.9 1.782h-1.47v-.924h2.67l-.06 10.359h1.2v.924h-3.49v-.924h1.09z" />
          <path d="m171.63 8.458c.044 1.555.719 2.861 2.876 2.861.822 0 1.643-.25 2.274-.572l-.044 1.042c-.557.279-1.732.528-2.494.528-2.392 0-3.874-1.306-3.874-3.947 0-2.23 1.203-3.976 3.507-3.976 2.993 0 3.272 2.715 3.272 4.064h-5.517m4.255-.924c0-1.189-.748-2.216-2.01-2.216-1.291 0-2.172.968-2.245 2.216h4.255" />
          <path d="m177.55 4.57h3.111v.925h-.91l1.746 4.857c.074.205.118.425.161.646h.029c.044-.22.088-.44.161-.646l1.79-4.857h-.983v-.925h3.02v.925h-.836l-2.612 6.647h-1.203l-2.626-6.647h-.851v-.925" />
          <path d="m187.6 8.458c.044 1.555.719 2.861 2.876 2.861.822 0 1.643-.25 2.274-.572l-.044 1.042c-.557.279-1.732.528-2.494.528-2.392 0-3.874-1.306-3.874-3.947 0-2.23 1.203-3.976 3.507-3.976 2.993 0 3.272 2.715 3.272 4.064h-5.517m4.255-.924c0-1.189-.748-2.216-2.01-2.216-1.291 0-2.172.968-2.245 2.216h4.255" />
          <path d="m195.63 5.495h-1.291v-.925h2.494v.411c0 .44-.088 1.335-.161 1.951h.044c.293-1.115.69-2.494 2.568-2.494.103 0 .704.059.763.118l-.118 1.086c-.205-.073-.528-.117-.822-.117-1.555 0-2.245 1.526-2.275 2.861l-.058 2.832h1.276v.925h-3.551v-.925h1.071l.059-5.722" />
          <path d="m202.1 6.8h-1.086v-1.819c.836-.338 1.731-.587 2.656-.587 1.951 0 2.993.719 2.993 2.729 0 .66-.088 2.729-.088 4.094h1.086v.925h-2.172v-.308c0-.44.088-1.042.147-1.335h-.029c-.455 1.218-1.203 1.819-2.538 1.819-.866 0-2.347-.323-2.347-2.172 0-2.113 2.259-2.318 4.798-2.318v-.543c0-1.423-.543-1.966-1.732-1.966-.425 0-1.144.074-1.687.308v1.174m3.419 1.864c-1.79 0-3.595 0-3.595 1.423 0 .851.646 1.306 1.423 1.306.822 0 2.172-.455 2.172-2.729" />
          <path d="m214.16 11.217h.968c0-1.174.059-2.406.059-3.624 0-.734-.029-2.201-1.717-2.201-1.071 0-2.245.646-2.245 2.7l-.029 3.125h1.1v.925h-3.287v-.925h.968l.059-5.722h-1.218v-.925h2.421c0 .293-.015.704-.044.91l-.118.763h.029c.323-1.145 1.541-1.849 2.685-1.849 2.084 0 2.597 1.408 2.597 2.847 0 1.291-.058 2.802-.058 3.976h1.115v.925h-3.286v-.925" />
          <path d="m224.21 6.668h-1.086l-.059-1.071c-.279-.235-.983-.279-1.335-.279-.763 0-1.746.25-1.746 1.159 0 .822.734 1.071 1.849 1.262 1.922.323 2.729.91 2.729 2.157 0 1.35-.91 2.421-3.096 2.421-.822 0-2.128-.205-2.597-.455l-.059-1.936h1.086l.059 1.129c.455.22 1.218.338 1.717.338.793 0 1.746-.338 1.746-1.262 0-.748-.778-1.012-1.599-1.189-2.025-.44-3.037-.792-3.037-2.274 0-1.218.807-2.274 2.979-2.274.631 0 1.805.205 2.392.455l.059 1.819" />
        </g>
      </g>
    </svg>
  ),
}
