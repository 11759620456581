/* eslint-disable max-len */
const programName = 'Lens Now'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 68.22 13.05">
      <title>{programName}</title>
      <g id="Lager_2" data-name="Lager 2">
        <g id="Lager_1-2" data-name="Lager 1">
          <path fill="#000" d="M1.22,12.78H0V0H1.22Z" />
          <path fill="#000" d="M12.13,9.11l-.07,0v0H5A3.06,3.06,0,0,0,8.14,12a2.53,2.53,0,0,0,2.41-1.28h1.33A3.77,3.77,0,0,1,8.1,13a4.24,4.24,0,0,1-4.32-4.4A4.23,4.23,0,0,1,8.1,4.25a4.06,4.06,0,0,1,4.05,4.34A4.35,4.35,0,0,1,12.13,9.11ZM5,8.16h5.89A2.83,2.83,0,0,0,8.06,5.31,3,3,0,0,0,5,8.16Z" />
          <path fill="#000" d="M22.41,8v4.79H21.19V8.05a2.45,2.45,0,0,0-2.54-2.72,3.61,3.61,0,0,0-2.86,1.55v5.9H14.56V4.47h1.23v1A4.2,4.2,0,0,1,18.87,4.2,3.46,3.46,0,0,1,22.41,8Z" />
          <path fill="#000" d="M25.56,10.21c.25,1.13,1.13,1.82,2.75,1.82,1.36,0,2.27-.58,2.27-1.48,0-.74-.59-1.19-1.44-1.31l-1.89-.33c-1.38-.21-2.46-.83-2.46-2.23s1.33-2.47,3.33-2.47c1.69,0,3.45.74,3.6,2.65H30.58c-.16-1.08-1.06-1.66-2.46-1.66S26,5.78,26,6.59c0,.63.45,1.12,1.64,1.3l1.66.28c1.2.2,2.48.72,2.48,2.31s-1.46,2.57-3.49,2.57-3.6-1-3.87-2.84Z" />
          <path fill="#000" d="M43,7.8v5H40.05V8.26c0-1.24-.63-1.83-1.58-1.83a2.22,2.22,0,0,0-1.62.88v5.47H33.93V4.05h2.92v.6a3.71,3.71,0,0,1,2.41-.87C41.47,3.78,43,5.31,43,7.8Z" />
          <path fill="#000" d="M53.82,8.41a4.74,4.74,0,0,1-9.47,0,4.74,4.74,0,0,1,9.47,0Zm-2.75,0a2,2,0,1,0-4,0,2,2,0,1,0,4,0Z" />
          <path fill="#000" d="M65.23,12.78H62.57l-1.39-4.5-1.4,4.5H57.13l-3-8.73h3.07l1.36,4.68L60,4.05h2.3L63.81,8.7l1.37-4.65h3Z" />
        </g>
      </g>
    </svg>
  ),
}
