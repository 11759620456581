/* eslint-disable max-len */
const programName = 'Kavat'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 722 176.8">
      <title>{programName}</title>
      <path d="M580 40.5h45.5v131.8h44.4V40.5h45V0H580zM412.2 0l-41.8 104.6L328.8 0h-48.7l72.4 172.3h34.8L460.8 0zm81.9 0l-66.2 172.3h47.5l42.7-119.1h.4l41.6 119.1h47.8L543 0zM44.8 97.6h.4l57.8 74.7h78.6l42.7-119.1h.5l41.6 119.1h47.7L249.2 0h-48.9l-57.6 149.9-56.3-68.3L154.9 0H99.4L45.2 71h-.4V0H0v172.3h44.8z" />
    </svg>
  ),
}
