/* eslint-disable max-len */
const programName = 'Monki'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 208 52">
      <title>{programName}</title>
      <g fill="none" fillRule="evenodd">
        <path d="M83.39 9c-9.623 0-17.45 7.87-17.45 17.544C65.94 36.17 73.767 44 83.39 44c9.626 0 17.455-7.83 17.455-17.456C100.845 16.87 93.015 9 83.39 9zm0 30.284c-6.922 0-12.558-5.69-12.558-12.688 0-6.995 5.636-12.686 12.56-12.686s12.56 5.69 12.56 12.686c0 6.997-5.636 12.688-12.56 12.688zM139 9v35l-25.035-23.955V44H109V9l24.99 23.278V9H139zm35.1 35h-6.65L154 28.78V44h-5V9h5v13.598L167.167 9h6.613l-16.31 16.408L174.1 44zm24.894 0c-5.6 0-10.214-2.096-12.994-4.716V44h-4.975L181 9h5v19.267c-.135 8.11 7.314 11.258 12.994 11.258V44zm-143.69 0l-4.11-20.448L41.236 44l-10.16-20.102L27.202 44h-4.95l.942-4.716C19.41 42.06 14.492 43.81 10 44v-4.477c5.59-.062 13.79-3.924 15.177-9.838l4.244-20.55 11.806 24.473L53.1 9.078 60.253 44h-4.95z" fill="#1F313A" />
      </g>
    </svg>
  ),
}
