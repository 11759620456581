const programName = 'Beautycos'
export default {
  programName,
  logo: (
    <svg className="beautycos" x="0px" y="0px" viewBox="0 0 171.5 97.2">
      <title>{programName}</title>
      <path
        className="st0"
        d="M101.1,15.8c-8.2-4.5-18.5-2.6-24.5,4.6c-6.9-8.3-19.2-9.5-27.5-2.6c-4.5,3.7-7.1,9.2-7.1,15.1c0,2,0.3,4.1,1,6
    C46.5,54,76.7,73,76.7,73s30.2-19,33.6-34.1c0.7-1.9,1-4,1-6C111.3,25.8,107.4,19.3,101.1,15.8L101.1,15.8z"
      />
      <path
        className="st0"
        d="M125.6,73V12.2h22.8c3.8-0.1,7.6,0.5,11.1,1.9c2.7,1.2,5,3.1,6.6,5.7c1.5,2.4,2.4,5.2,2.4,8
    c0,2.6-0.7,5.1-2.1,7.3c-1.6,2.4-3.8,4.4-6.4,5.6c3.4,0.9,6.3,2.8,8.5,5.5c2,2.6,3.1,5.9,3,9.2c0,2.7-0.6,5.5-1.8,7.9
    c-1,2.2-2.5,4.2-4.5,5.7c-2,1.4-4.2,2.5-6.6,3c-3.2,0.7-6.5,1.1-9.8,1L125.6,73z M133.6,37.8h13.1c2.6,0.1,5.1-0.1,7.6-0.7
    c1.8-0.5,3.4-1.5,4.6-3c1.1-1.6,1.6-3.4,1.5-5.3c0-1.9-0.5-3.7-1.5-5.3c-0.9-1.5-2.4-2.6-4.1-3.1c-1.8-0.6-4.9-0.9-9.2-0.9h-12.1
    V37.8z M133.6,65.9h15.1c1.8,0,3.7-0.1,5.5-0.3c1.6-0.3,3.2-0.8,4.6-1.7c1.3-0.8,2.4-2,3.1-3.4c0.8-1.6,1.2-3.3,1.2-5.1
    c0-2.1-0.6-4.2-1.8-5.9c-1.2-1.7-2.9-2.9-4.9-3.5c-2.9-0.8-5.9-1.1-8.9-1h-14V65.9z"
      />
      <g className="st1">
        <path className="st0" d="M29.5,72H5.6v-6.2h7.9V17.6H5.6v-6.2h23.9v6.2h-7.9v48.2h7.9V72z" />
      </g>
      <path
        className="st0"
        d="M5.4,97V84.5h5.5c0.9,0,1.8,0.1,2.7,0.4c0.6,0.2,1.2,0.6,1.6,1.2c0.4,0.5,0.6,1,0.6,1.6c0,0.5-0.2,1.1-0.5,1.5
    c-0.4,0.5-0.9,0.9-1.5,1.1c0.8,0.2,1.5,0.6,2,1.1c0.5,0.5,0.7,1.2,0.7,1.9c0,0.6-0.2,1.1-0.4,1.6c-0.3,0.5-0.6,0.9-1.1,1.2
    c-0.5,0.3-1,0.5-1.6,0.6c-0.8,0.1-1.6,0.2-2.3,0.2L5.4,97z M7.3,89.8h3.1c0.6,0,1.2,0,1.9-0.1c0.4-0.1,0.8-0.3,1.1-0.6
    c0.3-0.3,0.4-0.7,0.4-1.1c0-0.8-0.5-1.6-1.4-1.8C11.7,86,11,86,10.2,86H7.3L7.3,89.8z M7.3,95.5H11c0.4,0,0.9,0,1.3-0.1
    c0.4-0.1,0.8-0.2,1.1-0.4c0.3-0.2,0.6-0.4,0.7-0.7c0.2-0.3,0.3-0.7,0.3-1c0-0.4-0.1-0.9-0.4-1.2c-0.3-0.4-0.7-0.6-1.2-0.7
    c-0.7-0.2-1.4-0.2-2.1-0.2H7.3L7.3,95.5z"
      />
      <path className="st0" d="M24.5,97V84.5H35V86h-8.6v3.8h8.1v1.5h-8.1v4.2h9V97L24.5,97z" />
      <path
        className="st0"
        d="M41.8,97l5.6-12.4h2.1l6,12.4h-2.2l-1.7-3.7h-6.1L43.8,97L41.8,97z M46,91.9h5l-1.5-3.4c-0.5-1-0.8-1.9-1-2.6
    c-0.2,0.8-0.5,1.6-0.9,2.4L46,91.9z"
      />
      <path
        className="st0"
        d="M71.9,84.5h1.9v7.2c0,1-0.1,2-0.5,3c-0.4,0.8-1,1.4-1.8,1.8c-1.1,0.5-2.2,0.7-3.4,0.7c-1.2,0-2.3-0.2-3.4-0.6
    c-0.8-0.3-1.5-1-1.9-1.8c-0.4-1-0.6-2-0.6-3.1v-7.2h2v7.2c0,0.8,0.1,1.6,0.4,2.4c0.3,0.5,0.7,0.9,1.2,1.2c0.7,0.3,1.4,0.4,2.1,0.4
    c1.1,0.1,2.1-0.2,3-0.8c0.6-0.5,0.9-1.6,0.9-3.1L71.9,84.5z"
      />
      <path className="st0" d="M86.2,97V86h-4.8v-1.5h11.6V86h-4.9v10.9L86.2,97z" />
      <path
        className="st0"
        d="M104.4,97v-5.3l-5.6-7.1h2.4l2.9,3.7c0.5,0.7,1,1.4,1.5,2.1c0.4-0.6,1-1.4,1.6-2.2l2.9-3.6h2.3l-5.9,7.2V97
    L104.4,97z"
      />
      <path
        className="st0"
        d="M129.7,92.6l1.9,0.4c-0.3,1.3-1.1,2.3-2.2,3.1c-1.2,0.7-2.5,1.1-3.9,1.1c-1.3,0.1-2.7-0.2-3.9-0.8
    c-1-0.5-1.8-1.3-2.3-2.3c-0.5-1-0.8-2.2-0.8-3.3c0-1.2,0.3-2.4,0.9-3.4c0.6-1,1.5-1.7,2.5-2.2c1.1-0.5,2.3-0.8,3.5-0.8
    c1.3,0,2.6,0.3,3.7,1c1,0.6,1.7,1.6,2.1,2.7l-1.9,0.4c-0.3-0.8-0.8-1.5-1.5-2c-0.7-0.4-1.6-0.6-2.4-0.6c-1,0-2,0.2-2.8,0.7
    c-0.7,0.4-1.3,1-1.6,1.8c-0.3,0.8-0.5,1.6-0.5,2.4c0,0.9,0.2,1.9,0.5,2.8c0.4,0.8,1,1.4,1.7,1.8c0.8,0.4,1.6,0.6,2.5,0.6
    c1,0,2-0.2,2.8-0.8C128.9,94.4,129.5,93.6,129.7,92.6z"
      />
      <path
        className="st0"
        d="M138.7,90.9c-0.1-1.8,0.7-3.6,2-4.8c1.4-1.2,3.2-1.8,5.1-1.8c1.3,0,2.5,0.2,3.6,0.8c1,0.5,1.9,1.3,2.5,2.3
    c0.6,1,0.9,2.1,0.8,3.3c0,1.2-0.3,2.4-0.9,3.4c-0.6,1-1.5,1.8-2.5,2.2c-1.1,0.5-2.3,0.8-3.6,0.8c-1.3,0-2.6-0.3-3.7-0.9
    c-1-0.5-1.9-1.3-2.5-2.3C139,93.1,138.7,92,138.7,90.9z M140.7,90.9c-0.1,1.3,0.5,2.6,1.4,3.5c2.1,1.7,5.1,1.7,7.2,0
    c1-1,1.5-2.3,1.4-3.7c0-0.9-0.2-1.8-0.6-2.6c-0.4-0.8-1-1.4-1.8-1.8c-2-1-4.4-0.8-6.2,0.6C141.1,88,140.6,89.5,140.7,90.9
    L140.7,90.9z"
      />
      <path
        className="st0"
        d="M159.9,93l1.8-0.1c0.1,0.6,0.3,1.1,0.6,1.5c0.4,0.5,0.9,0.8,1.5,1c0.7,0.3,1.5,0.4,2.2,0.4c0.7,0,1.4-0.1,2-0.3
    c0.5-0.1,0.9-0.4,1.3-0.8c0.3-0.3,0.4-0.7,0.4-1.1c0-0.4-0.1-0.7-0.4-1c-0.4-0.4-0.8-0.6-1.3-0.7c-0.9-0.2-1.7-0.4-2.6-0.6
    c-1-0.2-1.9-0.4-2.8-0.8c-0.6-0.2-1.1-0.7-1.6-1.2c-0.3-0.4-0.5-1-0.5-1.6c0-0.6,0.2-1.3,0.6-1.8c0.5-0.6,1.1-1,1.8-1.2
    c0.9-0.3,1.8-0.4,2.7-0.4c1,0,1.9,0.1,2.9,0.4c0.8,0.2,1.4,0.7,1.9,1.3c0.4,0.6,0.7,1.3,0.7,2l-1.9,0.1c-0.1-0.7-0.4-1.3-1-1.8
    c-0.8-0.4-1.7-0.7-2.5-0.6c-0.9-0.1-1.8,0.1-2.6,0.6c-0.5,0.3-0.8,0.8-0.8,1.3c0,0.4,0.2,0.9,0.6,1.1c0.9,0.5,1.9,0.8,2.9,0.9
    c1.1,0.2,2.2,0.4,3.2,0.8c0.7,0.2,1.4,0.7,1.9,1.3c0.4,0.5,0.6,1.1,0.6,1.8c0,0.7-0.3,1.3-0.7,1.8c-0.5,0.6-1.2,1.1-1.9,1.4
    c-0.9,0.3-1.9,0.5-2.8,0.5c-1.1,0-2.3-0.1-3.3-0.5c-0.8-0.3-1.6-0.8-2.1-1.5C160.2,94.6,159.9,93.8,159.9,93z"
      />
    </svg>
  ),
}
