/* eslint-disable max-len */
const programName = 'TCM Cykel'
export default {
  programName,
  logo: (
    <svg className="tcmcykel" viewBox="0 0 1080 358">
      <title>{programName}</title>
      <g>
        <path
          className="st0"
          d="M998.56,170.02h-50.1c-2.3,26.51,1.67,14.71-7.41,41.33c-21.18,42.99-85.68,40.38-131.08,40.38
        c-4.38,56.15-34.02,83.39-83.07,83.39l-90.8-0.42l-238.36-0.63c-66.27,0-113.02-4.69-118.45-80.98
        c-67.84,0-140.16-1.77-141.83-83.07l-57.62,0.32C39.6,170.57,1.2,125.94,7.67,85.07c8.38-52.95,57.05-64.37,93.74-64.35
        l897.04,0.69C1093.53,20.15,1114.61,165.32,998.56,170.02z"
        />
        <path className="st1" d="M368.77,333.54c8.86,0.43,18.2,0.54,27.97,0.54l9.93,0.03v-17.43h-37.9V333.54z" />
        <circle className="st2" cx="219" cy="194.67" r="5" />
        <circle className="st2" cx="219" cy="194.67" r="5" />
        <rect x="444.57" y="241.63" className="st1" width="37.9" height="9.04" />
        <path
          className="st3"
          d="M355.71,291.08l4.21-19.27h5.47l4.17,19.27h-4.58l-0.58-3.73h-3.57l-0.54,3.73H355.71z M361.33,283.97h2.59
        l-1.3-8.64L361.33,283.97z M384.57,291.08v-19.32h4.57v15.47h5.52v3.85H384.57z M409.77,291.08v-19.32h4.58v15.47h5.52v3.85H409.77
        z M436.22,291.08v-15.42h-3.57v-3.85h11.77v3.85h-3.67v15.42H436.22z M478.38,271.76h4.57v19.32h-4.57V271.76z M499.1,291.08
        v-19.27h4.68l3.44,10.79h0.11l-0.44-10.79h4.32v19.27h-4.67l-3.52-10.79l-0.05,0.05l0.41,10.74H499.1z M527.12,285.55v-8.48
        c0.05-2.08,0.64-3.56,1.79-4.43c1.15-0.87,2.62-1.3,4.43-1.3c0.84,0,1.62,0.1,2.34,0.3c0.71,0.2,1.35,0.53,1.92,0.98
        c0.57,0.45,1.01,1.05,1.34,1.79c0.32,0.75,0.49,1.63,0.49,2.66v8.48c-0.07,1.37-0.31,2.48-0.7,3.35c-0.4,0.87-1.04,1.53-1.93,1.97
        c-0.89,0.45-2.1,0.67-3.61,0.67c-1.47,0-2.64-0.25-3.52-0.75c-0.87-0.5-1.51-1.19-1.89-2.07
        C527.39,287.84,527.17,286.78,527.12,285.55 M531.69,276.67l-0.05,0.42v8.53c0,0.71,0.14,1.3,0.41,1.77
        c0.27,0.46,0.69,0.7,1.23,0.7c0.58,0,0.98-0.23,1.22-0.67c0.23-0.45,0.35-1.12,0.35-2.01v-8.32v-0.42
        c-0.06-1.23-0.59-1.85-1.62-1.85C532.33,274.88,531.82,275.49,531.69,276.67 M555.49,291.08v-19.27h6.37l2.31,11.38h0.11
        l2.38-11.38h6.33v19.27h-4.29l0.44-15.11h-0.06l-3.21,15.11h-3.36l-3.11-15.11h-0.05l0.41,15.11H555.49z M615.1,283.55h4.52v2.86
        c0,1.2-0.32,2.18-0.96,2.96c-0.64,0.78-1.44,1.33-2.39,1.68c-0.95,0.34-1.92,0.5-2.89,0.5c-1.42,0-2.57-0.21-3.46-0.64
        c-0.89-0.42-1.55-1.08-1.96-1.95c-0.42-0.88-0.63-1.99-0.63-3.35v-8.33c0-1.76,0.44-3.19,1.33-4.29c0.88-1.1,2.46-1.64,4.72-1.64
        c0.99,0,1.86,0.11,2.63,0.33c0.76,0.22,1.41,0.56,1.94,1.02c0.53,0.46,0.93,1.04,1.21,1.74c0.27,0.7,0.41,1.53,0.41,2.48v0.36v1.26
        h-4.57v-1.26c0-0.82-0.13-1.43-0.39-1.85c-0.26-0.41-0.62-0.62-1.07-0.62h-0.05c-0.52,0.03-0.93,0.16-1.21,0.41
        c-0.29,0.24-0.43,0.72-0.43,1.44v9.74c0,1.11,0.51,1.67,1.53,1.67c1.01,0,1.57-0.63,1.68-1.9l0.05-0.82V283.55z M639.26,291.08
        v-8.07l-4.57-11.2h4.6l2.26,6.32h0.05l2.31-6.32h4.52l-4.67,11.2v8.07H639.26z M663.7,291.08v-19.32h4.52v7.73l3.37-7.73h4.65
        l-4.11,8.53l4.37,10.79h-4.78l-2.49-6.73l-1,1.96v4.78H663.7z M691.88,291.08v-19.32h10.32v3.85h-5.8v3.58h5.65v3.85h-5.65v4.19
        h6.16v3.85H691.88z M718.27,291.08v-19.32h4.57v15.47h5.52v3.85H718.27z"
        />
        <path
          className="st3"
          d="M358.22,253.35h364.85c14.72,0,26.82,12,26.82,26.61v0.31c0,14.61-12.11,26.61-26.82,26.61H358.22
        c-14.82,0-26.92-12-26.92-26.61v-0.31C331.29,265.35,343.4,253.35,358.22,253.35 M723.8,250.12c17.43,1.98,27.87,12.42,29.53,29.22
        c0,16.49-14.4,36.01-29.53,37.68H358.95c-16.8-1.67-29.33-20.24-31-36.63c0-17.22,15.13-28.6,30.27-30.27H723.8z"
        />
        <path
          className="st3"
          d="M284.66,203.98L307.93,89.6H252.1l6.68-29.53h154.04l-6.37,29.53h-55.83l-23.17,114.38H284.66z M562.79,204.29
        l30.27-142.87h66.27l19.83,105.2l65.54-105.2h63.56l-30.58,143.18l-40.39-0.31l25.26-115.32l-72.32,115.01h-43.31L623.95,90.33
        l-23.8,113.97H562.79z M497.56,55.68c26.93,0,75.04,4.7,69.3,52.81l-43.1-0.31c2.09-18.58-10.02-23.9-29.85-23.9
        c-14.51,0-22.23,6.37-29.95,13.15c-21.5,19.1-19.61,43.7-17.53,59.48c0.65,4.93,7.41,14.4,11.17,16.49
        c3.65,2.29,13.05,6.36,22.13,6.36c21.5,0,26.61-8.03,34.65-25.57h42.37c-8.35,43-53.12,54.79-87.46,54.79
        c-47.07,0-74.93-26.93-67.52-75.25C408.44,89.6,457.59,55.68,497.56,55.68"
        />
        <path
          className="st3"
          d="M588.18,54.87h77.34L683,148.69l58.5-93.82h75.66l-33.62,156.7l-54.14-0.34l18.83-85.75l-53.8,85.41h-52.46
        l-17.49-87.09L606,211.23h-51.11L588.18,54.87z M410.21,96.38h-54.16l-23.17,114.38h-56.15L299.9,96.38h-56.15l9.71-43h167.81
        l-8.76,39.97c32.98-41.02,93.51-58.13,140.89-31.94c19.52,14.82,20.87,29.95,19.83,53.85l-56.15-0.31c0-16.49,0.73-21.6-16.8-23.59
        c-33.6-1.67-49.36,26.92-47.69,58.55c1.1,19.45,18.47,25.88,38.61,21.81c10.75-3.65,14.19-14.09,19.2-24.21h55.1
        c-8.35,32.25-21.18,51.45-53.75,61.89c-50.1,14.09-115.32,6.05-117.72-58.55C394.04,131.76,398.42,111.93,410.21,96.38
         M585.05,56.24l0.84-3.95h81.72l15.73,85.56l51.12-81.61h2.89l2.48-3.95h80.36l-34.96,162.07l-4.51-0.02l-0.86,3.98L720.37,218
        l14.65-67.01l-39.55,62.74h-2.87l-2.49,3.95h-56.14l-12.97-65.46l-13.29,61.82h-4.52l-0.85,3.96h-56.46l7.41-34.56
        c-5.46,7.67-11.77,13.68-18.84,18.4c-17.53,14.25-40.48,18.89-67.26,20.54c-45.4-1.36-77.02-18.16-81.09-67.31
        c0.73-18.16,3.34-35.9,13.77-51.77h-42.09l-22.47,110.43h-4.56l-0.81,3.95h-61.89l23.27-114.38h-56.14l11.06-48.42h4.46l0.9-3.95
        h173.14l-7.21,32.08c3.54-2.96,7.15-5.74,10.83-8.33C462.55,48.41,503.09,38.35,545.5,55c16.09,7.25,24.72,17.31,28.56,31.86
        l6.47-30.62H585.05z M561.44,145.06l4.89-22.87l-57.08-0.42c0-17.12,2.29-20.46-14.2-23.48c-10.44,0.59-18.61,2.9-24.94,6.7
        c-10.68,9.59-14.33,25.13-14.78,44.76c2.1,23.19,33.12,22.1,36.55,18.94c5.41-5.29,8.6-12.94,11.62-19.68h3.6
        c0.61-1.33,1.19-2.66,1.77-3.95H561.44z"
        />
        <path
          className="st3"
          d="M812.21,261.13c66.48,0,137.44-4.17,139.32-81.82h39.66c36.84-0.63,88.6-26.93,88.81-79.63
        c-0.52-48.53-34.75-83.18-81.92-84.53l-833.44,0.31c-20.67,0-41.43,0.52-62.1,0.52C53.39,16.71,2.66,33.19,0.06,92.17
        c-1.78,40.29,37.74,86.57,78.88,86.45l50.95-0.14c-0.11,53.64,51.87,80.57,92.05,82.34c9.7,0.31,36.32,0.31,46.02,0.31
        c5.43,66.9,55.42,81.72,128.78,81.72l325.61-0.1C765.03,342.64,811.89,316.86,812.21,261.13 M725.9,335.13
        c49.05,0,78.69-27.24,83.07-83.39c45.4,0,109.9,2.61,131.08-40.39c9.08-26.61,5.11-14.82,7.41-41.33h50.1
        c116.05-4.7,94.97-149.87-0.1-148.61l-897.04-0.68C63.72,20.7,15.05,32.13,6.67,85.07c-6.47,40.87,31.93,85.5,72.17,85.28
        l57.62-0.32c1.67,81.3,73.99,83.07,141.83,83.07c5.43,76.29,52.18,80.98,118.45,80.98l238.36,0.63L725.9,335.13z"
        />
        <path className="st1" d="M292.92,241.62h37.9v26.44c-1.72,3.45-2.75,7.32-2.86,11.55h-35.04V241.62z" />
        <path className="st1" d="M217.1,241.64h-35.51c10.68,4.37,22.73,7.07,35.51,8.75V241.64z" />
        <path className="st1" d="M217.1,241.64h-35.51c10.68,4.37,22.73,7.07,35.51,8.75V241.64z" />
        <rect x="179.25" y="128.15" className="st1" width="37.9" height="37.99" />
        <rect x="179.25" y="52.53" className="st1" width="37.9" height="37.99" />
        <polygon className="st1" points="217.1,90.48 238.1,90.48 235.19,103.3 255,103.3 255,128.16 217.1,128.16" />
        <rect x="217.1" y="165.66" className="st1" width="37.9" height="37.99" />
        <polygon className="st1" points="558.3,122.13 558.3,128.17 520.4,128.17 520.4,121.86" />
        <rect x="520.4" y="241.64" className="st1" width="37.9" height="8.48" />
        <path className="st1" d="M548.94,203.67h-16.85c9.03-6.73,15.09-11.65,21.09-19.6L548.94,203.67z" />
        <rect x="823.74" y="90.17" className="st1" width="37.9" height="37.99" />
        <rect x="823.74" y="166.16" className="st1" width="37.9" height="37.5" />
        <path className="st1" d="M389.38,128.16h-20.64V103.3h31.12C394.68,111.19,391.43,119.53,389.38,128.16" />
        <rect x="368.75" y="241.63" className="st1" width="37.9" height="8.48" />
        <path className="st1" d="M368.75,165.67h18.74c3.01,16.36,9.63,28.5,19.16,37.25v0.75h-37.9V165.67z" />
        <polygon className="st1" points="674.58,90.19 709.95,90.19 709.95,95.36 689.39,128.18 681.56,128.18" />
        <rect x="672.05" y="241.64" className="st1" width="37.9" height="8.47" />
        <polygon className="st1" points="709.95,190.76 709.95,203.68 701.81,203.68" />
        <rect x="975.38" y="90.18" className="st1" width="37.9" height="37.99" />
        <polygon className="st1" points="255.02,128.15 286.28,128.15 278.55,166.14 255.02,166.14" />
        <polygon className="st1" points="255.02,203.65 270.92,203.65 268.06,217.68 292.92,217.68 292.92,241.65 255.02,241.65" />
        <polygon className="st1" points="558.32,128.17 565.05,128.17 561.44,145.06 558.32,145.06" />
        <rect x="558.32" y="218" className="st1" width="37.9" height="23.66" />
        <path className="st1" d="M558.32,52.55h27.51l-0.78,3.69h-4.53l-6.47,30.62c-2.65-10.07-7.6-17.99-15.74-24.31V52.55z" />
        <rect x="861.66" y="128.16" className="st1" width="37.9" height="37.99" />
        <rect x="861.66" y="203.66" className="st1" width="37.9" height="37.99" />
        <rect x="899.59" y="166.16" className="st1" width="37.9" height="37.62" />
        <rect x="861.66" y="52.54" className="st1" width="37.9" height="37.8" />
        <path className="st1" d="M406.67,203.66h0.8c9.7,8.57,22.3,13.77,37.11,16.47v21.52h-37.9V203.66z" />
        <path className="st1" d="M424.39,52.54h20.18v11.21c-5.54,3.23-10.94,6.89-16.2,10.93c-3.68,2.6-7.29,5.38-10.82,8.33L424.39,52.54z" />
        <polygon className="st1" points="731.7,166.17 725.45,166.17 735.02,150.99" />
        <path className="st1" d="M747.87,298.26v19.37H708v-0.62h14.55C732.44,315.97,742.02,308.06,747.87,298.26" />
        <polygon className="st1" points="709.96,203.68 723.5,203.68 720.37,218 747.87,218.14 747.87,241.67 709.96,241.67" />
        <polygon className="st1" points="709.96,52.56 739.66,52.56 737.35,56.24 734.46,56.24 712.96,90.55 709.96,90.55" />
        <rect x="1013.3" y="52.55" className="st1" width="37.9" height="37.79" />
        <rect x="596.22" y="241.64" className="st1" width="37.9" height="8.47" />
        <polygon className="st1" points="618.1,165.68 623.66,165.68 625.28,173.89 631.18,203.67 609.93,203.67" />
        <rect x="899.55" y="90.17" className="st1" width="38.01" height="37.99" />
        <path className="st1" d="M482.47,100.07v28.09h-24.79c2.18-9.58,6.05-17.44,12.43-23.17C473.64,102.88,477.73,101.22,482.47,100.07" />
        <path className="st1" d="M465.04,165.67h17.43v4.7C473.66,170.16,469.34,168.11,465.04,165.67" />
        <path className="st1" d="M747.87,241.65h37.9v37.99h-32.44c0-0.1,0-0.21,0-0.31c-0.63-6.29-2.48-11.69-5.47-16.08V241.65z" />
        <polygon className="st1" points="352.73,128.16 368.75,128.16 368.75,166.15 345,166.15" />
        <path className="st1" d="M368.75,317.01v0.61h-37.9v-25.57c4.9,12.52,15.25,23.69,28.11,24.97H368.75z" />
        <polygon className="st1" points="337.36,203.66 368.75,203.66 368.75,241.66 330.84,241.66 330.84,213.73 335.31,213.73" />
        <rect x="634.14" y="217.69" className="st1" width="37.9" height="23.99" />
        <polygon className="st1" points="667.66,52.56 672.04,52.56 672.04,76.42" />
        <rect x="937.48" y="128.17" className="st1" width="37.9" height="37.99" />
        <rect x="937.48" y="52.55" className="st1" width="37.9" height="37.59" />
        <path
          className="st1"
          d="M482.49,128.17h37.9v16.89h-11.52c-0.58,1.29-1.16,2.62-1.77,3.95h-3.59c-2.57,5.73-5.26,12.11-9.33,17.15
        h-11.69V128.17z"
        />
        <path className="st1" d="M520.39,210.79v30.88h-37.9v-20.65C496.49,219.3,509.22,216.27,520.39,210.79" />
        <polygon className="st1" points="803.81,128.18 823.69,128.18 823.69,166.18 795.62,166.18" />
        <polygon className="st1" points="787.53,203.68 823.69,203.68 823.69,241.68 785.79,241.68 785.79,211.74" />
        <polygon className="st1" points="820.12,52.57 823.69,52.57 823.69,90.56 811.93,90.56" />
        <rect x="65.52" y="90.39" className="st1" width="37.9" height="37.58" />
        <rect x="141.31" y="90.39" className="st1" width="37.9" height="37.82" />
        <rect x="103.42" y="128.04" className="st1" width="37.9" height="37.99" />
        <rect x="103.42" y="52.42" className="st1" width="37.9" height="37.99" />
        <rect x="27.62" y="52.34" className="st1" width="37.9" height="37.99" />
        <path className="st1" d="M103.42,20.73l-3.01,0c-11.02-0.01-23.12,1.02-34.89,3.9v27.69h37.9V20.73z" />
        <polygon className="st1" points="179.21,20.79 141.31,20.76 141.31,52.44 179.21,52.44" />
        <polygon className="st1" points="255.06,20.85 217.15,20.82 217.15,52.48 251.26,52.48 251.61,50.93 255.06,50.93" />
        <polygon className="st1" points="330.88,20.9 292.97,20.88 292.97,50.82 330.88,50.82" />
        <polygon className="st1" points="406.64,20.96 368.74,20.93 368.74,50.88 406.64,50.88" />
        <path className="st1" d="M482.45,21.02l-37.9-0.03v31.55h24.63c4.37-1.44,8.79-2.62,13.28-3.53V21.02z" />
        <path className="st1" d="M558.28,21.08l-37.9-0.03v27.09c6.16,0.95,12.38,2.45,18.65,4.52h19.25V21.08z" />
        <polygon className="st1" points="634.17,21.13 596.27,21.1 596.27,52.28 634.17,52.28" />
        <polygon className="st1" points="709.98,21.19 672.08,21.16 672.08,52.56 709.98,52.56" />
        <polygon className="st1" points="785.8,21.25 747.9,21.22 747.9,52.28 785.8,52.28" />
        <polygon className="st1" points="861.56,21.31 823.65,21.28 823.65,52.53 861.56,52.53" />
        <polygon className="st1" points="937.37,21.37 899.46,21.33 899.46,52.61 937.37,52.61" />
        <path className="st1" d="M1013.19,22.63c-4.99-0.85-10.23-1.29-15.73-1.22l-22.17-0.02v31.32h37.9V22.63z" />
        <path className="st1" d="M1061.69,52.58c-3.05-4.19-6.53-8.12-10.44-11.66v11.66H1061.69z" />
        <path className="st1" d="M1070.49,128.53c5.48-11.82,7.34-25.01,5.91-37.99h-25.19v37.99H1070.49z" />
        <path className="st1" d="M1022.17,166.51c11.61-3.03,21.25-7.83,29.03-13.86v-24.13h-37.9v37.99H1022.17z" />
        <path className="st1" d="M975.39,170.02h22.16c5.56-0.23,10.8-0.78,15.74-1.62v-1.9h-37.9V170.02z" />
        <path className="st1" d="M937.46,216.03c0.92-1.5,1.79-3.05,2.59-4.68c1.03-3.02,1.89-5.54,2.61-7.68h-5.21V216.03z" />
        <path className="st1" d="M899.55,243.59c1.81-0.59,3.6-1.23,5.34-1.92h-5.34V243.59z" />
        <path className="st1" d="M823.71,251.77c12.17,0,25.13-0.14,37.9-1.19v-8.87h-37.9V251.77z" />
        <path className="st1" d="M785.77,312.88c8.15-8.56,14.3-19.62,18.3-33.23h-18.3V312.88z" />
        <path className="st1" d="M747.87,333.04c12.85-2.63,23.87-7.75,32.88-15.41h-32.88V333.04z" />
        <polygon className="st1" points="670.73,334.87 708.64,335.05 708.64,317.09 670.73,317.09" />
        <polygon className="st1" points="595.2,334.6 633.1,334.7 633.1,317.09 595.2,317.09" />
        <polygon className="st1" points="520.02,334.41 557.92,334.51 557.92,317.09 520.02,317.09" />
        <polygon className="st1" points="444.48,334.21 482.38,334.31 482.38,317.09 444.48,317.09" />
        <path className="st1" d="M307.17,317.61c6.86,4.88,14.79,8.33,23.67,10.76v-10.76H307.17z" />
        <path className="st1" d="M282.53,279.62c2.53,9.3,6.02,17.03,10.4,23.43v-23.43H282.53z" />
        <path className="st1" d="M217.12,250.39c12.14,1.59,24.94,2.25,37.9,2.52v-11.26h-37.9V250.39z" />
        <path className="st1" d="M179.2,240.63c0.78,0.35,1.57,0.68,2.37,1.01h35.53v-37.99h-37.9V240.63z" />
        <path className="st1" d="M141.34,200.37c0.44,1.29,0.91,2.54,1.4,3.77h36.5v-37.99h-37.9V200.37z" />
        <path className="st1" d="M65.52,168.82c4.38,1.02,8.83,1.55,13.31,1.53l24.59-0.14v-4.19h-37.9V168.82z" />
        <path className="st1" d="M27.62,145.8c8.15,8.81,18.05,15.89,28.76,20.14h9.14v-37.99h-37.9V145.8z" />
        <path className="st1" d="M6.09,90.34c-0.86,13.01,2.59,26.19,8.99,37.99h12.53V90.34H6.09z" />
        <path className="st1" d="M27.63,43.83c-2.76,2.56-5.33,5.39-7.68,8.51h7.68V43.83z" />
      </g>
    </svg>
  ),
}
