/* eslint-disable max-len */
const programName = 'Houdini'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 132.3 25">
      <title>{programName}</title>
      <path d="M36.9 19.3V13h-7v6.3h-3.8V3.5h3.8v6.3h7V3.5h3.8v15.8zM57.5 11.4c0-2.6-2.1-4.8-5-4.8s-5 2.1-5 4.7 2.1 4.8 5 4.8c3 .1 5-2 5-4.7zm-5 7.9c-5.1 0-8.8-3.5-8.8-7.8s3.7-7.9 8.8-7.9c5.1 0 8.8 3.5 8.8 7.8s-3.7 7.9-8.8 7.9zM71.5 19.2c-4.5 0-7.3-2.3-7.3-6.9V3.5h3.7v8.7c0 2.5 1.4 3.8 3.6 3.8 2.3 0 3.6-1.3 3.6-3.7V3.5h3.7v8.7c.1 4.7-2.8 7-7.3 7zM94.5 11.4c0-2.8-2.1-4.7-5.2-4.7h-2.9v9.5h2.9c3.1 0 5.2-1.9 5.2-4.8zm-5.2 7.9h-6.7V3.5h6.7c5.4 0 9.2 3.4 9.2 7.8 0 4.6-3.8 8-9.2 8zM101.4 3.5h3.8v15.7h-3.8zM121.2 19.3l-8.4-10v10h-3.7V3.5h3.5l8.1 9.7V3.5h3.7v15.8zM128.5 3.5h3.8v15.7h-3.8zM21.5 5.7c-1.3 1.2-4.4 2.5-5.1 6-.5 2.5 2.7 3.6 3.8 5.4 1.8 3.1-4 6.6-5.5 1.7-1.4-5-.6-7-2.6-7.6-3.8-1.1-4.4 7.3-5.1 10.5-.9 5.1-9.2 4.1-6.3-2.6C2.6 14.3 9 12.6 9 9.7c.1-1.9-4.7-1-5.4-5.9C2.9-.1 8.1-1.6 8 2.3c-.1 4.2 1.4 6.3 2.7 6 2.5-.6 1.2-3 .7-4.8-1.2-3.8 5.9-3.3 3.8.4-1.5 2.7-1.4 3.1-.5 3.6 1.1.6 2.4 0 3.2-4.6 1-5.1 9.5-2.5 3.6 2.8z" />
    </svg>
  ),
}
