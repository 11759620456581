/* eslint-disable max-len */
const programName = 'Zooplus'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 410.5 112.6">
      <title>{programName}</title>
      <g fill="#3c9700">
        <path d="M3.5 26.7v13.5h25L1.4 69.7v16H51V72.2H23.3L51 42V26.7z" />
        <path d="M202.8 111.2h18.8V76.8h.9c3.1 8.1 9.1 9.6 15.9 9.6 15.7 0 21.7-7.2 21.7-25.2V47.7c0-15.6-7.9-21.7-21.7-21.7-8.2 0-14.2 2-16.4 10h-.9l.4-9.3h-18.8l.1 84.5zM231 40.8c9.9 0 10.4 3.9 10.4 15.4 0 13.4-2.6 15.3-10.4 15.3-8.7 0-9.4-5.4-9.4-15.3 0-12.4 1.4-15.4 9.4-15.4z" fillRule="evenodd" />
        <path d="M285.3 1.4h-18.8v84.3h18.8zm61.3 84.3v-59h-18.8V63c0 5.8-5.6 8.5-10.6 8.5-6.3 0-6.9-4.3-6.9-9.7V26.7h-18.8V67c0 14.4 8.1 19.3 19.4 19.3 8 0 14.5-2.9 17.1-12h.9l-.6 11.3 18.3.1zm59.8-42.3v-2.8c0-14.5-14.9-14.6-25.3-14.6-16 0-28.1.3-28.1 19.3 0 12 4.3 17.5 28.1 17.5 6.5 0 9.8 0 9.8 5.8 0 4.6-1.3 5.2-9.8 5.2-4.6 0-9.3 0-9.3-6.6H353c0 19.4 13.2 19.1 28.1 19.1 15.1 0 28.1-2.6 28.1-19.9 0-16.5-8.6-17.6-28.1-18.1-9.6-.2-9.8-2.2-9.8-5.2 0-3.5 0-4.7 9.8-4.7 3 0 7 0 7.6 4.9l17.7.1z" />

      </g>
      <radialGradient id="a" cx="161.334" cy="89.638" r="37.126" gradientTransform="matrix(1 0 0 -1 0 147.386)" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#fde263" />
        <stop offset=".467" stopColor="#fde262" />
        <stop offset=".513" stopColor="#f9d85a" />
        <stop offset=".591" stopColor="#efbc45" />
        <stop offset=".612" stopColor="#ecb43e" />
        <stop offset=".691" stopColor="#da902c" />
        <stop offset=".824" stopColor="#af6026" />
        <stop offset=".868" stopColor="#ad5f26" />
        <stop offset=".893" stopColor="#a45b26" />
        <stop offset=".913" stopColor="#965526" />
        <stop offset=".93" stopColor="#834c26" />
        <stop offset=".946" stopColor="#694126" />
        <stop offset=".958" stopColor="#523726" />
      </radialGradient>
      <path d="M197.3 57.7c0 19.8-16.1 35.8-35.8 35.8-19.8 0-35.8-16.1-35.8-35.8s16.1-35.8 35.8-35.8c19.8 0 35.8 16.1 35.8 35.8z" fill="url(#a)" />
      <path className="dark-detail" d="M180.5 57.7c0 10.5-8.5 19-19 19s-19-8.5-19-19 8.5-19 19-19 19 8.5 19 19z" fill="#262020" />
      <radialGradient id="b" cx="150.122" cy="101.913" r="10.825" gradientTransform="matrix(1 0 0 -1 0 147.386)" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#eaebec" stopOpacity=".95" />
        <stop offset=".979" stopColor="#eaebec" stopOpacity=".02" />
        <stop offset="1" stopColor="#eaebec" stopOpacity="0" />
      </radialGradient>
      <path d="M160.9 45.5c0 6-4.9 10.8-10.8 10.8-6 0-10.8-4.8-10.8-10.8s4.9-10.8 10.8-10.8c6-.1 10.8 4.8 10.8 10.8z" fill="url(#b)" />
      <radialGradient id="c" cx="87.172" cy="89.638" r="37.126" gradientTransform="matrix(1 0 0 -1 0 147.386)" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#fde263" />
        <stop offset=".467" stopColor="#fde262" />
        <stop offset=".513" stopColor="#f9d85a" />
        <stop offset=".591" stopColor="#efbc45" />
        <stop offset=".612" stopColor="#ecb43e" />
        <stop offset=".691" stopColor="#da902c" />
        <stop offset=".824" stopColor="#af6026" />
        <stop offset=".868" stopColor="#ad5f26" />
        <stop offset=".893" stopColor="#a45b26" />
        <stop offset=".913" stopColor="#965526" />
        <stop offset=".93" stopColor="#834c26" />
        <stop offset=".946" stopColor="#694126" />
        <stop offset=".958" stopColor="#523726" />
      </radialGradient>
      <path d="M123.2 57.7c0 19.8-16.1 35.8-35.8 35.8-19.8 0-35.8-16.1-35.8-35.8s16.1-35.8 35.8-35.8 35.8 16.1 35.8 35.8z" fill="url(#c)" />
      <path d="M106.4 57.7c0 10.5-8.5 19-19 19s-19-8.5-19-19 8.5-19 19-19 19 8.5 19 19z" fill="#262020" className="dark-detail" />
      <radialGradient id="d" cx="75.962" cy="101.913" r="10.825" gradientTransform="matrix(1 0 0 -1 0 147.386)" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#eaebec" stopOpacity=".95" />
        <stop offset=".979" stopColor="#eaebec" stopOpacity=".02" />
        <stop offset="1" stopColor="#eaebec" stopOpacity="0" />
      </radialGradient>
      <path d="M86.8 45.5c0 6-4.8 10.8-10.8 10.8s-10.8-4.8-10.8-10.8S70 34.6 76 34.6s10.8 4.9 10.8 10.9z" fill="url(#d)" />
    </svg>),
}
