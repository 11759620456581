/* eslint-disable max-len */
const programName = 'Lumene'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 410.23 80.57">
      <title>{programName}</title>
      <g id="_Gruppe_" dataName="&lt;Gruppe&gt;">
        <path id="_Zusammengesetzter_Pfad_" dataName="&lt;Zusammengesetzter Pfad&gt;" className="cls-1" d="M353.81,165.94a8.6,8.6,0,1,0-8.6-8.61A8.62,8.62,0,0,0,353.81,165.94Zm0-12.63a4.23,4.23,0,0,1,4,4,4.34,4.34,0,0,1-4,4.22,4.4,4.4,0,0,1-4.22-4.22A4.34,4.34,0,0,1,353.82,153.31Z" transform="translate(-191.84 -148.74)" />
        <path id="_Pfad_" dataName="&lt;Pfad&gt;" className="cls-1" d="M374.18,169.6a1.65,1.65,0,0,0-1.58-1.13,1.52,1.52,0,0,0-1.43,1l-17.49,41.39-17.5-41.39a1.51,1.51,0,0,0-1.43-1,1.65,1.65,0,0,0-1.58,1.13c-.26.77-18.67,57.22-18.67,57.22a1.07,1.07,0,0,0,.1,1,1.2,1.2,0,0,0,1,.53h6a2.11,2.11,0,0,0,1.93-1.36l12-37.36L351.92,228a1.9,1.9,0,0,0,3.51,0l16.45-38.3,12,37.36a2.11,2.11,0,0,0,1.93,1.36h6a1.21,1.21,0,0,0,1-.53,1.06,1.06,0,0,0,.09-1S374.44,170.37,374.18,169.6Z" transform="translate(-191.84 -148.74)" />
        <path id="_Pfad_2" dataName="&lt;Pfad&gt;" className="cls-1" d="M293.36,169.47h-5.82a1.62,1.62,0,0,0-1.62,1.62v35.27c0-.44,0-7.39,0,0,0,8.58-6.32,14.2-13.88,14.2s-13.88-5.62-13.88-14.2V171.09a1.61,1.61,0,0,0-1.62-1.62h-5.81a1.61,1.61,0,0,0-1.62,1.62v35.29c0,14.36,10.34,22.93,22.93,22.93S295,220.74,295,206.38V171.09A1.62,1.62,0,0,0,293.36,169.47Z" transform="translate(-191.84 -148.74)" />
        <path id="_Pfad_3" dataName="&lt;Pfad&gt;" className="cls-1" d="M224.85,220.29H202a1.07,1.07,0,0,1-1.06-1.07V171.09a1.61,1.61,0,0,0-1.62-1.62h-5.81a1.62,1.62,0,0,0-1.62,1.62v55.66a1.63,1.63,0,0,0,1.62,1.62h31.39a1.62,1.62,0,0,0,1.62-1.62v-4.84A1.61,1.61,0,0,0,224.85,220.29Z" transform="translate(-191.84 -148.74)" />
        <path id="_Pfad_4" dataName="&lt;Pfad&gt;" className="cls-1" d="M454.72,169.48H419.51a1.62,1.62,0,0,0-1.62,1.62v23.66h-8a1.63,1.63,0,0,0-1.62,1.62v4.53a1.63,1.63,0,0,0,1.62,1.62h8v24.23a1.63,1.63,0,0,0,1.62,1.62h35.21a1.62,1.62,0,0,0,1.62-1.62v-4.84a1.61,1.61,0,0,0-1.62-1.62H427.2V203.57a1.06,1.06,0,0,1,1.06-1h21a1.63,1.63,0,0,0,1.62-1.62v-4.53a1.63,1.63,0,0,0-1.62-1.62h-21a1.06,1.06,0,0,1-1.06-1.05V177.56h27.52a1.61,1.61,0,0,0,1.62-1.62V171.1A1.62,1.62,0,0,0,454.72,169.48Z" transform="translate(-191.84 -148.74)" />
        <path id="_Pfad_5" dataName="&lt;Pfad&gt;" className="cls-1" d="M600.45,177.56a1.62,1.62,0,0,0,1.62-1.62V171.1a1.63,1.63,0,0,0-1.62-1.62H565.24a1.61,1.61,0,0,0-1.62,1.62v23.66h-8a1.63,1.63,0,0,0-1.62,1.62v4.53a1.63,1.63,0,0,0,1.62,1.62h8v24.23a1.62,1.62,0,0,0,1.62,1.62h35.21a1.63,1.63,0,0,0,1.62-1.62v-4.84a1.62,1.62,0,0,0-1.62-1.62H572.93V203.58a1.05,1.05,0,0,1,1.06-1.06h21a1.63,1.63,0,0,0,1.62-1.62v-4.53a1.63,1.63,0,0,0-1.62-1.62H574a1.05,1.05,0,0,1-1.06-1.05V177.56Z" transform="translate(-191.84 -148.74)" />
        <path id="_Pfad_6" dataName="&lt;Pfad&gt;" className="cls-1" d="M529.44,169.48H524a1.63,1.63,0,0,0-1.62,1.62V209S490,172.9,486.58,169.5a1.93,1.93,0,0,0-1.4-.66,1.56,1.56,0,0,0-1.61,1.61v56.39a1.63,1.63,0,0,0,1.62,1.62h5.36a1.63,1.63,0,0,0,1.62-1.62V188.37S528,228.53,528.3,228.84a1.67,1.67,0,0,0,1.14.47,1.63,1.63,0,0,0,1.62-1.62V171.1A1.64,1.64,0,0,0,529.44,169.48Z" transform="translate(-191.84 -148.74)" />
      </g>
    </svg>
  ),
}
