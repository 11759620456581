/* eslint-disable max-len */
const programName = 'InkClub'
export default {
  programName,
  logo: (
    <svg className="inkclub" viewBox="0 0 133.41 49.27">
      <title>{programName}</title>
      <rect width="133.41" height="49.27" />
      <path className="cls-1" d="M16.14,10.35a3.67,3.67,0,0,1,3.59,3.57,3.71,3.71,0,0,1-3.59,3.66A3.7,3.7,0,0,1,12.55,14a3.72,3.72,0,0,1,3.59-3.66" />
      <path className="cls-2" d="M14,20.06h4.19a.24.24,0,0,1,.24.24V36.57a.24.24,0,0,1-.24.24H14a.23.23,0,0,1-.23-.23V20.29A.24.24,0,0,1,14,20.06Z" />
      <path className="cls-2" d="M31.45,26.61c0-2.42-1.46-3.23-2.92-3.23-1.87,0-3,1.63-3,3.91v9.28a.24.24,0,0,1-.23.24h-4.2a.23.23,0,0,1-.23-.24V20.29a.23.23,0,0,1,.23-.23h4a.23.23,0,0,1,.24.23V22c0,.13,0,.23,0,.23a.71.71,0,0,0,.15-.2,5.83,5.83,0,0,1,4.8-2.44c4,0,5.83,2.61,5.83,5.84V36.57a.23.23,0,0,1-.23.24h-4.2a.23.23,0,0,1-.23-.24Z" />
      <path className="cls-2" d="M78.73,12.3h4.19a.24.24,0,0,1,.24.24v24a.24.24,0,0,1-.24.24H78.73a.23.23,0,0,1-.23-.23v-24A.24.24,0,0,1,78.73,12.3Z" />
      <path className="cls-2" d="M96.32,34.86c0-.13,0-.23,0-.23a.56.56,0,0,0-.16.19,5.71,5.71,0,0,1-4.79,2.45c-4,0-5.83-2.61-5.83-5.84V20.29a.23.23,0,0,1,.23-.23h4.19a.23.23,0,0,1,.24.23V30c0,.13,0,.34,0,.47a2.89,2.89,0,0,0,2.93,3c1.88,0,3-1.63,3-3.91V20.29a.23.23,0,0,1,.23-.23h4.2a.23.23,0,0,1,.23.23V36.57a.23.23,0,0,1-.23.24h-4a.24.24,0,0,1-.24-.24Z" />
      <path className="cls-2" d="M111.92,23.58c-2.92,0-4.28,2.38-4.28,4.89,0,2.8,1.49,5,4.15,5s4.21-2.32,4.21-5-1.39-5-4.08-5m-8.78-11a.23.23,0,0,1,.23-.24h4.2a.23.23,0,0,1,.23.24V22.1c0,.13,0,.24,0,.24a.87.87,0,0,0,.16-.2,6.37,6.37,0,0,1,5.15-2.54c4.79,0,7.71,4.14,7.71,8.8s-3.14,8.87-7.78,8.87a6.19,6.19,0,0,1-5.41-2.81s-.06.11-.06.24v1.87a.24.24,0,0,1-.24.24h-4a.23.23,0,0,1-.23-.24Z" />
      <path className="cls-2" d="M66.12,32.9c-5.87,0-6.39-6.67-6.39-6.67a.32.32,0,0,0-.25-.28l-4.89-1.08a.9.9,0,0,0,0,.17s-.27,12.23,11.33,12.23c8,0,10.32-7.39,10.32-7.39a.23.23,0,0,0-.17-.28l-4.3-1a.24.24,0,0,0-.28.18,5.67,5.67,0,0,1-5.37,4.07M66,16.21a5.57,5.57,0,0,1,5.58,4.51.22.22,0,0,0,.27.18L76.1,20a.27.27,0,0,0,.2-.29s-1.45-7.82-10.38-7.82c-6.67,0-11.33,5.31-11.33,12.71v.14l4.87-1.07a.32.32,0,0,0,.23-.29s.62-7.13,6.3-7.13" />
      <path className="cls-2" d="M43.33,28.79a.67.67,0,0,0-.17-.19s0,.1,0,.23v7.74a.24.24,0,0,1-.24.24H38.7a.24.24,0,0,1-.24-.24v-24a.24.24,0,0,1,.24-.24h4.19a.24.24,0,0,1,.24.24V27.15c0,.13,0,.24,0,.24a.71.71,0,0,0,.16-.19l4.76-6.95a.48.48,0,0,1,.37-.19h4.94c.12,0,.16.08.09.19l-5.29,7.16a.35.35,0,0,0,0,.38l6.71,8.83c.08.1,0,.19-.09.19h-5.4a.56.56,0,0,1-.38-.19Z" />
    </svg>
  ),
}
