/* eslint-disable max-len */
const programName = 'Jotex'
export default {
    programName,
    logo: (
        <svg viewBox="0 0 143 40">
            <title>{programName}</title>
            <path d="M132.455 24.742l9.28-12.888h-9.257l-5.062 7.288-5.06-7.288h-9.26l9.28 12.888-10.543 14.707h9.53l6.053-8.722 6.053 8.72H143l-10.545-14.706zM98.6 17.946c2.017 0 4.32 1.452 4.792 3.832h-9.996c.516-1.925 2.518-3.832 5.204-3.832zm9.386-2.456c-2.42-2.7-5.753-4.187-9.386-4.187-3.848 0-7.324 1.537-9.787 4.327-2.34 2.652-3.63 6.22-3.63 10.05 0 4.178 1.427 7.773 4.127 10.395C91.916 38.605 95.567 40 99.59 40c4.4 0 8.724-1.897 11.282-4.95l.25-.3-4.663-4.67-.302.327c-.636.692-2.976 2.95-6.402 2.95-3.47 0-6.172-2.233-6.723-5.487h18.363l.064-.35c.113-.62.172-1.352.172-2.117 0-3.77-1.295-7.29-3.646-9.913zm-25.284 3.283v-6.92h-8.808V4.412h-8.01v7.444h-7.43v6.92h7.43v8.945c0 3.867.62 6.334 2.068 8.25 1.975 2.636 5.29 4.03 9.59 4.03 1.435 0 3.626-.23 4.6-.576l.343-.12-1.246-6.86-.433.096c-.486.108-1.828.265-2.825.265-1.522 0-2.636-.395-3.308-1.172-.546-.647-.78-1.57-.78-3.087v-9.773h8.808zM47.71 30.533c-1.21 1.288-2.91 1.997-4.782 1.997-1.873 0-3.57-.71-4.782-1.996-1.185-1.26-1.838-2.984-1.838-4.855 0-3.938 2.846-6.907 6.62-6.907s6.62 2.97 6.62 6.906c0 1.87-.653 3.594-1.84 4.854zm-4.782-19.23c-8.203 0-14.63 6.314-14.63 14.376C28.3 33.71 34.726 40 42.93 40c8.203 0 14.628-6.29 14.628-14.32 0-8.063-6.425-14.377-14.628-14.377zM15.26 0v27.72c0 1.992-.316 2.813-.79 3.43-.69.89-1.763 1.38-3.02 1.38-2.622 0-3.753-2.163-4.118-3.093l-.17-.435L0 32.59l.157.37C2.007 37.3 6.313 40 11.394 40c4.708 0 8.88-2.326 10.89-6.07.884-1.66 1.26-3.765 1.26-7.038V0h-8.283z"></path>
        </svg>
    ),
}