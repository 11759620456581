/* eslint-disable max-len */
const programName = 'Hugo Boss'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 857 319.1">
      <title>{programName}</title>
      <g id="layer1" transform="translate(-268.43,-697.22)">
        <g id="g6981" transform="translate(3.536427,3.542911)">
          <path id="path7114" className="st0" d="M280.6,960.9h6.7V981H313v-20.1h6.5v46.9H313v-21.5h-25.7v21.5h-6.7V960.9z" />
          <path
            id="path7116"
            className="st0"
            d="M423.3,990.8c0,11.8-7.2,18.1-19.4,18.1c-12.5,0-20.1-5.6-20.1-18.1v-29.9h6.7v29.9
                c0,8.3,4.9,13,13.4,13c8.3,0,13-4.7,13-13v-29.9h6.5V990.8z"
          />
          <path
            id="path7118"
            className="st0"
            d="M504.4,1009.2c-14.7,0.9-23.7-11.6-23.7-23.9c0-13.6,7.8-25.5,23.5-25.5
                c10.7,0,19.2,4.5,21,15.6h-6.5c-1.3-7.2-7.4-10.5-14.5-10.5c-12.1,0-17,10.1-17,20.1c0,9.8,6.3,19.2,18.1,19
                c6-0.2,10.9-1.3,13.4-5.4c1.8-2.5,1.3-6.9,1.1-10.3h-15.6V983h21.5c0,0,0,12.3,0,17.7C520.1,1007.6,513.4,1008.7,504.4,1009.2z"
          />
          <path
            id="path7120"
            className="st0"
            d="M605.9,1003.8c11.8,0,17-10.1,17-19.4c0-9.4-5.1-19.4-17-19.4c-12.1,0-17,10.1-17,19.4
                C588.9,993.7,593.8,1003.8,605.9,1003.8z M605.9,959.8c15.6,0,23.5,11.8,23.5,24.6c0,12.7-7.8,24.6-23.5,24.6
                c-15.6,0-23.5-11.8-23.5-24.6C582.4,971.6,590.2,959.8,605.9,959.8z"
          />
          <path
            id="path7122"
            className="st0"
            d="M761,981h13c7.4,0,10.5-2.5,10.5-7.4c0-6.5-4.7-7.6-10.5-7.6h-13V981z M761,1002.7h16.3
                c5.6,0,9.2-3.4,9.2-8.5c0-6-5.1-7.8-10.7-7.8H761V1002.7z M754.5,960.9h17.7c4.5,0,10.1,0,13,1.6c3.4,2,5.8,5.4,5.8,9.8
                c0,5.1-2.7,8.9-7.1,10.7l0,0c6,1.1,9.4,5.6,9.4,11.4c0,6.9-5.4,13.4-15,13.4h-23.7V960.9z"
          />
          <path
            id="path7124"
            className="st0"
            d="M875.1,1003.8c12.1,0,17-10.1,17-19.4c0-9.4-4.9-19.4-17-19.4c-11.8,0-17,10.1-17,19.4
                C858.1,993.7,863.3,1003.8,875.1,1003.8z M875.1,959.8c15.6,0,23.7,11.8,23.7,24.6c0,12.7-8,24.6-23.7,24.6
                c-15.6,0-23.5-11.8-23.5-24.6C851.7,971.6,859.5,959.8,875.1,959.8z"
          />
          <path
            id="path7126"
            className="st0"
            d="M988.9,974.8c-0.7-6.9-5.4-9.8-12.1-9.8c-5.6,0-10.9,2-10.9,8c0,5.8,7.8,6.7,15.6,8.5
                c7.8,1.6,15.6,4.2,15.6,13.6c0,9.8-10.3,13.9-19.2,13.9c-10.9,0-20.1-5.1-20.1-16.5h6c0,7.8,6.9,11.4,14.3,11.4
                c6,0,12.5-1.8,12.5-8.7c0-6.5-7.8-7.4-15.6-9.2c-7.8-1.6-15.9-3.8-15.9-12.5c0-9.4,8.9-13.6,17.9-13.6c10.1,0,17.7,4.5,18.1,15
                H988.9z"
          />
          <path
            id="path7128"
            className="st0"
            d="M1094.8,974.8c-0.9-6.9-5.6-9.8-12.3-9.8c-5.4,0-10.9,2-10.9,8c0,5.8,7.8,6.7,15.9,8.5
                c7.8,1.6,15.6,4.2,15.6,13.6c0,9.8-10.3,13.9-19.2,13.9c-10.9,0-20.3-5.1-20.3-16.5h6.3c0,7.8,6.9,11.4,14.3,11.4
                c5.8,0,12.5-1.8,12.5-8.7c0-6.5-8-7.4-15.9-9.2c-7.8-1.6-15.6-3.8-15.6-12.5c0-9.4,8.7-13.6,17.9-13.6c9.8,0,17.7,4.5,17.9,15
                H1094.8z"
          />
          <path
            id="path7130"
            className="st0"
            d="M338.2,712.9v84.5h30.6c0,0,2.7-0.2,4.2-0.7c17.9-1.6,29.3-23,30.4-38.7
                c1.8-16.8-8.5-44.5-34.2-45.1C361.7,712.7,338.2,712.9,338.2,712.9z M338.2,807.2v101.2h27.7c0,0,15.6,1.1,29.7-10.3
                c13.9-11.2,21-27.7,19.4-46.7c-1.1-17-14.1-41.1-43.1-44.2C356.5,807.2,338.2,807.2,338.2,807.2z M403.4,703.1
                c23,1.3,38,13.6,39.3,15.2c11.8,10.9,15.4,27.5,12.5,43.3c-3.8,26.6-36.9,37.8-36.9,37.8c27.7,3.4,48.5,24.1,51.6,46.5
                c2.7,21.9-5.4,41.3-23.5,55c-18.3,13.6-45.1,18.1-59.7,18.1c0,0-112.2,0-118.4,0c0-3.6,0-6.3,0-9.4c15.2,0.2,17,0.2,17-5.8
                c-0.2-22.8,0-181.9,0-186.1c0-4.9-2.5-5.1-17-5.1c-0.2-3.8,0.2-9.4,0.2-9.4H403.4z"
          />
          <path
            id="path7132"
            className="st0"
            d="M586.2,714.7c-14.7,9.8-25,32.4-29.9,51.2c-7.8,28.4-6.3,61.9,1.6,96.3
                c8,34.4,30.2,46.5,43.3,49.4c24.6,5.6,40.7-8.7,49.4-21.9c17-25.7,21.2-78.7,15.9-109c-4.9-28.2-12.5-47.8-27.3-61
                C622.6,705.1,599,706.2,586.2,714.7z M718.7,848.3c-10.3,30.6-30.8,49.8-54.5,62.3c-31.1,16.8-75.5,19.4-106.6,0.9
                c-19.2-11.4-68.1-41.8-61.7-113.5c1.3-15.4,9.2-54.1,52.3-83.1c29.5-19.2,71.9-24.8,111.5-6.5
                C721.4,736.6,734.4,806.1,718.7,848.3z"
          />
          <path
            id="path7134"
            className="st0"
            d="M899.9,713.1c0.2,16.1,0,49.4,0,49.4l-7.8,0.2c-4.7-20.8-14.7-38.7-32-46.9
                c-16.5-8-41.6-12.1-57.9,2.2c-9.6,8.5-10.9,17.2-7.6,27c5.8,14.5,21.9,19,34.4,24.6c10.3,4,32.2,11.2,32.2,11.2
                c24.4,8.5,51.4,25,58.8,52.5c3.4,11.8,3.4,25.2,0.7,37.3c-6,26.8-23.2,33.3-33.1,40c-4.9,3.4-50.9,21-106.4,2.9
                c-11.4-3.8-24.6-8.3-24.6-8.3v-60.1c0,0,6,0.2,8.5,0.2c3.8,9.6,7.2,21.2,15.2,32.8c18.8,27.3,45.4,35.5,74.6,29.3
                c11.6-2.7,24.4-10.7,27.7-22.8c4.2-13.6,2.9-36.2-38.2-51.4c-29.7-13.6-67-19.2-84.7-52.3c-6.9-18.5-4.9-50.7,17.9-66.4
                c23-15.6,45.4-19.2,72.4-15.6C876.9,702.8,884.1,706.4,899.9,713.1z"
          />
          <path
            id="path7136"
            className="st0"
            d="M1095.7,713.1c0.2,16.1,0,49.4,0,49.4l-7.8,0.2c-4.7-20.8-14.7-38.7-32-46.9
                c-16.5-8-41.6-12.1-57.9,2.2c-9.6,8.5-10.9,17.2-7.6,27c5.8,14.5,21.9,19,34.4,24.6c10.3,4,32.2,11.2,32.2,11.2
                c24.4,8.5,51.4,25,58.8,52.5c3.4,11.8,3.4,25.2,0.7,37.3c-6,26.8-23.2,33.3-33.1,40c-4.9,3.4-50.9,21-106.4,2.9
                c-11.4-3.8-24.6-8.3-24.6-8.3v-60.1c0,0,6,0.2,8.5,0.2c3.8,9.6,7.2,21.2,15.2,32.8c18.8,27.3,45.4,35.5,74.6,29.3
                c11.6-2.7,24.4-10.7,27.7-22.8c4.2-13.6,2.9-36.2-38.2-51.4c-29.7-13.6-67-19.2-84.7-52.3c-6.9-18.5-4.9-50.7,18.1-66.4
                c22.8-15.6,45.1-19.2,71.9-15.6C1072.6,702.8,1079.8,706.4,1095.7,713.1z"
          />
        </g>
      </g>
    </svg>),
}
