/* eslint-disable max-len */
const programName = 'Bulk'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 670.8 208.4">
      <title>{programName}</title>
      <g>
        <path d="M535,43.1" />
        <g>
          <path d="M322,131c0,25.3-20.5,45.8-45.8,45.8s-45.8-20.5-45.8-45.8V44.2h-31.6V131c0,42.7,34.6,77.3,77.3,77.3 s77.3-34.6,77.3-77.3V44.2H322V131z" />
          <path d="M423,129.4V0h-31.6v129.4c0,42.7,34.6,77.3,77.3,77.3v-31.6C443.5,175.2,423,154.7,423,129.4z" />
          <path d="M589.4,110.8c20.6-14.9,34-39.2,34-66.6h-31.6c0,30.5-24.7,55.2-55.2,55.2v0h-4.7V0h-31.6v205.2h31.6V131h20.5v0 c30.5,0,55.2,24.7,55.2,55.2v18.9h31.6v-18.9C639.3,152.4,618.7,123.3,589.4,110.8z" />
          <path d="M86.8,41C65.4,41,46,49.7,31.6,63.9V0H0v205.2h31.6v-19.7c14.4,14.1,33.9,22.8,55.2,22.8c44.5,0,80.5-37.5,80.5-83.7 S131.3,41,86.8,41z M83.7,176.8c-28.8,0-52.1-23.3-52.1-52.1c0-28.8,23.3-52.1,52.1-52.1s52.1,23.3,52.1,52.1 C135.7,153.5,112.4,176.8,83.7,176.8z" />
        </g>
        <g>
          <g>
            <path d="M639.3,0h13.5v2.7h-5.2v11.6h-3.1V2.7h-5.2V0z" />
          </g>
          <g>
            <path d="M667.7,14.3V6h-0.1l-4,6.7h-0.9l-4-6.7h-0.1v8.3h-3V0h3l4.7,7.8h0l4.7-7.8h2.9v14.3H667.7z" />
          </g>
        </g>
      </g>
    </svg>
  ),
}
