/* eslint-disable max-len */
const programName = 'Vero Moda'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 107.8 12.6">
      <title>{programName}</title>
      <g>
        <path d="M12.4,2l-4.5,9.5H6.1L1.9,2h2.7l2.8,6.3l2.9-6.4L12.4,2L12.4,2z M22.5,3.3h-5.4v2.5h4.1v1.6h-4.1v2.7h5.4v1.4h-8V2h8V3.3
                        L22.5,3.3z M35.3,11.5h-2.5l-2.9-4.1h-2.5v4.1h-2.5V2h6.6c0.5,0,1,0.1,1.3,0.2C33.3,2.4,33.7,2.6,34,3c0.3,0.4,0.5,1,0.5,1.6
                        s-0.2,1.1-0.6,1.5c-0.3,0.3-0.7,0.6-1.1,0.8c-0.3,0.2-0.5,0.2-0.6,0.2L35.3,11.5z M32.2,4.7C32.2,4.6,32.2,4.6,32.2,4.7
                        c0-0.3-0.1-0.6-0.2-0.8c-0.2-0.3-0.6-0.5-1.2-0.5h-3.4V6h3.5c0.1,0,0.2,0,0.3-0.1c0.3-0.1,0.5-0.2,0.6-0.3
                        C32.1,5.3,32.2,5,32.2,4.7z"
        />
        <path d="M47.3,6.7c0,1.4-0.4,2.5-1.2,3.4c-0.6,0.6-1.4,1.1-2.4,1.4c-0.6,0.2-1.3,0.3-1.9,0.3s-1.2-0.1-1.9-0.3
                        c-1-0.3-1.8-0.7-2.4-1.4c-0.8-0.9-1.2-2-1.2-3.4s0.4-2.6,1.2-3.4c0.6-0.6,1.4-1.1,2.4-1.4c0.6-0.2,1.3-0.3,1.9-0.3s1.3,0.1,1.9,0.3
                        c1,0.3,1.8,0.8,2.4,1.4C46.9,4.2,47.3,5.3,47.3,6.7z M44.6,6.7c0-1.1-0.3-2.1-0.8-2.9c-0.6-0.8-1.2-1.2-2-1.2S40.4,3,39.9,3.8
                        s-0.8,1.7-0.8,2.9c0,1.1,0.3,2.1,0.8,2.9c0.6,0.8,1.2,1.2,2,1.2s1.4-0.4,1.9-1.2C44.3,8.8,44.6,7.9,44.6,6.7z M68.5,11.5h-2.2
                        L64,4.2l-2.8,7.3h-1.9l-2.4-7.3l-2,7.3h-2.2L55.3,2h3.3l1.8,5.7L62.5,2h3.1L68.5,11.5z"
        />
        <path d="M80.4,6.7c0,1.4-0.4,2.5-1.2,3.4c-0.6,0.6-1.4,1.1-2.4,1.4c-0.6,0.2-1.3,0.3-1.9,0.3s-1.3-0.1-1.9-0.2
                        c-1-0.3-1.8-0.7-2.4-1.4c-0.8-0.8-1.2-2-1.2-3.4s0.4-2.6,1.2-3.4C71.2,2.8,72,2.3,73,2c0.6-0.2,1.3-0.3,1.9-0.3s1.3,0.1,1.9,0.3
                        c1,0.3,1.8,0.8,2.4,1.4C80,4.2,80.4,5.3,80.4,6.7z M77.6,6.7c0-1.1-0.3-2.1-0.8-2.9c-0.6-0.8-1.2-1.2-2-1.2S73.4,3,72.9,3.8
                        s-0.8,1.7-0.8,2.9c0,1.1,0.3,2.1,0.9,2.9c0.6,0.8,1.2,1.2,2,1.2s1.4-0.4,1.9-1.2C77.4,8.8,77.6,7.8,77.6,6.7z M93,6.9
                        c0,1.4-0.4,2.4-1.1,3.2c-0.5,0.6-1.3,1-2.2,1.2c-0.5,0.1-0.9,0.2-1.3,0.2h-5.8V2h5.8c0.4,0,0.9,0.1,1.3,0.2
                        c0.9,0.2,1.6,0.7,2.2,1.3C92.6,4.3,93,5.4,93,6.9z M90.2,6.7c0-1.1-0.2-2-0.6-2.6C89.2,3.4,88.5,3,87.7,3h-2.6v7.4H88
                        c0.6,0,1.1-0.4,1.5-1.1C90,8.7,90.2,7.8,90.2,6.7z M104,11.5h-2.4l-0.8-2.1h-4L96,11.5h-1.9L97.7,2h2.9L104,11.5z M100.2,7.8
                        l-1.5-4.2l-1.5,4.2H100.2z M105.9,1.9c0,0.3-0.1,0.6-0.3,0.8S105.1,3,104.8,3s-0.6-0.1-0.8-0.3s-0.3-0.5-0.3-0.8s0.1-0.6,0.3-0.8
                        s0.5-0.3,0.8-0.3s0.6,0.1,0.8,0.3C105.8,1.4,105.9,1.6,105.9,1.9z M105.7,1.9c0-0.3-0.1-0.5-0.3-0.7s-0.4-0.3-0.7-0.3
                        S104.2,1,104,1.2s-0.3,0.4-0.3,0.7s0.1,0.5,0.3,0.7s0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3S105.7,2.2,105.7,1.9z M105.4,2.6h-0.2L104.8,2
                        h-0.3v0.6h-0.1V1.3h0.5c0.1,0,0.2,0,0.3,0.1s0.1,0.1,0.1,0.3c0,0.1,0,0.2-0.1,0.2C105.1,2,105.1,2,105,2L105.4,2.6z M105.2,1.7
                        c0-0.1,0-0.1-0.1-0.2c-0.1,0-0.1-0.1-0.2-0.1h-0.3v0.4h0.2c0.1,0,0.2,0,0.3,0C105.1,1.8,105.2,1.8,105.2,1.7z"
        />
      </g>
    </svg>
  ),
}
