/* eslint-disable max-len */
const programName = 'Cervera'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 850.4 197">
      <title>{programName}</title>
      <g fill="#5a5c66">
        <path d="m158.6 62.6c6.2 2.4 11.4-2 13.5-6.4 4.3-8.6 3.6-21.3.7-28.7-5.2-13.3-17.1-23.8-37.3-26.6-35.9-5-70.7 12.7-89.6 28.2-22 18.1-45.9 51.1-45.9 95 0 43 29.8 72.6 78.1 72.9 44.2.2 71.7-29.5 75.7-34.2 1.7-2 5.7-7 3.3-10-2.4-2.9-5.9-1.8-11.7 1.7-25.6 15.7-48.8 20.5-69.8 15-19.5-5.1-40.8-30.6-25.6-76 17.9-53.4 59.4-75.3 84.1-74.9 5.2.1 11.8.5 17.1 5.3 6 5.5 6.4 15.5 3.7 22.4-2.2 5.3-4 13.3 3.7 16.3z" />
        <path d="m371.7 150.9c-15.3 16.5-20.8 16.9-23.2 15.5-2.7-1.5-1-12.4 3.2-24.8 3.8-11.2 14.3-36.1 17-41.8 2.3-4.9 4-8.9 3.2-12.2-1.4-5.6-5.3-15.3-13.8-14.7-9.1.7-25.6 3-36.6 2.3 1.2-3.3 4.4-12.2 6-18.7 4.1-17.2-7.8-22.3-20.4-18-11.1 3.2-26 17.2-26.1 29.9 0 7.5 4.4 14.1 19.4 18.7-9.6 22.2-28.1 58.4-58.6 73.3-2.5 1.2-26.7 13.7-41.8-6.6-3.2-4.4-6.2-14.6-5.4-21.5 27.6 0 48.3-8.4 59.1-18.9 25.8-25.2 17.6-55.1-21.2-55.5-22.2-.2-67.8 12.5-72.5 66.9-5.6 64.4 55.8 70 89.4 54.1 29.9-14.2 51.9-46 66.9-88.2 3.8.5 11.6 1.1 16.9 1.2.7 0 1.6 1 1.2 1.9-4.5 11.4-12.4 28.2-16.3 53.2-2.6 16.5 1.2 33.1 15.5 37.8 23.4 7.7 42-12.2 49.2-26 1.8-3.4 5.8-12 2.4-14.6-4.3-3.3-10.4 3.4-13.5 6.7zm-153.9-71.2c4.4-3.5 12.4-9 16.5-5.8 3.6 2.8-2.6 15.5-4.5 18.7-8.2 14.4-23.1 22.7-34 23 1.4-8 10.6-26.8 22-35.9zm95.4-25.2c-2.1 7.1-4.4 12.9-7 18.8-10.2-2.1-2.4-13.8 2.7-19.6 3.2-3.6 5.9-4.5 4.3.8z" />
        <path d="m850.3 178.9c-.6-2.1-2.5-2.3-4.1-2.3-5 0-10.5.8-14.6-2-4.3-3-5.4-8.8-5.9-13.6-.8-9.9.6-22.9 2.4-37.3 1-7.7 5.4-29.6 5-33.5-1-10.2-11.1-19.2-22.2-14.9-2.3.9-4.3 2.1-6.3 3.4-11.6-24.7-33.9-25.9-49.1-15.6-21.9 15-29.5 49.2-30.3 58-.2 2.2-3.2 5.4-5.2 7.9-6.2 7.6-34.7 41.6-41.4 37.1-2.5-1.7 1.4-11.4 6.7-23.8 4.8-11.2 17.5-36.5 20.6-42.2 2.7-4.9 4.8-8.9 4.3-12.2-.9-5.6-4-15.3-12.6-14.7-9.2.7-27.2 2.9-37.6 2.1 1.2-3.3 4.4-12 6-18.5 4.1-17.2-8.4-22.3-21-18-11 3.2-25.9 17.2-25.9 29.9 0 7.5 4.7 14.6 19.4 18.7-11 22.6-30.5 58.8-58.6 73.3-2.5 1.3-25 13.7-40.1-6.6-3.2-4.4-6.2-14.6-5.4-21.5 27.6 0 48.3-8.4 59.1-18.9 25.8-25.2 17.6-55.1-21.2-55.5-20.2-.2-59.7 10.3-70.2 53.1-2.5-.8-4.9-1.9-7.1-3.4-3.1-2.2-5.6-5.2-6.9-8.7 3.1-6.5 9.6-21.3 9.1-29.1-.6-9.3-7.7-14.6-16.9-13.1-19.7 3.2-20.4 24.6-13.2 40.2-1.2 3.1-15.9 39.3-25.3 54.1-5.3 8.4-8.3 11.8-10 10.6-2.2-1.5-1-11.5-.6-20.1.2-5.6 1.6-30.5 2-43.2.4-12.6-.3-29.3-14.7-37.3-14.1-7.8-29.7-8.7-44.4-6.8-4.4.6-8.7 2.7-8.5 5.3.2 1.7 2 3.6 8.3 5.5 8.2 2.5 19.3 6.8 21.6 22.5 2 13.7-1.3 70.8 2.3 87.2 1.9 8.8 7.9 15.7 18.4 16 8.3.2 17.9-2 28.6-13.4 6.1-6.5 11.9-14.5 15.4-20.6 8-13.8 14.1-26.3 20.3-40.2 2.8 2.5 5.8 4.6 8.9 6.3 2.7 1.5 6.5 3.6 10.2 4.5-3.9 62.1 54.7 67.4 87.9 51.7 29.9-14.2 50.1-49.9 67-88.5 3.9.7 11.9 1.6 16.7 1.5.7 0 1.5 1 1 1.9-6 12.4-14.5 28.4-20.9 53.5-4.2 16.5-1.4 32.8 12.3 37.8 10 3.7 26.7.3 44.1-17.1 2.3-2.3 11.1-11.1 15.8-18.2 0 11.5 4.8 23.4 9.2 28.1 9 9.9 22.1 10.5 32.8 5.5 15.9-7.4 26.4-26.7 29.6-31.9.8-1.3 1.3-.4 1.4.6 0 2.9 0 5.1.2 8 1.5 20.5 10 30.2 23 33.4 11.7 2.8 29.1-3 30.8-13-.1-.8-.1-1.4-.2-2zm-292.5-99.2c3.1-2.6 12.4-9 16.5-5.8 3.6 2.8-2.6 15.5-4.5 18.7-8.2 14.4-23.1 22.7-34 23 1.4-8.7 9.6-25.6 22-35.9zm93.7-25.2c-2 7.1-4.4 12.7-7 18.6-10.5-2.1-2.4-13.6 2.7-19.4 3.2-3.6 5.5-3.4 4.3.8zm144.2 60.1c-7.5 13.5-14 22.9-20.7 31.7-4.2 5.5-12.7 16.6-17.9 15.1-6.4-1.9-3.9-21.6-1.7-32.1 2.8-13.6 9.7-34.9 22.3-47.8 7.4-7.6 11.8-1.3 14.9 6.4 2.2 5.5 4.2 12.5 5 18.3.2 1.7-.1 5.2-1.9 8.4z" />
      </g>
    </svg>
  ),
}
