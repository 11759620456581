/* eslint-disable max-len */
const programName = 'Ralph Lauren'
export default {
  programName,
  logo: (

    <svg viewBox="0 0 349.6 25.2">
      <title>{programName}</title>
      <path className="st0" d="M6.3 11.7V1.1h3.3c4 0 5.4 2.6 5.4 5.1 0 3.4-2.1 5.6-6.5 5.6H6.3zM11.1 12.3c5.4-0.4 8.2-2.7 8.2-6 0-3.1-2.1-5.9-8.5-5.9H0v0.8h2.3V24H0v0.8h9V24H6.3V12.4h0.1l9.4 12.3h6v-0.8H20L11.1 12.3z" />
      <path className="st0" d="M35.7 14.5l3.7-10.3h0.1l3.6 10.3H35.7zM42.1 0.3h-2.4L31.2 24H29v0.8h6.8V24h-3.6l3.2-9h7.9l3.1 9h-2.7v0.8h8.2V24h-1.5L42.1 0.3z" />
      <path className="st0" d="M73.3 23.9h-5.5V1.2h3.7V0.4h-10v0.8h2.3V24h-2.3v0.8h18V15h-0.8C76.5 23.5 75.8 23.9 73.3 23.9" />
      <path className="st0" d="M97.5 1.5h3.8c3.9 0 5.5 2.2 5.5 5.6 0 4.3-1.8 5.3-5.8 5.3h-3.5C97.5 12.2 97.5 1.5 97.5 1.5L97.5 1.5zM101 24h-3.5V12.9h4.5c6.1 0 9-2.5 9-6.1s-3.2-6.4-9-6.4H91.3v0.8h2.3v22.7h-2.3v0.8h9.6V24z" />
      <polygon className="st0" points="134.8 23.9 134.8 24.8 143.5 24.8 143.5 23.9 141.2 23.9 141.2 1.4 143.5 1.4 143.5 0.4 134.8 0.4 134.8 1.4 137.1 1.4 137.1 11.8 127.4 11.8 127.4 1.4 129.8 1.4 129.8 0.4 121.1 0.4 121.1 1.4 123.3 1.4 123.3 23.9 121.1 23.9 121.1 24.8 129.8 24.8 129.8 23.9 127.4 23.9 127.4 12.5 137.1 12.5 137.1 23.9 " />
      <path className="st0" d="M195.2 14.9h-0.8c-2.3 8.5-3 9-5.4 9h-5.5V1.2h3.7V0.4h-10v0.8h2.3V24h-2.3v0.8h18V14.9" />
      <path className="st0" d="M210.8 14.5l3.7-10.3h0.1l3.6 10.3H210.8zM217.3 0.3h-2.4L206.3 24h-2.1v0.8h6.8V24h-3.6l3.2-9h7.9l3.1 9h-2.7v0.8h8.2V24h-1.4L217.3 0.3z" />
      <path className="st0" d="M250.1 1.1h3.3v17.4c0 3.7-2.5 5.9-6.1 5.9 -2.4 0-4.9-1.4-4.9-5.7V1.1h2.6V0.1h-8.9v0.8h2.3v16.9c0 4.8 2.7 7.2 8.8 7.2 4.4 0 7.3-2.6 7.3-6.6V1.1h2.3V0.1h-6.7V1.1z" />
      <path className="st0" d="M273.3 11.7V1.1h3.3c4 0 5.4 2.6 5.4 5.1 0 3.4-2.1 5.6-6.5 5.6H273.3zM278.2 12.3c5.4-0.4 8.2-2.7 8.2-6 0-3.1-2.1-5.9-8.5-5.9h-10.8v0.8h2.3V24h-2.3v0.8h9V24h-2.9V12.4h0.1l9.4 12.3h6v-0.8H287L278.2 12.3z" />
      <path className="st0" d="M310.4 23.9h-6.5V12.4h2.9c1.9 0 2.7 1.1 3.5 4.8h0.8V6.7h-0.8c-0.7 3.8-1.7 4.8-3.5 4.8h-2.9V1.1h7.4c1.8 0 2.4 0.5 4.4 7h0.8V0.4h-18.9v0.8h2.3V24h-2.3v0.8h19.3v-9.1H316C313.8 22.9 313 23.9 310.4 23.9" />
      <polygon className="st0" points="343.2 0.4 343.2 1.4 346.2 1.4 346.2 18 346.1 18 335.1 0.4 328 0.4 328 1.4 330.5 1.4 330.5 23.9 328 23.9 328 24.8 334.6 24.8 334.6 23.9 331.5 23.9 331.5 2 346 24.8 347.1 24.8 347.1 1.4 349.6 1.4 349.6 0.4 " />
    </svg>

  ),
}
