/* eslint-disable max-len */
const programName = 'J Lindeberg'
export default {
  programName,
  logo: (
    <svg x="0px" y="0px" viewBox="0 0 89.3 9.8">
      <title>{programName}</title>
      <g>
        <path d="M88.7,7.6c-0.3,0.5-1.2,1.8-4,1.8c-1.5,0-4.3-0.7-4.3-4.5c0-1.5,0.4-2.6,1.1-3.4c0.7-0.8,1.8-1.1,3.2-1.1
c3.1,0,3.8,2.2,3.9,2.9h-1.8c-0.1-0.5-0.6-1.3-2.1-1.3c-0.9,0-1.5,0.2-1.9,0.7c-0.4,0.5-0.6,1.2-0.6,2.1v0.4c0,2.3,1.7,2.8,2.6,2.8
c1,0,1.7-0.2,2.1-0.8l0-1.2h-2.4V4.4h4.2v2.5L88.7,7.6C88.8,7.6,88.7,7.6,88.7,7.6C88.7,7.6,88.7,7.6,88.7,7.6z"
        />
        <path d="M73.3,4.5h2.5c0.4,0,0.7-0.1,0.9-0.3c0.2-0.2,0.3-0.5,0.3-0.9c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.5-0.3-0.9-0.3h-2.5V4.5z
 M75.7,6h-2.4v3.3h-1.8V0.5H76c0.9,0,1.6,0.2,2.1,0.7c0.5,0.5,0.7,1.2,0.7,2c0,1.2-0.5,2-1.5,2.5l1.7,3.6h-1.9L75.7,6z"
        />
        <polygon points="62.5,0.5 62.5,9.3 69.2,9.3 69.2,7.7 64.3,7.7 64.3,5.6 68.6,5.6 68.6,4 64.3,4 64.3,2.1 69.1,2.1 69.1,0.5 " />
        <path d="M54.7,7.7h2.7c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.9-0.3h-2.6V7.7z
 M54.7,4h2.5c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.6-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3h-2.6V4z M52.9,0.5
h4.8c0.4,0,0.8,0.1,1.2,0.3c0.4,0.2,0.6,0.4,0.8,0.8c0.2,0.3,0.3,0.7,0.3,1.1c0,0.5-0.1,0.9-0.4,1.3s-0.6,0.6-1,0.7v0.1
c0.5,0.1,0.9,0.3,1.2,0.7c0.3,0.4,0.4,0.8,0.4,1.4c0,0.8-0.2,1.4-0.7,1.8s-1.1,0.6-1.9,0.6h-4.8V0.5z"
        />
        <polygon points="44,0.5 44,9.3 50.7,9.3 50.7,7.7 45.8,7.7 45.8,5.6 50.1,5.6 50.1,4 45.8,4 45.8,2.1 50.6,2.1 50.6,0.5 " />
        <path d="M39.6,2.8c-0.4-0.5-0.9-0.7-1.6-0.7h-1.7v5.6h1.7c0.7,0,1.3-0.2,1.6-0.7c0.4-0.5,0.6-1.1,0.6-1.9V4.7
C40.1,3.9,39.9,3.2,39.6,2.8 M34.4,0.5h3.5c1.3,0,2.3,0.4,3,1.1c0.7,0.7,1,1.8,1,3.3s-0.3,2.6-1,3.3c-0.7,0.7-1.7,1.1-3,1.1h-3.5
V0.5z"
        />
        <path d="M26.8,4.5c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.2-0.2-0.3-0.2-0.4l-0.1,0c0,0.1,0,0.2,0,0.3c0,0.2,0,0.3,0,0.4v4.9h-1.7V0.5
h1.7l3.2,4.8c0.1,0.1,0.2,0.2,0.3,0.4C30,5.9,30,6.1,30.1,6.1l0.1,0c0-0.1,0-0.2,0-0.3s0-0.3,0-0.4V0.5h1.7v8.8h-1.7L26.8,4.5z"
        />
        <rect x="20.2" y="0.5" width="1.8" height="8.8" />
        <polygon points="11.8,0.5 11.8,9.3 18.1,9.3 18.1,7.7 13.6,7.7 13.6,0.5 " />
        <rect x="7.9" y="7.5" width="1.7" height="1.8" />
        <path d="M6.3,0.5v6.3c0,0.9-0.2,1.5-0.7,2C5.1,9.2,4.4,9.4,3.4,9.4c-0.9,0-1.6-0.2-2.1-0.7c-0.5-0.4-0.7-1.1-0.7-2V6.3h1.8v0.5
c0,0.4,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.4,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8V0.5H6.3z"
        />
      </g>
    </svg>
  ),
}
