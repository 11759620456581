/* eslint-disable max-len */
const programName = 'Vagabond'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 549.21 90.85">
      <title>{programName}</title>
      <path className="cls-1" d="M436.49,28a1.32,1.32,0,0,0-.88-.34,1.28,1.28,0,0,0-1.42,1.46V54.54A1.42,1.42,0,0,1,432.81,56H422.4A1.44,1.44,0,0,1,421,54.54V11.36a1.42,1.42,0,0,1,1.42-1.44h10a4.41,4.41,0,0,1,2.75.65l35.07,26.15a1.38,1.38,0,0,0,.91.35c.76,0,1.39-.31,1.39-1.47V11.36a1.41,1.41,0,0,1,1.4-1.44h10.34a1.41,1.41,0,0,1,1.4,1.44V54.54a1.43,1.43,0,0,1-1.4,1.45h-8.65a4.21,4.21,0,0,1-2.72-.67Z" transform="translate(-8.99 -9.9)" />
      <path className="cls-1" d="M391.08,9.92l-21.39,0C352.22,9.9,347,24.25,347,33s5.27,23,22.74,23h21.39c17.46,0,22.73-14.27,22.73-23S408.54,9.92,391.08,9.92m-1.57,34.62H371.25c-11.5,0-12.32-8.57-12.32-11.47,0-2.62.89-11.65,12.6-11.65h17.73c11.67,0,12.57,9,12.57,11.65,0,2.91-.81,11.48-12.32,11.48" transform="translate(-8.99 -9.9)" />
      <path className="cls-1" d="M535.47,9.9H496.34a1.45,1.45,0,0,0-1.41,1.48V54.54A1.42,1.42,0,0,0,496.34,56h39.13c17.47,0,22.73-14.3,22.73-23S552.93,9.9,535.47,9.9m-1.56,34.57H508.84a1.45,1.45,0,0,1-1.4-1.49V22.82a1.42,1.42,0,0,1,1.4-1.45h24.8c11.69,0,12.56,9.06,12.56,11.67,0,2.9-.8,11.44-12.29,11.44" transform="translate(-8.99 -9.9)" />
      <path className="cls-1" d="M197.87,21.37a1.44,1.44,0,0,0,1.42-1.46V11.38a1.47,1.47,0,0,0-1.4-1.48H161.27c-17.46,0-22.73,14.32-22.73,23.05s5.27,23,22.73,23h36.62a1.43,1.43,0,0,0,1.4-1.44V30.16a1.45,1.45,0,0,0-1.42-1.47H170.39A1.43,1.43,0,0,0,169,30.16v5.71a1.43,1.43,0,0,0,1.41,1.47h16c.75,0,1,.23,1,1v5.09c0,.77-.24,1-1,1H162.84c-11.52,0-12.32-8.53-12.32-11.44,0-2.61.9-11.67,12.58-11.67Z" transform="translate(-8.99 -9.9)" />
      <path className="cls-1" d="M335.07,32.45c2.27-1.26,4.66-4.46,4.66-9.67,0-6.53-5.87-12.85-14.23-12.85H283.63a1.42,1.42,0,0,0-1.4,1.44V54.55a1.44,1.44,0,0,0,1.4,1.47h42.71c8.36,0,14.23-6.39,14.23-12.93,0-5.38-2.11-8.47-5.5-10.64M324,44.49H295.34c-.74,0-1-.25-1-1V38.33c0-.76.22-1,1-1H324a3.34,3.34,0,0,1,3.33,3.56A3.42,3.42,0,0,1,324,44.49m0-15.81-28.65,0c-.74,0-1-.24-1-1V22.54c0-.75.21-1,.95-1H324a3.34,3.34,0,0,1,3.33,3.55,3.42,3.42,0,0,1-3.34,3.6" transform="translate(-8.99 -9.9)" />
      <path className="cls-1" d="M32.83,54.5A2.59,2.59,0,0,0,35.32,56H51.08a2.6,2.6,0,0,0,2.5-1.49L77.25,12.06a1.55,1.55,0,0,0,.15-.69A1.42,1.42,0,0,0,76,9.92H65a2.61,2.61,0,0,0-2.5,1.49L45.28,41.86a2.36,2.36,0,0,1-2.08,1.4,2.43,2.43,0,0,1-2.08-1.4L23.9,11.41a2.61,2.61,0,0,0-2.5-1.49h-11A1.43,1.43,0,0,0,9,11.37a1.56,1.56,0,0,0,.17.69Z" transform="translate(-8.99 -9.9)" />
      <path className="cls-1" d="M137.46,53.86,113.79,11.42a2.62,2.62,0,0,0-2.5-1.5H96.68a2.58,2.58,0,0,0-2.49,1.49L70.49,53.86a1.6,1.6,0,0,0-.17.69A1.44,1.44,0,0,0,71.71,56H82.18a2.6,2.6,0,0,0,2.5-1.49l3.19-5.66a2.15,2.15,0,0,1,1.88-1.14h28.44a2.12,2.12,0,0,1,1.87,1.14l3.19,5.66a2.62,2.62,0,0,0,2.5,1.49h10.47a1.44,1.44,0,0,0,1.4-1.47,1.46,1.46,0,0,0-.16-.69M112.19,38.15H95.77a1,1,0,0,1-1-1.08,1.33,1.33,0,0,1,.15-.6l7.12-13c.3-.54.93-1.6,2-1.6s1.7,1.14,2,1.68l7.09,12.9a1.1,1.1,0,0,1,.16.6,1.05,1.05,0,0,1-1,1.08" transform="translate(-8.99 -9.9)" />
      <path className="cls-1" d="M274,53.86,250.29,11.42a2.59,2.59,0,0,0-2.49-1.5H233.19a2.6,2.6,0,0,0-2.5,1.49L207,53.86a1.6,1.6,0,0,0-.17.69A1.44,1.44,0,0,0,208.22,56H218.7a2.61,2.61,0,0,0,2.49-1.49l3.19-5.66a2.13,2.13,0,0,1,1.88-1.14H254.7a2.15,2.15,0,0,1,1.88,1.14l3.19,5.66A2.6,2.6,0,0,0,262.26,56h10.47a1.44,1.44,0,0,0,1.4-1.47,1.46,1.46,0,0,0-.16-.69M248.71,38.15H232.28a1.06,1.06,0,0,1-1.05-1.08,1.34,1.34,0,0,1,.16-.6l7.12-13c.29-.54.94-1.6,2-1.6s1.7,1.14,2,1.68l7.1,12.9a1.19,1.19,0,0,1,.15.6,1.05,1.05,0,0,1-1,1.08" transform="translate(-8.99 -9.9)" />
      <path className="cls-1" d="M187.44,90.43c3.63.89,5.52,2.19,5.52,5,0,3.24-2.53,5.15-6.14,5.15a10.61,10.61,0,0,1-7.15-2.74l1.81-2.15A8,8,0,0,0,186.9,98c1.86,0,3-.86,3-2.18s-.68-1.91-3.87-2.65c-3.65-.88-5.71-2-5.71-5.15,0-3,2.45-5,5.86-5a9.53,9.53,0,0,1,6.22,2.16l-1.61,2.28a7.82,7.82,0,0,0-4.66-1.76c-1.76,0-2.8.9-2.8,2,0,1.35.79,1.94,4.07,2.72" transform="translate(-8.99 -9.9)" />
      <polygon className="cls-1" points="193.66 83.32 193.66 90.55 190.64 90.55 190.64 73.4 193.66 73.4 193.66 80.52 201.84 80.52 201.84 73.4 204.87 73.4 204.87 90.55 201.84 90.55 201.84 83.32 193.66 83.32" />
      <path className="cls-1" d="M229.32,85.77a5.8,5.8,0,0,0-5.78,6.07A5.89,5.89,0,0,0,229.38,98a5.8,5.8,0,0,0,5.78-6.07,5.88,5.88,0,0,0-5.84-6.13m0,15a8.88,8.88,0,1,1,9-8.91,8.81,8.81,0,0,1-9,8.91" transform="translate(-8.99 -9.9)" />
      <polygon className="cls-1" points="248.57 76.09 238.87 76.09 238.87 80.55 247.47 80.55 247.47 83.25 238.87 83.25 238.87 87.85 248.69 87.85 248.69 90.55 235.85 90.55 235.85 73.39 248.57 73.39 248.57 76.09" />
      <polygon className="cls-1" points="265 86.33 264.91 86.33 259.52 78.3 259.52 90.55 256.57 90.55 256.57 73.39 259.78 73.39 265 81.51 270.22 73.39 273.43 73.39 273.43 90.55 270.42 90.55 270.42 78.24 265 86.33" />
      <path className="cls-1" d="M297.41,86.77l-2.94,6.86h5.9Zm9,13.68h-3.19l-1.74-4.14h-8.11l-1.77,4.14h-3.08l7.55-17.28h2.79Z" transform="translate(-8.99 -9.9)" />
      <polygon className="cls-1" points="318.69 90.55 315.04 90.55 309.19 82.83 306.51 85.58 306.51 90.55 303.5 90.55 303.5 73.39 306.51 73.39 306.51 82.02 314.67 73.39 318.38 73.39 311.24 80.77 318.69 90.55" />
      <polygon className="cls-1" points="337.75 76.09 328.05 76.09 328.05 80.55 336.65 80.55 336.65 83.25 328.05 83.25 328.05 87.85 337.88 87.85 337.88 90.55 325.04 90.55 325.04 73.39 337.75 73.39 337.75 76.09" />
      <path className="cls-1" d="M361.47,86h-4.39V91.8h4.41c2.16,0,3.53-1.13,3.53-2.9S363.7,86,361.47,86m3.6,14.41-4.21-6h-3.78v6h-3V83.29h7.64c4,0,6.37,2.09,6.37,5.45A5.13,5.13,0,0,1,364,94l4.64,6.5Z" transform="translate(-8.99 -9.9)" />
      <path className="cls-1" d="M381.37,90.5c3.63.88,5.51,2.18,5.51,5,0,3.23-2.53,5.14-6.13,5.14a10.53,10.53,0,0,1-7.16-2.75l1.82-2.15A7.9,7.9,0,0,0,380.83,98c1.86,0,3-.86,3-2.18s-.68-1.91-3.86-2.64c-3.66-.88-5.71-2-5.71-5.15,0-3,2.44-5,5.85-5a9.53,9.53,0,0,1,6.23,2.16l-1.63,2.28a7.76,7.76,0,0,0-4.65-1.77c-1.77,0-2.79.91-2.79,2.06,0,1.36.78,1.94,4.07,2.73" transform="translate(-8.99 -9.9)" />
    </svg>
  ),
}
