/* eslint-disable max-len */
const programName = 'Rocket Padel'
export default {
  programName,
  logo: (
    <svg x="0px" y="0px" viewBox="0 0 231.6 87.7">
      <title>{programName}</title>
      <g>
        <path d="M35.9,20.9c-1.7,2.3-4,4-7,5.2l5.9,10.2H22.5l-5-8.8h-2.9l-1.8,8.8H1.1l7-35h15.7c4.6,0,8.2,1,10.7,3
                    c2.6,2,3.8,4.8,3.8,8.5C38.4,15.9,37.5,18.6,35.9,20.9z M22,10.4h-4l-1.7,8.2h4.9c1.6,0,2.9-0.4,3.8-1.2c0.9-0.8,1.4-2,1.4-3.4
                    C26.4,11.6,24.9,10.4,22,10.4z"
        />
        <path d="M48.7,35.1c-2.7-1.3-4.8-3.2-6.2-5.5s-2.2-5.1-2.2-8.2c0-4.1,0.9-7.7,2.8-10.9s4.5-5.7,7.9-7.4c3.4-1.8,7.2-2.7,11.4-2.7
                    c3.6,0,6.7,0.7,9.4,2c2.7,1.3,4.8,3.2,6.2,5.5s2.2,5.1,2.2,8.2c0,4.1-0.9,7.7-2.8,10.9s-4.5,5.7-7.9,7.4c-3.4,1.8-7.2,2.7-11.4,2.7
                    C54.5,37.1,51.4,36.4,48.7,35.1z M63.3,26.1c1.4-1,2.4-2.3,3.2-3.9s1.1-3.4,1.1-5.4c0-2.1-0.6-3.7-1.8-5c-1.2-1.2-2.9-1.9-5-1.9
                    c-1.9,0-3.5,0.5-4.9,1.5s-2.4,2.3-3.2,3.9c-0.7,1.6-1.1,3.4-1.1,5.4c0,2.1,0.6,3.7,1.8,5c1.2,1.2,2.9,1.9,5,1.9
                    C60.3,27.5,61.9,27.1,63.3,26.1z"
        />
        <path d="M91.3,35.1c-2.7-1.3-4.8-3.2-6.3-5.5c-1.5-2.4-2.2-5.1-2.2-8.2c0-4.1,0.9-7.7,2.8-10.9c1.9-3.2,4.5-5.6,7.9-7.4
                    s7.2-2.7,11.4-2.7c3.4,0,6.4,0.6,9,1.8s4.5,2.9,5.8,5.2l-8.5,6.7c-1.6-2.7-3.9-4.1-7-4.1c-1.8,0-3.5,0.5-4.9,1.4s-2.6,2.2-3.4,3.8
                    s-1.2,3.5-1.2,5.5c0,2.1,0.7,3.7,2,5s3.1,1.9,5.2,1.9c3,0,5.6-1.4,7.8-4.1l7,6.7c-2,2.5-4.3,4.3-6.9,5.4c-2.6,1.1-5.6,1.6-9,1.6
                    C97.2,37.1,94,36.4,91.3,35.1z"
        />
        <path d="M144.2,17.2l11.4,19.1h-13.2l-7.1-11.4L132,28l-1.7,8.4h-11.6l7-35h11.6l-2.5,12.4l13.3-12.4h13.6L144.2,17.2z" />
        <path d="M176,10.2l-0.8,4.1h14.9l-1.7,8.5h-14.9l-0.9,4.6h17.6l-1.8,8.9h-29.1l7-35h28.5l-1.8,8.9H176z" />
        <path d="M207.1,10.4h-10.3l1.9-9.2H231l-1.9,9.2h-10.3l-5.2,25.9h-11.8L207.1,10.4z" />
        <path d="M34.5,55.8c2.6,2,3.9,4.9,3.9,8.6c0,2.9-0.7,5.5-2.2,7.8c-1.5,2.2-3.5,4-6.2,5.2s-5.8,1.9-9.3,1.9h-6.1l-1.7,8.6H1.1l7-35
                    h15.6C28.3,52.7,32,53.8,34.5,55.8z M25.1,68.8c0.9-0.8,1.4-2,1.4-3.4c0-2.4-1.5-3.6-4.4-3.6h-4l-1.7,8.3h4.9
                    C22.9,70.1,24.2,69.7,25.1,68.8z"
        />
        <path d="M60.8,81.7H47.5L44,87.8H31.5l22.3-35h11.6l8.3,35H61.9L60.8,81.7z M59.2,73.2l-1.7-9l-5.2,9H59.2z" />
        <path d="M83.4,52.7h15.8c3.7,0,6.9,0.6,9.5,1.8c2.7,1.2,4.7,2.9,6.2,5.2s2.2,4.9,2.2,7.9c0,4-0.9,7.6-2.8,10.6
                    c-1.9,3.1-4.5,5.4-7.8,7.1s-7.1,2.5-11.3,2.5H76.4L83.4,52.7z M95.5,78.6c3,0,5.4-1,7.1-2.9c1.7-1.9,2.5-4.4,2.5-7.4
                    c0-2-0.6-3.6-1.9-4.7c-1.2-1.1-3.1-1.7-5.4-1.7h-4.5L90,78.5h5.5V78.6z"
        />
        <path d="M134.5,61.7l-0.8,4.1h14.8l-1.7,8.5h-14.9l-0.9,4.6h17.6l-1.8,8.9h-29.1l7-35h28.5l-1.8,8.9H134.5z" />
        <path d="M159.8,52.7h11.8l-5.2,25.9h15.8l-1.9,9.2h-27.6L159.8,52.7z" />
      </g>
    </svg>
  ),
}
