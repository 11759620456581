/* eslint-disable max-len */
const programName = 'Name It'
export default {
  programName,
  logo: (
    <svg x="0px" y="0px" viewBox="0 0 338.9 49">
      <title>{programName}</title>
      <g>
        <path
          className="st0"
          d="M8.8,13.7c2-1.9,4.8-4.4,10.2-4.4c5.5,0,10.5,2.6,13.7,6.6c2.4,3,3.7,6.9,3.7,13.4v18.4h-8.7V28.9
                                c0-4.8-0.9-7-2.2-8.7c-1.8-1.8-3.6-2.6-6.7-2.6c-5,0-7.5,1.9-9.7,4.6v25.5H0V10.1h8.8V13.7z"
        />
        <path
          className="st0"
          d="M87.9,23.3c-2.3-3-5.8-5.4-10.4-5.4c-7.3,0-10.6,4.8-10.6,11.3c0,6.7,3.8,11.3,11,11.3c3.8,0,7.4-1.9,10-5.4
                                V23.3z M88.2,44.7c-3,2.7-6.8,4.2-11.2,4.2c-3.4,0-6.9-1-9.4-2.5c-5.7-3.4-9.6-9.6-9.6-17.1c0-7.5,4-13.8,9.7-17.2
                                c2.6-1.5,5.7-2.4,9.2-2.4c4.2,0,8,1.3,11.4,4.2v-3.4h8.5v37.6h-8.5V44.7z"
        />
        <path
          className="st0"
          d="M130.8,10.1v3.6c1.5-1.7,4.4-4.4,9.4-4.4c5.4,0,10,2.5,13.1,6.2c3.4-3.4,7.4-6.2,14.1-6.2
                                c5.3,0,10.1,2.4,13.1,6.3c2.3,2.9,3.4,6.6,3.4,12.9v19.2h-8.6V27.3c0-3.8-0.1-4.5-1.4-6.1c-1.7-2-3.8-3.3-6.6-3.3
                                c-5.4,0-8.1,2.1-10.5,4.6c0.4,1.8,0.6,3.3,0.6,5.9v19.2h-8.6V27.3c0-3.8-0.8-5.6-2.1-7.2c-1.7-2-4-2.6-6.8-2.6
                                c-4.6,0-6.8,2.1-8.9,4.6v25.5h-8.9V10.1H130.8z"
        />
        <rect x="281" y="10.1" className="st0" width="8.9" height="37.6" />
        <path
          className="st0"
          d="M338.2,47.8c-1.4,0.5-4.5,0.8-6.6,0.8c-4.7,0-8.2-1.7-10.6-4.4c-2.2-2.5-3-5.9-3-10V18.4h-10v-8.3h10v-10h9.1
                                v10h12v8.3h-12v16.2c0,2,0.7,3,1.3,3.8c0.5,0.6,1.5,1.7,3.6,1.9c1.1,0.1,3.8-0.2,4.9-0.5L338.2,47.8z"
        />
        <path
          className="st0"
          d="M214.3,24.5c0.5-3.4,4.6-6.9,8.7-6.9c4.1,0,7.8,3.6,8.1,6.9H214.3z M234.3,36.3c-1.8,1.9-5.5,4.7-9.7,4.7
                                c-5.3,0-9.6-2.2-10.6-9.5h26.3c0.2-0.8,0.2-1.5,0.2-2.5c0-10.3-7.3-19-17.6-19c-11,0-18.1,9-18.1,19.4c0,11.7,8.4,19.3,19.5,19.3
                                c5.8,0,11.7-2.4,15.3-6.7L234.3,36.3z"
        />
      </g>
    </svg>

  ),
}
