/* eslint-disable max-len */
const programName = 'Maria Nila'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 75 75">
      <title>{programName}</title>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Logo-2-black" transform="translate(-36.000000, -2.000000)" fill="#231F20" fillRule="nonzero">
          <g id="Logo" transform="translate(-2.000000, 2.000000)">
            <g id="logoAsset-4" transform="translate(38.000000, 0.000000)">
              <path d="M12.3270349,25.9692982 C13.1972312,26.5036721 14.2870165,26.5216517 15.1741681,26.0162711 C16.0613197,25.5108905 16.6064471,24.5615556 16.5983482,23.5360737 C16.5902492,22.5105918 16.0301963,21.5700878 15.1351744,21.0789474 C13.7921512,20.2982456 11.0232558,20.1491228 10.247093,21.5175439 C9.47093023,22.8859649 10.9840116,25.2061404 12.3270349,25.9868421" id="Path" />
              <path d="M10.2514535,32.9385965 C10.4872844,33.0035363 10.7307837,33.035996 10.9752907,33.0351063 C12.2457809,33.0351063 13.3580406,32.1771877 13.6875,30.9429825 C13.8805556,30.2200169 13.7799228,29.4495093 13.4077745,28.8012285 C13.0356263,28.1529477 12.4225001,27.6800901 11.7034884,27.4868421 C11.3629758,27.4000392 11.0125759,27.3587481 10.6613372,27.3640351 C9.12645349,27.3640351 7.40406977,27.9824561 7.09011628,29.1666667 C6.90261628,29.8640351 7.22965116,30.6973684 8.00145349,31.5087719 C8.61766019,32.1709411 9.39230462,32.6632103 10.2514535,32.9385965 M7.72238372,29.3377193 C7.94040698,28.5263158 9.41860465,28.0219298 10.6613372,28.0219298 C10.9551199,28.0180572 11.2481906,28.0519575 11.5334302,28.122807 C12.6727017,28.4313195 13.3518685,29.6057697 13.0552326,30.754386 C12.7346468,31.8895634 11.5784519,32.5660163 10.4389535,32.2850877 C9.69645056,32.0396682 9.02753792,31.6097243 8.49418605,31.0350877 C7.89244186,30.4035088 7.62209302,29.7807018 7.73982558,29.3201754" id="Shape" />
              <path d="M18.1133721,20.6140597 C19.2521407,20.6188062 20.2806601,19.9302971 20.7159333,18.8718345 C21.1512065,17.8133718 20.9067587,16.595238 20.0973837,15.7894737 C19.1657734,14.9491044 17.9726537,14.4606928 16.7223837,14.4078947 C16.1879863,14.372677 15.6618478,14.5543479 15.2616279,14.9122807 C14.184593,15.995614 15.0218023,18.6666667 16.1337209,19.7719298 C16.6542315,20.3088926 17.3677752,20.6124203 18.1133721,20.6140597 M15.7325581,15.3947368 C16.0106822,15.1669029 16.3645504,15.0540064 16.7223837,15.0789474 C17.7930623,15.1270202 18.8156396,15.5399898 19.622093,16.25 C20.4649086,17.0978309 20.4649086,18.4723446 19.622093,19.3201754 C18.76878,20.1422469 17.4230805,20.1422469 16.5697674,19.3201754 C15.6976744,18.4429825 14.9389535,16.1666667 15.6976744,15.372807" id="Shape" />
              <path d="M12.8459302,37.3903509 C12.8447747,36.6400671 12.5473497,35.9209748 12.0190857,35.3912666 C11.4908217,34.8615584 10.7749914,34.5646253 10.0290698,34.5657861 C8.47674419,34.5657861 6.00436047,35.8333333 6.00436047,37.3903509 C6.00436047,38.9473684 8.47674419,40.2149123 10.0290698,40.2149123 C11.5799624,40.2149123 12.8372093,38.9503131 12.8372093,37.3903509" id="Path" />
              <path d="M25.2296512,15.8157895 C25.874137,15.4415415 26.3442672,14.8249318 26.536472,14.1017999 C26.7286767,13.3786681 26.6271868,12.6083425 26.2543605,11.9605263 C25.4825581,10.6096491 23.1540698,9.0877193 21.8023256,9.86403509 C20.4505814,10.6403509 20.6075581,13.4342105 21.3662791,14.7850877 C21.7391908,15.4336415 22.3529951,15.9066028 23.0726318,16.0999024 C23.7922685,16.2932019 24.558774,16.1910016 25.2034884,15.8157895" id="Path" />
              <path d="M16.1642442,49.8464912 C15.3860239,48.4984867 13.6714118,48.0359698 12.3270349,48.8114035 C10.9840116,49.5921053 9.47093023,51.9342105 10.247093,53.2807018 C11.0232558,54.627193 13.7921512,54.4868421 15.1351744,53.7192982 C16.4773964,52.9381103 16.9377361,51.2115512 16.1642442,49.8596491" id="Path" />
              <path d="M11.7034884,47.2982456 C13.0644743,46.9309433 13.9440794,45.6059744 13.7597962,44.2007831 C13.5755129,42.7955919 12.3844144,41.7453701 10.9752907,41.745614 C10.7309302,41.7469248 10.4877176,41.7793463 10.2514535,41.8421053 C8.75145349,42.245614 6.69767442,44.1403509 7.09011628,45.6184211 C7.40406977,46.7982456 9.12645349,47.4210526 10.6613372,47.4210526 C11.0125759,47.4263396 11.3629758,47.3850485 11.7034884,47.2982456 M7.72238372,45.4473684 C7.44331395,44.3903509 9.22674419,42.8157895 10.4215116,42.4780702 C11.5539008,42.2124243 12.6915115,42.8987013 12.9917757,44.028612 C13.29204,45.1585227 12.6465695,46.3241966 11.5334302,46.6622807 C11.2479767,46.7315122 10.9549267,46.7639362 10.6613372,46.7587719 C9.41860465,46.7587719 7.94040698,46.2587719 7.72238372,45.4429825" id="Shape" />
              <path d="M59.0581395,24.9342105 C59.4303222,25.5835087 60.0438041,26.0574001 60.7635035,26.2515391 C61.4832028,26.4456781 62.2501058,26.3441457 62.8953488,25.9692982 C64.2383721,25.1885965 65.7514535,22.8508772 64.9752907,21.5 C64.1991279,20.1491228 61.4302326,20.2982456 60.0872093,21.0614035 C58.7455377,21.8408764 58.2850351,23.5660837 59.0581395,24.9166667" id="Path" />
              <path d="M55.1206395,19.7763158 C56.2193918,20.875273 57.994271,20.875273 59.0930233,19.7763158 C59.69985,19.1059769 60.1261481,18.2906699 60.3313953,17.4078947 C60.5973837,16.3114035 60.4665698,15.4298246 59.9563953,14.9166667 C59.5572039,14.5595427 59.0333297,14.3765344 58.5,14.4078947 C57.2480565,14.4589592 56.052999,14.9475328 55.1206395,15.7894737 C54.0309591,16.8941383 54.0309591,18.6760372 55.1206395,19.7807018 M55.5872093,16.2719298 C56.3949396,15.5537693 57.4230052,15.1358038 58.5,15.0877193 C58.8583003,15.0648884 59.211919,15.1792722 59.4898256,15.4078947 C59.8255814,15.745614 59.8997093,16.4210526 59.6947674,17.2719298 C59.5170857,18.0377484 59.1512732,18.746508 58.630814,19.3333333 C57.7766068,20.1531625 56.4326955,20.1531625 55.5784884,19.3333333 C55.1716018,18.9273395 54.9427398,18.374726 54.9427398,17.7982456 C54.9427398,17.2217652 55.1716018,16.6691517 55.5784884,16.2631579" id="Shape" />
              <path d="M37.6090116,14.9561399 C28.5858147,14.9561399 20.4511818,20.4236037 16.9985613,28.8088711 C13.5459408,37.1941384 15.4553298,46.8457228 21.8363172,53.2627713 C28.2173046,59.6798199 37.8131482,61.5984882 46.1489889,58.1240378 C54.4848296,54.6495874 59.9189149,46.4663149 59.9171516,37.3903509 C59.9171516,31.4396683 57.566697,25.7327743 53.3829839,21.5254176 C49.1992708,17.3180609 43.5250971,14.954977 37.6090116,14.9561399 L37.6090116,14.9561399 Z M51.2703488,24.7412281 L53.9738372,39.4736842 L44.6991279,20.1140351 C47.1989783,21.1556442 49.4418699,22.7349995 51.2703488,24.7412281 L51.2703488,24.7412281 Z M30.9593023,19.9561404 L27.619186,29.8991228 L24.6976744,23.9649123 C26.4947631,22.2175989 28.6246856,20.8539961 30.9593023,19.9561404 L30.9593023,19.9561404 Z M19.0247093,37.3903509 C19.0222609,33.7676261 20.072593,30.2231774 22.0465116,27.1929825 L19.4302326,41.3333333 C19.1468745,40.0366963 19.000712,38.713541 18.994186,37.3859649 L19.0247093,37.3903509 Z M20.3023256,44.3070175 L23.7906977,25.4473684 L27.0697674,32.1096491 C27.2012265,32.3696983 27.4735768,32.5264832 27.7630814,32.5087719 C28.0654011,32.5035026 28.3313124,32.3064221 28.4258721,32.0175439 L32.0930233,21.0526316 L36.7456395,56.0701754 C29.4569233,55.726733 23.0439385,51.1209578 20.3546512,44.2982456 L20.3023256,44.3070175 Z M37.6090116,51.6666667 L33.3008721,19.2105263 C36.132063,18.5087047 39.0903207,18.5087047 41.9215116,19.2105263 L37.6090116,51.6666667 Z M38.4811047,56.0526316 L43.2165698,20.3377193 L54.7979651,44.495614 C52.0512715,51.208457 45.68614,55.7121705 38.4767442,56.0438596 L38.4811047,56.0526316 Z M55.7747093,41.3333333 L53.1976744,27.1929825 C55.1720589,30.2229597 56.2224334,33.7675517 56.219483,37.3903509 C56.2129508,38.7179269 56.0667883,40.0410822 55.7834302,41.3377193 L55.7747093,41.3333333 Z" id="Shape" />
              <path d="M63.5188953,27.4868421 C62.1516421,27.8470941 61.2647385,29.1746518 61.4497227,30.5840738 C61.6347069,31.9934957 62.8337359,33.0440669 64.247093,33.0350877 C64.4916,33.035996 64.7350994,33.0035363 64.9709302,32.9385965 C66.4709302,32.5350877 68.5247093,30.6447368 68.1322674,29.1666667 C67.818314,27.9824561 66.1002907,27.3640351 64.5610465,27.3640351 C64.2097949,27.3584915 63.8593616,27.3997865 63.5188953,27.4868421 M67.5,29.3333333 C67.7790698,30.3947368 65.9956395,31.9649123 64.8008721,32.3026316 C63.6613736,32.5835602 62.5051787,31.9071073 62.184593,30.7719298 C61.8930025,29.624015 62.5702244,28.4529279 63.7063953,28.1403509 C63.991635,28.0695013 64.2847057,28.0356011 64.5784884,28.0394737 C65.8255814,28.0394737 67.2994186,28.5438596 67.5174419,29.3552632" id="Shape" />
              <path d="M37.6090116,12.4824561 C38.35529,12.4824561 39.0708761,12.1836691 39.5977558,11.6520623 C40.1246355,11.1204556 40.4194688,10.3997618 40.4171647,9.64912281 C40.4171647,8.0877193 39.1613372,5.60087719 37.6090116,5.60087719 C36.056686,5.60087719 34.8052326,8.09649123 34.8052326,9.64912281 C34.8052326,11.2073738 36.0598221,12.4712646 37.6090116,12.4736842" id="Path" />
              <path d="M30.4752907,13.4254571 C30.7197977,13.4263469 30.963297,13.3938871 31.1991279,13.3289474 C32.6950962,12.9242949 33.5840975,11.3790544 33.1875,9.87280702 C32.8386628,8.55701754 31.2776163,6.64034599 29.8255814,6.64034599 C29.693006,6.64000788 29.5609942,6.65771239 29.4331395,6.69298246 C27.9636628,7.0877193 27.3619186,9.8245614 27.7630814,11.3333333 C28.0925408,12.5675386 29.2048005,13.4254571 30.4752907,13.4254571 M29.6031977,7.32894737 C29.6753464,7.30741826 29.7503339,7.29706568 29.8255814,7.29824561 C30.8372093,7.29824561 32.2412791,8.86842105 32.5508721,10.0438596 C32.8478097,11.1911484 32.1709378,12.3649935 31.0334302,12.6754386 C29.8939318,12.9563672 28.7377369,12.2799143 28.4171512,11.1447368 C28.0988372,9.94298246 28.5741279,7.59210526 29.625,7.31140351" id="Shape" />
              <path d="M44.0406977,13.3289474 C44.2765285,13.3938871 44.5200279,13.4263469 44.7645349,13.4254571 C46.0350251,13.4254571 47.1472848,12.5675386 47.4767442,11.3333333 C47.7325264,10.1009159 47.5552983,8.8174111 46.9752907,7.70175439 C46.7351016,7.22218799 46.3143875,6.85901591 45.806686,6.69298246 C45.6773499,6.65768835 45.5438947,6.63998695 45.4098837,6.64034538 C43.9622093,6.64034538 42.4011628,8.57017544 42.0523256,9.87280702 C41.6531549,11.3793993 42.5431499,12.9263672 44.0406977,13.3289474 M42.684593,10.0394737 C42.9985465,8.86842105 44.4026163,7.29824561 45.4098837,7.29824561 C45.4864183,7.29926005 45.562551,7.30956861 45.6366279,7.32894737 C45.9723574,7.45711385 46.2478699,7.7076212 46.4084302,8.03070175 C46.9037335,8.99426851 47.0575474,10.0989217 46.8444767,11.1622807 C46.5157201,12.2925646 45.3495983,12.9517497 44.2187883,12.6465293 C43.0879783,12.3413089 42.4066109,11.1834622 42.684593,10.0394737" id="Shape" />
              <path d="M49.9927326,15.8201754 C51.3367747,16.5981907 53.0532957,16.1351589 53.8299419,14.7850877 C54.6061047,13.4342105 54.7543605,10.6447368 53.3938953,9.86842105 C52.0334302,9.09210526 49.7223837,10.6096491 48.9505814,11.9605263 C48.5775507,12.6090109 48.4759446,13.379999 48.6681203,14.103844 C48.860296,14.8276891 49.3305076,15.4450829 49.9752907,15.8201754" id="Path" />
              <path d="M20.1017442,55.004386 C18.9890125,53.940057 17.2420922,53.940057 16.1293605,55.004386 C15.5240977,55.6775238 15.0980767,56.4938096 14.8909884,57.377193 C14.625,58.4692982 14.7601744,59.3552632 15.2659884,59.8640351 C15.6632323,60.2248897 16.1881024,60.4098266 16.7223837,60.377193 C17.9733101,60.3251707 19.1675998,59.838461 20.1017442,59 C21.1919332,57.8938362 21.1919332,56.1105498 20.1017442,55.004386 M19.622093,58.5350877 C18.8143627,59.2532482 17.7862971,59.6712138 16.7093023,59.7192982 C16.3496383,59.7423767 15.9945704,59.6280279 15.7151163,59.3991228 C15.3837209,59.0614035 15.309593,58.3815789 15.5145349,57.5350877 C15.6929168,56.7695296 16.0586438,56.0609356 16.5784884,55.4736842 C17.4213648,54.6258787 18.7879375,54.6258787 19.630814,55.4736842 C20.4736904,56.3214897 20.4736904,57.6960541 19.630814,58.5438596" id="Shape" />
              <path d="M65.184593,34.5701754 C64.1557365,34.529372 63.187188,35.05821 62.6606913,35.9482534 C62.1341946,36.8382967 62.1341946,37.946791 62.6606913,38.8368344 C63.187188,39.7268777 64.1557365,40.2557158 65.184593,40.2149123 C66.7369186,40.2149123 69.2093023,38.9517544 69.2093023,37.3903509 C69.2093023,35.8289474 66.7369186,34.5701754 65.184593,34.5701754" id="Path" />
              <path d="M44.747093,61.3596485 C43.7456219,61.3589683 42.8198282,61.8956249 42.318314,62.7675439 C41.9445743,63.4162809 41.8426081,64.1878665 42.0348837,64.9122807 C42.3837209,66.2280702 43.9447674,68.1447532 45.3924419,68.1447532 C45.5264698,68.1453685 45.6599606,68.1276624 45.7892442,68.0921053 C47.2587209,67.6973684 47.8604651,64.9605263 47.4593023,63.4517544 C47.1298429,62.2175491 46.0175832,61.3596485 44.747093,61.3596485 M45.619186,67.4561404 C44.6206395,67.7061404 43.002907,66.004386 42.6671512,64.7412281 C42.3755606,63.5933132 43.0527826,62.4222261 44.1889535,62.1096491 C44.3710709,62.0609223 44.5586443,62.0358645 44.747093,62.0350877 C45.7215876,62.0353435 46.5745343,62.693633 46.8270349,63.6403509 C47.1453488,64.8421053 46.6700581,67.1885965 45.619186,67.4736842" id="Shape" />
              <path d="M49.9927326,58.9649123 C49.3479494,59.3400048 48.8777379,59.9573986 48.6855622,60.6812437 C48.4933865,61.4050888 48.5949926,62.1760768 48.9680233,62.8245614 C49.744186,64.1710526 52.068314,65.6973684 53.4113372,64.9166667 C54.7543605,64.1359649 54.6061047,61.3508772 53.8473837,60 C53.4752011,59.3507018 52.8617191,58.8768104 52.1420198,58.6826714 C51.4223204,58.4885324 50.6554174,58.5900648 50.0101744,58.9649123" id="Path" />
              <path d="M55.125,55.0087719 C54.5972343,55.5372005 54.3005352,56.2553595 54.3005352,57.004386 C54.3005352,57.7534124 54.5972343,58.4715715 55.125,59 C56.0570298,59.8390909 57.2502013,60.3259731 58.5,60.377193 C59.0333297,60.4085533 59.5572039,60.225545 59.9563953,59.8684211 C61.0290698,58.7807018 60.1918605,56.1096491 59.0843023,55.0087719 C57.9757161,53.9382757 56.2248653,53.9382757 55.1162791,55.0087719 M59.4767442,59.3947368 C59.199262,59.6241428 58.8453281,59.7386286 58.4869186,59.7149123 C57.4100757,59.6661263 56.3822336,59.2482516 55.5741279,58.5307018 C54.7312515,57.6828962 54.7312516,56.3083319 55.574128,55.4605264 C56.4170044,54.6127209 57.783577,54.6127208 58.6264535,55.4605263 C59.4985465,56.3377193 60.2572674,58.6140351 59.4985465,59.4078947" id="Shape" />
              <path d="M64.9709302,41.8464912 C64.735291,41.7801228 64.4917953,41.7461882 64.247093,41.745614 C62.9758735,41.747608 61.86402,42.6070517 61.5348837,43.8421053 C61.3418282,44.5650708 61.4424609,45.3355784 61.8146092,45.9838592 C62.1867574,46.63214 62.7998836,47.1049976 63.5188953,47.2982456 C63.8593616,47.3853012 64.2097949,47.4265962 64.5610465,47.4210526 C66.1002907,47.4210526 67.818314,46.7982456 68.1322674,45.6184211 C68.3197674,44.9210526 67.9927326,44.0877193 67.2209302,43.2763158 C66.6071377,42.6112163 65.8316733,42.1184261 64.9709302,41.8464912 M67.5,45.4473684 C67.2819767,46.2587719 65.8081395,46.7631579 64.5610465,46.7631579 C64.2674701,46.768039 63.9744555,46.7356189 63.6889535,46.6666667 C62.5603759,46.3442062 61.8878558,45.1812499 62.1671512,44.0350877 C62.4213001,43.0901101 63.2738402,42.4339321 64.247093,42.4342105 C64.4342512,42.4327826 64.6206762,42.4578831 64.8008721,42.5087719 C65.5439017,42.7508474 66.2132592,43.1795676 66.7456395,43.754386 C67.3473837,44.3859649 67.6177326,45.0131579 67.5,45.4736842" id="Shape" />
              <path d="M62.8953488,48.8114035 C62.0260718,48.3068442 60.9552125,48.307078 60.0861533,48.8120168 C59.2170941,49.3169555 58.6818657,50.2498871 58.6820835,51.2593852 C58.6823014,52.2688832 59.2179323,53.2015811 60.0872093,53.7061404 C61.4302326,54.4824561 64.1991279,54.6359649 64.9752907,53.2675439 C65.7514535,51.8991228 64.2340116,49.5745614 62.8953488,48.7938596" id="Path" />
              <path d="M25.2296512,58.9649123 C24.5844081,58.5900648 23.8175051,58.4885324 23.0978058,58.6826714 C22.3781065,58.8768104 21.7646245,59.3507018 21.3924419,60 C20.6162791,61.3508772 20.4680233,64.1359649 21.8284884,64.9166667 C23.1889535,65.6973684 25.5,64.1710526 26.2718023,62.8245614 C26.644833,62.1760768 26.7464391,61.4050888 26.5542634,60.6812437 C26.3620877,59.9573986 25.8918761,59.3400048 25.247093,58.9649123" id="Path" />
              <path d="M37.6133721,-1.42108547e-14 C22.5757541,-0.00177394681 9.01793806,9.10851096 3.26247605,23.0823644 C-2.49298595,37.0562179 0.687471102,53.1414244 11.3206729,63.8368086 C21.9538746,74.5321928 37.9455624,77.731249 51.8381726,71.9421293 C65.7307827,66.1530097 74.7881008,52.5159082 74.7863372,37.3903509 C74.7839295,16.7412335 58.1424365,0.00242175777 37.6133721,-1.42108547e-14 M37.6133721,71.0526319 C19.1368156,71.0502089 4.15988486,55.9837291 4.15988486,37.3991224 C4.15988486,18.8145157 19.1368169,3.74803658 37.6133734,3.74561461 C56.0899299,3.74319263 71.0707652,18.8057448 71.0755825,37.3903509 C71.077895,46.3180961 67.5533045,54.8809163 61.2775468,61.1941969 C55.0017891,67.5074776 46.489212,71.0537954 37.6133721,71.0526319" id="Shape" />
              <path d="M31.1991279,61.4561404 C30.963297,61.3912006 30.7197977,61.3587408 30.4752907,61.3596306 C29.2048005,61.3596306 28.0925408,62.2175491 27.7630814,63.4517544 C27.5710769,64.3382453 27.6086128,65.2594786 27.872093,66.127193 C28.181686,67.2061404 28.744186,67.8815789 29.4331395,68.0921053 C29.5626751,68.1259196 29.696127,68.1421455 29.8299419,68.1403509 C31.2776163,68.1403509 32.8386628,66.2149123 33.1875,64.9122807 C33.5866707,63.4056884 32.6966757,61.8587205 31.1991279,61.4561404 M32.5508721,64.7412281 C32.2151163,66.004386 30.6061047,67.7236842 29.6031977,67.4561404 C29.1671512,67.3289474 28.7311047,66.7807018 28.5,65.9385965 C28.2729524,65.1876465 28.2369688,64.3913789 28.3953488,63.622807 C28.6478494,62.6760891 29.5007961,62.0177997 30.4752907,62.0175439 C30.6624672,62.0158209 30.8489334,62.0409269 31.0290698,62.0921053 C32.1683412,62.4006178 32.847508,63.5750679 32.5508721,64.7236842" id="Shape" />
              <path d="M37.6133721,62.3026316 C36.0624795,62.3026316 34.8052326,63.5672308 34.8052326,65.127193 C34.8052326,66.6842105 36.0610465,69.1754386 37.6133721,69.1754386 C39.1656977,69.1754386 40.4171546,66.6842105 40.4171546,65.127193 C40.4183088,64.3784763 40.1234226,63.6600264 39.5974865,63.1301919 C39.0715504,62.6003574 38.3577367,62.3026316 37.6133721,62.3026316" id="Path" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
}
