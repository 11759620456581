/* eslint-disable max-len */
const programName = 'Träningsmaskiner'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 412 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>{programName}</title>
      <path d="M127.54 119.74H105.86V106.89H115.75C118 80.93 119.81 59.85 121.01 45.92L100.21 68.34L68.5 22.56L36.79 68.34L16 45.93L21.25 106.89H31.14V119.74H9.47L0 9.79001L35.34 47.88L68.51 0L101.68 47.88L137 9.81L135.41 28.41C135.38 28.75 132.5 62.59 128.05 113.87L127.54 119.74Z" fill="#DC164D" />
      <path d="M185.67 18.47V20.2C185.67 22.24 184.57 23.34 182.53 23.34H176.48V67.15C176.48 69.19 175.38 70.29 173.34 70.29H170.51C168.47 70.29 167.37 69.19 167.37 67.15V23.34H161.32C159.28 23.34 158.18 22.24 158.18 20.2V18.47C158.18 16.43 159.28 15.33 161.32 15.33H182.52C184.57 15.33 185.67 16.42 185.67 18.47Z" fill="#030405" />
      <path d="M216.21 70.28H213.46C211.65 70.28 210.48 69.5 209.93 67.69L203.81 48.38H199.18V67.14C199.18 69.18 198.08 70.28 196.04 70.28H193.21C191.17 70.28 190.07 69.18 190.07 67.14V18.46C190.07 16.42 191.17 15.32 193.21 15.32H204.28C212.6 15.32 218.1 18.62 218.1 26.94V36.75C218.1 42.01 215.9 45.31 212.13 46.96L218.8 66.67C219.51 68.79 218.49 70.28 216.21 70.28ZM199.17 40.53H203.96C206.94 40.53 208.98 39.43 208.98 35.51V28.37C208.98 24.44 206.94 23.35 203.96 23.35H199.17V40.53Z" fill="#030405" />
      <path d="M250.75 70.28H247.85C245.97 70.28 244.79 69.34 244.47 67.38L242.98 58.04H232.93L231.44 67.38C231.13 69.34 229.95 70.28 228.06 70.28H225.63C223.51 70.28 222.41 69.1 222.8 66.9L231.83 18.14C232.14 16.26 233.4 15.31 235.28 15.31H240.93C242.81 15.31 244.07 16.25 244.38 18.14L253.57 66.9C253.97 69.11 252.87 70.28 250.75 70.28ZM227.44 7.87V5.04001C227.44 3.00001 228.54 1.89999 230.58 1.89999H232.62C234.66 1.89999 235.76 3.00001 235.76 5.04001V7.87C235.76 9.91 234.66 11.01 232.62 11.01H230.58C228.53 11.01 227.44 9.91 227.44 7.87ZM241.8 50.5L237.95 26.08L234.1 50.5H241.8ZM240.47 7.87V5.04001C240.47 3.00001 241.57 1.89999 243.61 1.89999H245.65C247.69 1.89999 248.79 3.00001 248.79 5.04001V7.87C248.79 9.91 247.69 11.01 245.65 11.01H243.61C241.57 11.01 240.47 9.91 240.47 7.87Z" fill="#030405" />
      <path d="M263.94 70.28H261.9C259.86 70.28 258.76 69.18 258.76 67.14V18.46C258.76 16.42 259.86 15.32 261.9 15.32H265.2C267.01 15.32 268.18 16.18 268.73 17.91L279.41 49.31V18.45C279.41 16.41 280.51 15.31 282.55 15.31H284.59C286.63 15.31 287.73 16.41 287.73 18.45V67.13C287.73 69.17 286.63 70.27 284.59 70.27H281.76C279.95 70.27 278.78 69.41 278.23 67.68L267.08 34.55V67.13C267.08 69.18 265.98 70.28 263.94 70.28Z" fill="#030405" />
      <path d="M301.86 70.28H299.03C296.99 70.28 295.89 69.18 295.89 67.14V18.46C295.89 16.42 296.99 15.32 299.03 15.32H301.86C303.9 15.32 305 16.42 305 18.46V67.14C305 69.18 303.9 70.28 301.86 70.28Z" fill="#030405" />
      <path d="M318.35 70.28H316.31C314.27 70.28 313.17 69.18 313.17 67.14V18.46C313.17 16.42 314.27 15.32 316.31 15.32H319.61C321.42 15.32 322.59 16.18 323.14 17.91L333.82 49.31V18.45C333.82 16.41 334.92 15.31 336.96 15.31H339C341.04 15.31 342.14 16.41 342.14 18.45V67.13C342.14 69.17 341.04 70.27 339 70.27H336.17C334.36 70.27 333.19 69.41 332.64 67.68L321.49 34.55V67.13C321.49 69.18 320.39 70.28 318.35 70.28Z" fill="#030405" />
      <path d="M377.94 45.16V59.29C377.94 67.77 372.44 70.91 364.12 70.91H363.49C355.17 70.91 349.67 67.85 349.67 59.29V26.31C349.67 18.15 355.17 14.69 363.49 14.69H364.12C372.44 14.69 377.94 18.14 377.94 26.31V31.02C377.94 33.06 376.84 34.16 374.8 34.16H371.97C369.93 34.16 368.83 33.06 368.83 31.02V27.72C368.83 23.79 366.79 22.7 363.81 22.7C360.83 22.7 358.79 23.8 358.79 27.72V57.87C358.79 61.8 360.83 62.89 363.81 62.89C366.79 62.89 368.83 61.79 368.83 57.87V49.7H366.63C364.59 49.7 363.49 48.6 363.49 46.56V45.15C363.49 43.11 364.59 42.01 366.63 42.01H374.79C376.84 42.02 377.94 43.12 377.94 45.16Z" fill="#030405" />
      <path d="M408.24 34.95H405.57C403.53 34.95 402.43 33.85 402.43 31.81V27.26C402.43 23.81 400.47 22.55 397.8 22.55C395.13 22.55 393.17 23.81 393.17 27.26V31.03C393.17 33.86 394.11 35.11 396.7 36.6L403.14 40.21C408.4 43.19 411.54 45.63 411.54 51.52V59.45C411.54 67.93 406.28 70.91 397.96 70.91H397.8C389.48 70.91 384.22 68.01 384.22 59.45V53.01C384.22 50.97 385.32 49.87 387.36 49.87H390.03C392.07 49.87 393.17 50.97 393.17 53.01V58.19C393.17 61.57 395.13 63.06 397.88 63.06C400.63 63.06 402.59 61.57 402.59 58.19V53.87C402.59 51.04 401.73 49.63 398.98 48.14L392.62 44.53C387.28 41.47 384.22 38.96 384.22 33.22V26C384.22 17.83 390.19 14.69 397.72 14.69H397.88C405.42 14.69 411.38 17.83 411.38 26V31.81C411.39 33.85 410.29 34.95 408.24 34.95Z" fill="#030405" />
      <path d="M181.16 110.14C180.81 111.27 180.13 111.76 179.04 111.76C177.96 111.76 177.22 111.27 176.87 110.14L172.24 95.7101V114.82C172.24 116.1 171.55 116.79 170.27 116.79H168.89C167.61 116.79 166.92 116.1 166.92 114.82V84.2901C166.92 83.0101 167.61 82.3201 168.89 82.3201H170.66C171.79 82.3201 172.53 82.8101 172.88 83.9501L178.99 102.37L185.15 83.9501C185.5 82.8701 186.23 82.3201 187.37 82.3201H189.34C190.62 82.3201 191.31 83.0101 191.31 84.2901V114.82C191.31 116.1 190.62 116.79 189.34 116.79H187.76C186.48 116.79 185.79 116.1 185.79 114.82V95.7101L181.16 110.14Z" fill="#030405" />
      <path d="M212.09 116.79H210.27C209.09 116.79 208.35 116.2 208.15 114.97L207.21 109.11H200.91L199.97 114.97C199.77 116.2 199.03 116.79 197.85 116.79H196.32C194.99 116.79 194.3 116.05 194.55 114.67L200.21 84.0901C200.41 82.9101 201.19 82.3201 202.38 82.3201H205.93C207.11 82.3201 207.9 82.9101 208.1 84.0901L213.86 114.67C214.11 116.05 213.42 116.79 212.09 116.79ZM206.48 104.38L204.07 89.0601L201.66 104.38H206.48Z" fill="#030405" />
      <path d="M230.95 94.62H229.28C228 94.62 227.31 93.93 227.31 92.65V89.79C227.31 87.62 226.08 86.83 224.4 86.83C222.73 86.83 221.49 87.62 221.49 89.79V92.15C221.49 93.92 222.08 94.71 223.71 95.65L227.75 97.92C231.05 99.79 233.02 101.32 233.02 105.01V109.98C233.02 115.3 229.72 117.17 224.5 117.17H224.4C219.18 117.17 215.88 115.35 215.88 109.98V105.94C215.88 104.66 216.57 103.97 217.85 103.97H219.52C220.8 103.97 221.49 104.66 221.49 105.94V109.19C221.49 111.31 222.72 112.24 224.45 112.24C226.18 112.24 227.4 111.3 227.4 109.19V106.48C227.4 104.71 226.86 103.82 225.13 102.88L221.14 100.61C217.79 98.69 215.87 97.11 215.87 93.5201V88.9901C215.87 83.8701 219.61 81.9 224.34 81.9H224.44C229.17 81.9 232.91 83.8701 232.91 88.9901V92.63C232.92 93.93 232.23 94.62 230.95 94.62Z" fill="#030405" />
      <path d="M253.61 116.79H251.89C250.76 116.79 250.02 116.3 249.67 115.17L245.43 101.82L242.87 106.84V114.82C242.87 116.1 242.18 116.79 240.9 116.79H239.13C237.85 116.79 237.16 116.1 237.16 114.82V84.2901C237.16 83.0101 237.85 82.3201 239.13 82.3201H240.9C242.18 82.3201 242.87 83.0101 242.87 84.2901V97.8301L248.43 83.8401C248.82 82.8101 249.61 82.3101 250.7 82.3101H252.77C254.3 82.3101 254.89 83.2901 254.2 84.6201L248.78 95.2601L255.23 114.52C255.68 115.85 255.04 116.79 253.61 116.79Z" fill="#030405" />
      <path d="M262.27 116.79H260.5C259.22 116.79 258.53 116.1 258.53 114.82V84.2901C258.53 83.0101 259.22 82.3201 260.5 82.3201H262.27C263.55 82.3201 264.24 83.0101 264.24 84.2901V114.82C264.24 116.1 263.55 116.79 262.27 116.79Z" fill="#030405" />
      <path d="M272.62 116.79H271.34C270.06 116.79 269.37 116.1 269.37 114.82V84.2901C269.37 83.0101 270.06 82.3201 271.34 82.3201H273.41C274.54 82.3201 275.28 82.8601 275.63 83.9501L282.33 103.65V84.3001C282.33 83.0201 283.02 82.3301 284.3 82.3301H285.58C286.86 82.3301 287.55 83.0201 287.55 84.3001V114.83C287.55 116.11 286.86 116.8 285.58 116.8H283.81C282.68 116.8 281.94 116.26 281.59 115.18L274.6 94.4001V114.84C274.59 116.1 273.9 116.79 272.62 116.79Z" fill="#030405" />
      <path d="M305.76 116.79H294.43C293.15 116.79 292.46 116.1 292.46 114.82V84.2901C292.46 83.0101 293.15 82.3201 294.43 82.3201H305.61C306.89 82.3201 307.58 83.0101 307.58 84.2901V85.3701C307.58 86.6501 306.89 87.3401 305.61 87.3401H298.17V96.8001H304.08C305.36 96.8001 306.05 97.4901 306.05 98.7701V99.8501C306.05 101.13 305.36 101.82 304.08 101.82H298.17V111.77H305.75C307.03 111.77 307.72 112.46 307.72 113.74V114.82C307.73 116.1 307.04 116.79 305.76 116.79Z" fill="#030405" />
      <path d="M327.77 116.79H326.05C324.92 116.79 324.18 116.3 323.83 115.17L319.99 103.05H317.08V114.82C317.08 116.1 316.39 116.79 315.11 116.79H313.34C312.06 116.79 311.37 116.1 311.37 114.82V84.2901C311.37 83.0101 312.06 82.3201 313.34 82.3201H320.28C325.5 82.3201 328.95 84.3901 328.95 89.6101V95.7701C328.95 99.0701 327.57 101.14 325.21 102.17L329.4 114.53C329.84 115.85 329.2 116.79 327.77 116.79ZM317.09 98.1201H320.09C321.96 98.1201 323.24 97.4301 323.24 94.9701V90.4901C323.24 88.0301 321.96 87.3401 320.09 87.3401H317.09V98.1201Z" fill="#030405" />
      <path d="M338.51 112.35V114.81C338.51 116.09 337.82 116.78 336.54 116.78H334.87C333.59 116.78 332.9 116.09 332.9 114.81V112.35C332.9 111.07 333.59 110.38 334.87 110.38H336.54C337.82 110.38 338.51 111.07 338.51 112.35Z" fill="#030405" />
      <path d="M360.18 104.72V109.89C360.18 115.21 356.73 117.18 351.51 117.18H351.41C346.19 117.18 342.74 115.26 342.74 109.89V89.2101C342.74 84.0901 346.19 81.92 351.41 81.92H351.51C356.73 81.92 360.18 84.0901 360.18 89.2101V93.54C360.18 94.82 359.49 95.51 358.21 95.51H356.44C355.16 95.51 354.47 94.82 354.47 93.54V90.09C354.47 87.68 353.29 86.94 351.47 86.94C349.65 86.94 348.47 87.68 348.47 90.09V109C348.47 111.41 349.65 112.15 351.47 112.15C353.29 112.15 354.47 111.41 354.47 109V104.71C354.47 103.43 355.16 102.74 356.44 102.74H358.21C359.49 102.75 360.18 103.44 360.18 104.72Z" fill="#030405" />
      <path d="M373.18 81.92H373.57C378.79 81.92 382.24 83.9901 382.24 89.2101V109.89C382.24 115.11 378.79 117.18 373.57 117.18H373.18C367.96 117.18 364.51 115.11 364.51 109.89V89.2101C364.51 83.9401 367.96 81.92 373.18 81.92ZM373.37 112.16C375.24 112.16 376.52 111.47 376.52 109.01V90.09C376.52 87.63 375.24 86.94 373.37 86.94C371.5 86.94 370.22 87.63 370.22 90.09V109C370.22 111.47 371.5 112.16 373.37 112.16Z" fill="#030405" />
      <path d="M401.2 110.14C400.86 111.27 400.17 111.76 399.08 111.76C398 111.76 397.26 111.27 396.91 110.14L392.28 95.7101V114.82C392.28 116.1 391.59 116.79 390.31 116.79H388.93C387.65 116.79 386.96 116.1 386.96 114.82V84.2901C386.96 83.0101 387.65 82.3201 388.93 82.3201H390.7C391.83 82.3201 392.57 82.8101 392.92 83.9501L399.03 102.37L405.19 83.9501C405.53 82.8701 406.27 82.3201 407.41 82.3201H409.38C410.66 82.3201 411.35 83.0101 411.35 84.2901V114.82C411.35 116.1 410.66 116.79 409.38 116.79H407.8C406.52 116.79 405.83 116.1 405.83 114.82V95.7101L401.2 110.14Z" fill="#030405" />
    </svg>

  ),
}
