/* eslint-disable max-len */
const programName = 'Resväskor'
export default {
  programName,
  logo: (
    <svg className="resvaskor" x="0px" y="0px" viewBox="0 0 215 57">
      <title>{programName}</title>
      <g id="Page_1" transform="translate(-0.161 30.618) rotate(-8)">
        <path
          className="st0"
          d="M207.1-0.5l-177-24.8c-1.7-0.2-3.4,0.1-4.9,1L8.3-14.2c-2,1.2-3.4,3.3-3.7,5.6L2.9,4.1c-0.3,2.3,0.4,4.6,2,6.3
   l13.5,14.3c1.1,1.2,2.7,2.1,4.4,2.3l177,24.8c2.6,0.4,5.1-1.5,5.4-4.2l6-42.6C211.6,2.4,209.8-0.1,207.1-0.5z M17.9,0
   c0.2-2.1,2.2-3.7,4.4-3.3c2.1,0.4,3.7,2.4,3.3,4.4c-0.2,2.1-2.2,3.7-4.4,3.3C19.1,4.2,17.6,2.1,17.9,0z"
        />
        <path
          id="Fill_1_1_"
          className="st1"
          d="M20.6,8.8c-4.5-0.6-7.7-4.8-7-9.3c0.6-4.5,4.8-7.7,9.3-7c4.5,0.7,7.7,4.8,7,9.3
   C29.3,6.3,25.1,9.4,20.6,8.8z M22.2-2.7c-1.8-0.3-3.5,1-3.7,2.8c-0.3,1.8,1,3.5,2.8,3.7c1.8,0.3,3.5-1,3.7-2.8
   C25.3-0.7,24-2.4,22.2-2.7z"
        />
        <path
          id="Fill_6"
          className="st2"
          d="M36.1,28.9l-0.8-0.1l4-3.1l-0.1,0.9L36.1,28.9L36.1,28.9z M32.4,28.4h-0.2l0.1-0.6l7.4-5.8
   l-0.1,0.9L32.4,28.4L32.4,28.4z M32.7,24.8l0.1-0.9l7.4-5.8L40.1,19L32.7,24.8L32.7,24.8z M33.2,21.1l0.1-0.9l7.4-5.8l-0.1,0.9
   L33.2,21.1L33.2,21.1z M33.8,17.3l0.1-0.9l7.4-5.8l-0.1,0.9L33.8,17.3L33.8,17.3z M34.3,13.6l0.1-0.9l7.4-5.8l-0.1,0.9L34.3,13.6
   L34.3,13.6z M34.8,9.8L34.9,9l7.4-5.8L42.2,4L34.8,9.8L34.8,9.8z M35.4,6.1l0.1-0.9l7.4-5.8l-0.1,0.9L35.4,6.1L35.4,6.1z M35.9,2.3
   L36,1.4l7.4-5.8l-0.1,0.9L35.9,2.3L35.9,2.3z M36.4-1.5l0.1-0.9l7.4-5.8l-0.1,0.9L36.4-1.5L36.4-1.5z M36.9-5.2L37-6.1l7.4-5.8v0.9
   L36.9-5.2L36.9-5.2z M37.5-9l0.1-0.9l7.4-5.8l-0.1,0.9L37.5-9L37.5-9z M38-12.7l0.1-0.9l7.4-5.8l-0.1,0.9L38-12.7L38-12.7z
    M38.5-16.5l0.1-0.9l7.2-5.6H46l-0.1,0.7L38.5-16.5L38.5-16.5z M39-20.2l0.1-0.9l3-2.4l0.8,0.1L39-20.2L39-20.2z"
        />
        <path
          id="Fill_9"
          className="st3"
          d="M50.4,25.1l-4-0.6l5.2-37.2l6.5,0.9c1.4,0.1,2.6,0.7,3.5,1.8c0.7,1.2,0.9,2.6,0.6,4L60.8,4.2
   c-0.3,2.2-1.2,3.5-2.8,3.9c0.7,0.3,1.2,0.8,1.6,1.4c0.3,0.8,0.4,1.6,0.2,2.4l-2,14.1l-4-0.6l2-14c0.2-1.2-0.3-1.8-1.4-2l-1.8-0.2
   L50.4,25.1z M55-8.4L53,5.7l1.8,0.2c1.1,0.2,1.7-0.3,1.9-1.5l1.5-10.8C58.4-7.5,58-8,56.9-8.2L55-8.4z"
        />
        <path
          id="Fill_11"
          className="st3"
          d="M75.3-9.4l-0.5,3.8l-5.1-0.7L67.9,6.4l4.9,0.7l-0.5,3.7l-4.9-0.7l-1.9,13.2l5.2,0.7l-0.5,3.8
   l-9.3-1.3l5.2-37.2L75.3-9.4z"
        />
        <path
          id="Fill_13"
          className="st3"
          d="M85.4,16.9l-1.2,8.7c-0.5,3.4-2.3,4.9-5.5,4.4l-1.8-0.2c-3.2-0.4-4.5-2.3-4.1-5.7l1.4-10.4
   l3.9,0.5l-1.4,10.1c-0.2,1.1,0.2,1.6,1.3,1.8l0.6,0.1c1,0.1,1.5-0.3,1.7-1.4l1.1-8c0.3-1.1,0-2.4-0.9-3.2L77.2,10
   c-1.6-1.8-2-3.2-1.6-5.9l1.1-8c0.5-3.4,2.3-4.9,5.5-4.4L84-8.2c3.3,0.5,4.6,2.3,4.1,5.7l-1.4,9.7l-4-0.6L84-2.9
   c0.2-1.1-0.2-1.7-1.2-1.8l-0.5-0.1c-1-0.1-1.5,0.3-1.7,1.4l-1,7.4c-0.3,1.1,0,2.4,0.8,3.2l3.2,3.6C85.5,12.8,85.8,14.2,85.4,16.9"
        />
        <path id="Fill_15" className="st3" d="M89.4,30.5l1.5-37.8l4,0.6l-1.6,28.3L99.6-6l3.8,0.5l-9,36.7L89.4,30.5z" />
        <path
          id="Fill_17"
          className="st3"
          d="M111.5,33.6l-3.9-0.5l0.3-5.9l-4-0.6l-1.3,5.8L99,31.9l9-38l5.2,0.7L111.5,33.6L111.5,33.6z
    M109.5,2.3L109.5,2.3l-4.6,20.6l3.4,0.5L109.5,2.3L109.5,2.3z M111.8-7.3l0.5-3.3l3.1,0.4l-0.5,3.3L111.8-7.3L111.8-7.3z
    M107.2-7.9l0.5-3.3l3.1,0.4l-0.5,3.3L107.2-7.9L107.2-7.9z"
        />
        <path
          id="Fill_19"
          className="st3"
          d="M126.8,22.7l-1.2,8.7c-0.5,3.4-2.3,4.9-5.5,4.4l-1.8-0.2c-3.2-0.4-4.5-2.3-4.1-5.7l1.4-10.4
   l3.9,0.5l-1.4,10.1c-0.2,1.1,0.2,1.6,1.3,1.8L120,32c1,0.1,1.5-0.3,1.7-1.4l1.1-8c0.3-1.1,0-2.4-0.8-3.2l-3.3-3.7
   c-1.6-1.8-2-3.2-1.6-5.9l1.1-8c0.5-3.4,2.3-4.9,5.5-4.4l1.7,0.2c3.3,0.5,4.6,2.3,4.1,5.7l-1.4,9.7l-4-0.6l1.3-9.5
   c0.2-1.1-0.2-1.7-1.2-1.8L123.7,1c-1-0.1-1.5,0.3-1.7,1.4l-1,7.4c-0.3,1.1,0,2.4,0.8,3.2l3.2,3.6C126.8,18.6,127.2,20,126.8,22.7"
        />
        <path
          id="Fill_20"
          className="st3"
          d="M136,37.1l-1.3-18.6l-2.5,18l-4.1-0.6l5.2-37.2l4.1,0.6l-2.5,17.8l6.6-17.2l4.2,0.6l-7,17.8
   l1.7,19.4L136,37.1z"
        />
        <path
          id="Fill_21"
          className="st3"
          d="M148.1,39.8l-2.1-0.3c-3.1-0.4-4.4-2.4-3.9-5.8l3.9-28c0.5-3.4,2.3-4.9,5.4-4.5l2.1,0.3
   c1.3,0.1,2.5,0.7,3.3,1.8c0.7,1.2,0.9,2.6,0.6,4l-3.9,28c-0.1,1.4-0.7,2.7-1.7,3.7C150.7,39.8,149.4,40.1,148.1,39.8z M151.5,5.1
   c-1-0.1-1.5,0.3-1.6,1.4L146,34.1c-0.2,1.1,0.2,1.6,1.2,1.8L148,36c1,0.1,1.5-0.3,1.7-1.4L153.6,7c0.2-1.1-0.2-1.7-1.2-1.8
   L151.5,5.1z"
        />
        <path
          id="Fill_22"
          className="st3"
          d="M160,40.4l-4-0.6l5.2-37.2l6.5,0.9c1.4,0.1,2.6,0.7,3.5,1.8c0.7,1.2,0.9,2.6,0.6,4l-1.4,10.3
   c-0.3,2.2-1.2,3.5-2.8,3.9c1.5,0.7,2.1,1.9,1.8,3.9l-2,14.1l-4-0.6l2-14c0.2-1.2-0.3-1.8-1.4-2l-1.8-0.2
   C162.2,24.6,160,40.4,160,40.4z M164.7,6.9l-2,14.1l1.8,0.2c1.1,0.2,1.7-0.3,1.9-1.5l1.5-10.8c0.1-1.1-0.3-1.6-1.3-1.8L164.7,6.9z"
        />
        <path id="Fill_23" className="st1" d="M169.5,41.7l4.1,0.6l0.7-5.2l-4.1-0.6L169.5,41.7z" />
        <path
          id="Fill_24"
          className="st1"
          d="M188.8,31.4l-1.2,8.7c-0.5,3.4-2.3,4.9-5.5,4.4l-1.8-0.2c-3.2-0.4-4.5-2.3-4.1-5.7l1.4-10.4
   l3.9,0.5l-1.4,10.1c-0.2,1.1,0.2,1.6,1.3,1.8l0.6,0.1c1,0.1,1.5-0.3,1.7-1.4l1.1-8c0.3-1.1,0-2.4-0.9-3.2l-3.3-3.7
   c-1.6-1.8-2-3.2-1.6-5.9l1.1-8c0.5-3.4,2.3-4.9,5.5-4.4l1.7,0.2c3.3,0.5,4.6,2.3,4.1,5.7l-1.4,9.7l-4-0.6l1.3-9.5
   c0.2-1.1-0.2-1.7-1.2-1.8l-0.5-0.1c-1-0.1-1.5,0.3-1.7,1.4l-1,7.4c-0.3,1.1,0,2.4,0.8,3.2l3.2,3.6
   C188.9,27.3,189.2,28.7,188.8,31.4"
        />
        <path
          id="Fill_25"
          className="st1"
          d="M204.6,8.7l-0.5,3.8l-5.1-0.7l-1.8,12.7l4.9,0.7l-0.5,3.7l-4.9-0.7l-1.9,13.2l5.2,0.7l-0.5,3.8
   l-9.3-1.3l5.2-37.2L204.6,8.7z"
        />
      </g>
    </svg>
  ),
}
