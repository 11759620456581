/* eslint-disable max-len */
const programName = 'Body & Fit'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 133 26">
      <title>{programName}</title>
      <g id="Logos/Logo_white" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Logo" transform="translate(0.000000, 1.000000)" fill="black">
          <path d="M81.5807,19.5514 L82.1507,15.0004 L76.9957,15.0174 C74.2087,15.0174 73.3697,15.7154 73.1607,17.3634 L72.8907,19.5214 C72.8607,19.7604 72.8607,19.8514 72.8607,19.9704 C72.8607,21.4394 74.5087,22.0984 76.9357,22.0984 C79.9627,22.0984 81.3717,21.1684 81.5807,19.5514 L81.5807,19.5514 Z M87.3347,12.4184 C87.7837,12.4184 87.8737,12.6284 87.8447,12.9884 L87.6947,14.2774 C87.6637,14.6964 87.4547,15.0004 87.0647,15.0004 L85.0077,15.0174 L84.3977,19.9404 C84.0687,22.3984 82.3007,24.5264 76.6657,24.5264 C71.6617,24.5264 69.9237,22.7574 69.9237,20.5394 C69.9237,20.3904 69.9237,20.1504 69.9537,19.9104 L70.3427,17.0034 C70.5527,15.2664 71.3017,14.1564 73.4597,13.5574 C71.8717,13.1084 71.3317,12.1794 71.3317,10.9804 C71.3317,10.8004 71.3617,10.5304 71.3927,10.3214 L71.6617,8.1634 C71.9317,5.7364 73.2797,3.6684 78.3447,3.6684 C82.1207,3.6684 84.1877,5.0464 84.1877,7.5334 L84.1877,8.0134 L84.0987,9.0324 C84.0387,9.4514 83.8587,9.6024 83.4687,9.6024 L81.7007,9.6024 C81.2807,9.6024 81.1917,9.3924 81.2217,9.0324 L81.2807,8.4334 L81.2807,8.0734 C81.2807,6.4544 79.7527,6.0954 78.1347,6.0954 C76.0667,6.0954 74.6887,6.6644 74.4787,8.6434 L74.3287,9.9024 C74.2987,10.1714 74.2687,10.3214 74.2687,10.4714 C74.2687,11.7294 75.1377,12.4184 77.5057,12.4184 L87.3347,12.4184 Z" id="Fill-5" />
          <path d="M108.4938,23.8991 C108.4328,24.3281 108.3098,24.5121 107.8808,24.5121 L106.0108,24.5121 C105.6118,24.5121 105.4278,24.3281 105.4898,23.8991 L107.9108,4.2771 C107.9728,3.8471 108.1568,3.6641 108.5558,3.6641 L110.4258,3.6641 C110.8548,3.6641 110.9778,3.8471 110.9158,4.2771 L108.4938,23.8991 Z" id="Fill-1" />
          <path d="M127.2878,3.6637 C127.6858,3.6637 127.8698,3.8167 127.8088,4.2457 L127.6558,5.6257 C127.5948,6.0557 127.4098,6.2087 127.0118,6.2087 L122.1058,6.2087 L119.8988,23.8987 C119.8368,24.3277 119.7148,24.5127 119.2858,24.5127 L117.4148,24.5127 C117.0168,24.5127 116.8328,24.3277 116.8938,23.8987 L119.1008,6.2087 L114.1958,6.2087 C113.7978,6.2087 113.6138,6.0557 113.6748,5.6257 L113.8288,4.2457 C113.8898,3.8167 114.0738,3.6637 114.4728,3.6637 L127.2878,3.6637 Z" id="Fill-3" />
          <path d="M103.7419,3.6637 L92.4899,3.6637 C92.0909,3.6637 91.9069,3.8477 91.8459,4.2767 L91.3059,8.6487 L91.3059,8.6487 L91.2619,9.0087 C91.1999,9.4397 91.3609,9.6007 91.7629,9.6007 L93.6539,9.6007 C94.0879,9.6007 94.2369,9.4397 94.2989,9.0087 L94.3439,8.6487 L94.3469,8.6487 L94.6699,6.2047 L103.4349,6.2087 C103.8339,6.2087 104.0179,6.0557 104.0789,5.6257 L104.2629,4.2457 C104.3249,3.8167 104.1399,3.6637 103.7419,3.6637" id="Fill-8" />
          <path d="M102.1169,12.4137 L94.8889,12.4137 L91.5109,12.4137 L91.4789,12.4147 L91.3399,12.4147 C90.9599,12.4147 90.7939,12.6107 90.7679,12.9887 L89.4239,23.8987 C89.3629,24.3277 89.5459,24.5127 89.9449,24.5127 L91.8149,24.5127 C92.2439,24.5127 92.3669,24.3277 92.4289,23.8987 L93.5629,15.0077 L101.8099,15.0077 C102.2089,15.0077 102.3929,14.8237 102.4539,14.4257 L102.6379,12.9957 C102.6999,12.5667 102.5149,12.4137 102.1169,12.4137" id="Fill-10" />
          <path d="M7.7941,20.3815 L5.2951,20.3815 L5.8501,15.8365 L8.5241,15.8365 C9.6121,15.8365 10.9101,16.0785 10.9101,17.2315 C10.9101,17.3365 10.9091,17.3895 10.8831,17.6515 L10.7661,18.7935 C10.7271,19.1865 10.6071,20.3815 7.7941,20.3815 L7.7941,20.3815 Z M6.3201,11.8965 L6.8421,7.7615 L8.6121,7.7615 C10.9971055,7.7615 10.9971,8.5955 10.9971,8.9505 C10.9971,9.0275 10.9981,9.1835 10.9731,9.3835 L10.8561,10.2315 C10.7021,11.3355 9.7401,11.8965 7.9991,11.8965 L6.3201,11.8965 Z M15.6881,10.4115 L15.8641,8.9165 C15.8951,8.6325 15.9251,8.3495 15.9251,8.1605 C15.9251,6.8035 15.2301,3.6455 8.7871,3.6455 L3.5581,3.6455 C3.0421,3.6455 2.4041,3.8515 2.2961,4.8245 L0.0181,23.1915 C-0.0389,23.5885 0.0371,23.9105 0.2411,24.1475 C0.4431,24.3795 0.7431,24.4975 1.1331,24.4975 L7.5311,24.4975 C12.4211,24.4975 15.1171,22.8525 15.5421,19.6035 L15.8041,17.5255 C15.8361,17.2675 15.8371,17.0095 15.8371,16.8805 C15.8371,15.5445 15.0291,14.3145 13.7141,13.5535 C14.9151,12.8215 15.5341,11.8215 15.6881,10.4115 L15.6881,10.4115 Z" id="Fill-12" />
          <path d="M22.5104,18.6608 L23.6654,9.5338 C23.8564,7.9698 25.7674,7.8008 26.5794,7.8008 C28.2204,7.8008 29.0524,8.2498 29.0524,9.1348 C29.0524,9.2168 29.0334,9.3488 29.0124,9.5048 L27.8294,18.6308 C27.6884,19.7808 26.7174,20.3638 24.9444,20.3638 C23.3024,20.3638 22.4704,19.9148 22.4704,19.0298 C22.4704,18.9478 22.4904,18.8158 22.5104,18.6608 M26.8714,3.6628 C20.4134,3.6628 19.0624,6.8418 18.8324,8.7358 L17.5454,19.1268 L17.5344,19.2328 C17.5074,19.4938 17.4844,19.7258 17.4844,19.8878 C17.4844,20.9708 17.9994,24.5018 24.6224,24.5018 C31.1064,24.5018 32.4614,21.3228 32.6914,19.4298 L33.9774,9.0438 C34.0094,8.7608 34.0094,8.4578 34.0094,8.2768 C34.0094,6.0468 32.1344,3.6628 26.8714,3.6628" id="Fill-15" />
          <path d="M42.9601,20.2673 L40.3141,20.2673 L41.8611,7.8873 L44.5681,7.8873 C47.0151,7.8873 47.0151,8.8783 47.0151,9.2513 C47.0151,9.3383 46.9891,9.6633 46.9601,9.8653 L45.8781,18.4943 C45.7021,19.9593 44.1941,20.2673 42.9601,20.2673 M44.7731,3.6563 L38.5741,3.6563 C38.0571,3.6563 37.4181,3.8623 37.3101,4.8353 L35.0281,23.2063 C34.9831,23.6153 35.0661,23.9413 35.2771,24.1763 C35.4091,24.3233 35.6511,24.4973 36.0591,24.4973 L42.7551,24.4973 C48.8251,24.4973 50.4351,21.6763 50.7161,19.3113 L51.9431,9.3493 C51.9751,9.1243 52.0061,8.6063 52.0061,8.4333 C52.0061,7.3123 51.4851,3.6563 44.7731,3.6563" id="Fill-17" />
          <path d="M69.1286,4.0983 C69.0336,3.9353 68.7866,3.6613 68.2056,3.6613 L65.6346,3.6613 C64.8106,3.6613 64.4496,4.2923 64.2576,4.6273 L60.2876,11.4153 L58.9756,7.1303 L58.2646,4.7453 C58.1776,4.4213 57.9756,3.6613 57.0146,3.6613 L54.1796,3.6613 C53.7616,3.6613 53.4206,3.8123 53.2226,4.0883 C53.0376,4.3453 52.9986,4.6753 53.1146,5.0243 L57.1986,16.9293 L56.4226,23.2213 C56.3756,23.6243 56.4496,23.9293 56.6486,24.1543 C56.8476,24.3763 57.1396,24.4853 57.5406,24.4853 L60.0826,24.4853 C61.1806,24.4853 61.2826,23.6193 61.3156,23.3363 L62.0736,17.1913 L69.0606,5.1033 C69.3256,4.6293 69.2446,4.2953 69.1286,4.0983" id="Fill-19" />
          <path d="M130.3058,0.405 C129.5608,0.405 128.9548,1.011 128.9548,1.757 C128.9548,2.502 129.5608,3.108 130.3058,3.108 C131.0508,3.108 131.6568,2.502 131.6568,1.757 C131.6568,1.011 131.0508,0.405 130.3058,0.405 M130.3058,3.513 C129.3368,3.513 128.5488,2.725 128.5488,1.757 C128.5488,0.788 129.3368,0 130.3058,0 C131.2748,0 132.0628,0.788 132.0628,1.757 C132.0628,2.725 131.2748,3.513 130.3058,3.513" id="Fill-23" />
          <path d="M130.1837,1.2233 L130.1257,1.6743 L130.3547,1.6743 C130.5127,1.6743 130.6067,1.6283 130.6227,1.5143 L130.6367,1.4203 C130.6387,1.4023 130.6387,1.3883 130.6387,1.3793 C130.6387,1.2793 130.5697,1.2233 130.4167,1.2233 L130.1837,1.2233 Z M130.0947,1.9303 L130.0297,2.4633 C130.0257,2.5043 130.0097,2.5233 129.9687,2.5233 L129.7687,2.5233 C129.7377,2.5233 129.7117,2.5123 129.7187,2.4633 L129.8977,1.0273 C129.9017,0.9863 129.9197,0.9673 129.9587,0.9673 L130.4237,0.9673 C130.7987,0.9673 130.9477,1.1293 130.9477,1.3173 C130.9477,1.3303 130.9477,1.3523 130.9457,1.3693 L130.9227,1.5693 C130.9067,1.7063 130.8287,1.8323 130.6337,1.8913 L130.8057,2.4633 C130.8177,2.5003 130.7857,2.5233 130.7457,2.5233 L130.5497,2.5233 C130.5127,2.5233 130.5057,2.4953 130.4967,2.4633 L130.3367,1.9303 L130.3317,1.9303 L130.0947,1.9303 Z" id="Fill-21" />
        </g>
      </g>
    </svg>
  ),
}
