/* eslint-disable max-len */
const programName = 'Lekia'
export default {
  programName,
  logo: (
    <svg className="lekia" viewBox="0 0 777.766 428.898">
      <title>{programName}</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Lager_1" data-name="Lager 1">
          <path className="cls-1" d="M144.806,79.472c31.315-9.3,52.466-32.949,52.067-65.613C196.775,5.852,204,.412,211.98.014c9.126-.455,17.545,10.194,16.969,16.157-3.025,31.367,15.613,49.693,37.334,59.469,25.485,11.469,55.222,9.344,70.237-14.227,3.479-5.46,14.534-6.779,18.955-.844,3.429,4.6,3.394,11.54-.9,14.69-16.235,11.913-18.293,34.547-6.922,51.166,9.421,13.769,23.395,19.309,39.723,15.879l.021,22.647c-10.1,8.124-14.708,17.716-13.054,30.576,1.221,9.5,5.159,16.428,13.709,25.105l.017,44.244c-19.428,4.24-30.7,13.336-39.815,30.4-11.634,21.771-6.357,43.085,9.331,62.6,4.139,5.151,2.008,13.078-3.612,16.554-5.04,3.117-12.388,3.188-15.048-2.106-13.727-27.314-45.595-34.9-72.834-22.875-25.18,11.113-41.182,40.764-33.708,62,3.759,10.678-4.449,14.834-12.339,16.82-8.977,2.259-18.149-1.526-20.112-12.607-3.944-22.259-14.327-38.711-35.868-44.845-20.919-5.959-43.931-2.327-52.969,17.457-2.486,5.439-9.51,5.245-14.395,2.333-5.634-3.36-8.212-12.063-3.362-16.48,11.474-10.45,7.684-33.92-3.913-49.36-13.713-18.26-33.806-20.817-56.882-13.9-7.047,2.113-13.712-4.764-15.588-12.271-1.554-6.214,4.522-18.112,8.666-18.978,28.962-6.052,38.692-28.6,33.708-52.371C54.241,202.97,26.8,185.189,11.779,186.621,4.37,187.328-.507,179,.042,171.572c.551-7.451,6.214-14.175,13.844-13.243,21.014,2.566,38.456-8.873,47.553-26.185,9.245-17.6,13-35.683-8.427-50.264-6.538-4.449-3.7-14.794,2.106-20.166,6.2-5.743,18.022-4.966,21.67.6C91.352,84.55,121.9,86.277,144.806,79.472Z" />
          <path className="cls-2" d="M143.4,100.345V255.577c0,10.994,7.326,23.651,18.321,23.651H188.37V149.313c0-33.313,37.476-48.968,72.286-48.968h71.953v52.8c-21.63.107-51.128-.306-70.12,0-10.327.166-15.324,7.662-15.657,18.155-.3,9.322,7.123,17.488,16.489,17.488H321.95v53.465H263.321c-9.366,0-16.507,8.5-16.322,20.154.166,10.493,7.828,16.989,15.156,16.989,12.992,0,70.288.166,70.454,0v53.3H239.337c-19.654-2.166-35.477-18.655-48.135-37.642v37.642H136.238c-23.651,0-46.3-27.977-46.3-66.457,0-17.156,0-164.226.166-165.892,0-.166,53.3,0,53.3,0Z" />
          <path className="cls-2" d="M350.764,100.678h56.8c.166.167-.167,80.114,0,80.281,18.318.39,47.3,1,47.3-17.322V100.678h56.63v61.46c0,28.648-10.992,30.147-21.653,44.138,14.657,13.324,23.985,21.32,23.985,41.306V332.86H460.194V255.744c0-17.656-14.325-26.483-29.148-26.816H407.561v104.1h-56.8V100.678Z" />
          <path className="cls-2" d="M528.981,172.132c8.994,5.329,17.99,8.707,28.649,8.494,8.328-.167,19.154-3.331,28.148-8.661v160.9H529.149l-.168-160.728Z" />
          <path className="cls-2" d="M521.319,133.99a36.144,36.144,0,1,1,36.144,36.143,36.143,36.143,0,0,1-36.144-36.143Z" />
          <path className="cls-3" d="M691.709,148.98c23.489.168,29.147,18.823,29.147,35.81v30.647H661.061V187.455c0-18.655,7.33-38.642,30.648-38.475Zm-.334-54.8c47.635,0,86.278,35.477,86.278,84.111V332.86h-56.8c-.166-.333,0-67.456,0-67.456H661.061V332.86H601.768V167.635c0-37.809,44.637-73.452,89.607-73.452Z" />
          <path className="cls-4" d="M691.709,148.98c23.489.168,29.147,18.823,29.147,35.81v30.647H661.061V187.455c0-18.655,7.33-38.642,30.648-38.475Zm-.334-54.8c47.635,0,86.278,35.477,86.278,84.111V332.86h-56.8c-.166-.333,0-67.456,0-67.456H661.061V332.86H601.768V167.635c0-37.809,44.637-73.452,89.607-73.452Z" />
        </g>
      </g>
    </svg>
  ),
}
