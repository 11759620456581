/* eslint-disable max-len */
const programName = 'Tommy Hilfiger'
export default {
  programName,
  logo: (
    <svg className="tommy-hilfiger">
      <title>{programName}</title>
      <g fill="none" fillRule="evenodd">
        <path fill="#C8102E" d="M142.222 12.398h12.493v-8.15h-12.493z" />
        <path fill="#FFF" d="M129.73 12.398h12.492V4.27h-12.491z" />
        <path fill="#041E42" d="M129.728 4.259h24.987V.215h-24.987zm0 12.181h24.987v-4.065h-24.987zM254.938 0c1.905 0 3.239.31 5.787 1.382V3.74c-1.904-1.047-3.882-1.595-5.834-1.595-3.526 0-6.267 2.692-6.267 6.217 0 3.62 2.718 6.17 6.575 6.17 1.262 0 2.406-.286 3.527-.645v-3.335h-3.124V8.457h5.456v6.882c-2.167.953-3.928 1.336-6.19 1.336-4.979 0-8.65-3.48-8.65-8.242 0-4.788 3.765-8.433 8.72-8.433zM30.848 0c5.358 0 8.74 3.811 8.74 8.337 0 4.385-3.287 8.337-8.79 8.337-5.31 0-8.67-3.928-8.67-8.337 0-4.503 3.455-8.337 8.72-8.337zm-17.27.215v2.096H7.955v14.148H5.62V2.311H0V.215h13.577zm39.16 0l5.384 6.67 5.406-6.67h2.168v16.244h-2.334V3.811h-.049l-5.001 6.29h-.382l-5.002-6.29h-.048V16.46h-2.334V.214h2.192zm28.345 0l5.383 6.67 5.408-6.67h2.167v16.244h-2.334V3.811h-.048l-5.002 6.29h-.381l-5.003-6.29h-.047V16.46h-2.335V.214h2.192zm25.892 0l4.36 5.598L115.74.215h2.812l-6.028 7.67v8.575h-2.333V7.861L104.164.215h2.81zm63.297 0v7.074h9.1V.214h2.332V16.46h-2.333V9.386h-9.099v7.073h-2.335V.214h2.335zm23.435 0V16.46h-2.33V.215h2.33zm11.913 0v14.148h7.337v2.096h-9.673V.214h2.336zm22.713 0V2.31h-6.339v4.502h6.339V8.91h-6.339v7.55h-2.334V.216h8.673zm10.5 0V16.46h-2.332V.215h2.333zm40.328 0V2.31h-6.884v4.91h6.647v2.095h-6.647v5.048h7.1v2.098h-9.434V.215h9.218zm12.292 0c3.288 0 5.337 1.74 5.337 4.55 0 1.882-.976 3.286-2.717 3.977 1.475.977 2.382 2.477 3.62 4.503.69 1.118 1.098 1.668 2.218 3.214h-2.789l-2.144-3.335c-2.143-3.333-3.12-3.715-4.31-3.715h-.977v7.05h-2.335V.215h4.097zM30.847 2.144c-3.621 0-6.312 2.62-6.312 6.193 0 3.55 2.69 6.193 6.312 6.193 3.644 0 6.336-2.643 6.336-6.193 0-3.572-2.692-6.193-6.336-6.193zm260.344.072h-1.5v5.097h1.5c2.503 0 3.217-1.286 3.194-2.644-.027-1.526-.929-2.453-3.194-2.453z" />
      </g>
    </svg>
  ),
}
