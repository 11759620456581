/* eslint-disable max-len */
const programName = 'Burton'
export default {
  programName,
  logo: (
    <svg className="burton" viewBox="0 0 384 60">
      <title>{programName}</title>
      <g>
        <path d="M60,59.6H0v-60h60V59.6z M83.9,47.6v-36h17.8c4.4,0,7.7,0.6,9.8,1.9c2.7,1.7,4.1,4.1,4.1,7.3c0,3.1-1.6,5.6-4.7,7.6
                    c1.8,0.5,3.3,1.5,4.4,2.9c1.1,1.5,1.6,3.3,1.6,5.4c0,1.5-0.2,2.9-0.7,4.2c-0.5,1.3-1.2,2.3-2.1,3.2c-0.6,0.6-1.5,1.2-2.7,1.8
                    s-2.3,1-3.5,1.3c-1.2,0.3-3.1,0.4-5.9,0.4H83.9z M95.3,19.7V26h4.5c1.8,0,3.1-0.3,3.8-0.9c0.7-0.6,1.1-1.3,1.1-2.3
                    c0-0.7-0.2-1.3-0.6-1.9c-0.4-0.5-1-0.9-1.7-1.1c-0.5-0.1-1.6-0.2-3.3-0.2H95.3z M95.3,32.5v6.8h4.8c2.3,0,3.8-0.3,4.6-0.9
                    c0.8-0.6,1.1-1.5,1.1-2.6c0-1.1-0.4-1.9-1.1-2.5c-0.7-0.6-2.3-0.9-4.6-0.9H95.3z M136.3,11.6h11.8v22.2c0,1.5,0.2,2.6,0.5,3.3
                    c0.4,0.8,1,1.4,1.9,1.8c0.9,0.5,2,0.7,3.2,0.7c1.7,0,3.1-0.5,4-1.4s1.4-2.4,1.4-4.5V11.6H171v21c0,2.6-0.2,4.7-0.6,6.3
                    c-0.4,1.5-1.1,2.9-2,4.1c-1,1.2-2.1,2.2-3.5,3.1c-1.4,0.9-3,1.5-4.9,1.9c-1.9,0.4-3.9,0.7-6.1,0.7c-2.5,0-4.8-0.2-6.6-0.7
                    c-1.9-0.4-3.5-1.1-4.9-2c-1.4-0.9-2.5-1.9-3.4-3c-0.9-1.2-1.6-2.7-2.2-4.7c-0.3-1.2-0.5-3.2-0.5-5.7V11.6z M191.4,47.6v-36H209
                    c2.5,0,4.5,0.1,6.2,0.4c1.7,0.3,3.1,0.7,4.1,1.2c1.1,0.6,2,1.3,2.8,2.1c0.8,0.9,1.3,1.8,1.7,2.8c0.4,1,0.6,2.2,0.6,3.7
                    c0,1.8-0.3,3.3-0.9,4.5c-0.6,1.2-1.4,2.2-2.4,3c-0.7,0.6-1.7,1-2.8,1.3c2.3,0.9,3.9,1.9,4.7,2.9s1.3,2.9,1.3,5.4l0.2,5.7
                    c0,0.8,0.1,1.3,0.2,1.5c0.2,0.2,0.5,0.3,0.9,0.4v1.1h-12c-0.3-1-0.5-2.6-0.6-5l-0.1-2.7c0-1.2-0.2-2.2-0.6-2.8
                    c-0.4-0.7-0.9-1.2-1.7-1.5c-0.7-0.3-1.9-0.5-3.4-0.5h-4.6v12.5H191.4z M202.8,27.2h5.6c1.8,0,3.1-0.3,3.8-0.9
                    c0.7-0.6,1.1-1.5,1.1-2.5c0-1.1-0.4-1.9-1.1-2.5c-0.7-0.6-2-0.9-3.8-0.9h-5.8V27.2z M242.1,11.6h33.1V21h-10.7v26.6h-11.8V21h-10.7
                    V11.6z M327.7,38.9c-1.4,2.9-3.5,5.2-6.3,7s-6.3,2.7-10.4,2.7c-5.8,0-10.4-1.7-13.8-5.2c-3.4-3.5-5.2-8.1-5.2-13.8
                    c0-2.7,0.4-5.2,1.2-7.4s1.9-4,3.2-5.5s2.8-2.6,4.2-3.5c1.5-0.8,3-1.4,4.8-1.9c1.7-0.4,3.5-0.7,5.4-0.7c3.6,0,6.8,0.7,9.8,2.2
                    s5.3,3.7,6.9,6.5s2.4,6.1,2.4,9.8C329.8,32.8,329.1,36,327.7,38.9 M310.8,39.7c1.3,0,2.6-0.3,3.7-1c1.1-0.7,2-1.8,2.7-3.4
                    c0.7-1.6,1-3.5,1-5.7c0-1.6-0.2-3.1-0.5-4.3c-0.3-1.3-0.8-2.3-1.3-3.1s-1.3-1.4-2.2-2c-0.9-0.5-2-0.8-3.1-0.8
                    c-1.3,0-2.5,0.4-3.7,1.1s-2,1.9-2.7,3.4c-0.7,1.5-1,3.4-1,5.7c0,3.4,0.8,6,2.3,7.9C307.2,38.9,308.8,39.7,310.8,39.7z M349.2,47.6
                    v-36h11l12.4,19.3V11.6h11.3v36h-11.3l-12.1-18.8v18.8H349.2z"
        />
        <path
          className="st0"
          d="M38.7,24.6c0.5-0.2,1.2-0.6,1.9-0.8c0.4-0.1,0.8,0.1,1,0.4c0,0,2.2,4.6,4.5,9.8c2.5,5.8,5.1,12.3,5.1,12.4
                    c0.1,0.3,0,0.6-0.2,0.7c-0.3,0.2-0.6,0.1-0.8-0.1c0,0-3-5.2-4.1-6.9c-0.2-0.4-0.8-0.3-0.9,0.2c-0.2,0.9-0.3,2.4-0.8,3.3
                    c-0.1,0.2-0.4,0.3-0.6,0.2l0,0c-0.6-0.3-1-0.7-1.4-1.3l-0.1-0.2c-0.2-0.4-0.4-0.8-0.5-1.2l-0.3-1.1c0-0.1-0.1-0.3-0.2-0.4
                    c-0.1-0.3-0.3-0.8-0.5-1c-0.2-0.3-0.7-0.3-0.8,0l-0.4,0.9c-0.2,0.4-0.5,0.7-1,0.9c-0.3,0.1-0.4,0.1-0.6,0.1c-0.2,0-0.3,0.1-0.3,0.3
                    c-0.1,0.4-0.4,1.2-0.6,1.7c-0.1,0.3-0.5,0.4-0.7,0.2c-0.4-0.2-0.6-0.5-0.8-0.7c-0.6-0.5-1-1.2-1.2-2L34,38.3
                    c-0.1-0.4-0.2-0.7-0.4-1.1c-0.3-0.5-0.7-1.3-1-1.8c-0.3-0.4-0.8-0.4-0.9,0.1c-0.1,1-0.4,2.6-0.7,3.4c-0.2,0.5-0.6,0.4-0.8,0.2
                    c-0.1-0.1-0.4-0.6-0.5-0.7c-0.3-0.3-1-0.5-1.3,0.8c-0.2,1.1-0.7,2.8-1.1,3.6c-0.1,0.4-0.6,0.5-0.8,0.1c-0.4-0.7-0.9-1.8-1.2-2.3
                    c-0.2-0.4-0.6-0.7-1.1-0.7l-0.2,0c-0.5,0-1-0.3-1.2-0.8l-0.7-1.6c-0.1-0.2-0.2-0.3-0.4-0.4L21,36.6c-0.5-0.3-0.9-0.6-1.3-1
                    l-0.3-0.4c-0.5-0.7-1.3-0.6-1.5,0.3c-0.3,1.1-1.1,3.9-1.5,4.8c-0.1,0.3-0.5,0.4-0.7,0.2C15.2,40,15,39.2,14.5,39
                    c-0.3-0.2-0.7-0.1-0.8,0.2c-1,1.9-3.8,7.9-3.8,7.9c-0.2,0.3-0.6,0.4-0.8,0.2c-0.3-0.3-0.3-0.6-0.2-0.9c0,0,2.1-7.2,2.9-9.6
                    c0.9-2.6,2-5.6,2-5.6c0.2-0.5,0.5-0.9,1-1.1l0.7-0.3c0.4-0.2,0.7-0.5,0.9-0.9c0.5-1.2,1.5-3.5,2-4.7c0.1-0.3,0.4-0.5,0.7-0.6
                    c0.4-0.1,0.8-0.2,1.1-0.2c0.4-0.1,0.7-0.3,0.8-0.7l4.4-8c0.2-0.3,0.5-0.5,0.8-0.6l1-0.3c0.3-0.1,0.5-0.2,0.6-0.5l1.7-2.7
                    c0.2-0.3,0.6-0.3,0.7,0c0.9,1.3,2.6,4.7,4,7.2c1.2,2.3,2.6,5.3,3.2,6.4C37.8,24.6,38.2,24.8,38.7,24.6z"
        />
      </g>
    </svg>
  ),
}
