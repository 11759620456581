/* eslint-disable max-len */
const programName = 'Rizzo'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 470.81 191.84">
      <title>{programName}</title>
      <path className="cls-1" d="M106.5,189.44a5.82,5.82,0,0,1-5.81-5.43l-.14-1.87a221.36,221.36,0,0,1-.29-34c.74-10.34,4-27.65,5.39-35,1.27-6.77,4.07-30.49,5.41-41.89l.52-4.36a247.24,247.24,0,0,0,1.88-33.55c-.16-7.45-1.91-26.71-1.92-26.9a5.82,5.82,0,0,1,11.59-1c.08.8,1.8,19.84,2,27.69a260.22,260.22,0,0,1-2,35.2l-.51,4.34c-1.47,12.41-4.19,35.51-5.54,42.67s-4.52,24.06-5.22,33.74a211.1,211.1,0,0,0,.29,32.31l.15,2a5.83,5.83,0,0,1-5.41,6.21Z" />
      <path className="cls-1" d="M222.16,186.53a8.42,8.42,0,0,1-1.69-.17c-6.56-1.26-7.61-7.47-11.11-28l-.08-.43c0-.22-.07-.43-.11-.63-10.4,16.23-28.47,37.09-52.41,24.78-12.79-6.57-24.53-13-35.89-19.23C103.13,153.09,87,144.26,73,138.43q-11.4.52-24.48.6l-.3.26a23.8,23.8,0,0,1-5-.65l-1.69-.2c-13-1.5-14.16-7.69-14-10.28.23-5.53,5.27-8.49,15-8.79a106.68,106.68,0,0,1,13.66.84l.47.07A111,111,0,0,1,75.1,126c27.67-1.4,47.36-4.57,60-7.33C187.88,107.18,257.55,88.44,252.84,59c-4.12-25.74-51.21-32.78-132.59-19.81C47.66,50.74,11.6,78.33,12.28,85.78c.41,4.43,10.58,9.69,14.42,11.67a6.14,6.14,0,1,1-5.64,10.91C11.39,103.36,1,97.17.05,86.9c-2-21.53,53.29-49.5,118.27-59.85,91.78-14.62,141.12-4.53,146.65,30,6.24,39.05-60.84,59.15-127.29,73.66a338.73,338.73,0,0,1-39.58,6c9,4.61,18.6,9.85,28.67,15.37,11.29,6.19,23,12.59,35.6,19.08,13.77,7.08,26.49-2.12,42.51-30.74a6.74,6.74,0,0,1,6.87-4.5c5.76.51,7.29,6.17,9.64,20l.07.43c.79,4.65,1.56,9.17,2.26,12.74,1.13-1.79,2.36-3.81,3.58-5.79,2.2-3.6,4.94-8.06,8.15-13.08l2.13-2.85c2.61-3.08,6.6-4.81,11.52-5,6-.26,54.8,0,58.32,0a72,72,0,0,1,10.13.19c1.88.29,6.85,1.06,7.76,5.85.26,1.38.42,4.84-3.73,7.27-.6.35-.7.41-19.74,8.59l-16.39,7.06h32.48a6.14,6.14,0,1,1,0,12.28c-2.17,0-53.23,0-56.55,0-5.63,0-7.32-3.17-7.82-5-.91-3.36.69-6.28,4.75-8.68,1.26-.74,8.6-3.95,35-15.29-18.09-.07-40.08-.1-43.65.05a4.85,4.85,0,0,0-2.63.66L245.67,157c-3.09,4.82-5.64,9-7.9,12.67C230.72,181.14,227.41,186.53,222.16,186.53Z" />
      <path className="cls-1" d="M439.2,191.84c-6,0-11.38-1.59-15.37-4.64a17.08,17.08,0,0,1-6.64-11.13h0l-6.32,2.2c-10.14,3.52-15,5-22.25,5.22h-.82c-3.23,0-48.93,0-52.19,0-5.65,0-7.33-3.18-7.83-5-.91-3.36.69-6.28,4.74-8.67,1.28-.75,8.62-4,35.14-15.37-9.35,0-21.56,0-30.82,0h0a6.14,6.14,0,0,1,0-12.28c17.33-.06,45,0,45,0,.62,0,6.87-.06,9.78.28,1.72.2,6.94.81,8,5.72.23,1.1.64,4.89-3.72,7.43-.58.34-.69.4-20.08,8.74-4.85,2.09-10.57,4.54-16.05,6.91h28.77c5-.18,8.15-1,18.41-4.54l6.31-2.2c3.28-1.14,6.2-2.16,8.37-2.9l.52-.15a6.59,6.59,0,0,1,6.85,1.59c3,3.07,1.52,6.85.9,8.47a11.6,11.6,0,0,0-.57,1.69,5.15,5.15,0,0,0,2.08,4.27c2.83,2.17,8.36,2.72,13.77,1.37,4-1,13.44-4.13,13.46-11.21,0-2.06,0-7.51-13.15-11.35-3.56-1-14.41-2.2-30,.86A6.14,6.14,0,0,1,413,145.06c16.73-3.28,29.75-2.37,35.82-.6,18.23,5.32,22,15.54,22,23.18,0,11.05-8.34,19.47-22.77,23.08A36.62,36.62,0,0,1,439.2,191.84ZM425.8,173.1l-.34.1Z" />
      <path className="cls-1" d="M224.42,123.34c-.41,3.9-2.61,8-10.87,7.61-7.79-.33-10.29-5.91-10.18-8.51.19-4.45,5-7.68,10.87-7.61C221.59,114.92,224.76,120,224.42,123.34Z" />
    </svg>
  ),
}
