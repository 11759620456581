/* eslint-disable max-len */
const programName = 'Staples'
export default {
  programName,
  logo: (
    <svg className="staples" x="0px" y="0px" viewBox="0 0 102.4 36.1">
      <title>{programName}</title>
      <g id="Layer_1-2">
        <path
          className="st0"
          d="M7.7,28.3L7.7,28.3c-7,0-7.7-4.5-7.7-6.5h3.7c0,0.9,0.4,3.1,3.8,3.1c0.9,0,1.7-0.1,2.5-0.5s1.2-1.2,1.2-2
        C11.2,21,10,20.6,8,20l-2-0.5c-2.9-0.7-5.7-1.4-5.7-5.6c0-1.4,0.4-2.7,1.3-3.8c1.1-1.5,3-2.2,5.6-2.2c2.1-0.1,4.2,0.7,5.6,2.2
        c0.9,1.1,1.4,2.5,1.5,4h-3.7c-0.1-0.7-0.3-2.8-3.6-2.8c-0.7,0-1.4,0.1-2.1,0.5c-0.6,0.4-1,1-1,1.8c0,1.5,1.2,1.8,1.9,2l4.4,1.2
        c2.5,0.6,4.7,1.7,4.7,5.2c0.1,2.2-1,4.3-3,5.4C10.6,28,9.2,28.3,7.7,28.3"
        />
        <polygon className="st0" points="23.9,27.8 20,27.8 20,11.9 14.6,11.9 14.6,8.4 29.3,8.4 29.3,11.9 23.9,11.9 " />
        <polygon
          className="st0"
          points="85.5,27.8 71.7,27.8 71.7,8.4 85,8.4 85,11.9 75.5,11.9 75.5,15.9 84.3,15.9 84.3,19.4 75.5,19.4
        75.5,24.3 85.5,24.3"
        />
        <path className="st0" d="M38.7,27.8l-1.2-4h-6.6l-1.3,4h-4L32,8.4h4.4l6.4,19.4H38.7z M32,20.4h4.5l-2.3-7.5L32,20.4z" />
        <path
          className="st0"
          d="M47.5,27.8h-3.8V8.4H52c3.5,0,5.6,2.4,5.6,6.2c0,1.3-0.4,2.6-1.1,3.7c-1,1.6-2.8,2.5-4.7,2.4h-4.3V27.8z
         M47.5,17.4h3.4c2.8,0,2.8-2.1,2.8-3c0-1.9-1-2.6-3.3-2.6h-2.9V17.4z"
        />
        <path
          className="st0"
          d="M100.3,8.7h0.6c0.3,0,0.5,0.1,0.5,0.4c0,0.2-0.1,0.4-0.4,0.4l0,0l0.4,0.6h-0.3l-0.4-0.6h-0.2v0.6h-0.2V8.7z
         M100.5,9.3h0.2c0.2,0,0.4,0,0.4-0.2c0-0.2-0.2-0.2-0.3-0.2h-0.3V9.3z"
        />
        <path
          className="st0"
          d="M58.4,25.6V11.5c0-2,1.7-3.6,3.7-3.6c1.9,0,3.5,1.4,3.7,3.2l1.2,6.3l-3.5,0.7l-1.3-6.7c0,0,0-0.1-0.1-0.1l0,0
        L62,11.4v12.8h8.8v3.5H60.3C59.2,27.7,58.4,26.7,58.4,25.6"
        />
        <path
          className="st0"
          d="M102,9.4c0,0.7-0.6,1.3-1.3,1.3c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3l0,0C101.4,8.1,102,8.7,102,9.4
         M100.7,8.3c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1s1.1-0.5,1.1-1.1l0,0C101.8,8.8,101.3,8.3,100.7,8.3"
        />
        <path
          className="st0"
          d="M93.8,28.3L93.8,28.3c-7,0-7.7-4.5-7.7-6.5h3.7c0,0.9,0.4,3.1,3.8,3.1c0.9,0,1.7-0.1,2.5-0.5s1.2-1.2,1.2-2
        c0-1.4-1.2-1.8-3.2-2.4l-1.9-0.5c-3-0.7-5.7-1.4-5.7-5.7c0-1.4,0.4-2.7,1.3-3.8c1-1.4,3-2.1,5.5-2.1c2.1-0.1,4.2,0.7,5.6,2.2
        c0.9,1.1,1.4,2.5,1.5,4h-3.7c-0.1-0.8-0.3-2.9-3.6-2.9c-0.7,0-1.4,0.2-2.1,0.5c-0.6,0.4-1,1-1,1.8c0,1.5,1.2,1.8,1.9,2l4.4,1.2
        c2.4,0.6,4.8,1.7,4.8,5.1c0.1,2.2-1,4.3-3,5.4C96.7,27.9,95.3,28.3,93.8,28.3"
        />
      </g>
    </svg>),
}
