/* eslint-disable max-len */
const programName = 'Ideal Of Sweden'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 192 13">
      <title>{programName}</title>
      <path fill="currentColor" d="M0 11.74h2.3V.22H0v11.52zM12.04.22H7.43v11.52h4.65c3.43 0 6.04-2.2 6.04-5.72 0-3.5-2.6-5.8-6.08-5.8zm-.1 9.4h-2.2V2.34h2.2c2.05 0 3.92 1.17 3.92 3.68-.04 2.56-1.91 3.6-3.91 3.6zM22.72 11.74h8.3V9.62h-6V6.93h4.92V4.77h-4.91V2.34h5.9V.22h-8.2v11.52zM42.28.22h-2.61l-4.6 11.52h2.43l.87-2.3h5.2l.88 2.3h2.47L42.28.22zm-3.13 7.14l.95-2.55.74-1.86.09-.18h.04l.09.18.74 1.86.95 2.55h-3.6zM51.23 11.74H59V9.62h-5.47V.22h-2.3v11.52zM76.34 0c-3.48 0-6.04 2.43-6.04 5.98 0 3.55 2.56 5.98 6.04 5.98s6.08-2.43 6.08-5.98c0-3.55-2.6-5.98-6.08-5.98zm0 9.8c-2.13 0-3.74-1.48-3.74-3.82s1.61-3.86 3.74-3.86 3.74 1.48 3.74 3.86c.04 2.38-1.61 3.81-3.74 3.81zM95.1 2.34V.22h-8.07v11.52h2.3v-4.5h4.52V5.1h-4.52V2.34h5.78zM106.4 8.84c.57 2.55 2.83 3.16 4.83 3.16 2.74 0 4.34-1.17 4.34-3.42 0-2.51-1.95-3.16-3.65-3.51l-.56-.13c-1.3-.26-2.26-.56-2.26-1.56 0-.82.57-1.3 1.83-1.3 1.34 0 2.08.56 2.38 1.52l2.22-.57C114.93 1 113.27.1 110.97.1c-2.56 0-4.22 1.17-4.22 3.38 0 2.25 1.74 2.99 3.26 3.38l.26.04c1.44.35 3 .35 3 1.69 0 .95-.78 1.43-2.04 1.43-1.44 0-2.43-.6-2.6-1.73l-2.22.56zM129.96 11.74h2.47L135.91.22h-2.26l-2.04 7.14c-.22.74-.35 1.26-.35 1.26h-.04L128.96.48h-2.22l-2.3 8.14h-.05l-.08-.3c-.18-.52-.48-1.7-.87-3L122 .23h-2.56l3.48 11.52h2.6l1.4-4.8.25-.92c.3-1.17.48-1.9.53-1.9h.08c.05.13.3 1.2.74 2.68l1.44 4.94zM140.3 11.74h8.25V9.62h-5.95V6.93h4.87V4.77h-4.87V2.34h5.9V.22h-8.2v11.52zM158.02.22h-4.65v11.52h4.65c3.44 0 6.04-2.2 6.04-5.72.05-3.5-2.56-5.8-6.04-5.8zm-.08 9.4h-2.22V2.34h2.22c2.04 0 3.9 1.17 3.9 3.68-.04 2.56-1.9 3.6-3.9 3.6zM168.71 11.74h8.26V9.62H171V6.93h4.87V4.77h-4.87V2.34h5.91V.22h-8.2v11.52zM189.65 11.74l-5.51-7.71h-.05l.05 1.56v6.11h-2.3V.22h2.34l5.52 7.58h.04l-.04-1.6V.17h2.3V11.7h-2.35v.04z" />
    </svg>
  ),
}
