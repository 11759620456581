/* eslint-disable max-len */
const programName = 'Icebug'
export default {
  programName,
  logo: (
    <svg className="icebug" viewBox="0 0 679 128">
      <title>{programName}</title>
      <g fill="none" fillRule="evenodd">
        <path fill="black" d="M150 103.178h24.823V23H150zM213.794 23h53.17v21.64h-47.806v36.564H266.8v21.974h-50.497c-10.058 0-22.303-3.188-22.303-22.311V44.473C194 34.405 199.194 23 213.794 23M288.34 24h66.75v21.14h-41.592v9.39h41.263v18.282h-41.603v9.395h41.435v21.805H288L288.34 24M401.32 81.373H429V70.465h-27.68v10.908zm0-26.839H429V44.473h-27.68v10.061zM376.334 23h60.549c7.719 0 16.776 3.86 16.776 14.928v12.919c0 5.868-6.536 10.228-6.536 10.228s6.536 2.855 6.536 11.905c0 9.062-.171 11.914-.171 15.44 0 3.522-.832 14.928-17.777 14.928H376L376.334 23zM473 23h25.169v58.373h26.832V23h25.159v64.247c0 6.875-4.859 15.937-17.779 15.937h-41.094c-4.87 0-18.287-2.181-18.287-17.115V23M646.324 24v21.303h-52.167v36.731h28.352V55.585h24.824v27.627c0 4.865-.511 20.802-20.635 20.802h-37.405c-9.057 0-20.293-3.526-20.293-21.142V43.556c0-6.78 2.095-19.533 19.527-19.533 17.433 0 57.797-.023 57.797-.023M666.382 33.548h3.601v-1.376h-3.494l-.107 1.376zM663.21 29h8.138c1.173 0 2.181.737 2.181 2.166 0 1.426-.266 2.909-1.116 3.124 0 0 1.059.952 1.059 1.796v4.021h-3.489v-4.021h-3.494v3.913H663L663.21 29z" />
        <path d="M678.794 34.9c0-6.023-4.881-10.9-10.896-10.9C661.883 24 657 28.877 657 34.9c0 6.015 4.883 10.894 10.898 10.894s10.896-4.879 10.896-10.894zm-10.896 8.778a8.772 8.772 0 0 1-8.773-8.778c0-4.851 3.926-8.784 8.773-8.784a8.785 8.785 0 0 1 8.783 8.784c0 4.851-3.934 8.778-8.783 8.778zM127.856 63.927C127.856 28.617 99.233 0 63.93 0 28.62 0 0 28.617 0 63.927c0 35.306 28.62 63.927 63.93 63.927 35.303 0 63.926-28.621 63.926-63.927" fill="#000" />
        <path fill="white" d="M104.158 90.152c0-4.156-4.401-5.25-6.236-5.25h-9.786l-.12-10.523 5.256-2.69s.25.369 2.078 3.911c1.836 3.551 4.407 2.574 6.482 1.716 2.082-.858 4.653-2.449 4.653-2.449l-3.546-6.355-1.347.731s-.859-1.343-2.566-4.036c-1.716-2.687-4.893-2.568-6.363-1.831-1.464.733-4.647 2.568-4.647 2.568V60.68c0-1.462-.246-2.934-.246-2.934s3.062-.737 6.856-2.073c3.783-1.348 3.296-5.873 3.053-6.972-.244-1.102-1.23-3.545-1.23-3.545l1.592-.615-2.198-6.969s-3.058.849-5.748 1.957c-2.691 1.101-3.302 3.786-2.572 5.627.737 1.829 1.467 4.398 1.467 4.398l-4.525 1.591c-3.058-2.815-6.235-2.693-6.235-2.693v-6.355c0-8.563-4.151-9.299-4.151-9.299v-4.582h4.089c4.706 0 5.323-3.732 5.323-5.318V18h-7.34v1.84h-3.789c-3.789 0-5.748 2.814-5.748 5.255v7.216h-5.748v-7.216c0-2.441-1.957-5.255-5.741-5.255h-3.796V18h-7.341v4.898c0 1.586.617 5.318 5.318 5.318h4.101v4.582s-4.158.736-4.158 9.299v6.355s-3.177-.122-6.236 2.693l-4.53-1.591s.737-2.569 1.47-4.398c.733-1.841.122-4.526-2.566-5.627-2.687-1.108-5.75-1.957-5.75-1.957l-2.201 6.969 1.586.615s-.975 2.443-1.215 3.545c-.25 1.099-.742 5.624 3.052 6.972 3.789 1.336 6.847 2.073 6.847 2.073s-.243 1.472-.243 2.934v5.264s-3.177-1.835-4.647-2.568c-1.47-.737-4.647-.856-6.357 1.831a1102.737 1102.737 0 0 1-2.569 4.036l-1.348-.731L21 74.867s2.565 1.591 4.638 2.449c2.084.858 4.653 1.835 6.491-1.716 1.832-3.542 2.073-3.911 2.073-3.911l5.261 2.69-.116 10.523h-9.79c-1.835 0-6.236 1.094-6.236 5.25v4.157h-1.59v7.34h4.278c1.589 0 5.749.125 5.749-4.644v-4.403h8.928s2.079 6.597 10.025 6.597h11.377V60.68h3.307v38.519h11.368c7.955 0 10.03-6.597 10.03-6.597h8.926v4.403c0 4.769 4.159 4.644 5.748 4.644h4.279v-7.34h-1.588v-4.157" />
      </g>
    </svg>
  ),
}
