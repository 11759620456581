/* eslint-disable max-len */
const programName = 'Casall'
export default {
  programName,
  logo: (
    <svg className="casall" viewBox="0 0 495.56 170.38">
      <title>{programName}</title>
      <path className="line" d="M76.65 75.6c-8.4-7.5-16.12-11.7-27.83-11.7-18.12 0-32 16.34-32 45.28 0 28.5 12.15 46.84 32.7 46.84 11.27 0 18.78-4.65 26.73-12.38l9.93 10.6c-9.72 9.95-21 16.14-37.33 16.14-25.4 0-48.82-19.44-48.82-59.87 0-42.8 25.63-61 49.93-61a53.92 53.92 0 0 1 36.67 13.7L76.7 75.6m92.7 92.35V153.8c-8.17 8.85-19.66 16.58-36.23 16.58-18.12 0-37.1-9.06-37.1-37.12 0-26.07 21.4-37.1 44.4-37.1 11.7 0 22.07 2.86 28.93 6.62v-13.7c0-17.22-8.17-25.62-25.18-25.62-12.15 0-22.1 3.3-31.6 8.84l-6.18-13.48c10.16-5.52 23.86-9.27 38.88-9.27 25 0 40.65 10.6 40.65 37.55v80.85H169.4m0-51.9c-7.5-4.2-15.68-6.64-29.82-6.64-17 0-27.17 8.2-27.17 22.8 0 17.26 9.5 24.78 23.7 24.78 15 0 26.3-7.73 33.37-17.45V116zm70.1 54.33c-15.9 0-29.8-6-40.86-14.8l9.73-12.15c9.27 8.17 20.1 12.8 31.58 12.8 15.9 0 23.42-6.62 23.42-17.9 0-9.5-6.85-14.8-27.4-23.63-23.2-9.94-32.9-17-32.9-35.57 0-17.67 15.23-29.6 36.22-29.6 15.9 0 28.2 5.08 37.1 12.37l-9.3 12.15c-9.1-7.07-16.6-10.38-28.98-10.38-12.16 0-19.23 6-19.23 14.36 0 9.72 6 13.7 25.62 22.53 21.86 9.94 34.68 16.35 34.68 37.1 0 22.77-19.65 32.7-39.75 32.7m124.36-2.4V153.8c-8.14 8.85-19.63 16.58-36.2 16.58-18.1 0-37.1-9.06-37.1-37.12 0-26.07 21.43-37.1 44.4-37.1 11.7 0 22.1 2.86 28.94 6.62v-13.7c0-17.22-8.17-25.62-25.18-25.62-12.15 0-22.1 3.3-31.6 8.84l-6.17-13.48c10.14-5.52 23.83-9.27 38.86-9.27 25 0 40.64 10.6 40.64 37.55v80.85h-16.6m0-51.9c-7.5-4.2-15.67-6.64-29.8-6.64-17 0-27.17 8.2-27.17 22.8 0 17.26 9.5 24.78 23.64 24.78 15 0 26.26-7.73 33.33-17.45V116zm38.74 51.9V9.27L419.14 0v167.94h-16.57m39.65 0V9.28L458.8 0v167.94h-16.6m41.84-94.3a11.56 11.56 0 1 1 11.56-11.57 11.56 11.56 0 0 1-11.56 11.56zm0-21.6a10 10 0 1 0 9.9 10 10 10 0 0 0-9.9-10.04zm2.86 16.46l-3-5.26h-1.95v5.26h-1.8V55.6h4.4a3.94 3.94 0 0 1 4.06 3.86 3.57 3.57 0 0 1-2.7 3.54l3.1 5.5h-2.1zm-2.5-11.3H482v4.5h2.4a2.26 2.26 0 0 0 2.43-2.23 2.28 2.28 0 0 0-2.47-2.28z" />
    </svg>
  ),
}
