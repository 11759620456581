/* eslint-disable max-len */
const programName = 'Hope'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 358.2 71.6">
      <title>{programName}</title>
      <path d="M358.2 15.1V.6h-60.6V71h60.6V56.5h-34V42.2h34V28.3h-34V15.1zM44.5 28.6H26.6V.6H0V71h26.6V43.8h17.7V71h26.8V.6H44.5zM252 .6h-53.5V71h26.6V43H252c9.3 0 17.9-2.5 17.9-17.3v-7.8C270 4.1 262.7.6 252 .6zM235.1 29h-9.9V15h10.5c5.2 0 7.7 2.7 7.7 6.9-.1 7.1-6.5 7.1-8.3 7.1zM152.8 0h-35.3c-11.1 0-18.2 3.8-18.2 17.4v36.9c0 13.9 7.6 17.3 18.2 17.3h35.4c11 0 17.7-3.8 17.7-17.3V17.4c0-14.1-7.4-17.4-17.8-17.4zM144 49.1c0 6.2-3.6 8.2-7.1 8.2h-3.6c-6.9 0-7.3-6.4-7.3-8.8V23.9c0-2.2 0-9.4 7.4-9.4h3.6c5 0 7 3.8 7 8.6v26z" className="st0" />
    </svg>
  ),
}
