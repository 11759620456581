/* eslint-disable max-len */
const programName = 'Ecco'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 123.71 33.5">
      <title>{programName}</title>
      <path d="M40.72 4.46a14.25 14.25 0 015.18-.93 15 15 0 0111.63 5.54 2.63 2.63 0 01.6 1.63 2.44 2.44 0 01-1 2v.05a2.6 2.6 0 01-3.56-.46 9.63 9.63 0 00-1.91-1.8 9.83 9.83 0 00-5.72-1.83 9.36 9.36 0 00-3.4.62 10 10 0 00-5 4 9.82 9.82 0 0016 11.32 2.58 2.58 0 114 3.22 15 15 0 01-24.36-17.35 15.35 15.35 0 017.51-6.09M71.72 4.46a14.32 14.32 0 015.18-.93 15 15 0 0111.64 5.54 2.64 2.64 0 01.59 1.63 2.46 2.46 0 01-1 2 2.61 2.61 0 01-1.55.51 2.57 2.57 0 01-2-1 9.88 9.88 0 00-7.65-3.63 9.47 9.47 0 00-3.4.62 10 10 0 00-5 4 9.86 9.86 0 002.49 13 9.73 9.73 0 005.86 1.95 9.91 9.91 0 007.65-3.68 2.58 2.58 0 114 3.22 15 15 0 01-24.36-17.34 15.35 15.35 0 017.5-6.09M96.34 27.89a15 15 0 0024.38-17.35 2.62 2.62 0 00-2.2-1.23 2.58 2.58 0 00-2.57 2.58 2.91 2.91 0 00.43 1.46 9.71 9.71 0 01.65 9 10 10 0 01-3.13 4 9.82 9.82 0 01-13.53-1.66 9.63 9.63 0 01-2.18-6.17 9.81 9.81 0 019.83-9.83 9.38 9.38 0 013.4.62 2.581 2.581 0 001.77-4.85 14.22 14.22 0 00-5.17-.92A15 15 0 0096.34 27.9M9.8 4.46a2.58 2.58 0 00.89 5 2.42 2.42 0 00.88-.16 9.52 9.52 0 013.41-.62 9.92 9.92 0 015.75 1.88L5.97 22.39a9.81 9.81 0 01.65-9 2.87 2.87 0 00.44-1.46 2.57 2.57 0 00-4.76-1.35 15 15 0 0024.36 17.34 2.53 2.53 0 00.57-1.62 2.57 2.57 0 00-2.58-2.58 2.53 2.53 0 00-2 1A9.92 9.92 0 0115 28.39a9.81 9.81 0 01-5.86-2l17.05-13.7a2.47 2.47 0 001-2 2.61 2.61 0 00-.6-1.63 14.93 14.93 0 00-11.64-5.55 14.33 14.33 0 00-5.18.93M117.91 3.2a2.59 2.59 0 112.59 2.59 2.59 2.59 0 01-2.59-2.59m-.62 0A3.21 3.21 0 10120.5 0a3.21 3.21 0 00-3.21 3.2" />
      <path d="M119.48 1.62h.68c.68 0 1.49 0 1.49.86a.73.73 0 01-.66.74.47.47 0 01.34.33l.51 1.24h-.71l-.38-1a.39.39 0 00-.44-.33h-.21v1.32h-.63V1.61zm.63 1.36h.21c.31 0 .67 0 .67-.45s-.35-.42-.67-.42h-.21v.86z" />
    </svg>
  ),
}
