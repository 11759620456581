/* eslint-disable max-len */
const programName = 'Zwilling'
export default {
  programName,
  logo: (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <title>{programName}</title>
      <path d="M0.5 39.4239V0.5H39.4239V39.4239H0.5Z" stroke="#C6BEB8" />
      <path d="M32.9662 13.8423V6.81128H34.8V4.32114H30.4253V11.231H26.9662C26.1746 10.9662 26 9.34367 25.9802 9.13522C27.0535 8.78593 27.8309 7.77748 27.8309 6.58593C27.8309 5.10424 26.6309 3.90424 25.1493 3.90424C23.6676 3.90424 22.4676 5.10424 22.4676 6.58593C22.4676 7.77748 23.245 8.78593 24.3183 9.13522C24.2985 9.34367 24.1239 10.9662 23.3324 11.231H16.5915C15.8 10.9662 15.6253 9.34649 15.6056 9.13522C16.6788 8.78593 17.4563 7.77748 17.4563 6.58593C17.4563 5.10424 16.2563 3.90424 14.7746 3.90424C13.2929 3.90424 12.0929 5.10424 12.0929 6.58593C12.0929 7.77748 12.8704 8.78593 13.9436 9.13522C13.9239 9.34367 13.7493 10.9662 12.9577 11.231H6.9577V18.2169H5.1239V20.7437H9.49855V13.8423H10.9802L13.4845 21.0056L8.29855 26.1916V33.4254H6.32108V35.9296H10.9098V27.338L14.8281 23.4197L18.6394 27.231V33.4254H16.6985V35.9296H23.2281V33.4254H21.2873V27.2338L25.0985 23.4225L29.014 27.338V35.9296H33.6028V33.4254H31.6253V26.1916L26.4366 21.0056L28.9408 13.8423H32.9662V13.8423ZM19.9633 24.8873L16.0619 21.0056L18.5662 13.8423H21.3549L23.8591 21.0056L19.9633 24.8873Z" fill="#C6BEB8" />
    </svg>
  ),
}
