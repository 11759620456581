/* eslint-disable max-len */
const programName = 'Edblad'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 1003.04 117.84">
      <title>{programName}</title>
      <path d="M658.5,525.89V408.09s51.3,0,69.15.1c8.27.07,16.34,1.53,23.79,5.39,8.65,4.49,13.83,11.52,15.22,21.22a29.7,29.7,0,0,1-2.32,17.34,22.9,22.9,0,0,1-8.74,9.68,72.84,72.84,0,0,1-8.18,4,34.61,34.61,0,0,1,12.47,6,25.21,25.21,0,0,1,11,21.7,30.29,30.29,0,0,1-4.24,16.21c-3.49,5.67-8.56,9.4-14.6,11.93-7.79,3.26-16,4.3-24.34,4.32C709.85,525.92,658.5,525.89,658.5,525.89Zm13.72-12.74s42.35,0,55.84,0c6,0,11.82-.82,17.28-3.39,7.35-3.46,11.52-9.13,11.45-17.41-.07-7.38-4.14-12.24-10.47-15.41a39.34,39.34,0,0,0-17.7-3.83c-13.48-.05-56.4,0-56.4,0Zm0-53.25s40.41.09,53,0a38.3,38.3,0,0,0,17.33-3.68c9.62-4.84,12.1-13.47,9.25-22.67-1.69-5.44-5.89-8.56-11.1-10.42s-10.88-2.31-16.47-2.32c-12,0-52,0-52,0Z" transform="translate(-283.98 -408.08)" />
      <path d="M465.06,525.9V408.14s43.17-.08,57.05,0a69.83,69.83,0,0,1,28.45,5.95c13.75,6.22,23.6,16.21,28.79,30.5a65.38,65.38,0,0,1,1.78,39C577.28,499,568.3,510.48,554.33,518a64.59,64.59,0,0,1-25.05,7.35c-3.78.34-7.59.5-11.39.52C505.66,525.94,465.06,525.9,465.06,525.9ZM478.79,513s37.24,0,48.37-.46a44.08,44.08,0,0,0,17.49-4.86c11.37-5.53,19.12-14.28,22.61-26.45a49.9,49.9,0,0,0-1.06-31.71C562,438.2,554,430.38,543,425.51a58.76,58.76,0,0,0-24-4.67c-8.13,0-40.25.06-40.25.06Z" transform="translate(-283.98 -408.08)" />
      <path d="M1168.8,525.9V408.14s43.18-.08,57,0a69.91,69.91,0,0,1,28.46,5.95c13.75,6.22,23.59,16.21,28.79,30.5a65.45,65.45,0,0,1,1.77,39C1281,499,1272,510.48,1258.08,518a64.69,64.69,0,0,1-25.06,7.35c-3.78.34-7.59.5-11.38.52C1209.4,525.94,1168.8,525.9,1168.8,525.9ZM1182.53,513s37.24,0,48.37-.46a44,44,0,0,0,17.49-4.86c11.37-5.53,19.13-14.28,22.61-26.45a49.84,49.84,0,0,0-1.06-31.71c-4.21-11.36-12.21-19.18-23.17-24.05a58.76,58.76,0,0,0-24-4.67c-8.13,0-40.25.06-40.25.06Z" transform="translate(-283.98 -408.08)" />
      <path d="M284,525.8V408.1l101,0-.17,12.76H297.71v40.59h63.93v12.84h-64v38.9h87.22V525.8Z" transform="translate(-283.98 -408.08)" />
      <path d="M1116.83,525.83c-.45,0-15.46-.05-15.46-.05l-12-20.59H1008l-12.1,20.73-15.38-.08,68.29-117.76S1116.65,525.39,1116.83,525.83ZM1082,492.23l-33.55-56.56-32.75,56.56Z" transform="translate(-283.98 -408.08)" />
      <path d="M844.45,408.19h13.6V513.13h80.29v12.69H844.45Z" transform="translate(-283.98 -408.08)" />
    </svg>
  ),
}
