/* eslint-disable max-len */
const programName = 'Cellbes'
export default {
  programName,
  logo: (
    <svg className="cellbes" x="0px" y="0px" viewBox="0 0 270 45.5">
      <title>{programName}</title>
      <path
        className="st0"
        d="M251.7,44.2c-4.1,0.1-8.1-1-11.6-3.2c-3.3-2-5.4-5.6-5.5-9.5v-1h6.5l0,0.9c0.3,4.9,6.8,6.7,11.1,6.7
           c3.1-0.1,10.4-1,10.6-6.9c0-4.6-3.6-5.4-9.4-6.1c-0.6-0.1-1.3-0.1-1.9-0.2c-8-0.8-16.1-2.8-16.1-11.5c0-8.3,8.1-12.7,16.1-12.7
           c3.8-0.1,7.5,0.8,10.8,2.7c3.6,2.2,5.6,5.4,5.7,9.3v1h-6.3l-0.1-0.8c-0.1-1-1-5.9-10.1-5.9c-3.3,0-9.6,1.3-9.6,6.4
           c0,4.3,3.5,5,9,5.6l1.5,0.2c7.1,0.8,16.9,1.9,16.9,12.1c0,9.2-9.2,12.7-17.1,12.9L251.7,44.2L251.7,44.2z M21.9,44.3
           c-12.4,0-21.5-9.2-21.5-21.8c0-6.3,2.2-11.9,6.2-15.9c4-3.9,9.4-6,14.9-5.9h0.3c6.3-0.2,12.3,2.7,16.2,7.6l0.1,0.1L38,8.5l-4.8,4.3
           l0-0.1c-2.5-3.6-6.4-5.6-11.3-5.6h-0.3c-3.7-0.1-7.2,1.3-9.8,3.8c-3,3.1-4.6,7.2-4.5,11.5c0,8.9,6.1,15.4,14.5,15.4
           c5.2,0,9.4-2.3,11.8-6.4l0.1-0.1l0.1,0.1l5.3,3.6l0,0.1c-1.8,3-4.4,5.4-7.5,7C28.5,43.6,25.2,44.3,21.9,44.3z M77.7,43.7H50.2V1.4
           h26.8v6H57.5v11.5h14.8v6H57.5v12.6h20.2V43.7z M226.8,43.7h-27.5V1.4h26.8v6h-19.6v11.5h14.8v6h-14.8v12.6h20.2V43.7z M114.3,43.7
           H89.2V1.4h7.3v36.2h17.9V43.7z M148.7,43.7h-25.2V1.4h7.3v36.2h17.9V43.7z M176.7,43.7h-18.4V1.4H175c8.7,0,13.6,4.3,13.6,11.9
           c0.1,3.3-1.4,6.4-4.2,8.3c3.9,1.8,6,5.2,6,9.8C190.4,38.9,185.2,43.7,176.7,43.7L176.7,43.7z M164.9,37.8h11.5
           c2.2,0,7.5-0.6,7.5-6.4c0.1-1.5-0.4-3-1.4-4.2c-1.4-1.4-3.9-2.2-7.2-2.2h-10.4L164.9,37.8z M164.9,19.2h9.8c5,0,7.5-1.9,7.5-5.9
           c0-4.2-2.4-6.1-7.5-6.1h-9.8V19.2z"
      />
    </svg>
  ),
}
