/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable max-len */
const programName = 'Gymgrossisten'
export default {
  programName,
  logo: (<svg viewBox="0 0 253 32">
    <title>{programName}</title>
    <defs>
      <path id="a" d="M.286.56h10.646v31.359H.286z" />
      <path id="c" d="M.556.56h16.821v31.359H.557z" />
      <path id="e" d="M.124.56h21.144v31.36H.124z" />
      <path id="g" d="M.228.56h22.388v31.359H.228z" />
      <path id="i" d="M0 .56h19.96v31.359H0z" />
      <path id="k" d="M.463.56h20.08v31.359H.463z" />
      <path id="m" d="M.363.56h20.08v31.359H.363z" />
      <path id="o" d="M.103.56h20.079v31.359H.102z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="black" d="M74.637 7.32c.18-1.021.786-1.571 1.844-1.571h8.201a1.62 1.62 0 0 0 1.594-1.342L86.95.561H75.277a5.67 5.67 0 0 0-5.578 4.69l-4.015 22.94C65.336 30.178 66.86 32 68.873 32h7.799c2.75 0 5.102-1.98 5.58-4.697l2-11.423a.812.812 0 0 0-.798-.954h-6.087c-.786 0-1.458.568-1.595 1.344l-.67 3.844h3.234l-.894 5.127c-.186 1.06-.254 1.572-1.272 1.572h-4.967L74.637 7.32zM117.69 25.359c-.188 1.061-.834 1.572-1.865 1.572h-4.269l3.504-19.728c.182-1.022.835-1.572 1.865-1.572h4.28l-3.515 19.728zm9.037-20.985c.363-1.986-1.176-3.813-3.21-3.813h-7.72c-2.78 0-5.16 1.98-5.644 4.697l-4.078 22.933-.004.02c-.338 1.982 1.202 3.789 3.23 3.789h7.696c2.78 0 5.16-1.979 5.643-4.694l4.087-22.932z" />
      <g transform="translate(164.366)">
        <path fill="black" d="M10.932.56H6.374a.8.8 0 0 0-.784.67L.286 31.918h3.892c.772 0 1.432-.564 1.566-1.34L10.932.56z" />
      </g>
      <g transform="translate(196.341)">
        <path fill="black" d="M17.377.56H2.19a.819.819 0 0 0-.809.666L.556 5.735h5.251L1.13 31.919h4.03c.797 0 1.48-.564 1.618-1.34l4.437-24.844h3.88c.8 0 1.484-.57 1.619-1.348L17.377.56z" />
      </g>
      <g transform="translate(211.488)">
        <path fill="black" d="M20.58 4.434L21.269.56H6.386a.814.814 0 0 0-.803.67L.124 31.92h14.21a1.63 1.63 0 0 0 1.61-1.342l.675-3.833H6.315l1.433-8.035h6.61c.794 0 1.473-.569 1.605-1.346l.639-3.751H8.65l1.394-7.84h8.928a1.63 1.63 0 0 0 1.609-1.339" />
      </g>
      <g transform="translate(230)">
        <path fill="black" d="M22.617.56h-4.67a.814.814 0 0 0-.802.67l-2.934 16.5h-.237L10.856.56h-4.4a.814.814 0 0 0-.804.67L.228 31.919h3.994c.792 0 1.468-.565 1.605-1.34L8.64 14.671h.197l3.034 16.583c.07.385.408.665.802.665h3.022c.792 0 1.469-.565 1.606-1.34L22.617.56z" />
      </g>
      <path fill="black" d="M41.512.561h-4.635a1.62 1.62 0 0 0-1.42.841l-6.08 11.067L27.883.561h-4.633a.811.811 0 0 0-.797.955l3.118 17.437L23.289 32h3.956c.786 0 1.46-.565 1.596-1.341l2.062-11.706L41.513.561zM66.195.561h-5.89c-.474 0-.792.236-.994.708l-7.915 19.477L50.406.56h-6.003a.815.815 0 0 0-.803.671L38.146 32h3.833c.792 0 1.47-.565 1.606-1.342L46.62 13.53h.276L47.703 32h2.5c.502 0 .953-.305 1.138-.77l7.04-17.7h.276L55.393 32h3.88a1.63 1.63 0 0 0 1.606-1.342L66.195.56z" />
      <g>
        <path fill="black" d="M17.517.56H9.743c-2.775 0-5.15 1.972-5.636 4.678L.05 28.12c-.352 1.983 1.188 3.8 3.222 3.8h7.879c2.777 0 5.155-1.976 5.637-4.685L18.81 15.84a.812.812 0 0 0-.805-.95h-6.15c-.795 0-1.475.565-1.612 1.34l-.678 3.834h3.268l-.903 5.114c-.188 1.058-.256 1.568-1.285 1.568H5.641L9.096 7.302c.182-1.019.794-1.567 1.863-1.567h8.591c.255 0 .448-.23.403-.478L19.093.56h-1.576z" />
      </g>
      <path fill="black" d="M100.191 7.32l-1.073 6.054c-.177 1.022-.815 1.572-1.822 1.572h-3.831l1.62-9.197h4.395c1.006 0 .889.55.711 1.572m2.634-6.76H91.39a.803.803 0 0 0-.787.672L85.268 32h3.916c.776 0 1.44-.566 1.575-1.342l1.805-10.524h2.67L95.917 32h4.401a.807.807 0 0 0 .797-.882l-.65-11.456c2.001-.825 3.269-2.554 3.726-5.187l1.783-10.092c.352-1.992-1.156-3.822-3.148-3.822" />
      <g transform="translate(125.659)">
        <path fill="black" d="M17.275.56h-6.178c-3.679 0-6.044 1.96-6.7 5.645l-1.55 8.667a1.617 1.617 0 0 0 1.07 1.809l8.514 2.948-.968 5.548c-.188 1.058-.832 1.568-1.86 1.568H6.126l.72-4.145-4.685-1.233a.408.408 0 0 0-.508.322L.513 28.125c-.348 1.981 1.19 3.794 3.22 3.794h6.214c3.718 0 6.044-1.96 6.7-5.645l1.727-9.827a.81.81 0 0 0-.536-.905l-9.18-3.165.923-5.075c.181-1.019.872-1.567 1.86-1.567h3.474l-.568 3.26 4.706 1.23a.409.409 0 0 0 .507-.323s.934-5.417.94-5.586C20.82 2.35 19.29.56 17.275.56" />
      </g>
      <g transform="translate(144.732)">
        <path fill="black" d="M17.175.56H10.996c-3.678 0-6.043 1.96-6.699 5.645l-1.55 8.667a1.617 1.617 0 0 0 1.07 1.809l8.514 2.948-.968 5.548c-.188 1.058-.833 1.568-1.861 1.568H6.026l.72-4.145-4.685-1.233a.408.408 0 0 0-.507.322L.412 28.125c-.347 1.981 1.192 3.794 3.221 3.794h6.214c3.718 0 6.044-1.96 6.7-5.645l1.727-9.827a.809.809 0 0 0-.535-.905l-9.18-3.165.922-5.075c.181-1.019.872-1.567 1.86-1.567h3.473l-.568 3.26 4.707 1.23a.409.409 0 0 0 .507-.323s.934-5.417.94-5.586C20.72 2.35 19.19.56 17.175.56" />
      </g>
      <g transform="translate(174.463)">
        <path fill="black" d="M16.914.56h-6.178c-3.679 0-6.044 1.96-6.7 5.645l-1.55 8.667a1.617 1.617 0 0 0 1.07 1.809l8.514 2.948-.968 5.548c-.188 1.058-.832 1.568-1.86 1.568H5.765l.72-4.145L1.8 21.367a.408.408 0 0 0-.508.322L.152 28.125c-.347 1.981 1.191 3.794 3.22 3.794h6.215c3.717 0 6.044-1.96 6.7-5.645l1.727-9.827a.809.809 0 0 0-.536-.905l-9.18-3.165.922-5.075c.181-1.019.872-1.567 1.861-1.567h3.473l-.568 3.26 4.706 1.23a.409.409 0 0 0 .507-.323s.935-5.417.94-5.586C20.46 2.35 18.93.56 16.914.56" />
      </g>
    </g>
  </svg>
  ),
}
