/* eslint-disable max-len */
const programName = 'Padelxpert'
export default {
  programName,
  logo: (
    <svg className="padelxpert" x="0px" y="0px" viewBox="0 0 596 512">
      <title>{programName}</title>
      <g id="g10" transform="matrix(1.3333333,0,0,-1.3333333,0,755.90533)">
        <g id="g12" transform="translate(361.1914,287.0937)">
          <path
            id="path14"
            className="st0"
            d="M-53.2,91l2.7,15.5h12.3l-6-25.7l6-26.1h-12.3l-2.7,12.9L-56,54.7h-12.3l5.9,26.1l-5.9,25.7H-56
            L-53.2,91z"
          />
        </g>
        <g id="g16">
          <g>
            <g id="g18">
              <g id="g24" transform="translate(392.8682,290.5156)">
                <path
                  id="path26"
                  className="st1"
                  d="M-53.2,91V63.4h2.6c0.3,0,0.8,0.1,1.5,0.2c0.7,0.2,1,0.4,1,0.7V90c0,0.4-0.4,0.7-1.1,0.8
                        c-0.7,0.1-1.2,0.2-1.4,0.2H-53.2z M-65.8,33.7v69.4h23.2c0.8,0,1.7-0.2,2.5-0.6s1.6-0.9,2.2-1.6c0.7-0.7,1.2-1.4,1.6-2.2
                        c0.4-0.8,0.6-1.6,0.6-2.5V58.2c0-0.7-0.2-1.5-0.5-2.3c-0.4-0.8-0.8-1.6-1.4-2.2c-0.6-0.7-1.3-1.2-2-1.7
                        c-0.7-0.4-1.5-0.6-2.3-0.6h-11.3V33.7H-65.8z"
                />
              </g>
              <g id="g28" transform="translate(437.6455,302.6381)">
                <path
                  id="path30"
                  className="st1"
                  d="M-53.2,91V78.7h-8.3c-0.6-0.5-0.9-0.8-1-1.1v-4.2h9.3V61.2h-9.3v-8.7c0.2-0.6,0.7-0.9,1.4-1.1
                        h7.9V39.2h-15.1c-0.8,0-1.6,0.2-2.3,0.6c-0.8,0.4-1.5,1-2.2,1.6c-0.7,0.7-1.2,1.4-1.7,2.2c-0.4,0.8-0.6,1.6-0.6,2.4v38.1
                        c0,0.8,0.2,1.7,0.6,2.5c0.4,0.8,0.9,1.5,1.6,2.2c0.7,0.7,1.4,1.2,2.2,1.6c0.8,0.4,1.7,0.6,2.6,0.6L-53.2,91z"
                />
              </g>
              <g id="g32" transform="translate(464.8252,302.6381)">
                <path
                  id="path34"
                  className="st1"
                  d="M-53.2,91V78.7h-8.3c-0.6-0.5-0.9-0.8-1-1.1V39.2h-12.6v45c0,0.8,0.2,1.7,0.6,2.5
                        c0.4,0.8,0.9,1.5,1.6,2.2c0.7,0.7,1.4,1.2,2.2,1.6c0.8,0.4,1.7,0.6,2.6,0.6H-53.2z"
                />
              </g>
              <g id="g36" transform="translate(495.8184,290.4179)">
                <path
                  id="path38"
                  className="st1"
                  d="M-53.2,91h-13.3V65.3c0.1-0.9,0.6-1.5,1.5-1.7h8V51.4h-15.2c-0.8,0-1.6,0.2-2.3,0.6
                        c-0.8,0.4-1.5,1-2.2,1.6c-0.7,0.7-1.2,1.4-1.7,2.2c-0.4,0.8-0.6,1.6-0.6,2.4v62.6h12.5v-17.6h13.3V91z"
                />
              </g>
              <g id="g40" transform="translate(179.2314,154.2856)">
                <path
                  id="path42"
                  className="st2"
                  d="M-53.2,91c0.6,0.3,1.2,0.7,1.9,1.2c0.7,0.4,1.4,0.9,2.1,1.5c0.8,0.5,1.5,1.1,2.3,1.7
                        c1.6,1.2,3.2,2.6,4.8,4c1.6,1.4,3.1,2.9,4.5,4.4c1.4,1.4,2.7,2.8,3.8,4c0.8,0.9,1.6,1.8,2.1,2.4c0.2,0.2,0.2,0.4,0.2,0.4
                        c-1.2,7.5-3.2,13.1-3.9,15c-0.2-0.1-0.4-0.2-0.8-0.3c-0.7-0.3-1.8-0.7-3.1-1.1c-0.6-0.2-1.4-0.4-2.1-0.6
                        c-0.8-0.2-1.5-0.5-2.4-0.7c-0.8-0.2-1.7-0.4-2.5-0.6c-0.9-0.2-1.7-0.4-2.6-0.6c-0.9-0.2-1.8-0.4-2.6-0.5
                        c-0.9-0.2-1.7-0.3-2.6-0.4c-0.8-0.1-1.7-0.2-2.4-0.3c-0.8-0.1-1.5-0.2-2.2-0.2c-2.1-0.1-3.6-0.3-4.2-0.3
                        c0.9-1.9,2.1-6.6,3.5-12.9c-0.1-3,3.2-16.2,3.5-17.4c0,0,0.5,0.3,1.3,0.7C-54.3,90.4-53.8,90.7-53.2,91"
                />
              </g>
              <g id="g44" transform="translate(213.0459,115.8491)">
                <path
                  id="path46"
                  className="st2"
                  d="M-53.2,91c-0.4-0.3-0.7-0.6-1.3-1c-0.5-0.3-1.1-0.7-1.7-1.1c-0.6-0.4-1.4-0.7-2.2-1.1
                        c-0.8-0.4-1.6-0.7-2.5-1c-0.9-0.3-1.8-0.6-2.7-0.9c-0.9-0.3-1.9-0.5-2.9-0.7c-1-0.2-1.9-0.4-2.9-0.6c-1-0.2-1.9-0.3-2.8-0.4
                        c-1.8-0.2-3.6-0.3-5-0.4c-3-0.1-4.9,0-4.9,0l0,0.1c0,0,2,0,4.9,0.2c1.5,0.1,3.2,0.3,5,0.6c0.9,0.1,1.8,0.3,2.8,0.5
                        c0.9,0.2,1.9,0.4,2.8,0.7c0.9,0.3,1.9,0.5,2.8,0.8c0.9,0.3,1.8,0.6,2.6,1c0.8,0.4,1.6,0.7,2.4,1.1c0.7,0.4,1.4,0.8,2,1.2
                        c0.6,0.4,1.1,0.8,1.6,1.1c0.5,0.3,0.8,0.7,1.2,1c0.3,0.3,0.6,0.5,0.8,0.7l-4,17.9c-0.6-0.7-1.4-1.5-2.3-2.5
                        c-1.1-1.2-2.4-2.5-3.9-3.9c-1.5-1.4-3.1-2.8-4.7-4.2c-1.7-1.4-3.3-2.7-5-3.9c-0.8-0.6-1.6-1.2-2.4-1.7c-0.8-0.5-1.5-1-2.2-1.4
                        c-0.7-0.4-1.3-0.8-1.9-1.1c-0.6-0.3-1.1-0.6-1.5-0.8c-0.8-0.4-1.3-0.7-1.3-0.7l-0.1,0.1c0,0,0.5,0.3,1.3,0.7
                        c0.4,0.2,0.9,0.5,1.5,0.8c0.6,0.3,1.2,0.7,1.9,1.2c0.7,0.4,1.4,0.9,2.1,1.5c0.8,0.5,1.5,1.1,2.3,1.7c1.6,1.2,3.2,2.6,4.8,4
                        c1.6,1.4,3.1,2.9,4.5,4.4c1.4,1.4,2.7,2.8,3.8,4c1.4,1.5,2.4,2.7,3,3.4c-1.4,9-3.5,15.5-3.5,15.5c0,0-0.1,0-0.2,0l-4.3,19.3
                        c-0.4-0.4-0.9-0.9-1.4-1.5c-1.1-1.2-2.4-2.5-3.9-3.9c-1.5-1.4-3.1-2.8-4.7-4.2c-1.7-1.4-3.3-2.7-5-3.9
                        c-0.8-0.6-1.6-1.2-2.4-1.7c-0.8-0.5-1.5-1-2.2-1.4c-0.7-0.4-1.3-0.8-1.9-1.1c-0.6-0.3-1.1-0.6-1.5-0.8
                        c-0.8-0.4-1.3-0.7-1.3-0.7l0,0.1c1.2-6.5,2.4-13.3,3.5-19.6c0-0.1-0.1-0.2-0.1-0.3c-0.1-1.8,1.1-7.3,2.1-11.7
                        c1.3-7.5,2.1-12.5,2.1-12.5c-3.7-3.8-0.3-7.8-0.3-7.8c3.6-6.4,18.5-2,18.5-2c13.7,4.1,14.2,9.8,14.2,9.8c0.3,4-1.5,6.6-2.6,7.9
                        C-52.5,91.6-52.8,91.3-53.2,91"
                />
              </g>
              <g id="g48" transform="translate(176.7456,194.4125)">
                <path
                  id="path50"
                  className="st2"
                  d="M-53.2,91c-1.5-0.3-3-0.5-4.6-0.6L-53.2,91z M-54.2,80.5c0.8,0.1,1.6,0.3,2.4,0.4
                        c0.8,0.2,1.7,0.3,2.5,0.5c0.9,0.2,1.7,0.4,2.6,0.6c0.9,0.2,1.7,0.4,2.6,0.7c0.8,0.2,1.7,0.5,2.5,0.7c0.8,0.2,1.6,0.5,2.3,0.8
                        c0.7,0.3,1.4,0.5,2,0.7c1.3,0.5,2.3,0.9,3,1.2c0.4,0.2,0.7,0.3,0.9,0.4l-1.4,9.6c-0.2-0.1-0.4-0.2-0.7-0.3
                        c-0.8-0.3-1.9-0.7-3.2-1.1c-0.7-0.2-1.4-0.4-2.1-0.7c-0.8-0.2-1.6-0.5-2.4-0.7c-1.7-0.4-3.4-1-5.3-1.3
                        c-0.9-0.2-1.8-0.4-2.7-0.6c-0.9-0.2-1.8-0.3-2.6-0.4c-0.9-0.1-1.7-0.2-2.5-0.4c-0.8-0.1-1.5-0.2-2.2-0.2
                        c-2-0.1-3.5-0.3-4.2-0.3l2-10.4c0.6,0.1,2.1,0.2,4.2,0.5C-55.6,80.3-54.9,80.4-54.2,80.5"
                />
              </g>
              <g id="g52" transform="translate(116.1479,415.3588)">
                <path
                  id="path54"
                  className="st1"
                  d="M-53.2,91c0.6-3-0.6-6-2.6-6.5c-2-0.5-4.1,1.5-4.7,4.5c-0.6,3,0.6,6,2.6,6.5
                        C-56,96.1-53.9,94.1-53.2,91 M-49.5,74.1c0.6-3-0.6-6-2.6-6.5c-2-0.5-4.1,1.5-4.7,4.5c-0.6,3,0.6,6,2.6,6.5
                        C-52.3,79.2-50.1,77.2-49.5,74.1 M-49.7,112.5c2,0.5,4.1-1.5,4.7-4.5c0.6-3-0.6-5.9-2.6-6.5c-2-0.5-4.1,1.5-4.7,4.5
                        C-52.9,109.1-51.7,112-49.7,112.5 M-41.7,92.6c0.6-3-0.6-6-2.6-6.5c-2-0.5-4.1,1.5-4.7,4.5c-0.6,3,0.6,6,2.6,6.5
                        C-44.4,97.7-42.3,95.6-41.7,92.6 M-38.1,75.7c0.6-3-0.6-6-2.6-6.5c-2-0.5-4.1,1.5-4.7,4.5c-0.6,3,0.6,6,2.6,6.5
                        C-40.8,80.8-38.6,78.8-38.1,75.7 M-38.1,114c2,0.5,4.1-1.5,4.7-4.5c0.6-3-0.6-6-2.6-6.5c-2-0.5-4.1,1.5-4.7,4.5
                        C-41.3,110.6-40.2,113.5-38.1,114 M-30.1,94.2c0.6-3-0.6-6-2.6-6.5c-2-0.5-4.1,1.5-4.7,4.5c-0.6,3,0.6,6,2.6,6.5
                        C-32.9,99.2-30.7,97.2-30.1,94.2 M-26.6,77.3c0.6-3-0.6-6-2.6-6.5c-2-0.5-4.1,1.5-4.7,4.5c-0.6,3,0.6,6,2.6,6.5
                        C-29.3,82.4-27.2,80.4-26.6,77.3 M-26.6,115.5c2,0.5,4.1-1.5,4.7-4.5c0.6-3-0.6-6-2.6-6.5c-2-0.5-4.1,1.5-4.7,4.5
                        C-29.7,112-28.6,115-26.6,115.5 M-18.6,95.7c0.6-3-0.5-6-2.6-6.5c-2-0.5-4.1,1.5-4.7,4.5c-0.6,3,0.6,6,2.6,6.5
                        C-21.3,100.8-19.2,98.8-18.6,95.7 M-15.1,79c0.6-3-0.5-6-2.6-6.5c-2-0.5-4.1,1.5-4.7,4.5c-0.6,3,0.6,6,2.6,6.5
                        C-17.8,84-15.7,82-15.1,79 M-15,117c2,0.5,4.1-1.5,4.7-4.5c0.6-3-0.6-6-2.6-6.5c-2-0.5-4.1,1.5-4.7,4.5
                        C-18.1,113.5-17,116.4-15,117 M-7,97.3c0.6-3-0.6-6-2.6-6.5c-2-0.5-4.1,1.5-4.7,4.5c-0.6,3,0.6,6,2.6,6.5
                        C-9.7,102.4-7.6,100.3-7,97.3 M-3.6,80.6c0.6-3-0.5-6-2.6-6.5c-2-0.5-4.1,1.5-4.7,4.5c-0.6,3,0.6,6,2.6,6.5
                        C-6.3,85.6-4.2,83.6-3.6,80.6 M-3.4,118.5c2,0.5,4.1-1.5,4.7-4.5c0.6-3-0.6-6-2.6-6.5c-2-0.5-4.1,1.5-4.7,4.5
                        C-6.6,115-5.4,117.9-3.4,118.5 M4.5,98.9c0.6-3-0.6-6-2.6-6.5c-2-0.5-4.1,1.5-4.7,4.5c-0.6,3,0.6,6,2.6,6.5
                        C1.8,103.9,3.9,101.9,4.5,98.9 M7.9,82.2c0.6-3-0.5-6-2.6-6.5c-2-0.5-4.1,1.5-4.7,4.5c-0.6,3,0.6,6,2.6,6.5
                        C5.2,87.3,7.3,85.2,7.9,82.2 M8.2,58.9c-2-0.5-4.1,1.5-4.7,4.5c-0.6,3,0.5,6,2.6,6.5c2,0.5,4.1-1.5,4.7-4.5
                        C11.4,62.4,10.2,59.5,8.2,58.9 M13.4,48.6c0.6-3-0.6-6-2.6-6.5c-2-0.5-4.1,1.5-4.7,4.5c-0.6,3,0.6,6,2.6,6.5
                        C10.6,53.7,12.8,51.7,13.4,48.6 M15.9,33.3c0.6-3-0.6-6-2.6-6.5c-2-0.5-4.1,1.5-4.7,4.5c-0.6,3,0.6,6,2.6,6.5
                        C13.2,38.3,15.3,36.3,15.9,33.3 M21.5,16.2c0.6-3-0.5-6-2.5-6.5c-2-0.6-4.2,1.4-4.8,4.4c-0.6,3,0.5,6,2.5,6.6
                        C18.7,21.2,20.9,19.2,21.5,16.2 M23.1-53.6c0,0-0.9-3.3-2.3-8.3c-0.7-2.5-1.5-5.4-2.3-8.6c-0.9-3.1-1.7-6.5-2.6-9.8
                        c-0.9-3.3-1.7-6.7-2.4-9.9c-0.7-3.2-1.4-6.1-1.9-8.7c-0.2-1.3-0.5-2.4-0.6-3.5c-0.1-1-0.3-2-0.4-2.7c-0.1-1.5-0.1-2.4-0.1-2.4
                        s0,0.9,0,2.4c0.1,0.8,0.1,1.7,0.2,2.7c0.1,1.1,0.3,2.2,0.5,3.5c0.4,2.6,1,5.5,1.7,8.7c0.7,3.2,1.5,6.5,2.4,9.9
                        c0.9,3.3,1.8,6.7,2.7,9.8c0.9,3.1,1.8,6,2.5,8.5c0.7,2.5,1.4,4.5,1.9,6C22.9-54.4,23.1-53.6,23.1-53.6 M3.9-94.5
                        c-9.6,15.2-26.3,36.9-26.3,36.9c9.9-8,23.4-5.5,33.1-2C9.1-65.7,5.2-81.8,3.9-94.5 M-25.2-3.9c2,0.6,4.2-1.3,4.9-4.3
                        c0.7-3-0.4-6-2.4-6.6c-2-0.6-4.2,1.3-4.9,4.3C-28.3-7.5-27.2-4.6-25.2-3.9 M-26.3,54.1c-2-0.5-4.1,1.5-4.7,4.5
                        c-0.6,3,0.6,6,2.6,6.5c2,0.5,4.1-1.5,4.7-4.5C-23.1,57.5-24.3,54.6-26.3,54.1 M-23.7,37.3c-2-0.5-4.1,1.5-4.7,4.5
                        c-0.6,3,0.5,6,2.6,6.5c2,0.5,4.1-1.5,4.7-4.5C-20.5,40.7-21.7,37.8-23.7,37.3 M-21.1,21.9c-2-0.5-4.1,1.5-4.7,4.5
                        c-0.6,3,0.6,6,2.6,6.5c2,0.5,4.1-1.5,4.7-4.5C-18,25.4-19.1,22.5-21.1,21.9 M-13.1,10.7c0.6-3-0.5-6-2.5-6.5
                        c-2-0.6-4.2,1.4-4.8,4.4c-0.6,3,0.5,6,2.5,6.5C-15.9,15.7-13.7,13.8-13.1,10.7 M-14.8,55.7c-2-0.5-4.1,1.5-4.7,4.5
                        c-0.6,3,0.6,6,2.6,6.5c2,0.5,4.1-1.5,4.7-4.5C-11.6,59.2-12.8,56.3-14.8,55.7 M-12.2,38.9c-2-0.5-4.1,1.5-4.7,4.5
                        c-0.6,3,0.6,6,2.6,6.5c2,0.5,4.1-1.5,4.7-4.5C-9,42.3-10.2,39.4-12.2,38.9 M-11.2-12.8c-2-0.6-4.2,1.3-4.9,4.3
                        c-0.7,3,0.4,6,2.4,6.6c2,0.6,4.2-1.3,4.9-4.3C-8.1-9.2-9.2-12.2-11.2-12.8 M-9.6,23.5c-2-0.5-4.1,1.5-4.7,4.5
                        c-0.6,3,0.6,6,2.6,6.5c2,0.5,4.1-1.5,4.7-4.5C-6.5,27-7.6,24.1-9.6,23.5 M-1.5,12.5C-0.9,9.5-2,6.6-4,6c-2-0.6-4.2,1.4-4.8,4.4
                        c-0.7,3,0.5,6,2.5,6.6C-4.3,17.6-2.2,15.6-1.5,12.5 M-3.3,57.3c-2-0.5-4.1,1.5-4.7,4.5c-0.6,3,0.5,6,2.6,6.5s4.1-1.5,4.7-4.5
                        C-0.1,60.8-1.3,57.9-3.3,57.3 M-0.7,40.5c-2-0.5-4.1,1.5-4.7,4.5c-0.6,3,0.6,6,2.6,6.5c2,0.5,4.1-1.5,4.7-4.5
                        C2.5,44,1.3,41.1-0.7,40.5 M0.3-10.8c-2-0.6-4.2,1.3-4.9,4.3c-0.7,3,0.4,6,2.4,6.6c2,0.6,4.2-1.3,4.9-4.3
                        C3.4-7.2,2.3-10.2,0.3-10.8 M1.9,25.1c-2-0.5-4.1,1.5-4.7,4.5c-0.6,3,0.6,6,2.6,6.5c2,0.5,4.1-1.5,4.7-4.5
                        C5,28.6,3.9,25.7,1.9,25.1 M14.2-2.2c0.7-3-0.4-6-2.4-6.6C9.9-9.4,7.7-7.5,7-4.5c-0.7,3,0.4,6,2.4,6.6
                        C11.3,2.7,13.5,0.8,14.2-2.2 M7.5,7.8c-2-0.6-4.2,1.4-4.8,4.4c-0.6,3,0.5,6,2.5,6.5c2,0.6,4.2-1.4,4.8-4.4
                        C10.7,11.3,9.5,8.4,7.5,7.8 M-31.9,6.8c-0.6,3,0.5,6,2.5,6.5c2,0.6,4.2-1.4,4.8-4.4c0.6-3-0.5-6-2.5-6.5
                        C-29.1,1.8-31.2,3.8-31.9,6.8 M-37.3,24.8c-0.6,3,0.6,6,2.6,6.5c2,0.5,4.1-1.5,4.7-4.5c0.6-3-0.5-6-2.6-6.5
                        C-34.6,19.8-36.8,21.8-37.3,24.8 M-34.2-16.9c-2-0.6-4.2,1.3-4.9,4.3c-0.7,3,0.4,6,2.4,6.6c2,0.6,4.2-1.3,4.9-4.3
                        C-31.2-13.3-32.2-16.2-34.2-16.9 M-32.6,42.2c0.6-3-0.5-6-2.6-6.5c-2-0.5-4.1,1.5-4.7,4.5c-0.6,3,0.6,6,2.6,6.5
                        C-35.3,47.2-33.2,45.2-32.6,42.2 M-37.8,52.5c-2-0.5-4.1,1.5-4.7,4.5c-0.6,3,0.5,6,2.6,6.5c2,0.5,4.1-1.5,4.7-4.5
                        C-34.6,55.9-35.7,53-37.8,52.5 M-43.4,5c-0.6,3,0.5,6,2.5,6.6c2,0.6,4.2-1.4,4.8-4.4c0.6-3-0.5-6-2.5-6.5
                        C-40.6-0.1-42.7,1.9-43.4,5 M-48.8,23.2c-0.6,3,0.6,6,2.6,6.5c2,0.5,4.1-1.5,4.7-4.5c0.6-3-0.6-6-2.6-6.5
                        C-46.1,18.2-48.2,20.2-48.8,23.2 M-44.1,40.6c0.6-3-0.6-6-2.6-6.5c-2-0.5-4.1,1.5-4.7,4.5c-0.6,3,0.6,6,2.6,6.5
                        C-46.8,45.6-44.7,43.6-44.1,40.6 M-49.2,50.9c-2-0.5-4.1,1.5-4.7,4.5c-0.6,3,0.6,6,2.6,6.5c2,0.5,4.1-1.5,4.7-4.5
                        C-46.1,54.3-47.2,51.4-49.2,50.9 M-50.1-1.3c-2-0.6-4.2,1.4-4.8,4.4c-0.6,3,0.5,6,2.5,6.6c2,0.6,4.2-1.4,4.8-4.4
                        C-47,2.2-48.1-0.7-50.1-1.3 M-60.3,21.6c-0.6,3,0.6,6,2.6,6.5c2,0.5,4.1-1.5,4.7-4.5c0.6-3-0.6-6-2.6-6.5
                        C-57.6,16.5-59.7,18.6-60.3,21.6 M-60.3,43.5c2,0.5,4.1-1.5,4.7-4.5c0.6-3-0.6-6-2.6-6.5c-2-0.5-4.1,1.5-4.7,4.5
                        C-63.5,40-62.3,42.9-60.3,43.5 M-62.9,60.3c2,0.5,4.1-1.5,4.7-4.5c0.6-3-0.6-6-2.6-6.5c-2-0.5-4.1,1.5-4.7,4.5
                        C-66.1,56.8-64.9,59.7-62.9,60.3 M-65.8,77c2,0.5,4.1-1.5,4.7-4.5c0.6-3-0.6-6-2.6-6.5c-2-0.5-4.1,1.5-4.7,4.5
                        C-68.9,73.6-67.8,76.5-65.8,77 M-67.1,15.5c-2-0.5-4.1,1.5-4.7,4.5c-0.6,3,0.6,6,2.6,6.5c2,0.5,4.1-1.5,4.7-4.5
                        C-63.9,18.9-65.1,16-67.1,15.5 M-71.8,41.9c2,0.5,4.1-1.5,4.7-4.5c0.6-3-0.6-6-2.6-6.5c-2-0.5-4.1,1.5-4.7,4.5
                        C-75,38.4-73.8,41.3-71.8,41.9 M-74.4,58.7c2,0.5,4.1-1.5,4.7-4.5c0.6-3-0.5-6-2.6-6.5c-2-0.5-4.1,1.5-4.7,4.5
                        C-77.6,55.2-76.4,58.1-74.4,58.7 M-79.8,68.9c-0.6,3,0.6,6,2.6,6.5c2,0.5,4.1-1.5,4.7-4.5c0.6-3-0.6-6-2.6-6.5
                        C-77.1,63.8-79.2,65.9-79.8,68.9 M-69.5,94c2,0.5,4.1-1.5,4.7-4.5c0.6-3-0.6-6-2.6-6.5c-2-0.5-4.1,1.5-4.7,4.5
                        C-72.7,90.5-71.6,93.4-69.5,94 M-61.3,111c2,0.5,4.1-1.5,4.7-4.5c0.6-3-0.5-6-2.6-6.5c-2-0.5-4.1,1.5-4.7,4.5
                        C-64.5,107.6-63.3,110.5-61.3,111 M-76.1,123.6c-5.6-5.6-10.5-12.7-13.9-21.1c-3.4-8.4-5.3-17.9-5.6-27.9c-0.2-5,0-10,0.6-15.1
                        c0.5-5.1,1.4-10.2,2.3-15.4c1.8-10.4,4.2-20.8,7.7-30.9c3.4-10.1,7.9-19.9,13-29c5.2-9.2,11.1-17.7,17.2-25.6
                        c3.1-3.9,6.2-7.7,9.4-11.4c3.2-3.6,6.4-7.1,9.5-10.6c6.1-7,11.6-13.7,16.4-20.2c4.8-6.5,9.1-12.5,12.6-18
                        c3.6-5.5,6.5-10.4,8.8-14.5c4.2-7.5,6.4-12.3,6.7-13c0.3,0.1,0.5,0.1,0.8,0.2c0.9,0.2,1.8,0.4,2.7,0.7c1.8,0.4,3.5,1,5.2,1.5
                        c0.8,0.3,1.6,0.6,2.3,0.8c0.7,0.3,1.4,0.5,2.1,0.8c1.3,0.5,2.3,0.9,3.1,1.2c0.1,0.1,0.2,0.1,0.4,0.2c1,6.1,4.7,26.7,10.1,51.1
                        L25.4-4.9c-0.4-0.9-1.1-1.6-2-1.9c-2-0.6-4.2,1.3-4.9,4.3c-0.7,3,0.4,6,2.4,6.6C22,4.5,23.3,4,24.2,2.9l-6.5,44.7
                        c-0.1,0.2-0.2,0.5-0.2,0.8c-0.1,0.5-0.1,1.1-0.1,1.6l-2,13.7c-0.2,0.5-0.4,1-0.5,1.5c-0.2,0.8-0.2,1.7-0.1,2.4l-1.7,11.7
                        c-0.5,0.7-0.9,1.6-1.1,2.6c-0.3,1.3-0.2,2.6,0.1,3.7l-1.3,9.1c-1,0.8-1.8,2.2-2.1,3.8c-0.4,2,0,3.9,0.8,5.1l-5.1,35
                        c-9.2,4.6-20.3,7.5-33.8,7.2v0c0,0-0.3,0-0.9-0.1c-0.6-0.1-1.6-0.2-2.8-0.4c-2.4-0.4-6-1.2-10.4-2.6c-4.4-1.4-9.7-3.4-15.4-6.4
                        C-64.4,133.3-70.5,129.2-76.1,123.6"
                />
              </g>
              <g id="g56" transform="translate(109.8652,463.6118)">
                <path
                  id="path58"
                  className="st0"
                  d="M-53.2,91c0.1,0,0.1,0,0.2,0.1C-53.2,91-53.2,91-53.2,91C-53.3,91-53.3,91-53.2,91 M8.5-178.5
                        c0.7,0.1,1.4,0.2,2.2,0.3c0.8,0.1,1.6,0.3,2.4,0.5c0.5,0.1,0.9,0.2,1.4,0.3c-0.4,0.9-2.7,5.6-6.9,12.8c-2.4,4-5.4,8.9-9,14.3
                        c-3.6,5.5-7.9,11.4-12.8,17.8c-4.9,6.4-10.5,13-16.6,19.9c-3.1,3.4-6.3,6.9-9.6,10.5c-3.2,3.6-6.4,7.4-9.5,11.4
                        c-6.2,7.9-12.2,16.5-17.5,25.7c-5.3,9.2-9.8,19.1-13.3,29.3c-3.5,10.2-6,20.7-7.9,31.1c-0.9,5.2-1.9,10.4-2.4,15.6
                        c-0.6,5.2-0.8,10.4-0.7,15.4c0.3,10.2,2.1,20,5.6,28.7c1.7,4.4,3.9,8.4,6.3,12.1c2.5,3.7,5.2,7,8.1,9.9
                        c5.8,5.8,12.1,10.1,17.9,13.2c3.8,2,7.4,3.6,10.6,4.8c-9.1-1.5-39.5-8.4-53.7-36.8c0,0-27.7-49.9,22.3-136
                        C-58-106-18.2-137.9,4.9-178.9C5.7-178.8,6.9-178.7,8.5-178.5"
                />
              </g>
              <g id="g60" transform="translate(296.8428,330.7055)">
                <path
                  id="path62"
                  className="st3"
                  d="M-53.2,91v22.5c-4.9-1.2-7.4-3.4-7.4-6.6V91c0-2.7,1.3-4,3.8-4C-54.4,87-53.2,88.3-53.2,91
                         M-31.8,74.7h-19v3.4c-1.6-3.1-6.2-4.6-13.8-4.6c-11.7,0-17.5,4.6-17.5,13.7v17.5c0,3.9,1.4,7.3,4.3,10.2
                        c2.8,2.9,8.5,6.6,17.1,10.9c5,2.5,7.5,4.5,7.5,6v13c0,2.9-1.2,4.3-3.7,4.3c-2.8,0-4.2-3.5-4.2-10.5c0-1.3,0.1-3.1,0.2-5.6
                        c0.2-2.5,0.3-4.3,0.3-5.6h-20.7V145c0,11.8,8.5,17.7,25.6,17.7c16,0,23.9-6.1,23.9-18.4V74.7z"
                />
              </g>
              <g id="g64" transform="translate(352.1064,330.7055)">
                <path
                  id="path66"
                  className="st3"
                  d="M-53.2,91v54.3c0,2.6-1.2,3.8-3.7,3.8c-2.5,0-3.8-1.3-3.8-4V91c0-2.7,1.3-4,3.8-4
                        C-54.5,87-53.2,88.3-53.2,91 M-31.8,74.7H-48l-1.8,2.9c-3.7-2.8-7.9-4.1-12.5-4.1c-5.5,0-10.2,1.3-14,3.8
                        c-3.8,2.6-5.8,6-5.8,10.5v62.8c0,3.7,1.5,6.7,4.6,8.8c3,2.2,6.7,3.3,11.1,3.3c5.9,0,10.4-1.1,13.3-3.2v23.2h21.5V74.7z"
                />
              </g>
              <g id="g68" transform="translate(406.9951,364.5112)">
                <path
                  id="path70"
                  className="st3"
                  d="M-53.2,91v20c0,2.9-1.2,4.3-3.6,4.3c-2.5,0-3.7-1.4-3.7-4.3V91H-53.2z M-31.8,77.9h-28.8V57.5
                        c0-3,1.2-4.4,3.7-4.4c2.4,0,3.6,1.5,3.6,4.4v17.6h21.5V57.4c0-11.8-8.4-17.7-25.2-17.7c-16.7,0-25,6.1-25,18.4v52.3
                        c0,12.3,8.3,18.4,25,18.4c16.8,0,25.2-5.9,25.2-17.7V77.9z"
                />
              </g>
              <path id="path72" className="st3" d="M401.6,405.4h-21.5v108h21.5V405.4z" />
              <g id="g74" transform="translate(240.5239,375.2787)">
                <path
                  id="path76"
                  className="st3"
                  d="M-53.2,91c0-3.8-2.6-5.7-7.9-5.7v37.3c5.3,0,7.9-1.9,7.9-5.7V91z M-37.3,134.3
                        c-4.8,2.5-12.1,3.8-22,3.8h-24.9V55.4l4.1-25.3h19.1v39.8C-48.3,69.7-39.9,71-36,74c3.9,2.9,5.9,7.9,5.9,15v30
                        C-30.1,126.7-32.5,131.8-37.3,134.3"
                />
              </g>
              <g id="g78" transform="translate(297.875,123.435)">
                <path
                  id="path80"
                  className="st4"
                  d="M-53.2,91c0-14.4-11.7-26.1-26.1-26.1s-26.1,11.7-26.1,26.1s11.7,26.1,26.1,26.1
                        S-53.2,105.4-53.2,91"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
}
