/* eslint-disable max-len */
const programName = 'Ganni'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 643.88 164.69">
      <title>{programName}</title>
      <path d="M144.44,78.16H78.5v20.92h40.84c-0.08,6.13-1.12,12.2-3.09,18c-1.74,5.17-4.64,9.87-8.47,13.76c-3.9,3.86-8.61,6.81-13.78,8.64c-6.02,2.07-12.36,3-18.72,2.75c-8.37,0-15.6-1.69-21.69-5.06c-5.96-3.26-11.06-7.9-14.86-13.54c-3.92-5.88-6.79-12.4-8.48-19.27c-3.66-14.44-3.66-29.56,0-44c1.69-6.86,4.56-13.38,8.48-19.27c3.8-5.65,8.91-10.3,14.88-13.57c6.09-3.37,13.32-5.06,21.69-5.06c4.84-0.01,9.66,0.66,14.31,2c4.42,1.26,8.59,3.27,12.33,5.94c7.69,5.49,12.72,13.96,13.87,23.34h26.86c-0.83-8.22-3.38-16.17-7.48-23.34c-3.81-6.57-8.91-12.3-15-16.84c-6.2-4.57-13.16-8.01-20.56-10.15C91.73,1.11,83.53-0.04,75.3,0C63.56,0,53.02,2.2,43.7,6.61c-9.07,4.2-17.15,10.27-23.7,17.83c-6.57,7.71-11.61,16.6-14.83,26.2C1.69,60.85-0.06,71.57,0,82.35C-0.07,93.21,1.68,104,5.17,114.27c3.22,9.6,8.26,18.5,14.83,26.2c6.53,7.55,14.62,13.59,23.7,17.73c9.33,4.32,19.87,6.48,31.6,6.49c9.54,0,17.98-1.61,25.32-4.84c7.34-3.23,14.67-9.03,22-17.4l2.78,18.5h19V78.16H144.44zM305.31,161L244.76,3.74h-29.29L155.14,161h28.41l14.75-41.62h62.75L275.8,161H305.31z M253.78,98.42h-48l23.78-67.59h0.66L253.78,98.42L253.78,98.42z M316.42,160.95h26.2V45.36h0.66l71.55,115.59h29.07V3.74h-26.2v115.82h-0.44L345.48,3.74h-29.06V160.95L316.42,160.95z M466.35,160.95h26.2V45.36h0.66l71.56,115.59h29.07V3.74h-26.21v115.82h-0.44L495.42,3.74h-29.07V160.95L466.35,160.95z M616.36,160.95h27.52V3.74h-27.52V160.95L616.36,160.95z" />
    </svg>
  ),
}
