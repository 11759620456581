/* eslint-disable max-len */
const programName = 'Muurikka'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 293 32" fill="none">
      <title>{programName}</title>
      <path d="M23.796 0l-4.32 10.51L15.328 0H0v6.663h3.049V24.21H0v6.569h13.21v-6.569h-3.048V11.167l7.621 18.393 7.706-18.393v13.044h-3.048v6.569h16.174v-6.569h-3.049V6.663h3.049V0h-14.82zM63.512.094V6.85h3.556v13.701c0 3.379-1.524 4.692-4.996 4.692-3.641 0-5.08-1.407-5.08-4.692v-13.7h3.556V.093H40.732V6.85h3.557v13.701C44.289 28.341 49.2 32 59.955 32s15.666-3.566 15.666-11.449v-13.7h3.472V.093H63.512zM103.481.094V6.85h3.557v13.701c0 3.379-1.524 4.692-4.996 4.692-3.642 0-5.081-1.407-5.081-4.692v-13.7h3.556V.093H80.702V6.85h3.556v13.701C84.258 28.341 89.17 32 99.925 32c10.754 0 15.666-3.566 15.666-11.449v-13.7h3.472V.093h-15.582zM136.592 18.58c2.879 0 3.811 1.033 3.811 4.317v7.977h13.464v-6.569h-2.71v-2.91c0-3.753-1.101-4.785-6.181-5.63 4.826-1.501 6.689-3.472 6.689-7.225 0-2.44-1.016-4.692-2.794-6.1C146.669.657 143.959 0 138.963 0h-18.545v6.663h3.048V24.21h-3.048v6.569h17.36v-6.569h-3.134v-5.63h1.948zm-1.948-11.917h1.694c3.048 0 4.403.938 4.403 3.003 0 2.064-1.439 3.003-4.403 3.003h-1.694V6.663zM157.17 0v6.663h3.048V24.21h-3.048v6.569h17.275v-6.569h-3.049V6.663h3.049V0H157.17zM192.906 6.663h2.71L195.531 0h-16.852v6.663h3.049V24.21h-3.049v6.569h16.937v-6.569h-2.71v-9.009l7.452 9.01h-2.456v6.568h18.122v-6.569h-2.456l-9.908-10.416 8.638-7.132h2.794V0h-17.19v6.663h3.895l-8.891 7.507V6.663zM234.061 6.663h2.625V0H219.75v6.663h3.048V24.21h-3.048v6.569h16.936v-6.569h-2.625v-9.009l7.367 9.01h-2.456v6.568h18.207v-6.569h-2.456l-9.992-10.416 8.722-7.132h2.794V0h-17.275v6.663h3.98l-8.891 7.507V6.663zM279.705 24.211h-3.388v6.569H293v-6.569h-2.541l-6.012-17.548h3.218V0h-22.102v6.663h2.54l-6.182 17.548h-2.455v6.569h13.21v-6.569h-3.218l.847-2.627h8.722l.678 2.627zm-7.283-9.29l2.71-7.695 2.032 7.695h-4.742z" fill="#B11A23" />
    </svg>),
}
