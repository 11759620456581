/* eslint-disable max-len */
const programName = 'Uret'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 93.818 41.277">
      <title>{programName}</title>
      <g>
        <path d="M17.937,0V33.458q0,7.818-6.669,7.819H6.612Q0,41.277,0,33.4V0H5.519V33.573q0,2.7,2.415,2.7h2.241q2.242,0,2.242-2.7V0Z" />
        <path d="M28.858,0H39.667q6.322,0,6.323,7.071V17.247q0,5.4-3.277,6.84l3.852,17.19H41.334L37.712,24.605H34.377V41.277H28.858Zm5.519,19.891H38q2.472,0,2.471-2.99V7.991Q40.47,5,38,5H34.377Z" />
        <path d="M56.337,41.277V0H70.019V5H61.856V17.534H69.1v5H61.856v13.74h8.163v5Z" />
        <path d="M88.53,5V41.277H83.012V5h-5.29V0h16.1V5Z" />
      </g>
    </svg>),
}
