/* eslint-disable max-len */
const programName = 'Ray Ban'
export default {
  programName,
  logo: (
    <svg width="110px">
      <g fillRule="evenodd">
        <path d="M34.812 36.768c-.413 1.48-2.086 3.914-2.87 3.99-.232.022-.572-.102-.558-.636.03-1.251 1.982-4.085 2.788-4.302.55-.148.796.389.64.948m14.667-7.476c-1.645 0-3.015 6.532-4.36 6.469-1.13-.054-.186-4.507-1.92-4.519-1.064-.007-1.383 1.78-1.869 3.313-.027.087-.813 3.173-2.006 3.173-.76 0-1.425-.609-1.425-2.407 0-.842-.043-2.094-1.049-2.302-1.74-.36-1.343 1.429-2.87 1.14-4.13-.779-7.258 7.707-4.745 8.842 3.013 1.362 5.211-3.984 6.11-3.838.686.114.24 2.417 2.403 2.27 1.763-.12 3.154-3.76 3.839-3.718.608.039.079 2.19 1.6 2.27 1.78.094 2.513-2.859 3.324-2.874.204-.003.291.15.275.876-.024.989-.156 13.513-.176 13.92-.037.772.337.836.505.836.115 0 1.825-.036 2.153-.049.33-.013.387-.322.384-.51-.002-.076.237-18.233.254-22.163.002-.73-.301-.73-.427-.73m33.186-6.073c-.465 1.321-1.808 3.441-2.632 3.589-.283.05-.627-.23-.605-.715.052-1.135 1.954-3.546 2.726-3.725.527-.122.701.311.51.85m18.268-2c-3.21 2.028-2.591-5.471-4.544-5.471-2.131 0-2.754 4.447-3.514 4.521-.93.091.348-3.259-1.496-3.185-2.03.083-3.167 7.446-4.64 6.876-1.512-.584-.429-4.474-2.13-4.758-.878-.146-1.059.556-1.583.895-.782.507-2.19-.377-4.396 2.196-2.497 2.907-3.26 7.604-1.356 7.925 2.563.43 4.898-3.943 5.491-4.506.825-.785.364 2.266 2.588 2.31 2.35.047 3.056-3.83 3.74-3.793.587.033-.093 2.035 1.025 2.177 2.425.31 3.755-6.046 4.752-5.906.999.139.623 3.93 3.025 4.17 2.04.2 3.437-2.583 3.626-2.995.197-.425-.138-.738-.588-.455" />
        <path d="M35.986 47.595c.57.277 1.367.793.961 1.719a15.57 15.57 0 01-.629 1.28c-.353.602-1.21.965-2.232.482-1.635-.773-10.422-4.968-10.702-5.098-.309-.144-.81-.038-.812.823v2.339c0 .317.043 1.096-.604 1.554a25.86 25.86 0 01-2.01 1.256c-.281.143-1.005.706-1.015-.467-.02-2.33-.02-6.656-.02-6.896 0-.24.055-.702-.638-1.017-.625-.284-4.601-2.163-4.848-2.277-.319-.146-.559-.719.09-1.11.775-.468 4.64-2.73 4.908-2.92.45-.32.45-.627.454-1.558.004-1.05-.06-14.681-.052-14.902.014-.354-.035-.648.57-1.037.684-.441 1.422-.921 1.66-1.076.38-.247 1.54-.732 1.522.582-.013.882-.005 13.352 0 13.684.006.456.219 1.152.954.609 8.8-6.501 9.968-11.996 8.215-15.63C28.176 10.51 9.57 22.36 1.52 28.997.255 30.04.285 29.58.284 29.076c-.003-.595.026-2.8.074-3.206.07-.585.215-2.486 1.955-3.747C3.998 20.901 27.067 6.04 33.77 15.59c3.27 4.657 1.273 12.01-10.341 20.55-.709.523-.84.694-.84 1.636s-.022 2.365 0 2.634c.02.27.048.84.608 1.124.56.286 12.313 5.83 12.788 6.061M58.09 28.557c-.853-.958-2.566-.942-3.885-.13-1.738 1.07-2.203 2.745-1.347 3.703.853.958 2.896.756 4.147-.075 1.433-.952 1.94-2.54 1.086-3.498M71.11 3.01c4.091 4.026-2.879 11.662-4.56 13.626-.756.882-1.456 2.193-.27 3.451 1.204 1.278 2.455 2.46 3.487 3.414 1.273 1.18.893 3.386.088 4.58-.537.794-1.737 2.197-3.12 3.964-1.386 1.773-2.644 3.39-2.784 3.568a2.905 2.905 0 00-.208 3.405c1.487 2.308 1.63 2.273 2.578.627 1.58-2.745 3.662-5.293 5.248-7.1 1.428-1.627 2.203-2.79 2.405-3.078 1.435-2.047 1.57-4.235.122-5.496-1.918-1.669-4.136-3.13-5.97-4.525-1.059-.808-.325-1.801.808-2.65 4.03-3.015 9.653-8.065 7.074-14.14-2.497-5.879-14.243-1.344-14.72 10.819-.17 4.338.407 16.335.084 19.338-.187 1.747.477 1.552 1.115 1.288.584-.243 2.827-1.338 2.711-4.196-.116-2.845-.376-15.26-.46-16.545C64.303 6.665 68.454.395 71.11 3.01m28.768 12.63v-.298h.185c.102 0 .233.015.233.139 0 .14-.083.16-.207.16h-.21zm-.26.714h.26v-.513h.123l.29.513h.268l-.32-.526c.187-.015.32-.102.32-.322 0-.273-.177-.364-.485-.364h-.455v1.212z" />
        <path d="M100.04 14.864a.897.897 0 000 1.794.898.898 0 00.897-.897.898.898 0 00-.896-.897m0 2a1.105 1.105 0 110-2.21 1.105 1.105 0 010 2.21" />
      </g>
    </svg>
  ),
}
