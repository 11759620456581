/* eslint-disable max-len */
const programName = 'Guldfynd'
export default {
  programName,
  logo: (
    <svg className="guldfynd" x="0px" y="0px" viewBox="0 0 531.2 156.9">
      <title>{programName}</title>
      <g id="Layer_2_1_">
        <g id="Lager_1">
          <path
            className="st0"
            d="M452.8,0.1c-0.4,0-0.8,0-1.1,0H78.4C35.1,0.1-0.1,35.2-0.1,78.5s35.1,78.4,78.4,78.4h374.4
            c43.3,0,78.5-35.1,78.5-78.5S496.1,0,452.8,0.1C452.8,0,452.8,0,452.8,0.1L452.8,0.1z M7,78.4C7.1,39,39,7.1,78.4,7h374.4
            c39.5-0.5,71.8,31.1,72.3,70.6c0.5,39.5-31.1,71.8-70.6,72.3c-0.6,0-1.1,0-1.7,0H78.5C39,149.8,7.1,117.9,7,78.4z"
          />
          <path
            className="st1"
            d="M3.5,78.4c0,41.4,33.5,74.9,74.9,75h374.4c41.4-1.4,73.8-36.2,72.4-77.6c-1.4-39.4-33-71-72.4-72.4H78.4
            C37,3.6,3.5,37.1,3.5,78.4z"
          />
          <path
            className="guldfynd-bokstav"
            d="M122.4,37.7c-0.4,0.3-1.1,0.1-2.6-0.4c-5-1.5-16.8-4.5-28-3.4C70.8,36,44.1,53,44.1,84.1c0,30.2,25.5,43.4,41.9,43.4
            c18.7,0,27.6-8.2,39.5-12.9c4.6-1.8,11.4,1.5,7.8-6.4V84.6c2.8-1,7.7-1,7.7-4.6c0-2.7-5.8-1.8-5.8-1.8S128,79,123.7,79
            s-15.1-0.6-15.1-0.6s-5.5-0.8-5.5,1.6c0,3.7,3.7,3,10.7,4.8c2.9,0.7,3.6,0.5,3.6,1.7v25.2c0,0-6.3,7.2-18.3,7.2
            C63.3,118.9,59,76.6,59,76c0-29.8,18.7-36.3,31.5-36.3s24.9,6,31,14.4c1.2,5.6,2.4,11.5,2.4,11.5s0.7,2.4,2.7,2.3s2-2.6,2-2.6
            s0.1-16.2,0.1-20.7s1-14.4,1-14.4s-0.1-2.4-2.1-2.4s-2.8,2.2-2.8,2.2S122.9,37.3,122.4,37.7z"
          />
          <path
            className="guldfynd-bokstav"
            d="M140,56.3c2.9,0.4,5.8,0.7,8.7,0.6c3.3,0,6.6-0.3,9.9-0.7c1.8-0.3,2.1,1.6,0.6,2.2c-1,0.5-2,0.9-3.1,1.2
            c-0.8,0.2-1.7,0.8-1.7,3.1v29.7c0,2.2,2.7,11.2,12.1,11.2c7.6,0.2,14-5.4,14.8-12.9c0.1-1,0-7.4,0-15.1c-0.1-4.5-0.5-9-1.1-13.4
            c-0.2-1.5-0.4-2.1-1.8-2.5s-2.6-0.3-4.1-0.7s-1.2-3,0.2-2.8c2.7,0.5,5.5,0.7,8.2,0.7c2.2,0,4.4-0.2,6.6-0.5
            c1.9-0.4,1.7,1.6,0.9,2.1s-2.7,1.6-3,1.8s-1.1,0.2-1.2,1.8s-0.5,18.5-1,24.3c-0.6,6.4-1.6,11-4.3,13.9c-3.7,4-8.8,6.3-14.2,6.3
            c-16.9,0-21.2-9.3-21.2-18c0-10.2-0.1-23.2-0.1-23.2s0.2-4.1-1.3-4.8s-2.2-0.7-4.3-1.6S138.3,55.8,140,56.3z"
          />
          <path
            className="guldfynd-bokstav"
            d="M200.2,60.7c0-0.3-0.2-0.8-1-1c-1.5-0.3-3-0.7-4.5-1.2c-2-0.8-1.3-2.3,0.8-2.3c1.1,0,5.7,0.4,9.9,0.3
            c3.6-0.1,6.8-0.6,8.2-0.8c3.2-0.3,3.2,2.1,1.3,2.6c-1.4,0.3-2.7,0.6-4.1,1.1c-0.9,0.5-1.1,1.1-1.1,2.3v39.3c0,0,0,0.5,3.8,0.5
            c4.3-0.2,8.5-0.8,12.7-1.6c1.2-0.2,4-0.4,4.5-3.7c0.7-4.7,2.8-5.2,2.8-0.9c0,2.2,0.4,7.2,0.5,9.8s-0.2,3.4-1.6,1.7
            c-1.1-1.4-2.7-2.2-4.5-2.1c-3.8-0.4-7.6-0.6-11.5-0.4c-7.5,0-20.4,0.7-21.5,0.7c-2.4,0-2.1-1.5,0.4-1.9c1.3-0.2,2-0.3,3.4-0.7
            s1.4-0.9,1.4-2.9S200.2,61.4,200.2,60.7z"
          />
          <path
            className="guldfynd-bokstav"
            d="M245.5,55.6c8.4,0,10.5-0.8,14.1-0.9c6.5-0.2,24.6,3.3,24.6,23.3c0,21-15.9,24.8-21,25.7c-6.3,1.1-20.7,0.1-25.5,0.9
            c-3.3,0.5-1.5-1.9,0.1-2.3s2.4-0.4,3.1-1c0.8-0.5,1.4-1.3,1.6-2.2V60.8c0-2.4-3.7-2.7-5-2.9c-2.3-0.4-3.1-2.8,0.1-2.7
            C240.1,55.4,243,55.6,245.5,55.6z M251.4,60.1v39.1c0,0,0.2,2.6,2.6,2.6c2.4,0,20.5,1.3,20.5-22.7c0-18.7-13.4-21.4-17.6-21.4
            S251.4,60.1,251.4,60.1z"
          />
          <path
            className="guldfynd-bokstav"
            d="M442.6,55.6c8.3,0,10.5-0.8,14.1-0.9c6.5-0.2,24.6,3.3,24.6,23.3c0,21-15.9,24.8-21,25.7c-6.3,1.1-20.7,0.1-25.5,0.9
            c-3.3,0.5-1.5-1.9,0.1-2.3s2.4-0.4,3.1-1c0.8-0.5,1.4-1.3,1.6-2.2V60.8c0-2.4-3.7-2.7-5-2.9c-2.3-0.4-3.1-2.8,0.1-2.7
            C437.1,55.4,440.1,55.6,442.6,55.6z M448.5,60.1v39.1c0,0,0.2,2.6,2.6,2.6s20.5,1.3,20.5-22.7c0-18.7-13.4-21.4-17.6-21.4
            S448.5,60.1,448.5,60.1z"
          />
          <path
            className="guldfynd-bokstav"
            d="M292.5,60.9v37.9c0,1.4-1.5,2.3-2.1,2.7s-2.3,0.8-2.9,1.1s-1.6,1.6,0,1.6h17.5c2.6,0,3.3-2.1,1-2.5
            c-0.9-0.1-2.8-0.6-3.6-0.7s-0.9-0.3-0.9-3.1V82.3c0-0.8,0.4-0.8,1-0.8c3.6,0,13.9,0.5,14.8,0.6c1.2,0.2,2.8,0.2,3.7,1.8
            c0.6,1.1,1.1,2.2,1.5,3.3c0.7,1.6,1.7,1,1.9-0.3s-0.1-3.9-0.1-6.9c0.1-2.2,0.2-4.4,0.5-6.6c0.2-2.3-2.1-1.9-2.6-0.4
            c-0.2,0.6-0.8,2.7-1,3.1c-2.2,0.9-4.4,1.5-6.7,2c-4.9,0.4-10.8,0.2-11.5,0.2c-1,0-1.6-0.5-1.6-1.8V60.7c0-1,0.3-0.8,1.1-1
            c1.4-0.3,11.6-0.9,15.7,0.2c2.4,0.6,4.9,1.2,6.1,5.6c0.4,1.5,2.2,1.8,2.2,0.1c-0.3-1.7-0.4-3.5-0.3-5.2c0.4-3.2,0.5-6.2,0.6-6.7
            c0-0.9-1.1-0.5-1.4,0c-0.3,0.4-0.6,0.8-1,1.1c-0.8,0.7-1.9,1.1-3,1.1c-3.1,0.2-17.9,0.2-21.3,0.2s-8.4,0-9.6-0.3
            c-4.4-0.9-6.2,1.1-3.9,2.3c1.4,0.6,2.7,1,4.1,1.4C291.2,59.6,292.5,59.9,292.5,60.9z"
          />
          <path
            className="guldfynd-bokstav"
            d="M347.1,82.7v16.5c0.1,1-0.6,1.8-1.5,2c-0.8,0.2-2.8,0.5-3.9,0.8c-2.6,0.7-1.6,2.2,0,2.2h19.9c1.6,0,2.1-1.7-0.1-2.5
            c-1.2-0.4-2-0.6-3.5-1s-1.5-1.3-1.5-2.4V81.7c0-1.3,0.3-2.5,1.1-3.6c1-1.6,11.8-16.8,12.6-17.9c0.5-0.6,1.1-1.1,1.7-1.5
            c0,0,1-0.4,3-1.1s1.3-2.5-0.5-2.4s-5.2,0.3-7.5,0.3s-5.2-0.3-8.1-0.1s-1.4,2.2,0.1,2.5c0.6,0.1,2.4,0.3,3,0.5s2,0.2,1.3,2.1
            c-0.8,2.2-6.8,13.2-6.8,13.2s-1.1,1.9-2.3,2.2c-3.4-2.6-8.5-14.1-9.1-15.1c-0.4-0.6-0.3-1.4,0.3-1.9c0.1-0.1,0.2-0.1,0.3-0.1
            c0.6-0.2,2.3-0.7,3.3-1s0.9-2.3-0.5-2.2S341.3,56,339,56s-6.8-0.1-9-0.3s-1.8,1.8-0.7,2.1c1,0.2,2.7,0.4,3.4,1
            c2.3,2.2,13.7,21.7,13.7,21.7L347.1,82.7z"
          />
          <path
            className="guldfynd-bokstav"
            d="M379.2,55.4h9.4c0.6,0,1.1,0.2,1.6,0.5l26,29.7c0,0,1.8,1.6,2.4,1.2s0.8-2.7,0.8-2.7s-0.9-21.5-1.3-22.7s-0.3-2.5-2.2-2.8
            s-2.4-0.7-3.5-0.8c-1.5-0.2-2.4-2.5,0-2.5s6.3,0.5,8.2,0.5s4.2-0.2,7.4-0.4c3.6-0.2,3.4,2,1.3,2.4s-2.9,0.3-4.2,1
            c-1.7,0.9-1.9,3.5-1.9,4.4v38.9c0.2,0.5,0,1.1-0.5,1.5c-0.5,0.5-1.3,0.6-1.8,0.1c0,0-0.1,0-0.1-0.1c-2-1.5-29.8-33-30.6-33.9
            s-0.9-0.8-0.9,0.1s0.8,26.3,0.9,27.8s0.3,2.3,1.5,2.8c1.5,0.5,3,0.9,4.5,1.1c2.7,0.6,2.6,2.7,0.4,2.5c-1.6-0.2-6.3-0.3-8.3-0.3
            s-6.7,0.5-8.1,0.5s-1.6-1.2-0.4-1.8s3-1.1,3.7-1.5c1-0.5,1.2-1.2,1.3-3.2s0.7-28.1,0.7-31.4s-3-5.4-3.9-6s-1.9-1.2-2.9-1.8
            S376.8,55.4,379.2,55.4z"
          />
        </g>
      </g>
    </svg>
  ),
}
