/* eslint-disable max-len */
const programName = 'Klättermusen'
export default {
  programName,
  logo: (
    <svg x="0px" y="0px" viewBox="0 0 277 46">

      <title>{programName}</title>

      <path d="M125.767,13.511l-2.856,7.836h-3.408v3.414h2.167l-2.581,7.106h8.646l-2.678-3.237l1.398-3.869h3.86v-3.414h-2.62l1.91-5.25
                        L125.767,13.511z M111.588,13.511l-2.857,7.836h-3.406v3.414h2.166l-2.58,7.106h8.646l-2.678-3.237l1.398-3.869h3.86v-3.414h-2.62
                        l1.91-5.25L111.588,13.511z M80.61,13.511l-6.676,18.355h8.646l-2.679-3.237l4.57-12.532L80.61,13.511z M95.439,17.162h3.406v-3.434
                        h-3.406V17.162z M90.142,17.162h3.406v-3.434h-3.406V17.162z M67.573,17.064v2.743l-5.021,4.461l2.973-8.19h-8.626l2.639,3.237
                        l-4.568,12.552h8.646l-2.658-3.218l1.536-4.243l3.506,3.75l-1.319,3.632l3.82,2.565l2.599-7.144l-2.678-2.881l4.037-3.572v-6.987
                        L67.573,17.064z M260.332,24.958l1.3-3.611h-8.626l2.658,3.216l-2.658,7.303h8.626l-2.639-3.218l1.241-3.414h4.766l-2.383,6.553
                        l3.84,2.565l3.329-9.118l-4.844-6.238L260.332,24.958z M186.304,24.998l-4.647-6.001l-4.608,5.961l1.3-3.611h-8.626l2.658,3.216
                        l-2.658,7.303h8.626l-2.639-3.218l1.241-3.414h4.766l-2.383,6.553l3.841,2.565l3.328-9.118h4.51l-2.383,6.553l3.84,2.565
                        l3.308-9.118l-4.824-6.238L186.304,24.998z M241.15,25.155l1.379-3.808h-4.785l-2.974,8.15l9.217,4.895l4.451-2.961l-3.682-1.973
                        l5.986-4.223l-4.824-6.217L241.15,25.155z M240.422,27.149l0.708-1.914h3.485v4.144L240.422,27.149z M198.534,27.978l4.825,6.217
                        l4.608-5.941l-1.3,3.612h8.646l-2.658-3.218l2.639-7.302h-4.806l-2.403,6.631h-4.745l2.304-6.395l-3.86-2.565L198.534,27.978z
                         M158.064,24.879l1.281-3.532h-8.626l2.658,3.237l-2.658,7.282h8.626l-2.64-3.218l1.221-3.414h4.727l-0.631,1.717l3.88,2.586
                        l1.556-4.302l-4.825-6.217L158.064,24.879z M139.06,25.155l1.379-3.808h-4.786l-2.973,8.15l9.217,4.895l4.45-2.961l-3.682-1.973
                        l5.987-4.223l-4.825-6.217L139.06,25.155z M138.331,27.149l0.709-1.914h3.485v4.144L138.331,27.149z M85.75,25.235l3.013,9.334
                        l4.077-2.703h8.626l-2.66-3.218l2.66-7.302H96.66l-1.359,3.749l-4.726-6.078L85.75,25.235z M90.713,25.235h4.529l-2.402,6.631
                        L90.713,25.235z M219.664,24.03l6.164,5.901l-6.204-2.23l-2.342,3.433l7.681,2.804l6.026-4.698l-5.928-5.683l5.475,2.032
                        l2.363-3.513l-7.345-2.645L219.664,24.03z"
      />
      <path d="M273.392,20.391h0.872c0.171,0,0.3-0.04,0.387-0.121c0.088-0.081,0.132-0.202,0.132-0.364c0-0.097-0.013-0.176-0.042-0.236
                        c-0.028-0.062-0.068-0.109-0.117-0.143c-0.048-0.035-0.107-0.057-0.169-0.069c-0.064-0.011-0.13-0.017-0.199-0.017h-0.863V20.391z
                         M272.861,19.006h1.45c0.335,0,0.586,0.073,0.753,0.218c0.167,0.144,0.251,0.346,0.251,0.605c0,0.144-0.021,0.264-0.063,0.36
                        c-0.042,0.096-0.092,0.172-0.149,0.23c-0.057,0.058-0.113,0.1-0.168,0.126c-0.056,0.025-0.096,0.042-0.121,0.051v0.008
                        c0.045,0.006,0.094,0.021,0.146,0.044c0.052,0.022,0.102,0.058,0.146,0.108c0.046,0.051,0.084,0.115,0.113,0.195
                        c0.03,0.079,0.045,0.179,0.045,0.298c0,0.179,0.013,0.341,0.041,0.488c0.027,0.146,0.068,0.252,0.125,0.314h-0.57
                        c-0.039-0.065-0.063-0.138-0.069-0.217c-0.007-0.08-0.011-0.156-0.011-0.231c0-0.139-0.008-0.259-0.025-0.36
                        c-0.018-0.1-0.048-0.185-0.094-0.252c-0.045-0.067-0.107-0.115-0.185-0.146c-0.077-0.031-0.177-0.047-0.299-0.047h-0.783v1.253
                        h-0.531V19.006z"
      />
      <path d="M274.049,17.914c-1.468,0-2.662,1.196-2.662,2.668c0,1.471,1.194,2.668,2.662,2.668s2.662-1.197,2.662-2.668
                        C276.711,19.11,275.517,17.914,274.049,17.914 M274.049,23.508c-1.61,0-2.92-1.312-2.92-2.926c0-1.614,1.31-2.927,2.92-2.927
                        s2.92,1.313,2.92,2.927C276.969,22.196,275.659,23.508,274.049,23.508"
      />
    </svg>
  ),
}
