/* eslint-disable max-len */
const programName = 'Dyson'
export default {
  programName,
  logo: (
    <svg x="0px" y="0px" viewBox="0 0 74 28">
      <title>{programName}</title>
      <g id="Layer_2">
        <g id="Layer_1-2">
          <path
            className="st0"
            d="M70.3,13.9c0-1.8-1.5-3.3-3.3-3.3s-3.3,1.5-3.3,3.3c0,0,0,0,0,0c0,0.9,0,6.7,0,6.7H60c0,0,0-4.8,0-6.7
                    c0-3.9,3.1-7,7-7s7,3.1,7,7c0,0,0,0,0,0c0,2,0,6.7,0,6.7h-3.6C70.3,20.6,70.3,14.8,70.3,13.9z"
          />
          <path
            className="st0"
            d="M44.4,13.9c0-3.9,3.1-7,7-7s7,3.1,7,7s-3.1,7-7,7l0,0C47.6,20.9,44.4,17.8,44.4,13.9z M51.4,17.2
                    c1.9,0,3.4-1.5,3.4-3.4s-1.5-3.4-3.4-3.4c-1.9,0-3.4,1.5-3.4,3.4C48.1,15.7,49.6,17.3,51.4,17.2C51.4,17.3,51.4,17.3,51.4,17.2
                    L51.4,17.2z"
          />
          <path
            className="st0"
            d="M35.8,11.4c-0.5-0.4-0.4-1,0.5-1.2c1.6-0.3,3.3,0,4.7,0.7l1.8-2.4c-1.4-1-3.1-1.6-4.8-1.6c0,0-4-0.5-5.7,2.6
                    c-0.8,1.7-0.2,3.7,1.4,4.7c1.7,1.1,4.8,1.5,5.7,2c0.9,0.5,0.5,1.3-0.6,1.5c-1.9,0.2-3.8-0.3-5.3-1.3l-1.8,2.4
                    c1.5,1.1,3.3,1.9,5.1,2.1c0,0,3.8,0.7,5.9-2c1.2-1.6,1-3.9-0.6-5.1c-0.2-0.2-0.5-0.3-0.7-0.4C39.5,12.4,36.7,12,35.8,11.4z"
          />
          <path
            className="st0"
            d="M18.5,23.4c1.1,0.7,2.3,1.2,3.5,1.3c4,0.1,4.3-4.1,4.3-5.1c-0.9,0.8-2.1,1.3-3.3,1.3c-3.8,0-6.9-3-7-6.8
                    c0,0,0-0.1,0-0.1c0-1.9,0-6.9,0-6.9h3.7c0,0,0,6,0,6.9c0,1.8,1.5,3.3,3.3,3.3s3.3-1.5,3.3-3.3c0,0,0,0,0,0c0-0.8,0-6.9,0-6.9H30
                    V14c0,6.1,0.3,12.3-5.6,13.8c-2.7,0.6-5.6-0.1-7.8-1.9L18.5,23.4z"
          />
          <path
            className="st0"
            d="M14.1,0h-3.7v6.8H7c-3.9,0-7,3.1-7,7s3.1,7,7,7s7-3.1,7-7c0-1.9,0-7,0-7L14.1,0z M10.4,13.9
                    c0,1.8-1.5,3.3-3.4,3.3c-1.8,0-3.3-1.5-3.3-3.4c0-1.8,1.5-3.3,3.3-3.3h3.3L10.4,13.9z"
          />
        </g>
      </g>
    </svg>

  ),
}
