/* eslint-disable max-len */
const programName = 'Superfruit'
export default {
  programName,
  logo: (
    <svg x="0px" y="0px" viewBox="0 0 359 86.5">
      <title>{programName}</title>
      <path d="M359,36.6v-7.8h-8.6V15.9h-11.7v12.8c0,0.1,0,0.1,0,0.2c0,3-1.2,5.7-3.2,7.7h3.2v25.2c0,2.2,0.4,3.9,1.1,5.2
                c0.7,1.3,1.7,2.3,2.9,3.1c1.2,0.7,2.7,1.2,4.3,1.4c1.6,0.2,3.3,0.4,5.2,0.4c1.2,0,2.3,0,3.6-0.1c1.2-0.1,2.3-0.2,3.3-0.3v-9.1
                c-0.5,0.1-1.1,0.2-1.7,0.2c-0.6,0.1-1.2,0.1-1.9,0.1c-2,0-3.3-0.3-4-1c-0.7-0.7-1-2-1-4V36.6H359z M58.2,72.6c2.5,0,4.9-0.6,7.3-1.7
                c2.4-1.1,4.3-2.9,5.8-5.4h0.2v6h11.2V28.8H70.9v22.4c0,4.3-0.7,7.5-2.2,9.4c-1.4,1.9-3.8,2.8-7,2.8c-2.8,0-4.8-0.9-5.9-2.6
                c-1.1-1.7-1.7-4.4-1.7-7.9V28.8H42.5v26.3c0,2.6,0.2,5.1,0.7,7.2c0.5,2.2,1.3,4,2.4,5.5c1.2,1.5,2.8,2.7,4.8,3.5
                C52.4,72.2,55,72.6,58.2,72.6z M39.7,57.8c0-2-0.4-3.7-1.2-5c-0.8-1.4-1.8-2.5-3.1-3.4c-1.3-0.9-2.7-1.6-4.4-2.2
                c-1.6-0.5-3.3-1-5-1.4c-1.7-0.4-3.3-0.7-4.9-1.1c-1.6-0.3-3-0.7-4.3-1.1c-1.2-0.4-2.3-1-3-1.6c-0.8-0.7-1.2-1.5-1.2-2.6
                c0-0.9,0.2-1.6,0.7-2.1c0.4-0.5,1-0.9,1.6-1.2c0.6-0.3,1.3-0.5,2.1-0.5c0.8-0.1,1.5-0.1,2.2-0.1c2.1,0,3.9,0.4,5.5,1.2
                c1.6,0.8,2.4,2.3,2.6,4.6h11.2c-0.2-2.6-0.9-4.8-2-6.6c-1.1-1.7-2.6-3.1-4.3-4.2c-1.7-1-3.7-1.8-5.8-2.2c-2.2-0.4-4.4-0.7-6.7-0.7
                c-2.3,0-4.6,0.2-6.8,0.6c-2.2,0.4-4.2,1.1-6,2.2c-1.8,1-3.2,2.4-4.3,4.2c-1.1,1.8-1.6,4-1.6,6.8c0,1.9,0.4,3.5,1.2,4.8
                c0.8,1.3,1.8,2.4,3.1,3.2c1.3,0.9,2.7,1.5,4.4,2.1c1.6,0.5,3.3,1,5,1.4c4.2,0.9,7.5,1.8,9.8,2.6c2.4,0.9,3.5,2.2,3.5,4
                c0,1-0.2,1.9-0.7,2.6c-0.5,0.7-1.1,1.2-1.9,1.7c-0.7,0.4-1.6,0.7-2.5,0.9c-0.9,0.2-1.8,0.3-2.6,0.3c-1.2,0-2.3-0.1-3.4-0.4
                c-1.1-0.3-2-0.7-2.9-1.3c-0.8-0.6-1.5-1.3-2-2.2c-0.5-0.9-0.8-2-0.8-3.3H0c0.1,2.9,0.8,5.2,1.9,7.2c1.2,1.9,2.7,3.4,4.6,4.6
                c1.9,1.2,4,2,6.3,2.5c2.4,0.5,4.8,0.7,7.3,0.7c2.4,0,4.8-0.2,7.2-0.7c2.3-0.5,4.4-1.3,6.3-2.4c1.8-1.2,3.3-2.7,4.4-4.6
                C39.1,62.9,39.7,60.6,39.7,57.8z M274.8,67.8c1.2,1.5,2.8,2.7,4.8,3.5c2,0.8,4.6,1.2,7.8,1.2c2.5,0,4.9-0.6,7.3-1.7
                c2.4-1.1,4.3-2.9,5.8-5.4h0.2v6h11.2V28.8h-11.7v22.4c0,4.3-0.7,7.5-2.2,9.4c-1.4,1.9-3.8,2.8-7,2.8c-2.8,0-4.8-0.9-5.9-2.6
                c-1.1-1.7-1.7-4.4-1.7-7.9V28.8h-11.7v26.3c0,2.6,0.2,5.1,0.7,7.2C272.8,64.5,273.6,66.3,274.8,67.8z M316.5,28.9v42.7h11.7V39.9
                c-0.2,0-0.4,0-0.7,0C321.5,39.9,316.5,35,316.5,28.9z M316.5,28.7v0.1C316.5,28.8,316.5,28.8,316.5,28.7L316.5,28.7z M192.2,52.2
                c0-1.9,0.2-3.7,0.6-5.4c0.4-1.7,1-3.1,1.9-4.4c0.9-1.2,2.1-2.2,3.6-2.9c1.5-0.7,3.3-1.1,5.5-1.1c0.7,0,1.5,0,2.2,0.1
                c0.8,0.1,1.4,0.2,2,0.3V28c-0.9-0.3-1.8-0.4-2.6-0.4c-1.5,0-2.9,0.2-4.3,0.7c-1.4,0.4-2.7,1.1-3.9,1.9c-1.2,0.8-2.3,1.8-3.2,2.9
                c-0.9,1.1-1.7,2.4-2.2,3.7h-0.2v-7.9h-11.2v42.8h11.7V52.2z M176,58.4h-10.4c-0.4,1.3-1.4,2.6-3.1,3.7c-1.7,1.2-3.8,1.8-6.1,1.8
                c-3.3,0-5.9-0.9-7.6-2.6c-1.8-1.7-2.7-4.5-2.9-8.3h30.9c0.2-3.3-0.1-6.5-0.8-9.5c-0.8-3-2-5.8-3.8-8.1c-1.7-2.4-4-4.3-6.7-5.7
                c-2.7-1.4-5.9-2.1-9.5-2.1c-3.3,0-6.2,0.6-8.9,1.7c-2.7,1.2-5,2.8-6.9,4.8c-1.9,2-3.4,4.4-4.5,7.2c-1,2.8-1.6,5.7-1.6,9
                c0,3.3,0.5,6.4,1.5,9.1c1,2.8,2.5,5.1,4.3,7.1c1.9,2,4.2,3.5,6.9,4.6c2.7,1.1,5.7,1.6,9.1,1.6c4.9,0,9-1.1,12.4-3.3
                C171.8,67.1,174.4,63.5,176,58.4z M146.4,42.8c0.3-1.1,0.8-2,1.6-3c0.7-0.9,1.7-1.7,3-2.4c1.2-0.6,2.8-1,4.7-1c2.9,0,5,0.8,6.4,2.3
                c1.4,1.6,2.4,3.8,2.9,6.8h-19.1C145.9,44.7,146.1,43.8,146.4,42.8z M126.4,34.5c-1.6-2.1-3.6-3.8-6.1-5.1c-2.4-1.3-5.3-1.9-8.7-1.9
                c-2.7,0-5.1,0.5-7.3,1.6c-2.2,1.1-4,2.7-5.5,5.1h-0.2l0-5.5H87.6v57.8h11.7V66.3h0.2c1.4,2.1,3.3,3.7,5.5,4.8
                c2.2,1.1,4.7,1.6,7.3,1.6c3.2,0,5.9-0.6,8.3-1.8c2.3-1.2,4.3-2.8,5.9-4.9c1.6-2,2.7-4.4,3.5-7c0.8-2.7,1.2-5.4,1.2-8.3
                c0-3-0.4-6-1.2-8.8C129.2,39.1,128,36.6,126.4,34.5z M118.9,55.3c-0.3,1.7-0.9,3.1-1.7,4.3c-0.8,1.2-1.9,2.2-3.2,3
                c-1.3,0.8-2.9,1.2-4.9,1.2c-1.9,0-3.5-0.4-4.8-1.2c-1.3-0.8-2.4-1.8-3.2-3c-0.8-1.2-1.4-2.7-1.7-4.3c-0.4-1.7-0.5-3.3-0.5-5.1
                c0-1.8,0.2-3.5,0.5-5.1c0.3-1.7,0.9-3.1,1.7-4.4c0.8-1.3,1.9-2.3,3.2-3.1c1.3-0.8,2.9-1.2,4.9-1.2c1.9,0,3.5,0.4,4.8,1.2
                c1.3,0.8,2.4,1.8,3.2,3.1c0.8,1.3,1.4,2.8,1.8,4.4c0.4,1.7,0.5,3.3,0.5,5.1C119.4,52,119.3,53.7,118.9,55.3z M232.1,12.4
                c-4.8,0-8.4,1.2-10.7,3.6c-2.4,2.4-3.5,5.6-3.5,9.4v3.3h-7v7.8h7v34.9h11.7V36.6h8.1v-7.8h-8.1v-2.6c0-1.8,0.3-3,1-3.8
                c0.7-0.7,1.8-1.1,3.4-1.1c1.5,0,2.9,0.1,4.3,0.2v-8.8c-1-0.1-2-0.1-3.1-0.2C234.2,12.5,233.2,12.4,232.1,12.4z M268.9,38.9V28
                c-0.9-0.3-1.8-0.4-2.6-0.4c-1.5,0-2.9,0.2-4.3,0.7c-1.4,0.4-2.7,1.1-3.9,1.9c-1.2,0.8-2.3,1.8-3.2,2.9c-0.9,1.1-1.7,2.4-2.2,3.7
                h-0.2v-7.9h-11.2v42.8h11.7V52.2c0-1.9,0.2-3.7,0.6-5.4c0.4-1.7,1-3.1,1.9-4.4c0.9-1.2,2.1-2.2,3.6-2.9c1.5-0.7,3.3-1.1,5.5-1.1
                c0.7,0,1.5,0,2.2,0.1C267.7,38.7,268.4,38.8,268.9,38.9z M327.6,20.5c-4.2,0-7.6,3.4-7.6,7.6c0,4.2,3.4,7.6,7.6,7.6
                c4.2,0,7.6-3.4,7.6-7.6C335.2,23.9,331.8,20.5,327.6,20.5z M315.1,24.3c4.2,0,7.6-3.4,7.6-7.6c0-4.2-3.4-7.6-7.6-7.6
                c-4.2,0-7.6,3.4-7.6,7.6C307.6,20.9,310.9,24.3,315.1,24.3z M329.9,0c-4.2,0-7.6,3.4-7.6,7.6c0,4.2,3.4,7.6,7.6,7.6
                c4.2,0,7.6-3.4,7.6-7.6C337.5,3.4,334.1,0,329.9,0z"
      />
    </svg>
  ),
}
