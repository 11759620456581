/* eslint-disable max-len */
const programName = 'Fyrklövern'
export default {
  programName,
  logo: (
    <svg x="0px" y="0px" viewBox="0 0 672 135">
      <title>{programName}</title>
      <g id="Lager_1">
        <g>
          <path d="M158.5,41.7c0-0.8,0.6-1.5,1.5-1.5h31c0.9,0,1.5,0.7,1.5,1.5V44c0,0.8-0.6,1.5-1.5,1.5h-26.4V67h22.6
            c0.8,0,1.5,0.7,1.5,1.5v2.3c0,0.8-0.7,1.5-1.5,1.5h-22.6v21.5c0,0.8-0.7,1.5-1.5,1.5H160c-0.9,0-1.5-0.7-1.5-1.5V41.7z"
          />
          <path d="M219.6,69.1l-19-26.3c-0.6-1,0-2.3,1.3-2.3h3.7c0.5,0,1,0.4,1.3,0.7l15.9,22l15.9-22c0.2-0.3,0.7-0.7,1.3-0.7h3.7
            c1.3,0,1.9,1.3,1.3,2.3l-19.2,26.2v25.1c0,0.8-0.7,1.5-1.5,1.5h-3.1c-0.9,0-1.5-0.7-1.5-1.5L219.6,69.1L219.6,69.1z"
          />
          <path d="M255.7,42.1c0-0.8,0.6-1.5,1.5-1.5h20.4c9.4,0,17.1,7.4,17.1,16.8c0,7.2-4.8,13.3-11.6,16l10.8,20c0.5,1,0,2.3-1.3,2.3h-4
            c-0.7,0-1.1-0.4-1.3-0.7l-10.5-20.9h-15v20.1c0,0.8-0.7,1.5-1.5,1.5h-3.1c-0.9,0-1.5-0.7-1.5-1.5L255.7,42.1L255.7,42.1z
             M277.2,68.9c6.1,0,11.3-5,11.3-11.5c0-6-5.3-11.1-11.3-11.1h-15.3v22.6H277.2z"
          />
          <path d="M311.9,42.5c0-1,0.8-1.9,1.9-1.9h2.3c1,0,1.9,0.9,1.9,1.9v22.3l24.5-23.4c0.3-0.4,1-0.8,1.5-0.8h3.9
            c1.4,0,2.2,1.6,0.9,2.8l-24.9,23.2l26,26.7c0.5,0.5,0.4,2.4-1.3,2.4h-4c-0.5,0-1.3-0.3-1.4-0.5l-25.2-26.4v25c0,1-0.9,1.9-1.9,1.9
            h-2.3c-1.1,0-1.9-0.9-1.9-1.9L311.9,42.5L311.9,42.5z"
          />
          <path d="M363.3,41.3c0-0.8,0.6-1.5,1.5-1.5h3.1c0.8,0,1.5,0.7,1.5,1.5v48.3H392c0.9,0,1.5,0.7,1.5,1.5v2.3c0,0.8-0.6,1.5-1.5,1.5
            h-27.2c-0.9,0-1.5-0.7-1.5-1.5V41.3z"
          />
          <path d="M426.1,39c15.7,0,28.3,12.7,28.3,28.4c0,15.7-12.6,28.2-28.3,28.2c-15.7,0-28.2-12.5-28.2-28.2S410.4,39,426.1,39z
             M426.1,90.2c12.5,0,22.8-10.2,22.8-22.7c0-12.5-10.3-22.9-22.8-22.9c-12.5,0-22.7,10.4-22.7,22.9
            C403.4,79.9,413.6,90.2,426.1,90.2z M414.2,28.9c0-2.1,1.6-3.8,3.7-3.8c2.1,0,3.8,1.6,3.8,3.8c0,2-1.6,3.7-3.8,3.7
            C415.8,32.6,414.2,30.9,414.2,28.9z M430.8,28.9c0-2.1,1.7-3.8,3.7-3.8c2.1,0,3.8,1.6,3.8,3.8c0,2-1.7,3.7-3.8,3.7
            C432.4,32.6,430.8,30.9,430.8,28.9z"
          />
          <path d="M457.8,41.9c-0.5-1,0.2-2,1.3-2h3.2c0.7,0,1.2,0.5,1.3,0.9l19.6,44.1h0.3l19.6-44.1c0.2-0.4,0.7-0.9,1.3-0.9h3.2
            c1.2,0,1.8,1,1.3,2L485,94.8c-0.2,0.5-0.7,0.9-1.3,0.9h-0.8c-0.5,0-1.1-0.4-1.3-0.9L457.8,41.9z"
          />
          <path d="M518,41.3c0-0.8,0.6-1.5,1.5-1.5h31c0.9,0,1.5,0.7,1.5,1.5v2.3c0,0.8-0.6,1.5-1.5,1.5h-26.4v19.2h22.6
            c0.8,0,1.5,0.7,1.5,1.5V68c0,0.9-0.7,1.5-1.5,1.5h-22.6v20.1h26.4c0.9,0,1.5,0.7,1.5,1.5v2.3c0,0.8-0.6,1.5-1.5,1.5h-31
            c-0.9,0-1.5-0.7-1.5-1.5V41.3z"
          />
          <path d="M568.5,41.3c0-0.8,0.6-1.5,1.5-1.5h20.4c9.4,0,17.2,7.4,17.2,16.8c0,7.2-4.8,13.3-11.6,16l10.8,20c0.5,1,0,2.3-1.3,2.3h-4
            c-0.7,0-1.1-0.4-1.3-0.7l-10.5-20.9h-15v20.1c0,0.8-0.7,1.5-1.5,1.5H570c-0.9,0-1.5-0.7-1.5-1.5L568.5,41.3L568.5,41.3z M590,68.1
            c6.1,0,11.3-5,11.3-11.5c0-6-5.3-11.1-11.3-11.1h-15.3v22.6H590z"
          />
          <path d="M623,40.4c0-0.8,0.7-1.4,1.5-1.4h2l35.5,44.2c0.1,0,0.1,0,0.2,0V41.3c0-0.8,0.6-1.5,1.5-1.5h2.8c0.8,0,1.5,0.7,1.5,1.5
            v52.9c0,0.8-0.7,1.4-1.5,1.4h-1.4l-36.1-45.1h-0.1v42.8c0,0.8-0.6,1.5-1.5,1.5h-2.8c-0.8,0-1.5-0.7-1.5-1.5L623,40.4L623,40.4z"
          />
        </g>
      </g>
      <g id="Layer_1_1_">
        <g>
          <path d="M67.1,64.6L47,44.5c-5.5-5.5-5.5-14.5,0-20L67.1,4.3l20.1,20.1c5.5,5.5,5.5,14.5,0,20L67.1,64.6z" />
          <path d="M90.1,87.5L69.9,67.4l20.1-20.1c5.5-5.5,14.5-5.5,20,0l20.1,20.1l-20.1,20.1C104.6,93.1,95.6,93.1,90.1,87.5z" />
          <path d="M24.2,87.6L4,67.4l20.1-20.1c5.5-5.5,14.5-5.5,20,0l20.1,20.1L44.2,87.6C38.7,93.1,29.7,93.1,24.2,87.6z" />
          <path d="M67,130.4l-20.1-20.1c-5.5-5.5-5.5-14.5,0-20L67,70l20.1,20.1c5.5,5.5,5.5,14.5,0,20L67,130.4z" />
        </g>
      </g>
    </svg>
  ),
}
