/* eslint-disable max-len */
const programName = 'Calvin Klein'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 194 35" fill="#000">
      <title>{programName}</title>
      <path fill="#1A1919" fillRule="evenodd" d="M14.594.229c8.236 0 12.22 5.678 13.14 8.632h-3.448c-1.035-1.88-3.908-5.525-9.692-5.525-6.32 0-11.146 5.026-11.146 11.817 0 6.713 4.826 11.74 11.146 11.74 5.9 0 8.81-3.645 9.73-5.448h3.333C26.814 24.207 22.83 30 14.594 30 6.282 30 0 23.708 0 15.114 0 6.636 6.359.23 14.594.23zM154.488 8.86c5.899 0 10.304 4.719 10.15 11.317h-17.275c.23 3.875 3.18 6.945 7.201 6.945 4.022 0 5.976-2.417 6.78-4.106h3.027c-.767 2.763-3.755 6.945-9.807 6.945-5.899 0-10.266-4.566-10.266-10.55 0-5.947 4.406-10.551 10.19-10.551zm-114.841 0c4.558 0 6.703 2.915 7.316 4.258V9.321h3.026v20.18h-3.026v-3.798c-.651 1.381-2.758 4.259-7.316 4.259-5.746 0-10.266-4.527-10.266-10.55 0-6.024 4.52-10.551 10.266-10.551zm57.343 0c5.631 0 8.274 4.143 8.274 9.246V29.5H102.2V18.184c0-3.799-2.03-6.446-5.708-6.446-3.639 0-5.822 2.724-5.822 6.599V29.5h-3.026V9.321h3.026v3.376c.383-1.112 2.145-3.836 6.32-3.836zM119.208.728v15.345L132.69.727h3.984l-11.53 13.006 11.53 15.768h-3.83l-9.998-13.198-3.64 4.106V29.5h-3.332V.727h3.333zm64.43 8.133c5.631 0 8.274 4.143 8.274 9.246V29.5h-3.064V18.184c0-3.799-2.03-6.446-5.708-6.446-3.639 0-5.822 2.724-5.822 6.599V29.5h-3.026V9.321h3.026v3.376c.383-1.112 2.145-3.836 6.32-3.836zm-121.314.46l6.397 17.073L75.118 9.32h3.332l-7.814 20.18h-3.83L58.99 9.322h3.333zm21.643 0v20.18H80.94V9.321h3.027zm57.459-9.13V29.5h-3.027V.191h3.027zm29.189 9.13v20.18h-3.026V9.321h3.026zM56.693.191V29.5h-3.026V.191h3.026zM39.762 11.737c-4.214 0-7.278 3.3-7.278 7.673 0 4.335 3.064 7.673 7.278 7.673 4.175 0 7.24-3.337 7.24-7.673 0-4.335-3.065-7.673-7.24-7.673zm114.726-.076c-3.908 0-6.436 2.953-6.934 5.83h13.828c-.612-3.03-2.872-5.83-6.894-5.83zm-70.483-9.4v3.53h-3.103v-3.53h3.103zm86.648 0v3.53h-3.103v-3.53h3.103z" />
    </svg>
  ),
}
