/* eslint-disable max-len */
const programName = 'The Protein Works'
export default {
  programName,
  logo: (
    <svg className="proteinworks" viewBox="0 0 121.169 12.739">
      <title>{programName}</title>
      <g transform="translate(60.085 6.369)">
        <g transform="translate(-60.085 -6.369)">
          <path className="a" d="M1.7,6.433H3.834l.349-3.989H6.274L6.405.6H.174L0,2.444H2.048Z" transform="translate(0 -0.3)" />
          <path className="a" d="M2.223,15.3,1.7,21.176H3.835L4.358,15.3Z" transform="translate(-0.959 -8.695)" />
          <path className="a" d="M39.68,6.433l.044-.858H36.979l.305-3.131h3.573L40.987.6H35.279L34.8,6.433Z" transform="translate(-19.637 -0.3)" />
          <path className="a" d="M35.014,15.772h2.745L37.8,15.3h-4.88L32.4,21.176h5.969l.174-1.8H34.709Z" transform="translate(-18.283 -8.695)" />
          <path className="a" d="M14.123,15.3,13.6,21.176h2.135l.479-5.4H18.48L18,21.176h2.135l.523-5.876Z" transform="translate(-7.674 -8.695)" />
          <path className="a" d="M22.236,6.433,22.759.6H20.624l-.436,4.975H17.922L18.358.6H16.223L15.7,6.433Z" transform="translate(-8.859 -0.3)" />
          <path className="b" d="M111.274,6.319h2.179l.218-4.075h2.091L115.849.4h-6.318L109.4,2.244h2.091Z" transform="translate(-61.732 -0.186)" />
          <path className="b" d="M112,15.3l-.305,5.962h2.179l.305-5.962Z" transform="translate(-63.03 -8.695)" />
          <path className="b" d="M51.805,15.243,51.5,21.2h2.179l.261-5.1H54.9a4.174,4.174,0,0,0,3.05-.9Z" transform="translate(-29.06 -8.638)" />
          <path className="b" d="M60.092,6.319a4.534,4.534,0,0,0,.61-2.23C60.833,1.429,59.656.4,57,.4H53.9L53.6,6.319ZM55.953,2.159h.828c1.394,0,1.83.429,1.743,1.887S58,5.933,56.563,5.933h-.828Z" transform="translate(-30.245 -0.186)" />
          <path className="b" d="M78.487,6.319a4.366,4.366,0,0,0,.654-2.273C79.272,1.387,78.1.4,75.655.4H72.6L72.3,6.319ZM74.7,2.159h.784c1.176,0,1.569.429,1.525,1.887s-.523,1.887-1.7,1.887h-.828Z" transform="translate(-40.797 -0.186)" />
          <path className="b" d="M76.356,15.3H70.6L70.3,21.262h2.179l.261-5.1h1.133L75.136,21.3h2.179L75.79,15.643Z" transform="translate(-39.669 -8.695)" />
          <path className="b" d="M93.935,6.091l.131-2.831c.044-.944.349-1.373,1.22-1.373s1.133.429,1.089,1.373l-.131,2.831h2.179L98.6,3.174A2.821,2.821,0,0,0,95.46,0a3.188,3.188,0,0,0-3.486,3.174L91.8,6.091Z" transform="translate(-51.801 0.043)" />
          <path className="b" d="M94.655,15.3l-.174,2.917c-.044.944-.349,1.373-1.22,1.373s-1.133-.429-1.089-1.373l.174-2.917H90.167l-.174,2.96a2.821,2.821,0,0,0,3.137,3.174,3.188,3.188,0,0,0,3.486-3.174l.174-2.96Z" transform="translate(-50.778 -8.695)" />
          <path className="b" d="M125.64,15.772h2.789l.044-.472h-4.967l-.305,5.962h6.013l.131-1.844h-3.878Z" transform="translate(-69.519 -8.695)" />
          <path className="b" d="M130.167,6.319l.044-.858h-2.789l.174-3.217h3.616L131.3.4h-5.8L125.2,6.319Z" transform="translate(-70.648 -0.186)" />
          <path className="b" d="M141.349,15.3,141,21.262h2.179l.305-5.962Z" transform="translate(-79.564 -8.695)" />
          <path className="b" d="M145.235,6.319,145.54.4H143.4l-.3,5.919Z" transform="translate(-80.749 -0.186)" />
          <path className="b" d="M165.261,6.319l.3-5.919H163.6L163.3,6.319Z" transform="translate(-92.147 -0.186)" />
          <path className="b" d="M154.561,6.319l.044-1.244h.044l.392,1.244h1.743L154.909.4h-2l-.3,5.919Z" transform="translate(-86.109 -0.186)" />
          <path className="b" d="M150.9,15.3l-.305,5.962h1.961l.305-5.962Z" transform="translate(-84.981 -8.695)" />
          <path className="b" d="M159.348,15.3l-.044.686h0l-.218-.686H157.3l1.83,5.962H161l.305-5.962Z" transform="translate(-88.761 -8.695)" />
          <path className="a" d="M193.83,6.476,195.181.6h-2L192,6.476Z" transform="translate(-108.342 -0.3)" />
          <path className="a" d="M183.069,6.476l.436-1.716h0l.261,1.716h1.612L184.594.6h-1.743L181.5,6.476Z" transform="translate(-102.417 -0.3)" />
          <path className="a" d="M176.9,15.4l-.349,1.415H176.5l-.131-1.415H174.5l.828,5.833h1.786l1.351-5.833Z" transform="translate(-98.467 -8.752)" />
          <path className="a" d="M187.735,15.4l-.3,1.415h-.044l-.174-1.415H185.6l.828,5.833h1.786l1.351-5.833Z" transform="translate(-104.73 -8.752)" />
          <path className="a" d="M176.4,6.476,175.7.6h-2l.828,5.876Z" transform="translate(-98.015 -0.3)" />
          <path className="a" d="M203.535,6.3l.131-2.831c.044-.9.349-1.373,1.22-1.373s1.089.429,1.046,1.373L205.8,6.3h2.135l.174-2.874A2.8,2.8,0,0,0,205.016.3a3.166,3.166,0,0,0-3.442,3.131L201.4,6.3Z" transform="translate(-113.646 -0.128)" />
          <path className="a" d="M204.568,15.4l-.131,2.831c-.044.9-.349,1.373-1.22,1.373s-1.089-.429-1.046-1.373L202.3,15.4h-2.135l-.174,2.874a2.8,2.8,0,0,0,3.094,3.131,3.166,3.166,0,0,0,3.442-3.131L206.7,15.4Z" transform="translate(-112.849 -8.752)" />
          <path className="a" d="M225.326,15.4H219.7l-.3,5.833h2.135l.261-5.018h1.089l1.264,5.018h2.179L224.8,15.7Z" transform="translate(-123.803 -8.752)" />
          <path className="a" d="M226.856,6.476A4.24,4.24,0,0,0,227.51,4.2c.131-2.616-1.046-3.6-3.4-3.6H221.1L220.8,6.433Zm-3.7-4.118h.741c1.133,0,1.569.429,1.481,1.887s-.523,1.887-1.7,1.887h-.741Z" transform="translate(-124.593 -0.3)" />
          <path className="a" d="M245.262,6.476l-.349-1.33L247.441.6h-2.135l-2.484,4.547h-.044L243.04.6H240.9l-.3,5.876Z" transform="translate(-135.766 -0.3)" />
          <path className="a" d="M239.2,15.4l-.3,5.833h2.135l.218-4.332.828-1.33h.044l1.438,5.662H245.7L244.041,15.4Z" transform="translate(-134.807 -8.752)" />
          <path className="a" d="M263.209,6.3c-1.133-1.029-2.353-1.844-2.309-3s.436-1.2,1.133-1.2,1.046.3,1.046,1.373l2.048-.214C265.039,1.544,264.081.3,262.338.3A3.246,3.246,0,0,0,258.9,3.345a3.83,3.83,0,0,0,1.22,2.96Z" transform="translate(-146.088 -0.128)" />
          <path className="a" d="M258.009,15.4c1.089.987,2.309,1.759,2.222,3.045a1.151,1.151,0,0,1-.405.839,1.189,1.189,0,0,1-.9.276c-.7,0-1.133-.6-1.176-1.844l-2.048.3c0,1.844,1.046,3.388,3.006,3.388a3.212,3.212,0,0,0,3.529-3.131,3.829,3.829,0,0,0-1.176-2.874Z" transform="translate(-144.286 -8.752)" />
          <text className="c" transform="translate(119.169 1)">
            <tspan x="0" y="0">TM</tspan>
          </text>
        </g>
      </g>
    </svg>
  ),
}
