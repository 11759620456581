/* eslint-disable max-len */
const programName = 'Lindex'
export default {
  programName,
  logo: (
    <svg id="logo" viewBox="0 0 226.57 40">
      <title>{programName}</title>
      <g>
        <polygon points="5.88 0.62 0 0.62 0 39.04 23.65 39.04 23.65 34.41 5.88 34.41 5.88 0.62" />
        <rect x="36.71" y="0.62" width="6.03" height="38.41" />
        <polygon points="88.71 28.07 57.97 0.01 57.97 39.03 62.83 39.03 62.83 12.18 93.6 40 93.6 39.03 93.6 0.62 88.73 0.62 88.71 28.07" />
        <path d="M123.34.62H109.13V39h14.69c12.67,0,20.71-9.34,20.71-19.2C144.53,10.54,138.42,1.25,123.34.62Zm0,34h-8.45V4.69h8.18C134,5.14,138,11.25,138,19.84,138,24.59,136.52,34,123.36,34.64Z" />
        <polygon points="209.76 18.47 224.7 0.62 218.38 0.62 206.69 14.98 195.35 0.57 187.84 0.57 203.22 19.15 186.41 39.03 192.79 39.03 206.15 22.76 219.02 39.12 226.57 39.09 209.76 18.47" />
        <path d="M169.18,34.64c-4-.43-6.93-2.6-7.29-7.25-.66-7.59,4.89-8.22,4.89-8.22-3.36-.39-5-3.68-4.79-7.71.35-5.07,3.92-7.19,8.42-7.19a15.3,15.3,0,0,1,6.16,1.54V1.29A26.78,26.78,0,0,0,169.41,0c-7.58-.47-13,4.18-13,10.06,0,4.17,1.71,7.23,5,9-3.83,1.19-5.57,5.32-5.57,8.81,0,6.88,4.87,11.69,13.66,11.62a19.23,19.23,0,0,0,7.94-1.42V33C175.16,34.55,171.68,34.93,169.18,34.64Z" />
      </g>
    </svg>
  ),
}
