/* eslint-disable max-len */
const programName = 'Mos Mosh'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 721 119">
      <g stroke="none" strokeWidth="1" fill="#000000" fillRule="evenodd">
        <path d="M348.538,116.531 C354.127,116.531 359.712,116.531 365.301,116.531 C366.764,116.531 368.228,116.531 369.691,116.531 C369.691,86.12 369.691,55.704 369.691,25.293 C369.797,25.293 369.903,25.293 370.01,25.293 C376.795,55.704 383.579,86.12 390.364,116.531 C396.349,116.531 402.333,116.531 408.319,116.531 C415.103,86.12 421.888,55.704 428.672,25.293 C428.779,25.293 428.885,25.293 428.992,25.293 C428.992,55.704 428.992,86.12 428.992,116.531 C436.044,116.531 443.096,116.531 450.149,116.531 C450.149,95.329 450.149,74.127 450.149,52.926 C450.149,36.032 450.149,19.137 450.149,2.243 C438.771,2.243 427.392,2.243 416.014,2.243 C410.509,29.137 405.009,56.027 399.505,82.921 C399.398,82.921 399.288,82.921 399.182,82.921 C393.733,56.027 388.284,29.137 382.835,2.243 C374.434,2.243 366.033,2.243 357.632,2.243 C354.599,2.243 351.57,2.243 348.538,2.243 C348.538,19.137 348.538,36.032 348.538,52.926 C348.538,74.127 348.538,95.329 348.538,116.531" />
        <path d="M582.997,96.999 C581.734,95.397 580.981,93.469 580.594,91.476 C580.164,89.293 579.952,86.863 579.952,84.195 C579.952,83.078 579.952,81.957 579.952,80.84 C572.257,80.84 564.566,80.84 556.871,80.84 C556.871,82.386 556.871,83.932 556.871,85.478 C556.871,96.039 559.674,104.152 565.285,109.811 C570.895,115.461 580.215,118.29 593.257,118.29 C604.065,118.29 612.82,116.238 619.519,112.126 C629.549,105.979 633.003,94.981 633.003,83.72 C633.003,80.194 632.684,76.991 632.042,74.111 C631.4,71.23 630.251,68.588 628.596,66.192 C626.938,63.788 624.721,61.545 621.944,59.464 C619.166,57.382 615.584,55.386 611.207,53.465 C605.278,50.904 599.344,48.347 593.414,45.785 C588.497,43.754 585.294,41.537 583.797,39.141 C582.299,36.737 581.555,33.78 581.555,30.254 C581.555,27.047 582.082,23.589 584.201,21.053 C586.753,17.944 590.845,16.967 594.699,16.967 C599.187,16.967 602.389,18.436 604.312,21.367 C606.235,24.307 607.196,28.496 607.196,33.933 C608.013,33.933 608.829,33.933 609.646,33.933 C616.205,33.933 622.76,33.933 629.319,33.933 C629.319,28.05 629.145,22.187 626.674,16.729 C624.913,12.838 622.45,9.635 619.298,7.128 C616.146,4.622 612.407,2.804 608.081,1.683 C603.755,0.561 599.025,0 593.895,0 C582.576,0 570.219,3.309 563.638,13.258 C560.193,18.466 558.474,25.254 558.474,33.618 C558.474,38.309 559.057,42.446 560.24,46.023 C561.409,49.6 563.256,52.743 565.765,55.462 C568.275,58.189 571.402,60.559 575.144,62.59 C578.88,64.612 583.316,66.481 588.446,68.189 C592.291,69.472 595.524,70.729 598.144,71.952 C602.249,73.873 606.269,76.676 607.923,81.077 C609.421,85.147 609.587,90.576 607.575,94.701 C605.346,99.263 600.127,101.807 595.18,101.807 C590.683,101.807 585.907,100.707 582.997,96.999" />
        <path d="M647.337,116.531 C655.027,116.531 662.722,116.531 670.413,116.531 C670.413,99.616 670.413,82.7 670.413,65.785 C679.388,65.785 688.368,65.785 697.343,65.785 C697.343,82.7 697.343,99.616 697.343,116.531 C701.124,116.531 704.906,116.531 708.687,116.531 C712.601,116.531 716.51,116.531 720.423,116.531 C720.423,97.504 720.423,78.477 720.423,59.451 C720.423,40.382 720.423,21.312 720.423,2.243 C716.829,2.243 713.235,2.243 709.64,2.243 C705.54,2.243 701.443,2.243 697.343,2.243 C697.343,16.809 697.343,31.372 697.343,45.938 C688.368,45.938 679.388,45.938 670.413,45.938 C670.413,31.372 670.413,16.809 670.413,2.243 C662.722,2.243 655.027,2.243 647.337,2.243 C647.337,21.312 647.337,40.382 647.337,59.451 C647.337,78.477 647.337,97.504 647.337,116.531" />
        <path d="M543.741,83.397 C544.541,76.141 544.945,68.138 544.945,59.387 C544.945,50.746 544.541,42.769 543.741,35.454 C542.937,28.147 541.176,21.877 538.45,16.652 C535.727,11.419 531.797,7.341 526.671,4.401 C521.541,1.47 514.651,0 505.994,0 C497.338,0 490.447,1.47 485.321,4.401 C480.191,7.341 476.265,11.419 473.539,16.652 C470.812,21.877 469.051,28.147 468.247,35.454 C467.448,42.769 467.048,50.746 467.048,59.387 C467.048,68.138 467.448,76.141 468.247,83.397 C469.051,90.652 470.812,96.897 473.539,102.122 C476.265,107.355 480.191,111.357 485.321,114.127 C490.447,116.905 497.338,118.29 505.994,118.29 C514.651,118.29 521.541,116.905 526.671,114.127 C531.797,111.357 535.727,107.355 538.45,102.122 C541.176,96.897 542.937,90.652 543.741,83.397 Z M521.299,78.996 C520.925,84.382 520.176,88.758 519.057,92.122 C517.939,95.478 516.335,97.933 514.251,99.48 C512.166,101.034 509.414,101.807 505.994,101.807 C502.574,101.807 499.822,101.034 497.742,99.48 C495.658,97.933 494.054,95.478 492.931,92.122 C491.812,88.758 491.064,84.382 490.689,78.996 C490.315,73.609 490.124,67.067 490.124,59.387 C490.124,51.707 490.315,45.199 490.689,39.855 C491.064,34.519 491.812,30.152 492.931,26.737 C494.054,23.322 495.658,20.84 497.742,19.286 C499.822,17.74 502.574,16.967 505.994,16.967 C509.414,16.967 512.166,17.74 514.251,19.286 C516.335,20.84 517.939,23.322 519.057,26.737 C520.176,30.152 520.925,34.519 521.299,39.855 C521.673,45.199 521.865,51.707 521.865,59.387 C521.865,67.067 521.673,73.609 521.299,78.996 L521.299,78.996 Z" />
        <path d="M0.5,116.531 C2.456,116.531 4.411,116.531 6.368,116.531 C11.466,116.531 16.564,116.531 21.662,116.531 C21.662,86.12 21.662,55.704 21.662,25.293 C21.768,25.293 21.875,25.293 21.981,25.293 C28.766,55.704 35.55,86.12 42.335,116.531 C48.32,116.531 54.304,116.531 60.29,116.531 C66.149,90.27 72.008,64.005 77.868,37.743 C78.197,36.265 80.018,25.293 80.967,25.293 C80.967,55.704 80.967,86.12 80.967,116.531 C84.972,116.531 88.976,116.531 92.981,116.531 C96.027,116.531 99.075,116.531 102.12,116.531 C102.12,113.829 102.12,111.128 102.12,108.426 C102.12,92.08 102.12,75.733 102.12,59.387 C102.12,46.766 102.12,34.146 102.12,21.525 C102.12,15.097 102.12,8.67 102.12,2.243 C100.474,2.243 98.828,2.243 97.182,2.243 C87.447,2.243 77.715,2.243 67.98,2.243 C65.713,13.326 63.444,24.409 61.177,35.492 C57.941,51.303 54.706,67.11 51.471,82.921 C51.365,82.921 51.259,82.921 51.153,82.921 C45.701,56.027 40.253,29.137 34.801,2.243 C24.429,2.243 14.056,2.243 3.684,2.243 C2.623,2.243 1.561,2.243 0.5,2.243 C0.5,8.67 0.5,46.766 0.5,59.387 C0.5,75.733 0.5,92.08 0.5,108.426 C0.5,111.128 0.5,113.829 0.5,116.531" />
        <path d="M238.426,96.999 C237.162,95.397 236.409,93.469 236.022,91.476 C235.593,89.293 235.38,86.863 235.38,84.195 C235.38,83.078 235.38,81.957 235.38,80.84 C227.687,80.84 219.992,80.84 212.299,80.84 C212.299,82.386 212.299,83.932 212.299,85.478 C212.299,96.039 215.103,104.152 220.713,109.811 C226.324,115.461 235.644,118.29 248.685,118.29 C262.012,118.29 277.568,114.921 284.442,102.22 C288.966,93.868 289.459,83.023 287.47,74.111 C286.126,68.04 282.279,63.142 277.372,59.464 C271.81,55.296 265.181,52.841 258.854,50.105 C252.726,47.459 242.996,45.178 239.225,39.141 C237.728,36.737 236.983,33.78 236.983,30.254 C236.983,27.047 237.511,23.589 239.629,21.053 C242.877,17.098 249.121,16.232 253.866,17.374 C261.24,19.15 262.629,27.468 262.629,33.933 C270.003,33.933 277.374,33.933 284.748,33.933 C284.748,32.867 284.748,31.805 284.748,30.739 C284.748,21.792 281.947,12.872 274.73,7.128 C267.64,1.495 258.15,0 249.323,0 C238.315,0 229.663,2.778 223.359,8.326 C217.05,13.874 213.903,22.302 213.903,33.618 C213.903,40.343 215.126,47.251 219.075,52.849 C222.012,56.991 226.136,60.186 230.573,62.59 C234.308,64.612 238.744,66.481 243.874,68.189 C247.72,69.472 250.952,70.729 253.573,71.952 C256.189,73.176 258.299,74.544 259.902,76.03 C261.506,77.526 262.65,79.208 263.352,81.077 C264.041,82.946 264.39,85.155 264.39,87.721 C264.39,92.521 262.973,96.073 260.145,98.367 C257.312,100.661 254.134,101.807 250.608,101.807 C246.112,101.807 241.337,100.707 238.426,96.999" />
        <polyline points="377.104 54.51 376.942 54.328 376.942 54.383 377.104 54.51" />
        <path d="M178.974,4.401 C173.844,1.47 166.954,0 158.297,0 C149.641,0 142.75,1.47 137.62,4.401 C132.49,7.341 128.564,11.419 125.842,16.652 C123.111,21.877 121.354,28.147 120.55,35.454 C119.751,42.769 119.346,50.746 119.346,59.387 C119.346,68.138 119.751,76.141 120.55,83.397 C121.354,90.652 123.111,96.897 125.842,102.122 C128.564,107.355 132.49,111.357 137.62,114.127 C142.75,116.905 149.641,118.29 158.297,118.29 C166.954,118.29 173.844,116.905 178.974,114.127 C184.1,111.357 188.03,107.355 190.753,102.122 C193.475,96.897 195.24,90.652 196.04,83.397 C196.844,76.141 197.244,68.138 197.244,59.387 C197.244,50.746 196.844,42.769 196.04,35.454 C195.24,28.147 193.475,21.877 190.753,16.652 C188.03,11.419 184.1,7.341 178.974,4.401 Z M173.602,78.996 C173.228,84.382 172.479,88.758 171.36,92.122 C170.238,95.478 168.634,97.933 166.549,99.48 C164.465,101.034 161.717,101.807 158.297,101.807 C154.877,101.807 152.125,101.034 150.041,99.48 C147.956,97.933 146.353,95.478 145.234,92.122 C144.111,88.758 143.363,84.382 142.993,78.996 C142.614,73.609 142.427,67.067 142.427,59.387 C142.427,51.707 142.614,45.199 142.993,39.855 C143.363,34.519 144.111,30.152 145.234,26.737 C146.353,23.322 147.956,20.84 150.041,19.286 C152.125,17.74 154.877,16.967 158.297,16.967 C161.717,16.967 164.465,17.74 166.549,19.286 C168.634,20.84 170.238,23.322 171.36,26.737 C172.479,30.152 173.228,34.519 173.602,39.855 C173.972,45.199 174.168,51.707 174.168,59.387 C174.168,67.067 173.972,73.609 173.602,78.996 L173.602,78.996 Z" />
      </g>
    </svg>
  ),
}
