/* eslint-disable max-len */
const programName = 'By Malina'
export default {
  programName,
  logo: (
    <svg width="130" height="13">
      <title>{programName}</title>
      <defs>
        <path id="a" d="M0 .076h9.104v12.15H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path fill="#020203" d="M43.105.059l-5.058 10.517L32.97.059h-2.056v11.996h1.398V1.69h.041l5.036 10.366h1.316L43.72 1.69h.043v10.366h1.397V.06zm14.811 0l-5.92 11.996h1.5l1.768-3.729h6.683l1.79 3.73h1.5L59.479.058h-1.562zm.72 1.141l2.837 6.15h-5.777l2.94-6.15zM72.116.059v11.996h9.622v-.973h-8.224V.059zM88.8 12.055h1.398V.058H88.8zM108.938.059v10.148h-.042L100.346.059h-1.563v11.996h1.398V1.906h.042l8.552 10.15h1.561V.058zM122.654 0l-5.921 11.997h1.5l1.768-3.73h6.683l1.788 3.73h1.501L124.216 0h-1.562zm.72 1.142l2.837 6.15h-5.777l2.94-6.15z" />
        <g transform="translate(0 .116)">
          <mask id="b" fill="#fff">
            <use href="#a" />
          </mask>
          <path d="M.749 11.579h4.118c2.144 0 3.488-.953 3.488-2.757 0-2.076-1.889-2.586-3.488-2.586H.749v5.343zm0-5.99h4.118c2.025 0 3.08-1.02 3.08-2.501 0-1.753-1.464-2.365-3.08-2.365H.749v4.866zM0 .076h4.867c2.892 0 3.828 1.582 3.828 3.148 0 1.31-.953 2.416-2.365 2.586l.034.034c.323-.05 2.74.528 2.74 2.978 0 2.212-1.702 3.403-4.237 3.403H0V.075z" fill="#020203" mask="url(#b)" />
        </g>
        <path fill="#020203" d="M21.321.192h.834L17.27 7.254v5.087h-.749V7.254L11.673.192h.85l4.39 6.432z" />
      </g>
    </svg>
  ),
}
