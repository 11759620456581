/* eslint-disable max-len */
const programName = 'Stor&Liten'
export default {
  programName,
  logo: (
    <svg className="storliten" x="0px" y="0px" viewBox="0 0 300 38">
      <title>{programName}</title>
      <g id="Lager_1">
        <g>
          <path
            className="st0"
            d="M15.7,1.2c1.7,0,4,0.4,6.9,1.3c0.4,0.1,0.6,0.1,0.8,0.1c0.1,0,0.7-0.1,1.5-0.4C25.2,2.1,25.4,2,25.8,2
                        c1.1,0,2.3,0.9,3.6,2.7c1.3,1.8,1.9,3.5,1.9,5.2c0,1-0.3,1.7-0.8,2.4c-0.6,0.7-1.3,1-2.1,1c-0.7,0-1.2-0.1-1.6-0.4
                        c-0.4-0.3-1.3-1-2.8-2.4c-2-1.9-4-2.8-6-2.8c-1,0-1.7,0.2-2.2,0.7c-0.6,0.4-0.8,1-0.8,1.8c0,1.5,1.3,2.6,3.9,3.4
                        c3.5,1,5.8,1.8,6.7,2.2c4.2,2,6.4,5.2,6.4,9.2c0,3.6-1.5,6.6-4.4,8.8c-3,2.3-7,3.5-11.8,3.5c-2.2,0-4.5-0.2-6.9-0.7
                        s-4.1-1.1-5.2-1.8c-0.8-0.5-1.6-1.7-2.2-3.5c-0.7-1.9-1.1-3.7-1.1-5.5c0-0.8,0.1-1.5,0.5-1.9c0.4-0.5,1-0.8,1.6-0.8
                        c0.7,0,1.3,0.3,1.9,1c0.4,0.4,1.2,1.6,2.5,3.6c0.6,1,1.6,1.7,3,2.4c1.3,0.6,2.8,1,4.3,1c1.3,0,2.2-0.2,3.1-0.7
                        c0.7-0.4,1.2-1,1.2-1.8c0-0.7-0.3-1.3-0.9-1.7c-0.6-0.5-1.6-1-3-1.3c-2.4-0.7-4.3-1.5-5.5-2.1c-1.3-0.7-2.5-1.4-3.6-2.4
                        c-2.7-2.4-4-5.1-4-8.1c0-1.5,0.4-2.9,1-4.3S4,6,5.2,5C8.1,2.4,11.5,1.2,15.7,1.2"
          />
          <path
            className="st0"
            d="M49,18v8.6c0,1,0.2,1.9,0.7,2.5c0.5,0.7,1.1,1,1.9,1c0.7,0,1.6-0.3,2.6-0.9c0.2-0.1,0.5-0.2,0.7-0.2
                        c0.4,0,0.7,0.2,1.1,0.7c0.4,0.5,0.5,1,0.5,1.6c0,1-0.6,2.1-1.7,3c-2.4,2-5.3,3.1-8.8,3.1c-2.7,0-4.9-0.7-6.5-2.3S37,31.5,37,29
                        V17.2c0-0.4-0.1-0.6-0.1-0.7c-0.1-0.1-0.3-0.1-0.7-0.1h-2.6c-0.5,0-0.8-0.1-1-0.2c-0.1-0.1-0.2-0.5-0.2-1.1V14
                        c0-0.5,0.2-0.9,0.6-1.1l11.7-7.3c0.3-0.1,0.7-0.2,1-0.2H48c0.4,0,0.7,0.1,0.8,0.3s0.2,0.4,0.2,1v3.4c0,0.5,0.1,0.8,0.2,1
                        c0.1,0.1,0.5,0.2,1,0.2H55c0.6,0,1,0.1,1.2,0.4s0.3,0.7,0.3,1.5v1.5c0,0.8-0.1,1.3-0.4,1.7c-0.2,0.3-0.7,0.4-1.3,0.4h-4.6
                        c-0.4,0-0.8,0.1-1,0.2C49.1,17.2,49,17.5,49,18"
          />
          <path
            className="st0"
            d="M71.5,17.2c-0.7,0-1.3,0.3-1.8,0.8c-0.4,0.5-0.7,1.3-0.7,2.2c0,1,0.2,2.4,0.6,4.2c0.4,1.7,0.8,3.3,1.3,4.6
                        c0.5,1.4,1.3,2.1,2.5,2.1c1.5,0,2.2-1,2.2-2.8c0-1.4-0.2-3.1-0.6-4.9s-0.8-3.3-1.4-4.4C73.3,17.8,72.6,17.2,71.5,17.2 M73.6,11.2
                        c3.1,0,5.8,0.8,8.2,2.4c1.6,1.1,3,2.6,4,4.5s1.6,4,1.6,6.1c0,3.7-1.7,6.8-5.1,9.4c-3.2,2.5-6.9,3.7-11.1,3.7c-4,0-7.3-1.1-9.9-3.4
                        c-2.5-2.2-3.7-5.2-3.7-8.8c0-4,1.5-7.3,4.5-9.8C65.3,12.6,69.1,11.2,73.6,11.2"
          />
          <path
            className="st0"
            d="M101.1,11.5c0.9,0,1.4,0.1,1.6,0.4c0.2,0.2,0.4,1,0.5,2.5c0.1,0.7,0.4,1.1,0.8,1.1s0.7-0.2,1-0.7
                        c1.3-2.1,3.1-3.2,5.3-3.2c1.6,0,2.8,0.5,3.8,1.5c0.4,0.5,0.9,1.1,1.2,1.9c0.3,0.7,0.4,1.5,0.4,2.2c0,0.8-0.2,1.6-0.5,2.5
                        c-0.4,0.8-0.8,1.6-1.4,2.1c-1.1,1-2.4,1.6-4,1.6c-0.9,0-1.6-0.1-2.2-0.4s-1.3-0.9-2.3-1.9c-0.4-0.4-0.7-0.5-1-0.5
                        c-0.4,0-0.7,0.3-0.9,0.8c-0.1,0.5-0.2,1.6-0.2,3.4c0,2.5,0.1,4.3,0.3,5.2c0.1,0.5,0.3,0.9,0.5,1c0.2,0.2,0.7,0.4,1.3,0.6
                        c1.5,0.4,2.3,1.3,2.3,2.4c0,0.7-0.2,1.3-0.7,1.8s-1.1,0.9-1.9,1c-1.6,0.4-4,0.5-7.2,0.5c-3,0-5.3-0.3-6.9-0.8
                        C89.6,36,89,35.2,89,34c0-0.9,0.5-1.6,1.5-2.4c0.5-0.3,0.8-0.6,1-0.9c0.1-0.3,0.3-0.6,0.4-1c0.3-1.6,0.4-4.4,0.4-8.6
                        c0-0.7-0.1-1.3-0.2-1.6s-0.5-0.6-1-0.8c-1.2-0.4-1.8-1.2-1.8-2.1c0-0.8,0.4-1.5,1.3-2.1c0.8-0.6,2.2-1.2,4.2-1.7
                        C97.1,11.8,99.3,11.5,101.1,11.5"
          />
          <path
            className="st0"
            d="M133,0.5c2.3,0,4.4,0.4,6.1,1.4c1.7,1,2.5,2.1,2.5,3.4c0,0.7-0.3,1.4-0.9,2c-0.6,0.6-1.3,0.9-1.9,0.9
                        c-0.3,0-0.6-0.1-0.8-0.1c-0.2-0.1-0.7-0.4-1.7-1.1c-0.8-0.5-1.6-0.8-2.5-0.8s-1.6,0.3-2.2,1s-0.8,1.4-0.8,2.4
                        c0,1.3,0.5,2.2,1.5,2.8c0.3,0.1,1,0.3,2.3,0.4c1,0.1,1.6,0.8,1.6,2.1c0,1.4-0.6,2.4-1.7,3.1c-0.5,0.3-1.4,0.5-2.8,0.7
                        c-0.8,0.1-1.2,0.9-1.2,2.3c0,1.7,0.5,3.4,1.6,5.2c1,1.6,2.2,2.8,3.8,3.7c1.4,0.8,2.9,1.3,4.4,1.3c2,0,3.9-0.6,5.4-1.9
                        c1.4-1.1,2.1-2.5,2.1-4.2c0-0.8-0.3-1.5-1-2.1s-1.4-0.9-2.3-0.9c-1.4,0-2.6,0.9-3.6,2.6c-0.4,0.7-0.9,1.1-1.3,1.1
                        c-0.6,0-1.1-0.4-1.6-1c-0.4-0.7-0.7-1.6-0.7-2.5c0-2.1,0.7-3.9,2.2-5.5s3.6-2.9,6.4-4c2.2-0.8,3.6-1.5,4.1-1.9
                        c0.6-0.5,0.9-1.1,0.9-1.7c0-0.4-0.1-0.6-0.4-0.9c-0.2-0.2-0.5-0.4-0.8-0.4c-0.1,0-0.4,0.1-0.9,0.3c-0.1,0-0.2,0.1-0.4,0.1
                        c-0.3,0-0.5-0.1-0.7-0.3s-0.3-0.4-0.3-0.7c0-0.7,0.4-1.4,1-2.1c0.6-0.6,1.4-1,2.5-1.4s2-0.5,3.1-0.5c1.9,0,3.5,0.4,4.9,1.4
                        c0.8,0.5,1.4,1.3,1.9,2.2c0.4,0.9,0.7,1.9,0.7,2.8c0,1.5-0.5,2.9-1.5,4.3s-2.3,2.5-4,3.4c-1.3,0.7-1.9,1.3-1.9,1.7
                        c0,0.4,0.4,0.8,1.3,1.3c0.7,0.4,1.3,1.1,1.8,1.9c0.4,0.8,0.7,1.7,0.7,2.7c0,1.6-0.6,3.2-1.8,4.9c-1.8,2.5-4.6,4.4-8.3,5.8
                        c-3.1,1.1-6.5,1.7-10.4,1.7c-5.1,0-9.4-1-13-2.8c-2.6-1.3-4.6-3.3-6.1-5.9c-0.9-1.6-1.4-3.4-1.4-5.4s0.7-3.8,2-5.2
                        c0.8-0.9,1.6-1.6,2.5-2c0.6-0.3,1-0.6,1-1c0-0.1-0.1-0.4-0.2-0.7c-1-1.8-1.6-3.3-1.6-4.6s0.4-2.5,1-3.7c0.7-1.2,1.7-2.2,2.9-3.1
                        c1.1-0.7,2.5-1.3,4-1.9C130.1,0.8,131.5,0.5,133,0.5"
          />
          <path
            className="st0"
            d="M179,13v11.7c0,2.6,0.2,4.3,0.7,5.1c0.5,0.9,1.7,1.3,3.5,1.3c1.9,0,3.4-0.4,4.6-1.3c1.1-0.8,1.9-2.2,2.3-4
                        c0.2-1.1,0.8-1.6,1.7-1.6s1.6,0.4,2.2,1.2c0.6,0.8,0.8,1.9,0.8,3.1c0,2.1-0.7,4.3-2.3,6.7c-0.5,0.7-0.9,1.3-1.3,1.6
                        s-0.8,0.4-1.3,0.4l-5.7-0.7h-17.8c-1.2,0-2-0.1-2.5-0.4c-0.7-0.4-1-1-1-1.7c0-0.4,0.1-0.8,0.4-1c0.2-0.2,0.7-0.5,1.6-1
                        c1-0.4,1.6-1.1,1.9-1.9c0.2-0.7,0.3-2.7,0.3-6.1c0-1.6-0.1-3.8-0.1-6.7c-0.1-2.8-0.1-4.9-0.1-6.4c0-1.3-0.1-2.3-0.3-2.8
                        s-0.7-0.8-1.3-1c-1.1-0.4-1.8-0.7-2.2-1c-0.5-0.4-0.8-0.9-0.8-1.4c0-1.3,1-2.2,3.1-2.8c1.7-0.6,4.1-0.8,7-0.8
                        c3.3,0,5.8,0.1,7.4,0.5c2.1,0.4,3.1,1.3,3.1,2.8c0,0.5-0.1,1-0.4,1.3c-0.3,0.3-1,0.6-2,1.1c-0.7,0.3-1,0.7-1.2,1.5
                        C179.1,9.1,179,10.7,179,13"
          />
          <path
            className="st0"
            d="M209.7,13v14.8c0,1.2,0.1,2,0.3,2.5s0.7,1,1.3,1.5c0.5,0.4,0.9,0.7,1,1s0.3,0.6,0.3,1c0,2.2-2.8,3.3-8.3,3.3
                        c-3,0-5.4-0.2-7.1-0.7c-0.6-0.1-1-0.4-1.4-0.9c-0.4-0.4-0.5-0.9-0.5-1.4c0-0.4,0.1-0.8,0.3-1c0.1-0.2,0.7-0.7,1.4-1.2
                        c0.5-0.4,0.9-1,1.1-1.9c0.2-0.9,0.3-2.2,0.3-4c0-0.5-0.1-1.6-0.1-3.1c-0.1-1.5-0.1-2.4-0.1-2.7c-0.1-0.7-0.6-1.2-1.4-1.6
                        c-1-0.4-1.6-1.1-1.6-2c0-1,0.7-1.9,2.1-2.4c1.9-0.7,4-1.3,6.1-1.9c2.2-0.5,3.8-0.7,5-0.7C209.3,11.5,209.7,12,209.7,13"
          />
          <path
            className="st0"
            d="M229.4,18v8.6c0,1,0.2,1.9,0.7,2.5c0.5,0.7,1.1,1,1.9,1c0.7,0,1.6-0.3,2.6-0.9c0.2-0.1,0.5-0.2,0.7-0.2
                        c0.4,0,0.7,0.2,1.1,0.7c0.4,0.5,0.5,1,0.5,1.6c0,1-0.6,2.1-1.7,3c-2.4,2-5.3,3.1-8.8,3.1c-2.7,0-4.9-0.7-6.5-2.3s-2.5-3.6-2.5-6.1
                        V17.2c0-0.4-0.1-0.6-0.1-0.7c-0.1-0.1-0.3-0.1-0.7-0.1H214c-0.5,0-0.8-0.1-1-0.2c-0.1-0.1-0.2-0.5-0.2-1.1V14
                        c0-0.5,0.2-0.9,0.6-1.1l11.7-7.3c0.3-0.1,0.7-0.2,1-0.2h2.1c0.4,0,0.7,0.1,0.8,0.3c0.1,0.2,0.2,0.6,0.2,1.2v3.4
                        c0,0.5,0.1,0.8,0.2,1c0.1,0.1,0.5,0.2,1,0.2h4.7c0.6,0,1,0.1,1.2,0.4s0.3,0.7,0.3,1.5v1.5c0,0.8-0.1,1.3-0.4,1.7
                        c-0.2,0.3-0.7,0.4-1.3,0.4h-4.6c-0.4,0-0.8,0.1-1,0.2C229.4,17.2,229.4,17.5,229.4,18"
          />
          <path
            className="st0"
            d="M250.3,20.5h2.5c1.1,0,1.6-0.5,1.6-1.5c0-0.9-0.3-1.6-0.8-2.2s-1.2-0.9-2.1-0.9c-1,0-1.7,0.4-2.2,1.1
                        s-0.7,1.4-0.7,2.1c0,0.5,0.1,0.9,0.4,1C249.2,20.4,249.7,20.5,250.3,20.5 M261.6,25.3h-12c-0.5,0-0.8,0.2-0.8,0.7
                        c0,1.1,0.6,2.2,1.8,3.1c1.2,1,2.5,1.5,3.9,1.5c0.8,0,1.7-0.1,2.7-0.4s1.8-0.7,2.5-1.1c0.6-0.4,1-0.6,1.3-0.6
                        c0.4,0,0.9,0.3,1.3,0.8s0.6,1.1,0.6,1.6c0,1.2-0.7,2.4-2.3,3.5c-2.7,2-5.8,3-9.4,3c-3.6,0-6.6-1-9-3.1c-1.2-1-2.2-2.2-2.9-3.7
                        c-1-1.9-1.5-4-1.5-6.1c0-2.8,0.9-5.4,2.6-7.7c1.5-1.9,3.4-3.4,5.7-4.4c1.7-0.7,3.6-1.1,5.6-1.1c3.2,0,6,0.9,8.2,2.8
                        c1.1,0.9,2,2,2.8,3.3c0.8,1.4,1.2,2.8,1.2,4.2c0,1.1-0.2,2-0.7,2.7C262.9,24.9,262.3,25.3,261.6,25.3"
          />
          <path
            className="st0"
            d="M296.7,20.7l0.4,9c0,0.7,0.1,1.2,0.3,1.5c0.1,0.3,0.7,1,1.6,1.9c0.2,0.3,0.4,0.7,0.4,1.1c0,1.1-0.7,1.9-2,2.3
                        c-1.3,0.4-3.7,0.7-7.2,0.7c-2.3,0-4-0.2-4.9-0.6c-1-0.4-1.5-1.1-1.5-2.1c0-0.4,0.1-0.6,0.1-0.7c0.1-0.1,0.4-0.5,1-1.2
                        c0.7-0.7,1-3.1,1-7.1c0-2.3-0.3-4-0.8-5.2c-0.4-0.9-1.3-1.3-2.5-1.3c-1.1,0-1.9,0.4-2.3,1.1s-0.7,2-0.7,4v5c0,1,0.1,1.7,0.2,2.1
                        s0.5,1.1,1.3,2.2c0.1,0.3,0.3,0.7,0.3,1c0,1-0.7,1.7-2.1,2.2c-1.4,0.4-3.6,0.7-6.5,0.7c-4.7,0-7.1-1-7.1-2.9
                        c0-0.4,0.1-0.7,0.2-0.9s0.5-0.5,1-0.9c0.4-0.3,0.7-0.7,0.8-1.1c0.1-0.4,0.2-1.4,0.3-3.1c0.1-1.9,0.1-4,0.1-6.1
                        c0-1.2-0.1-1.9-0.3-2.3c-0.1-0.4-0.7-0.8-1.6-1.3c-0.7-0.4-1.1-1-1.1-1.6c0-0.7,0.3-1.3,0.9-1.6c0.6-0.4,1.8-1,3.7-1.6
                        c1.6-0.6,3.2-1,4.7-1.5c1.5-0.4,2.5-0.6,3.1-0.6c0.5,0,1,0.2,1.3,0.6c0.4,0.4,0.5,0.8,0.5,1.3c0,0.1,0,0.3,0,0.6
                        c0,0.1,0,0.2,0,0.4c0,0.8,0.4,1.2,1,1.2c0.4,0,0.8-0.3,1.3-1c1.5-2.1,3.6-3.1,6.4-3.1c2.2,0,4,0.6,5.5,1.6c1,0.8,1.8,1.7,2.2,2.8
                        C296.3,17,296.5,18.6,296.7,20.7"
          />
          <path
            className="st0"
            d="M208.6,4.5c-0.2-3.7-3.7-3.6-3.7-3.6c-1.5-0.1-2.6,1.6-2.7,1.6c-0.1-0.1-1.2-1.8-2.7-1.6c0,0-3.4-0.1-3.7,3.6
                        c0,0-0.4,4.3,5.5,5.4c0,0,0.4,0.1,0.8,0.1c0.4-0.1,0.7-0.1,0.7-0.1C209.1,8.8,208.6,4.5,208.6,4.5"
          />
        </g>
      </g>
    </svg>
  ),
}
