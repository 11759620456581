/* eslint-disable max-len */
const programName = 'Homeroom'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 181 37" fill="#000">
      <title>{programName}</title>
      <path d="M13.8634 29.0114H10.1813V21.0991C10.2227 19.9561 9.63656 18.8819 8.65308 18.2981C7.6696 17.7144 6.44583 17.7144 5.46235 18.2981C4.47887 18.8819 3.89272 19.9561 3.93409 21.0991V29.0114H0.252024V21.0991C0.191375 18.6234 1.47744 16.3094 3.61188 15.0538C5.74631 13.7981 8.39367 13.7981 10.5281 15.0538C12.6625 16.3094 13.9486 18.6234 13.888 21.0991L13.8634 29.0114Z" />
      <path d="M3.92182 12.2212V8.22412H0.239746V14.6923C1.24924 13.583 2.51271 12.7351 3.92182 12.2212Z" />
      <path d="M23.49 29.4124C21.4783 29.4459 19.5392 28.6613 18.117 27.2383C16.6947 25.8153 15.9112 23.8758 15.9458 21.8641C15.8935 19.8565 16.6791 17.9177 18.1141 16.5129C19.5253 15.0679 21.4706 14.27 23.49 14.3077C25.5028 14.2902 27.4387 15.0795 28.8655 16.4993C30.2922 17.9192 31.0909 19.8513 31.0832 21.8641C31.1033 23.88 30.3083 25.8185 28.8786 27.2397C27.4489 28.661 25.5057 29.4445 23.49 29.4124ZM23.49 25.8121C24.5283 25.8407 25.5316 25.436 26.2597 24.6952C27.0075 23.9466 27.4119 22.9217 27.3766 21.8641C27.4112 20.8079 27.0069 19.7845 26.2597 19.0371C24.712 17.5476 22.2638 17.5476 20.7161 19.0371C19.9888 19.7953 19.5966 20.8139 19.6279 21.8641C19.596 22.9156 19.9881 23.9358 20.7161 24.6952C21.4452 25.4371 22.4503 25.8418 23.49 25.8121Z" />
      <path d="M54.493 20.1949V29.0114H50.8109V20.4649C50.8109 18.7507 49.9231 17.7484 48.4053 17.7484C46.7197 17.7484 45.7501 18.8939 45.7501 20.9804V29.0114H42.068V20.4649C42.068 18.7507 41.1803 17.7484 39.6665 17.7484C38.03 17.7484 36.9786 18.8939 36.9786 20.9804V29.0114H33.2965V14.7168H36.9786V16.2347C37.8378 14.9459 39.151 14.3159 40.9552 14.3159C42.7595 14.3159 44.0114 15.0032 44.8419 16.3615C45.7869 14.9868 47.2148 14.3159 49.1008 14.3159C52.3778 14.3159 54.493 16.6315 54.493 20.1949Z" />
      <path d="M60.7566 23.382C61.2435 25.1821 62.5895 26.0699 64.7905 26.0699C66.1897 26.0699 67.278 25.583 67.9939 24.638L70.9641 26.3563C69.5649 28.4018 67.4784 29.4124 64.7332 29.4124C62.3603 29.4124 60.4743 28.7005 59.0424 27.2686C57.6131 25.8398 56.8373 23.8842 56.8986 21.8641C56.8472 19.8598 57.6104 17.9203 59.0138 16.4883C60.4377 15.0308 62.4106 14.242 64.4468 14.3159C66.4056 14.254 68.2945 15.0469 69.6222 16.4883C70.9888 17.9388 71.7244 19.8721 71.6678 21.8641C71.6601 22.3731 71.6122 22.8806 71.5246 23.382H60.7566ZM67.9939 20.5181C67.7069 18.7999 66.1876 17.5627 64.4468 17.6298C62.4749 17.6298 61.1289 18.6894 60.6993 20.5181H67.9939Z" />
      <path d="M122.881 18.002C123.376 16.7743 124.117 15.6606 125.058 14.7291C124.276 14.4233 123.443 14.2666 122.603 14.2668C118.84 14.2735 115.792 17.3237 115.787 21.0868V29.0114H119.469V21.0991C119.469 19.3744 120.866 17.9757 122.591 17.9734C122.726 17.9734 122.791 17.9939 122.881 18.002Z" />
      <path d="M132.262 29.4124C130.25 29.4471 128.31 28.663 126.887 27.2398C125.464 25.8166 124.679 23.8765 124.714 21.8641C124.665 19.8563 125.452 17.9182 126.887 16.5129C129.867 13.5723 134.658 13.5723 137.638 16.5129C139.081 17.9119 139.878 19.8468 139.839 21.856C139.862 23.8696 139.069 25.8069 137.643 27.2281C136.216 28.6494 134.276 29.4341 132.262 29.4042V29.4124ZM132.262 25.8121C133.302 25.8408 134.307 25.4363 135.036 24.6952C135.784 23.9466 136.188 22.9217 136.153 21.8641C136.188 20.8079 135.783 19.7845 135.036 19.0371C133.487 17.5471 131.038 17.5471 129.489 19.0371C128.764 19.7967 128.373 20.8148 128.404 21.8641C128.373 22.9148 128.763 23.9344 129.489 24.6952C130.218 25.4358 131.223 25.8403 132.262 25.8121Z" />
      <path d="M149.249 29.4124C147.237 29.447 145.296 28.6629 143.873 27.2398C142.45 25.8166 141.666 23.8765 141.701 21.8641C141.651 19.8563 142.438 17.9181 143.873 16.5128C146.037 14.3347 149.3 13.6773 152.138 14.8479C154.976 16.0186 156.827 18.786 156.826 21.8559C156.848 23.8696 156.056 25.8069 154.629 27.2281C153.203 28.6494 151.263 29.4341 149.249 29.4042V29.4124ZM149.249 25.8121C150.288 25.8403 151.293 25.4358 152.023 24.6952C152.77 23.9462 153.172 22.9212 153.136 21.8641C153.171 20.8085 152.768 19.7851 152.023 19.0371C150.474 17.547 148.024 17.547 146.475 19.0371C145.749 19.7957 145.358 20.8144 145.391 21.8641C145.358 22.9151 145.748 23.9354 146.475 24.6952C147.205 25.4363 148.209 25.8408 149.249 25.8121Z" />
      <path d="M180.24 20.1949V29.0114H176.558V20.4649C176.558 18.7507 175.67 17.7484 174.152 17.7484C172.466 17.7484 171.497 18.8939 171.497 20.9804V29.0114H167.815V20.4649C167.815 18.7507 166.927 17.7484 165.413 17.7484C163.777 17.7484 162.725 18.8939 162.725 20.9804V29.0114H159.043V14.7168H162.725V16.2347C163.584 14.9459 164.898 14.3159 166.702 14.3159C168.506 14.3159 169.758 15.0032 170.589 16.3615C171.534 14.9868 172.961 14.3159 174.848 14.3159C178.125 14.3159 180.24 16.6315 180.24 20.1949Z" />
      <path d="M106.173 6.98447L95.6381 1.19953C94.4543 0.550352 93.0211 0.550352 91.8374 1.19953L81.3026 6.98447C80.0416 7.67991 79.2581 9.00559 79.257 10.4456V32.8776C79.257 33.9247 79.6729 34.9289 80.4133 35.6692C81.1537 36.4096 82.1579 36.8256 83.205 36.8256H104.27C106.451 36.8256 108.218 35.058 108.218 32.8776V10.4456C108.217 9.00559 107.434 7.67991 106.173 6.98447ZM92.451 34.2563H83.205C82.8408 34.2552 82.492 34.1092 82.2356 33.8506C81.9792 33.5919 81.8363 33.2418 81.8385 32.8776V18.0389H92.451V34.2563ZM92.451 15.4573H81.8385V10.4456C81.838 9.94681 82.1092 9.48734 82.5463 9.2469L92.451 3.80562V15.4573ZM105.637 32.8776C105.635 33.6313 105.024 34.2418 104.27 34.2441H95.0285V26.7735H105.637V32.8776ZM105.637 24.1797H95.0285V3.80562L104.929 9.2469C105.366 9.48734 105.637 9.94681 105.637 10.4456V24.1797Z" />
    </svg>
  ),
}
