/* eslint-disable max-len */
const programName = 'Smarta Saker'
export default {
  programName,
  logo: (
    <svg x="0px" y="0px" viewBox="0 305.8 1280 188.5">
      <title>{programName}</title>
      <g>
        <g>
          <path fill="#000000" d="M38.1,429.8H10c-5.4,0-10-4.5-10-10s4.5-10,10-10h28.1c5.4,0,10,4.5,10,10S43.5,429.8,38.1,429.8z" />
        </g>
        <g>
          <path fill="#000000" d="M228.8,429.8h-28.1c-5.4,0-10-4.5-10-10s4.5-10,10-10h28.1c5.4,0,10,4.5,10,10S234.3,429.8,228.8,429.8z" />
        </g>
        <g>
          <path
            fill="#000000"
            d="M94.4,352.5c-4.2,0-8.2-2.7-9.4-6.9L76,318.9c-1.6-5.3,1.1-10.9,6.4-12.5c5.3-1.6,10.9,1.1,12.5,6.4
            l8.7,26.7c1.6,5.3-1.1,10.9-6.4,12.5C96.4,352.5,95.3,352.5,94.4,352.5z"
          />
        </g>
        <g>
          <path
            fill="#000000"
            d="M144.4,352.5c-1.1,0-2-0.2-3.1-0.5c-5.3-1.6-8.2-7.3-6.4-12.5l8.7-26.7c1.6-5.3,7.3-8.2,12.5-6.4
            c5.3,1.6,8.2,7.3,6.4,12.5l-8.7,26.7C152.6,349.9,148.8,352.5,144.4,352.5z"
          />
        </g>
        <g>
          <path
            fill="#000000"
            d="M53.7,382c-2,0-4.2-0.5-5.8-2l-22.7-16.5c-4.5-3.3-5.4-9.4-2.2-14s9.4-5.4,14-2.2l22.7,16.5
            c4.5,3.3,5.4,9.4,2.2,14C59.7,380.6,56.8,382,53.7,382z"
          />
        </g>
        <g>
          <path
            fill="#000000"
            d="M185.1,382c-3.1,0-6.2-1.5-8.2-4.2c-3.3-4.5-2.2-10.7,2.2-14l22.7-16.5c4.5-3.3,10.7-2.2,14,2.2
            c3.3,4.5,2.2,10.7-2.2,14l-22.5,16.7C189.3,381.5,187.3,382,185.1,382z"
          />
        </g>
        <g>
          <path
            fill="#000000"
            d="M30.8,494.2c-3.1,0-6.2-1.5-8.2-4.2c-3.3-4.5-2.2-10.7,2.2-14l22.7-16.5c4.5-3.3,10.7-2.2,14,2.2
            c3.3,4.5,2.2,10.7-2.2,14l-22.7,16.5C35,493.5,32.8,494.2,30.8,494.2z"
          />
        </g>
        <g>
          <path
            fill="#000000"
            d="M208,494.2c-2,0-4.2-0.5-5.8-2l-22.7-16.5c-4.5-3.3-5.4-9.4-2.2-14s9.4-5.4,14-2.2l22.7,16.5
            c4.5,3.3,5.4,9.4,2.2,14C214.1,492.7,211,494.2,208,494.2z"
          />
        </g>
        <g>
          <path
            fill="#000000"
            d="M76.9,459.6c-9.6-12.5-13.7-23.5-13.7-37.2c0-31.2,27.6-56.6,58.6-56.6l0.9,20.3l-3.1-0.2
            c-20.1,0-36.5,16.3-36.5,36.5c0,9.3,2.8,16.2,9.7,25.3L76.9,459.6z"
          />
        </g>
        <g>
          <path
            fill="#000000"
            d="M120.1,494.4h-12.7c-10.7,0-14.5-4.9-17.1-10.2c-0.5-1.1-1.3-2.7-2.2-4.4c-0.7-1.6-1.6-3.3-2.2-4.5
            c-2-4-6.4-12.2-10-16.9l15.8-12.2c4.7,6.2,10,16,12,20.1c0.7,1.3,1.5,3.1,2.2,4.7c0.5,1.3,1.1,2.4,1.6,3.3h12.3v20H120.1z"
          />
        </g>
        <g>
          <path
            fill="#000000"
            d="M162.4,458.4l-15.8-12.2c6.9-8.9,9.1-14.9,9.1-24.1c0-20.1-17.6-36.3-37.7-36.3l1.3-20.1l0,0
            c31.2,0,56.4,25.3,56.4,56.3C175.7,435.9,172,445.7,162.4,458.4z"
          />
        </g>
        <g>
          <path
            fill="#000000"
            d="M131.2,494.4h-15.6v-20h15.3c0.5-0.9,1.1-2.2,1.6-3.3c0.7-1.6,1.6-3.3,2.2-4.7c2-4.2,8.1-15,12.8-21.2
            l16,11.9c-3.4,4.5-9,14-11,18c-0.7,1.3-1.5,2.9-2.2,4.5c-0.9,1.8-1.6,3.4-2.2,4.4C145.7,489.5,141.9,494.4,131.2,494.4z"
          />
        </g>
        <g>
          <path
            fill="#000000"
            d="M337.9,470.4c-17.6,0-33.8-6.5-46.6-18.9c-1.5-1.3-2.2-3.3-2.2-5.3s0.7-3.8,2.2-5.3l2.4-2.4
            c2.7-2.7,7.3-2.9,10.2,0c9.6,9.3,21.4,14.2,34.5,14.2c15.1,0,25-7.1,25-17.6v-0.4c0-5.8,0-12.3-28.7-18.1
            c-21.8-4.4-41.7-11.6-41.7-34.8v-0.4c0-19.2,18.3-34.1,41.6-34.1c14.7,0,28.7,4.7,40.3,13.6c1.5,1.1,2.5,2.9,2.7,4.9
            s-0.4,3.8-1.5,5.4l-2.2,2.7c-2.4,3.1-6.9,3.8-10.2,1.3c-6.2-4.7-16.3-10.3-29.6-10.3c-14.7,0-23.4,8.3-23.4,16.5v0.4
            c0,5.3,0,12,28.1,17.8c22,4.5,42.3,11.8,42.3,35.2v0.4C381.1,455.9,363.3,470.4,337.9,470.4z"
          />
        </g>
        <g>
          <path
            fill="#000000"
            d="M511.2,468.6h-3.1c-3.8,0-6.9-3.1-6.9-6.9v-44.3c0-8.5-2.4-22.9-18.3-22.9c-11.3,0-19.1,12.2-19.1,23.2
            v43.9c0,3.8-3.1,6.9-6.9,6.9h-3.1c-3.8,0-6.9-3.1-6.9-6.9v-44.3c0-8.5-2.4-22.9-18.3-22.9c-11.3,0-19.1,12.2-19.1,23.2v43.9
            c0,3.8-3.1,6.9-6.9,6.9h-3.1c-3.8,0-6.9-3.1-6.9-6.9v-75.3c0-3.8,3.1-6.9,6.9-6.9h3.1c3.1,0,5.8,2,6.5,4.9
            c5.4-4,12.2-6.7,19.4-6.7c12,0,21.8,4.9,27.9,13.6c6.2-7.6,15.4-13.6,26.3-13.6c21.4,0,35.2,15.4,35.2,39.4v44.6
            C518.1,465.5,515,468.6,511.2,468.6z"
          />
        </g>
        <g>
          <path
            fill="#000000"
            d="M564.7,470.4c-20.7,0-35-12.2-35-29.4v-0.4c0-18.7,16-30.8,40.5-30.8c7.1,0,14.5,0.9,21.2,2.4
            c-0.5-8-4.2-17.1-21.4-17.1c-7.3,0-13.8,1.6-22,5.6c-1.6,0.7-3.6,0.9-5.4,0.2s-3.1-2-4-3.6l-1.3-2.7c-1.5-3.4,0-7.4,3.3-9.1
            c10.2-5.1,19.6-7.3,29.4-7.3c12.3,0,22.1,3.4,29,10.3c6.2,6.2,9.4,15.2,9.4,26.3v46.8c0,3.8-3.1,6.9-6.9,6.9h-3.1
            c-3.4,0-6.4-2.5-6.9-6C584.8,467.3,575.8,470.4,564.7,470.4z M570.3,426.5c-22.3,0-23.6,11.6-23.6,13.8v0.4
            c0,12.2,15.1,12.9,18.1,12.9c16.5,0,26.7-10.2,26.7-19.4v-4.7C585,427.4,578.1,426.5,570.3,426.5z"
          />
        </g>
        <g>
          <path
            fill="#000000"
            d="M634.2,468.6h-3.1c-3.8,0-6.9-3.1-6.9-6.9v-75.3c0-3.8,3.1-6.9,6.9-6.9h3.1c3.8,0,6.9,3.1,6.9,6.9v0.4
            c6.2-5.4,14.3-9.1,23.8-9.1h0.4c3.8,0,6.9,3.1,6.9,6.9v3.1c0,3.8-3.1,6.9-6.9,6.9h-0.7c-14.3,0-23.4,16.7-23.4,28.1v39
            C641.1,465.5,638,468.6,634.2,468.6z"
          />
        </g>
        <g>
          <path
            fill="#000000"
            d="M722.9,469.9c-16.1,0-26.1-10.5-26.1-27.6v-46.1h-5.1c-3.8,0-6.9-3.1-6.9-6.9v-2.9c0-3.8,3.1-6.9,6.9-6.9
            h5.1v-19.2c0-3.8,3.1-6.9,6.9-6.9h3.1c3.8,0,6.9,3.1,6.9,6.9v19.1h20.9c3.8,0,6.9,3.1,6.9,6.9v3.1c0,3.8-3.1,6.9-6.9,6.9h-20.9
            v45.9c0,9.6,4.4,11.1,9.3,11.1c2.9,0,5.8-0.7,8.3-2c2.2-1.1,4.7-0.9,6.7,0.4s3.3,3.4,3.3,5.8v3.3c0,2.7-1.6,5.1-4,6.4
            C732.7,469,728,469.9,722.9,469.9z"
          />
        </g>
        <g>
          <path
            fill="#000000"
            d="M786.6,470.4c-20.7,0-35-12.2-35-29.4v-0.4c0-18.7,16-30.8,40.5-30.8c7.1,0,14.5,0.9,21.2,2.4
            c-0.5-8-4.2-17.1-21.4-17.1c-7.3,0-13.8,1.6-22,5.6c-1.6,0.7-3.6,0.9-5.4,0.2s-3.1-2-4-3.6l-1.3-2.7c-1.5-3.4,0-7.4,3.3-9.1
            c10.2-5.1,19.6-7.3,29.4-7.3c12.3,0,22.1,3.4,29,10.3c6.2,6.2,9.4,15.2,9.4,26.3v46.8c0,3.8-3.1,6.9-6.9,6.9h-3.3
            c-3.4,0-6.4-2.5-6.9-6C806.6,467.3,797.5,470.4,786.6,470.4z M792.1,426.5c-22.3,0-23.6,11.6-23.6,13.8v0.4
            c0,12.2,15.1,12.9,18.1,12.9c16.5,0,26.7-10.2,26.7-19.4v-4.7C806.8,427.4,800,426.5,792.1,426.5z"
          />
        </g>
        <g>
          <path
            fill="#000000"
            d="M906,470.4c-17.6,0-33.8-6.5-46.6-18.9c-1.5-1.3-2.2-3.3-2.2-5.3s0.7-3.8,2.2-5.3l2.4-2.4
            c2.7-2.7,7.3-2.9,10.2,0c9.6,9.3,21.6,14.2,34.5,14.2c15.1,0,25-7.1,25-17.6v-0.4c0-5.8,0-12.3-28.7-18.1
            c-21.8-4.4-41.7-11.6-41.7-34.8v-0.4c0-19.2,18.3-34.1,41.6-34.1c14.7,0,28.7,4.7,40.3,13.6c1.5,1.1,2.5,2.9,2.7,4.9
            s-0.4,3.8-1.5,5.4l-2.2,2.7c-2.4,3.1-6.9,3.8-10.2,1.3c-6.2-4.7-16.3-10.3-29.6-10.3c-14.7,0-23.4,8.3-23.4,16.5v0.4
            c0,5.3,0,12,28.1,17.8c22,4.5,42.3,11.8,42.3,35.2v0.4C949.4,455.9,931.6,470.4,906,470.4z"
          />
        </g>
        <g>
          <path
            fill="#000000"
            d="M994,470.4c-20.7,0-35-12.2-35-29.4v-0.4c0-18.7,16-30.8,40.5-30.8c7.1,0,14.5,0.9,21.2,2.4
            c-0.5-8-4.2-17.1-21.4-17.1c-7.3,0-13.8,1.6-22,5.6c-1.6,0.7-3.6,0.9-5.4,0.2s-3.1-2-4-3.6l-1.3-2.7c-1.5-3.4,0-7.4,3.3-9.1
            c10.2-5.1,19.6-7.3,29.4-7.3c12.3,0,22.1,3.4,29,10.3c6.2,6.2,9.4,15.2,9.4,26.3v46.8c0,3.8-3.1,6.9-6.9,6.9h-3.1
            c-3.4,0-6.4-2.5-6.9-6C1014,467.3,1004.9,470.4,994,470.4z M999.5,426.5c-22.3,0-23.6,11.6-23.6,13.8v0.4
            c0,12.2,15.1,12.9,18.1,12.9c16.5,0,26.7-10.2,26.7-19.4v-4.7C1014.2,427.4,1007.5,426.5,999.5,426.5z"
          />
        </g>
        <g>
          <path
            fill="#000000"
            d="M1177.5,470.4c-25.8,0-44.5-19.4-44.5-46.3v-0.4c0-26.3,18.5-46.1,43-46.1c27.2,0,41.6,23.4,41.6,46.6v0.5
            c0,3.8-3.1,6.9-6.9,6.9h-59.5c2.9,13.1,13.2,21.8,26.5,21.8c9.4,0,16.3-3.3,20.9-9.8c2.2-3.1,6.5-3.8,9.6-1.6l2.5,1.8
            c3.1,2.2,3.8,6.5,1.6,9.6C1204.7,464.6,1192.9,470.4,1177.5,470.4z M1151.2,415.2h48.1c-2.5-10.9-10.2-20.7-23.4-20.7
            C1163.5,394.6,1154.1,402.7,1151.2,415.2z"
          />
        </g>
        <g>
          <path
            fill="#000000"
            d="M1242.1,468.6h-3.1c-3.8,0-6.9-3.1-6.9-6.9v-75.3c0-3.8,3.1-6.9,6.9-6.9h3.1c3.8,0,6.9,3.1,6.9,6.9v0.4
            c6.2-5.4,14.3-9.1,23.8-9.1h0.4c3.8,0,6.9,3.1,6.9,6.9v3.1c0,3.8-3.1,6.9-6.9,6.9h-0.7c-14.3,0-23.4,16.7-23.4,28.1v39
            C1249,465.5,1245.9,468.6,1242.1,468.6z"
          />
        </g>
        <g>
          <path
            fill="#000000"
            d="M1122.3,468.6L1122.3,468.6h-4c-2,0-3.8-0.9-5.1-2.4l-31.6-35l-11.4,10.5v19.8c0,3.8-3.1,6.9-6.9,6.9h-3.1
            c-3.8,0-6.9-3.1-6.9-6.9V351.7c0-3.8,3.1-6.9,6.9-6.9h3.1c3.8,0,6.9,3.1,6.9,6.9v67.7l40.6-37.9c1.3-1.3,2.9-1.8,4.7-1.8h4.2
            c2.9,0,5.4,1.8,6.4,4.4c1.1,2.7,0.4,5.6-1.6,7.6l-30.7,28.5l33.2,36.7c1.5,1.3,2.2,3.1,2.2,5.1
            C1129.2,465.5,1126.1,468.6,1122.3,468.6z"
          />
        </g>
      </g>
    </svg>
  ),
}
