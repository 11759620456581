const programName = 'Bättre Nätter'
export default {
  programName,
  logo: (
    <svg className="battrenatter" x="0px" y="0px" viewBox="0 0 355.9 70.3">
      <title>{programName}</title>
      <g>
        <path
          className="st0"
          d="M0,69.5V30h7.1v15.6c1.2-1.9,4.1-3.7,8.1-3.7c7.9,0,12.4,6.1,12.4,14c0,8.1-5,14.2-12.7,14.2
        c-3.7,0-6.5-1.6-8-3.9v3.3H0z M13.8,48.4C10.1,48.4,7,51.1,7,56c0,4.9,3.1,7.6,6.7,7.6c3.7,0,6.7-2.7,6.7-7.6S17.5,48.4,13.8,48.4z"
        />
        <path
          className="st0"
          d="M39.8,54l6.6-1c1.5-0.2,2-1,2-1.9c0-1.9-1.5-3.5-4.5-3.5c-3.2,0-4.9,2-5.1,4.4l-6.4-1.4
        c0.4-4.2,4.3-8.8,11.5-8.8c8.5,0,11.7,4.8,11.7,10.2v13.2c0,1.4,0.2,3.3,0.3,4.3h-6.7c-0.2-0.7-0.3-2.2-0.3-3.2
        c-1.4,2.1-3.9,4-7.9,4c-5.7,0-9.2-3.9-9.2-8.1C31.8,57.4,35.3,54.7,39.8,54z M38.3,31.3c2.1,0,3.9,1.7,3.9,3.9
        c0,2.1-1.7,3.8-3.9,3.8c-2.1,0-3.8-1.7-3.8-3.8C34.5,33.1,36.2,31.3,38.3,31.3z M48.4,58.6v-1.2l-6.1,0.9c-1.9,0.3-3.3,1.3-3.3,3.4
        c0,1.6,1.1,3.1,3.5,3.1C45.6,64.8,48.4,63.4,48.4,58.6z M49.8,31.3c2.1,0,3.9,1.7,3.9,3.9c0,2.1-1.7,3.8-3.9,3.8
        c-2.2,0-3.8-1.7-3.8-3.8C46,33.1,47.6,31.3,49.8,31.3z"
        />
        <path
          className="st0"
          d="M71.7,42.6h5.4v6.4h-5.4v11.2c0,2.3,1.1,3.1,3.2,3.1c0.9,0,1.9-0.1,2.2-0.2v6c-0.7,0.3-2,0.7-4.1,0.7
        c-5.2,0-8.5-3.1-8.5-8.3V49.1h-4.9v-6.4H61c2.8,0,4.1-1.9,4.1-4.3v-3.8h6.5V42.6z"
        />
        <path
          className="st0"
          d="M92,42.6h5.4v6.4H92v11.2c0,2.3,1.1,3.1,3.2,3.1c0.9,0,1.9-0.1,2.2-0.2v6c-0.7,0.3-2,0.7-4.1,0.7
        c-5.2,0-8.5-3.1-8.5-8.3V49.1H80v-6.4h1.4c2.8,0,4.1-1.9,4.1-4.3v-3.8H92V42.6z"
        />
        <path
          className="st0"
          d="M119.6,49.8c-0.8-0.2-1.5-0.2-2.2-0.2c-3.7,0-6.9,1.8-6.9,7.6v12.3h-7.3V42.6h7v4c1.6-3.5,5.3-4.2,7.6-4.2
        c0.6,0,1.1,0.1,1.7,0.1V49.8z"
        />
        <path
          className="st0"
          d="M148.6,61.8c-1.4,4.6-5.6,8.5-12.2,8.5c-7.4,0-13.9-5.3-13.9-14.4c0-8.6,6.3-14.1,13.2-14.1
        c8.3,0,13.3,5.3,13.3,13.9c0,1-0.1,2.1-0.1,2.2h-19.2c0.2,3.5,3.2,6.1,6.8,6.1c3.4,0,5.2-1.7,6.1-4.1L148.6,61.8z M141.8,53
        c-0.1-2.7-1.9-5.3-6-5.3c-3.8,0-5.8,2.8-6,5.3H141.8z"
        />
        <path
          className="st0"
          d="M169.5,69.5h-7.3V42.6h7V46c1.6-2.8,4.9-4,7.7-4c6.7,0,9.7,4.7,9.7,10.6v16.9h-7.3V53.8c0-3-1.5-5.3-5-5.3
        c-3.2,0-5,2.5-5,5.6V69.5z"
        />
        <path
          className="st0"
          d="M199.5,54l6.6-1c1.5-0.2,2-1,2-1.9c0-1.9-1.5-3.5-4.5-3.5c-3.2,0-4.9,2-5.1,4.4l-6.4-1.4
        c0.4-4.2,4.3-8.8,11.5-8.8c8.5,0,11.7,4.8,11.7,10.2v13.2c0,1.4,0.2,3.3,0.3,4.3h-6.7c-0.2-0.7-0.3-2.2-0.3-3.2
        c-1.4,2.1-3.9,4-7.9,4c-5.7,0-9.2-3.9-9.2-8.1C191.4,57.4,195,54.7,199.5,54z M198,31.3c2.1,0,3.9,1.7,3.9,3.9
        c0,2.1-1.7,3.8-3.9,3.8s-3.8-1.7-3.8-3.8C194.2,33.1,195.9,31.3,198,31.3z M208.1,58.6v-1.2l-6.1,0.9c-1.9,0.3-3.3,1.3-3.3,3.4
        c0,1.6,1.1,3.1,3.5,3.1C205.2,64.8,208.1,63.4,208.1,58.6z M209.4,31.3c2.1,0,3.9,1.7,3.9,3.9c0,2.1-1.7,3.8-3.9,3.8
        c-2.2,0-3.8-1.7-3.8-3.8C205.6,33.1,207.3,31.3,209.4,31.3z"
        />
        <path
          className="st0"
          d="M231.3,42.6h5.4v6.4h-5.4v11.2c0,2.3,1.1,3.1,3.2,3.1c0.9,0,1.9-0.1,2.2-0.2v6c-0.7,0.3-2,0.7-4.1,0.7
        c-5.2,0-8.5-3.1-8.5-8.3V49.1h-4.9v-6.4h1.4c2.8,0,4.1-1.9,4.1-4.3v-3.8h6.5V42.6z"
        />
        <path
          className="st0"
          d="M251.6,42.6h5.4v6.4h-5.4v11.2c0,2.3,1.1,3.1,3.2,3.1c0.9,0,1.9-0.1,2.2-0.2v6c-0.7,0.3-2,0.7-4.1,0.7
        c-5.2,0-8.5-3.1-8.5-8.3V49.1h-4.9v-6.4h1.4c2.8,0,4.1-1.9,4.1-4.3v-3.8h6.5V42.6z"
        />
        <path
          className="st0"
          d="M287,61.8c-1.4,4.6-5.6,8.5-12.2,8.5c-7.4,0-13.9-5.3-13.9-14.4c0-8.6,6.3-14.1,13.2-14.1
        c8.3,0,13.3,5.3,13.3,13.9c0,1-0.1,2.1-0.1,2.2h-19.2c0.2,3.5,3.2,6.1,6.8,6.1c3.4,0,5.2-1.7,6.1-4.1L287,61.8z M280.3,53
        c-0.1-2.7-1.9-5.3-6-5.3c-3.8,0-5.8,2.8-6,5.3H280.3z"
        />
        <path
          className="st0"
          d="M308.5,49.8c-0.8-0.2-1.5-0.2-2.2-0.2c-3.7,0-6.9,1.8-6.9,7.6v12.3h-7.3V42.6h7v4c1.6-3.5,5.3-4.2,7.6-4.2
        c0.6,0,1.1,0.1,1.7,0.1V49.8z"
        />
      </g>
      <g>
        <path
          className="st1"
          d="M329.3,3.3c-9.6,2.9-16.3,12-15.6,22.4c0.8,12,11.4,21.1,23.5,20.3c7.6-0.5,14-4.8,17.6-10.9
        c-1.6,0.5-3.2,0.8-4.9,1c-12.2,0.9-22.7-8.2-23.5-20.3C326.1,11.2,327.2,6.9,329.3,3.3"
        />
      </g>
    </svg>
  ),
}
