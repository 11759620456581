/* eslint-disable max-len */
const programName = 'Hifiklubben'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 126.6 32.6">
      <title>{programName}</title>
      <path fill="currentColor" d="M0 0v32.6h52.2V0H0zm41.3 9.7c.8 0 1.5.7 1.5 1.5v.1c0 .8-.7 1.5-1.5 1.4-.8 0-1.5-.7-1.5-1.5.1-.8.7-1.5 1.5-1.5zm-16 0c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5-1.5-.7-1.5-1.5.6-1.5 1.5-1.5zm-4 2.1h-1.5v9h1.5v1.9H16v-1.9h1.5v-3.5h-5.4v3.5h1.5v1.9H8.3v-1.9h1.5v-9H8.3V9.9h5.3v1.9h-1.5v3.5h5.4v-3.5H16V9.9h5.3v1.9zm6.4 10.9h-4.9v-1.9h1.3V16h-1.3v-2h3.6v6.8h1.3v1.9zm10.6-9.3h-2.2v-1.6h-3.3v3.5h4v1.9h-4v3.5h1.7v1.9h-5.4v-1.9h1.3v-9H29V9.9h9.3v3.5zm5.5 9.3h-4.9v-1.9h1.3V16h-1.3v-2h3.6v6.8h1.3v1.9zM68.9 20.8h1.2v1.9H65v-1.9h1.2l-2.6-4-1.5 1.4v2.6h1.5v1.9h-5.3v-1.9h1.5v-9h-1.5V9.9h5.3v1.9h-1.5v4l4.1-4H65V9.9h5.1v1.9h-1.3l-3.6 3.4 3.7 5.6zm5.9 0H76v1.9h-4.6v-1.9h1.2v-9h-1.2V9.9h3.4v10.9zm10.6 0h1.3v1.9h-3.2v-.8c-.8.7-1.8 1-2.8 1-1.8 0-3.1-1-3.1-3.3v-3.7h-1.2V14h3.4v5.4c0 1.1.6 1.5 1.4 1.5.7 0 1.3-.3 1.8-.8V16h-1v-2h3.4v6.8zm4.9 1.1v.8h-2.2V11.8H87V9.9h3.4v4.9c.7-.6 1.6-.9 2.5-.9 2 0 3.6 1.7 3.6 4.3 0 2.9-1.6 4.6-3.8 4.6-.8.1-1.7-.3-2.4-.9zm4-3.5c0-1.5-.7-2.6-2-2.6s-2 1.1-2 2.5.7 2.6 2.1 2.6 1.9-1 1.9-2.5zm5.8 3.5v.8H98V11.8h-1.1V9.9h3.4v4.9c.7-.6 1.6-.9 2.5-.9 2 0 3.6 1.7 3.6 4.3 0 2.9-1.6 4.6-3.8 4.6-1 .1-1.9-.2-2.5-.9zm3.9-3.5c0-1.5-.7-2.6-1.9-2.6s-2 1.1-2 2.5.8 2.6 2.1 2.6 1.8-1 1.8-2.5zm11.5 0v.6h-5.8c-.1 1 .7 1.9 1.8 2h.2c.7.1 1.4-.3 1.7-.8h2c-.4 1.7-2 2.8-3.7 2.8-2.7 0-4.2-1.7-4.2-4.5s1.6-4.5 4.1-4.5 3.9 1.5 3.9 4.4zm-2.3-1c0-.9-.7-1.7-1.6-1.7h-.2c-.9 0-1.7.8-1.8 1.7h3.6zm13.4 3.4v1.9h-3.4v-5.4c0-1.1-.6-1.5-1.4-1.5-.7 0-1.3.3-1.8.8v4.1h1.2v1.9h-4.6v-1.9h1.2v-4.8h-1.2V14h3v.8c.7-.7 1.7-1 2.7-1 1.7-.1 3.1 1.2 3.2 2.8v4.1l1.1.1z" />
    </svg>
  ),
}
