/* eslint-disable max-len */
const programName = 'Furniturebox'
export default {
  programName,
  logo: (
    <svg className="furniturebox" viewBox="0 0 284 27.866">
      <title>{programName}</title>
      <g transform="translate(-22.94 -22.14)">
        {' '}
        <path transform="translate(0 -.565)" d="M38.218 29.179h-8.359v4.438h7.6V39.5h-7.6v10.472H22.94V23.3h15.273v5.879z" data-name="Path 96" />
        {' '}
        <path transform="translate(-18.066 -.565)" d="M66.919 23.3v15.8a5.409 5.409 0 0 0 1.358 4.121 4.914 4.914 0 0 0 6.478.123c1.6-1.4 1.558-3.357 1.558-4.838V23.3h6.919v15.314c0 2.8-.118 5.72-3 8.636-2.24 2.281-5.038 3.321-8.795 3.321-4.2 0-7.2-1.44-8.836-3.2-2.327-2.516-2.6-5.915-2.6-8.754V23.3z" data-name="Path 97" />
        {' '}
        <path transform="translate(-43.887 -.565)" d="M123.764 23.3a8.622 8.622 0 0 1 6.519 2.358 8.79 8.79 0 0 1 2.281 6 7.673 7.673 0 0 1-2.122 5.556 7.486 7.486 0 0 1-3.68 1.84l8.359 10.917h-8.6l-6.637-10.235v10.231h-6.914V23.3zm-3.88 11.957h1.316a5.187 5.187 0 0 0 3.357-1.081 3.328 3.328 0 0 0 .882-2.24 3.143 3.143 0 0 0-.917-2.24 4.7 4.7 0 0 0-3.321-1.04h-1.317v6.6z" data-name="Path 98" />
        {' '}
        <path transform="translate(-67.73 -.565)" d="M161.88 49.972V23.3h6.92l12.757 16.314V23.3h6.919v26.672h-6.919L168.8 33.658v16.314z" data-name="Path 99" />
        {' '}
        <path transform="translate(-96.744 -.565)" d="M228.319 23.3v26.672H221.4V23.3z" data-name="Path 100" />
        {' '}
        <path transform="translate(-106.63 -.565)" d="M254.278 29.179v20.793h-6.919V29.179h-5.679V23.3h18.272v5.879z" data-name="Path 101" />
        {' '}
        <path transform="translate(-127.7 -.565)" d="M291.824 23.3v15.8a5.409 5.409 0 0 0 1.358 4.121 4.914 4.914 0 0 0 6.478.123c1.6-1.4 1.558-3.357 1.558-4.838V23.3h6.919v15.314c0 2.8-.118 5.72-3 8.636-2.24 2.281-5.038 3.321-8.795 3.321-4.2 0-7.2-1.44-8.836-3.2-2.317-2.522-2.6-5.92-2.6-8.759V23.3z" data-name="Path 102" />
        {' '}
        <path transform="translate(-153.6 -.565)" d="M348.829 23.3a8.622 8.622 0 0 1 6.519 2.358 8.79 8.79 0 0 1 2.281 6 7.691 7.691 0 0 1-2.117 5.556 7.486 7.486 0 0 1-3.68 1.84l8.359 10.917h-8.6l-6.637-10.235v10.231h-6.914V23.3zm-3.88 11.957h1.322a5.187 5.187 0 0 0 3.357-1.081 3.328 3.328 0 0 0 .882-2.24 3.143 3.143 0 0 0-.917-2.24 4.7 4.7 0 0 0-3.321-1.04h-1.322v6.6z" data-name="Path 103" />
        {' '}
        <path transform="translate(-177.44 -.565)" d="M402.1 29.179h-8.236v4.438h7.8V39.5h-7.8v4.6h8.236v5.879h-15.16V23.3h15.16z" data-name="Path 104" />
        {' '}
        <path className="cls-1" transform="translate(-195.58 -.565)" d="M424.15 49.972V23.3h10.317a7.48 7.48 0 0 1 5.838 2.081 7.225 7.225 0 0 1 1.64 5 6.941 6.941 0 0 1-.759 3.28 4.2 4.2 0 0 1-2.158 2.081 6.629 6.629 0 0 1 3.639 1.6 6.7 6.7 0 0 1 1.8 4.879 7.234 7.234 0 0 1-1.958 5.238c-1.44 1.481-3.957 2.522-7.237 2.522H424.15zm6.919-16.155h1.281a3.662 3.662 0 0 0 2.64-.841 2.579 2.579 0 0 0 .641-1.758 2.74 2.74 0 0 0-.641-1.8 3.673 3.673 0 0 0-2.64-.8h-1.281zm0 10.758h1.558c1.922 0 3.521-.2 4.28-1.081a2.6 2.6 0 0 0 .559-1.717 2.726 2.726 0 0 0-.682-1.922c-.841-.841-2.558-.882-4.28-.882h-1.44v5.6z" data-name="Path 105" />
        {' '}
        <path className="cls-1" transform="translate(-216.8)" d="M492.912 46.049a15.956 15.956 0 0 1-21.034 0 13.954 13.954 0 0 1 0-19.953 15.956 15.956 0 0 1 21.034 0 13.954 13.954 0 0 1 0 19.953zM477 30.976a7.3 7.3 0 0 0-2.117 5.238A6.773 6.773 0 0 0 477 41.17a7.567 7.567 0 0 0 5.4 2.117 7.244 7.244 0 0 0 7.519-7.2 7.194 7.194 0 0 0-2.158-5.12 8 8 0 0 0-5.361-2.122 7.739 7.739 0 0 0-5.4 2.131z" data-name="Path 106" />
        {' '}
        <path className="cls-1" transform="translate(-243.08 -.57)" d="m532.02 35.744-8.559-12.434h8.559l3.957 6.919 4.118-6.919h8.518l-8.677 12.434 10.079 14.233h-8.4l-5.674-8.677-6 8.677h-8.356z" data-name="Path 107" />
        {' '}
      </g>
    </svg>
  ),
}
