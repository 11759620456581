/* eslint-disable max-len */
const programName = 'Svensk Hälsokost'
export default {
  programName,
  logo: (
    <svg className="svenskhalsokost" x="0px" y="0px" viewBox="0 0 631.6 129.1">
      <title>{programName}</title>
      <path
        className="st0"
        d="M550.7,116.1c1.5,0.8,3.7,5.6,0.5,8.1c-1.2,0.9-4.4,0.6-5.7-0.4c-3.1-2.4-1-6.9,0.5-7.7
    C547.5,115.2,549.7,115.6,550.7,116.1z"
      />
      <path
        className="st0"
        d="M366.8,116.8c7.4,0,11.4-8,11.4-14.2c0-11.1-3.3-19.6-12.6-19.6c-9.3,0.1-14.5,8.5-14.5,17.9
    C351.1,110.4,355.8,116.8,366.8,116.8 M365.3,124.7c-17.3,0.5-22.3-11.5-22.7-24.4c-0.5-13.1,10.5-25.6,24.1-25.2
    c13.9,0.4,20.1,15.1,19.8,25.2C386.1,114.3,379.9,125,365.3,124.7"
      />
      <path
        className="st0"
        d="M450.3,116.8c7.4,0,11.3-7.3,11.4-14.2c0.1-11.1-3.3-19.6-12.6-19.6c-9.3,0.1-14.5,8.5-14.5,17.9
    C434.6,110.4,439.3,116.8,450.3,116.8 M448.8,124.7c-17.3,0.5-22.3-11.5-22.7-24.4c-0.5-13.1,10.5-25.6,24.1-25.2
    c13.9,0.4,20.2,15.1,19.8,25.2C469.4,115.2,463.4,125,448.8,124.7"
      />
      <path
        className="st0"
        d="M191.1,38.7c2.6-1.1,3.4-1.6,7.2,4.7c3.5,5.4,10.5,5.8,14.3,3.1c4-3,3.4-9.1-1.3-11c-3.5-1.4-7.5-2.5-11.1-3.6
    c-9.2-2.7-12.6-12-7-19.8c4.1-5.6,10.3-6.7,16.8-5.7c6.1,0.9,10.5,3.7,11.5,10.1c0.6,3.5,0.4,4.4-1,5c-4.5,1.1-5.1,0.2-6.4-2.4
    c-2.3-4.7-6.1-5.7-10.4-5c-1.7,0.3-4.3,1.7-4.5,3c-0.4,1.9,0.3,5.2,1.7,6.1c2.6,1.8,6.3,2.6,9.5,3.3c13.3,3,14.5,12.9,10.7,21.5
    c-2.3,5.1-6.8,7-11.9,7.2c-9.8,0.4-16.7-4.9-20-13.5C188.8,40.7,188.9,39.6,191.1,38.7z"
      />
      <path
        className="st0"
        d="M558.2,109.1c1.9-0.7,6.3-1.4,8.7,3.8c2,4.2,8.6,6.4,12.7,3.3c4-3,3.4-9.1-1.3-11c-3.5-1.4-6.2-2.5-9.8-3.6
    c-9.2-2.7-12.6-12-7-19.8c4.1-5.6,9-6.7,15.6-5.7c6.1,0.9,10.5,3.7,11.5,10.1c0.3,1.6-1.3,2.8-2.1,3.1s-4.6,0.9-5.3-0.6
    c-2.2-4.8-4.8-5.7-9.1-5c-1.7,0.3-4.6,1.3-4.9,3.7c-0.3,1.9,0.7,4.5,2.1,5.5c2.6,1.8,5,2.6,8.2,3.3c13.3,3,14.5,12.9,10.7,21.5
    c-2.3,5.1-6.8,7-11.9,7.2c-9.8,0.4-14.8-3.8-18.7-13.5C557.5,111.3,556.8,109.6,558.2,109.1z"
      />
      <path
        className="st0"
        d="M392.4,19.5c0.6-0.4,0.7-1.2,0.7-1.6C392.1,12,387.9,7.2,382.5,6c-7.7-1.7-15.2,1.2-18.1,7.1
    c-3.7,7.4-0.9,15.2,6.7,18.3c3.5,1.4,7.3,2.1,10.9,3.2c5,1.6,6.4,6.8,2.8,10.4c-4.2,4.2-13.1,2.9-14.8-2.7c-1.5-5.2-5.2-4.6-7-4.1
    c-0.8,0.3-1.2,0.5-1.4,1.4c-0.1,0.6-0.2,1.8-0.1,2.2c1.9,7.2,3.6,10.1,11.6,13c8.1,2.9,18.6-1.9,21.2-9.8c2.9-9-1.1-15.4-11.1-18.3
    c-3.6-1-7.2-2.3-10.7-3.8c-3-1.3-3.1-3.8-1.7-6.4c2.5-4.7,10.2-4.8,13.6,0.1c2.3,3.3,4.2,3.8,6.8,3.3
    C391.5,19.9,391.5,19.9,392.4,19.5z"
      />
      <path
        className="st0"
        d="M334.7,87.6c0.4-5.9-4.5-15.3-19-12.2c-6.2,1.3-9.7,6.6-9.9,12.6c-0.2,6.7,2,10,8.6,12.6
    c3.9,1.5,7.6,2.2,10.5,3.1c5.1,1.6,6.5,6.7,2.9,10.4c-4.1,4.3-11.2,3.8-13.6-2.3c-1.5-4-2.6-5.6-6.1-4.6c-0.7,0.2-1.2,0.4-1.8,1.5
    c-0.6,1.1-0.6,1.8-0.4,2.9c1,6.2,4,10.4,10.1,12.4c8.1,2.8,18.6-1.8,21.2-9.7c3-9-1-15.5-11-18.4c-3.3-0.9-6.8-1.8-9.8-3.4
    c-1.5-0.8-3.2-3.3-3.1-4.4c0.2-2.8,2.4-5.2,4.3-5.9c3.5-1.3,8.1-0.3,9.2,3.6c1.2,4.2,3,4.5,6.1,3.9
    C332.8,89.6,334.8,88.8,334.7,87.6z"
      />
      <path
        className="st0"
        d="M476.5,107.6c3.9-0.4,3.9-0.4,6.3,5.1c2.4,5.5,11.9,6.4,16,2.1c3.5-3.7,1.8-9.3-3.3-10.9
    c-3.5-1.1-7.1-1.8-10.5-3.1c-6.7-2.6-8.8-5.9-8.6-12.6c0.2-5.8,3.5-10.5,9.4-12.4c11-3.5,19.3,2.8,20.7,10c0.2,1,0,1.8-0.5,2.3
    c-0.6,0.7-1.4,1.2-2.3,1.1c-2.1-0.2-3.8-1.5-5.4-4.5c-1.9-3.6-6.7-3.2-10.3-1.9c-1.9,0.7-4,2.4-4.4,5.4c-0.3,1.9,1.7,3.7,3.2,4.4
    c3.1,1.5,6.5,2.4,9.9,3.4c10,2.8,14,9.4,11,18.4c-2.6,7.8-13.2,12.6-21.2,9.6c-7.2-2.6-11.2-7.4-11.5-14.1
    C475.2,110.1,475.1,107.7,476.5,107.6z"
      />
      <path
        className="st0"
        d="M316.6,54.8c6.1,1.1,6.7,0.1,6.7-5.7c-0.1-7.2,0-14.4,0-22.7c1.5,2,2.5,3.2,3.3,4.4
    c5.2,7.2,10.6,14.3,15.6,21.6c2.4,3.5,6,3.6,8.9,2.5c1.5-0.6,2.3-3.8,2.3-5.9c0.3-10.5,0.3-20.9,0.3-31.4c0-3.3-0.3-6.7-0.6-10
    c0-0.5,0-0.9-0.3-1.1c-0.2-0.2-0.5-0.5-0.8-0.5c-6.9-0.6-6.9-0.3-7.3,6.3c-0.1,1.1,0,2.2,0,3.3c0,7.9,0,15.7,0,23.6
    c-3.6-3.3-6.3-7-9-10.8c-4.5-6.5-8.7-13.3-13.4-19.6c-1-1.4-3.6-2.1-5.7-2c-1,0-1.9,1.7-1.9,2.6c-0.1,14.8,0.1,29.6-0.1,44.5
    c0,0.4,0.4,0.8,1,1L316.6,54.8z"
      />
      <path
        className="st0"
        d="M412.3,27c0.1,1.3,0.9,1.2,1.3,0.5c4.4-5.1,8.8-11.6,11.7-15.8c3.7-5.4,4.7-6.9,11.9-5.3c0.2,0,0.3,0.2,0.3,0.4
    c0,0.2-0.1,0.5-0.3,0.8c-2.5,4.4-6,9.4-8.8,13.3c-3.6,5.2-3.3,5.6-1.1,10.2c3.4,7.2,6.4,12,8.9,18.5c0.4,1.1,0.5,1.5,0.9,2.6
    c0.1,0.4,0.2,1.1,0,1.5c-0.2,0.4-0.9,0.6-1.2,0.7c-5.5,1.1-6.1-0.4-8.6-5.4c-2.5-5-4.7-10.3-7.6-15.3c-0.2-0.4-0.3-0.6-0.4-0.7
    c-0.3-0.4-0.9-0.5-1.3-0.2c-6,4.9-5.2,13.1-5.6,20.1c0,0.6,0,1-0.4,1.3c-0.3,0.2-0.8,0.3-0.8,0.3h-5.3c0,0-0.5,0-0.8-0.2
    c-0.4-0.2-0.4-0.6-0.4-0.6v-46c0,0-0.1-0.9,0.4-1.5c0.3-0.3,0.6-0.3,1.1-0.4c1.2-0.1,2.6-0.2,4.2-0.2c0.6,0,1.7,0.1,1.8,1.9V27z"
      />
      <path
        className="st0"
        d="M303.6,6.1c-0.5,0-17,0.1-25.1-0.1c-4.3-0.1-5.4,1.9-5.3,5.8c0.4,13.5,0.4,26.9,0.8,40.4c0,1.3,1.3,2.9,2.3,2.9
    c9,0.1,18.1,0,27.1-0.3c1,0,2.7-2,2.9-3.3c0.6-3-1.5-3.6-4.1-3.6c-6.6,0.1-13.3,0-19.8,0c-0.1,0-0.1,0-0.2,0
    c-0.1-0.1-0.1-0.2-0.1-0.2V30.4c0,0,0-0.2,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1c5.4,0,10.4,0,15.3,0c2.4,0,4.9,0,4.9-3.6
    c0-3.7-2.3-3.4-4.8-3.3c-5.1,0.1-10.1,0-15.6,0c-0.1,0-0.2,0-0.3,0c-0.2-0.1-0.1-0.4-0.1-0.4v-9.6c0,0,0-0.1,0.1-0.3
    c0.1-0.2,0.2-0.1,0.4-0.1c6,0,11.5,0,17,0c5.2,0,5.7-0.2,5.2-5.6c0-0.3-0.1-0.7-0.2-0.9C304.1,6.1,303.6,6.1,303.6,6.1z"
      />
      <path
        className="st0"
        d="M626.4,76.7c0.2,0.5,0.2,1,0.2,1.1c0.3,3.9,0.1,4.6-5.3,4.6c-5.4,0-9.2,0-14.8,0c-0.3,0-0.3,0-0.6,0.2
    c-0.2,0.1-0.2,0.5-0.2,0.5v9.6c0,0-0.1,0.4,0.2,0.7c0.3,0.2,0.3,0.2,1.1,0.2c4.9-0.2,8.1,0,12.8,0c4.2,0,4.9-0.4,4.8,3.3
    c0,3.6-0.6,3.6-4.9,3.6c-4.6,0-7.5,0-12.3,0c-0.5,0-0.7,0-1,0.2c-0.5,0.2-0.5,0.7-0.5,0.7v15.5c0,0,0,0.5,0.3,0.7
    c0.3,0.2,0.5,0.2,0.9,0.2c5.9,0,9.8,0.1,16.1,0c3.3,0,4.1,0,4.1,3.6c0,3.2-0.8,3.1-2.9,3.3c-9,0.4-14.9,0.4-23.9,0.3
    c-0.8,0-2.3-1.9-2.3-2.9c-0.4-13.5-0.1-23.7-0.1-37.1c0-6.2-0.1-9.1,7.6-9.1c8.5,0,18.1,0,18.9,0C625.4,75.8,626.2,76.1,626.4,76.7z
    "
      />
      <path
        className="st0"
        d="M248.1,38.5c2.8-9.2,5.7-18.4,8.5-27.6c0.6-2,1.1-3.1,1.7-4c0.7-1.1,2.5-1.3,6-0.6c0.8,0.1,1.4,0.5,1.5,0.8
    c0.1,0.3,0.1,1,0.1,1.1c-0.1,0.8-0.2,1.3-0.5,2.1c-4.4,13.6-8.7,27.1-13.3,40.6c-0.6,1.7-2.4,3.5-4.1,4.1c-2.9,1.1-4.1-1.4-4.9-3.8
    c-5-14-9.9-28-14.9-42c-0.1-0.4-0.5-1.2-0.3-1.8c0.2-0.6,0.9-0.9,1.2-0.9c2.5-0.6,6-0.9,6.8,0.3c0.4,0.6,0.8,1.2,1.8,4
    c3,9.3,6.1,18.6,9.1,27.8C247.1,39.7,247.8,39.5,248.1,38.5"
      />
      <path
        className="st0"
        d="M261.6,64.4c1.6,0.6,2.8,2.2,2.4,4.8c-0.3,2-2.3,2.7-4.2,2.7c-2.5,0-3.7-1.4-3.7-3.2c0-1.2,0.2-2.8,1.8-4
    C259,63.8,260.5,64,261.6,64.4z"
      />
      <path
        className="st0"
        d="M249,64.4c1.6,0.6,2.8,2.2,2.4,4.8c-0.3,2-2.3,2.7-4.2,2.7c-2.5,0-3.7-1.4-3.7-3.2c0-1.2,0.2-2.8,1.8-4
    C246.4,63.8,247.9,64,249,64.4z"
      />
      <path
        className="st0"
        d="M221.1,91.6c0.2-0.2,0.2-0.4,0.2-0.7c0-4,0-7.7,0-11.4c0-4.2,0.3-4.2,4.1-4.2c3.3,0,3.7,0,3.7,4.1
    c0,13.5,0.4,27,0.4,40.5c0,4.3,0.1,4.5-3.3,4.5c-4.7,0-4.7-0.2-4.7-4.2c0-6.3,0-12.5,0-19.7c0-0.2,0-0.4-0.1-0.5
    c-0.1-0.1-0.3-0.2-0.5-0.2c-7.4,0-10.8,0.1-17.4,0.1c-2.7,0-2.3,0.8-2.3,3.1c0,5.1,0.1,10.2,0.1,15.2c0,6.7,0,6.2-2.5,6.2
    c-3.5,0-5,0-5.8-0.8c-0.3-0.3-0.2-0.9-0.2-1.2c0-14.5,0-28.3,0-42.9c0-3.9,0.2-4.1,4.9-4.1c3.2,0,3.4,0.3,3.4,4.4c0,3.5,0,7.1,0,11
    c0,0.3,0,0.5,0.3,0.7s0.7,0.1,0.7,0.1h18.6C220.5,91.8,221,91.8,221.1,91.6z"
      />
      <path
        className="st0"
        d="M423.5,123.5c0.2-0.5,0-1.8-0.3-2.4c-1.4-4-7.6-15.5-10.2-22.3c-1.2-3.1,0.1-5.7,1.9-8.5
    c2.6-4.2,5-8.5,7.6-12.9c0.2-0.4,0.3-0.7,0.2-0.9c-0.1-0.4-0.2-0.5-0.6-0.6c-6.9-1.4-7.4-0.9-10.6,4.7c-3.2,5.6-7.4,11-10.6,16.6
    c-0.7,1.1-1.2,1.7-1.2-0.3V78.9c0,0,0.1-1-0.1-2.2c-0.1-0.8-1.4-1.3-2-1.3c-1,0.1-1.9,0.1-2.7,0.2c-0.9,0.1-1.6,0.1-2.1,0.6
    c-0.5,0.6-0.5,1.3-0.5,1.3v45v1.1c0,0,0,0.2,0.3,0.4c0.2,0.2,0.8,0.2,0.8,0.2h3.8c0,0,1.3,0,1.9-0.1c0.4-0.1,0.9-0.3,0.9-1
    c-0.1-1.4-0.1-2.5-0.1-3.9c0.1-5.6,1.1-12.5,5.7-16.7c0.2-0.2,0.4-0.2,0.5-0.2c0.2,0.1,0.3,0.2,0.4,0.5c2.1,5,4.5,9.5,6.6,13.8
    c1.7,3.4,2.7,6.3,4.2,7.2c0.6,0.4,2.3,0.5,5.2,0.2C422.4,124.2,423.3,124,423.5,123.5z"
      />
      <path
        className="st0"
        d="M246.9,110.8h12.3c-1.5-5.3-4.5-11.8-6.3-18.1C251.3,98.9,248.4,105.3,246.9,110.8 M270.6,124.3
    c-3.7,0.5-7.5,2-8.3-4.7c-0.2-1.3-1.7-1.5-2.6-1.5c-3.3,0-9.9,0.1-13.2,0.5c-0.8,0.1-1.8,0-2,1.6c-0.9,5.9-4.2,4.8-7.7,4.3
    c-0.3,0-0.7-0.2-1-0.5c-0.2-0.3-0.1-0.9-0.1-1.2c2.4-8.7,5.1-16.3,7.4-24.7c1.8-6.5,4.6-13.1,6.7-19.6c0.4-1.3,2.1-2.7,3.4-3
    c0.8-0.2,2.7,1.5,3.1,2.6c4.5,14.8,10.4,29.7,14.8,44.6c0.1,0.3,0.2,0.9,0.1,1.2C271.1,124,270.9,124.1,270.6,124.3z"
      />
      <path
        className="st0"
        d="M545.4,75.9c0.4,0.3,0.3,1.2,0.3,1.4c-0.1,3.2,1.1,6-3,6.5c-2.6,0.3-4.9,0.1-8.2,0.1c-0.3,0-0.5,0-0.7,0.2
    s-0.2,0.3-0.2,0.6c0,2.8,0,5.5,0,8.1c0.1,9.4,0,18.7,0.2,28.1c0,2.8-0.8,4.1-3.7,4.1c-3.1,0-4.5-0.8-4.5-4.3
    c0.2-10.6,0-21.2-0.2-31.8c0-1.3-0.2-2.6-0.4-4.1c0-0.3-0.2-0.5-0.4-0.7c-0.3-0.3-0.9-0.3-0.9-0.3h-9.4c0,0-0.6,0-1.1-0.5
    s-0.7-0.7-0.7-1.2c0-6.5-0.6-6.5,6.2-6.5c7.1,0,21.1,0,25,0C544.6,75.5,545.1,75.6,545.4,75.9z"
      />
      <path
        className="st0"
        d="M284.9,76c-0.4-0.3-1-0.3-1.2-0.3c-1.9,0.1-2.3,0.1-3.8,0.2c-0.8,0-1.6,0-2,0.6c-0.3,0.5-0.4,0.8-0.4,1.5
    c0,14.9-0.1,29.5,0.2,44c0,1,2.4,2.7,3.8,2.7c4.9,0.3,11.4-0.2,16.3-0.1c2.8,0,4.1-0.8,4-3.8c-0.1-2.9-1.7-3.8-4.3-3.8
    c-3.3,0-8.1,0-12.2,0V76.9C285.3,76.9,285.2,76.2,284.9,76z"
      />
      <path
        className="st0"
        d="M161.5,12.2C156.5,7.2,150,4.5,143,4.5c-9.8,0-16.4,6.5-19.6,10.5c-3.2-4-9.9-10.5-19.6-10.5
    c-14.4,0-26.1,11.7-26.1,26.1c0,11.5,10.9,26.8,32.4,45.3c0.8,0.7,1.9,1.1,3,1c1.1-0.1,2.1-0.6,2.8-1.4c1.5-1.7,1.3-4.3-0.4-5.8
    c-19-16.4-29.5-30.2-29.5-39c0-9.8,8-17.8,17.8-17.8c5,0,11.9,3.6,15.9,11.7l3.7,7.4l3.7-7.4c4.1-8.1,10.9-11.7,15.9-11.7
    c4.8,0,9.2,1.9,12.6,5.2c3.4,3.4,5.2,7.8,5.2,12.6c0,6.5-7,17.3-19.2,29.6c-10.4,10.5-15,14.5-21.4,18.9c-5.2,3.5-9.8,5.8-14.9,7.3
    c-0.3-0.4-0.5-0.7-0.8-1c-0.4-0.6-0.8-1.1-1.3-1.6c-5-7.3-13-17.9-26-26.1C60.7,47,47.6,46.4,33.6,45.7c-6.8-0.3-13.9-0.7-21.6-2.3
    l-8.3-1.7l3.7,7.6c11.3,23.2,26.9,39,42.7,43.4c10.9,3.1,22.1,4.6,33.2,4.6c4.2,0,8.3-0.2,12.3-0.7c0.1,0,0.2,0,0.2,0
    c1.4-0.2,2.8-0.4,4.1-0.6c0.6-0.1,1.3-0.2,1.9-0.3c5.7,7.6,12,16.8,20,29.1c0.8,1.2,2.1,1.9,3.5,1.9c0.8,0,1.6-0.2,2.2-0.7
    c0.9-0.6,1.6-1.5,1.8-2.6c0.2-1.1,0-2.2-0.6-3.1c-7.2-11.2-13-19.7-18.4-27c15-5.4,34.8-23.1,46.7-37.8c7.9-9.5,12.1-18.2,12.1-25
    C169.1,23.6,166.4,17.1,161.5,12.2z M52.4,84.8c-11.7-3.3-23.9-14.9-33.7-31.9c5.1,0.6,9.9,0.8,14.5,1.1l0,0
    c13.3,0.6,24.9,1.2,39.6,10.5c11,7,18,15.7,21.9,21.7c0.3,0.4,1,1.4,1.4,2C88,89.3,71.8,90.2,52.4,84.8z"
      />
    </svg>
  ),
}
