import SkeletonImage from '../components/SkeletonImage/SkeletonImage'

import {
  ahlens,
  apotek365,
  apotekHjartat,
  asos,
  autoexperten,
  babyland,
  badmintonshoppen,
  battreNatter,
  beautycos,
  beijerBygg,
  bga,
  bjornborg,
  bodyAndFit,
  boohoo,
  bubbleroom,
  buddypetfoods,
  burton,
  buttericks,
  byggmax,
  casall,
  cellbes,
  cervera,
  ciqolaCarpets,
  clarins,
  cocopanda,
  confidentLiving,
  conrad,
  designtorget,
  dharmazone,
  doggie,
  efvaAttling,
  elon,
  enemo,
  eton,
  fitnessguru,
  frankDandy,
  furniturebox,
  fyrklovern,
  gents,
  glimja,
  golvpoolen,
  gorillaSports,
  grandpa,
  granngarden,
  guldfynd,
  haglofs,
  hambedo,
  happyAngler,
  hemtex,
  hifiklubben,
  homeroom,
  hunkemoller,
  indiska,
  InkClub,
  iphonebutiken,
  jdSports,
  nordicspectra,
  kellfri,
  kjellocompany,
  kockumsJernverk,
  koketsFavoriter,
  lamp24,
  lekia,
  lensNow,
  lensOn,
  lensway,
  lindex,
  litenleker,
  lookFantastic,
  magnussonsUr,
  mediaMarkt,
  misterSpex,
  myProtein,
  nakd,
  nelly,
  nike,
  nlyMan,
  nordicFeel,
  padelxpert,
  parfym,
  peaceOfHome,
  phoneLife,
  pierreRobert,
  polarnopyret,
  rapunzelOfSweden,
  resvaskor,
  rocketPadel,
  smartaSaker,
  soderstroms,
  sportshopen,
  staples,
  stayhard,
  stigaSports,
  storoliten,
  superfruit,
  svenskHalsokost,
  svensktKosttillskott,
  theProteinWorks,
  tooorch,
  tretti,
  underArmour,
  uret,
  valmano,
  varmepumpshopen,
  verktygsproffsen,
  villaNytt,
  watski,
  webhallen,
  whiteAway,
  wiggle,
  yvesRocher,
  bluetomato,
  soffadirekt,
  rituals,
  icebug,
  gant,
  ecco,
  birkenstock,
  houdini,
  lego,
  ugg,
  lexington,
  peakperformance,
  fjallraven,
  idealofsweden,
  filippak,
  adidas,
  edblad,
  happysocks,
  kavat,
  patagonia,
  vans,
  ganni,
  klattermusen,
  marimekko,
  betterbodies,
  boomerang,
  bymalina,
  eivy,
  millenotti,
  rieker,
  rusta,
  stenstroms,
  yankeecandle,
  asics,
  lecreauset,
  pandora,
  snoofsweden,
  artwood,
  converse,
  georgjensen,
  levis,
  nameit,
  pukka,
  vagabond,
  veromoda,
  bergspotter,
  calvinklein,
  ghostfragrances,
  hokaoneone,
  jlindeberg,
  marianila,
  monki,
  muurikka,
  nudiejeans,
  ralphlauren,
  thebodyshop,
  thomassabo,
  woodwick,
  baumundpferdgarten,
  desigual,
  dyson,
  hestra,
  hope,
  lumene,
  mango,
  morris,
  mosmosh,
  newbalance,
  norrona,
  nude,
  olaplex,
  rayban,
  reima,
  samsoesamsoe,
  stinegoya,
  tommyhilfiger,
  outnorth,
  moccamaster,
  dpj,
  minirodini,
  zooplus,
  greatlife,
  evy,
  hugoboss,
  lyko,
  traningsmaskiner,
  bangerhead,
  jotex,
  cubus,
  rizzo,
  johnells,
  zwilling,
  sportkost,
  eleven,
  life,
  footpatrol,
  gaminggadgets,
  gymgrossisten,
  aboutyou,
  boozt,
  tcmcykel,
  nordicnest,
  hultens,
  andlight,
  bulk,
  prada,
  louisvuitton,
  acer,
} from './programs'

const ProgramIcon = ({
  programName, brandLogo, width, height, className,
}) => {
  switch (programName) {
  case ahlens.programName: return ahlens.logo
  case apotek365.programName: return apotek365.logo
  case apotekHjartat.programName: return apotekHjartat.logo
  case asos.programName: return asos.logo
  case autoexperten.programName: return autoexperten.logo
  case babyland.programName: return babyland.logo
  case badmintonshoppen.programName: return badmintonshoppen.logo
  case battreNatter.programName: return battreNatter.logo
  case beautycos.programName: return beautycos.logo
  case beijerBygg.programName: return beijerBygg.logo
  case bga.programName: return bga.logo
  case bjornborg.programName: return bjornborg.logo
  case bodyAndFit.programName: return bodyAndFit.logo
  case boohoo.programName: return boohoo.logo
  case bubbleroom.programName: return bubbleroom.logo
  case buddypetfoods.programName: return buddypetfoods.logo
  case burton.programName: return burton.logo
  case buttericks.programName: return buttericks.logo
  case byggmax.programName: return byggmax.logo
  case casall.programName: return casall.logo
  case cellbes.programName: return cellbes.logo
  case cervera.programName: return cervera.logo
  case ciqolaCarpets.programName: return ciqolaCarpets.logo
  case clarins.programName: return clarins.logo
  case cocopanda.programName: return cocopanda.logo
  case confidentLiving.programName: return confidentLiving.logo
  case conrad.programName: return conrad.logo
  case designtorget.programName: return designtorget.logo
  case dharmazone.programName: return dharmazone.logo
  case doggie.programName: return doggie.logo
  case efvaAttling.programName: return efvaAttling.logo
  case elon.programName: return elon.logo
  case enemo.programName: return enemo.logo
  case eton.programName: return eton.logo
  case fitnessguru.programName: return fitnessguru.logo
  case frankDandy.programName: return frankDandy.logo
  case furniturebox.programName: return furniturebox.logo
  case fyrklovern.programName: return fyrklovern.logo
  case gents.programName: return gents.logo
  case glimja.programName: return glimja.logo
  case golvpoolen.programName: return golvpoolen.logo
  case gorillaSports.programName: return gorillaSports.logo
  case grandpa.programName: return grandpa.logo
  case granngarden.programName: return granngarden.logo
  case guldfynd.programName: return guldfynd.logo
  case haglofs.programName: return haglofs.logo
  case hambedo.programName: return hambedo.logo
  case happyAngler.programName: return happyAngler.logo
  case hemtex.programName: return hemtex.logo
  case hifiklubben.programName: return hifiklubben.logo
  case homeroom.programName: return homeroom.logo
  case hunkemoller.programName: return hunkemoller.logo
  case indiska.programName: return indiska.logo
  case InkClub.programName: return InkClub.logo
  case iphonebutiken.programName: return iphonebutiken.logo
  case jdSports.programName: return jdSports.logo
  case nordicspectra.programName: return nordicspectra.logo
  case kellfri.programName: return kellfri.logo
  case kjellocompany.programName: return kjellocompany.logo
  case kockumsJernverk.programName: return kockumsJernverk.logo
  case koketsFavoriter.programName: return koketsFavoriter.logo
  case lamp24.programName: return lamp24.logo
  case lekia.programName: return lekia.logo
  case lensNow.programName: return lensNow.logo
  case lensOn.programName: return lensOn.logo
  case lensway.programName: return lensway.logo
  case lindex.programName: return lindex.logo
  case litenleker.programName: return litenleker.logo
  case lookFantastic.programName: return lookFantastic.logo
  case magnussonsUr.programName: return magnussonsUr.logo
  case mediaMarkt.programName: return mediaMarkt.logo
  case misterSpex.programName: return misterSpex.logo
  case myProtein.programName: return myProtein.logo
  case nakd.programName: return nakd.logo
  case nelly.programName: return nelly.logo
  case nike.programName: return nike.logo
  case nlyMan.programName: return nlyMan.logo
  case nordicFeel.programName: return nordicFeel.logo
  case padelxpert.programName: return padelxpert.logo
  case parfym.programName: return parfym.logo
  case peaceOfHome.programName: return peaceOfHome.logo
  case phoneLife.programName: return phoneLife.logo
  case pierreRobert.programName: return pierreRobert.logo
  case polarnopyret.programName: return polarnopyret.logo
  case rapunzelOfSweden.programName: return rapunzelOfSweden.logo
  case resvaskor.programName: return resvaskor.logo
  case rocketPadel.programName: return rocketPadel.logo
  case smartaSaker.programName: return smartaSaker.logo
  case soderstroms.programName: return soderstroms.logo
  case sportshopen.programName: return sportshopen.logo
  case staples.programName: return staples.logo
  case stayhard.programName: return stayhard.logo
  case stigaSports.programName: return stigaSports.logo
  case storoliten.programName: return storoliten.logo
  case superfruit.programName: return superfruit.logo
  case svenskHalsokost.programName: return svenskHalsokost.logo
  case svensktKosttillskott.programName: return svensktKosttillskott.logo
  case theProteinWorks.programName: return theProteinWorks.logo
  case tooorch.programName: return tooorch.logo
  case tretti.programName: return tretti.logo
  case underArmour.programName: return underArmour.logo
  case uret.programName: return uret.logo
  case valmano.programName: return valmano.logo
  case varmepumpshopen.programName: return varmepumpshopen.logo
  case verktygsproffsen.programName: return verktygsproffsen.logo
  case villaNytt.programName: return villaNytt.logo
  case watski.programName: return watski.logo
  case webhallen.programName: return webhallen.logo
  case whiteAway.programName: return whiteAway.logo
  case wiggle.programName: return wiggle.logo
  case yvesRocher.programName: return yvesRocher.logo
  case bluetomato.programName: return bluetomato.logo
  case soffadirekt.programName: return soffadirekt.logo
  case rituals.programName: return rituals.logo
  case icebug.programName: return icebug.logo
  case gant.programName: return gant.logo
  case ecco.programName: return ecco.logo
  case birkenstock.programName: return birkenstock.logo
  case houdini.programName: return houdini.logo
  case lego.programName: return lego.logo
  case ugg.programName: return ugg.logo
  case lexington.programName: return lexington.logo
  case peakperformance.programName: return peakperformance.logo
  case fjallraven.programName: return fjallraven.logo
  case idealofsweden.programName: return idealofsweden.logo
  case filippak.programName: return filippak.logo
  case adidas.programName: return adidas.logo
  case edblad.programName: return edblad.logo
  case happysocks.programName: return happysocks.logo
  case kavat.programName: return kavat.logo
  case patagonia.programName: return patagonia.logo
  case vans.programName: return vans.logo
  case ganni.programName: return ganni.logo
  case klattermusen.programName: return klattermusen.logo
  case marimekko.programName: return marimekko.logo
  case betterbodies.programName: return betterbodies.logo
  case boomerang.programName: return boomerang.logo
  case bymalina.programName: return bymalina.logo
  case eivy.programName: return eivy.logo
  case millenotti.programName: return millenotti.logo
  case rieker.programName: return rieker.logo
  case rusta.programName: return rusta.logo
  case stenstroms.programName: return stenstroms.logo
  case yankeecandle.programName: return yankeecandle.logo
  case asics.programName: return asics.logo
  case lecreauset.programName: return lecreauset.logo
  case pandora.programName: return pandora.logo
  case snoofsweden.programName: return snoofsweden.logo
  case artwood.programName: return artwood.logo
  case converse.programName: return converse.logo
  case georgjensen.programName: return georgjensen.logo
  case levis.programName: return levis.logo
  case nameit.programName: return nameit.logo
  case pukka.programName: return pukka.logo
  case vagabond.programName: return vagabond.logo
  case veromoda.programName: return veromoda.logo
  case bergspotter.programName: return bergspotter.logo
  case calvinklein.programName: return calvinklein.logo
  case ghostfragrances.programName: return ghostfragrances.logo
  case hokaoneone.programName: return hokaoneone.logo
  case jlindeberg.programName: return jlindeberg.logo
  case marianila.programName: return marianila.logo
  case monki.programName: return monki.logo
  case muurikka.programName: return muurikka.logo
  case nudiejeans.programName: return nudiejeans.logo
  case ralphlauren.programName: return ralphlauren.logo
  case thebodyshop.programName: return thebodyshop.logo
  case thomassabo.programName: return thomassabo.logo
  case woodwick.programName: return woodwick.logo
  case baumundpferdgarten.programName: return baumundpferdgarten.logo
  case desigual.programName: return desigual.logo
  case dyson.programName: return dyson.logo
  case hestra.programName: return hestra.logo
  case hope.programName: return hope.logo
  case lumene.programName: return lumene.logo
  case mango.programName: return mango.logo
  case morris.programName: return morris.logo
  case mosmosh.programName: return mosmosh.logo
  case newbalance.programName: return newbalance.logo
  case norrona.programName: return norrona.logo
  case nude.programName: return nude.logo
  case olaplex.programName: return olaplex.logo
  case rayban.programName: return rayban.logo
  case reima.programName: return reima.logo
  case samsoesamsoe.programName: return samsoesamsoe.logo
  case stinegoya.programName: return stinegoya.logo
  case tommyhilfiger.programName: return tommyhilfiger.logo
  case outnorth.programName: return outnorth.logo
  case moccamaster.programName: return moccamaster.logo
  case dpj.programName: return dpj.logo
  case minirodini.programName: return minirodini.logo
  case zooplus.programName: return zooplus.logo
  case greatlife.programName: return greatlife.logo
  case evy.programName: return evy.logo
  case hugoboss.programName: return hugoboss.logo
  case lyko.programName: return lyko.logo
  case traningsmaskiner.programName: return traningsmaskiner.logo
  case bangerhead.programName: return bangerhead.logo
  case jotex.programName: return jotex.logo
  case cubus.programName: return cubus.logo
  case rizzo.programName: return rizzo.logo
  case johnells.programName: return johnells.logo
  case zwilling.programName: return zwilling.logo
  case sportkost.programName: return sportkost.logo
  case eleven.programName: return eleven.logo
  case life.programName: return life.logo
  case footpatrol.programName: return footpatrol.logo
  case gaminggadgets.programName: return gaminggadgets.logo
  case gymgrossisten.programName: return gymgrossisten.logo
  case aboutyou.programName: return aboutyou.logo
  case boozt.programName: return boozt.logo
  case tcmcykel.programName: return tcmcykel.logo
  case nordicnest.programName: return nordicnest.logo
  case hultens.programName: return hultens.logo
  case andlight.programName: return andlight.logo
  case bulk.programName: return bulk.logo
  case prada.programName: return prada.logo
  case louisvuitton.programName: return louisvuitton.logo
  case acer.programName: return acer.logo

  default: return (
    <SkeletonImage
      width={width}
      height={height}
      src={brandLogo}
      className={className}
      alt={programName}
    />
  )
  }
}

export default ProgramIcon
