/* eslint-disable max-len */
const programName = 'VillaNytt'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 116 40" fill="none">
      <title>{programName}</title>
      <g clipPath="url(#clip0)">
        <path fillRule="evenodd" clipRule="evenodd" d="M99.0843 0L85.1538 28.8H91.4143L94.0259 22.9584H107.086L109.739 28.8H116L102.028 0H99.0843ZM100.536 8.57911L104.972 18.0972H96.1409L100.536 8.57911ZM63.6785 0V28.8H83.4072V23.898H69.6071V0H63.6785ZM41.8015 0V28.8H61.5299V23.898H47.7301V0H41.8015ZM32.4852 28.8H38.4554V0H32.4852V28.8ZM23.5076 0L14.9668 18.7917L6.42671 0H0L13.5986 28.8H16.3351L29.9755 0H23.5076Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M109.004 31.2002V32.7479H111.647V40.0002H113.345V32.7479H116V31.2002H109.004ZM101.497 31.2002V32.7479H104.139V40.0002H105.838V32.7479H108.492V31.2002H101.497ZM98.9688 31.2002L96.8689 35.0821L94.7455 31.2002H92.8225L96.0077 37.017V40.0002H97.7066V37.017L100.903 31.2002H98.9688ZM90.5798 31.2002V36.7422L85.6256 31.2002H84.7998V40.0002H86.4865V34.458L91.4527 40.0002H92.2787V31.2002H90.5798Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="116" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
}
