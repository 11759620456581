/* eslint-disable max-len */
const programName = 'Stayhard'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 190 26">
      <title>{programName}</title>
      <path d="m183.23 18.063c-1.419 1.7628-3.493 2.8065-6.514 2.8065h-3.547v-16.552h3.547c3.021 0 5.095 1.0437 6.514 2.8066 1.164 1.4394 1.856 3.3826 1.856 5.4694 0 2.0869-0.692 4.03-1.856 5.4694zm1.71-15.688c-2.074-1.5111-4.73-2.3746-8.769-2.3746h-8.096v25.188h8.096c4.039 0 6.695-0.8634 8.769-2.3748 3.13-2.3031 5.058-6.0454 5.058-10.219 0-4.174-1.928-7.9165-5.058-10.219z" fill="#222" />
      <path d="m148.02 4.3005h4.429c1.712 0 2.568 0.39445 3.163 1.006 0.558 0.57586 0.856 1.3309 0.856 2.1588 0 0.82763-0.298 1.6247-0.856 2.2003-0.595 0.61184-1.451 1.0851-3.163 1.0851h-4.429v-6.4503zm8.165 10.225-1e-3 -0.0025c1.393-0.3696 2.429-0.9927 3.26-1.8386 1.302-1.2957 2.046-3.2396 2.046-5.2195 0-1.9793-0.744-3.9123-2.046-5.208-1.451-1.4755-3.423-2.2568-6.772-2.2568h-9.761v25.188h4.95l0.029-10.444h2.536l8.194 10.444h6.214l-8.649-10.662z" fill="#222" />
      <path d="M104.842 25.1875H109.615V0H104.842V9.53507H92.4659V0H87.6923V25.1875H92.4659V13.8528H104.842V25.1875Z" fill="#222" />
      <path d="m123.02 15.358 3.224-7.4504 3.223 7.4504h-6.447zm4.962-15.358h-5.298l0.924 2.1619-9.932 23.026h5.107l2.39-5.5289h10.177l2.391 5.5289h5.106l-10.865-25.188z" fill="#222" />
      <path d="m3.0518e-5 22.209c2.6105 2.3832 5.7652 3.7913 9.1737 3.7913 4.5686 0 8.5933-3.1052 8.5933-7.583 0-8.522-11.711-7.2581-11.711-11.338 0-1.9497 2.0664-2.7445 3.9156-2.7445 1.6681 0 3.3723 0.72225 4.3875 1.6613l2.5382-3.611c-2.3934-1.7694-4.7862-2.3832-7.3607-2.3832-4.2058 0-8.3757 2.6-8.3757 7.1139 0 2.8526 1.595 4.5858 3.5894 5.741 3.4086 1.9864 8.1223 2.3836 8.1223 5.6336 0 1.8413-1.5233 3.1774-3.6985 3.1774-2.3206 0-4.5326-1.1916-5.9828-2.7803l-3.1909 3.322z" fill="#222" />
      <path d="m47.507 15.358 3.2231-7.4504 3.2238 7.4504h-6.4469zm4.9615-15.358h-5.2983l0.9242 2.1619-9.9316 23.026h5.1068l2.3903-5.5289h10.177l2.3901 5.5289h5.1067l-10.865-25.188z" fill="#222" />
      <path d="M82.8205 0H77.3308L71.8771 8.85164L66.3877 0H60.8974L69.4531 13.781V25.1875H74.2654V13.781L82.8205 0Z" fill="#222" />
      <path d="m20.299 0v4.318h6.8417v20.87h4.9922v-20.87h6.8414v-4.318h-18.675z" fill="#222" />
    </svg>
  ),
}
