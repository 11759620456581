/* eslint-disable max-len */
const programName = 'Footpatrol'
export default {
  programName,
  logo: (<svg className="footpatrol" viewBox="0 0 481.6 57.6">
    <title>{programName}</title>
    <g>
      <path d="M56.8,56c-0.3-0.5-0.8-0.3-1.2-0.4c-3.9-1-6.4-3.7-6.9-7.7c-0.1-0.8-0.1-1.6-0.1-2.4c0-10.8,0-21.6,0-32.4
        c0-1.3,0.1-2.5,0.4-3.8c1.1-3.9,4-6.4,8.2-6.5C64,2.7,71,2.6,77.9,2.8c5.1,0.1,8.5,3.8,8.6,8.9c0.1,9.4,0.1,18.8,0.1,28.1
        c0,2.3,0,4.5-0.1,6.8c-0.2,5.1-3.1,8.4-8.2,9.1c-2.5,0.2-4.9,0.1-7.4,0.1c-1.9,0-3.8,0-5.7,0c-2.2,0-4.4,0-6.6,0
        c-0.4,0-0.8-0.1-1.2,0.1C57.2,56,57,56,56.8,56z"
      />
      <path d="M102.1,56c-0.2-0.4-0.6-0.2-0.9-0.3c-4.1-0.9-6.7-3.7-7.2-7.9c-0.1-0.8-0.1-1.6-0.1-2.4c0-10.8,0-21.6,0-32.5
        c0-1.3,0.1-2.5,0.5-3.8c1.1-3.8,4-6.3,8.2-6.4c6.9-0.1,13.8-0.2,20.7,0c5,0.1,8.5,3.7,8.6,8.7c0.1,4.7,0.1,9.5,0.1,14.2
        c0,6.7,0,13.4,0,20.2c0,2.8-0.7,5.3-2.7,7.4c-1.6,1.6-3.5,2.3-5.7,2.5c-4.3,0.2-8.6,0-12.9,0.1c-2.3,0-4.5,0-6.8,0
        c-0.4,0-0.8-0.1-1.2,0.1C102.4,56,102.2,56,102.1,56z"
      />
      <path
        className="st0"
        d="M266.4,56c-5.7,0-11.4,0-17,0c-0.1-0.1-0.1-0.3-0.2-0.4c0.4-3.7,1-7.4,1.6-11.1c0.1-0.8,0.6-1.3,1.5-1.3
        c3.8,0,7.6,0,11.4,0c0.7,0,1.3,0.3,1.4,1.1c0.6,3.8,1.3,7.5,1.6,11.3C266.6,55.7,266.5,55.8,266.4,56z"
      />
      <path
        className="st1"
        d="M368.1,56c0-7.4,0-14.8,0-22.2c0.7,0.3,0.6,1,0.6,1.5c0,3.9,0,7.9,0,11.8c0,2.3,0,4.5,0,6.8
        c0,1.4,0.1,1.5,1.5,1.5c2.6,0,5.3,0,7.9,0c1.4,0,1.5-0.1,1.5-1.6c0-4.2,0-8.3,0-12.5c0-0.6-0.2-1.4,0.6-1.8c0,3.4,0.1,6.8,0.1,10.2
        c0,2.1,0,4.2,0,6.3C376.2,56,372.2,56,368.1,56z"
      />
      <path
        className="st2"
        d="M436.4,56c0-2.4,0-4.7,0-7.1c0-0.3-0.1-0.7,0.3-0.8c0.4,0.5,0.3,1,0.3,1.6c0,1.5,0,2.9,0,4.4
        c0,0.9,0.4,1.2,1.2,1.2c0.4,0,0.9,0,1.3,0c11.3,0,22.5,0,33.8,0c0.7,0,1.4-0.1,2.1,0.3c0,0.1,0,0.2,0,0.3
        C462.5,56,449.5,56,436.4,56z"
      />
      <path
        className="st1"
        d="M298.1,56c0-2,0-4-0.1-6.1c0-0.7,0.2-1,0.9-0.9c0.6,0,1.2,0,1.8,0c-0.1,0.6-0.6,0.6-1,0.6
        c-0.8,0.1-1,0.5-1,1.2c0,1,0,2.1,0,3.1c0,1.3,0.2,1.4,1.5,1.4c4.3,0,8.6,0,12.9,0c0.7,0,1.4,0,2.2,0c0.4,0,0.9,0,1.2,0.3
        c0,0.1,0,0.2,0,0.3C310.4,56,304.2,56,298.1,56z"
      />
      <path
        className="st2"
        d="M22,55.7c0,0.1,0,0.2,0,0.3c-6.4,0-12.8,0-19.2,0c0-0.1,0-0.2,0-0.3c0.5-0.4,1-0.4,1.6-0.4c5.3,0,10.6,0,16,0
        C20.9,55.3,21.5,55.3,22,55.7z"
      />
      <path
        className="st2"
        d="M212.9,55.7c0,0.1,0,0.2,0,0.2c-6.2,0-12.5,0-18.7,0c0-0.1,0-0.2,0-0.2c0.5-0.4,1-0.4,1.6-0.4
        c5.2,0,10.4,0,15.5,0C211.9,55.3,212.4,55.3,212.9,55.7z"
      />
      <path
        className="st2"
        d="M153.7,56c0-0.1,0-0.2,0-0.2c0.5-0.4,1-0.4,1.6-0.4c5,0,10,0,15,0c0.6,0,1.1-0.1,1.6,0.4c0,0.1,0,0.2,0,0.3
        C165.8,56,159.7,56,153.7,56z"
      />
      <path
        className="st2"
        d="M354.9,55.7c0,0.1,0,0.2,0,0.3c-5.3,0-10.6,0-15.8,0c0-0.1,0-0.2,0-0.3c0.4-0.4,1-0.3,1.5-0.3
        c4.3,0,8.5,0,12.8,0C353.9,55.3,354.4,55.3,354.9,55.7z"
      />
      <path
        className="st2"
        d="M266.4,56c0-0.1,0-0.2,0-0.3c0.4-0.3,0.8-0.3,1.2-0.3c4,0,8.1,0,12.1,0c0.4,0,0.9,0,1.2,0.3c0,0.1,0,0.2,0,0.3
        C276.1,56,271.3,56,266.4,56z"
      />
      <path
        className="st2"
        d="M249.4,55.7c0,0.1,0,0.2,0,0.2c-4.7,0-9.4,0-14.2,0c0-0.1,0-0.2,0-0.3c0.4-0.3,0.8-0.3,1.2-0.3
        c3.9,0,7.8,0,11.7,0C248.6,55.4,249,55.4,249.4,55.7z"
      />
      <path
        className="st1"
        d="M65.2,55.8c4.4,0,8.8,0,13.2,0c0,0.1,0,0.1,0,0.2c-4.4,0-8.8,0-13.2,0C65.1,55.9,65,55.9,65.2,55.8
        C65.1,55.8,65.1,55.8,65.2,55.8z"
      />
      <path
        className="st2"
        d="M110.5,55.8c4.3,0,8.6,0,12.9,0c0,0.1,0,0.1,0,0.2c-4.3,0-8.6,0-13,0C110.4,55.9,110.4,55.9,110.5,55.8
        C110.4,55.8,110.5,55.8,110.5,55.8z"
      />
      <path
        className="st3"
        d="M110.5,55.8c0,0.1,0,0.1,0,0.2c-2.6,0-5.3,0-7.9,0c0.1-0.3,0.4-0.2,0.6-0.2C105.6,55.7,108.1,55.7,110.5,55.8z
        "
      />
      <path className="st3" d="M65.2,55.8c0,0.1,0,0.1,0,0.2c-2.6,0-5.1,0-7.7,0c0.1-0.3,0.4-0.2,0.6-0.2C60.4,55.7,62.8,55.7,65.2,55.8z" />
      <path d="M354.9,55.7c-5.3,0-10.5,0-15.8,0c0-1.9,0-3.7,0-5.6c0-0.6,0.1-0.9,0.8-0.9c2.1,0,2.1-0.1,2.1-2.1c0-11.5,0-23.1,0-34.6
        c0-1-0.3-1.3-1.2-1.2c-1.7,0.1-1.7,0-1.7-1.6c0-2,0-4,0-6c0-0.6,0.1-0.9,0.8-0.9c10.5,0,21,0,31.5,0c4.9,0,8.2,3.3,8.7,8.1
        c0.4,3.6,0.2,7.2,0.1,10.8c0,2.3-1.4,3.7-3.2,4.8c-1,0.6-2.1,1-3.2,1.3c-0.3,0.1-0.6,0.3-0.6,0.6c-0.1,0.5,0.3,0.4,0.6,0.4
        c3.8,0.9,5.9,3.2,6.3,7.1c0.1,1.1,0.1,2.3,0.2,3.4c-0.3,0.4-0.1,0.8-0.1,1.3c0,4.6,0,9.2,0,13.8c0,0.9-0.1,1.2-1.1,1.2
        c-3.2-0.1-6.4-0.1-9.6,0c-1.1,0-1.2-0.3-1.2-1.3c0.1-6.5,0.1-13,0.1-19.5c0-0.4,0.2-0.9-0.2-1.2c-4.2,0-8.4,0-12.6-0.1
        c-0.7,0-0.6,0.4-0.6,0.8c0,1.7,0,3.5,0,5.2C354.9,45,354.9,50.4,354.9,55.7z"
      />
      <path d="M388.7,29.1c0-5.4,0-10.9,0-16.3c0-1.3,0.1-2.5,0.5-3.8c1.1-3.7,4.2-6.2,8.1-6.2c7-0.1,13.9-0.1,20.9,0
        c5,0.1,8.5,3.9,8.6,9.4c0.1,11.4,0.1,22.8,0,34.1c0,5.7-3.6,9.3-9.3,9.4c-6.6,0.1-13.2,0.1-19.8,0c-5.5-0.1-9-3.9-9-9.7
        C388.7,40.4,388.7,34.7,388.7,29.1z"
      />
      <path d="M249.4,55.7c-4.7,0-9.4,0-14.1,0c0-1.8,0-3.7,0-5.5c0-0.8,0.2-1.2,1-1c1,0.2,1.3-0.3,1.5-1.2c2.8-14.7,5.7-29.5,8.5-44.2
        c0.2-0.8,0.4-1,1.2-1c7.2,0,14.5,0,21.7,0c0.8,0,1.1,0.2,1.2,1c2.3,11.9,4.5,23.9,6.8,35.8c0.6,2.9,1.1,5.8,1.7,8.8
        c0.1,0.7,0.4,1,1.1,0.9c1-0.2,1.1,0.3,1.1,1.1c-0.1,1.8,0,3.6,0,5.4c-4.9,0-9.7,0-14.6,0c-0.6-3.7-1.2-7.4-1.7-11.2
        c-0.1-0.8-0.3-1.1-1.2-1.1c-3.7,0.1-7.4,0.1-11.1,0c-0.9,0-1.2,0.3-1.3,1.2C250.5,48.3,249.9,52,249.4,55.7z"
      />
      <path d="M212.9,55.7c-6.2,0-12.5,0-18.7,0c0-1.9,0-3.8-0.1-5.7c0-0.7,0.3-0.8,0.9-0.8c1.9,0,1.9,0,1.9-2c0-11.5,0-23.1,0-34.6
        c0-1-0.2-1.6-1.3-1.4c-1.2,0.2-1.6-0.2-1.5-1.4c0.1-2.1,0.1-4.1,0-6.2c0-0.7,0.2-0.9,0.9-0.8c10.4,0,20.7,0,31.1,0
        c5.3,0,8.7,3.5,8.9,9.2c0.1,4.5,0.1,9,0,13.5c-0.2,5.6-3.8,8.9-9.6,8.9c-4.8,0-9.5,0-14.3,0c-0.9,0-1.2,0.1-1.2,1.1
        c0.1,4.2,0,8.4,0,12.5c0,0.8,0.1,1.2,1.1,1.2c2-0.1,2,0,2,2C212.9,52.7,212.9,54.2,212.9,55.7z"
      />
      <path d="M316.5,55.7c-5.9,0-11.7,0-17.6,0.1c-0.6,0-0.7-0.3-0.7-0.8c0-1.6,0-3.2,0-4.8c0-0.8,0.2-1.1,1-1c0.5,0,1,0.2,1.5-0.2
        c0.2-0.4,0.1-0.8,0.1-1.2c0-11.8,0-23.6,0-35.4c0-1-0.2-1.3-1.2-1.3c-2.4,0.1-4.7,0.1-7.1,0c-1,0-1.2,0.3-1.2,1.2
        c0.1,1.9,0,3.8,0,5.7c0,0.6-0.1,0.9-0.8,0.9c-2.2-0.1-4.5,0-6.7,0c-0.6,0-0.8-0.2-0.8-0.8c0-4.9,0-9.8,0-14.7c0-0.9,0.5-0.8,1-0.8
        c6,0,12.1,0,18.1,0c9.4,0,18.7,0,28.1,0c1.2,0,1.4,0.3,1.4,1.4c-0.1,4.6-0.1,9.2,0,13.8c0,0.8-0.2,1.1-1.1,1c-2.2-0.1-4.3,0-6.5,0
        c-0.6,0-0.8-0.2-0.8-0.8c0-2.1,0-4.1,0-6.2c0-0.6-0.1-0.8-0.8-0.8c-2.6,0-5.1,0-7.7,0c-0.8,0-1,0.2-1,1c0,4.5,0,8.9,0,13.4
        c0,7.5,0,15,0,22.5c0,0.9,0.2,1.2,1.1,1.1c1.2-0.2,1.7,0.2,1.6,1.5C316.4,52.3,316.5,54,316.5,55.7z"
      />
      <path d="M171.9,55.7c-6.1,0-12.1,0-18.2,0c0-1.9,0-3.8-0.1-5.7c0-0.7,0.2-0.9,0.9-0.8c0.3,0,0.6,0,0.8,0c0.7,0.1,0.9-0.2,0.9-0.9
        c0-12,0-24.1,0-36.1c0-0.7-0.2-0.9-0.9-0.9c-2.6,0-5.2,0-7.8,0c-0.7,0-0.9,0.2-0.9,0.9c0,2,0,4.1,0,6.1c0,0.7-0.2,0.9-0.9,0.9
        c-2.2,0-4.4,0-6.6,0c-0.6,0-0.8-0.1-0.8-0.8c0-4.9,0-9.8,0-14.7c0-0.9,0.4-0.8,1-0.8c4.9,0,9.8,0,14.6,0c10.5,0,21.1,0,31.6,0
        c1.1,0,1.3,0.3,1.3,1.3c-0.1,4.7,0,9.3,0,14c0,0.8-0.2,1.1-1,1.1c-2.1-0.1-4.2-0.1-6.4,0c-0.8,0-1-0.2-1-1c0.1-1.9,0-3.9,0-5.8
        c0-0.7-0.1-1-1-1c-2.5,0.1-5,0.1-7.6,0c-0.7,0-0.9,0.2-0.9,0.9c0,12,0,24.1,0,36.1c0,0.7,0.2,0.9,0.9,0.9c1.7,0,1.7,0,1.7,1.7
        C171.9,52.5,171.9,54.1,171.9,55.7z"
      />
      <path d="M22,55.7c-6.4,0-12.8,0-19.1,0c0-1.9,0-3.7,0-5.6c0-0.7,0.1-1,0.9-1c1.9,0,1.9,0,1.9-1.9c0-11.5,0-23.1,0-34.6
        c0-1.1-0.2-1.6-1.4-1.4C3,11.5,2.7,11,2.8,9.8c0.1-2.1,0.1-4.1,0-6.2c0-0.7,0.2-0.8,0.9-0.8c12.5,0,25,0,37.5,0
        c0.7,0,0.9,0.2,0.9,0.9c0,3.7,0,7.5,0,11.2c0,0.8-0.2,1-1,1c-3,0-5.9,0-8.9,0c-0.7,0-1-0.1-1-0.9c0.1-1,0-2,0-3
        c0-0.6-0.2-0.8-0.8-0.8c-3.8,0-7.7,0-11.5,0c-0.5,0-0.7,0.2-0.7,0.7c0,4.5,0,9,0,13.5c0,0.7,0.3,0.7,0.9,0.7c2.8,0,5.7,0,8.5,0
        c0.8,0,1.1,0.1,1.1,1c-0.1,1.7-0.1,3.5,0,5.2c0,0.8-0.2,1-1,1c-2.8-0.1-5.7,0-8.5,0c-0.8,0-1,0.2-1,1c0,4.7,0,9.3,0,14
        c0,0.7,0.2,0.9,0.9,0.9c0.9,0,2.1-0.4,2.5,0.2c0.4,0.5,0.2,1.7,0.2,2.5C22,53.2,22,54.4,22,55.7z"
      />
      <path d="M475.5,55.7c-0.4,0-0.9,0.1-1.3,0.1c-12.1,0-24.1,0-36.2,0c-1.2,0-1.5-0.3-1.4-1.4c0.1-2.1-0.1-4.1,0.1-6.2
        c2.7,0,2.7,0,2.7-2.7c0-11,0-22,0-32.9c0-1.1-0.2-1.4-1.3-1.3c-1.8,0.1-1.6,0.3-1.6-1.5c0-2,0-4,0-6c0-0.7,0.1-1,0.9-1
        c5.6,0,11.2,0,16.8,0c0.6,0,0.9,0.1,0.9,0.8c0,2.3,0,4.5,0,6.8c0,0.7-0.2,0.9-0.9,0.9c-2.1,0-2.1,0.1-2.1,2.1c0,11.2,0,22.4,0,33.5
        c0,1,0.2,1.3,1.2,1.3c3.6-0.1,7.1,0,10.7,0c0.7,0,1-0.2,0.9-0.9c-0.1-1.9,0-3.8,0-5.7c0-0.7,0.1-1.1,1-1c2.9,0.1,5.8,0.1,8.6,0
        c0.8,0,1,0.2,1,1C475.5,46.2,475.5,50.9,475.5,55.7z"
      />
      <path d="M476.1,33.1c-1.5-1.1-3.1-2.2-4.6-3.4c-0.5-0.4-0.9-0.4-1.4,0c-1.2,1-2.5,1.9-3.8,2.7c-0.3,0.2-0.5,0.6-1,0.5
        c0.6-1.9,1.2-3.7,1.9-5.6c0.2-0.6,0.1-0.8-0.4-1.1c-1.6-1.1-3.1-2.2-5-3.6c1.7,0,3,0,4.4,0c2.5,0,2.5,0,3.3-2.3
        c0.4-1.3,0.9-2.6,1.4-4.3c0.6,2,1.3,3.7,1.7,5.4c0.3,1,0.8,1.3,1.7,1.2c1.8-0.1,3.5,0,5.6,0c-1.7,1.2-3.1,2.3-4.6,3.3
        c-0.9,0.6-1,1.1-0.6,2c0.6,1.6,1.1,3.3,1.6,4.9C476.3,33,476.2,33.1,476.1,33.1z"
      />
      <path
        className="st0"
        d="M73.9,29.2c0,5.8,0,11.5,0,17.3c0,0.9-0.2,1.1-1.1,1.1c-3.4-0.1-6.7-0.1-10.1,0c-0.8,0-1-0.2-1-1
        c0-11.6,0-23.2,0-34.8c0-0.8,0.2-1,1-1c3.4,0,6.8,0,10.2,0c0.8,0,1,0.2,1,1C73.9,17.6,73.9,23.4,73.9,29.2z"
      />
      <path
        className="st0"
        d="M107.1,29.2c0-5.8,0-11.5,0-17.3c0-0.9,0.2-1.1,1.1-1.1c3.4,0.1,6.7,0,10.1,0c0.8,0,1,0.2,1,1
        c0,11.6,0,23.2,0,34.8c0,0.8-0.2,1-1,1c-3.4,0-6.8,0-10.2,0c-0.8,0-1-0.2-1-1C107.1,40.8,107.1,35,107.1,29.2z"
      />
      <path
        className="st0"
        d="M354.9,18.9c0-2.3,0-4.5,0-6.8c0-0.7,0.2-0.9,0.9-0.9c3.7,0,7.3,0,11,0c0.7,0,0.9,0.2,0.9,0.9
        c0,4.5,0,9.1,0,13.6c0,0.7-0.2,0.9-0.9,0.9c-3.7,0-7.3,0-11,0c-0.7,0-0.9-0.2-0.9-0.9C354.9,23.4,354.9,21.1,354.9,18.9z"
      />
      <path
        className="st0"
        d="M414.1,29.1c0,5.8,0,11.6,0,17.4c0,0.8-0.2,1.1-1,1.1c-3.4-0.1-6.7-0.1-10.1,0c-0.9,0-1-0.3-1-1.1
        c0-11.6,0-23.1,0-34.7c0-0.9,0.3-1.1,1.1-1.1c3.3,0,6.6,0.1,9.9,0c1,0,1.1,0.3,1.1,1.1C414.1,17.6,414.1,23.4,414.1,29.1z"
      />
      <path
        className="st0"
        d="M257.8,34.5c-1.5,0-3,0-4.4,0c-0.5,0-0.7,0-0.6-0.6c1.1-6.9,2.2-13.8,3.2-20.7c0.1-0.6,0.3-0.8,0.9-0.8
        c2.9-0.1,3-0.1,3.4,2.7c0.9,6.1,1.8,12.2,2.8,18.2c0.2,1-0.1,1.3-1.1,1.2C260.6,34.5,259.2,34.5,257.8,34.5z"
      />
      <path
        className="st0"
        d="M222.5,19.5c0,2.5,0,4.9,0,7.4c0,0.8-0.2,0.9-0.9,0.9c-3.6,0-7.2,0-10.8,0c-0.6,0-0.9-0.1-0.9-0.8
        c0-5,0-9.9,0-14.9c0-0.7,0.2-0.9,0.9-0.9c3.6,0,7.2,0,10.8,0c0.7,0,1,0.2,0.9,0.9C222.4,14.6,222.5,17.1,222.5,19.5z"
      />
    </g>
  </svg>),
}
