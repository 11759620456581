/* eslint-disable max-len */
const programName = 'Eleven'
export default {
  programName,
  logo: (
    <svg className="eleven" viewBox="0 0 818 175">
      <title>{programName}</title>
      <path
        className="st0"
        d="M110.6,119.4H26.3c3.9,16.5,16.5,28.8,33.9,28.8c13.6,0,20.3-4.9,25.7-10.8l17.5,14.4
	c-9.3,11.3-24.2,19-43.2,19c-35,0-59.7-27.8-59.7-61.7s21.6-61.7,57.1-61.7c30.1,0,54,23.4,54,60.2
	C111.6,111.9,111.4,115.8,110.6,119.4z M26.8,96.8h59.1c-1-14.9-11.8-26.7-28.3-26.7C41.2,70,30.4,81.3,26.8,96.8z"
      />
      <path className="st0" d="M458.1,168.8L410.8,49.4h26.7l30.9,82.8l30.9-82.8H526l-47.3,119.4H458.1z" />
      <path
        className="st0"
        d="M375.6,119.4h-84.3c3.9,16.5,16.5,28.8,33.9,28.8c13.6,0,20.3-4.9,25.7-10.8l17.5,14.4
	c-9.3,11.3-24.2,19-43.2,19c-35,0-59.7-27.8-59.7-61.7s21.6-61.7,57.1-61.7c30.1,0,54,23.4,54,60.2
	C376.7,111.9,376.4,115.8,375.6,119.4z M291.8,96.8h59.1c-1-14.9-11.8-26.7-28.3-26.7C306.2,70,295.4,81.3,291.8,96.8z"
      />
      <path
        className="st0"
        d="M670.2,119.4h-84.3c3.9,16.5,16.5,28.8,33.9,28.8c13.6,0,20.3-4.9,25.7-10.8l17.5,14.4
	c-9.3,11.3-24.2,19-43.2,19c-35,0-59.7-27.8-59.7-61.7s21.6-61.7,57.1-61.7c30.1,0,54,23.4,54,60.2
	C671.2,111.9,671,115.8,670.2,119.4z M586.4,96.8h59.1c-1-14.9-11.8-26.7-28.3-26.7C600.8,70,590,81.3,586.4,96.8z"
      />
      <path
        className="st0"
        d="M817.3,104v64.8h-24.7V104c0-21.9-6.7-33.9-25.7-33.9c-14.9,0-27.8,13.9-27.8,37v61.7h-24.7V49.4h24.7v16.5
	c4.9-8.5,15.2-18.5,32.4-18.5C800.9,47.4,817.3,67.4,817.3,104z"
      />
      <path
        className="st0"
        d="M227.1,137.5l-0.2-0.1c-3.8,6-9.4,10.8-19.1,10.8c-15.7,0-24.2-13.4-24.2-30.9V0h-45.4v22.6h20.7v89.6
	c0,34.7,16.2,58.6,48.6,58.6c18.2,0,29.5-8.6,37.4-18.6L227.1,137.5z"
      />
    </svg>

  ),
}
