/* eslint-disable max-len */
const programName = 'NLY Man'
export default {
  programName,
  logo: (
    <svg x="0px" y="0px" viewBox="0 0 103.1 19">
      <title>{programName}</title>
      <polygon
        fill="#112335"
        points="58.7,9 53.2,0 49.9,0 49.9,18.9 53.2,18.9 53.2,5.6 58.7,14.5 64.4,5.7 64.4,18.9 67.8,18.9 67.8,0
                64.4,0 "
      />
      <path fill="#112335" d="M76,0l-6.6,18.9h3.5l1.4-4.2h7l1.4,4.2H86L79.4,0H76z M75.2,11.8L77.8,4l2.5,7.8H75.2z" />
      <polygon
        fill="#112335"
        points="99.8,0 99.8,13.6 91.3,0 87.9,0 87.9,18.9 91.3,18.9 91.3,5.4 99.7,18.9 99.8,18.9 103.1,18.9
                103.1,0 "
      />
      <polygon fill="#112335" points="34.7,8.9 30.1,0 26.4,0 33,11.9 33,18.9 36.3,18.9 36.3,11.9 43,0 39.3,0 " />
      <polygon fill="#112335" points="22.3,0 19,0 19,18.9 30,18.9 30,15.8 22.3,15.8 " />
      <polygon fill="#112335" points="11.9,13.6 3.4,0.1 0,0.1 0,19 3.4,19 3.4,5.5 11.8,19 11.9,19 15.2,19 15.2,0.1 11.9,0.1 " />
    </svg>
  ),
}
