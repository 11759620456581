/* eslint-disable max-len */
const programName = 'Dharmazone'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 215.21 44.53">
      <title>{programName}</title>
      <g id="Lager_2" data-name="Lager 2">
        <g id="Lager_1-2" data-name="Lager 1">
          <g>
            <g>
              <path d="M80.94,30.61a1.43,1.43,0,0,0,.4-.62c.06-.18.15-.26.26-.26a.27.27,0,0,1,.19.11c.06.26.13.8.23,1.6l.26,2.08c0,.13-.08.2-.24.2s-.17-.08-.23-.23a6,6,0,0,0-1.74-2.4,3.83,3.83,0,0,0-2.51-1,2.5,2.5,0,0,0-2,.81,2.64,2.64,0,0,0-.68,1.71,2.05,2.05,0,0,0,.74,1.7,11.05,11.05,0,0,0,3.06,1.32,13.18,13.18,0,0,1,2.54,1,4.19,4.19,0,0,1,1.42,1.39A3.46,3.46,0,0,1,83.2,40a4.24,4.24,0,0,1-1.45,3.13,4.94,4.94,0,0,1-3.55,1.38,7.15,7.15,0,0,1-3.8-1.18,1.38,1.38,0,0,0-.49.73c-.06.23-.15.35-.29.35s-.2-.15-.25-.46-.16-1.73-.32-4.28a.22.22,0,0,1,.23-.14c.11,0,.2.08.27.25,1.13,2.85,2.72,4.28,4.75,4.28a3.09,3.09,0,0,0,2.37-.92,3,3,0,0,0,.86-2.09,2.58,2.58,0,0,0-.46-1.5,3.41,3.41,0,0,0-1.21-1.11,14.75,14.75,0,0,0-2.4-.94,10,10,0,0,1-2.86-1.28,3.84,3.84,0,0,1-1.11-1.36,3.5,3.5,0,0,1-.35-1.46,3.36,3.36,0,0,1,1.33-2.61,4.86,4.86,0,0,1,3.27-1.14,5.74,5.74,0,0,1,1.53.23A8.6,8.6,0,0,1,80.94,30.61Z" fill="#090400" />
              <path d="M90,42.11a24.69,24.69,0,0,0,1.9-5.2,1.69,1.69,0,0,0-.2-.76,1.37,1.37,0,0,0-.4-.5,1.09,1.09,0,0,0-.58-.15c-.18,0-.27-.08-.27-.2s.1-.25.29-.25l2,0q1.41,0,1.59,0t.18.18c0,.1-.13.19-.37.26-.44.16-.66.37-.66.65a4.15,4.15,0,0,0,.2.72c.14.4.41,1.08.82,2L95.82,42c.5-1.27,1-2.51,1.35-3.75a13,13,0,0,0,.61-2.13c0-.38-.23-.59-.71-.64-.27,0-.41-.11-.41-.25S96.78,35,97,35s.73,0,1.48,0a9,9,0,0,1,1,0q.21,0,.21.18c0,.09-.15.21-.44.34a1.83,1.83,0,0,0-.83.64,5.73,5.73,0,0,0-.63,1.64c-.19.68-.62,1.88-1.27,3.6l-1.06,2.83c-.06.16-.13.25-.23.25s-.21-.19-.34-.56l-1-2.26q-1.11-2.53-1.62-3.93l-.07.06c-.21.63-.53,1.47-1,2.51l-1.15,2.81a8.37,8.37,0,0,1-.59,1.33.21.21,0,0,1-.14.07q-.14,0-.33-.48c-.6-1.54-1.16-2.92-1.67-4.12L86.13,37a4.34,4.34,0,0,0-.71-1.28.94.94,0,0,0-.53-.2c-.25,0-.38-.1-.38-.21s.17-.24.5-.24l2.51,0,1,0c.21,0,.32.06.32.17s-.14.25-.43.29c-.53.08-.8.26-.8.53A42.91,42.91,0,0,0,90,42.11Z" fill="#090400" />
              <path d="M102.52,39.14c0,.37,0,.67,0,.89a3.91,3.91,0,0,0,.93,2.65,2.85,2.85,0,0,0,2.28,1.09,3.56,3.56,0,0,0,2.47-1.07,1.18,1.18,0,0,1,.43-.29c.11,0,.18.09.18.28A3.26,3.26,0,0,1,107.33,44a4.83,4.83,0,0,1-2.26.53A4.15,4.15,0,0,1,102,43.21a4.63,4.63,0,0,1-1.27-3.35,5,5,0,0,1,1.4-3.61,4.12,4.12,0,0,1,3-1.45,3.89,3.89,0,0,1,2.7,1.13,3.57,3.57,0,0,1,1.2,2.66.59.59,0,0,1-.07.34c0,.08-.33.12-.85.12Zm0-.58c1,0,1.89.06,2.8.06l.58,0a1.86,1.86,0,0,0,1-.26.9.9,0,0,0,.36-.83,2.33,2.33,0,0,0-.64-1.62,2,2,0,0,0-1.5-.69,2.28,2.28,0,0,0-1.59.79A4.39,4.39,0,0,0,102.52,38.56Z" fill="#090400" />
              <path d="M119.1,29.16c0,1.58,0,3.71,0,6.39l-.09,7a1.21,1.21,0,0,0,1.07,1.35c.24,0,.35.12.35.23a.16.16,0,0,1-.12.17c-.09,0-.57,0-1.47,0l-1.36,0c0-.45,0-.77,0-1s0-.61,0-1.08a4.73,4.73,0,0,1-1.36,1.66,2.81,2.81,0,0,1-1.69.56,4.06,4.06,0,0,1-2.67-1.24,4.32,4.32,0,0,1-1.35-3.34,5.34,5.34,0,0,1,1.26-3.55,4,4,0,0,1,3.12-1.5,3.16,3.16,0,0,1,1.6.39,2.87,2.87,0,0,1,1.09,1.19c.05-.87.07-1.69.07-2.45a19.94,19.94,0,0,0-.18-3.91.93.93,0,0,0-.83-.46c-.27,0-.41-.11-.41-.22s.18-.19.52-.19Zm-1.56,9.91a5,5,0,0,0-.64-2.82,2.16,2.16,0,0,0-1.92-.93,2.21,2.21,0,0,0-2,1.09,6.31,6.31,0,0,0-.67,3.27,4.88,4.88,0,0,0,.8,3.15,2.28,2.28,0,0,0,1.74,1,2.49,2.49,0,0,0,1.82-1A5.83,5.83,0,0,0,117.54,39.07Z" fill="#090400" />
              <path d="M123.75,39.14c0,.37,0,.67,0,.89a3.91,3.91,0,0,0,.92,2.65,2.88,2.88,0,0,0,2.29,1.09,3.56,3.56,0,0,0,2.47-1.07c.22-.19.36-.29.42-.29s.18.09.19.28A3.26,3.26,0,0,1,128.56,44a4.87,4.87,0,0,1-2.27.53,4.13,4.13,0,0,1-3.1-1.32,4.63,4.63,0,0,1-1.27-3.35,5,5,0,0,1,1.39-3.61,4.13,4.13,0,0,1,3-1.45,3.85,3.85,0,0,1,2.7,1.13,3.54,3.54,0,0,1,1.21,2.66.58.58,0,0,1-.08.34c0,.08-.33.12-.84.12Zm0-.58q1.44.06,2.79.06l.59,0a1.91,1.91,0,0,0,1-.26.9.9,0,0,0,.35-.83,2.33,2.33,0,0,0-.63-1.62,2,2,0,0,0-1.5-.69,2.25,2.25,0,0,0-1.59.79A4.39,4.39,0,0,0,123.75,38.56Z" fill="#090400" />
              <path d="M134.35,34.83a6.85,6.85,0,0,1,.32,2.2,5.31,5.31,0,0,1,1.52-1.62,3.31,3.31,0,0,1,1.82-.54,3.87,3.87,0,0,1,1.46.28,2.12,2.12,0,0,1,1.08,1,5.27,5.27,0,0,1,.38,2.35l-.07,3.72a2.74,2.74,0,0,0,.18,1.28,1.2,1.2,0,0,0,.73.42q.39.11.39.24c0,.11-.07.17-.19.17h-.15a8.13,8.13,0,0,0-1.51-.1c-.6,0-1.24,0-1.92.08-.28,0-.41-.07-.41-.19s.07-.15.22-.18a2.25,2.25,0,0,0,.8-.28.74.74,0,0,0,.26-.38,10.64,10.64,0,0,0,.13-1.59c0-.91.05-1.65.05-2.21a5.55,5.55,0,0,0-.59-3.1,1.75,1.75,0,0,0-1.46-.78,2.5,2.5,0,0,0-2.15,1.4,9.41,9.41,0,0,0-.85,4.66,5.56,5.56,0,0,0,.19,1.89c.13.27.45.43,1,.49q.24,0,.24.21t-.24.15l-.58,0c-.2,0-.88,0-2,0l-.88,0q-.48,0-.48-.18c0-.12.17-.22.52-.29a1,1,0,0,0,.68-.34,4.44,4.44,0,0,0,.24-1.66c0-.94.06-2.1.06-3.51a6.68,6.68,0,0,0-.26-2.43,1.12,1.12,0,0,0-.75-.59c-.28-.07-.43-.16-.43-.28s.11-.18.33-.18h.9Z" fill="#090400" />
            </g>
            <g>
              <path d="M195.66,22.05h-1.87a.4.4,0,0,1-.32-.16L180.87,5.8l0,15.8a.41.41,0,0,1-.42.41h-2.27a.42.42,0,0,1-.42-.41L177.77.88a.41.41,0,0,1,.42-.41h2.19a.41.41,0,0,1,.32.15L193,16.33,193,.92a.4.4,0,0,1,.4-.41h2.28a.41.41,0,0,1,.41.41l-.05,20.72A.41.41,0,0,1,195.66,22.05Z" fill="#090400" />
              <path d="M7.6,21.57l-7.19,0A.41.41,0,0,1,0,21.14L.05.43A.41.41,0,0,1,.46,0l7.2,0C14.27.05,19.06,4.58,19,10.8S14.21,21.59,7.6,21.57Zm-4.43-3H7.61c4.81,0,8.18-3.17,8.19-7.73S12.46,3,7.65,3H3.2Z" fill="#090400" />
              <path d="M39,21.65H36.61a.41.41,0,0,1-.41-.41l0-8.92-11.14,0,0,8.92a.41.41,0,0,1-.4.41H22.32a.4.4,0,0,1-.41-.41L22,.48a.42.42,0,0,1,.42-.41h2.33a.41.41,0,0,1,.41.41l0,8.79,11.14,0,0-8.79a.41.41,0,0,1,.41-.41H39a.4.4,0,0,1,.41.41l0,20.71A.41.41,0,0,1,39,21.65Z" fill="#090400" />
              <path d="M63.35,21.71H60.81a.41.41,0,0,1-.37-.24l-2.31-5.24-10.74,0L45,21.43a.41.41,0,0,1-.38.24h-2.4a.39.39,0,0,1-.34-.19.44.44,0,0,1,0-.39L51.38.24A.39.39,0,0,1,51.75,0h2.19a.39.39,0,0,1,.37.24L63.66,21a.42.42,0,0,1,.09.27A.39.39,0,0,1,63.35,21.71ZM48.69,13.28l8.17,0-4-9.16Z" fill="#090400" />
              <path d="M84,21.77H81.09a.4.4,0,0,1-.33-.17L74.89,13.7l-5.29,0,0,7.64a.41.41,0,0,1-.42.41H66.82a.4.4,0,0,1-.4-.41l0-20.72a.4.4,0,0,1,.41-.4l8.91,0c4.8,0,7.9,2.61,7.89,6.62,0,3.17-2,5.5-5.34,6.32L84.24,21a.41.41,0,0,1-.26.73Zm-14.38-11h6c3,0,4.92-1.51,4.92-3.86S78.71,3.2,75.61,3.19l-6,0Z" fill="#090400" />
              <path d="M107.78,21.83h-2.34a.41.41,0,0,1-.41-.41l0-15.44-6.82,10a.42.42,0,0,1-.34.18H97.8a.42.42,0,0,1-.35-.18L90.69,6l0,15.41a.41.41,0,0,1-.42.41H88a.41.41,0,0,1-.41-.41l0-20.72A.41.41,0,0,1,88,.24h2.37a.42.42,0,0,1,.34.18l7.17,10.81L105.13.46a.4.4,0,0,1,.33-.18h2.37a.41.41,0,0,1,.41.41l0,20.72A.41.41,0,0,1,107.78,21.83Z" fill="#090400" />
              <path d="M132.06,21.89h-2.54a.4.4,0,0,1-.37-.24l-2.31-5.23-10.74,0-2.36,5.22a.4.4,0,0,1-.38.24H111a.4.4,0,0,1-.37-.58L120.09.41a.41.41,0,0,1,.38-.24h2.18a.42.42,0,0,1,.38.24l9.34,20.8a.35.35,0,0,1,.1.26A.41.41,0,0,1,132.06,21.89ZM117.4,13.45l8.17,0-4-9.15Z" fill="#090400" />
              <path d="M151.84,21.94l-16.61,0a.41.41,0,0,1-.41-.41V19.92a.41.41,0,0,1,.09-.26L147.84,3.31l-12.12,0a.41.41,0,0,1-.41-.41V.77a.41.41,0,0,1,.41-.41l16.16,0a.42.42,0,0,1,.41.41V2.39a.4.4,0,0,1-.09.25L139.26,19l12.58,0a.41.41,0,0,1,.41.41v2.1A.4.4,0,0,1,151.84,21.94Z" fill="#090400" />
              <path d="M164.36,22.33a10.77,10.77,0,0,1-10.91-11.12,11,11,0,0,1,11-11.13A10.79,10.79,0,0,1,175.4,11.2,11,11,0,0,1,164.36,22.33Zm0-19.27c-4.38,0-7.7,3.47-7.71,8.1s3.31,8.17,7.73,8.19,7.71-3.47,7.72-8.1S168.84,3.07,164.41,3.06Z" fill="#090400" />
              <path d="M214.79,22.1l-15.12,0a.4.4,0,0,1-.4-.41l0-20.72a.41.41,0,0,1,.41-.41l15,0a.41.41,0,0,1,.41.41V3.11a.41.41,0,0,1-.41.41l-12.22,0,0,6.25,10.9,0a.42.42,0,0,1,.41.42v2.13a.42.42,0,0,1-.41.41l-10.9,0v6.43l12.37,0a.41.41,0,0,1,.41.41v2.13A.41.41,0,0,1,214.79,22.1Z" fill="#090400" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
}
