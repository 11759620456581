/* eslint-disable max-len */
const programName = 'Parfym'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 4158.8 582.95">
      <title>{programName}</title>
      <path d="M27.94,563.71H96.2V75.79h66.16c20.79,0,39.78,2.29,56.43,6.79,17,4.6,31.65,12,43.62,22a97.41,97.41,0,0,1,27.81,38.76c6.28,15.43,9.47,34.05,9.47,55.34,0,19.17-2.72,36.39-8.06,51.15s-13,27.67-22.7,38a96,96,0,0,1-34.6,23.61,117,117,0,0,1-43.58,7.94c-2.84,0-5.94-.06-9.31-.21-2.09-.07-4.3-.23-6.61-.45l-15.34,51.71c7.34,1.49,22.18,2.12,25.37,2.12a209.22,209.22,0,0,0,71.65-12.77,196.29,196.29,0,0,0,62.33-36.5,180.68,180.68,0,0,0,43.77-57.94c11-22.66,16.51-48.76,16.51-77.6,0-26.83-5-50.91-15-71.54a147.76,147.76,0,0,0-42.23-52.5c-18.14-14.24-40.23-25.27-65.63-32.79S206,19.6,174.86,19.6H27.94Z" />
      <path d="M788.87,563.71h88L684.12,19.6h-99L392.38,563.71H458l46.56-143.08h237Zm-64.75-197H521.26L616.41,61.3h17.9Z" />
      <path d="M1472.64,563.71h71.13V307.83h207.49l10.16-54.31H1543.77V75.79h246.1L1800.4,19.6H1472.64Z" />
      <path d="M2901.18,563.71l82.76-.41-44-543-127-.7L2672.42,456.41h-15.64L2517,19.6H2388.2l-41.59,544.11h71.22l27.06-500.79h16.62l159.54,489.43h78L2856.71,62.92h16.63Z" />
      <path d="M1131.94,291.3,1280.4,563.71l89.54-.41-148-237s25-9,42-22c10.81-8.27,23.37-17.48,34-31a140.85,140.85,0,0,0,25-47c4.89-15.47,8-35,8-59,0-19.27-5.85-44.38-14.38-61.22s-21-31.95-37-44.67-36-23-59.49-30.53-46.35-11.28-76-11.28H973.94V563.71h69.58V75.79h87.31c19.48,0,37.82,2.65,52.54,7.86s27.42,12.64,37.39,21.93a91.33,91.33,0,0,1,22.66,33.31A110.58,110.58,0,0,1,1251,179.8a136.41,136.41,0,0,1-4.74,35.09,113.2,113.2,0,0,1-13.3,30.41c-6.33,9.49-19,23-35,32C1176.49,289.36,1131.94,291.3,1131.94,291.3Z" />
      <path d="M3171.39,375.06a60.08,60.08,0,0,1-44.11-18.6,65.93,65.93,0,0,1-13-19.86,59.09,59.09,0,0,1-4.82-23.42,61.57,61.57,0,0,1,4.82-24,67.84,67.84,0,0,1,13-20.28,61.56,61.56,0,0,1,19.44-14,60.3,60.3,0,0,1,49.13,0,61,61,0,0,1,19.66,14,68,68,0,0,1,12.95,20.28,61.56,61.56,0,0,1,4.81,24,59.08,59.08,0,0,1-4.81,23.42,66.12,66.12,0,0,1-12.95,19.86,59.87,59.87,0,0,1-19.66,13.59A60.59,60.59,0,0,1,3171.39,375.06Z" />
      <path d="M2238.94,19.3,2091.56,278.4l-6.62-.1L1936.74,19.6h-69.13L2046,321.3s4.79,158.94,4.43,242.41h69.12l.35-242.41L2308,19.6Z" />
      <path d="M3693.21,468.33c10.84-20.4,16.34-43.1,16.34-67.46,0-20.6-3.85-38.18-11.44-52.23h0a114.93,114.93,0,0,0-30.9-36.44,182.69,182.69,0,0,0-44.72-25.2c-17.18-6.79-34.89-12.94-52.64-18.3q-27.26-8.22-53.23-15.95A279.39,279.39,0,0,1,3468.77,234c-14.54-7.44-26.42-16.65-35.3-27.38-9.34-11.29-14.08-25.54-14.08-42.36a70.81,70.81,0,0,1,6.9-31.17l.15-.39c5.3-13.7,24.43-34.11,34.74-41.18,9.9-6.79,21.84-12.07,35.49-15.73a175.73,175.73,0,0,1,44.9-5.39,300.59,300.59,0,0,1,30.64,1.83c11.57,1.23,23.46,3,35.34,5.25a286,286,0,0,1,35.93,9.24,210.21,210.21,0,0,1,28.28,11.53l15.65-61.07c-9.58-2.79-19.53-5.22-29.66-7.26-12.72-2.54-25.26-4.58-37.26-6.08s-23.63-2.62-34.52-3.3-20.21-1-27.84-1c-31.93,0-61.31,4.53-87.32,13.47S3422.38,54.17,3404,69.45a164.19,164.19,0,0,0-42.82,53.62A144.6,144.6,0,0,0,3346,187.88c0,20.21,3.83,37.42,11.4,51.15a116.94,116.94,0,0,0,30.46,35.76,179,179,0,0,0,43.94,24.92c16.93,6.78,34.39,12.94,51.89,18.32q26.73,8.22,52.43,15.93a266,266,0,0,1,47.13,18.81c14.18,7.46,25.84,16.55,34.68,27,9.37,11.13,14.12,25.15,14.12,41.66a72.61,72.61,0,0,1-8.91,35.54,96.29,96.29,0,0,1-26.21,29.3c-9.91,7.14-30.93,17.95-44,21.64-23.84,6.75-51.86,7.83-85.24,2.78a387.84,387.84,0,0,1-42.24-8.92,408.83,408.83,0,0,1-43.5-14.41,365.1,365.1,0,0,1-33.46-15.12L3332,538.19a547.92,547.92,0,0,0,80.28,23c29.39,6,57.35,9.11,83.09,9.11,29.93,0,58.32-4.3,84.37-12.76s48.87-20.37,68.15-35.53A169.14,169.14,0,0,0,3693.21,468.33Z" />
      <polygon points="4131.1 563.85 4120.54 507.54 3873.91 507.54 3873.91 456.34 3873.91 456.34 3873.91 307.48 4081.85 307.48 4092.03 253.06 3873.91 253.06 3873.91 74.95 4120.54 74.95 4131.1 18.63 3804.63 18.63 3804.63 563.92 3873.91 563.92 3873.91 563.85 4131.1 563.85" />
    </svg>),
}
