/* eslint-disable max-len */
const programName = 'Boohoo'
export default {
  programName,
  logo: (
    <svg x="0px" y="0px" viewBox="0 0 609 151">
      <title>{programName}</title>
      <g>
        <path d="M176.5,52.1h-47.9c-12.4,0.2-22.3,10.4-22.1,22.8v47.4c-0.2,12.4,9.7,22.7,22.1,22.9h47.9c12.4-0.2,22.3-10.5,22.1-22.9
   V74.8C198.8,62.5,188.9,52.3,176.5,52.1z M184.6,81.3v35.1c0.1,8-6.3,14.6-14.3,14.8h-35.6c-8-0.1-14.4-6.8-14.3-14.8V81.3
   c-0.1-8,6.3-14.6,14.3-14.8h35.6C178.3,66.7,184.7,73.3,184.6,81.3z"
        />
        <path d="M278.2,52.1h-48.1c-12.4,0.2-22.3,10.4-22.1,22.8v47.4c-0.2,12.4,9.7,22.7,22.1,22.9h48.1c12.4-0.2,22.3-10.5,22.1-22.9
   V74.8C300.5,62.5,290.6,52.3,278.2,52.1z M286.4,81.3v35.1c0.1,8-6.3,14.6-14.3,14.8h-35.6c-8-0.2-14.3-6.8-14.2-14.8V81.3
   c-0.1-8,6.3-14.6,14.3-14.8h35.6C280.1,66.7,286.5,73.3,286.4,81.3z"
        />
        <path d="M480.7,52.1h-48.1c-12.4,0.2-22.3,10.4-22.1,22.8v47.4c-0.2,12.4,9.7,22.6,22.1,22.8h48.1c12.4-0.2,22.3-10.4,22.1-22.8
   V74.8C503,62.5,493.1,52.3,480.7,52.1z M488.8,81.3v35.1c0.1,8-6.3,14.6-14.3,14.8h-35.6c-8-0.1-14.4-6.8-14.3-14.8V81.3
   c-0.1-8,6.3-14.6,14.3-14.8h35.6C482.5,66.7,488.9,73.3,488.8,81.3z"
        />
        <path d="M582.3,52.1h-48.1c-12.4,0.2-22.3,10.4-22.1,22.8v47.4c-0.2,12.4,9.7,22.6,22.1,22.8h48.1c12.4-0.2,22.3-10.4,22.1-22.8
   V74.8C604.5,62.5,594.7,52.3,582.3,52.1z M590.4,81.3v35.1c0.1,8-6.3,14.6-14.3,14.8h-35.6c-8-0.1-14.4-6.8-14.3-14.8V81.3
   c-0.1-8,6.3-14.6,14.3-14.8h35.6C584.1,66.7,590.5,73.3,590.4,81.3L590.4,81.3z"
        />
        <path d="M74.4,52.1H26.3c-2.7,0-5.3,0.6-7.8,1.6v-48H4.4v116.8c-0.2,12.4,9.7,22.6,22.1,22.8h47.8c12.4-0.2,22.3-10.5,22.1-22.9
   V74.9C96.6,62.5,86.8,52.3,74.4,52.1z M82.5,81.3v35.1c0.1,8-6.3,14.6-14.3,14.8H32.5c-8-0.1-14.4-6.8-14.3-14.8V81.3
   c0-7.9,6.3-14.4,14.3-14.6h35.6C76.1,66.9,82.5,73.4,82.5,81.3z"
        />
        <path d="M379.5,52.5h-47.9c-2.7,0-5.3,0.6-7.8,1.6v-48h-14.1v139.3h14.1v-1.2c0-5.6-0.3-62-0.3-62.5c0-7.9,6.3-14.4,14.3-14.6h35.6
   c8,0.1,14.4,6.8,14.3,14.8v63.6h14.2v-70c0.3-12.4-9.6-22.7-22-23C379.7,52.5,379.6,52.5,379.5,52.5z"
        />
      </g>
    </svg>
  ),
}
