/* eslint-disable max-len */
const programName = 'Prada'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 86.6 13.6">
      <title>{programName}</title>
      <path
        d="M10.2,8.4H5.6v3.3c0,1.6,1.5,1.6,1.7,1.6v0.2H0v-0.2c0.2,0,1.7-0.1,1.7-1.6V1.8c0-1.6-1.5-1.6-1.7-1.6V0h10.2 C13.4,0,15,2.1,15,4.2C15,6.3,13.5,8.4,10.2,8.4z M8.4,0.9H5.6v6.6h2.8c2.1,0,3.1-1.5,3.1-3.3C11.5,2.5,10.5,0.9,8.4,0.9z M28.6,13.6l-6.3-5.2v3.3c0,1.6,1.5,1.6,1.7,1.6v0.2h-7.3v-0.2c0.2,0,1.7-0.1,1.7-1.6V1.8c0-1.6-1.5-1.6-1.7-1.6V0h10.2 c3.2,0,4.8,2.1,4.8,4.2c0,2.1-1.5,4.2-4.8,4.2h-3.1c0.7,0.7,2.2,1,3.5,1c0.6,0,1.2-0.1,1.7-0.2l5.2,4.2v0.2 C34.2,13.6,28.6,13.6,28.6,13.6z M25.1,0.9h-2.9v6.6h2.9c2.1,0,3.1-1.5,3.1-3.3C28.2,2.5,27.2,0.9,25.1,0.9z M45.4,13.6v-0.2 c0.7,0,1-0.2,1-0.7c0-0.2-0.1-0.5-0.2-0.8l-1.4-2.3h-5.7l-1,2c-0.1,0.3-0.2,0.6-0.2,0.9c0,0.5,0.2,0.8,0.8,0.8v0.2h-3.5v-0.2 c0.8,0,1.4-0.6,1.9-1.6l4.2-8.4l-1.2-1.9c-0.6-1-1.2-1.2-1.9-1.2V0h5.6l6.8,11.9c0.4,0.7,0.9,1.5,1.8,1.5v0.2H45.4z M41.8,4.3 l-2.2,4.3h4.7L41.8,4.3z M61.8,13.6h-8v-0.2c0.2,0,1.7-0.1,1.7-1.6V1.9c0-1.6-1.5-1.6-1.7-1.6V0.1h8c3.6,0,7.3,2.3,7.3,6.8 C69.2,11.3,65.4,13.6,61.8,13.6z M64.6,4.3c0-2.5-1.6-3.4-3.3-3.4h-1.9v11.8h1.9c1.7,0,3.3-0.9,3.3-3.4V4.3z M79.6,13.6v-0.2 c0.7,0,1-0.2,1-0.7c0-0.2-0.1-0.5-0.2-0.8l-1.3-2.3h-5.7l-1,2c-0.1,0.3-0.2,0.6-0.2,0.9c0,0.5,0.2,0.8,0.8,0.8v0.2h-3.5v-0.2 c0.8,0,1.4-0.6,1.9-1.6l4.2-8.4l-1.2-1.9c-0.6-1-1.2-1.2-1.9-1.2V0H78l6.8,11.9c0.4,0.7,0.9,1.5,1.8,1.5v0.2H79.6z M76,4.3l-2.2,4.4 h4.7L76,4.3z"
      />
    </svg>),
}
