/* eslint-disable max-len */
const programName = 'Vans'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 415.52 169.03">
      <title>{programName}</title>
      <path d="M25.47 280.15h-6.28L18 266.67h6.29zm380.55 0h6.29l1.2-13.48h-6.29zM14.28 266.67H8l1.2 13.48h6.29zM416 280.15h6.29l1.2-13.48h-6.29zm-358.72 3.28a8.49 8.49 0 0 1-8.54 8.82 8.64 8.64 0 0 1-8.59-8.92v-.09c0-4.77 3.41-8.82 8.5-8.82a8.65 8.65 0 0 1 8.64 8.92v.09m-26.69 0c0 9.48 7.68 17.08 18 17.08s18.15-7.69 18.15-17.18v-.09c0-9.49-7.68-17.08-18-17.08s-18.15 7.69-18.15 17.18v.09m42 16.42h9.31v-11.8h15.87v-7.6H81.89v-5.62h17.57v-8H72.58v33zm32.3 0h9.31v-11.8h15.89v-7.6h-15.9v-5.62h17.57v-8h-26.89v33zm53.09 0h9.31v-25h10.08v-8h-29.49v8H158v25zm25.51 0h9.31V287.3h12v12.55h9.31v-33h-9.31v12.36h-12v-12.39h-9.31v33zm36.89 0h27.27v-7.79h-18.07v-5.24h16.13v-7.22h-16.13v-5h17.81v-7.79h-27v33zm52.85.24h8.11l6.72-19 6.77 19h8.11l11.23-33.27h-9.65l-5.86 19.11-6.48-19.21h-8l-6.48 19.21-5.86-19.11H262zm51.84-13.26l3.79-9.39 3.74 9.39h-7.54zm-14.93 13h9.79l2.4-5.9h13l2.45 5.9h10l-14.3-33.27h-9zm42 0h25.59v-8h-16.25v-25h-9.31v33zm30.77 0h25.59v-8h-16.25v-25h-9.31v33z" transform="translate(-7.99 -131.48)" />
      <path className="cls-1" d="M368.2 183.71h-18.06c-1.75-4.44-3.71-7.89-13.83-7.89-7 0-10 2.88-10 5.77 0 3.16 2.49 5.82 10.2 7.53l16.36 4.36c10.71 2.86 15.9 11.36 15.9 20.34 0 5-1.08 11-5.82 16-3.54 3.69-8.9 8.56-26.67 8.56-24.44 0-32.47-15.45-32.47-24.78h19c1.87 6.82 6.55 9.41 15.81 9.41 6.42 0 11.93-2.29 11.93-7.2 0-4.67-4-6.43-9.15-7.84l-12.93-3.49c-6.23-1.9-11.44-3.42-15.12-6.53-9.63-8.16-8.93-22.89 1.54-30.55 5.8-4.24 11.74-6.45 21.17-6.45 11.08 0 17.41 3.16 22.39 6.6 4.42 3 9.67 10.38 9.67 16.2m-305-52.23c.15.65 37.8 106 37.8 106h22.47l34.61-87.7h215l1.63-18.33h-233.1l-28.72 72.67L87 131.48H63.17M249 199.57l28.06 37.89h17.56v-74.93H274.4v40.75L249 162.53h-19.4v74.93H249v-37.89zm126.35 34a4 4 0 1 0-4 4 4 4 0 0 0 4.05-4zm-.46 0a3.52 3.52 0 1 1-3.51-3.53 3.5 3.5 0 0 1 3.56 3.53zm-1.62-.83a1.43 1.43 0 0 0-1.66-1.4h-1.88v4.24h.94v-1.36h.74l.9 1.36h1.09l-1-1.52a1.32 1.32 0 0 0 .92-1.32zm-.95.05a.62.62 0 0 1-.72.62h-.94v-1.24h.91a.63.63 0 0 1 .8.63zM142 237.46h21.55l4-11.72h28.53l4.13 11.72h21.22l-27.16-74.93h-22.85zm31.6-25.28l8.35-24.91 8.24 24.91h-16.64z" transform="translate(-7.99 -131.48)" />
    </svg>
  ),
}
