/* eslint-disable max-len */
const programName = 'Baum Und Pferdgarten'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 206 64">
      <title>{programName}</title>
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Header-1440" transform="translate(-120.000000, -61.000000)" fill="#fff">
          <g id="Logo" transform="translate(120.000000, 61.000000)">
            <path d="M164.73331,14.9972175 L164.73331,22.4295409 C164.73331,29.8607639 157.296822,29.8233548 157.296822,29.8233548 L156.899176,29.8233548 C156.899176,29.8233548 149.465988,29.8233548 149.465988,22.4091859 L149.465988,15.0043693 L149.465988,0.630442956 L154.04525,0.630442956 L154.04525,22.5962318 C154.04525,25.5609091 157.015225,25.5609091 157.015225,25.5609091 L157.175273,25.5609091 C157.175273,25.5609091 160.149098,25.5735622 160.149098,22.6050339 L160.149098,0.630442956 L164.73991,0.630442956 L164.73331,14.9972175" id="Fill-1" />
            <path d="M205.68591,12.7477707 C205.68591,0.630497969 200.627603,0.630497969 195.617145,0.630497969 L195.0028,0.630497969 L190.416939,0.630497969 L190.416939,29.3722991 L195.0028,29.3722991 L195.617145,29.3722991 C200.833301,29.3722991 205.68591,29.3805511 205.68591,17.2539261 L205.68591,12.7477707 Z M195.0028,4.89294366 C200.325105,4.89294366 201.277147,5.21587288 201.277147,14.9989229 C201.277147,24.7836234 200.325105,25.1098534 195.0028,25.1098534 L195.0028,4.89294366 L195.0028,4.89294366 Z" id="Fill-3" />
            <path d="M53.0791339,14.9972175 L53.0791339,22.4295409 C53.0791339,29.8607639 45.6459464,29.8233548 45.6459464,29.8233548 L45.2466498,29.8233548 C45.2466498,29.8233548 37.8151123,29.8233548 37.8151123,22.4091859 L37.8151123,15.0043693 L37.8151123,0.630442956 L42.3916238,0.630442956 L42.3916238,22.5962318 C42.3916238,25.5609091 45.3643488,25.5609091 45.3643488,25.5609091 L45.5227474,25.5609091 C45.5227474,25.5609091 48.4949224,25.5735622 48.4949224,22.6050339 L48.4949224,0.630442956 L53.0835338,0.630442956 L53.0791339,14.9972175" id="Fill-6" />
            <path d="M23.348749,0.630277915 L29.1413002,0.630277915 L34.6291541,29.3720791 L30.0427427,29.3720791 L29.1176504,23.4394236 L23.4251983,23.4394236 L22.4473065,29.3720791 L17.8630951,29.3720791 L23.348749,0.630277915 Z M24.3183908,18.8485473 L28.3069573,18.8485473 L26.3847234,5.20905121 L24.3183908,18.8485473 Z" id="Fill-8" />
            <polygon id="Fill-10" points="72.3598817 29.3791208 77.5204883 5.86800287 77.072242 29.3791208 81.6559035 29.3791208 81.6559035 0.641720722 75.0664089 0.641720722 70.0438512 21.7305944 65.0234934 0.641720722 58.4306989 0.641720722 58.4306989 29.3791208 63.0154603 29.3791208 62.5672141 5.86800287 67.7267207 29.3791208" />
            <polygon id="Fill-12" points="205.094555 62.9868106 198.502311 62.9868106 193.340604 39.9279037 193.79435 63.000564 189.206839 63.000564 189.206839 34.2527114 195.796883 34.2527114 200.96244 56.8753613 200.511444 34.2527114 205.094555 34.2527114" />
            <path d="M104.974992,41.0399465 C104.974992,38.074719 102.003367,38.074719 102.003367,38.074719 L101.843319,38.074719 C101.843319,38.074719 98.8711437,38.0609657 98.8711437,41.0316945 L98.8711437,44.0024233 L98.8711437,56.2302731 C98.8711437,59.1966008 101.843319,59.1966008 101.843319,59.1966008 L102.003367,59.1966008 C102.003367,59.1966008 104.974992,59.2114545 104.974992,56.2385251 L104.974992,52.1664261 L101.843319,52.1664261 L101.843319,47.5595959 L109.559754,47.5595959 L109.559754,56.0646825 C109.559754,63.4948053 102.126566,63.4590465 102.126566,63.4590465 L101.72837,63.456846 C101.72837,63.456846 94.2918822,63.456846 94.2918822,56.0426771 L94.2918822,48.6389607 L94.2918822,41.2093881 C94.2918822,33.7787151 101.72837,33.815024 101.72837,33.815024 L102.126566,33.8172246 C102.126566,33.8172246 109.559754,33.8172246 109.559754,41.2291929 L109.559754,43.3620662 L104.965642,43.3620662 L104.974992,41.0399465" id="Fill-14" />
            <path d="M118.29896,34.2610735 L124.093162,34.2610735 L129.582115,63.004525 L124.994054,63.004525 L124.069512,57.0691189 L118.37871,57.0691189 L117.398068,63.004525 L112.815506,63.004525 L118.29896,34.2610735 Z M119.270802,52.4787927 L123.259369,52.4787927 L121.334935,38.8392966 L119.270802,52.4787927 Z" id="Fill-17" />
            <polygon id="Fill-19" points="23.3546889 63.004525 23.3546889 50.8619461 31.1734231 50.8619461 31.1734231 46.5995004 23.3546889 46.5995004 23.3546889 38.522969 32.3597632 38.522969 32.3597632 34.2610735 18.7715775 34.2610735 18.7715775 63.004525" />
            <polygon id="Fill-21" points="49.7082672 38.522969 49.7082672 34.2610735 36.1189815 34.2610735 36.1189815 63.004525 49.7082672 63.004525 49.7082672 58.7420793 40.703193 58.7420793 40.703193 50.8619461 48.5202772 50.8619461 48.5202772 46.5995004 40.703193 46.5995004 40.703193 38.522969" />
            <polygon id="Fill-23" points="184.184006 38.522969 184.184006 34.2610735 170.59362 34.2610735 170.59362 63.004525 184.184006 63.004525 184.184006 58.7420793 175.182782 58.7420793 175.182782 50.8619461 182.998766 50.8619461 182.998766 46.5995004 175.182782 46.5995004 175.182782 38.522969" />
            <path d="M6.55618022,46.5993354 L4.59819669,46.5993354 L4.59819669,38.5129015 L6.55618022,38.5129015 C6.55618022,38.5129015 10.6151461,38.5129015 10.6151461,42.5618949 C10.6151461,46.6202405 6.55618022,46.5993354 6.55618022,46.5993354 M7.12817541,34.2609084 L0.0123352642,34.2609084 L0.0123352642,63.00436 L4.59819669,63.00436 L4.59819669,50.8441767 L7.12817541,50.8441767 C7.12817541,50.8441767 15.2807568,50.686288 15.2807568,42.5525426 C15.2807568,34.4193473 7.12817541,34.2609084 7.12817541,34.2609084" id="Fill-25" />
            <path d="M67.20582,48.7488227 C68.637458,47.5275231 69.7825484,45.5987499 69.7825484,42.5521025 C69.7825484,34.4194573 61.6305169,34.2610184 61.6305169,34.2610184 L54.5141268,34.2610184 L54.5141268,63.00447 L59.0983382,63.00447 L59.0983382,50.8888477 L61.0508218,50.8888477 C65.1004378,50.8888477 65.1004378,54.9488437 65.1004378,54.9488437 L65.1004378,63.00447 L69.702799,63.00447 L69.702799,54.7546461 C69.702799,54.7546461 69.6307496,51.1639152 67.20582,48.7488227 M59.0983382,38.5119113 L61.0579718,38.5119113 C61.0579718,38.5119113 65.1174876,38.5119113 65.1174876,42.5620049 C65.1174876,46.6187001 61.0579718,46.5994454 61.0579718,46.5994454 L59.0983382,46.5994454 L59.0983382,38.5119113" id="Fill-27" />
            <path d="M146.038802,48.7488227 C147.47044,47.5275231 148.61553,45.5987499 148.61553,42.5521025 C148.61553,34.4194573 140.463499,34.2610184 140.463499,34.2610184 L133.347109,34.2610184 L133.347109,63.00447 L137.93132,63.00447 L137.93132,50.8888477 L139.883804,50.8888477 C143.93342,50.8888477 143.93342,54.9488437 143.93342,54.9488437 L143.93342,63.00447 L148.535231,63.00447 L148.535231,54.7546461 C148.535231,54.7546461 148.463182,51.1639152 146.038802,48.7488227 M137.93132,38.5119113 L139.890954,38.5119113 C139.890954,38.5119113 143.950469,38.5119113 143.950469,42.5620049 C143.950469,46.6187001 139.890954,46.5994454 139.890954,46.5994454 L137.93132,46.5994454 L137.93132,38.5119113" id="Fill-29" />
            <polygon id="Fill-31" points="156.96567 38.522969 156.96567 63.004525 161.528432 63.004525 161.528432 38.522969 166.872187 38.522969 166.872187 34.2610735 151.602115 34.2610735 151.602115 38.522969" />
            <path d="M89.7628353,46.3790613 C89.7628353,34.2612385 84.7023279,34.2612385 79.69297,34.2612385 L79.0791752,34.2612385 L74.4916638,34.2612385 L74.4916638,63.00469 L79.0791752,63.00469 L79.69297,63.00469 C84.9102261,63.00469 89.7628353,63.0090911 89.7628353,50.8868672 L89.7628353,46.3790613 Z M79.0791752,38.5231341 C84.4009304,38.5231341 85.3524224,38.8460633 85.3524224,48.6313139 C85.3524224,58.4165644 84.4009304,58.7422443 79.0791752,58.7422443 L79.0791752,38.5231341 L79.0791752,38.5231341 Z" id="Fill-33" />
            <path d="M11.6259276,13.9511909 C11.6259276,13.9511909 14.424854,11.9817077 14.3671045,7.82433781 C14.3671045,0.635724251 7.16106513,0.635724251 7.16106513,0.635724251 L0.00012536692,0.635724251 L0.00012536692,29.3665227 L7.11596551,29.3665227 C7.11596551,29.3665227 15.2685469,29.3665227 15.2685469,21.2349778 C15.2685469,17.4505994 13.5113117,15.2363062 11.6259276,13.9511909 L11.6259276,13.9511909 Z M4.58598679,4.89816994 L6.35917188,4.89816994 C6.35917188,4.89816994 10.034241,4.89816994 10.034241,8.56426934 C10.034241,12.239721 6.35917188,12.2221167 6.35917188,12.2221167 L4.58598679,12.2221167 L4.58598679,4.89816994 Z M6.54397032,25.1150797 L4.58598679,25.1150797 L4.58598679,17.0286459 L6.54397032,17.0286459 C6.54397032,17.0286459 10.6029362,17.0082909 10.6029362,21.0655362 C10.6029362,25.1150797 6.54397032,25.1150797 6.54397032,25.1150797 L6.54397032,25.1150797 Z" id="Fill-35" />
            <polygon id="Fill-37" points="185.401641 29.3614064 178.809396 29.3614064 173.64769 6.30249946 174.101436 29.3751598 169.513924 29.3751598 169.513924 0.627307186 176.103969 0.627307186 181.269526 23.2499571 180.818529 0.627307186 185.401641 0.627307186" />
          </g>
        </g>
      </g>
    </svg>
  ),
}
