/* eslint-disable max-len */
const programName = 'Frank Dandy'
export default {
  programName,
  logo: (
    <svg className="frankdandy" viewBox="0 0 379.655 150.036">
      <title>{programName}</title>
      <polygon points="10.784 39.977 53.916 39.977 53.916 29.194 10.784 29.194 10.784 18.411 53.916 18.411 53.916 7.628 0.001 7.628 0.001 61.543 10.784 61.543 10.784 39.977" />
      <path className="3e2cbf74-a72e-4f8e-8888-fbe6ea485c43" d="M204.889,50.76H180.166l24.723-24.725Zm10.783,10.783V0l-61.539,61.54Z" />
      <path className="3e2cbf74-a72e-4f8e-8888-fbe6ea485c43" d="M124.01,131.627H99.286L124.01,106.9Zm10.783,10.783V80.873L73.255,142.411Z" />
      <polygon className="3e2cbf74-a72e-4f8e-8888-fbe6ea485c43" points="253.422 26.036 296.553 69.168 296.553 7.628 285.769 7.628 285.769 43.136 242.637 0.004 242.637 61.543 253.422 61.543 253.422 26.036" />
      <polygon className="3e2cbf74-a72e-4f8e-8888-fbe6ea485c43" points="204.889 124.002 161.758 80.873 161.758 142.411 172.54 142.411 172.54 106.903 215.672 150.036 215.672 88.498 204.889 88.498 204.889 124.002" />
      <polygon className="3e2cbf74-a72e-4f8e-8888-fbe6ea485c43" points="379.652 56.135 372.027 63.759 350.478 42.21 323.52 69.167 323.52 7.628 334.303 7.628 334.303 43.135 372.03 5.41 379.655 13.034 358.102 34.585 379.652 56.135" />
      <polygon className="3e2cbf74-a72e-4f8e-8888-fbe6ea485c43" points="372.028 86.277 350.477 107.827 328.895 86.247 321.271 93.872 345.086 117.686 345.086 142.411 355.87 142.411 355.87 117.686 379.652 93.904 372.028 86.277" />
      <path className="3e2cbf74-a72e-4f8e-8888-fbe6ea485c43" d="M32.359,131.627H10.784V99.28H32.359a16.174,16.174,0,0,1,0,32.348m0-43.13H0v53.913H32.359a26.957,26.957,0,1,0,0-53.913" />
      <path className="3e2cbf74-a72e-4f8e-8888-fbe6ea485c43" d="M274.971,131.627H253.4V99.28h21.574a16.174,16.174,0,1,1,0,32.348m0-43.13H242.615v53.913h32.356a26.957,26.957,0,1,0,0-53.913" />
      <path className="3e2cbf74-a72e-4f8e-8888-fbe6ea485c43" d="M119.273,17.755a16.2,16.2,0,0,1,1.061,21.7L97.572,16.694a16.2,16.2,0,0,1,21.7,1.061M127.98,47.1A26.955,26.955,0,0,0,89.926,9.047L80.878,0V61.54H91.662V26.033L129.4,63.774l7.624-7.625Z" />
    </svg>),
}
