/* eslint-disable max-len */
const programName = 'New Balance'
export default {
  programName,
  logo: (
    <svg x="0px" y="0px" viewBox="0 0 447 221">
      <title>{programName}</title>
      <path
        className="st0"
        d="M227.2,79.6l20.2-35.2l68.6-4.4l2.4-4.3l-63.4-4.4l14.9-25.7h117.6c36.2,0,62.9,13,52.5,50.9
                        c-3.1,11.6-17.8,40.6-61,52.7c9.2,1.1,32,11.2,28,37.4c-7,46.5-67.1,68.6-100.6,68.6l-138.9,0.1l-6.9-26.9l72.8-5.1l2.5-4.3l-79-4.8
                        l-8.5-31.9l112.3-6.6l2.5-4.2L61.5,118.9l9.7-16.8l217.2-14.1l2.5-4.2L227.2,79.6 M318.1,86.6l20-0.1c13.4-0.1,27.3-6.2,32.4-18.5
                        c4.7-11.5-1.6-21.3-10-21.2h-19.4L318.1,86.6z M295,126.5l-24.1,41.9h23c10.7,0,29.8-5.3,35.7-20.5c5.5-14.2-4.6-21.4-11.6-21.4
                        L295,126.5z M119.8,191.2l-14,23.9L6,215.3l9.7-16.8L119.8,191.2z M126.8,5.5l80.3,0.1l6.1,22.7l-95.9-6L126.8,5.5z M217.7,46.3
                        l8.8,33.3l-137.1-9L99,53.9L217.7,46.3z M147.9,142.3l-17.4,30.2L33.8,167l9.7-16.8L147.9,142.3z"
      />
    </svg>

  ),
}
