/* eslint-disable max-len */
const programName = 'nordicspectra'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 500 162">
      <title>{programName}</title>
      <rect className="st0" width="499" height="162" />
      <g>
        <path d="M105.5,62.1c-5.6-5.5-9.3-9.4-11.2-11.7L55.8,13v61.2h-1.7V12.3c-0.5-1.1-1.3-2.3-2.5-3.4c-1.1-1.1-2.4-2.3-3.9-3.5 c-1.6-1.2-2.6-2.2-3.3-2.9V2.3H60l56.2,55.3l0.5-55.3h2l-0.9,73.3C115.2,72.2,111.1,67.7,105.5,62.1z" />
        {' '}
        <path d="M194.1,62.2c-6.7,7.8-15.2,12.1-25.7,13c-10.5,0.7-19.8-2.2-27.9-8.8c-8.2-6.6-12.8-15.1-13.9-25.4 c-1-10.3,1.8-19.3,8.5-27.1s15.2-12.1,25.7-13c10.5-0.7,19.7,2.2,27.9,8.8c8.1,6.7,12.7,15.1,13.7,25.4 C203.5,45.4,200.7,54.4,194.1,62.2z M188.3,11.9c-5.8-6.2-12.3-9-19.7-8.4C160.5,4.1,152.9,7.9,146,15c-7,7.2-11,15.4-12,24.8 c-1,9.3,1.1,17.2,6.4,23.5c5.3,6.4,11.6,9.3,18.8,8.7c8.4-0.6,16.3-4.6,23.7-11.9s11.6-15.6,12.8-25 C196.5,25.9,194.1,18.2,188.3,11.9z" />
        {' '}
        <path d="M261.6,68.8c-3-1.6-6.3-3.1-9.7-4.5c-3.5-1.3-7-3-10.4-5s-6.3-4.7-8.6-8.3s-3.4-8-3.4-13.1v-2l12.6-0.1 c2.2,0,4-0.1,5.6-0.3c1.6-0.1,3.1-0.5,4.8-1.1s3-1.5,4.1-2.6c1-1,1.9-2.5,2.6-4.4c0.6-1.9,0.9-4,0.9-6.6c0-4-0.9-7.5-2.7-10.2 c-1.9-2.6-4-4.5-6.6-5.4s-5.6-1.5-8.8-1.5L219.7,4v70.2h-9.4V2.3h33.9c7.3,0,13.5,1.8,18.6,5.3s7.7,7.9,7.7,13 c0,4.4-2.6,8.3-7.9,11.9s-10.8,5.5-16.5,5.6h-15.8c0,4.9,2.3,8.6,6.7,11.3c4.5,2.8,11,5,19.6,6.7c10.8,2.8,16.6,8.8,17.4,18h-6.4 C266.7,72.2,264.7,70.4,261.6,68.8z" />
        {' '}
        <path d="M338.6,12.7c7.6,7,11.4,15.5,11.4,25.5s-3.7,18.6-11.2,25.5s-16.9,10.5-28.2,10.5h-28l1.7-3.7V4l-6.4-1.7H310 C321.4,2.3,330.9,5.8,338.6,12.7z M332.2,62.5c5.5-7,8.3-14.9,8.3-24.2c0-4.6-0.8-8.8-2.6-12.9c-1.8-4-4.4-7.8-7.9-11.2 c-3.6-3.4-8.5-6.1-14.6-8.1c-6.2-2-13.5-2.9-21.7-2.9V73h16.9C319.4,73,326.7,69.4,332.2,62.5z" />
        {' '}
        <path d="M358.8,13l-0.5-10.7h10L367.6,13c-0.4,17.1-0.4,33.9,0,50.5l0.6,10.7h-10l0.5-10.7C359.5,44.3,359.5,27.4,358.8,13z" />
        {' '}
        <path d="M393.7,53.4c2.4,5.9,5.9,10.7,10.5,14.3c4.5,3.6,9.4,5.5,14.9,5.5c7.2,0,13.4-1.4,18.7-4c5.3-2.6,11.2-7.3,17.8-13.9 l-1.5,2.8c-1.8,2-3.7,3.7-5.8,5.5c-2.2,1.8-4.7,3.6-7.7,5.6s-6.4,3.4-10.2,4.6c-3.7,1.1-7.6,1.8-11.4,1.8 c-11.3-0.1-20.9-3.7-28.7-10.9c-8-7.2-11.9-15.9-11.9-25.9c0-5.1,1-10,3.1-14.5c2.1-4.6,4.9-8.4,8.4-11.5c3.4-3.1,7.5-5.7,12.1-7.9 c4.7-2.2,9.5-3.4,14.7-3.7c5.1-0.3,10.4,0,15.7,1c2.2,0.4,4.4,0.9,6.4,1.6c2,0.6,4,1.3,6.1,2.1c2,0.7,3.1,1.1,3.3,1.2l0.4,18.1 c-2.8-5.7-6.2-10.4-10.3-14c-4.2-3.6-8.4-6-12.7-7.3c-4.3-1.2-8.6-1.6-12.9-0.8c-4.4,0.7-8.2,2.4-11.5,5c-3.4,2.6-6.1,6.2-8.2,10.9 c-2.2,4.7-3.2,9.9-3.2,15.8C390.1,41.2,391.3,47.5,393.7,53.4z" />
        {' '}

      </g>
      {' '}
      <g>
        {' '}
        <path d="M28.1,161.6c-1.8-0.1-3.3-0.1-4.6-0.2c-1.3-0.1-2.7-0.2-4.3-0.5c-1.6-0.3-2.6-0.5-3.3-0.6s-1.9-0.3-3.2-0.8 c-1.5-0.4-2.4-0.7-2.8-0.8c-0.4-0.1-1.3-0.4-2.9-0.9s-2.4-0.8-2.7-0.9l-3.3-28h0.6c0.5,4.9,1.5,9.1,2.9,12.8c1.5,3.7,3.2,6.7,5.3,9 c2,2.4,4.4,4.3,7.1,5.7c2.7,1.6,5.4,2.6,8.2,3.1c2.7,0.5,5.6,0.8,8.6,0.7c3.4-0.1,6.4-0.5,9-1.5c2.5-0.8,4.4-2,5.6-3.4 c1.2-1.3,2.2-2.8,2.7-4.2c0.5-1.3,0.8-2.7,0.8-4c0-2.6-0.9-4.9-2.7-7c-1.8-2-4.2-3.7-7.1-5.2c-2.9-1.5-6.1-2.8-9.5-4.1 c-3.5-1.4-7.1-2.7-10.6-4.2s-6.7-3-9.6-4.8c-3-1.8-5.4-3.9-7.2-6.5c-1.9-2.6-2.8-5.6-2.9-8.9c0-1.3,0.2-2.7,0.5-4.1 c0.3-1.5,1.1-3.1,2.3-4.9c1.1-1.8,2.7-3.4,4.6-4.9c1.8-1.3,4.4-2.6,7.7-3.6c3.2-1,7.1-1.7,11.3-1.8c4.1-0.1,8.5-0.1,13.1,0.2 c4.5,0.3,8,0.7,10.5,1l3.7,0.6l2.9,24.2h-0.5c-0.4-4.7-1.6-8.3-3.3-11c-1.9-2.7-4.6-5.2-8-7.4c-4.9-3.2-10.5-5.1-16.8-5.8 c-6.3-0.7-11.6,0-16.1,2.2s-6.6,5.4-6.5,9.6c0.1,3.8,2,6.9,5.5,9.5s7.8,4.8,12.7,6.4c4.9,1.7,9.9,3.4,14.7,5.2 c4.9,1.8,9.1,4.4,12.7,7.7c3.4,3.3,5.2,7.4,5.3,12.2c0.1,6.5-2.2,11.4-6.8,14.6c-4.7,3.2-10.6,5-17.8,5.1 C31.7,161.7,29.8,161.7,28.1,161.6z" />
        {' '}
        <path d="M122.7,94.3c5.9,4,8.8,9.1,8.8,15.2c0,4.9-1.5,9.1-4.5,12.8c-3,3.7-6.6,6.4-10.8,8.1c-4.1,1.8-8.4,2.6-12.7,2.7H78.9v24.8 l0.2,2.3h-9.6c0.2-1.7,0.3-2.5,0.3-2.6V90.3l-6-2H101C109.6,88.3,116.8,90.3,122.7,94.3z M115.8,125.4c3.3-3.8,5-8.9,5-15.5 c0-13.3-8.7-19.9-26.2-19.9H78.9v41.3h23.7C108.1,131.2,112.5,129.2,115.8,125.4z" />
        {' '}
        <path d="M184.2,88.3l14.2,28c-0.9-0.9-2.2-2.6-3.8-4.9c-1.8-2.3-3.2-4.3-4.6-6c-1.3-1.7-3.3-3.5-5.9-5.7c-2.6-2.2-5.4-3.9-8.3-5.3 c-3-1.3-6.8-2.5-11.5-3.4s-10-1.4-15.7-1.4v33.9h31.5v1.6h-31.5V159c4.9,0.2,9.3-0.3,13.6-1.5c4.3-1.1,8.1-2.9,11.7-5.3 c3.6-2.4,6.8-5,9.7-7.8c2.8-2.7,5.8-6,9-10l-13.1,25.7h-40.4V88.3H184.2z" />
        {' '}
        <path d="M218.9,139.4c2.4,5.9,5.9,10.7,10.5,14.3c4.5,3.6,9.4,5.5,14.9,5.5c7.2,0,13.4-1.4,18.7-4c5.3-2.6,11.2-7.3,17.8-13.9 l-1.5,2.8c-1.8,2-3.7,3.7-5.8,5.5c-2.2,1.8-4.7,3.6-7.7,5.6s-6.4,3.4-10.2,4.6s-7.6,1.8-11.4,1.8c-11.3-0.1-20.9-3.7-28.7-10.9 c-8-7.2-11.9-15.9-11.9-25.9c0-5.1,1-10,3.1-14.5c2.1-4.6,4.9-8.4,8.4-11.5c3.4-3.1,7.5-5.7,12.1-7.9c4.7-2.2,9.5-3.4,14.7-3.7 c5.1-0.3,10.4,0,15.7,1c2.2,0.4,4.4,0.9,6.4,1.6c2,0.6,4,1.3,6.1,2.1c2,0.7,5.5,2.3,5.7,2.4l-3.3,18.6c-0.4-7-4.9-12-8.9-15.7 c-4.1-3.6-8.4-6-12.7-7.3c-4.3-1.2-8.6-1.6-12.9-0.8c-4.4,0.7-8.2,2.4-11.5,5c-3.4,2.6-6.1,6.2-8.2,10.9c-2.2,4.7-3.2,9.9-3.2,15.8 C215.2,127.2,216.5,133.5,218.9,139.4z" />
        {' '}
        <path d="M345.4,112.7c-2-5.9-4.7-11.3-8.2-16c-3.6-4.7-7.8-7-12.4-7h-8.4v70.4h-9.1V89.8H299c-4.7,0-8.8,2.3-12.3,7 c-3.6,4.7-6.3,10-8.3,15.9H278l4.4-24.3h59l4.5,24.3L345.4,112.7z" />
        {' '}
        <path d="M403.5,154.8c-3-1.6-6.3-3.1-9.8-4.5c-3.5-1.3-7-3-10.4-5s-6.3-4.7-8.6-8.3c-2.3-3.6-3.4-8-3.4-13.1v-2l12.5-0.1 c2.2,0,4-0.1,5.6-0.3c1.6-0.1,3.1-0.5,4.8-1.1c1.7-0.6,3-1.5,4.1-2.6c1-1,1.9-2.5,2.6-4.4c0.6-1.9,0.9-4,0.9-6.6 c0-4-0.9-7.5-2.7-10.2c-1.9-2.6-4-4.5-6.6-5.4s-5.6-1.5-8.8-1.5L361.6,90v70.2h-9.4V88.3h33.9c7.3,0,13.5,1.8,18.6,5.3 s7.7,7.9,7.7,13c0,4.4-2.6,8.3-7.9,11.9s-10.8,5.5-16.5,5.6h-15.8c0,4.9,2.3,8.6,6.7,11.3c4.5,2.8,11,5,19.6,6.7 c10.8,2.8,16.6,8.8,17.4,18h-6.4C408.6,158.2,406.5,156.4,403.5,154.8z" />
        {' '}
        <path d="M433.9,160.2h-11L455,95.3c-0.8-1.2-2.6-2.6-5.2-3.9c-2.6-1.4-4.3-2.4-4.8-3h16.8l37.2,71.9h-11.3L474.5,134h-36.3 l-4.5,9.2C429.9,150.6,430,156.3,433.9,160.2z M438.9,132.4h34.9L455.9,97L438.9,132.4z" />
      </g>
    </svg>
  ),
}
