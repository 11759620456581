/* eslint-disable max-len */
const programName = 'Lamp24'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 165 40">
      <title>{programName}</title>
      <path fill="#333" d="M49.4 29.9V10.1h3.1v19.8h-3.1zm16.2 0v-2c-1.1 1.4-2.7 2.3-4.6 2.3-3.7 0-6.3-2.8-6.3-7.5 0-4.6 2.7-7.5 6.3-7.5 1.8 0 3.4.8 4.6 2.3v-2h3.1v14.3h-3.1v.1zm0-4.3v-5.7c-.7-1.1-2.2-1.9-3.7-1.9-2.5 0-4.1 2-4.1 4.7 0 2.8 1.6 4.8 4.1 4.8 1.5 0 3-.8 3.7-1.9zm23.9 4.3v-9.3c0-1.5-.7-2.6-2.4-2.6-1.4 0-2.7 1-3.3 1.9v10h-3.1v-9.3c0-1.5-.7-2.6-2.3-2.6-1.4 0-2.7 1-3.3 1.9v10H72V15.6h3v1.9c.6-.9 2.5-2.3 4.7-2.3 2.1 0 3.4 1.1 3.9 2.6.8-1.3 2.7-2.6 4.9-2.6 2.6 0 4.1 1.4 4.1 4.3v10.4h-3.1zm9.4-2v7.5h-3.1V15.6h3.1v2c1.1-1.4 2.7-2.3 4.6-2.3 3.7 0 6.4 2.8 6.4 7.5s-2.6 7.5-6.4 7.5c-1.8 0-3.4-.8-4.6-2.4zm7.7-5.1c0-2.8-1.6-4.7-4.1-4.7-1.5 0-3 .8-3.7 1.9v5.7c.7 1.1 2.2 2 3.7 2 2.5-.2 4.1-2.2 4.1-4.9zm37.9 5.8 1-1.6c.7.7 2.1 1.4 3.4 1.4 1.3 0 2-.5 2-1.3 0-2-6.1-.6-6.1-4.4 0-1.6 1.4-3 3.9-3 1.7 0 3 .6 3.9 1.4l-.9 1.5c-.6-.7-1.7-1.2-3-1.2-1.1 0-1.8.5-1.8 1.2 0 1.8 6.1.4 6.1 4.4 0 1.8-1.5 3.1-4.2 3.1-1.7.1-3.2-.5-4.3-1.5zm9.9-3.7c0-2.9 2.1-5.3 5.1-5.3s4.9 2.3 4.9 5.4v.5h-7.7c.2 1.5 1.3 2.8 3.2 2.8 1 0 2.1-.4 2.9-1.1l1 1.4c-1 1-2.5 1.5-4.1 1.5-3.1.1-5.3-2-5.3-5.2zm5.1-3.5c-1.9 0-2.7 1.4-2.8 2.6h5.6c0-1.1-.8-2.6-2.8-2.6zm-47.6 8.5v-2.7c6.6-5 9-6.3 9-8.7 0-1.8-1.3-3-2.8-3-2 0-3.6 1-4.7 2.3l-1.6-2.1c1.5-1.7 3.8-2.9 6.2-2.9 3.3 0 5.8 2.3 5.8 5.7 0 3.2-2.5 5.4-7.1 8.8h7.3v2.6h-12.1zm21.3 0V26h-8.1v-2.4l7-10.8h4.1v10.5h2.3v2.6h-2.3v3.9h-3v.1zm0-14.4-5.2 7.9h5.2v-7.9z" />
      <path fill="#DA344E" d="M139.5 28.7c0-.8.7-1.4 1.5-1.4s1.5.7 1.5 1.4c0 .8-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5zM20 .2C9.1.2.2 9.1.2 20c0 2.4.4 4.8 1.3 6.9 1.7-1.6 2.7-.4 4.4-1.9 2-1.7 5.3-5.2 7.2-7.9-.4-.4-.8-.9-1.3-1.3-1.4-1.4-1.4-3.6 0-5.1 1.4-1.4 3.7-1.4 5 0 2.4 2.4 4.2 3.6 8.6 3.9 4.2.3 6.1 2 7.3 3.1l.5.5c.2.2.2.5 0 .7l-5.7 5.7-2.5-2.5 2-2c.2-.2.2-.6-.2-.7-.3-.1-8.5-2.8-8.7-2.8-.2-.1-.4-.1-.5.1-.1.1-.1.2-.1.5.1.2 2.7 8.4 2.8 8.7.1.3.5.4.7.2l2-2 2.5 2.5-5.7 5.7c-.2.2-.5.2-.7 0l-.5-.5c-1.2-1.2-2.9-3.1-3.1-7.3-.1-1.4-.3-2.5-.5-3.5-3.7 3.3-7.7 7.5-8.3 9.1-.5 1.7-.5 4.4 2 6.3 3.2 2.2 7 3.4 11.1 3.4 11 0 19.8-8.9 19.8-19.8C39.9 9.1 31 .2 20 .2z" />
      <path fill="#FFF" d="M20.1 32.3c-.2.2-.5.2-.7 0l-.5-.5c-1.2-1.2-2.9-3.1-3.1-7.3-.1-1.4-.3-2.5-.5-3.5-3.7 3.3-7.7 7.5-8.3 9.1-.5 1.7-.5 4.3 1.7 6.1C5.4 34 2.9 30.8 1.5 27c1.7-1.6 2.7-.4 4.4-1.9 2-1.7 5.3-5.2 7.2-7.9-.4-.4-.8-.9-1.3-1.3-1.4-1.4-1.4-3.6 0-5.1 1.4-1.4 3.7-1.4 5 0 2.4 2.4 4.2 3.6 8.6 3.9 4.2.3 6.1 2 7.3 3.1l.5.5c.2.2.2.5 0 .7l-5.7 5.7-2.5-2.5 2-2c.2-.2.2-.6-.2-.7-.3-.1-8.5-2.8-8.7-2.8-.2-.1-.4-.1-.5.1-.1.1-.1.2-.1.5.1.2 2.7 8.4 2.8 8.7.1.3.5.4.7.2l2-2 2.5 2.5c.3-.1-5.2 5.4-5.4 5.6zm-.1 7.6zM9.4 36.7c-.2-.2-.5-.3-.7-.5l.2.2c.2.2.3.2.5.3zM1.5 27c0-.1-.1-.2-.1-.3 0 .1 0 .2.1.3zM20 39.9c11 0 19.8-8.9 19.8-19.8C39.9 31 31 39.9 20 39.9zM.3 22c-.1-.6-.1-1.3-.1-1.9 0 .6 0 1.2.1 1.9zM34.4 6.3c3.4 3.6 5.5 8.4 5.5 13.7 0-5.3-2.1-10.1-5.5-13.7zM8.7 3.8C11.9 1.5 15.8.2 20.1.2c5.6 0 10.7 2.4 14.3 6.1C30.8 2.6 25.7.2 20 .2c-4.2 0-8.1 1.3-11.3 3.6zM.2 20c0-6.7 3.4-12.7 8.5-16.2C3.6 7.4.2 13.3.2 20z" />
      <path fill="#DA344E" d="M3.9 28.2c-.7 0-1.2.5-1.2 1.2 0 .6.5 1.2 1.2 1.2.6 0 1.2-.5 1.2-1.2 0-.6-.5-1.2-1.2-1.2z" />
    </svg>
  ),
}
