/* eslint-disable max-len */
const programName = 'Birkenstock'
export default {
  programName,
  logo: (
    <svg x="0px" y="0px" viewBox="0 0 163.55 26.43">
      <title>{programName}</title>
      <g id="Assets">
        <g id="Desktop" transform="translate(-29.000000, -30.000000)">
          <g id="logo" transform="translate(29.000000, 30.000000)">
            <path
              id="Fill-1"
              className="st0"
              d="M115.14,4.96c0.29,0,0.53-0.24,0.53-0.53v-3.9
                        c0-0.29-0.24-0.53-0.53-0.53h-13.21c-0.29,0-0.53,0.24-0.53,0.53v3.89c0,0.29,0.24,0.53,0.53,0.53h3.48
                        c0.29,0,0.53,0.24,0.53,0.53v20.39c0,0.3,0.25,0.53,0.53,0.53h4.12c0.29,0,0.53-0.24,0.53-0.53V5.49c0-0.3,0.24-0.53,0.53-0.53
                        H115.14"
            />
            <path
              id="Fill-3"
              className="st0"
              d="M86.38,26.41c0.29,0,0.53-0.24,0.53-0.53l0.01-25.34
                        c0-0.3-0.24-0.54-0.54-0.54H82.5c-0.3,0-0.53,0.25-0.53,0.54v11.27c0,0.29-0.09,0.31-0.2,0.03l-4.7-11.33
                        C76.97,0.24,76.64,0,76.34,0h-3.67c-0.29,0-0.53,0.24-0.53,0.54v25.34c0,0.3,0.24,0.53,0.53,0.53h3.87c0.3,0,0.54-0.24,0.54-0.53
                        V13.95c0-0.3,0.08-0.31,0.18-0.03l4.87,11.99c0.1,0.27,0.42,0.5,0.72,0.5H86.38"
            />
            <path
              id="Fill-5"
              className="st0"
              d="M62.12,15.74c0-0.29,0.06-0.35,0.36-0.35h3.62
                        c0.29,0,0.53-0.25,0.53-0.54v-3.81c0-0.29-0.24-0.53-0.53-0.53h-3.62c-0.3,0-0.36-0.06-0.36-0.36V5.31
                        c0-0.29,0.06-0.35,0.36-0.35h6.25c0.29,0,0.53-0.2,0.53-0.49V0.53c0-0.29-0.24-0.53-0.53-0.53H57.45c-0.29,0-0.53,0.24-0.53,0.53
                        v25.35c0,0.3,0.24,0.53,0.53,0.53h11.28c0.29,0,0.53-0.24,0.53-0.53v-3.91c0-0.3-0.24-0.54-0.53-0.54h-6.25
                        c-0.3,0-0.36-0.06-0.36-0.36L62.12,15.74"
            />
            <path
              id="Fill-6"
              className="st0"
              d="M45.97,15.92c0-0.29,0.08-0.29,0.18-0.02l3.91,10.01
                        c0.09,0.27,0.41,0.5,0.7,0.5H55c0.3,0,0.46-0.23,0.36-0.5l-5.02-12.76c-0.1-0.27-0.1-0.73,0.01-1L54.86,0.5
                        C54.97,0.23,54.81,0,54.52,0h-4.24c-0.3,0-0.61,0.22-0.71,0.5l-3.4,9.29c-0.1,0.27-0.19,0.26-0.19-0.03V0.53
                        c0-0.29-0.24-0.53-0.53-0.53h-3.89c-0.29,0-0.53,0.24-0.53,0.53v25.35c0,0.3,0.24,0.53,0.53,0.53h3.89c0.3,0,0.53-0.24,0.53-0.53
                        L45.97,15.92"
            />
            <path
              id="Fill-7"
              className="st0"
              d="M151.79,15.92c0-0.29,0.08-0.29,0.17-0.02l3.91,10.01
                        c0.1,0.27,0.41,0.5,0.7,0.5h4.24c0.29,0,0.45-0.23,0.35-0.5l-5.02-12.76c-0.1-0.27-0.09-0.73,0.01-1l4.51-11.65
                        c0.11-0.27-0.05-0.5-0.34-0.5h-4.24c-0.3,0-0.62,0.22-0.72,0.5l-3.41,9.29c-0.1,0.27-0.18,0.26-0.18-0.03V0.53
                        c0-0.29-0.24-0.53-0.53-0.53h-3.89c-0.3,0-0.53,0.24-0.53,0.53v25.35c0,0.3,0.24,0.53,0.53,0.53h3.89c0.29,0,0.53-0.24,0.53-0.53
                        v-9.96"
            />
            <path
              id="Fill-8"
              className="st0"
              d="M21.2,0.53C21.2,0.24,20.96,0,20.67,0h-3.89
                        c-0.29,0-0.53,0.24-0.53,0.53v25.35c0,0.29,0.24,0.53,0.53,0.53h3.89c0.29,0,0.53-0.24,0.53-0.53V0.53"
            />
            <path
              id="Fill-9"
              className="st0"
              d="M97.26,11.23c-1.57-1.57-3.19-2.81-3.19-4.27
                        c0-1.01,0.82-1.99,1.85-2c0.24,0,2.14,0,2.93,0h0.01c0.3,0,0.54-0.24,0.54-0.54V0.55V0.54c0-0.3-0.24-0.54-0.54-0.54h-3.65
                        c-4.04,0-6.28,2.82-6.23,6.8c0.02,3.23,1.91,5.44,4.55,7.89c1.5,1.37,2.73,2.5,2.73,4.34c0,1.51-0.87,2.35-2.31,2.39h-4.43
                        c-0.29,0.01-0.53,0.25-0.53,0.55v3.9c0,0.3,0.24,0.54,0.54,0.54h5.83h0.01c4.41,0.09,6.04-3.62,6.04-7.42
                        C101.4,15.34,99.53,13.54,97.26,11.23"
            />
            <path
              id="Fill-11"
              className="st0"
              d="M6.88,21.71H5.32c-0.3,0-0.36-0.06-0.36-0.36v-5.6
                        c0-0.3,0.07-0.36,0.36-0.36h1.56c1.2,0,1.51,1.05,1.54,3.05C8.44,21.09,8.01,21.71,6.88,21.71L6.88,21.71z M4.94,5.06
                        c0-0.3,0.06-0.35,0.35-0.35h1.23c1.12,0,1.58,0.84,1.58,3c0,2.41-0.65,2.79-1.58,2.79H5.29c-0.29,0-0.35-0.06-0.35-0.35V5.06
                        L4.94,5.06z M11.48,12.71c0,0-0.14-0.09-0.15-0.24c0-0.14,0.17-0.28,0.17-0.28c1.2-1.27,1.49-2.55,1.49-3.82V5.3
                        C12.95,1.57,11.69,0,7.58,0H0.54C0.24,0,0,0.24,0,0.54v25.33c0,0.3,0.24,0.54,0.54,0.54h7.41c3.87,0,5.37-2.76,5.37-6.63v-2.17
                        C13.33,15.05,12.94,13.77,11.48,12.71L11.48,12.71z"
            />
            <path
              id="Fill-13"
              className="st0"
              d="M32.86,7.75c0.03,2.66-0.41,3.28-1.54,3.28h-1.55
                        c-0.3,0-0.36-0.06-0.36-0.36v-5.6c0-0.3,0.07-0.36,0.36-0.36h1.55C32.52,4.71,32.83,5.75,32.86,7.75L32.86,7.75z M38.84,25.91
                        l-4.25-10.44h0.05l0.4-0.16c1.3-0.66,2.63-2.07,2.63-4.78V5.62c0-4.7-2.75-5.62-6.14-5.62h-6.55c-0.3,0-0.55,0.24-0.55,0.54
                        v25.33c0,0.3,0.24,0.54,0.55,0.54h3.87c0.3,0,0.54-0.24,0.54-0.54v-9.95c0-0.29,0.08-0.29,0.17-0.02l3.97,10.01
                        c0.09,0.27,0.41,0.5,0.7,0.5h4.24C38.78,26.42,38.94,26.19,38.84,25.91L38.84,25.91z"
            />
            <path
              id="Fill-14"
              className="st0"
              d="M142.57,26.42c-7.68,0-8.64-5.53-8.64-13.25s0.96-13.16,8.64-13.16
                        h1.74c0.3,0,0.55,0.22,0.55,0.51v3.91c0,0.3-0.24,0.54-0.54,0.54h-1.75c-3.03,0-3.52,1.41-3.52,8.21c0,6.83,0.5,8.26,3.52,8.26
                        h1.75l0,0c0.3,0,0.54,0.24,0.54,0.54v3.91c0,0.3-0.24,0.54-0.54,0.54h-1.75"
            />
            <path
              id="Fill-15"
              className="st0"
              d="M124.05,21.46c3.03,0,3.09-1.48,3.09-8.31
                        c0-6.8-0.06-8.47-3.09-8.47c-3.02,0-3.09,1.67-3.09,8.47C120.96,19.98,121.02,21.46,124.05,21.46L124.05,21.46z M124.05,0
                        c7.68,0,8.21,5.43,8.21,13.15s-0.53,13.26-8.21,13.26s-8.21-5.54-8.21-13.26C115.84,5.44,116.36,0,124.05,0L124.05,0z"
            />
            <path
              id="Fill-16"
              className="st0"
              d="M161.72,12.24c0-0.24-0.14-0.37-0.45-0.37h-0.58
                        c-0.04,0-0.05,0.01-0.05,0.05v0.66c0,0.03,0.01,0.05,0.05,0.05h0.58C161.58,12.63,161.72,12.49,161.72,12.24L161.72,12.24z
                         M160.25,14.16c-0.05,0-0.09-0.03-0.09-0.09v-2.54c0-0.06,0.04-0.09,0.09-0.09h1.03c0.59,0,0.94,0.3,0.94,0.81
                        c0,0.37-0.21,0.64-0.54,0.76l0.55,1.07c0.03,0.05,0,0.09-0.05,0.09h-0.36c-0.05,0-0.09-0.03-0.12-0.09l-0.51-1h-0.49
                        c-0.04,0-0.05,0.01-0.05,0.05v0.95c0,0.06-0.04,0.09-0.09,0.09h-0.31V14.16z M163.12,12.81c0-1.17-0.86-2.03-2.02-2.03
                        s-2.02,0.86-2.02,2.03s0.86,2.04,2.02,2.04S163.12,13.98,163.12,12.81L163.12,12.81z M158.65,12.81c0-1.39,1.07-2.45,2.45-2.45
                        c1.4,0,2.45,1.07,2.45,2.45c0,1.4-1.05,2.47-2.45,2.47C159.72,15.28,158.65,14.21,158.65,12.81L158.65,12.81z"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
}
