/* eslint-disable max-len */
const programName = 'Stine Goya'
export default {
  programName,
  logo: (
    <svg x="0px" y="0px" viewBox="0 0 632 74">
      <title>{programName}</title>
      <path
        className="st0"
        d="M42.1,40.2c-2.9-3-7.7-5.8-14.6-8.5c-1-0.4-1.7-0.7-2.2-0.9c-0.6-0.2-1.5-0.6-2.6-1c-5.7-2.2-9.8-4.3-12-6.4
    c-1.1-1-2-2.3-2.6-3.8c-0.6-1.5-0.9-3.1-0.9-4.9c0-3.6,1.2-6.5,3.6-8.7c2.4-2.2,5.6-3.2,9.6-3.2c4.4,0,8.4,1.2,12,3.7
      c3.6,2.5,6.7,6.2,9.1,10.8h2.9L41.7,1.6h-3.3c-0.4,0.8-0.9,1.3-1.5,1.7c-0.6,0.4-1.3,0.5-2,0.5c-0.3,0-2.2-0.6-5.6-1.9
      C25.9,0.6,22.6,0,19.5,0C14.1,0,9.6,1.8,6,5.3c-3.6,3.5-5.4,8-5.4,13.3c0,2.6,0.5,5.1,1.5,7.3c1,2.3,2.4,4.3,4.2,6.1
      c2.8,2.6,7.4,5.2,13.9,7.6c1.4,0.5,2.5,0.9,3.2,1.2c7.3,2.9,12,5.4,14,7.5c2,2.1,3,4.8,3,8.2c0,4.3-1.4,7.8-4.2,10.5
      c-2.8,2.7-6.4,4-10.8,4c-4.8,0-9.2-1.6-13.2-4.9c-4-3.3-7-7.8-9-13.4H0l0.8,19.2h3c0.4-0.9,0.9-1.6,1.6-2c0.7-0.4,1.6-0.6,2.6-0.6
      c0.7,0,3,0.8,6.7,2.4c3.7,1.6,7.1,2.4,10.1,2.4c6.3,0,11.6-1.9,15.8-5.8c4.2-3.9,6.3-8.7,6.3-14.6c0-2.9-0.4-5.4-1.2-7.6
      C45,43.9,43.8,42,42.1,40.2z"
      />
      <path
        className="st0"
        d="M126,4.4h3.1c4.1,0,6.7,0.5,7.9,1.5c1.2,1,1.7,3.5,1.7,7.4v47.6c0,3.9-0.6,6.4-1.7,7.4c-1.2,1-3.8,1.5-7.9,1.5
      H126v2.8h35.4v-2.8h-3.1c-4.2,0-6.8-0.5-8-1.5c-1.2-1-1.8-3.5-1.8-7.4V13.3c0-3.9,0.6-6.3,1.8-7.4c1.2-1,3.8-1.5,8-1.5h3.1V1.6H126
      V4.4z"
      />
      <path
        className="st0"
        d="M216.9,4.4h2.9c3.7,0,6.4,1.1,8,3.4c1.7,2.3,2.5,6,2.5,11.1v38.8L189.1,1.6h-20.4v2.8h4.1c3.1,0,5.4,1,6.9,2.9
      c1.5,1.9,2.3,4.9,2.3,8.8v38.3c0,6-0.8,10-2.3,12.2c-1.5,2.2-4.2,3.2-8.2,3.2h-2.9v2.8h29.6v-2.8H195c-3.6,0-6.2-1.1-7.7-3.3
      c-1.5-2.2-2.2-6.2-2.2-12.1V11.9l44.5,60.7h3.7l0-55.4c0-4.6,0.7-7.9,2.2-9.8c1.5-1.9,4-2.9,7.5-2.9h3.2V1.6h-29.4V4.4z"
      />
      <path
        className="st0"
        d="M307.8,64.7c-3.5,3-9.1,4.6-15.6,4.6h-5.4c-4.2,0-7-0.5-8.4-1.4c-1.4-1-2.1-3.5-2.1-7.5v-24
      c0.5,0,1-0.1,1.6-0.1c0.6,0,1.5,0,2.6,0c5.4,0,9.1,0.9,11.2,2.8c2.1,1.9,3.1,3.5,3.2,8.3h3.2V22H295v0.6c0,4.3-1.1,6.3-3.3,8.1
      c-2.2,1.8-5.8,2.8-10.9,2.8c-0.8,0-2.2,0-4.1-0.1l-0.3,0V13.3c0-3.5,0.6-5.8,1.7-6.8c1.1-1.1,3.4-1.6,6.8-1.6h6.9
      c6,0,10.6,0.9,13.4,3.1c2.8,2.2,4.6,5.8,6,11.4l3.1-2l-1.4-15.7h-59v2.8h3.1c4.1,0,6.8,0.5,7.9,1.5c1.2,1,1.8,3.5,1.8,7.4v47.6
      c0,3.9-0.6,6.3-1.8,7.4c-1.2,1-3.8,1.5-7.9,1.5h-3.1v2.8h60.6l3.2-16.9l-3.3-2.5C312.3,60.4,311.3,61.7,307.8,64.7z"
      />
      <path
        className="st0"
        d="M398.9,45.7c4.2,0.3,6.9,0.9,7.9,1.8c1,0.9,1.5,2,1.5,5.1v3.6c0,5.5-1.5,8.4-4.6,10.9
      c-3.1,2.6-7.5,3.8-13.2,3.8c-7.7,0-13.5-2.7-17.2-8.1c-3.7-5.4-5.5-13.9-5.5-25.3c0-11.2,1.9-19.8,5.7-25.7C377.2,6,382.7,3,389.9,3
      c5.9,0,11.4,1.9,15.7,5.4c3.4,2.8,4.9,3.5,8.8,11.1l2.8-2.2l0-14.6h-3.6c-0.4,1.1-0.9,2-1.6,2.6c-0.7,0.6-1.5,0.9-2.4,0.9
      c-0.9,0-2.1-0.5-3.8-1.5c-1.7-1-3-1.7-3.8-2.1c-2-0.9-4-1.5-6.1-2c-2-0.4-4.1-0.7-6.2-0.7c-4.6,0-8.9,1-13.1,3
      c-4.1,2-7.7,4.8-10.7,8.5c-2.9,3.6-5.1,7.6-6.6,12.1c-1.5,4.5-2.2,9.3-2.2,14.6c0,10.7,3.1,19.4,9.2,26.1c6.1,6.7,14.2,10,24,10
      c3.9,0,7.9-0.7,12.1-2.2c4.1-1.4,6.5-2.2,7.1-2.2c0.9,0,1.7,0.3,2.5,0.7c0.8,0.5,1.3,0.9,1.9,2h3.3V56.3c0-4.9,0.6-7.9,1.8-9
      c1.2-1,3.7-1.6,7.6-1.6v-2.8h-27.6V45.7z"
      />
      <path
        className="st0"
        d="M485.5,6.4c-2.6-2.1-5.6-3.6-9-4.8c-3.4-1.1-6.9-1.7-10.6-1.7c-3.7,0-7.2,0.5-10.5,1.6
      c-3.4,1.1-6.4,2.7-9.1,4.8c-4.5,3.6-8,7.9-10.3,13c-2.3,5.1-3.5,10.9-3.5,17.3c0,11,3.1,20,9.3,27c6.2,6.9,14.3,10.4,24.1,10.4
      c3.7,0,7.2-0.6,10.6-1.7c3.4-1.1,6.4-2.7,9-4.8c4.6-3.6,8-8,10.4-13.1c2.3-5.1,3.5-11,3.5-17.6c0-6.5-1.2-12.4-3.5-17.5
      C493.6,14.4,490.1,10,485.5,6.4z M482.8,62.3c-3.9,5.9-9.5,8.8-16.9,8.8c-7.3,0-12.9-2.9-16.8-8.8c-3.9-5.8-5.9-14.3-5.9-25.3
      c0-11,2-19.5,5.9-25.3c3.9-5.9,9.5-8.8,16.8-8.8c7.3,0,12.9,2.9,16.8,8.8c3.9,5.9,5.9,14.3,5.9,25.3
      C488.6,48.1,486.7,56.5,482.8,62.3z"
      />
      <path
        className="st0"
        d="M557.3,14.6c2.2-4.1,4-6.8,5.5-8.1c1.5-1.3,3.2-2,5.3-2.1V1.6h-22.2v2.8h0.5c3,0,5.2,0.4,6.6,1.3
      c1.4,0.8,2.1,2.2,2.1,4c0,0.9-0.2,2.1-0.6,3.3c-0.4,1.3-1.1,2.7-2,4.4l-14.7,28.2l-15.3-32.2c-0.4-0.8-0.6-1.6-0.8-2.3
      c-0.2-0.8-0.3-1.5-0.3-2.1c0-1.6,0.6-2.7,1.8-3.4c1.2-0.7,3.4-1.1,6.4-1.1V1.6h-28.8v2.8c2.7,0.1,4.8,0.8,6.4,1.9
      c1.6,1.1,3.3,3.7,5.3,7.7l17,34.1v12.8c0,3.9-0.6,6.3-1.8,7.4c-1.2,1-3.8,1.5-8,1.5h-3.1v2.8h35.4v-2.8h-3.1c-4.1,0-6.7-0.5-7.9-1.5
      c-1.2-1-1.7-3.5-1.7-7.4V49.2L557.3,14.6z"
      />
      <path
        className="st0"
        d="M631.1,69.7c-2.7,0-4.8-0.6-6.2-1.9c-1.5-1.3-3.1-4.5-4.9-9.6L599.2,0.8l-7.5,3.5L572.6,58
      c-1.5,4.3-3.3,7.3-5.2,9.1c-1.9,1.7-4.5,2.6-7.7,2.7v2.8h24v-2.8h-1c-2.6,0-4.6-0.5-6-1.6c-1.4-1.1-2.1-2.6-2.1-4.5
      c0-0.7,0.1-1.6,0.3-2.5c0.2-1,0.5-2.1,1-3.5l4.1-12h25.6l4.4,12.8c0.5,1.2,0.8,2.4,1.1,3.5s0.4,2,0.4,2.6c0,1.8-0.7,3.1-2.1,3.9
      c-1.4,0.8-3.6,1.2-6.7,1.2v2.8h29.1v-2.8H631.1z M581,42.9L592.8,9l12,33.9H581z"
      />
      <path
        className="st0"
        d="M117.7,1.6h-1.1h-2.2H86.2h0H58h-2.2h-1.1l-2.5,15.7l3.2,1.8c1.8-6.3,2.7-8.1,5.5-10.7c2.8-2.7,7.4-4,12.1-4
      c3.3,0,5.5,0.6,6.7,1.8c1.2,1.2,1.7,3.7,1.7,7.5v47.2c0,3.9-0.6,6.3-1.8,7.3c-1.2,1-3.8,1.5-7.9,1.5h-3.8v2.8h18.3h0h18.3v-2.8h-3.8
      c-4.1,0-6.8-0.5-7.9-1.5c-1.2-1-1.8-3.5-1.8-7.3V13.7c0-3.8,0.6-6.2,1.7-7.5c1.2-1.2,3.4-1.8,6.7-1.8c4.6,0,9.3,1.3,12.1,4
      c2.8,2.7,3.7,4.5,5.5,10.7l3.2-1.8L117.7,1.6z"
      />
    </svg>
  ),
}
