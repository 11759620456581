/* eslint-disable max-len */
const programName = 'Acer'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 100 50">
      <title>{programName}</title>
      <path
        fill="#83b81a"
        d="M92.5,15.8c-0.3-0.4-0.6-0.7-2.1-0.8c-0.4,0-1.6-0.1-3.7-0.1c-5.5,0-9.3,1.7-11.2,5c0.5-3.3-2.7-5-9.7-5
      c-8.2,0-13.4,3.3-15.5,10c-0.8,2.5-0.8,4.5-0.2,6.1L48.1,31c-1.5,0.1-3.5,0.2-4.8,0.2c-3,0-4.9-0.5-5.6-1.5
      c-0.6-0.9-0.6-2.6,0.2-5.1c1.3-4.1,4.4-5.9,9.4-5.9c2.6,0,5.1,0.3,5.1,0.3l0,0c0.3,0,0.5-0.4,0.5-0.7L52.8,18l-0.2-1.4
      c-0.1-0.9-0.8-1.4-2-1.6c-1.1-0.1-2-0.1-3.2-0.1c0,0,0,0-0.1,0c-6.5,0-11.1,2.2-13.6,6.4c0.9-4.3-2.1-6.4-9-6.4
      c-3.9,0-6.7,0.1-8.3,0.3c-1.3,0.2-2.1,0.7-2.4,1.6l-0.6,1.8c-0.1,0.3,0.1,0.5,0.5,0.5l0,0c0.3,0,1.5-0.2,3.5-0.3
      c2.7-0.2,4.6-0.3,5.9-0.3c3.8,0,5.5,1,4.9,2.9c-0.1,0.4-0.4,0.7-0.9,0.8c-3.6,0.6-6.5,1-8.6,1.3c-6,0.8-9.5,2.6-10.4,5.6
      c-1.3,4.1,1.9,6.1,9.6,6.1c3.8,0,7.1-0.2,9.7-0.7c1.5-0.3,2.3-0.6,2.6-1.6l1.4-4.6c-0.1,4.6,3,6.9,9.6,6.9c2.8,0,5.5-0.1,6.8-0.3
      c1.3-0.2,2-0.5,2.3-1.5l0.3-1.4c1.4,2.2,4.5,3.3,9.2,3.3c5,0,7.9-0.1,8.7-0.4c0.7-0.2,1.1-0.6,1.3-1.1c0-0.1,0-0.2,0.1-0.3l0.6-1.9
      c0.1-0.3-0.1-0.5-0.5-0.5l-3.2,0.2c-2.1,0.1-3.8,0.2-5,0.2c-2.3,0-3.8-0.2-4.7-0.5c-1.3-0.5-1.9-1.4-1.8-2.9l10.1-1.3
      c4.5-0.6,7.6-2,9.1-4.2L71,34.3c-0.1,0.2,0,0.4,0.1,0.5c0.1,0.1,0.3,0.2,0.7,0.2H76c0.7,0,1-0.4,1.1-0.7l3.7-12.9
      c0.5-1.7,2-2.5,4.8-2.5c2.5,0,4.3,0,5.5,0.1c0,0,0.1,0,0.1,0c0.2,0,0.6-0.1,0.9-0.6l0.6-1.8C92.7,16.3,92.7,16,92.5,15.8L92.5,15.8z
       M25.1,31.2c-1.3,0.2-3.2,0.3-5.6,0.3c-4.3,0-6.1-0.9-5.6-2.6c0.4-1.2,1.5-2,3.3-2.2l9.7-1.3L25.1,31.2L25.1,31.2z M69.8,20.8
      c-0.4,1.3-2.3,2.2-5.7,2.7l-8.1,1.1l0.2-0.7c0.7-2.2,1.6-3.6,2.8-4.4c1.2-0.8,3.2-1.2,6-1.2C68.7,18.4,70.3,19.2,69.8,20.8
      L69.8,20.8z"
      />
    </svg>
  ),
}
