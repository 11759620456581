/* eslint-disable max-len */
const programName = 'Adidas'
export default {
  programName,
  logo: (
    <svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="1.41421" viewBox="0 0 100 68">
      <title>{programName}</title>
      <path d="m49.735 58.35c0-2.684 2.154-4.838 4.838-4.838s4.837 2.154 4.837 4.838-2.153 4.838-4.837 4.838-4.838-2.154-4.838-4.838m19.682 0c0-2.684 2.154-4.838 4.838-4.838 2.683 0 4.837 2.154 4.837 4.838s-2.154 4.838-4.837 4.838c-2.684 0-4.838-2.154-4.838-4.838m-45.626 0c0-2.684 2.153-4.838 4.838-4.838 2.683 0 4.837 2.154 4.837 4.838s-2.154 4.838-4.837 4.838c-2.685 0-4.838-2.154-4.838-4.838m-19.682 0c0-2.684 2.153-4.838 4.837-4.838 2.685 0 4.838 2.154 4.838 4.838s-2.153 4.838-4.838 4.838c-2.684 0-4.837-2.154-4.837-4.838m39.794-8.549h-4.208v16.932h4.208zm-30.152 16.932h4.208v-16.932h-4.208v1.359c-1.425-.994-3.148-1.557-5.003-1.557-4.838 0-8.748 3.909-8.748 8.747 0 4.871 3.91 8.747 8.748 8.747 1.855 0 3.578-.563 5.003-1.557zm65.308 0h4.208v-16.932h-4.208v1.359c-1.425-.994-3.148-1.557-5.003-1.557-4.838 0-8.747 3.909-8.747 8.747 0 4.871 3.909 8.747 8.747 8.747 1.855 0 3.578-.563 5.003-1.557zm5.766-5.501c.132 3.413 3.048 5.832 7.919 5.832 3.976 0 7.256-1.723 7.256-5.533 0-2.651-1.491-4.208-4.937-4.871l-2.684-.53c-1.723-.331-2.916-.663-2.916-1.69 0-1.126 1.16-1.623 2.618-1.623 2.121 0 2.982 1.06 3.048 2.319h4.242c-.232-3.314-2.883-5.533-7.157-5.533-4.407 0-7.025 2.418-7.025 5.566 0 3.844 3.115 4.473 5.799 4.97l2.22.398c1.59.298 2.286.762 2.286 1.69 0 .828-.795 1.656-2.684 1.656-2.75 0-3.645-1.424-3.678-2.651zm-51.392-17.196v7.124c-1.425-.994-3.148-1.557-5.003-1.557-4.838 0-8.748 3.909-8.748 8.747 0 4.871 3.91 8.747 8.748 8.747 1.855 0 3.578-.563 5.003-1.557v1.193h4.208v-22.697zm25.944 0v7.124c-1.425-.994-3.148-1.557-5.003-1.557-4.838 0-8.747 3.909-8.747 8.747 0 4.871 3.909 8.747 8.747 8.747 1.855 0 3.578-.563 5.003-1.557v1.193h4.208v-22.697zm-48.443-5.003 2.85 5.003h19.649l-7.787-13.453zm32.969 9.211v-4.208h15.474l-16.501-28.562-14.745 8.449 11.564 20.113v4.208zm21.737-4.208h19.682l-25.448-44.036-14.711 8.482z" fillRule="nonzero" />
    </svg>
  ),
}
