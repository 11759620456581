/* eslint-disable max-len */
const programName = 'Lensway'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 717 138.6">
      <title>{programName}</title>
      <path d="M145.2 37.8c-27.2 0-42.9 9.8-46.7 29.2l-2.4 12.5c-.5 2.8-.9 5.7-1 8.6 0 7.2 3.6 14.6 10 19.8 7.6 6.6 16.9 8.8 33 8.8 14.8 0 24.1-1.7 32.3-6.6 7.8-4.7 10.2-8.8 12.1-21.2H154c-1.9 9.9-6.1 14.4-13.4 14.4-5.6 0-9.9-3.8-9.9-8.8.1-1.8.2-3.6.5-5.3l1.2-6.9h51l1.9-11.4c.3-1.8.4-3.6.3-5.4.1-17.5-14.7-27.7-40.4-27.7zm8.3 25.5l-.9 5h-17.4l1.2-7.1c1.2-6.9 3.6-9.1 9.5-9.1 5 0 8 2.6 8 7.1.2 1.4 0 2.8-.4 4.1zm131.8-3.2c.3-1.6.5-3.2.5-4.8 0-9-8.8-16.7-19.2-16.7-7.1 0-15.9 2.8-23.1 7.1-4.1 2.4-6 4.2-11.1 8.7l2.8-14.3h-36.1l-13.1 75h35.8l8.1-45.9c1.6-8.8 5-12.6 11.2-12.6 3.9.2 6.9 3.4 6.9 7.3 0 1-.3 2.9-.5 4.7l-8.6 46.6h36.3l10-53.8c.1-.5.1-.9.1-1.3zM64.7 0H20.8L0 115.1h89l3.7-19.7H47.4zm630.5 40.2l-33.5 52.4-5.2-52.4h-38.2l6.7 71.1.2 1.7c.1.7.2 1.5.2 2.3 0 5.2-2.1 7.1-8.7 7.3l-4.7.2-4.5 15.9h25.1c10.7 0 20.2-5 25.6-13.3l1.4-2.2 53.8-82.8h-18.2zm-364-1.4c-25.4 0-40.4 9.7-40.4 25.8 0 9.3 4.3 14.3 15.5 18l10.2 3.3 1.2.3c6 1.9 9.2 5.2 9.2 9.9 0 5-4 8.3-10.4 8.3-6.2 0-9.8-3.3-9.8-9.2 0-.9.2-3.1.3-5.5h-21.9c-.3 1.9-.5 3.9-.5 5.9.2 6.8 3.8 13 9.5 16.6 6 3.3 14.3 4.8 27.3 4.8 7.6 0 15.1-.9 22.5-2.8 11.9-3.5 20.7-13.5 20.7-24 0-8-5.7-15.4-13.6-18l-2.1-.7-14.3-4.5-1.7-.5c-4.1-1.4-6.6-4-6.6-7.4 0-4 4.5-7.6 9.8-7.6s9.6 4 9.6 8.8c0 1.5-.3 3-.9 4.3h21.6c.3-1.5.5-3 .5-4.5-.1-2.7-.7-5.4-1.9-7.8-4.9-9.9-13.9-13.5-33.8-13.5zM517.8 0l-28.3 67-2.3-67h-42.7l-28 67.4L413.2 0h-42.9l4.5 115.1h44l24.9-58.3 2.1 58.3h44.9L539.3 0zm90.8 72.1c.8-3.8 1.2-7.6 1.4-11.5 0-4.8-1.8-9.4-5-13-6.1-7-15-9.8-32.3-9.8-30.2 0-45.9 7.9-48.5 24.7l26.3-1.4c2.2-7.2 6.2-9.9 14.1-9.9 5.3 0 9.3 3 9.3 6.8 0 6.3-6.2 10.8-15.2 11l-8.3.4h-1.7c-20.8.7-34.8 11.5-34.8 26.7 0 11.6 10.4 20.7 23.2 20.7 8.7 0 16.6-2.8 27.6-10l-1.5 8.4h37.5l7.8-41.3.1-1.8zm-41 18.9c-1.2 6.3-4.1 9.1-9.5 9.1-3.9-.1-7-3.2-6.9-7.1v-.1c0-3.7 2.2-6.3 7.6-9.2l11.4-5.9-2.6 13.2z" fill="#ec2027" />
    </svg>
  ),
}
