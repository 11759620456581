/* eslint-disable max-len */
const programName = 'Kjell & Company'
export default {
  programName,
  logo: (
    <svg className="kjell-company" x="0px" y="0px" viewBox="0 0 145.6 24">
      <title>{programName}</title>
      <rect className="st0" width="145.6" height="24" />
      <path
        className="st1"
        d="M22,2.7c0.5,0,0.7,0.1,0.7,0.4c0,0.2-0.2,1.2-0.3,1.8c-0.1,0.6-0.8,0.6-2,0.6h-0.6c-1,0-1.5,0-1.5-0.5
c0-0.3,0.1-0.9,0.2-1.4c0.2-0.9,0.3-1,1.7-1H22z M57,11.3L54,14.7l1.1,3.5h-3.8L51,17c-0.8,0.9-2.1,1.6-3.2,1.6
c-1.5,0-2.9-1-2.9-3.6c0-3.3,2.2-4.3,4.1-5.1c-0.4-1.1-0.7-2.4-0.7-3.3c0-3,2.8-3.5,4.3-3.5c1.6,0,3.5,0.4,3.5,3.1
c0,1.8-1.1,2.9-3.4,4.3l0.4,1.1l0.3-0.4C53.3,11.3,57,11.3,57,11.3z M50.2,15.1l-0.7-2.2c-1,0.7-1.3,1.1-1.3,2
c0,0.6,0.3,0.9,0.6,0.9C49.3,15.8,49.7,15.4,50.2,15.1z M52.6,6.3c0-0.8-0.3-1.2-0.8-1.2c-0.5,0-0.7,0.5-0.7,1.3
c0,0.6,0.2,1.5,0.4,2.1C52,8.1,52.6,7.1,52.6,6.3L52.6,6.3z M66.5,15c1,0,2-0.4,3.1-0.6l-0.7,4c-1.8,0.6-3.7,0.7-4.6,0.7
c-2.9,0-4.2-0.8-4.2-4.1c0-2.1,0.8-6.9,2-8.9c1.1-1.7,2.8-2,4.4-2c1.7,0,3.3,0.3,4.9,0.8l-0.8,4.7c-0.8-0.2-1.5-0.3-2.3-0.3
c-1.2,0-2.3,0.1-3,1.1c-0.4,0.5-0.7,1.5-0.7,2.7C64.4,14.6,65,15,66.5,15L66.5,15z M80.6,9.8c0,2-0.3,3.8-0.9,5.6
c-1,3.2-2.7,3.8-5,3.8c-2.2,0-4.9-0.2-4.9-4.5c0-2,0.5-4.5,1.2-6.2c0.7-1.6,1.6-2.4,4.7-2.4C77.4,6.1,80.6,6.2,80.6,9.8z M76.6,11.8
c0-1.1-0.3-1.4-1.3-1.4c-1.2,0-1.6,1.9-1.6,3.7c0,0.8,0.4,1.1,1.2,1.1C76.2,15.3,76.6,13.1,76.6,11.8z M96.5,11l-1.3,7.6h-3.8
l0.8-4.4c0.1-0.6,0.3-1.5,0.3-1.8c0-0.7-0.5-0.9-0.8-0.9c-0.9,0-1.1,0.7-1.4,2.6l-0.8,4.6h-3.7l0.8-4.4c0.1-0.6,0.3-1.5,0.3-1.8
c0-0.7-0.5-0.9-0.8-0.9c-0.9,0-1.1,0.7-1.4,2.6l-0.8,4.6h-3.7l2.1-12.2H86l-0.6,2.9h0c0.7-2.1,1.7-3.3,3.3-3.3
c1.6,0,2.3,0.6,2.3,3.3h0c0.5-2.1,1.7-3.3,3.2-3.3c1.3,0,2.4,0.9,2.4,2.6C96.8,9.4,96.6,10.4,96.5,11z M119.3,6.5l-2.2,12.2h-3.9
l0.5-2.5h0c-0.7,2-1.9,2.9-3.4,2.9c-1.5,0-2.7-0.9-2.7-3.4c0-2,0.4-3.9,0.8-5.6c0.6-2.7,2-4,3.9-4c1.7,0,2.6,0.7,2.7,3.1h0l0.5-2.7
H119.3L119.3,6.5z M114.4,12c0-0.8-0.5-1.1-1-1.1c-1.1,0-1.5,0.8-1.8,2.2c-0.1,0.3-0.1,0.7-0.1,0.9c0,0.9,0.5,1.2,1.1,1.2
c1,0,1.5-0.9,1.8-2.1C114.3,12.8,114.4,12.5,114.4,12L114.4,12z M128.8,11.4l-1.3,7.3h-3.9l0.7-3.7c0.1-0.7,0.3-1.9,0.3-2.4
c0-0.8-0.3-1-0.8-1c-0.7,0-1.1,0.3-1.4,2.2l-0.8,4.9h-3.8l2.1-12.2h3.8l-0.5,2.9h0c0.8-2.3,1.8-3.3,3.5-3.3c1.3,0,2.4,0.7,2.4,2.8
C129.1,9.7,129,10.6,128.8,11.4L128.8,11.4z M140.4,6.6l-5.2,12.2c-0.8,1.9-1.6,2.8-3.5,2.8h-3l0.6-3.1h2.1v0
c-0.7-0.7-0.7-1.3-0.8-2.5l-0.7-9.4h3.8l0.1,7.3h0l2.4-7.3L140.4,6.6L140.4,6.6z M22.2,6.2L20,18.9c-0.3,1.8-0.7,2.7-3.2,2.7
c-0.7,0-1.4-0.1-2-0.2l0.5-2.8H11l-1.4-5.8h0l-0.9,5.8H4.6L7,4.2h4.2l-1,5.7h0l3-5.7h5.2l-4.8,7.7l1.8,6.3c0.1,0,0.2,0,0.3,0
c0.4,0,0.6-0.2,0.7-0.8l2-11.1c0.5,0.1,1.2,0.2,2,0.2h0C21.2,6.4,21.7,6.3,22.2,6.2z M38.4,3.7l-2.6,15H32l2.6-15H38.4z M39.5,3.7
h3.8l-2.6,15h-3.8L39.5,3.7z M25.8,13.9c-0.1,0.3-0.1,0.5-0.1,0.6c0,1.3,1.1,1.4,1.9,1.4c1.2,0,2.5-0.4,3.6-0.8l-1,3.2
c-1.4,0.6-2.9,0.9-4.4,0.9c-1.8,0-4.1-0.3-4.1-3.8c0-2.7,0.8-5.7,1.5-7c1.2-2.1,2.6-2.2,4.3-2.2c3.6,0,4.9,0.9,4.9,4.2
c0,0.7-0.4,2.7-0.7,3.5L25.8,13.9L25.8,13.9z M26.1,10.9H29c0-0.3,0.1-0.6,0.1-0.9c0-0.8-0.7-1-1-1C27.1,9,26.5,9.4,26.1,10.9z
M107.5,9.2c0,1.2-0.1,2.2-0.4,3.8c-0.6,3.2-1.5,6-4.2,6c-0.9,0-2.6,0-2.8-2.8h0l-0.9,5.4h-3.9L98,6.5h3.8l-0.5,2.7h0
c0.8-2,1.8-3.1,3.5-3.1C106.6,6.1,107.5,7.1,107.5,9.2L107.5,9.2z M103.1,12.1c0-0.8-0.4-1.2-0.9-1.2c-1.2,0-1.5,1.8-1.5,3.2
c0,0.8,0.2,1.2,0.8,1.2C102.5,15.3,103.1,13.9,103.1,12.1L103.1,12.1z"
      />
    </svg>
  ),
}
