/* eslint-disable max-len */
const programName = 'Evy'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 56.89 35.4">
      <title>{programName}</title>
      <g>
        <path className="st0" d="M3.72,23.77v4.3H2.85v-4.3H1.23v-0.79h4.1v0.79H3.72z" />
        <path className="st0" d="M6.89,28.07v-5.09H10v0.79H7.76v1.33h2.02v0.76H7.76v1.41H10v0.79H6.89z" />
        <path
          className="st0"
          d="M13.86,28.16c-1.61,0-2.74-1.11-2.74-2.62c0-1.51,1.15-2.63,2.74-2.63c0.63,0,1.21,0.2,1.64,0.47l-0.3,0.74
                c-0.4-0.23-0.9-0.39-1.35-0.39c-1.12,0-1.8,0.74-1.8,1.82c0,1.07,0.7,1.81,1.82,1.81c0.45,0,0.98-0.16,1.39-0.4l0.26,0.73
                C15.1,27.95,14.5,28.16,13.86,28.16z"
        />
        <path className="st0" d="M20.33,28.07v-2.19h-2.34v2.19h-0.87v-5.09h0.87v2.12h2.34v-2.12h0.87v5.09H20.33z" />
        <path className="st0" d="M27.2,28.17l-3.19-3.36v3.26h-0.87v-5.16l0.14-0.03l3.15,3.32v-3.22h0.87v5.17L27.2,28.17z" />
        <path
          className="st0"
          d="M31.2,28.16c-1.47,0-2.55-1.12-2.55-2.62c0-1.51,1.12-2.63,2.6-2.63c1.47,0,2.55,1.12,2.55,2.63
                C33.8,27.04,32.67,28.16,31.2,28.16z M31.23,23.68c-0.97,0-1.65,0.79-1.65,1.85c0,1.06,0.67,1.86,1.65,1.86
                c0.96,0,1.64-0.8,1.64-1.86C32.87,24.47,32.19,23.68,31.23,23.68z"
        />
        <path className="st0" d="M35.15,28.07v-5.09h0.87v4.3h2.24v0.79H35.15z" />
        <path
          className="st0"
          d="M41.57,28.16c-1.47,0-2.55-1.12-2.55-2.62c0-1.51,1.12-2.63,2.6-2.63c1.47,0,2.55,1.12,2.55,2.63
                C44.17,27.04,43.04,28.16,41.57,28.16z M41.6,23.68c-0.97,0-1.65,0.79-1.65,1.85c0,1.06,0.67,1.86,1.65,1.86
                c0.96,0,1.64-0.8,1.64-1.86C43.24,24.47,42.56,23.68,41.6,23.68z"
        />
        <path
          className="st0"
          d="M49.81,25.38v2.22c-0.54,0.35-1.13,0.55-1.9,0.55c-1.45,0-2.7-1.05-2.7-2.62c0-1.58,1.27-2.63,2.7-2.63
                c0.75,0,1.29,0.21,1.81,0.53l-0.4,0.69c-0.41-0.26-0.9-0.42-1.37-0.42c-1.16,0-1.81,0.82-1.81,1.83c0,1.08,0.8,1.86,1.81,1.86
                c0.44,0,0.73-0.08,1.03-0.24v-1h-1.19v-0.76H49.81z"
        />
        <path className="st0" d="M53.34,25.93v2.14h-0.87v-2.15l-1.79-2.95h0.99l1.25,2.15l1.24-2.15h0.97L53.34,25.93z" />
      </g>
      <g>
        <path className="st0" d="M9.5,18.01V1.07h10.73v3.61h-6.8V7.7h6.2v3.42h-6.2v3.31h6.8v3.58H9.5z" />
        <path className="st0" d="M28.79,18.15h-0.46L20.24,1.07h4.51l3.86,9.15l3.86-9.15h4.42L28.79,18.15z" />
        <path className="st0" d="M46.55,11.31v6.7h-3.93v-6.72L36.66,1.07h4.51l3.47,6.49l3.47-6.49h4.42L46.55,11.31z" />
      </g>
      <line className="st1" x1="15.82" y1="33.08" x2="40.05" y2="33.08" />
    </svg>),
}
