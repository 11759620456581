/* eslint-disable max-len */
const programName = 'Valmano'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 89 11">
      <title>{programName}</title>
      <path fill="#333" fillRule="evenodd" d="M4.045 10.734h1.574L9.722.266H7.757L4.912 8.324h-.058L2.037.266H0l4.045 10.468zm6.717 0H12.8l.924-2.395h4.435l.954 2.395h2.08L16.786.266h-1.575l-4.449 10.468zm3.583-3.992l1.603-4.258 1.575 4.258h-3.178zm10.458 3.992h6.169V9.078h-4.348V.266h-1.82v10.468zm10.271 0h1.734V2.32h.029l3.062 8.413h1.271l3.106-8.413h.029v8.413h1.806V.266h-2.86l-2.63 7.437h-.043L37.92.266h-2.846v10.468zm14.937 0h2.037l.924-2.395h4.435l.954 2.395h2.08L56.035.266H54.46l-4.449 10.468zm3.583-3.992l1.603-4.258 1.575 4.258h-3.178zm11.036 3.992h1.82V2.647h.029l4.854 8.087h2.311V.266h-1.82v7.836h-.029L67.042.266H64.63v10.468zM78.418 7.71a5.228 5.228 0 0 0 1.142 1.744 5.138 5.138 0 0 0 1.733 1.138c.67.271 1.399.407 2.189.407.799 0 1.536-.136 2.21-.407a5.204 5.204 0 0 0 1.748-1.138 5.174 5.174 0 0 0 1.148-1.744c.275-.676.412-1.422.412-2.24 0-.838-.137-1.595-.412-2.27a4.94 4.94 0 0 0-1.148-1.723A5.101 5.101 0 0 0 85.692.384 6.173 6.173 0 0 0 83.482 0c-.79 0-1.52.128-2.189.384a5.035 5.035 0 0 0-1.733 1.094 4.99 4.99 0 0 0-1.142 1.723c-.274.675-.411 1.432-.411 2.27 0 .818.137 1.564.411 2.24zm1.82-3.749c.169-.463.407-.867.716-1.212a3.332 3.332 0 0 1 1.105-.813 3.4 3.4 0 0 1 1.437-.296c.53 0 1.011.099 1.445.296.433.197.804.468 1.112.813.308.345.547.75.715 1.212.169.464.253.966.253 1.508 0 .562-.084 1.077-.253 1.546-.168.468-.41.874-.722 1.22a3.302 3.302 0 0 1-1.12.805c-.433.192-.91.288-1.43.288a3.319 3.319 0 0 1-2.535-1.094 3.611 3.611 0 0 1-.722-1.22 4.532 4.532 0 0 1-.253-1.545c0-.542.084-1.044.253-1.508z" />
    </svg>
  ),
}
