/* eslint-disable max-len */
const programName = 'Pierre Robert'
export default {
  programName,
  logo: (
    <svg x="0px" y="0px" viewBox="0 0 566.9 140.5">
      <title>{programName}</title>
      <g>
        <path d="M565,59c-6.3-1.9-12.8-3.3-19.3-4.1c-1.6-0.2-3.3-0.4-4.9-0.5l-4.9-0.4c-1.5-0.1-3-0.1-4.5-0.1c-0.2-5.1-0.6-10.1-1.1-15.1
    l0-0.1c-0.2-1.6-1.4-2.9-3-3c-1.8-0.2-3.5,1.2-3.7,3c-0.5,5.1-0.7,10.1-0.9,15.1l-6.3,0c-3.2,0.1-6.3,0-9.4-0.1
    c-6.2-0.2-12.3-0.9-18.5-2c-1.2-0.2-2.4,0.4-2.9,1.6c-0.6,1.4,0,3,1.4,3.6c6.1,2.7,12.8,4.3,19.4,5.1l2.5,0.3
    c0.8,0.1,1.7,0.1,2.5,0.2c1.7,0.1,3.3,0.2,4.9,0.2c2.1,0.1,4.2,0.1,6.3,0.1c0,3.5,0.1,7,0.4,10.5c-2.2,8.3-6.2,16.3-11.4,23.2
    c-1.2,1.5-2.5,3-3.7,4.2c-0.2-1.9-0.2-4.1-0.2-6.2c0-2.6,0-5.3-0.2-8c-0.2-2.7-0.4-5.6-1.3-8.7c-0.5-1.5-1.1-3.2-2.3-4.7
    c-1.2-1.6-3-2.9-4.7-3.6c-0.9-0.4-1.7-0.6-2.4-0.8l-1.1-0.3l-0.4-0.1c-0.1-0.3-0.2-0.6-0.4-1c-0.4-0.8-1-1.8-2.5-2.5
    c-0.7-0.3-1.6-0.5-2.3-0.5c-0.8,0-1.4,0.2-1.9,0.4c-0.5,0.2-1,0.5-1.6,1.1c-0.6,0.5-1.2,1.5-1.4,2.7c-0.2,1.1,0.1,2.1,0.4,2.7
    c0.3,0.6,0.6,1,0.9,1.4c0.3,0.3,0.6,0.6,0.8,0.8l-0.4,1.4c-0.3,1.2-0.7,2.4-1.1,3.6c-0.8,2.4-1.6,4.8-2.5,7.1
    c-1.9,4.6-4.2,9-7.1,12.4c-1.4,1.7-3.1,2.9-4.4,3.3c-0.5,0.1-1.4,0.2-2.1,0c-0.8-0.1-1.5-0.4-2.2-0.9c-1.5-0.9-2.8-2.4-3.9-4.3
    c-0.2-0.4-0.5-0.8-0.7-1.2c1.3-0.5,2.6-1.3,3.7-2.1c1.3-0.9,2.4-1.9,3.5-3c1.1-1.2,1.9-2.5,2.7-3.8c0.8-1.4,1.4-2.8,1.9-4.3
    c0.2-0.7,0.5-1.5,0.6-2.3l0.2-1.2c0.1-0.5,0.1-1,0.1-1.5c0-2-0.4-3.9-1.2-5.6c-0.8-1.7-1.9-3.3-3.6-4.6c-1.6-1.3-3.8-2.2-6.1-2.3
    c-2.3-0.1-4.4,0.7-6,1.6c-0.8,0.5-1.5,1-2.2,1.6c-0.3,0.3-0.6,0.6-0.9,0.9c-0.4,0.4-0.7,0.8-1,1.2c-1.2,1.6-1.8,3.3-2.3,4.9
    c-0.9,3.2-1,6.2-0.8,9.1c0.2,2.7,0.6,5.3,1.3,7.9c-0.9,0.2-1.8,0.2-2.6,0.2c-1.8-0.1-3.5-0.7-5.1-1.9c-0.6-0.4-1.1-0.8-1.6-1.3
    c0.1-0.5,0.1-1,0.2-1.5c0.3-2.7,0.5-5.3,0.5-8.1c0-1.4-0.2-2.9-0.5-4.4c-0.4-1.5-0.8-3.2-2.2-5c-0.7-0.9-1.8-1.8-3.3-2.3
    c-1.5-0.5-3-0.3-4.2,0.1l-0.4,0.2c-0.2,0.1-0.4,0.2-0.5,0.3c-0.4,0.2-0.7,0.4-1,0.7c-0.6,0.5-1,1-1.3,1.5c-0.7,1-1,2-1.2,2.9
    c-0.4,1.8-0.5,3.5-0.3,5c0.2,1.6,0.5,3.1,1,4.5c0.5,1.4,1.1,2.8,1.9,4.1c0.4,0.7,0.8,1.3,1.2,1.9c0.2,0.3,0.4,0.6,0.7,0.9l0.6,0.8
    c0.4,0.4,0.7,0.8,1.1,1.3c-0.6,2.9-1.6,5.6-2.8,8c-1,1.9-2.4,3.5-3.5,4.1c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1,0-0.1,0-0.2,0l-0.3,0
    c-0.4,0.1-0.7,0.1-1,0.1c-0.6,0-1.2-0.3-1.9-0.7c-1.3-0.9-2.7-2.6-3.7-4.6c-1.1-2-2-4.2-2.8-6.4c-1.6-4.5-2.6-9.4-3.5-14.2
    c-0.2-1.1-0.4-2.2-0.6-3.3c2.2-2.8,3.9-5.8,5.3-9c2-4.9,3.5-9.8,4.6-15c0.5-2.6,1-5.2,1.2-7.9c0.2-2.7,0.2-5.5-0.5-8.5
    c-0.4-1.5-1-3.1-2.2-4.6c-1.2-1.5-3.2-2.6-5.1-2.9c-0.5-0.1-0.9-0.1-1.4-0.1c-0.4,0-1.1,0-1.6,0.1c-1.1,0.2-2.2,0.7-3,1.3
    c-1.7,1.3-2.6,2.8-3.2,4.2c-1.3,2.8-1.8,5.5-2.2,8.2c-0.4,2.7-0.5,5.3-0.6,7.9c-0.1,2.6,0,5.2,0.2,7.8c0.2,2.6,0.4,5.1,0.6,7.7
    c0.3,3.5,0.7,6.9,1.2,10.4c-0.3,0.3-0.6,0.6-0.8,0.8c-1.7,1.5-3.6,2.8-5.6,3.7c-0.2,0.1-0.3,0.1-0.5,0.2c0-0.1,0-0.1-0.1-0.2
    c-1-2.4-2.3-4.9-4.6-7c-1.1-1-2.6-1.9-4.4-2.4c-1.7-0.5-3.7-0.4-5.3,0.2c-0.8,0.2-1.7,0.8-2.5,1.3c-0.4,0.3-0.8,0.7-1.1,1.1
    c-0.3,0.4-0.6,0.8-0.9,1.3c-1,1.8-1.1,3.9-0.7,5.6c0.4,1.7,1.3,3.2,2.4,4.4c1.1,1.2,2.5,2,3.9,2.6c2.5,1,5,0.9,7.2,0.4
    c0,0.3,0.1,0.5,0.1,0.8c0.5,3.9,0.1,7.8-1.6,10.5c-0.8,1.4-1.9,2.4-3.2,3c-1.1,0.6-2.9,0.8-4.5,0.7c-3.3-0.2-6.1-2.2-8-5.1
    c-1-1.5-1.6-3.3-2.1-5.1c-0.5-1.9-0.7-3.8-0.6-5.7c0-1.9,0.3-3.8,0.9-5.6c0.3-0.9,0.7-1.8,1.1-2.7c0.2-0.4,0.4-0.9,0.7-1.3
    c0.2-0.4,0.5-0.9,0.7-1.1L379,74c0.8-1.1,0.6-2.7-0.4-3.6c-1.1-1-2.9-1-3.9,0.2c-0.6,0.6-0.9,1.1-1.3,1.6c-0.4,0.5-0.7,1.1-1.1,1.6
    c-0.7,1.1-1.2,2.2-1.7,3.4c-1,2.3-1.6,4.9-1.8,7.4c-0.2,2.5,0,5.1,0.5,7.6c0.5,2.5,1.3,5,2.6,7.3c1.3,2.3,3.2,4.5,5.5,6.1
    c2.3,1.6,5.1,2.6,7.9,2.9c1.4,0.2,2.8,0.1,4.3,0c0.7-0.1,1.4-0.3,2.1-0.4c0.7-0.2,1.5-0.5,2.2-0.8c2.8-1.3,5-3.5,6.5-5.9
    c1.5-2.5,2.3-5.2,2.7-7.8c0.3-2.6,0.3-5.2-0.1-7.7c-0.1-0.8-0.3-1.6-0.4-2.5c0.3-0.1,0.7-0.2,1-0.4c2-0.8,3.9-1.8,5.6-3.1
    c0.9,4.7,1.9,9.5,3.5,14.2c0.8,2.5,1.8,5,3.2,7.5c1.4,2.5,3,5,5.8,7c1.4,1,3.2,1.8,5.1,1.9c0.9,0.1,1.9,0.1,2.8-0.1l0.7-0.1
    l0.7-0.2c0.5-0.2,0.9-0.3,1.4-0.6c1.7-0.9,3-2,4-3.2c1-1.2,1.8-2.5,2.6-3.7c1.1-2,1.9-4.1,2.6-6.2c2.3,1.5,5.2,2.5,8.1,2.7
    c2.1,0.2,4-0.1,5.9-0.5c0.3,0.5,0.6,1.1,0.9,1.6c1.6,2.6,3.7,5.2,6.7,7c1.5,0.9,3.3,1.6,5.1,1.9c1.8,0.3,3.6,0.3,5.7-0.2
    c1.9-0.6,3.5-1.5,4.8-2.5c1.3-1,2.4-2.1,3.4-3.2c2-2.3,3.6-4.7,4.9-7.2c1.4-2.5,2.5-5,3.6-7.5c1-2.5,1.9-5.1,2.7-7.7
    c0.4-1.3,0.8-2.6,1.1-3.9l0.2-0.6l0,0c0.5,0.1,1,0.3,1.3,0.4c0.7,0.3,1.1,0.6,1.5,1.1c0.4,0.5,0.8,1.3,1.1,2.3
    c0.6,2,0.9,4.3,1.1,6.8c0.2,2.5,0.2,5,0.2,7.7c0.1,2.7,0,5.3,0.5,8.3c0.1,0.8,0.3,1.6,0.6,2.6c0.2,0.5,0.4,1,0.8,1.7
    c0.4,0.6,1.1,1.4,2.2,2c1.1,0.6,2.4,0.6,3.3,0.5c0.9-0.2,1.5-0.4,2.1-0.7c1-0.5,1.6-1.1,2.3-1.6c2.4-2.1,4.1-4.2,5.9-6.4
    c3-3.9,5.4-8.1,7.5-12.5c0.6,2.9,1.4,5.8,2.6,8.7c0.8,1.9,1.8,3.8,3.1,5.6c1.3,1.8,3.1,3.5,5.4,4.6c1.1,0.5,2.4,1,3.9,1
    c1.4,0,2.9-0.3,4.1-1c2.4-1.4,3.8-3.3,4.9-5.2c2.1-3.8,3.2-7.7,4.1-11.6c0.3-1.3-0.5-2.6-1.9-2.9c-1.3-0.3-2.7,0.5-3,1.8l0,0
    c-0.8,3.5-2,7.1-3.7,10c-0.9,1.4-1.9,2.6-2.9,3.1c-1,0.5-1.8,0.4-3.1-0.2c-2.4-1.2-4.3-4.1-5.6-7.4c-1.3-3.3-2.1-6.9-2.7-10.5
    c-0.4-2.5-0.7-5.1-0.9-7.6c0.5-1.9,1-3.9,1.4-5.9c0.5-2.7,0.8-5.4,0.8-8.2c4.5,0,9,0.1,13.4,0.3c6.3,0.3,12.6,0.8,19,1.2l0,0
    c1.2,0.1,2.4-0.7,2.7-1.9C567.2,61,566.4,59.5,565,59z M390.5,79.9c-0.6-0.3-1.1-0.7-1.5-1.2c-0.3-0.5-0.6-1-0.7-1.5
    c0-0.5,0-0.8,0.1-0.9c0.1-0.1,0.2-0.2,0.7-0.4c0.5-0.1,0.8-0.1,1.2,0c0.4,0.1,0.9,0.4,1.4,0.9c0.8,0.8,1.6,2.1,2.1,3.4
    c0,0-0.1,0.1-0.1,0.1C392.5,80.5,391.4,80.3,390.5,79.9z M413.1,40.9c0.3-2.3,0.8-4.7,1.6-6.4c0.4-0.9,0.9-1.6,1.3-1.8
    c0.4-0.2,0.4-0.3,1.3-0.2c0.6,0.1,1,0.3,1.4,0.8c0.4,0.5,0.8,1.3,1,2.2c0.5,1.9,0.6,4.3,0.4,6.6c-0.1,2.3-0.5,4.7-1,7.1
    c-1,4.8-2.4,9.5-4.1,14.1c-0.3,0.9-0.7,1.7-1.1,2.6c-0.1-1-0.2-1.9-0.3-2.9c-0.3-2.5-0.5-5-0.6-7.4c-0.2-2.4-0.2-4.9-0.2-7.3
    C412.7,45.7,412.8,43.3,413.1,40.9z M460.3,76.5c0.6-2,1.2-2.7,2.7-3.7c0.6-0.4,1.1-0.5,1.5-0.5c0.4,0,0.7,0.1,1.2,0.5
    c0.9,0.7,1.7,2.3,1.6,3.6c0,0.2,0,0.3,0,0.5c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.5-0.2,1-0.4,1.5c-0.3,1-0.6,2-1.1,2.9
    c-0.5,0.9-1,1.8-1.6,2.7c-0.6,0.9-1.3,1.7-2,2.5c-0.4,0.4-0.8,0.8-1.2,1.1c-0.1,0.1-0.1,0.1-0.2,0.2c-0.4-1.8-0.7-3.7-0.8-5.6
    C459.6,80.7,459.8,78.4,460.3,76.5z M492.3,67.3C492.3,67.3,492.3,67.3,492.3,67.3L492.3,67.3z M505.9,102.3
    C505.9,102.3,505.9,102.3,505.9,102.3C505.8,102.4,505.8,102.4,505.9,102.3z"
        />
        <path d="M283.1,91.2c-1.3-0.5-2.7,0.2-3.2,1.5l0,0.1c-0.1,0.2-0.3,0.6-0.5,1c-0.2,0.4-0.5,0.8-0.8,1.2c-0.6,0.8-1.2,1.6-1.9,2.4
    c-1.4,1.5-2.9,3-4.6,4.1c-1.6,1.2-3.4,2.1-5.1,2.6c-0.9,0.2-1.7,0.4-2.6,0.4c-0.4,0-0.8,0-1.2-0.1c-0.4-0.1-0.8-0.2-1.3-0.3
    c-3.6-1-6.6-3.3-8.9-6.4c0.9-1,1.8-1.9,2.7-2.9c3.1-3.7,5.9-7.7,7.6-12.7c0.4-1.3,0.8-2.6,0.9-4c0.2-1.3,0.2-2.7,0.1-4.1
    c-0.1-1.4-0.4-3-1.2-4.5c-0.7-1.5-2-3-3.6-3.9c-1.6-1-3.5-1.3-5.1-1.3c-1.7,0.1-3.3,0.5-4.7,1.2c-2.8,1.4-5,3.9-6.1,6.7
    c-1.9,5-2.1,10.1-1.4,15.1c0.4,2.8,1.2,5.6,2.4,8.2c-1.2,1.1-2.5,2.1-3.8,3c-1.6,1.1-3.4,2.2-4.9,2.6c0-0.1,0-0.1-0.1-0.2
    c-0.4-1.4-0.5-3.7-0.6-5.9c-0.1-2.3-0.3-4.6-0.6-7c-0.6-4.8-1.9-9.7-4.1-14.2l-0.4-0.8l-0.2-0.4l-0.1-0.2l-0.2-0.3
    c-0.5-0.9-1.2-1.7-2-2.3c-1.5-1.2-3.1-1.9-4.6-2.3c-1.2-0.3-2.3-0.5-3.3-0.6c0,0,0-0.1,0-0.1c-0.2-0.4-0.5-0.9-1-1.4
    c-0.5-0.5-1.4-1.1-2.4-1.3c-0.2-0.1-0.5-0.1-0.7-0.1c-0.3,0-0.4,0-0.5,0c-0.3,0-0.5,0-0.8,0.1c-0.5,0.1-1.1,0.3-1.6,0.5
    c-1,0.5-1.7,1.3-2.2,2c-0.5,0.8-0.7,1.5-0.9,2.3c-0.1,0.8-0.2,1.7,0.3,3c0.2,0.4,0.3,0.6,0.6,1c0.1,0.2,0.3,0.4,0.4,0.5
    c0.1,0.1,0.6,0.6,1.1,0.8c-0.1,0.4-0.1,0.9-0.2,1.3c-0.7,4.3-1.4,8.7-2.4,13c-0.5,2.1-1,4.2-1.7,6.2c-0.6,2-1.4,3.9-2.3,5.5
    c-0.4,0.8-0.9,1.4-1.4,1.9c-0.4,0.5-0.8,0.6-1,0.7c-0.1,0-0.2,0.1-0.4,0.1c0-0.1-0.1-0.2-0.1-0.3c-0.6-1.3-1.1-3.5-1.5-5.6
    l-1.2-6.6c-0.8-4.5-1.6-9.1-3-13.7c-0.7-2.3-1.5-4.6-2.6-6.9c-0.7-1.5-1.8-3.1-3.5-4.2c-1-0.7-2-1.1-3-1.3
    c-0.1-0.4-0.2-0.8-0.5-1.2c-0.3-0.6-0.9-1.2-1.5-1.6c-1.3-0.9-2.5-0.9-3.2-0.9c-1.5,0-3.1,0.6-4.4,1.8c-0.6,0.6-1.2,1.4-1.5,2.5
    c-0.3,1-0.3,2.2,0.1,3.4c0.4,1.1,1.1,1.9,1.8,2.5c0.1,0.1,0.3,0.2,0.4,0.3c0,0.2-0.1,0.4-0.1,0.6l-0.5,3.3
    c-0.3,2.2-0.7,4.4-1.1,6.5c-0.8,4.3-1.9,8.6-3.3,12.4c-0.7,1.9-1.6,3.7-2.6,5c-1,1.4-2.1,2.2-3.3,2.6l-0.5,0.1
    c-0.2,0-0.4,0.1-0.6,0.1c-0.4,0.1-0.8,0.1-1.2,0.1c-0.8,0-1.5,0-2.2-0.2c-1.4-0.3-2.7-1.1-3.8-2.3c-0.1-0.1-0.2-0.3-0.4-0.4
    c0.7-0.7,1.5-1.5,2.1-2.2c4.1-4.6,7.6-9.9,9.5-16.1c0,0,0,0,0-0.1c0.1-0.3,0.1-0.6,0.1-0.8c0.1-0.6,0.3-1.2,0.4-1.9
    c0.2-1.5,0.1-3.1-0.3-4.7c-0.4-1.6-1.2-3.1-2.3-4.4c-2.2-2.6-5.5-4-8.7-4c-3.2-0.1-6.4,1.2-8.7,3.3c-2.3,2.1-3.7,4.9-4.5,7.6
    c-0.7,2.8-0.8,5.3-0.7,7.8c0.1,2.5,0.4,4.9,0.9,7.4c0.5,2.4,1.3,4.8,2.3,7.2c0.1,0.3,0.2,0.5,0.4,0.8c-0.8,0.7-1.7,1.3-2.6,1.9
    c-1,0.6-2.1,1.2-3.1,1.7c-0.6,0.2-1.1,0.4-1.6,0.6c-0.5,0.1-0.9,0.2-1.5,0.2c-1,0-2-0.2-3-0.6c-2-0.9-3.8-2.6-5.3-4.7
    c-3-4.3-5-9.6-6.4-15l0-0.1c-0.2-0.8-0.8-1.4-1.6-1.8c-1.4-0.6-3,0-3.6,1.4l-10.5,24.2c-0.6,1.3,0,2.8,1.3,3.4
    c1.3,0.6,2.9,0,3.4-1.3l7.8-17.4c1.2,3.4,2.8,6.7,4.9,9.8c1.8,2.6,4.2,5.2,7.5,6.8c1.7,0.8,3.6,1.2,5.4,1.2c0.9,0,2-0.1,2.9-0.3
    c0.9-0.2,1.7-0.5,2.5-0.8c2.5-1,4.7-2.4,6.8-3.9c0.2,0.3,0.4,0.5,0.7,0.8c1.9,2.1,4.6,3.8,7.6,4.6c1.5,0.4,3,0.5,4.5,0.4
    c0.7,0,1.5-0.1,2.2-0.2c0.4-0.1,0.7-0.1,1-0.2c0.4-0.1,0.8-0.2,1.1-0.3c1.5-0.5,3-1.3,4.2-2.2c1.2-1,2.2-2.1,3.1-3.2
    c1.7-2.3,2.8-4.6,3.7-6.9c1.8-4.6,2.9-9.2,3.8-13.7c0.5-2.3,0.9-4.5,1.2-6.8l0.4-2.6c0.8,1.7,1.5,3.7,2,5.6c1.2,4.1,2,8.3,2.7,12.8
    c0.4,2.2,0.7,4.5,1.2,6.8c0.5,2.3,0.9,4.6,2.1,7.5c0.3,0.7,0.7,1.5,1.4,2.3c0.6,0.8,1.6,1.8,3.1,2.3c0.4,0.1,0.7,0.2,1.1,0.3
    l0.3,0.1l0.2,0l0.3,0c0.4,0.1,0.9,0.1,1.3,0.1c0.9,0,1.9-0.2,2.8-0.4c1.9-0.6,3.5-1.7,4.6-2.9c1.1-1.2,1.9-2.4,2.6-3.6
    c1.3-2.4,2.2-4.7,2.9-7c0.7-2.3,1.3-4.6,1.8-6.9c1-4.6,1.8-9.1,2.5-13.7c0.1-0.5,0.1-1.1,0.2-1.6c0.3,0,0.6,0.1,0.8,0.2
    c0.7,0.2,1.2,0.5,1.5,0.6c0.1,0.1,0.2,0.2,0.2,0.3l0,0l0.1,0.2l0.2,0.3l0.4,0.7c1.8,3.6,2.9,7.5,3.4,11.7c0.3,2.1,0.4,4.2,0.6,6.5
    c0.1,1.1,0.1,2.3,0.3,3.5c0.1,1.2,0.3,2.5,0.7,4c0.5,1.5,1,3.4,3.4,5c1.6,1,3.1,1,4,1c1-0.1,1.8-0.3,2.6-0.5c2.9-1,4.9-2.4,6.9-3.8
    c1.2-0.9,2.3-1.8,3.4-2.7c3,3.7,7.1,6.6,11.8,7.8c0.6,0.2,1.3,0.3,2,0.4c0.7,0.1,1.4,0.1,2.1,0.1c1.4,0,2.8-0.3,4.1-0.7
    c2.6-0.8,4.8-2.1,6.8-3.6c2-1.5,3.7-3.2,5.3-5.1c0.8-0.9,1.5-1.9,2.2-3c0.3-0.5,0.7-1.1,1-1.6c0.3-0.6,0.6-1.2,0.9-2.1
    C285,93,284.4,91.6,283.1,91.2z M154.2,77.5c0.4-1.6,1.2-3.1,2.2-4c1-1,2.3-1.5,3.6-1.5c1.3,0,2.6,0.6,3.3,1.4
    c0.8,0.9,1.1,2.1,1,3.6c-0.2,1.4-0.9,3.6-1.7,5.4c-0.8,1.9-1.8,3.7-2.9,5.4c-1.1,1.8-2.4,3.5-3.8,5.1c-0.5-1.2-0.9-2.5-1.2-3.8
    c-0.5-2-0.8-4-0.9-6C153.8,81.2,153.8,79.1,154.2,77.5z M249.9,74.8c0.5-1.4,1.6-2.4,2.8-3.1c1.2-0.6,2.6-0.6,3.2-0.1
    c0.3,0.2,0.6,0.5,0.8,1c0.2,0.5,0.4,1.2,0.5,2c0,0.8,0,1.7-0.1,2.6c-0.1,0.9-0.4,1.7-0.7,2.6c-1.3,3.6-3.7,7.1-6.5,10.2
    c-0.2,0.2-0.3,0.3-0.5,0.5c-0.4-1.4-0.7-2.8-0.9-4.2C248.2,82.4,248.5,78.3,249.9,74.8z"
        />
        <path d="M122.5,73.9c7.7-2.1,15-5.6,21.5-10.4c3.2-2.4,6.2-5.2,8.8-8.5c2.6-3.2,4.8-6.9,6.1-11.2c0.6-2.1,1-4.4,1.1-6.7
    c0.1-2.3-0.3-4.6-1-6.8c-0.7-2.2-1.6-4.3-2.8-6.2c-1.2-1.9-2.6-3.6-4.2-5.1C145.8,13,138,9.6,130.3,7.5c-7.8-2.1-15.7-2.9-23.5-3.1
    c-7.8-0.2-15.6,0.3-23.3,1.2C68.1,7.5,52.8,10.5,38.1,16c-7.3,2.8-14.5,6.3-21.1,11c-3.3,2.3-6.4,5-9.2,8.2
    c-1.4,1.6-2.7,3.3-3.8,5.1C3,42.2,2,44.1,1.3,46.2C0.6,48.3,0.1,50.6,0,53c0,1.2,0,2.4,0.2,3.6c0.2,1.1,0.5,2.3,0.9,3.3
    c0.7,2.2,1.8,4.2,3.2,6c1.3,1.8,2.9,3.3,4.6,4.7c3.4,2.7,7,4.6,10.8,6.1c3.7,1.5,7.5,2.6,11.4,3.5c7.6,1.7,15.3,2.5,23,2.9
    c1.9,0.1,3.8,0.1,5.8,0.2l2.9,0c1,0,2,0,3-0.1c2-0.2,4-0.5,6-1.1c0.5-0.2,1-0.3,1.5-0.6c0.5-0.3,1.1-0.4,1.9-1.3
    c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.1,0.2-0.4,0.2-0.7c0.1-0.2,0-0.6,0-0.9c-0.1-0.3-0.2-0.5-0.3-0.7c-0.5-0.7-0.9-0.9-1.2-1
    c-0.7-0.3-1.2-0.4-1.7-0.6c-0.8-0.2-1.7,0.3-2,1.1c-0.2,0.5,0,1,0.2,1.4c-1.6,0.4-3.4,0.5-5.2,0.5c-0.9,0-1.7,0-2.7-0.1l-2.8-0.2
    c-1.9-0.1-3.7-0.3-5.6-0.5c-7.4-0.8-14.8-2-21.9-3.9c-3.5-1-7-2.1-10.3-3.6c-3.3-1.5-6.3-3.2-8.8-5.4C10.9,63,9,60.4,8.2,57.5
    c-0.2-0.7-0.4-1.5-0.5-2.2c-0.1-0.7-0.1-1.4-0.1-2.1c0.1-1.4,0.4-2.9,1-4.4c0.5-1.5,1.3-2.9,2.2-4.3c0.9-1.4,2-2.7,3.1-3.9
    c2.3-2.5,5-4.8,7.9-6.8c5.8-4,12.5-7.2,19.3-9.7c13.7-5.1,28.5-7.9,43.2-9.7c7.4-0.9,14.8-1.3,22.1-1.2c7.3,0.1,14.6,0.9,21.5,2.6
    c6.9,1.7,13.5,4.6,18.2,9.1c1.2,1.1,2.2,2.3,3.1,3.6c0.9,1.3,1.5,2.7,2,4.1c1,2.8,1,5.9,0.1,8.9c-0.8,3.1-2.4,6-4.5,8.8
    c-2.1,2.7-4.6,5.2-7.3,7.5c-5.5,4.5-12,7.9-18.8,10.1c-3.4,1.1-7,1.9-10.4,2.1c-3.4,0.2-6.6-0.4-9-2.3c-2.4-1.9-4.1-5-4.9-8.3
    c0-0.1-0.1-0.3-0.1-0.4c-0.2-3.4-0.4-6.7-0.8-10.1l0,0c0,0,0,0,0,0c0,0,0,0,0-0.1l0-0.1c0.1-0.9-0.5-1.6-1.4-1.8
    c-0.9-0.1-1.7,0.5-1.9,1.4c0,0.2,0,0.3-0.1,0.5C90.6,55.4,89.7,62,89,68.6c-0.6,6.7-1,13.3-1,20c0,6.7,0.2,13.3,0.7,20
    c0.6,6.6,1.4,13.3,2.8,19.9c0.1,0.7,0.7,1.2,1.4,1.3c0.9,0.1,1.8-0.5,1.9-1.4l0,0c1-6.7,1.4-13.3,1.7-19.9
    c0.3-6.6,0.5-13.2,0.5-19.8c0.1-6.1,0-12.3-0.2-18.4c0.4,0.5,0.9,1,1.4,1.5c1.7,1.5,3.8,2.6,5.9,3.2c2.1,0.6,4.3,0.8,6.4,0.8
    C114.7,75.6,118.6,74.9,122.5,73.9z M72.4,79.5C72.4,79.5,72.4,79.5,72.4,79.5C72.5,79.5,72.5,79.5,72.4,79.5z"
        />
        <path d="M383.7,136.2c-0.5-0.6-1.4-0.8-2-0.3c-0.4,0.3-0.6,0.7-0.5,1.2c-0.3,0-0.8-0.1-1.2-0.2c-1.1-0.3-2.3-1-3.3-1.7
    c-0.5-0.4-1.1-0.8-1.6-1.2l-1.7-1.4l-3.4-2.8l-13.5-11.3c-4.4-3.8-8.9-7.6-13.3-11.5c-4.4-3.8-8.8-7.7-13.1-11.7
    c-2.6-2.3-5.2-4.7-7.8-7.1c5.6,0,11.3-0.6,16.8-2.1c4.4-1.1,8.7-2.7,12.8-4.9c4-2.2,7.8-5,11.1-8.3c1.6-1.6,3.2-3.4,4.6-5.3
    c1.4-1.9,2.6-3.8,3.7-5.9c2.2-4.1,3.8-8.5,4.6-13.4c0.2-1.2,0.3-2.5,0.3-3.8c0-1.3-0.1-2.6-0.3-3.9c-0.4-2.5-1.1-5-2.1-7.2
    c-2-4.5-4.9-8.4-8.2-11.7c-6.6-6.6-14.5-11.1-22.7-14.3c-8.2-3.3-16.8-5.3-25.4-6.4C309,0,300.2-0.2,291.7,0.2
    c-4.3,0.2-8.5,0.4-12.8,0.9c-4.3,0.4-8.5,1-12.7,1.8c-8.5,1.5-16.9,3.9-24.9,7.5c-4,1.9-7.9,4.1-11.5,6.9c-3.6,2.9-7,6.3-9,11.3
    c-0.5,1.3-0.8,2.7-0.9,4.3c0,0.9,0,1.5,0.1,2.2c0.1,0.7,0.2,1.3,0.4,2c0.7,2.6,2,4.9,3.5,6.9c3.2,3.9,7.4,6.4,11.5,8.2
    c4.2,1.8,8.5,2.8,12.8,3.6c4.3,0.7,8.6,1.1,12.9,1.3c6.2,0.2,12.5,0.1,18.7-0.6c0.7,1.6,1.6,3.2,2.4,4.7c2.2,3.9,4.7,7.5,7.5,10.9
    c2.8,3.4,5.8,6.6,9.2,9.6c0.7,0.6,1.5,1.2,2.3,1.8c0.1,2.6,0.3,5.2,0.5,7.8c0.3,2.9,0.7,5.9,1.4,8.9c0.3,1.5,0.7,3,1.3,4.5
    c0.6,1.5,1,3,2.4,4.8c0.3,0.4,0.9,0.7,1.5,0.7c1.1,0,1.9-0.8,1.9-1.9l0-1c0-0.8,0-2.2,0.1-3.5c0.1-1.3,0.2-2.7,0.3-4.1l0.7-8.4
    c0.1-0.9,0.1-1.7,0.2-2.6c0.2,0.2,0.3,0.3,0.5,0.5c4.2,4.2,8.6,8.2,12.9,12.2c4.4,3.9,8.9,7.8,13.5,11.6c4.6,3.7,9.2,7.5,14,11
    c4.7,3.6,9.6,7,14.5,10.3l3.7,2.4l1.9,1.2c0.7,0.4,1.4,0.9,2.1,1.2c1.5,0.7,3,1.4,4.8,1.6c0.9,0.1,1.8,0.1,3-0.2
    c0.5-0.2,1.3-0.5,1.8-1.4c0.3-0.4,0.3-1.1,0.2-1.5C384.2,136.8,383.9,136.6,383.7,136.2z M302.8,56.1c-0.5,3.4-0.8,6.8-1.1,10.3
    c-0.4-0.4-0.7-0.8-1.1-1.2c-2.9-3.3-5.7-6.6-8.6-10c-0.3-0.3-0.5-0.6-0.8-0.9c2.7-0.7,5.3-1.5,7.9-2.6c1.6-0.7,3.2-1.5,4.8-2.4
    C303.5,51.6,303.1,53.8,302.8,56.1z M261.5,51.1c-8.1-0.7-16.2-2.1-23.3-5.2c-3.5-1.6-6.6-3.7-8.6-6.4c-1-1.3-1.7-2.8-2-4.3
    c-0.1-0.4-0.1-0.7-0.2-1.1c0-0.4-0.1-0.8,0-1c0-0.5,0.2-1.2,0.5-1.8c1.2-2.8,3.8-5.6,6.8-7.8c3-2.3,6.4-4.1,10-5.7
    c7.2-3.1,15.1-5.1,23.1-6.4c8-1.3,16.2-2,24.4-2.2c8.2-0.3,16.3,0,24.2,1.2c8,1.1,15.8,3,23.1,6c7.3,2.9,14.1,7,19.4,12.3
    c5.2,5.3,8.6,12,7.5,18.4c-0.5,3.4-1.8,7-3.5,10.3c-0.9,1.6-1.9,3.2-3,4.7c-1.1,1.5-2.3,2.9-3.6,4.2c-2.6,2.6-5.6,4.9-8.9,6.7
    c-3.3,1.8-6.9,3.1-10.6,4.1c-7.4,1.9-15.4,2.3-23.3,1.6l-0.8-0.1l-0.6-0.6c0.1-1.3,0.1-2.7,0.2-4c0.2-5.7,0.2-11.5,0-17.2
    c-0.2-5.8-0.6-11.6-1.6-17.4l0,0c-0.1-0.7-0.6-1.4-1.4-1.6c-1.1-0.4-2.3,0.2-2.6,1.3c-0.6,1.7-1,3.5-1.5,5.2
    c-0.7,0.4-1.3,0.8-2,1.2c-1.7,0.9-3.6,1.7-5.5,2.4c-3.2,1.1-6.4,1.9-9.8,2.5c-1.4-1.7-2.7-3.4-4-5.1c-0.9-1.1-2.3-1.7-3.8-1.4
    c-1.6,0.3-2.7,1.6-2.9,3.1c-0.3,0.5-0.3,1,0.1,1.5c0,0,0,0,0,0c0.2,1,0.5,2,0.8,2.9C272.5,51.6,266.9,51.5,261.5,51.1z
     M381.3,137.8C381.3,137.8,381.3,137.8,381.3,137.8c0,0,0,0.1,0.1,0.1C381.4,137.9,381.4,138,381.3,137.8z"
        />
      </g>
    </svg>
  ),
}
