/* eslint-disable max-len */
const programName = 'Yankee Candle'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 316.94 35.07">
      <g
        id="Text_Elements"
        data-name="Text &amp; Elements"
        transform="translate(-21.53,-54.45)"
      >
        <path
          className="cls-1"
          d="m 336.86,56.67 c 0,-0.63 -0.38,-0.89 -1.14,-0.89 h -1.17 v 3.11 h 0.51 v -1.34 h 0.48 l 0.8,1.34 h 0.58 l -0.87,-1.37 a 0.79,0.79 0 0 0 0.81,-0.85 z m -1.8,0.45 v -0.91 h 0.61 c 0.31,0 0.68,0 0.68,0.42 0,0.42 -0.34,0.49 -0.73,0.49 z"
          id="path912"
        />
        <path
          className="cls-1"
          d="m 335.62,54.45 a 2.85,2.85 0 1 0 2.85,2.85 2.86,2.86 0 0 0 -2.85,-2.85 z M 338,57.3 a 2.37,2.37 0 1 1 -2.37,-2.37 2.37,2.37 0 0 1 2.37,2.37 z"
          id="path914"
        />
        <path
          className="cls-1"
          d="m 171.18,67.92 c -0.8,-6.82 -5.05,-10.58 -12,-10.58 -8.21,0 -12.51,6.36 -12.51,12.64 A 13,13 0 0 0 150,79 a 12.59,12.59 0 0 0 9.48,3.7 13,13 0 0 0 10.43,-4.7 c 0.47,-0.74 0.37,-1.2 0.19,-1.45 a 0.67,0.67 0 0 0 -0.47,-0.28 1.12,1.12 0 0 0 -0.83,0.26 l -0.15,0.13 a 13,13 0 0 1 -8.64,3 c -5.43,0 -9.36,-3.48 -9.81,-8.68 h 20.11 a 1,1 0 0 0 0.74,-0.32 1,1 0 0 0 0.29,-0.74 c -0.02,-0.43 -0.05,-1.11 -0.16,-2 z m -3.25,0.06 h -17.62 a 8.64,8.64 0 0 1 8.89,-7.58 c 7,0 8.44,5 8.74,7.18 C 168,67.9 167.93,68 167.93,68 Z"
          id="path916"
        />
        <path
          className="cls-1"
          d="m 122.34,79 a 12.63,12.63 0 0 0 9.49,3.7 c 7.17,0 10.43,-4.66 10.46,-4.7 0.48,-0.74 0.37,-1.2 0.2,-1.45 a 0.72,0.72 0 0 0 -0.48,-0.28 1.17,1.17 0 0 0 -0.83,0.26 l -0.14,0.13 a 13,13 0 0 1 -8.64,3 c -5.44,0 -9.36,-3.48 -9.82,-8.68 h 20.11 a 1,1 0 0 0 0.74,-0.32 1,1 0 0 0 0.29,-0.74 c 0,-0.42 -0.05,-1.1 -0.15,-2 -0.8,-6.82 -5.06,-10.58 -12,-10.58 -8.21,0 -12.51,6.36 -12.51,12.64 a 13,13 0 0 0 3.28,9.02 z m 0.35,-11 a 8.65,8.65 0 0 1 8.89,-7.58 c 7,0 8.44,5 8.75,7.18 a 0.88,0.88 0 0 1 0,0.39 h -17.64 z"
          id="path918"
        />
        <path
          className="cls-1"
          d="m 53.2,54.57 h -2.94 a 1.68,1.68 0 0 0 -1.28,0.52 L 37.67,67.93 26.35,55.2 a 2.15,2.15 0 0 0 -1.71,-0.62 h -3.11 l 0.22,0.25 13.77,15.67 v 11.8 h 3.63 V 70.48 L 53,54.82 Z"
          id="path920"
        />
        <path
          className="cls-1"
          d="m 58.4,58.36 a 1.56,1.56 0 0 0 -1.48,-1 1.64,1.64 0 0 0 -1.41,1 L 43.42,81.2 42.84,82.3 H 45 a 1.59,1.59 0 0 0 1.54,-0.8 C 46.83,81 49,76.89 49.93,75 h 13.28 c 0.95,1.84 3.13,6 3.31,6.38 a 1.65,1.65 0 0 0 1.7,0.89 H 71 l -0.29,-0.52 z m 3.42,14 h -10.5 l 5.25,-10.09 z"
          id="path922"
        />
        <path
          className="cls-1"
          d="M 91.79,59.14 V 75.47 L 73.36,57.65 73.05,57.34 h -0.8 v 25 h 3.1 v -0.22 0 -17.64 l 18.74,18.09 0.11,0.11 h 0.69 v -25 h -1.66 a 1.38,1.38 0 0 0 -1.44,1.46 z"
          id="path924"
        />
        <path
          className="cls-1"
          d="m 146.5,81.45 c -0.22,-0.21 -0.64,-0.28 -1.15,0.14 l -0.27,0.23 c -3.57,3.22 -8.26,4.84 -13.94,4.84 h -0.22 C 121,86.59 113,80.54 107.76,69.18 l 13.31,-10.67 1.08,-0.86 h -3.09 a 3.08,3.08 0 0 0 -2.18,0.75 l -3,2.42 -0.93,0.73 c -3.32,2.62 -8.29,6.55 -11.32,9 V 59.09 a 1.33,1.33 0 0 0 -1.51,-1.43 h -1.6 V 82.3 h 3.1 V 74 l 3.61,-2.77 a 3.91,3.91 0 0 0 0.18,0.35 v 0.1 c 2.2,4.41 8.9,17.84 25.75,17.84 10.39,0 14.75,-6 15.22,-6.69 0.47,-0.69 0.37,-1.18 0.12,-1.41 z"
          id="path926"
        />
        <path
          className="cls-1"
          d="m 333.11,67.92 c -0.81,-6.82 -5.06,-10.58 -12,-10.58 -8.21,0 -12.51,6.36 -12.51,12.64 a 13,13 0 0 0 3.27,9 12.6,12.6 0 0 0 9.49,3.7 c 7.13,0 10.4,-4.61 10.46,-4.7 0.48,-0.74 0.37,-1.2 0.2,-1.45 a 0.72,0.72 0 0 0 -0.48,-0.28 1.1,1.1 0 0 0 -0.83,0.27 l -0.14,0.12 a 13,13 0 0 1 -8.64,3 c -5.44,0 -9.37,-3.48 -9.82,-8.68 h 20.11 a 1,1 0 0 0 0.74,-0.32 1,1 0 0 0 0.29,-0.74 c 0,-0.41 -0.04,-1.09 -0.14,-1.98 z m -3.26,0.06 h -17.62 a 8.65,8.65 0 0 1 8.89,-7.58 c 7,0 8.44,5 8.75,7.18 0.04,0.32 -0.02,0.42 -0.02,0.42 z"
          id="path928"
        />
        <path
          className="cls-1"
          d="m 209.05,76 a 0.59,0.59 0 0 0 -0.41,-0.25 1.09,1.09 0 0 0 -0.8,0.26 l -0.16,0.13 a 14.77,14.77 0 0 1 -9.79,3.44 c -6.23,0 -10.72,-4 -11.17,-10 v 0 a 11.47,11.47 0 0 1 0.2,-3.18 10.93,10.93 0 0 1 7.19,-7.94 12.45,12.45 0 0 1 13.12,3.26 l 0.2,0.24 0.43,-0.35 a 3.33,3.33 0 0 0 1.26,-1.5 c 0.19,-0.73 -0.4,-1.28 -0.79,-1.65 l -0.15,-0.14 A 9.83,9.83 0 0 0 206.5,57 15.43,15.43 0 0 0 185,61.84 a 14,14 0 0 0 -1.86,6.79 v 0 0.2 c 0,0.42 0,1 0,1.29 v 0 a 13.84,13.84 0 0 0 3.54,8.39 c 2.51,2.68 6.14,4.09 10.5,4.09 8,0 11.53,-5.13 11.57,-5.19 0.56,-0.69 0.47,-1.13 0.3,-1.41 z"
          id="path930"
        />
        <path
          className="cls-1"
          d="m 224,58.36 a 1.56,1.56 0 0 0 -1.48,-1 1.64,1.64 0 0 0 -1.41,1 l -12.28,23.15 -0.41,0.79 h 2.18 a 1.59,1.59 0 0 0 1.54,-0.8 c 0.26,-0.44 2.2,-4.2 3.37,-6.47 h 13.28 c 0.94,1.82 3.13,6.06 3.31,6.38 a 1.65,1.65 0 0 0 1.7,0.89 h 2.75 l -0.36,-0.66 z m 3.42,14 H 216.9 l 5.25,-10.09 z"
          id="path932"
        />
        <path
          className="cls-1"
          d="m 257.31,59.14 v 16.33 l -18.58,-18 -0.1,-0.09 a 0.23,0.23 0 0 0 -0.17,-0.07 H 238 a 0.25,0.25 0 0 0 -0.25,0.25 V 82.3 h 3.1 V 64.48 l 18.75,18.09 0.11,0.11 h 0.7 v -25 h -1.67 a 1.37,1.37 0 0 0 -1.43,1.46 z"
          id="path934"
        />
        <path
          className="cls-1"
          d="m 271.83,57.65 h -7.78 v 24.6 h 7.78 c 10.45,0 15.58,-4 15.69,-12.31 -0.11,-8.26 -5.24,-12.29 -15.69,-12.29 z M 284.29,70 v 0 c 0,3.76 -1.41,6.26 -4.32,7.66 -2.91,1.4 -6.68,1.52 -10.12,1.52 h -2.93 V 60.73 h 2.93 c 6.99,0 14.44,0.71 14.44,9.27 z"
          id="path936"
        />
        <path
          className="cls-1"
          d="m 307.84,79.55 h -14.2 V 59.3 c 0,-1.13 -0.49,-1.64 -1.59,-1.64 h -1.77 v 24.6 h 19.09 v -1.44 c 0,-0.82 -0.49,-1.27 -1.53,-1.27 z"
          id="path938"
        />
      </g>
    </svg>

  ),
}
