/* eslint-disable max-len */
const programName = 'Iphonebutiken'
export default {
  programName,
  logo: (
    <svg className="iphonebutiken" viewBox="0 0 269.23 50">
      <title>{programName}</title>
      <g id="Lager_2" data-name="Lager 2">
        <g id="Lager_1-2" data-name="Lager 1">
          <path className="cls-1" d="M16.56 32a.87.87 0 0 1-.76-.44c-3.84-6.93-5.18-16.71 1-21.8 2.07-1.76 4.04-2.5 5.82-2.19 2.42.43 3.7 2.62 4.32 3.67l.08.13a7.89 7.89 0 0 1 1.27 5.21c-.49 3.28-2.43 4.39-5.18 5.06a1 1 0 0 0-.86 1.28 9.44 9.44 0 0 0 1.58 4l-1.48.88a11 11 0 0 1-1.81-4.71A2.75 2.75 0 0 1 22.71 20c2.3-.56 3.53-1.27 3.89-3.65a6.21 6.21 0 0 0-1.06-4.07l-.09-.15c-.52-.89-1.49-2.55-3.13-2.84-1.26-.22-2.76.39-4.47 1.8-3.77 3.14-5.55 10.62-.54 19.65a.85.85 0 0 1-.31 1.13.77.77 0 0 1-.44.13z" />
          <path className="cls-1" d="M39.24 37.56h-.07a.84.84 0 0 1-.79-.91 7.66 7.66 0 0 0-1.08-4 6 6 0 0 0-3.3-2.93c-1.75-.54-3.07-.07-4.73 1.67a2.75 2.75 0 0 1-3.76.35 11 11 0 0 1-3.18-3.93.87.87 0 0 1 .31-1.18.85.85 0 0 1 1.17.3 9.44 9.44 0 0 0 2.67 3.39 1 1 0 0 0 1.54-.11c1.42-1.49 3.44-3.07 6.49-2.13a7.72 7.72 0 0 1 4.25 3.73 9.31 9.31 0 0 1 1.29 4.95.86.86 0 0 1-.81.8z" />
          <path className="cls-1" d="M25.44 50a25.5 25.5 0 0 1-5.37-.58 25.05 25.05 0 0 1-16-11A24.8 24.8 0 0 1 .7 19 25.13 25.13 0 0 1 12.61 3.28a25.41 25.41 0 0 1 19.84-2.2 24.32 24.32 0 0 1 14.87 12.39c5.85 11.63 1.12 21.09-2.61 24.85a14.65 14.65 0 0 1-12.59 4.59C25.2 42 18.69 36.53 15.83 31.58a.86.86 0 1 1 1.48-.86c2.59 4.47 8.73 9.67 15 10.48a12.77 12.77 0 0 0 11.15-4.09c3.4-3.43 7.71-12.11 2.29-22.87A22.56 22.56 0 0 0 32 2.73a23.66 23.66 0 0 0-18.5 2A23.41 23.41 0 0 0 2.37 19.41a23 23 0 0 0 3.16 18.08 23.3 23.3 0 0 0 14.9 10.25c5.56 1.2 11.05.39 15-2.21a.86.86 0 0 1 1.19.25.87.87 0 0 1-.2 1.22 20.17 20.17 0 0 1-10.98 3z" />
          <path className="cls-2" d="M243.37 34.79a9.05 9.05 0 0 1-8.83-9.23c0-5.26 3.72-9.22 8.66-9.22 5.2 0 8.43 3.37 8.43 8.8a.86.86 0 0 1-.86.86h-14.5a7.4 7.4 0 0 0 7.1 7.07c2.81 0 4.86-1.07 5.92-3.09a.86.86 0 1 1 1.52.8c-1.36 2.58-4 4.01-7.44 4.01zm-7-10.51h13.53c-.31-3.92-2.76-6.22-6.68-6.22s-6.37 3.01-6.87 6.22zm-82.32 10.51a9.05 9.05 0 0 1-8.83-9.23c0-5.26 3.72-9.22 8.66-9.22 5.2 0 8.43 3.37 8.43 8.8a.86.86 0 0 1-.86.86H147a7.4 7.4 0 0 0 7.1 7.07c2.81 0 4.86-1.07 5.93-3.09a.85.85 0 0 1 1.15-.36.86.86 0 0 1 .36 1.16c-1.41 2.58-4.05 4.01-7.49 4.01zm-7-10.51h13.53c-.31-3.92-2.75-6.22-6.68-6.22s-6.37 3.01-6.9 6.22zM65.74 34.31a.86.86 0 0 1-.86-.86V17.67a.86.86 0 1 1 1.72 0v15.78a.86.86 0 0 1-.86.86zm62.81 0a.86.86 0 0 1-.86-.86V17.67a.86.86 0 0 1 1.72 0v15.78a.85.85 0 0 1-.86.86zm-57.27 7.8a.86.86 0 0 1-.86-.85V17.67a.86.86 0 1 1 1.72 0v23.59a.85.85 0 0 1-.86.85zm19.64-7.8a.85.85 0 0 1-.85-.86V8.73a.86.86 0 0 1 1.71 0v24.72a.85.85 0 0 1-.86.86zm74.69 0a.85.85 0 0 1-.86-.86V8.73a.86.86 0 0 1 1.72 0v24.72a.86.86 0 0 1-.86.86zm54.49 0a.85.85 0 0 1-.86-.86V8.73a.86.86 0 0 1 1.72 0v24.72a.86.86 0 0 1-.86.86zm-9.85.01h-1.77a3.66 3.66 0 0 1-3.65-3.66V11.8a.86.86 0 1 1 1.71 0v18.86a1.94 1.94 0 0 0 1.94 1.94h1.77a.86.86 0 0 1 0 1.72zm-93.82.42a9 9 0 0 1-8.77-9.19 8.78 8.78 0 1 1 17.55 0 9 9 0 0 1-8.78 9.19zm0-16.67a7.29 7.29 0 0 0-7.06 7.48 7.07 7.07 0 1 0 14.12 0 7.29 7.29 0 0 0-7.06-7.48z" />
          <path className="cls-2" d="M78.93 34.74c-4.69 0-8.51-4.12-8.51-9.19s3.82-9.19 8.51-9.19 8.51 4.12 8.51 9.19-3.82 9.19-8.51 9.19zm0-16.67c-3.74 0-6.79 3.36-6.79 7.48s3 7.48 6.79 7.48 6.79-3.36 6.79-7.48-3.05-7.48-6.79-7.48zm94.33 16.67c-4.69 0-8.51-4.12-8.51-9.19s3.82-9.19 8.51-9.19 8.5 4.12 8.5 9.19-3.76 9.19-8.5 9.19zm0-16.67c-3.75 0-6.79 3.36-6.79 7.48s3 7.48 6.79 7.48 6.79-3.36 6.79-7.48-3.05-7.48-6.79-7.48zm-68.97 16.24a.86.86 0 0 1-.86-.86v-9c0-4.27-1.66-6.35-5.09-6.35a6.94 6.94 0 0 0-6.56 7.1.86.86 0 1 1-1.71 0 8.56 8.56 0 0 1 8.27-8.81c4.39 0 6.81 2.86 6.81 8.06v9a.85.85 0 0 1-.86.86zm37.63 0a.86.86 0 0 1-.86-.86v-9c0-4.27-1.66-6.35-5.09-6.35a6.94 6.94 0 0 0-6.56 7.1.86.86 0 0 1-1.72 0 8.57 8.57 0 0 1 8.31-8.84c4.39 0 6.81 2.86 6.81 8.06v9a.85.85 0 0 1-.89.89zm113.08 0a.85.85 0 0 1-.86-.86V17.67a.86.86 0 1 1 1.71 0v15.78a.85.85 0 0 1-.85.86z" />
          <path className="cls-2" d="M268.38 34.31a.85.85 0 0 1-.86-.86v-9c0-4.27-1.67-6.35-5.1-6.35a6.94 6.94 0 0 0-6.56 7.1.86.86 0 1 1-1.71 0 8.56 8.56 0 0 1 8.27-8.81c4.4 0 6.81 2.86 6.81 8.06v9a.85.85 0 0 1-.85.86zm-70 0a.85.85 0 0 1-.85-.85V17.67a.86.86 0 0 1 1.71 0v15.79a.85.85 0 0 1-.86.85z" />
          <path className="cls-2" d="M191 34.77c-4.39 0-6.81-2.86-6.81-8.06v-9a.86.86 0 0 1 1.71 0v9c0 4.27 1.67 6.35 5.1 6.35a6.94 6.94 0 0 0 6.56-7.1.86.86 0 1 1 1.71 0 8.56 8.56 0 0 1-8.27 8.81zm19.55-16.29H202a.81.81 0 0 1 0-1.61h8.58a.81.81 0 1 1 0 1.61zm9.55 9.76a.86.86 0 0 1-.54-1.53l12.84-10.19a.86.86 0 0 1 1.2.14.85.85 0 0 1-.14 1.2l-12.83 10.19a.82.82 0 0 1-.53.19z" />
          <path className="cls-2" d="M233.57 34.31a.86.86 0 0 1-.64-.28l-8.73-9.69a.86.86 0 1 1 1.28-1.15l8.73 9.69a.85.85 0 0 1-.64 1.43z" />
          <circle className="cls-2" cx="65.7" cy="10.64" r="1.07" />
          <path className="cls-2" d="M214.61 34.31a.86.86 0 0 1-.86-.86V17.67a.86.86 0 1 1 1.72 0v15.78a.86.86 0 0 1-.86.86z" />
          <circle className="cls-2" cx="214.57" cy="10.64" r="1.07" />
        </g>
      </g>
    </svg>
  ),
}
