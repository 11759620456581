/* eslint-disable max-len */
const programName = 'Kockums Jernverk'
export default {
  programName,
  logo: (
    <svg className="kockumsjernverk" viewBox="0 0 372.74 89.28">
      <title>{programName}</title>
      <defs>
        <clipPath id="clipPath" transform="translate(-38.18 -35.34)">
          <rect className="cls-1" width="439.38" height="145.73" />
        </clipPath>
      </defs>
      <title>Kockums_logo_enkel_ORG</title>
      <g className="cls-2">
        <path className="cls-3" d="M357.59,76.79c4.45,4.45,11,7.49,20,7.49,12.72,0,18.86-6.5,18.86-15.12,0-10.46-9.89-12.79-17.66-14.55-5.44-1.27-9.4-2.26-9.4-5.44,0-2.83,2.4-4.8,6.92-4.8a20.1,20.1,0,0,1,13.49,5.16l5.58-7.35C390.72,37.86,384.5,35.6,377,35.6c-11.09,0-17.87,6.36-17.87,14.2,0,10.53,9.89,12.65,17.59,14.41,5.44,1.27,9.54,2.47,9.54,6,0,2.68-2.69,5.3-8.2,5.3a20.66,20.66,0,0,1-15-6.36Zm-19.71,6.64h6.38s3.73,0,3.73-3.73v-41a2.36,2.36,0,0,0-2.41-2.41h-8a5.67,5.67,0,0,0-5.08,3.47l-8.74,22.45s-1.35,3.47-2.71,0l-9.22-23.67a3.67,3.67,0,0,0-3.29-2.25h-7.92s-3.73,0-3.73,3.73v43.4h6.31s3.73,0,3.73-3.73V53.24s0-3.73,1.36-.26l11.92,30.44h2.19s2.19,0,3.55-3.47l10.56-27s1.36-3.47,1.36.26ZM241.93,64.64c0,11.59,6.71,19.64,21.12,19.64s21-8,21-19.71V36.3h-6.45s-3.73,0-3.73,3.73V64.28c0,6.57-3.6,11.09-10.81,11.09s-11-4.52-11-11.09v-28h-6.45s-3.73,0-3.73,3.73ZM163.86,84.28c10.74,0,16.67-5.72,20.07-11.59l-5.26-2.54s-3.35-1.62-4.34.32-5.23,4.91-10.46,4.91c-8.2,0-14.41-6.57-14.41-15.47s6.22-15.47,14.41-15.47a12.9,12.9,0,0,1,11.45,6.85l5.27-2.6s3.35-1.64,1.68-4.58-7.66-8.58-18.4-8.58c-13.78,0-24.73,9.89-24.73,24.38s10.95,24.38,24.73,24.38M120.49,59.9c0,8.76-5.65,15.47-14.34,15.47S91.87,68.66,91.87,59.9s5.58-15.47,14.27-15.47,14.34,6.64,14.34,15.47m-38.93,0c0,14.2,10.38,24.38,24.59,24.38S130.8,74.1,130.8,59.9s-10.39-24.37-24.66-24.37S81.56,45.7,81.56,59.9" transform="translate(-38.18 -35.34)" />
        <path className="cls-3" d="M203.5,57.36V36.3h-6.31s-3.73,0-3.73,3.73v43.4h6.31s3.73,0,3.73-3.73V68.94" transform="translate(-38.18 -35.34)" />
        <path className="cls-3" d="M206.29,59.94a12.22,12.22,0,0,0,2.27,6.68l12.88,16.8h8.64s3.73,0,1.4-2.91L216.14,61.33a4.58,4.58,0,0,1,.08-5.75l16.32-19.27H223.9a8.35,8.35,0,0,0-6,2.92L207.79,51.94a7.69,7.69,0,0,0-1.5,4.3Z" transform="translate(-38.18 -35.34)" />
        <path className="cls-3" d="M48.21,57.39V36.34H41.9s-3.73,0-3.73,3.73v43.4h6.31s3.73,0,3.73-3.73V69" transform="translate(-38.18 -35.34)" />
        <path className="cls-3" d="M51,60a12.22,12.22,0,0,0,2.27,6.68l12.88,16.8h8.64s3.73,0,1.4-2.91L60.85,61.36a4.58,4.58,0,0,1,.08-5.75L77.25,36.34H68.61a8.35,8.35,0,0,0-6,2.92L52.5,52a7.69,7.69,0,0,0-1.5,4.3Z" transform="translate(-38.18 -35.34)" />
        <path className="cls-3" d="M378.58,124.31h3.57V106.9h-3.7v11.27l-8.07-11.27h-3.81v17.4h3.7V112.62Zm-32.18,0h12.31V121.1h-8.61v-4h8.43v-3.18h-8.43v-3.79h8.61V106.9H346.4Zm-20-3.26V110.16h3.16a5.09,5.09,0,0,1,5.45,5.45,5.21,5.21,0,0,1-5.45,5.43Zm-3.7,3.26h6.86c5.45,0,9.24-3.44,9.24-8.69s-3.78-8.71-9.24-8.71h-6.86Zm-20.17,0h12.31V121.1h-8.61v-4h8.43v-3.18h-8.43v-3.79h8.61V106.9H302.54Zm-15.61,0h3.94l5-17.4h-4.17l-3,12.58-3.29-12.58h-2.9l-3.31,12.58-3.05-12.58h-4.15l5,17.4h3.94l3-11.95Zm-35.12-2.45a10,10,0,0,0,7.38,2.76c4.7,0,7-2.4,7-5.58,0-3.86-3.65-4.72-6.52-5.38-2-.47-3.47-.83-3.47-2s.89-1.78,2.56-1.78a7.43,7.43,0,0,1,5,1.9l2.06-2.71a9.67,9.67,0,0,0-6.78-2.43c-4.1,0-6.6,2.35-6.6,5.24,0,3.89,3.65,4.67,6.5,5.32,2,.47,3.53.91,3.53,2.22,0,1-1,2-3,2a7.63,7.63,0,0,1-5.53-2.35Zm-46,2.45h4.57l-7.39-9.24,6.92-8.17H205.3l-6.16,7.78V106.9h-3.7v17.4h3.7V119l1.36-1.62ZM183.9,112.49a2.4,2.4,0,0,1-2.61,2.4H177.4v-4.81h3.89a2.4,2.4,0,0,1,2.61,2.4m-.36,11.82h4.25l-3.91-6.63a5,5,0,0,0,3.81-5.17c0-3.24-2.22-5.61-5.84-5.61H173.7v17.4h3.7v-6.24h2.72Zm-30,0h12.31V121.1h-8.61v-4h8.43v-3.18h-8.43v-3.79h8.61V106.9H153.53Zm-17.93,0h4.64l6.71-17.4h-4.23l-4.8,13.39-4.83-13.39h-4.23Zm-16.86,0h3.57V106.9h-3.7v11.27l-8.07-11.27h-3.81v17.4h3.7V112.62ZM95.2,112.49a2.41,2.41,0,0,1-2.61,2.4H88.7v-4.81h3.89a2.41,2.41,0,0,1,2.61,2.4m-.37,11.82h4.25l-3.91-6.63A5,5,0,0,0,99,112.51c0-3.24-2.22-5.61-5.84-5.61H85v17.4h3.7v-6.24h2.72Zm-30,0H77.14V121.1H68.53v-4H77v-3.18H68.53v-3.79h8.61V106.9H64.82Zm-19.21-1.38a6.43,6.43,0,0,0,4.67,1.7c3.58,0,6.13-1.9,6.13-6V106.9h-3.7v11.64A2.58,2.58,0,0,1,50,121.34a3.93,3.93,0,0,1-2.77-1.25Z" transform="translate(-38.18 -35.34)" />
        <path className="cls-3" d="M231.06,119.21a2.4,2.4,0,1,0-2.4-2.4,2.4,2.4,0,0,0,2.4,2.4" transform="translate(-38.18 -35.34)" />
        <path d="M400.1,40.74a5.41,5.41,0,1,1,5.41,5.41,5.39,5.39,0,0,1-5.41-5.41m.82,0a4.59,4.59,0,1,0,4.59-4.59,4.59,4.59,0,0,0-4.59,4.59m4.46.66h-1.07v2.45h-.87v-6.2H406a1.9,1.9,0,0,1,2,1.89,1.81,1.81,0,0,1-1.58,1.84l1.66,2.48H407Zm-1.07-3v2.19H406a1.09,1.09,0,0,0,1.12-1.07A1.1,1.1,0,0,0,406,38.42Z" transform="translate(-38.18 -35.34)" />
      </g>
    </svg>
  ),
}
