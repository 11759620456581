/* eslint-disable max-len */
const programName = 'My Protein'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 759 71">
      <title>{programName}</title>
      <g stroke="none" strokeWidth="1" fill="black" fillRule="evenodd">
        <path d="M247.0348,23.7738 C247.0348,21.3168 246.2058,19.1498 244.5448,17.2748 C242.8858,15.3998 240.2718,14.4618 236.7078,14.4618 L227.6388,14.4618 C227.1998,14.4618 226.8428,14.8178 226.8428,15.2578 L226.8428,33.2138 C226.8428,33.6528 227.1998,34.0098 227.6388,34.0098 L236.7078,34.0098 C240.2718,34.0098 242.8858,33.0418 244.5448,31.1048 C246.2058,29.1688 247.0348,26.7258 247.0348,23.7738 M262.8018,24.0508 C262.8018,27.4318 262.2788,30.5368 261.2348,33.3638 C260.1878,36.1908 258.6658,38.6048 256.6698,40.6008 C254.6718,42.5998 252.2288,44.1518 249.3398,45.2568 C246.4498,46.3638 243.1628,46.9178 239.4748,46.9178 L227.6388,46.9178 C227.1998,46.9178 226.8428,47.2728 226.8428,47.7118 L226.8428,68.7118 C226.8428,69.1508 226.4868,69.5068 226.0478,69.5068 L212.7018,69.5068 C212.2618,69.5068 211.9058,69.1508 211.9058,68.7118 L211.9058,2.0718 C211.9058,1.6328 212.2618,1.2758 212.7018,1.2758 L226.8428,1.2758 L239.6588,1.2758 C243.2848,1.2758 246.5288,1.7998 249.3868,2.8448 C252.2438,3.8898 254.6568,5.4118 256.6238,7.4088 C258.5908,9.4068 260.1118,11.8038 261.1878,14.5998 C262.2638,17.3988 262.8018,20.5478 262.8018,24.0508" />
        <path d="M332.5065,23.4057 C332.5065,20.7637 331.6915,18.6117 330.0635,16.9517 C328.4335,15.2917 325.8365,14.4627 322.2725,14.4627 L311.6345,14.4627 C311.1955,14.4627 310.8395,14.8187 310.8395,15.2587 L310.8395,31.7377 C310.8395,32.1777 311.1955,32.5337 311.6345,32.5337 L322.1795,32.5337 C325.7435,32.5337 328.3575,31.7037 330.0165,30.0447 C331.6775,28.3847 332.5065,26.1717 332.5065,23.4057 M347.6945,69.5077 L332.6685,69.5077 C332.3465,69.5077 332.0575,69.3137 331.9335,69.0177 L322.2925,45.9307 C322.1695,45.6347 321.8795,45.4427 321.5585,45.4427 L311.6345,45.4427 C311.1955,45.4427 310.8395,45.7987 310.8395,46.2377 L310.8395,68.7117 C310.8395,69.1507 310.4825,69.5077 310.0435,69.5077 L296.6985,69.5077 C296.2585,69.5077 295.9025,69.1507 295.9025,68.7117 L295.9025,2.0727 C295.9025,1.6327 296.2585,1.2767 296.6985,1.2767 L322.2725,1.2767 C331.0005,1.3387 337.5155,3.2297 341.8195,6.9477 C346.1215,10.6677 348.2735,16.1537 348.2735,23.4057 C348.2735,28.0767 347.3355,32.0727 345.4605,35.3917 C343.7035,38.5017 341.1535,40.9107 337.8065,42.6167 C337.4255,42.8107 337.2545,43.2617 337.4285,43.6527 L348.4215,68.3877 C348.6545,68.9147 348.2695,69.5077 347.6945,69.5077" />
        <path d="M520.632,15.476 L503.692,15.476 C503.252,15.476 502.896,15.832 502.896,16.272 L502.896,68.711 C502.896,69.151 502.539,69.507 502.1,69.507 L488.754,69.507 C488.315,69.507 487.958,69.151 487.958,68.711 L487.958,16.272 C487.958,15.832 487.602,15.476 487.162,15.476 L470.129,15.476 C469.69,15.476 469.333,15.12 469.333,14.68 L469.333,2.072 C469.333,1.633 469.69,1.276 470.129,1.276 L520.632,1.276 C521.071,1.276 521.428,1.633 521.428,2.072 L521.428,14.68 C521.428,15.12 521.071,15.476 520.632,15.476" />
        <path d="M570.6642,16.1796 L570.6642,27.5886 C570.6642,28.0286 571.0202,28.3846 571.4602,28.3846 L595.0402,28.3846 C595.4792,28.3846 595.8362,28.7416 595.8362,29.1806 L595.8362,40.7736 C595.8362,41.2136 595.4792,41.5696 595.0402,41.5696 L571.4602,41.5696 C571.0202,41.5696 570.6642,41.9266 570.6642,42.3656 L570.6642,54.6966 C570.6642,55.1366 571.0202,55.4916 571.4602,55.4916 L598.2672,55.4916 C598.7062,55.4916 599.0622,55.8476 599.0622,56.2876 L599.0622,68.7116 C599.0622,69.1506 598.7062,69.5076 598.2672,69.5076 L556.5232,69.5076 C556.0842,69.5076 555.7272,69.1506 555.7272,68.7116 L555.7272,2.0726 C555.7272,1.6326 556.0842,1.2766 556.5232,1.2766 L598.2672,1.2766 C598.7062,1.2766 599.0622,1.6326 599.0622,2.0726 L599.0622,14.5876 C599.0622,15.0276 598.7062,15.3836 598.2672,15.3836 L571.4602,15.3836 C571.0202,15.3836 570.6642,15.7406 570.6642,16.1796" />
        <path d="M641.0236,68.7113 L641.0236,2.0723 C641.0236,1.6323 641.3786,1.2763 641.8186,1.2763 L655.1646,1.2763 C655.6036,1.2763 655.9596,1.6323 655.9596,2.0723 L655.9596,68.7113 C655.9596,69.1503 655.6036,69.5073 655.1646,69.5073 L641.8186,69.5073 C641.3786,69.5073 641.0236,69.1503 641.0236,68.7113" />
        <path d="M758.757,2.0722 L758.757,68.7112 C758.757,69.1512 758.401,69.5072 757.961,69.5072 L743.021,69.5072 C742.584,69.5072 742.178,69.2822 741.945,68.9132 L715.823,27.5862 C715.653,27.3172 715.236,27.4372 715.236,27.7562 L715.236,68.7112 C715.236,69.1512 714.88,69.5072 714.441,69.5072 L701.28,69.5072 C700.841,69.5072 700.485,69.1512 700.485,68.7112 L700.485,2.0722 C700.485,1.6332 700.841,1.2762 701.28,1.2762 L716.218,1.2762 C716.655,1.2762 717.062,1.5012 717.295,1.8702 L743.416,43.2872 C743.586,43.5572 744.004,43.4372 744.004,43.1172 L744.004,2.0722 C744.004,1.6332 744.36,1.2762 744.8,1.2762 L757.961,1.2762 C758.401,1.2762 758.757,1.6332 758.757,2.0722" />
        <path d="M410.4191,55.8481 C419.8061,55.8481 429.9601,49.5251 429.9601,35.3481 C429.9601,21.1701 419.8061,14.7511 410.4191,14.7511 C400.9351,14.7511 390.7801,21.1701 390.7801,35.3481 C390.7801,49.5251 400.9351,55.8481 410.4191,55.8481 M410.4191,0.0001 C429.4811,0.0001 445.6701,13.8901 445.6701,35.4431 C445.6701,56.9011 429.4811,70.7911 410.4191,70.7911 C391.2601,70.7911 375.0711,56.9011 375.0711,35.4431 C375.0711,13.8901 391.2601,0.0001 410.4191,0.0001" />
        <path d="M136.3917,1.2319 C136.8597,1.2319 137.4207,1.5699 137.6387,1.9839 L151.1447,27.5999 C151.3637,28.0139 151.7207,28.0139 151.9377,27.5999 L165.4427,1.9839 C165.6617,1.5699 166.2227,1.2319 166.6897,1.2319 L181.2247,1.2319 C181.6927,1.2319 181.8897,1.5659 181.6627,1.9749 L159.2427,42.2769 C159.0157,42.6849 158.8287,43.4029 158.8287,43.8709 L158.8287,68.6439 C158.8287,69.1119 158.4467,69.4949 157.9787,69.4949 L144.6427,69.4949 C144.1757,69.4949 143.7927,69.1119 143.7927,68.6439 L143.7927,43.8709 C143.7927,43.4029 143.6067,42.6859 143.3777,42.2779 L120.8687,1.9749 C120.6407,1.5659 120.8367,1.2319 121.3047,1.2319 L136.3917,1.2319 Z" />
        <path d="M53.1275,69.5063 L37.1125,69.5063 C36.7465,69.5063 36.5135,69.1133 36.6885,68.7913 L73.2785,1.7803 C73.4485,1.4703 73.7735,1.2773 74.1265,1.2773 L90.1415,1.2773 C90.5075,1.2773 90.7405,1.6703 90.5655,1.9913 L53.9755,69.0033 C53.8055,69.3143 53.4805,69.5063 53.1275,69.5063" />
        <path d="M0.567,1.2753 L16.808,1.2753 C17.067,1.2753 17.306,1.4173 17.43,1.6443 L40.614,44.1033 C40.753,44.3573 40.753,44.6643 40.614,44.9183 L32.643,59.5163 C32.428,59.9093 31.862,59.9093 31.648,59.5163 L14.844,28.7423 C14.704,28.4843 14.312,28.5853 14.312,28.8783 L14.312,68.9393 C14.312,69.2523 14.058,69.5063 13.745,69.5063 L0.567,69.5063 C0.254,69.5063 0,69.2523 0,68.9393 L0,1.8423 C0,1.5293 0.254,1.2753 0.567,1.2753" />
        <path d="M76.6378,44.7856 L90.0928,20.1436 C90.2338,19.8856 90.6248,19.9856 90.6248,20.2796 L90.6248,68.9386 C90.6248,69.2526 90.3708,69.5066 90.0578,69.5066 L76.8578,69.5066 C76.5448,69.5066 76.2908,69.2526 76.2908,68.9386 L76.2908,46.1436 C76.2908,45.6696 76.4098,45.2026 76.6378,44.7856" />
      </g>
    </svg>
  ),
}
