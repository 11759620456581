/* eslint-disable max-len */
const programName = 'Nude'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 278.75 38.395">
      <title>{programName}</title>
      <g id="Group_995" dataName="Group 995" transform="translate(-880.787 -30.718)">
        <g id="Group_39" dataName="Group 39" transform="translate(929.989 42.748)">
          <g id="Group_4" dataName="Group 4">
            <path id="Path_18" dataName="Path 18" d="M226.5,617.984v-.412h4.756v.412a2,2,0,0,0-2.047,2.308v11.549h-.462L218.6,620.409v8.622a2.361,2.361,0,0,0,.411,1.582,2.9,2.9,0,0,0,1.66.653v.412h-4.861v-.412a2.186,2.186,0,0,0,1.419-.677,2.542,2.542,0,0,0,.43-1.63v-9.573a12.77,12.77,0,0,0-1.137-1.083,1.778,1.778,0,0,0-.736-.318v-.412h4.238l8.243,9.454v-6.346a3.638,3.638,0,0,0-.369-1.937A2.192,2.192,0,0,0,226.5,617.984Z" transform="translate(-215.787 -616.584)" fill="#080808" />
            <path id="Path_19" dataName="Path 19" d="M246.082,631.677v-1.884a9.3,9.3,0,0,1-2.255,1.772,5.086,5.086,0,0,1-2.29.477,4.848,4.848,0,0,1-3.526-1.271,4.531,4.531,0,0,1-1.313-3.415v-7.394a2,2,0,0,0-.347-1.336,2.935,2.935,0,0,0-1.443-.643v-.412H241.6v.412a2.841,2.841,0,0,0-1.43.643,2.192,2.192,0,0,0-.36,1.453v7.888a2.819,2.819,0,0,0,.705,2.032,2.569,2.569,0,0,0,1.943.735,5.263,5.263,0,0,0,3.627-1.955v-8.818a2,2,0,0,0-.341-1.332,3.032,3.032,0,0,0-1.448-.647v-.412h6.663v.412a2.621,2.621,0,0,0-1.391.619,2.532,2.532,0,0,0-.375,1.643v9.041a2.007,2.007,0,0,0,.339,1.331,2.959,2.959,0,0,0,1.427.647v.412Z" transform="translate(-217.507 -616.584)" fill="#080808" />
            <path id="Path_20" dataName="Path 20" d="M255.129,631.677v-.412a3.206,3.206,0,0,0,1.454-.677,2.582,2.582,0,0,0,.336-1.607v-8.995a2.01,2.01,0,0,0-.336-1.329,3.073,3.073,0,0,0-1.454-.673v-.412h7.217a9.112,9.112,0,0,1,6.211,1.991,6.619,6.619,0,0,1,2.278,5.238,6.469,6.469,0,0,1-2.038,5.005,7.826,7.826,0,0,1-5.509,1.871Zm6.734-13.3h-1.836v10.842a1.463,1.463,0,0,0,.53,1.283,3.118,3.118,0,0,0,1.764.378,4.634,4.634,0,0,0,3.791-1.625,7.009,7.009,0,0,0,1.343-4.591,6.765,6.765,0,0,0-1.472-4.6A5.178,5.178,0,0,0,261.863,618.374Z" transform="translate(-219.327 -616.584)" fill="#080808" />
            <path id="Path_21" dataName="Path 21" d="M284.61,622.435h.46V626.8h-.46a1.814,1.814,0,0,0-1.954-1.8h-2.484v4.145a1.736,1.736,0,0,0,.435,1.342,2.246,2.246,0,0,0,1.491.388h.824q2.067,0,2.96-2.626h.412l-.258,3.426H275.3v-.412a2.539,2.539,0,0,0,1.416-.642,2.662,2.662,0,0,0,.349-1.619V619.94a2,2,0,0,0-.362-1.372,2.66,2.66,0,0,0-1.4-.583v-.412h10.573V620.7h-.412a3.847,3.847,0,0,0-.877-1.854,2.445,2.445,0,0,0-1.677-.477h-2.732V624.2h2.484a1.873,1.873,0,0,0,1.295-.4A2.528,2.528,0,0,0,284.61,622.435Z" transform="translate(-221.141 -616.584)" fill="#080808" />
            <path id="Path_22" dataName="Path 22" d="M309.362,627.581a4.539,4.539,0,0,1-1.559,3.508,5.88,5.88,0,0,1-4.1,1.413,5.944,5.944,0,0,1-4.115-1.406,4.525,4.525,0,0,1-1.571-3.516,4.657,4.657,0,0,1,.723-2.483,4.723,4.723,0,0,1,1.989-1.8,6.726,6.726,0,0,1,2.973-.616,5.873,5.873,0,0,1,4.109,1.412A4.52,4.52,0,0,1,309.362,627.581Zm-3.013.006a6,6,0,0,0-.689-3.142,2.293,2.293,0,0,0-3.95.028,6.458,6.458,0,0,0-.682,3.278,5.415,5.415,0,0,0,.724,2.989,2.221,2.221,0,0,0,1.948,1.115,2.179,2.179,0,0,0,1.948-1.127A5.932,5.932,0,0,0,306.349,627.587Z" transform="translate(-223.185 -617.044)" fill="#080808" />
            <path id="Path_23" dataName="Path 23" d="M317.391,622.49h2.027v.741h-2.027V629.1a2.537,2.537,0,0,0,.3,1.513,2.926,2.926,0,0,0,1.573.56v.412H312.93v-.412a1.61,1.61,0,0,0,1.73-2.049v-5.887h-1.448V622.9a2.046,2.046,0,0,0,1.159-.6,2.944,2.944,0,0,0,.43-1.647,5.717,5.717,0,0,1,.372-1.937,3.708,3.708,0,0,1,.84-1.2,4.99,4.99,0,0,1,3.5-1.036,5.087,5.087,0,0,1,3.272,1.007l-1.919,2.35h-.388c.007-.119.011-.237.011-.354a2.843,2.843,0,0,0-.471-1.7,1.372,1.372,0,0,0-1.13-.654q-1.495,0-1.5,2.661Z" transform="translate(-224.527 -616.486)" fill="#080808" />
            <path id="Path_24" dataName="Path 24" d="M338.707,617.172v3.72H338.3a4.528,4.528,0,0,0-1.416-2.078,3.205,3.205,0,0,0-2.075-.843,2.5,2.5,0,0,0-1.638.554,1.69,1.69,0,0,0-.676,1.354,1.824,1.824,0,0,0,.177.771,2.451,2.451,0,0,0,.512.713,17.932,17.932,0,0,0,2.162,1.469,25.554,25.554,0,0,1,2.4,1.585,5.977,5.977,0,0,1,1.035,1.143,4.006,4.006,0,0,1,.752,2.346,3.7,3.7,0,0,1-1.324,2.993A4.841,4.841,0,0,1,335,632.007a9.769,9.769,0,0,1-2.413-.365,5,5,0,0,0-1.036-.188.749.749,0,0,0-.718.459h-.412v-4.25h.412a4.678,4.678,0,0,0,1.319,2.6,3.441,3.441,0,0,0,2.46.949,2.975,2.975,0,0,0,1.866-.565,1.741,1.741,0,0,0,.736-1.436,2.188,2.188,0,0,0-.471-1.337,9.988,9.988,0,0,0-2.522-1.85,14.458,14.458,0,0,1-2.5-1.738,4.146,4.146,0,0,1-1.233-2.983,3.871,3.871,0,0,1,1.24-2.969,4.49,4.49,0,0,1,3.191-1.156,6.428,6.428,0,0,1,1.446.2,6.431,6.431,0,0,0,1.2.189,1.085,1.085,0,0,0,.73-.388Z" transform="translate(-226.1 -616.548)" fill="#080808" />
            <path id="Path_25" dataName="Path 25" d="M352.592,622.684l.2,3.307h-.412q-1.3-2.753-3.343-2.755a2.582,2.582,0,0,0-2.19,1.077,4.841,4.841,0,0,0-.8,2.949,4.638,4.638,0,0,0,.888,3.009,3,3,0,0,0,2.456,1.089,3.582,3.582,0,0,0,1.712-.371,5.288,5.288,0,0,0,1.478-1.337h.483a4.942,4.942,0,0,1-4.9,2.849,5.267,5.267,0,0,1-3.715-1.307,4.441,4.441,0,0,1-1.419-3.426,4.767,4.767,0,0,1,1.562-3.627,5.493,5.493,0,0,1,3.9-1.459,12.122,12.122,0,0,1,1.793.153,9.32,9.32,0,0,0,1.2.14,1.123,1.123,0,0,0,.719-.293Z" transform="translate(-227.235 -617.044)" fill="#080808" />
            <path id="Path_26" dataName="Path 26" d="M358.21,632.5a2.806,2.806,0,0,1-1.926-.7,2.177,2.177,0,0,1-.794-1.69,2.043,2.043,0,0,1,.79-1.643,9.567,9.567,0,0,1,3.3-1.312q1.212-.318,1.743-.52a2.579,2.579,0,0,0-.542-1.824,2.29,2.29,0,0,0-1.719-.565,2.941,2.941,0,0,0-2.731,1.518h-.353a3.939,3.939,0,0,1,1.431-2.279,4.262,4.262,0,0,1,2.643-.8,4.456,4.456,0,0,1,2.914.894,3.065,3.065,0,0,1,1.089,2.508v4.285q0,1.214.553,1.212.577,0,.718-.848h.389a1.78,1.78,0,0,1-.648,1.309,2.451,2.451,0,0,1-1.566.458,2.65,2.65,0,0,1-1.348-.3,2.265,2.265,0,0,1-.854-.983l-.212.2A4.2,4.2,0,0,1,358.21,632.5Zm3.085-1.93v-3.45a8.806,8.806,0,0,0-2.385,1.318,1.879,1.879,0,0,0-.582,1.424,1.912,1.912,0,0,0,.348,1.2,1.094,1.094,0,0,0,.912.453A2.61,2.61,0,0,0,361.3,630.572Z" transform="translate(-228.356 -617.044)" fill="#080808" />
            <path id="Path_27" dataName="Path 27" d="M372.575,622.684v2.225h.047a3.9,3.9,0,0,1,3.45-2.225,3.112,3.112,0,0,1,2.261.9,3.224,3.224,0,0,1,.918,2.408v3.981a1.833,1.833,0,0,0,.307,1.206,2.17,2.17,0,0,0,1.117.547v.412h-5.592v-.412a2.07,2.07,0,0,0,1.118-.535,1.628,1.628,0,0,0,.319-1.1V626.32a2.548,2.548,0,0,0-.442-1.594,1.479,1.479,0,0,0-1.242-.57,2.557,2.557,0,0,0-2.261,1.589v4.25a1.823,1.823,0,0,0,.282,1.153,2.192,2.192,0,0,0,1.061.578v.412h-5.5v-.412a2.233,2.233,0,0,0,1.112-.542,1.471,1.471,0,0,0,.313-1.024v-5.086a1.533,1.533,0,0,0-.359-1.13,2.286,2.286,0,0,0-1.278-.482v-.412l3.957-.364Z" transform="translate(-229.5 -617.044)" fill="#080808" />
            <path id="Path_28" dataName="Path 28" d="M394.439,616.486h.411V629.1a2.429,2.429,0,0,0,.323,1.5,2.686,2.686,0,0,0,1.49.57v.412h-6.8a6.57,6.57,0,0,1-4.3-1.248,4.213,4.213,0,0,1-1.507-3.426,4.658,4.658,0,0,1,1.336-3.45,4.716,4.716,0,0,1,3.48-1.329,5.781,5.781,0,0,1,3.261.917v-4.169a1.539,1.539,0,0,0-.5-1.306,3.179,3.179,0,0,0-1.749-.412v-.412Zm-2.308,12.469v-4.71a3.049,3.049,0,0,0-2.554-1.472,2.077,2.077,0,0,0-1.831,1.065,5.349,5.349,0,0,0-.677,2.891,4.738,4.738,0,0,0,.966,3.155,3.069,3.069,0,0,0,2.449,1.141,1.555,1.555,0,0,0,1.283-.459A2.659,2.659,0,0,0,392.132,628.955Z" transform="translate(-230.926 -616.486)" fill="#080808" />
            <path id="Path_29" dataName="Path 29" d="M403.47,622.127h.413v7.5a1.44,1.44,0,0,0,1.471,1.543v.412h-5.721v-.412a2.183,2.183,0,0,0,1.218-.518,1.858,1.858,0,0,0,.3-1.212v-4.85a1.666,1.666,0,0,0-.318-1.166,2.578,2.578,0,0,0-1.317-.518v-.412Zm-2.531-4.074a1.5,1.5,0,0,1,.466-1.107,1.539,1.539,0,0,1,1.123-.459,1.557,1.557,0,0,1,1.566,1.566,1.541,1.541,0,0,1-.459,1.124,1.5,1.5,0,0,1-1.108.465,1.519,1.519,0,0,1-1.123-.47A1.535,1.535,0,0,1,400.939,618.052Z" transform="translate(-232.317 -616.486)" fill="#080808" />
            <path id="Path_30" dataName="Path 30" d="M411.994,622.684v2.225h.048a3.9,3.9,0,0,1,3.448-2.225,3.106,3.106,0,0,1,2.26.9,3.218,3.218,0,0,1,.919,2.408v3.981a1.834,1.834,0,0,0,.306,1.206,2.182,2.182,0,0,0,1.118.547v.412H414.5v-.412a2.069,2.069,0,0,0,1.118-.535,1.619,1.619,0,0,0,.318-1.1V626.32a2.554,2.554,0,0,0-.441-1.594,1.482,1.482,0,0,0-1.242-.57,2.554,2.554,0,0,0-2.26,1.589v4.25a1.823,1.823,0,0,0,.282,1.153,2.2,2.2,0,0,0,1.059.578v.412h-5.5v-.412a2.236,2.236,0,0,0,1.113-.542,1.471,1.471,0,0,0,.311-1.024v-5.086a1.529,1.529,0,0,0-.359-1.13,2.275,2.275,0,0,0-1.276-.482v-.412l3.955-.364Z" transform="translate(-233.047 -617.044)" fill="#080808" />
            <path id="Path_31" dataName="Path 31" d="M425.741,632.5a2.805,2.805,0,0,1-1.925-.7,2.178,2.178,0,0,1-.8-1.69,2.046,2.046,0,0,1,.79-1.643,9.564,9.564,0,0,1,3.3-1.312q1.209-.318,1.742-.52a2.574,2.574,0,0,0-.542-1.824,2.286,2.286,0,0,0-1.719-.565,2.942,2.942,0,0,0-2.731,1.518h-.352a3.942,3.942,0,0,1,1.429-2.279,4.268,4.268,0,0,1,2.645-.8,4.457,4.457,0,0,1,2.914.894,3.071,3.071,0,0,1,1.089,2.508v4.285c0,.809.183,1.212.552,1.212s.624-.283.718-.848h.389a1.778,1.778,0,0,1-.649,1.309,2.448,2.448,0,0,1-1.566.458,2.649,2.649,0,0,1-1.347-.3,2.265,2.265,0,0,1-.854-.983l-.213.2A4.2,4.2,0,0,1,425.741,632.5Zm3.085-1.93v-3.45a8.8,8.8,0,0,0-2.385,1.318,1.882,1.882,0,0,0-.582,1.424,1.923,1.923,0,0,0,.348,1.2,1.094,1.094,0,0,0,.912.453A2.612,2.612,0,0,0,428.826,630.572Z" transform="translate(-234.432 -617.044)" fill="#080808" />
            <path id="Path_32" dataName="Path 32" d="M446.012,623.084v.412a1.877,1.877,0,0,0-1.158.553,5.625,5.625,0,0,0-1.009,1.589l-3.28,6.9h-.514l-3.421-7.064a5.056,5.056,0,0,0-1.031-1.531,1.944,1.944,0,0,0-1.22-.448v-.412h5.665v.412c-.574.11-.861.393-.861.846a1.477,1.477,0,0,0,.167.623l2.1,4.24,1.632-3.381a3.08,3.08,0,0,0,.378-1.307q0-.94-1.12-1.022v-.412Z" transform="translate(-235.454 -617.08)" fill="#080808" />
            <path id="Path_33" dataName="Path 33" d="M452.7,622.127h.412v7.5a1.44,1.44,0,0,0,1.472,1.543v.412h-5.723v-.412a2.187,2.187,0,0,0,1.22-.518,1.853,1.853,0,0,0,.3-1.212v-4.85a1.675,1.675,0,0,0-.318-1.166,2.589,2.589,0,0,0-1.319-.518v-.412Zm-2.531-4.074a1.5,1.5,0,0,1,.463-1.107,1.545,1.545,0,0,1,1.126-.459,1.516,1.516,0,0,1,1.106.453,1.5,1.5,0,0,1,.46,1.113,1.534,1.534,0,0,1-.46,1.124,1.5,1.5,0,0,1-1.106.465,1.524,1.524,0,0,1-1.126-.47A1.543,1.543,0,0,1,450.166,618.052Z" transform="translate(-236.746 -616.486)" fill="#080808" />
            <path id="Path_34" dataName="Path 34" d="M459.518,632.5a2.805,2.805,0,0,1-1.925-.7,2.177,2.177,0,0,1-.794-1.69,2.045,2.045,0,0,1,.789-1.643,9.563,9.563,0,0,1,3.3-1.312q1.211-.318,1.742-.52a2.579,2.579,0,0,0-.541-1.824,2.29,2.29,0,0,0-1.719-.565,2.942,2.942,0,0,0-2.732,1.518h-.352a3.947,3.947,0,0,1,1.429-2.279,4.271,4.271,0,0,1,2.646-.8,4.453,4.453,0,0,1,2.913.894,3.068,3.068,0,0,1,1.089,2.508v4.285c0,.809.183,1.212.553,1.212s.624-.283.718-.848h.389a1.775,1.775,0,0,1-.649,1.309,2.446,2.446,0,0,1-1.565.458,2.651,2.651,0,0,1-1.348-.3,2.264,2.264,0,0,1-.853-.983l-.214.2A4.2,4.2,0,0,1,459.518,632.5Zm3.086-1.93v-3.45a8.788,8.788,0,0,0-2.385,1.318,1.88,1.88,0,0,0-.583,1.424,1.918,1.918,0,0,0,.348,1.2,1.094,1.094,0,0,0,.912.453A2.616,2.616,0,0,0,462.6,630.572Z" transform="translate(-237.471 -617.044)" fill="#080808" />
          </g>
        </g>
        <g id="Group_954" dataName="Group 954" transform="translate(881.537 31.468)">
          <rect className="no-fill" id="Rectangle_1" dataName="Rectangle 1" width="36.926" height="36.895" fill="none" stroke="white" strokeMiterlimit="10" strokeWidth="1.5" />
          <g id="Group_7" dataName="Group 7" transform="translate(7.067 7.23)">
            <path id="Path_43" dataName="Path 43" d="M217.3,218.053c0-.03.008-.061.008-.091q0-6.6,0-13.205a6.268,6.268,0,0,0-.269-1.927,1.792,1.792,0,0,0-1.251-1.257,3.721,3.721,0,0,0-1.042-.153c-.112,0-.159-.029-.151-.147a3.165,3.165,0,0,0,0-.385c-.006-.11.025-.152.147-.152q2.651.006,5.3,0c.395,0,.79,0,1.184,0,.111,0,.162.03.156.148a3.418,3.418,0,0,0,0,.385c.007.116-.041.15-.153.15a3.655,3.655,0,0,0-1.461.275,1.753,1.753,0,0,0-.965,1.053,4.656,4.656,0,0,0-.275,1.494c-.018.618-.038,1.237-.038,1.856q0,8.448,0,16.9c0,.128-.039.15-.161.167a.9.9,0,0,1-.907-.415c-1.009-1.283-2.051-2.54-3.082-3.806q-1.007-1.238-2.018-2.473-1.052-1.29-2.1-2.582-1.021-1.253-2.045-2.5t-2.058-2.529q-1.034-1.269-2.07-2.537-.978-1.2-1.954-2.4c-.03-.037-.062-.073-.116-.136a1.317,1.317,0,0,0-.018.137q0,7.835,0,15.67a4.236,4.236,0,0,0,.263,1.55,1.929,1.929,0,0,0,1.312,1.191,4.092,4.092,0,0,0,1.22.171c.106,0,.149.039.144.147-.006.128-.006.257,0,.385,0,.088-.028.13-.117.126-.028,0-.055,0-.083,0h-6.444c-.2,0-.2,0-.2-.2,0-.1.006-.2,0-.3-.008-.118.046-.152.155-.152a3.69,3.69,0,0,0,1.083-.157,1.736,1.736,0,0,0,1.169-1.056,4.731,4.731,0,0,0,.326-1.609q.038-1.2.04-2.393.006-6.059,0-12.117a4.845,4.845,0,0,0-.465-2.2,2.531,2.531,0,0,0-1.3-1.186,5.075,5.075,0,0,0-1.42-.353c-.094-.012-.127-.051-.125-.141,0-.128.005-.257,0-.385s.041-.16.161-.16c1.074,0,2.148,0,3.222,0,.757,0,1.515,0,2.272,0a.269.269,0,0,1,.183.088q1.328,1.634,2.647,3.274,1.487,1.841,2.976,3.68,1.379,1.707,2.755,3.416t2.759,3.414q1.365,1.692,2.727,3.386a.542.542,0,0,1,.046.083Z" transform="translate(-197.502 -200.736)" fillRule="evenodd" />
          </g>
        </g>
      </g>
    </svg>
  ),
}
