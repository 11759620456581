/* eslint-disable max-len */
const programName = 'Pandora'
export default {
  programName,
  logo: (
    <svg id="icon-logo" viewBox="0 0 283.5 58.9">
      <title>{programName}</title>
      <polygon points="177.9,10.2 179,10.2 179.1,10.2 178.1,0.1 178,0 177.7,0 177.7,0.1 176.6,10.2 176.7,10.2 " />
      <polygon points="183.6,10.9 183.6,10.8 185.3,1.8 185.3,1.7 185,1.6 184.9,1.7 181.2,10.3 181.2,10.3 " />
      <path d="M181.8,14.8c0.7,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4C180.4,14.2,181,14.8,181.8,14.8z" />
      <path d="M177.9,14.3c0.7,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4C176.4,13.8,177.1,14.3,177.9,14.3z" />
      <polygon points="172.2,10.9 174.5,10.3 174.6,10.3 170.8,1.7 170.7,1.6 170.5,1.7 170.4,1.8 172.1,10.8 " />
      <path d="M172.5,13.3c0,0.8,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4C173.2,12,172.5,12.6,172.5,13.3z" />
      <path d="M12.1,17.3h-12L0,17.3c0,0,0.3,2.9,0.3,7.9v25.3c0,5-0.3,7.9-0.3,7.9l0.1,0.1h7.9L8,58.4c0,0-0.3-2.9-0.3-7.9v-7.4L7.8,43
c1.1,0.1,3.5,0.2,4.3,0.2c8.1,0,15.1-4.9,15.1-13.3C27.1,21.9,20.1,17.3,12.1,17.3z M11.7,36.5c-0.9,0-3.1-0.1-4-0.3V24h4.6
c4.1,0,7.3,1.6,7.3,5.9C19.6,34.2,16.8,36.5,11.7,36.5z"
      />
      <path d="M49.6,20.6c-0.9-2.1-1.2-3.3-1.2-3.3l-0.1-0.1H42l-0.1,0.1c0,0-0.2,1.2-1.1,3.3L26.9,54c-1.2,2.8-2.4,4.4-2.4,4.4v0.1h8.1
l0.1-0.1c0-0.2,0.2-1.2,1.2-3.7l2.5-6.3h17.9l2.7,6.3c0.9,2.4,1.4,3.7,1.4,3.7l0.1,0.1h8.1v-0.1c0,0-1.1-1.5-2.4-4.4L49.6,20.6z
M39.2,41.6L45,27.1h0.1l6.3,14.5H39.2z"
      />
      <path d="M107.2,17.3L107.2,17.3l-7.2-0.1l-0.1,0.1c0,0,0.3,2.9,0.3,7.9v20.7h-0.1L77.9,17.3h-7.9L70,17.3c0.9,1.9,1.4,3.2,1.4,4.1
v29.1c0,5-0.3,7.9-0.3,7.9l0.1,0.1h7.2l0.1-0.1c0,0-0.3-2.9-0.3-7.9V29.4h0.1l22.3,29h6.7l0.1-0.1c0,0-0.3-2.9-0.3-7.9V25.2
C106.9,20.2,107.2,17.3,107.2,17.3z"
      />
      <path d="M129.6,17.3h-13.8l-0.1,0.1c0,0,0.3,2.9,0.3,7.9v25.3c0,5-0.3,7.9-0.3,7.9l0.1,0.1h4.2c2.8,0,5.9,0.4,9.4,0.4
c12.6,0,21.6-8.9,21.6-20.9C151,25.9,142.3,17.3,129.6,17.3z M129.1,52c-2,0-3.8-0.1-4.9-0.4c-0.6-0.2-0.8-0.4-0.8-1.5V24h5.5
c8.9,0,14.6,6,14.6,14C143.5,45.9,137.8,52,129.1,52z"
      />
      <path d="M177.9,16.7c-11.9,0-21.1,9.2-21.1,21.1c0,12,9.2,21.1,21.1,21.1c12,0,21.1-9.1,21.1-21.1C199,25.9,189.9,16.7,177.9,16.7z
M177.9,52.2c-7.6,0-13.6-6.2-13.6-14.3c0-8.1,5.9-14.3,13.6-14.3c7.7,0,13.6,6.2,13.6,14.3C191.5,45.9,185.6,52.2,177.9,52.2z"
      />
      <path d="M228.3,44.5c-1-1.6-2.5-2.9-4.1-3.4V41c4.3-0.9,8.3-5.9,8.3-10.9c0-6.8-5.2-12.8-13.3-12.8h-13.2l-0.1,0.1
c0,0,0.3,2.9,0.3,7.9v25.3c0,5-0.3,7.9-0.3,7.9l0.1,0.1h7.9l0.1-0.1c0,0-0.3-2.9-0.3-7.9v-7.9c3.9,0,5.5,0.8,6.9,3L224,51
c2.8,4.5,3.9,7.3,3.9,7.3l0.1,0.1h9.4v-0.1c0,0-2.4-2.9-5.1-7.3L228.3,44.5z M217.4,36.1c-0.9,0-2.6,0-3.7-0.2V24h4.5
c4.4,0,6.8,2.9,6.8,6.2C225,33.3,222.6,36.1,217.4,36.1z"
      />
      <path d="M281.1,54l-14.6-33.4c-0.9-2.1-1.2-3.3-1.2-3.3l-0.1-0.1h-6.4l-0.1,0.1c0,0-0.2,1.2-1.1,3.3L243.8,54
c-1.2,2.8-2.4,4.4-2.4,4.4v0.1h8.1l0.1-0.1c0-0.2,0.2-1.2,1.2-3.7l2.5-6.3h17.9l2.7,6.3c0.9,2.4,1.4,3.7,1.4,3.7l0.1,0.1h8.1v-0.1
C283.5,58.4,282.4,56.9,281.1,54z M256.1,41.6l5.8-14.5h0.1l6.3,14.5H256.1z"
      />
    </svg>
  ),
}
