/* eslint-disable max-len */
const programName = 'Wiggle'
export default {
  programName,
  logo: (
    <svg className="wiggle" x="0px" y="0px" viewBox="0 0 445.6 285.2">
      <title>{programName}</title>
      <g id="Background" className="st0">
        <rect x="14.2" y="14.2" className="st1" width="1162.2" height="813.5" />
      </g>
      <g id="Artwork">
        <g>
          <g>
            <g>
              <path
                className="st2"
                d="M294.9,1.5c-9.4-2.4-8.8,3.4-8.6,15.4c0.3,27-0.5,59.5-8.9,71.5c-5.8,8.2-22.1,14.5-39.7,17.7
                                c-19.9,3.7-40.9,3.9-49-0.3C176.4,99.4,163.2,81,154.4,58c-1.6-4.3-3.8-12.9-8.7-11.4c-8.6,2.5-15.3,11.8-16.1,19.9
                                c-0.8,7.9,4.8,19.2,14.8,30.6c9.8,11.1,23.3,22.9,36,28.1c25.1,10.2,99.7-0.7,116.6-25.5c9.4-13.9,15.8-50,17.9-69.2
                                C316.5,15,310.7,5.5,294.9,1.5z"
              />
              <path
                className="st2"
                d="M233.2,83.4c8.1-1.2,14.8-14.7,13.8-35.5c-0.9-20.4-12.9-37.4-31.3-33.6c-14.4,3-17.7,22.4-13.7,40.1
                                C205.8,70.5,218,85.5,233.2,83.4z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <path
              className="st3"
              d="M114.6,162.7c-3.5,0.9-7.4,2.1-10.5,3.2c-2.4,0.9-3,1.7-3.3,4.3c-1.4,15.4-4.7,40.8-11.6,64.5
                            c-3.7-14.8-12.2-43.3-17.7-58.3c-3.2-8.7-7-10.7-12.5-10.7c-5.4,0-9,2-12.1,10.7c-8,22.3-16.9,54.5-17.8,58.3
                            c-1.7-5.6-9.8-34.7-12-69.1c-0.2-3.2-1.5-3.5-3.7-2.9c-1.6,0.4-7.7,2.2-9.7,2.9c-1.3,0.4-2.1,0.9-2.6,1.6
                            c-0.5,0.8-0.5,1.9-0.4,3.4c1.8,25.3,9.3,58.2,15.5,75.4c2.1,5.7,4.9,6.9,13.4,6.9c8.2,0,11.2-1.9,13.2-10.3
                            c4.2-17.7,12.2-46.3,16.4-57.6c4,11.4,13.9,46.6,17,60.2c1.4,5.9,4.6,7.7,12.8,7.7c7.9,0,10.9-1.4,13-7
                            c8.1-22.2,14.2-58.2,15.8-80.6C118.1,162.7,116.9,162.1,114.6,162.7z"
            />
            <path
              className="st3"
              d="M444,202c0-27.3-15-40.7-38.1-40.7c-23.8,0-42.1,17.3-42.1,46.3c0,30.8,16.7,46,43.2,46.1
                            c9.1,0.1,19.2-2.2,25.9-5.6c2.1-1.1,2.4-3,1-4.9c-1.2-1.6-4.3-5.9-5.1-7c-1.3-1.7-2.8-1.9-4.7-1.1c-20.4,8-38-0.1-41.8-15
                            l51.8-10.4C440.5,208.3,444,207.7,444,202z M380.6,205.8c0-18.4,10.7-30.8,25.1-30.8c12.5,0,20.9,8.7,21.6,21.5L380.6,205.8z"
            />
            <g>
              <path
                className="st3"
                d="M146.4,147.6c0-2-0.6-3-2.8-2.6c-0.3,0.1-5.8,1.7-8.1,2.4c-3.4,1-5.8,3.4-5.8,7.8c0,2.8,1,3.5,2.9,2.9
                                c2.5-0.7,9.2-2.6,11-3.2c2.3-0.7,2.7-1.9,2.7-3.9C146.4,150.8,146.4,149.6,146.4,147.6z"
              />
            </g>
            <path
              className="st3"
              d="M228.9,166.2c-7.4-2.6-17.9-4.4-26.9-4.5c-14,0.1-24.3,3-32.7,12.2c-7.4,8.1-11.6,20.1-11.5,36
                            c0.1,27.1,15.8,43.9,38.7,43.6c8.2-0.1,15.5-2.6,20.7-6.8c-1.5,13.2-9.2,21.4-22.6,21.4c-1.6,0-3.8-0.2-5.3-0.3
                            c-2-0.2-3,0.1-4.2,1.8c-0.8,1.2-4.6,7.3-5.4,8.9c-0.9,1.9-0.1,3.4,1.9,4c2.9,0.8,6.1,1.3,11.5,1.3c12.5,0,23.8-4.3,30.8-12.4
                            c7.6-8.8,10.3-20.2,10.3-32.7c0-14.2,0-57.1,0-65.4C234.3,169.3,232.5,167.5,228.9,166.2z M217.7,221.4c0,11.3-9.2,16.6-19.9,17
                            c-15.1-0.4-23.6-12.5-23.6-30.4c0-19.6,10.1-31.9,25.9-31.9c7.9,0,12,0.8,17.7,2.4C217.7,197.9,217.7,201.6,217.7,221.4z"
            />
          </g>
          <path
            className="st3"
            d="M142.3,162.9l-9.1,2.7c-2.7,0.8-3.5,1.5-3.5,4.6v78.6c0,3.1,0.7,4,3.5,4h9.7c2.7,0,3.5-1,3.5-4v-83
                        C146.4,162.9,145.5,161.9,142.3,162.9z"
          />
          <path
            className="st3"
            d="M351.9,225.1v-84.4c0-2.8-0.8-3.8-4.1-2.8l-9.1,2.7c-2.7,0.8-3.5,1.5-3.5,4.6V229c0,9.7,1.1,13.2,3.4,16.9
                        c3,4.8,8.7,7.7,14.6,7.7c3.4,0,6.4-0.8,8.4-2.3c2.2-1.5,1.9-3.5-0.5-4.9C354.4,242.4,351.9,237.6,351.9,225.1z"
          />
          <path
            className="st3"
            d="M316.5,166.2c-7.4-2.6-17.9-4.4-26.9-4.5c-14.1,0.1-24.3,3-32.7,12.2c-7.4,8.1-11.6,20.1-11.5,36
                        c0.1,27.1,15.8,43.9,38.7,43.6c8.2-0.1,15.5-2.6,20.7-6.8c-1.5,13.2-9.2,21.4-22.6,21.4c-1.6,0-3.8-0.2-5.3-0.3
                        c-2-0.2-3,0.1-4.2,1.8c-0.8,1.2-4.6,7.3-5.4,8.9c-0.9,1.9-0.1,3.4,1.9,4c2.9,0.8,6.1,1.3,11.5,1.3c12.5,0,23.8-4.3,30.8-12.4
                        c7.6-8.8,10.3-20.2,10.3-32.7c0-14.2,0-57.1,0-65.4C321.9,169.3,320.2,167.5,316.5,166.2z M305.3,221.4c0,11.3-9.2,16.6-19.9,17
                        c-15.1-0.4-23.6-12.5-23.6-30.4c0-19.6,10.1-31.9,25.9-31.9c7.9,0,12,0.8,17.7,2.4C305.3,197.9,305.3,201.6,305.3,221.4z"
          />
        </g>
      </g>
    </svg>
  ),
}
