/* eslint-disable max-len */
const programName = 'Verktygsproffsen'
export default {
  programName,
  logo: (
    <svg className="verktygsproffsen" version="1.1" x="0px" y="0px" viewBox="0 0 1000 107">
      <title>{programName}</title>
      <g id="horizontal_light_1_">
        <g>
          <g>
            <path
              className="st0"
              d="M457.5,65.5L449,79.2c8.2,5.9,17.8,8.9,27.7,9.4l26.7-30.8c-3.8-5.9-11.4-8.9-20.4-11.4
                c-6.8-2-10.5-3.2-10.5-5.7v-0.2c0-1.8,1.8-3.1,5.3-3.1c5.1,0,12.4,2.3,19.1,6l7.8-14.3c-7.4-4.8-17.2-7.5-26.6-7.5
                c-14.9,0-25.9,7.6-25.9,21.4v0.2c0,13,10.2,17.3,22.9,20.6c6.8,1.9,10.4,3,10.4,5.5v0.2c0,2.3-1.8,3.5-5.7,3.5
                C473,72.9,464.9,70.4,457.5,65.5 M25.9,3.8H0l33.9,84.1h21.5L89.3,3.8H63.9l-19,52.8L25.9,3.8z M98.1,61.3h43.2
                c0.1-1.6,0.1-3.2,0.1-4.7c0-19-10.3-35.3-32.6-35.3c-19.1,0-32.6,14.9-32.6,33.8v0.2c0,20,14.3,33.5,34.6,33.5
                c12.9,0,21.9-5.2,28.1-13.1l-12.5-10.4c-4.7,4.5-9,6.6-14.3,6.6C105,71.9,99.9,68.4,98.1,61.3 M97.7,49.7
                c1.2-7,5.3-11.7,11.2-11.7c6.2,0,10.4,4.5,11.2,11.7H97.7z M168.9,87.3v-20c0-14.9,6.7-21.7,18.5-21.7h1.9V21.7
                c-10.7-0.5-16.7,5.2-20.4,14.1v-13h-22.7v64.5H168.9z M266.7,6.4v16.3h-6.5l-1.1,1.3v17h7.6v26.2c0,15.4,8.1,21.4,21.7,21.4
                c6.3,0,11.7-1.4,15.8-3.7V67.2c-2.7,1.3-5.6,2.1-8.6,2.1c-4.2,0-6.2-1.9-6.2-6.4V41h15V22.8h-15V6.4H266.7z M237.8,48.7
                l21.3-24.6v-1.3h-24.6L217,44.4V0.2h-22.7v87.1H217V72.2l5.9-6.8l13,21.9h25L237.8,48.7z M306.8,22.8l25.3,63.3
                c-1.4,2-3,2.6-5.3,2.6c-2.5,0-6.3-1.3-9.9-3.5l-7,15.9c6,3.3,12.8,5.6,20.8,5.6c12.9,0,19-6,24.6-21l23.6-63h-23.3l-12.2,38.9
                l-12.9-38.9H306.8z M423.4,22.8v7.6c-4.9-5.1-10.6-9.1-20.3-9.1c-14.9,0-27.9,11.5-27.9,30.1v0.2c0,18.5,12.8,29.9,28.7,29.9
                c9.4,0,15.4-4.4,19.9-9.7v2.5c0,10.3-5.6,15.9-17,15.9c-8.7,0-15.6-2.3-22.6-6.1l-7.3,15c9.3,4.8,19.8,7.4,32.4,7.4
                c13.5,0,22.6-3.2,28.4-9.1c5.7-5.7,8.4-14,8.4-25.8v-49H423.4z M423.7,51.4v0.2c0,7.2-5.5,12.5-13.1,12.5
                c-7.8,0-13.3-5.3-13.3-12.4v-0.2c0-7.2,5.5-12.5,13.3-12.5C418.3,39,423.7,44.3,423.7,51.4"
            />
            <path className="st0" d="M489.4,89.2c1,0,1.9,0.1,2.9,0.1c15.8,0,26.4-7.4,26.4-21.6v-0.2c0-3.7-1-6.6-2.6-9.1L489.4,89.2z" />
            <polygon className="st1" points="533.7,22.8 515.6,22.8 515.6,43.8" />
            <path
              className="st1"
              d="M977.3,87.9h22.7V45.7c0-14.7-8.2-23.7-22-23.7c-9.2,0-15.2,5.1-19.3,10.5v-9.1H936v64.5h22.7V53
                c0-7,4.1-10.9,9.6-10.9c5.4,0,9.1,3.8,9.1,10.9V87.9z M887.9,61.9h43.2c0.1-1.5,0.1-3.2,0.1-4.7c0-19-10.3-35.3-32.6-35.3
                c-19.1,0-32.6,14.9-32.6,33.8v0.2c0,20,14.3,33.5,34.6,33.5c12.9,0,21.9-5.3,28.1-13.1L916.2,66c-4.7,4.5-9,6.6-14.3,6.6
                C894.8,72.5,889.7,68.9,887.9,61.9 M887.5,50.3c1.2-7,5.3-11.7,11.2-11.7c6.2,0,10.4,4.5,11.2,11.7H887.5z M718.4,55.7v-0.2
                c0-18.4-14.9-33.5-35.6-33.5c-20.8,0-35.8,15.4-35.8,33.8v0.2c0,18.4,14.9,33.5,35.6,33.5C703.4,89.5,718.4,74.1,718.4,55.7
                 M696.3,55.9c0,7.8-5.3,14.3-13.5,14.3c-8,0-13.7-6.7-13.7-14.6v-0.2c0-7.8,5.3-14.3,13.5-14.3c8,0,13.7,6.7,13.7,14.6V55.9z
                 M829.4,41.3V41c0-1.8,1.8-3.1,5.3-3.1c5.1,0,12.4,2.3,19.1,6l7.8-14.3c-7.4-4.8-17.2-7.5-26.6-7.5c-14.9,0-25.9,7.6-25.9,21.4
                v0.2c0,13,10.1,17.3,22.9,20.6c6.8,1.9,10.4,3,10.4,5.5V70c0,2.3-1.8,3.5-5.7,3.5c-6.7,0-14.8-2.5-22.2-7.4l-8.5,13.7
                c9,6.4,19.7,9.5,30.6,9.5c15.8,0,26.4-7.4,26.4-21.6v-0.2c0-12.5-10.1-16.9-22.9-20.5C833.1,45,829.4,43.8,829.4,41.3
                 M748.1,87.9V41.5h13.8V24.1h-14.2v-1.5c0-4.2,2.3-6.1,6.4-6.1c3.2,0,5.8,0.5,8.5,1.3v-16c-3.6-1.1-7.9-1.8-14.8-1.8
                c-7.6,0-12.9,1.7-16.8,5.6c-3.8,3.8-5.5,9.3-5.5,16.7v1.4H718v17.8h7.5v46.4H748.1z M761.9,23.7v0.4h0.6v17.4h6.9v46.4H792V41.5
                h14.5V24.1h-14.8v-1.5c0-4.2,2.3-6.1,6.4-6.1c3.2,0,5.9,0.5,8.5,1.3v-16C803,0.7,798.7,0,791.8,0c-7.6,0-12.9,1.7-16.8,5.6
                c-3.8,3.8-5.5,9.3-5.5,16.7v1.4H761.9z M626.9,87.9v-20c0-14.9,6.7-21.7,18.5-21.7h1.9V22.3c-10.7-0.5-16.7,5.3-20.4,14.1v-13
                h-22.7v64.5H626.9z M570.6,21.9c-9.7,0-15.2,4.7-19.6,10v-8.6h-4.5l-18.2,21V107H551V80c4.2,4.9,9.9,9.3,19.7,9.3
                c15.3,0,28.5-12.8,28.5-33.8v-0.2C599.2,34.7,586,21.9,570.6,21.9 M576.9,55.7c0,8.7-5.9,14.7-13.3,14.7
                c-7.3,0-13.1-6.1-13.1-14.7v-0.2c0-8.5,5.8-14.6,13.1-14.6c7.4,0,13.3,6.1,13.3,14.6V55.7z"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
}
