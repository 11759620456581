/* eslint-disable max-len */
const programName = 'Efva Attling'
export default {
  programName,
  logo: (
    <svg clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 290 63">
      <title>{programName}</title>
      <g transform="matrix(.965145 0 0 .633404 0 -.568455)">
        <path className="no-fill" d="m0 .897h299.646v98.423h-299.646z" fill="none" />
        <g fillRule="nonzero" transform="matrix(1.03611 0 0 1.57877 .622889 1.956311)">
          <path d="m21.283 28.922h-21.196c-.116 0-.116-.579 0-.579h3.243c.116 0 .232 0 .232-.116v-26.635c0-.115-.116-.115-.232-.115h-3.243c-.116-.116-.116-.695 0-.695h19.343c.116 0 .116.116.116.116v7.411c0 .116-.58.116-.58 0 0-3.706-1.853-6.832-6.486-6.832h-4.285c-.232 0-.232 0-.232.115v12.623h1.506c3.011 0 4.749-1.737 4.749-4.516 0-.116.463-.116.463 0v9.727c0 .116-.463.116-.463 0 0-2.663-1.738-4.516-4.749-4.516h-1.622v13.317c0 .116.116.116.232.116h4.285c6.255 0 8.456-3.59 8.456-7.643 0-.116.579-.116.579 0v8.222c0-.116 0 0-.116 0z" />
          <path d="m44.795 8.193c0-3.706-1.968-6.832-6.717-6.832h-4.054c-.232 0-.232 0-.232.115v12.507h1.39c3.012 0 4.749-1.737 4.749-4.516 0-.116.579-.116.579 0v9.612c0 .116-.579.116-.579 0 0-2.664-1.737-4.517-4.749-4.517h-1.39v13.318c0 .116.116.116.232.116h3.359c.116 0 .116.579 0 .579h-11.467c-.116 0-.116-.579 0-.579h3.359c.116 0 .232 0 .232-.116v-26.288c0-.116-.116-.116-.232-.116h-3.359c-.116 0-.116-.579 0-.579h19.343c.116 0 .116.116.116.116v7.18c0 .116-.58.116-.58 0z" />
          <path d="m74.678 1.361c-.347 0-.347.115-.926 1.621l-8.687 26.403c0 .116-.579.116-.579 0l-11.583-27.793c0-.116-.116-.116-.347-.116h-3.475c-.116 0-.116-.579 0-.579h12.509c.116 0 .116.579 0 .579h-3.706c-.232 0-.232 0-.232.116l8.803 21.887 6.718-20.381c.463-1.506.463-1.622.231-1.622h-3.359v-.579h7.761c.116 0 .116.579 0 .579h-3.128z" />
          <path d="m84.292 6.109-4.401 12.854h8.687zm4.054 22.697c-.116 0-.116-.579 0-.579h3.359c.116 0 .232-.115.116-.115l-2.896-8.57h-9.034l-2.432 6.948c-.464 1.39-.464 1.622-.348 1.622h3.243c.116 0 .116.579 0 .579h-7.413v-.579h2.896c.348 0 .463-.116.927-1.622l9.034-26.403c0-.116.579-.116.579 0l10.077 27.909s.116.116.232.116h3.243c.116 0 .116.579 0 .579h-11.583z" />
          <path d="m124.946 6.109-4.401 12.854h8.687zm3.822 22.697c-.115 0-.115-.579 0-.579h3.359c.116 0 .232-.115.116-.115l-2.895-8.57h-9.035l-2.432 6.948c-.463 1.39-.463 1.622-.348 1.622h3.243c.116 0 .116.579 0 .579h-7.297v-.579h2.896c.348 0 .463-.116.927-1.622l9.034-26.403c0-.116.579-.116.579 0l10.077 27.909s.116.116.232.116h3.243c.116 0 .116.579 0 .579h-11.699z" />
          <path d="m162.127 9.467c0-4.632-2.317-7.99-7.761-7.99h-.347c-.116 0-.232 0-.232.115v26.52c0 .115.116.115.232.115h3.243c.116 0 .116.579 0 .579h-11.351c-.116 0-.116-.579 0-.579h3.243c.116 0 .232 0 .232-.115v-26.52c0-.115-.116-.115-.232-.115h-.463c-5.56 0-7.529 3.474-7.529 7.99 0 .116-.579.116-.579 0v-8.569s0-.116.116-.116h21.891c.116 0 .116.116.116.116v8.569z" />
          <path d="m188.766 9.467c0-4.632-2.316-7.99-7.76-7.99h-.463c-.116 0-.232 0-.232.115v26.52c0 .115.116.115.232.115h3.243c.116 0 .116.579 0 .579h-11.235c-.116 0-.116-.579 0-.579h3.243c.116 0 .231 0 .231-.115v-26.52c0-.115-.115-.115-.231-.115h-.464c-5.559 0-7.528 3.474-7.528 7.99 0 .116-.579.116-.579 0v-8.569s0-.116.115-.116h21.891c.116 0 .116.116.116.116v8.569z" />
          <path d="m214.943 28.806h-20.385c-.116 0-.116-.579 0-.579h3.359c.115 0 .231 0 .231-.115v-26.52c0-.116-.116-.116-.231-.116h-3.359c-.116 0-.116-.579 0-.579h11.466c.116 0 .116.579 0 .579h-3.359c-.115 0-.231 0-.231.116v26.52c0 .115.116.115.231.115h4.054c5.676 0 7.645-3.937 7.645-7.99 0-.116.579-.116.579 0z" />
          <path d="m218.766 28.806c-.116 0-.116-.579 0-.579h3.243c.116 0 .231 0 .231-.115v-26.52c0-.116-.115-.116-.231-.116h-3.243c-.116 0-.116-.579 0-.579h11.466c.116 0 .116.579 0 .579h-3.359c-.115 0-.231 0-.231.116v26.52c0 .115.116.115.231.115h3.359c.116 0 .116.579 0 .579z" />
          <path d="m256.524 1.361c-.116 0-.231 0-.231.115v27.794c0 .115-.464.115-.58 0l-17.258-27.446v26.172c0 .116 0 .116.116.116h3.359v.579h-7.76c-.116 0-.116-.579 0-.579h3.359c.116 0 .231 0 .231-.116v-26.404c0-.116 0-.116-.231-.116h-3.359c-.116 0-.116-.579 0-.579h8.108c.231 0 .463.116.926.695l12.394 19.919v-19.919c0-.116-.116-.116-.232-.116h-3.243c-.116 0-.116-.579 0-.579h7.644v.579h-3.243z" />
          <path d="m284.554 18.268c-.116 0-.232 0-.232.116v10.654c0 .116-.695.116-.695 0 0-.811-.347-1.621-1.39-1.621-1.737 0-3.127 2.2-7.297 2.2-7.297 0-11.814-7.064-11.814-14.707 0-7.875 5.096-14.707 12.277-14.707 2.085 0 3.359 1.042 4.749 1.042 1.043 0 1.738-.463 1.854-.927 0-.115.579-.115.579 0v8.223c0 .115-.579.115-.579 0 0-3.938-2.78-7.759-6.603-7.759-1.737 0-3.358.347-4.633 1.505-1.969 1.853-3.011 5.906-3.011 12.623 0 7.296.347 10.77 2.432 12.623 1.159 1.158 3.012 1.505 4.633 1.505 3.359 0 5.097-1.505 5.097-3.706v-6.832c0-.116-.116-.116-.232-.116h-3.243c-.116 0-.116-.579 0-.579h11.467c.115 0 .115.579 0 .579h-3.359z" />
          <path d="m40.047 55.094c.116 3.011 2.085 4.169 4.98 4.169 2.78 0 4.865-1.39 4.865-3.59 0-5.443-10.54-2.084-10.54-8.917 0-3.242 3.127-4.516 5.675-4.516 3.012 0 5.328 1.042 6.255 3.937l-1.506.926c-.695-2.316-2.548-3.242-4.749-3.242-1.853 0-3.938 1.042-3.938 3.011 0 5.211 10.54 1.853 10.54 8.685 0 3.474-2.432 5.443-6.486 5.443-3.59 0-6.023-1.274-6.602-4.864z" />
          <path d="m61.358 42.703v1.506h5.56v16.212h1.622v-16.212h5.559v-1.506z" />
          <path d="m91.589 59.263c4.17 0 5.907-4.053 5.907-7.759s-1.737-7.759-5.907-7.759-5.907 4.053-5.907 7.759c-.116 3.706 1.621 7.759 5.907 7.759zm0-17.023c5.212 0 7.644 4.632 7.644 9.264 0 4.748-2.432 9.265-7.644 9.265s-7.645-4.633-7.645-9.265 2.317-9.264 7.645-9.264z" />
          <path d="m122.63 47.682c-.695-2.431-1.969-3.821-5.212-3.821-3.938 0-5.907 3.127-5.907 7.759 0 5.674 2.664 7.759 6.139 7.759 2.779 0 4.169-1.39 4.98-3.706l1.622.926c-1.274 3.127-3.591 4.401-6.95 4.401-4.17 0-7.529-2.664-7.529-9.264 0-6.717 3.591-9.265 7.413-9.265 3.475 0 5.676 1.274 7.066 4.517z" />
          <path d="m146.259 42.703-9.613 9.844v-9.844h-1.738v17.718h1.738v-5.674l3.822-3.822 6.602 9.496h2.085l-7.529-10.77 6.95-6.948z" />
          <path d="m171.625 42.703v7.759h-10.54v-7.759h-1.737v17.718h1.737v-8.338h10.54v8.338h1.738v-17.718z" />
          <path d="m192.473 59.263c4.169 0 5.907-4.053 5.907-7.759s-1.738-7.759-5.907-7.759c-4.17 0-5.907 4.053-5.907 7.759s1.737 7.759 5.907 7.759zm0-17.023c5.212 0 7.644 4.632 7.644 9.264 0 4.748-2.432 9.265-7.644 9.265-5.213 0-7.645-4.633-7.645-9.265s2.432-9.264 7.645-9.264z" />
          <path d="m211.7 42.703v17.718h11.467v-1.621h-9.729v-16.097z" />
          <path d="m247.375 42.703-5.676 14.823h-.115l-5.676-14.823h-2.085v17.718h1.738v-14.475l5.559 14.475h1.043l5.559-14.475v14.475h1.738v-17.718z" />
        </g>
      </g>
    </svg>
  ),
}
