/* eslint-disable max-len */
const programName = 'Boomerang'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 939 78">
      <title>{programName}</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="white">
          <path d="M12.637,43.362 L12.637,64.908 L38.12,64.908 C45.9,64.908 49.986,59.951 49.986,53.947 C49.986,48.224 45.9,43.362 38.12,43.362 L12.637,43.362 Z M12.637,13.137 L12.637,33.544 L35.592,33.544 C42.886,33.544 46.777,28.871 46.777,23.34 C46.777,17.715 42.983,13.137 35.395,13.137 L12.637,13.137 Z M0.089,1.794 L36.078,1.794 C51.543,1.794 59.519,10.659 59.519,21.626 C59.519,29.347 55.531,34.975 49.111,38.025 C57.476,40.887 62.825,47.18 62.825,55.756 C62.825,67.296 53.78,76.164 39.381,76.164 L0.089,76.164 L0.089,1.794 Z" />
          <path d="M135.167,65.581 C150.34,65.581 162.4,53.663 162.4,38.978 C162.4,24.297 150.34,12.376 135.167,12.376 C120.285,12.376 108.026,24.297 108.026,38.978 C108.026,53.663 120.285,65.581 135.167,65.581 Z M135.167,0.363 C157.148,0.363 175.142,17.715 175.142,38.978 C175.142,60.24 157.148,77.595 135.167,77.595 C113.377,77.595 95.287,60.24 95.287,38.978 C95.287,17.715 113.377,0.363 135.167,0.363 Z" />
          <path d="M247.331,65.581 C262.504,65.581 274.564,53.663 274.564,38.978 C274.564,24.297 262.504,12.376 247.331,12.376 C232.447,12.376 220.19,24.297 220.19,38.978 C220.19,53.663 232.447,65.581 247.331,65.581 Z M247.331,0.363 C269.315,0.363 287.306,17.715 287.306,38.978 C287.306,60.24 269.315,77.595 247.331,77.595 C225.541,77.595 207.451,60.24 207.451,38.978 C207.451,17.715 225.541,0.363 247.331,0.363 Z" />
          <polyline points="326.411 76.164 326.411 1.794 338.861 1.794 366.388 51.18 393.915 1.794 406.363 1.794 406.363 76.164 393.815 76.164 393.815 24.771 370.571 66.244 362.205 66.244 338.958 24.771 338.958 76.164 326.411 76.164" />
          <polyline points="451.9 1.794 507.15 1.794 507.15 13.807 464.447 13.807 464.447 34.02 500.242 34.02 500.242 45.273 464.447 45.273 464.447 64.245 507.15 64.245 507.15 76.164 451.9 76.164 451.9 1.794" />
          <path d="M564.073,13.137 L564.073,41.742 L586.152,41.742 C596.657,41.742 601.714,35.07 601.714,27.443 C601.714,19.719 596.657,13.137 586.152,13.137 L564.073,13.137 Z M586.735,1.794 C604.731,1.794 613.973,13.523 613.973,27.348 C613.973,37.741 608.525,47.369 597.828,51.18 L616.015,76.164 L600.842,76.164 L584.109,52.995 L564.073,52.995 L564.073,76.164 L551.526,76.164 L551.526,1.794 L586.735,1.794 Z" />
          <path d="M671.586,51.086 L701.056,51.086 L686.271,16.187 L671.586,51.086 Z M692.691,1.794 L725.371,76.164 L711.658,76.164 L705.821,62.339 L666.818,62.339 L660.981,76.164 L647.171,76.164 L679.946,1.794 L692.691,1.794 Z" />
          <polyline points="760.692 76.164 760.692 1.794 771.489 1.794 814.677 54.901 814.677 1.794 827.225 1.794 827.225 76.164 816.428 76.164 773.24 23.151 773.24 76.164 760.692 76.164" />
          <path d="M903.307,35.354 L938.225,35.354 L938.225,58.241 C929.377,72.55 914.004,77.595 903.89,77.595 C882.006,77.595 864.01,60.24 864.01,38.978 C864.01,17.715 882.006,0.363 903.89,0.363 C916.924,0.363 928.595,6.559 935.891,15.998 L925.58,22.867 C920.523,16.479 912.741,12.376 903.89,12.376 C888.911,12.376 876.752,24.297 876.752,38.978 C876.752,53.663 888.911,65.581 903.89,65.581 C913.326,65.581 921.592,60.813 926.36,53.471 L926.36,45.749 L903.307,45.749 L903.307,35.354" />
        </g>
      </g>
    </svg>
  ),
}
