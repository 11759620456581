/* eslint-disable max-len */
const programName = 'Tretti'
export default {
  programName,
  logo: (
    <svg className="tretti" viewBox="0 0 120.651 80">
      <title>{programName}</title>
      <path d="M100.45 1.64l.615 3.092c1.152 5.789 10.227 7.223 12.476 18.532 2.249 11.311-5.585 16.11-4.434 21.898l.615 3.091-59.799 11.894L36.653 80l-3.285-16.562-19.873 3.953-.615-3.091C11.728 58.509 2.653 57.074.404 45.766c-2.25-11.309 5.585-16.11 4.434-21.897l-.615-3.091 96.23-19.14" />
      <path className="st0" d="M37.42 28.735c-2.387.474-3.657 2.378-3.999 5.8l-.855-4.3-6.212 1.24 4.578 23.03 6.589-1.307-1.87-9.396c-.986-4.956.262-7.728 3.768-8.425.094-.019.375-.071.615-.071l-1.333-6.732c-.527.054-.956.091-1.282.156M26.156 50.355c-2.671.533-3.275.169-3.805-2.497l-1.934-9.72 4.952-.985-1.049-5.28-4.954.986-1.654-8.318-6.78 1.344 1.659 8.321-3.648.725L10 40.209l3.638-.724 2.144 10.745c1.093 5.514 3.547 7.116 9.297 5.972.889-.176 1.887-.516 4.2-1.37l-1.01-5.05c-.876.269-1.563.455-2.119.569m65.826-13.09c-2.663.523-3.27.16-3.802-2.502l-1.931-9.724 4.951-.982-1.048-5.283-4.957.986-1.655-8.318-6.778 1.351 1.658 8.318-3.642.721v.003l-3.02.602-1.658-8.32-6.773 1.347 1.654 8.318-3.641.728 1.047 5.28 3.645-.725 2.138 10.749c1.096 5.513 3.555 7.119 9.301 5.968.892-.175 1.892-.521 4.203-1.37l-1.01-5.04c-.866.268-1.563.456-2.122.567-2.66.529-3.27.163-3.803-2.499l-1.93-9.721 3.02-.603v.002l3.645-.724 2.134 10.747c1.1 5.512 3.558 7.118 9.301 5.971.889-.174 1.892-.522 4.2-1.366l-1-5.05c-.869.276-1.566.46-2.123.574m6.972-20.242l-6.772 1.347 4.583 23.04 6.778-1.352-4.589-23.04m-.39-1.962l-1.264-6.355-6.774 1.343 1.265 6.363 6.772-1.351m-44.03 30.23c-2.616.52-4.827-1.08-5.399-3.93-.059-.326-.149-.748-.191-1.227l14.814-2.946c-.854-4.059-1.703-5.876-3.516-7.898-2.324-2.65-6.154-3.827-9.893-3.083-6.685 1.326-10.226 7.185-8.782 14.478 1.409 7.05 6.869 10.821 13.736 9.458 4.929-.98 7.737-3.829 8.683-8.822l-6.285 1.253c-.442 1.452-1.505 2.39-3.167 2.718m-3.065-14.693c2.477-.495 3.874.685 4.867 3.938l-8.185 1.629c-.312-3.288.743-5.058 3.318-5.567" />
      <path d="M113.63 3.831l1.38-.278c.565-.106.918.067 1.03.624.116.582-.147.883-.704.997l-1.386.279-.32-1.622m-.319 5.01l1.266-.253-.446-2.24 1.256-.251c.637-.127.925.087 1.134.679.155.443.257 1 .484 1.337l1.255-.258c-.29-.269-.42-.947-.515-1.303-.153-.576-.448-1.14-1.101-1.192l-.004-.008c.582-.379.757-.949.624-1.621-.176-.863-.969-1.452-1.998-1.247l-3.096.616 1.141 5.742" />
      <path d="M113.92.111c-3.038.595-5.02 3.571-4.415 6.619.608 3.041 3.572 5.03 6.619 4.417a5.62 5.62 0 0 0 4.42-6.609c-.608-3.056-3.577-5.04-6.624-4.427m2.02 10.08a4.647 4.647 0 0 1-5.472-3.659 4.646 4.646 0 0 1 3.653-5.468c2.513-.503 4.96 1.136 5.469 3.66a4.649 4.649 0 0 1-3.65 5.466" />
    </svg>
  ),
}
