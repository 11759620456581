/* eslint-disable max-len */
const programName = 'Conrad'
export default {
  programName,
  logo: (
    <svg id="conrad" viewBox="0 0 1000 250">
      <title>{programName}</title>
      <path fill="none" d="M0 0h1000v250H0z" />
      <g fill="#0098dd">
        <path d="M186.5 206.2H140c-49 0-67.3-16-67.3-59 0-66.7 31.5-103.4 88.6-103.4H223L232.7 1h-86.1c-49.8 0-80 8.4-101.4 28.2C17.2 55.3 0 104.5 0 158.9c0 67.2 24.3 90 96 90h81l9.5-42.7zM296.3 192.9h50.3l20.1-90.2 44 90.2H463l30.7-136h-50.3l-17.8 78.4L389 56.9h-62.3l-30.4 136z" />
        <path d="M889.1 91.8h30c21 0 26.9 4.6 26.9 21.5 0 27.4-13.9 44.7-36 44.7h-35.8l15-66.2zM813.8 193h109.7c50 0 76.5-30 76.5-86.3 0-35-15.7-49.7-52.5-49.7H844.3l-30.5 136zM633.8 175.8l-20.3-25.9c31.2-6.7 47.6-24.3 47.6-51.6 0-29.5-17.2-41.4-60.3-41.4H511l-30.6 136H533l22.8-101h33.5c15 0 22.8 4.5 22.8 13.1 0 12.3-7.1 18.5-21.7 18.5h-36.8l25.2 43.5c14.8 25.1 16 25.9 45.6 25.9h52.7l16.4-24.4h55.6l-5.5 24.4h52.8l30.6-136h-63.5c-44.7 0-46.4 1-75.4 42l-54.5 76.9zM755 135.3h-40.4l15.7-24C741.6 94 745 91.9 761.2 91.9h3.6l-9.7 43.5z" fillRule="evenodd" clipRule="evenodd" />
      </g>
      <path d="M181.9 88h32l6.9-31.1h-57c-50.2 0-77.5 31.8-77.5 90.5 0 33.8 14.4 45.5 56.3 45.5h47.6l7.4-32.8H170a234 234 0 0 1-12-.6c-2.2-.1-3.4-.3-5.5-.8l-2.8-.6-3-.9c-7.9-2.6-11.5-8.4-11.6-20.3.2-31.5 17-48.8 46.9-48.8z" fill="#ffe600" />
      <path d="M242.3 56.9H220l-6.8 30.5h-32.1c-30.1 0-47 17.6-47 49.6 0 16.8 7 21.4 22.3 22.5l4.6.3c-16.4-1.3-22-7-22-21.4 0-29.3 15.6-45.4 42.7-45.4h34.5c21.5 0 27.1 4.2 27.1 20.3 0 30.5-13.8 46.8-39.4 46.8h-7.1l-7.4 32.8h30c50.8 0 79.8-31 79.8-85.7 0-36.9-15.2-50.3-57-50.3z" fill="#0098dd" />
    </svg>
  ),
}
