/* eslint-disable max-len */
const programName = 'Artwood'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 486.06 129.23">
      <title>{programName}</title>
      <path d="M111.64,267.06l16-36.54h.81l16,36.54h-5.87l-4-9.7h-16l-4,9.7Zm7.7-11.75h14.34l-7.12-17.46Z" transform="translate(-35.14 -230.52)" />
      <path d="M167.79,250v17h-5.28V231.38h11.64a15.23,15.23,0,0,1,8.92,2.43,7.47,7.47,0,0,1,3.47,6.39,7.67,7.67,0,0,1-2.77,6.25,16.3,16.3,0,0,1-7.52,3.15l14.44,17.46-6.25.75L171.94,250Zm0-1.62h3.45q9.69,0,9.7-7.65a8,8,0,0,0-.65-2.91,7.14,7.14,0,0,0-5.28-4.47,28.42,28.42,0,0,0-7.22.92Z" transform="translate(-35.14 -230.52)" />
      <path d="M221.73,267.06H216.5V234a15.77,15.77,0,0,0-3.66-.43l-7.28,1.67-.32-3.83H233l-.27,3.83-7.33-1.67a15.77,15.77,0,0,0-3.66.43Z" transform="translate(-35.14 -230.52)" />
      <path d="M289.09,231.38h2.7l-13.37,36.49h-.86l-8.25-22.1-8.08,22.1h-.86l-13.64-36.49h5.88l9.48,29.21,6-18-4.21-11.21h5.88l9.48,29.21Z" transform="translate(-35.14 -230.52)" />
      <path d="M326.06,230.74a15.61,15.61,0,0,1,11.58,4.61q4.59,4.6,4.58,12.79a21.84,21.84,0,0,1-1.83,9.25,15.25,15.25,0,0,1-4.79,6.11,21.09,21.09,0,0,1-12.72,4.21,15.44,15.44,0,0,1-11.51-4.66q-4.55-4.67-4.55-12.86a22.84,22.84,0,0,1,1.21-7.68,16.13,16.13,0,0,1,3.18-5.57,18.3,18.3,0,0,1,4.56-3.62A21.18,21.18,0,0,1,326.06,230.74ZM319,264.61a11,11,0,0,0,5.47,1.32,10,10,0,0,0,5.52-1.56,10.84,10.84,0,0,0,3.77-4.1,22.42,22.42,0,0,0,2.75-10.89q0-7.15-2.85-11.53-3.18-4.9-10.51-4.9a14.47,14.47,0,0,0-7.92,6.3,19.21,19.21,0,0,0-2.7,10.13,22.63,22.63,0,0,0,2.7,11.54A9.89,9.89,0,0,0,319,264.61Z" transform="translate(-35.14 -230.52)" />
      <path d="M377.79,230.74a15.64,15.64,0,0,1,11.59,4.61Q394,240,394,248.14a21.84,21.84,0,0,1-1.83,9.25,15.35,15.35,0,0,1-4.8,6.11,21,21,0,0,1-12.72,4.21,15.42,15.42,0,0,1-11.5-4.66q-4.56-4.67-4.56-12.86a22.83,22.83,0,0,1,1.22-7.68,16,16,0,0,1,3.17-5.57,18.48,18.48,0,0,1,4.56-3.62A21.18,21.18,0,0,1,377.79,230.74Zm-7.06,33.87a11.07,11.07,0,0,0,5.47,1.32,10,10,0,0,0,5.53-1.56,11,11,0,0,0,3.77-4.1,22.52,22.52,0,0,0,2.74-10.89q0-7.15-2.85-11.53-3.18-4.9-10.51-4.9a14.54,14.54,0,0,0-7.92,6.3,19.21,19.21,0,0,0-2.7,10.13,22.63,22.63,0,0,0,2.7,11.54A9.89,9.89,0,0,0,370.73,264.61Z" transform="translate(-35.14 -230.52)" />
      <path d="M413.3,267.06V231.38h10.19a24.25,24.25,0,0,1,11.48,2.7,18,18,0,0,1,4.79,3.5,15.41,15.41,0,0,1,3.29,5.36,20.2,20.2,0,0,1,1.24,7.25,15.94,15.94,0,0,1-2.86,9.65q-5.22,7.21-16.92,7.22Zm10.13-1.83q6.85,0,11-4t4.15-11.86A16.48,16.48,0,0,0,435.29,239a15.54,15.54,0,0,0-8.51-5.69,26.58,26.58,0,0,0-8.2,1.14v30.28A18.51,18.51,0,0,0,423.43,265.23Z" transform="translate(-35.14 -230.52)" />
      <line x1="76.81" y1="62.06" x2="153.6" y2="62.06" />
      <line x1="331.63" y1="62.35" x2="408.42" y2="62.35" />
      <path d="M207.23,296.09l1.27-.73a3.51,3.51,0,0,0,.77.77,2.15,2.15,0,0,0,2.19.06,1.07,1.07,0,0,0,.4-1c0-.47-.41-1-1.23-1.67s-1.48-1.18-2-1.63a2.36,2.36,0,0,1-.73-1.83,2.1,2.1,0,0,1,.85-1.8,3,3,0,0,1,1.8-.63,3.38,3.38,0,0,1,1.57.33,3.09,3.09,0,0,1,1,.85l-1.27.94a3.15,3.15,0,0,0-.69-.41,2,2,0,0,0-.59-.08,1.41,1.41,0,0,0-.72.23.76.76,0,0,0-.36.69c0,.31.31.71.94,1.2s1.28,1.05,2,1.68a3,3,0,0,1,1,2.24,2.49,2.49,0,0,1-.88,2,3.28,3.28,0,0,1-2.18.75,3.08,3.08,0,0,1-2.1-.72A4.55,4.55,0,0,1,207.23,296.09Z" transform="translate(-35.14 -230.52)" />
      <path d="M222.93,298.09V287.65h1.63v10.44Z" transform="translate(-35.14 -230.52)" />
      <path d="M234.34,298.09V287.65h.41l5.5,6.44v-6.44h1.68v10.44h-.62L236,291.65v6.44Z" transform="translate(-35.14 -230.52)" />
      <path d="M259.46,288.55l-.9,1.22a4,4,0,0,0-2-.49,3.59,3.59,0,0,0,0,7.18,4.11,4.11,0,0,0,2-.49l.9,1.22a5.22,5.22,0,1,1,0-8.64Z" transform="translate(-35.14 -230.52)" />
      <path d="M268.83,298.09V287.65h5.38v1.63h-3.75v3.22H274v1.63h-3.51v2.33h3.75v1.63Z" transform="translate(-35.14 -230.52)" />
      <path d="M297.28,290.59l2.94-2.94h.33v10.44h-1.63v-6.85l-.48.5Z" transform="translate(-35.14 -230.52)" />
      <path d="M315,287.65a3.14,3.14,0,0,1,3.12,3.12,7.85,7.85,0,0,1-5.1,7.32l-.65-1.47a6.55,6.55,0,0,0,3.34-2.81,3.51,3.51,0,0,1-.71.08,3.12,3.12,0,0,1,0-6.24Zm0,1.63a1.5,1.5,0,1,0,1.05.44A1.44,1.44,0,0,0,315,289.28Z" transform="translate(-35.14 -230.52)" />
      <path d="M330.28,298.09a3.12,3.12,0,0,1-3.11-3.12,7.82,7.82,0,0,1,5.09-7.32l.65,1.47a6.59,6.59,0,0,0-3.34,2.81,3.59,3.59,0,0,1,.71-.08,3,3,0,0,1,2.21.92,3.11,3.11,0,0,1,0,4.4A3,3,0,0,1,330.28,298.09Zm0-1.63a1.44,1.44,0,0,0,1.05-.44,1.47,1.47,0,0,0,0-2.1,1.46,1.46,0,0,0-2.09,0,1.47,1.47,0,0,0,0,2.1A1.43,1.43,0,0,0,330.28,296.46Z" transform="translate(-35.14 -230.52)" />
      <path d="M345.57,287.65a3,3,0,0,1,2.2.92,3,3,0,0,1,.92,2.2,7.82,7.82,0,0,1-5.09,7.32l-.66-1.47a6.57,6.57,0,0,0,3.35-2.81,3.69,3.69,0,0,1-.72.08,3.12,3.12,0,0,1,0-6.24Zm0,1.63a1.45,1.45,0,0,0-1.05.44,1.47,1.47,0,0,0,0,2.1,1.45,1.45,0,0,0,1.05.44,1.44,1.44,0,0,0,1.05-.44,1.47,1.47,0,0,0,0-2.1A1.44,1.44,0,0,0,345.57,289.28Z" transform="translate(-35.14 -230.52)" />
      <path d="M38,344.38a8,8,0,0,1,5.82,2.1,7.81,7.81,0,0,1,0,11A8,8,0,0,1,38,359.57H35.14V344.38Zm-2,.9v13.41h1.94a7.15,7.15,0,0,0,5.17-1.82,6.93,6.93,0,0,0,0-9.78,7.15,7.15,0,0,0-5.17-1.81Z" transform="translate(-35.14 -230.52)" />
      <path d="M59.94,344.38v15.19h-.86V344.38Z" transform="translate(-35.14 -230.52)" />
      <path d="M80.17,344.38v.86H74.38v6.31h5.79v.86H74.38v7.16h-.86V344.38Z" transform="translate(-35.14 -230.52)" />
      <path d="M99.65,344.38v.86H93.86v6.31h5.79v.86H93.86v7.16H93V344.38Z" transform="translate(-35.14 -230.52)" />
      <path d="M120.07,344.38v.86h-6.73v6.31h6.73v.86h-6.73v6.3h6.73v.86h-7.59V344.38Z" transform="translate(-35.14 -230.52)" />
      <path d="M137.24,354.31H134.4v5.26h-.85V344.38h4a5,5,0,0,1,2.95,1.45,5,5,0,0,1-2.3,8.34l3.5,5.4h-1.06Zm-2.84-9.06v8.19H137a4.1,4.1,0,0,0,0-8.19Z" transform="translate(-35.14 -230.52)" />
      <path d="M162.51,344.38v.86h-6.74v6.31h6.74v.86h-6.74v6.3h6.74v.86h-7.6V344.38Z" transform="translate(-35.14 -230.52)" />
      <path d="M177,344.38l10.1,14.15V344.38H188v15.19h-1.32l-9.86-13.81v13.81H176V344.38Z" transform="translate(-35.14 -230.52)" />
      <path d="M205.26,345.24v14.33h-.84V345.24h-3.85v-.86h8.54v.86Z" transform="translate(-35.14 -230.52)" />
      <path d="M245.9,348.41l3.78,9.78,3.7-13.81h.87l-4.08,15.19h-.86l-3.66-9.6-3.67,9.6h-.85L237,344.38h.87l3.72,13.81,3.77-9.78Z" transform="translate(-35.14 -230.52)" />
      <path d="M270.64,344.38l5.51,15.19h-.91l-2.42-6.68h-5.26l-2.47,6.68h-.91l5.57-15.19ZM272.5,352l-2.3-6.37L267.88,352Z" transform="translate(-35.14 -230.52)" />
      <path d="M291.68,353.43l-5.22-9.05h1l4.67,8.1,4.67-8.1h1l-5.22,9.05v6.14h-.86Z" transform="translate(-35.14 -230.52)" />
      <path d="M314.49,351.16a6.34,6.34,0,0,1-2.56-1.48,3.22,3.22,0,0,1,2.28-5.48,3.14,3.14,0,0,1,2.28.95,2.83,2.83,0,0,1,.72,1.12l-.78.31a2.21,2.21,0,0,0-.56-.85,2.24,2.24,0,0,0-1.66-.69,2.28,2.28,0,0,0-1.67.69,2.25,2.25,0,0,0-.69,1.67,2.35,2.35,0,0,0,.68,1.68,6.37,6.37,0,0,0,1.79,1.16,7.45,7.45,0,0,1,3.07,1.84,4.49,4.49,0,1,1-6.36,6.34,4.23,4.23,0,0,1-1.27-2.45l.81-.29a3.39,3.39,0,0,0,1.05,2.15,3.66,3.66,0,1,0,5.18-5.16A6.91,6.91,0,0,0,314.49,351.16Z" transform="translate(-35.14 -230.52)" />
      <path d="M360.85,346.45a7.8,7.8,0,1,1-5.52-2.28A7.51,7.51,0,0,1,360.85,346.45ZM355.33,345a6.93,6.93,0,1,0,4.9,2A6.66,6.66,0,0,0,355.33,345Z" transform="translate(-35.14 -230.52)" />
      <path d="M382.72,344.38v.86h-5.79v6.31h5.79v.86h-5.79v7.16h-.86V344.38Z" transform="translate(-35.14 -230.52)" />
      <path d="M412.07,359.57V344.38h.86v14.33h5.79v.86Z" transform="translate(-35.14 -230.52)" />
      <path d="M432.51,344.38v15.19h-.86V344.38Z" transform="translate(-35.14 -230.52)" />
      <path d="M455.43,344.38h.9l-5.46,15.19h-.53l-5.45-15.19h.9L450.61,358Z" transform="translate(-35.14 -230.52)" />
      <path d="M469.56,344.38v15.19h-.85V344.38Z" transform="translate(-35.14 -230.52)" />
      <path d="M484.2,344.38l10.09,14.15V344.38h.86v15.19h-1.32L484,345.76v13.81h-.86V344.38Z" transform="translate(-35.14 -230.52)" />
      <path d="M521.13,357.68a7.21,7.21,0,0,1-5.17,2.08,7.78,7.78,0,1,1,5.23-13.53l-.65.58a6.9,6.9,0,1,0-.26,10.57v-4.63h.85Z" transform="translate(-35.14 -230.52)" />
    </svg>
  ),
}
