/* eslint-disable max-len */
const programName = 'Åhlens'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 98 22">
      <title>{programName}</title>
      <g fill="#DF1B12">
        <path d="M42 19.2c-.2 0-.3-.1-.3-.3V6.5h-2.8v15.3h10.4v-2.5l-7.3-.1z" />
        <path d="M64.1 19.2h-8.2c-.2 0-.3-.1-.3-.3v-3.6h6.2v-2.5h-6.2V9.5c0-.2.1-.3.3-.3h7.7V6.6h-4.2L62 3.3h-3s-1.4 1.8-2.6 3.1c-.1.1-.1.2-.3.2h-3.4v15.3h11.4v-2.7z" />
        <path d="M78.7 16.8l.1.1v-.1V6.6h2.8v15.3h-2.5c-.2 0-.4-.1-.6-.3l-7.6-10-.1-.1V21.8H68V6.5h2.6c.2 0 .3.1.3.1l7.8 10.2z" />
        <path d="M31.3 12.3c0 .2-.1.3-.3.3h-7.1c-.2 0-.3-.1-.3-.3V6.5h-2.8v15.3h2.8v-6.3c0-.2.1-.3.3-.3H31c.2 0 .3.1.3.3v6.3h2.8V6.5h-2.8v5.8z" />
        <path d="M6.3 15.3l2.2-4.8s.1-.3.3-.3c.3 0 .3.3.3.3l2.3 4.8H6.3zm4.3-9.5c-.4.4-1 .7-1.7.7-1.3 0-2.3-1-2.3-2.3 0-.6.3-1.2.7-1.6.4-.4 1-.7 1.7-.7.6 0 1.2.3 1.7.7.4.4.7 1 .7 1.6s-.4 1.2-.8 1.6zM0 21.8h3.2l1.4-3.2s.2-.5.4-.6c.2-.1.6-.1.6-.1h6.1s.5-.1.7.1c.3.2.4.6.4.6l1.5 3.2h3.2L11 7.8c.3-.1.5-.3.8-.6.8-.8 1.2-1.9 1.2-3C13 1.9 11.2 0 8.8 0c-1.1 0-2.2.4-3 1.2-.8.9-1.2 1.9-1.2 3s.4 2.2 1.2 3c.2.2.5.4.8.5L0 21.8z" />
        <path d="M91.9 22c-3.1 0-5.5-1.2-6.8-2.6l1.7-2c1.3 1.2 3 2 5 2 1.3 0 3-.3 3-1.9 0-1.4-1.7-1.7-3.2-2-1-.2-5.8-1-5.8-4.6 0-3 3-4.6 6-4.6 1.9 0 4.2.5 5.7 1.7l-1.3 2.1c-1.2-1-3.2-1.3-4.3-1.3-.9 0-2.9.4-2.9 1.8 0 1.3 1.7 1.9 3.5 2.1 4.3.8 5.5 2.5 5.5 4.6-.1 3.1-2.9 4.7-6.1 4.7z" />
      </g>
    </svg>
  ),
}
