/* eslint-disable max-len */
const programName = 'Nelly'
export default {
  programName,
  logo: (
    <svg viewBox="-206 385 198 24">
      <title>{programName}</title>
      <path fill="#FF9393" d="M-25.7 392l6.2 9h1.9l6.3-9v16.5H-8V385h-2.7l-7.8 11.4-7.8-11.4H-29v23.5h3.3V392zm-23.6-7c-2.1 0-4 .5-5.7 1.6-1.8 1.1-3.2 2.5-4.2 4.4-1 1.9-1.5 3.9-1.5 6.1 0 3.3 1.1 6.1 3.4 8.4 2.3 2.3 5.1 3.5 8.3 3.5s6-1.2 8.2-3.5 3.4-5.2 3.4-8.5c0-3.4-1.1-6.2-3.4-8.5s-5.1-3.5-8.5-3.5zm-21.9 17l-.3.3c-.9 1.2-1.9 2-3 2.6-1.1.6-2.3.9-3.6.9-1.1 0-2.2-.2-3.3-.6-.5-.2-.9-.5-1.4-.8-.4-.3-.8-.7-1.2-1.1-.8-.8-1.4-1.8-1.8-2.8-.4-1.1-.6-2.3-.6-3.5 0-1.7.4-3.2 1.1-4.5s1.6-2.4 2.9-3.2c1.3-.8 2.6-1.2 4.1-1.2 1.4 0 2.6.3 3.7.9 1.2.6 2.1 1.5 2.9 2.7l.3.4 2.3-1.9-.3-.4c-1-1.5-2.2-2.7-3.8-3.5-.8-.4-1.6-.7-2.4-.9-.9-.2-1.8-.3-2.7-.3-3.2 0-5.8 1.1-7.9 3.4s-3.2 5.1-3.2 8.4c0 3.2.9 5.9 2.6 8.1 2.1 2.7 4.9 4 8.5 4 1.9 0 3.6-.4 5.1-1.2 1.5-.8 2.8-1.9 3.8-3.4l.3-.4-2.1-2zm29.4-.5c-.7 1.4-1.7 2.4-3.1 3.2-1.4.8-2.8 1.1-4.3 1.1-1.2 0-2.2-.2-3.2-.6-1-.4-1.9-1-2.7-1.9-.8-.9-1.4-1.8-1.8-2.8-.4-1-.6-2.1-.6-3.4 0-1.7.4-3.2 1.1-4.5.8-1.3 1.8-2.4 3.1-3.2s2.7-1.2 4.2-1.2 2.9.4 4.3 1.2c1.3.8 2.4 1.9 3.1 3.2.7 1.3 1.1 2.8 1.1 4.5-.1 1.6-.4 3.1-1.2 4.4zm-54.9 3c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2c0-1-.9-2-2-2zm-19.4-19.5h-3.9l7.8 12.4v11.1h3.3v-11.1l7.5-12.4h-3.8l-5.4 8.8-5.5-8.8zm-16.7 0h-3.3v23.5h12v-3.1h-8.7V385zm-20.8 0h-3.3v23.5h12v-3.1h-8.7V385zm-12 0h-14v23.5h13.8v-3.1h-10.5v-7.8h8.5v-3.3h-8.5v-6h10.7V385zm-36.9 7.2l11.4 16.4h2.7V385h-3.3v16.6l-11.6-16.6h-2.7v23.4h3.5v-16.2z" />
    </svg>
  ),
}
