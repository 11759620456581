/* eslint-disable max-len */
const programName = 'Hestra'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 88 16">
      <title>{programName}</title>
      <path fill="black" stroke="none" d="M16.542 15.567h10.97V12.79h-8.187V9.468h6.117V6.691h-6.117V3.248h8.187V.47l-10.97.001v15.095zM67.682 5.692c0-3.121-2.205-5.22-5.486-5.22h-6.164V15.57h2.852v-4.565h2.369l3.02 4.565h3.577l-3.71-5.293c2.154-.68 3.542-2.475 3.542-4.584zM63.74 7.811c-.392.25-.855.394-1.354.394h-3.501V3.15h3.501A2.528 2.528 0 0163.74 7.81zM9.751 8.897h-6.97V.46H0v15.107h2.781V11.68h6.97v3.887h2.781V.46H9.751v8.437zM35.759 6.392c-.782-.193-1.762-.321-2.268-1.023-.53-.735-.152-1.66.508-2.161 1.482-1.12 3.238-.303 4.435.81l1.947-2.147A6.431 6.431 0 0038.749.703c-2.79-1.4-7.163-.786-8.27 2.53-.522 1.564-.192 3.373 1.078 4.477 1.131.984 2.616 1.234 4.022 1.586.602.152 1.197.31 1.624.795.545.616.642 1.47.154 2.148-.857 1.19-2.67 1.39-3.943.85-.754-.32-1.302-.837-1.845-1.417l-1.922 2.123c.15.183.361.367.436.443 1.525 1.536 3.928 1.998 6.013 1.654 2.272-.374 4.166-1.823 4.48-4.2.18-1.362-.187-2.748-1.209-3.705-.968-.904-2.35-1.284-3.608-1.595zM75.84.47h-4.693v15.097h2.793v-3.103h7.331l2.305 3.103h3.48L75.84.47zm-1.9 2.88h.561l4.703 6.333H73.94V3.35zM42.294 3.247h4.163V15.57h2.885V3.247H53.4V.466H42.294v2.781z" className="css-1jcgmi3" />
    </svg>
  ),
}
