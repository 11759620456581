/* eslint-disable max-len */
const programName = 'Under Armour'
export default {
  programName,
  logo: (
    <svg id="icon-ualogo" viewBox="0 0 100 60">
      <title>{programName}</title>
      <path d="M81.4,30c10.9-4.8,18.1-12,18.4-20c0,0-2.9-2.2-12.2-5.7c-8.2-3-14.4-3.7-14.4-3.7l0,17.8
    c0,2.7-1.1,5.4-3.1,7.8c-6.2-1.5-13-2.3-20.1-2.3c-7.2,0-14,0.8-20.2,2.3c-2-2.4-3.1-5.1-3.1-7.8l0-17.8c0,0-6.2,0.7-14.4,3.7
    C3,7.8,0.2,10,0.2,10c0.4,8,7.5,15.2,18.5,20C7.7,34.8,0.6,42,0.2,50c0,0,2.9,2.2,12.2,5.7c8.2,3,14.4,3.7,14.4,3.7l0-17.8
    c0-2.7,1.1-5.4,3.1-7.8c6.2,1.5,13,2.3,20.1,2.3c7.2,0,14-0.8,20.2-2.3c2,2.4,3.1,5.1,3.1,7.8l0,17.8c0,0,6.2-0.7,14.4-3.7
    C97,52.3,99.8,50,99.8,50C99.4,42,92.3,34.8,81.4,30L81.4,30z M50,34h-0.2c-6.5,0-11.7-1.6-15.6-4c3.9-2.4,9.1-4,15.6-4h0.4
    c6.5,0,11.7,1.6,15.6,4c-3.9,2.4-9.1,4-15.6,4H50L50,34z"
      />
    </svg>
  ),
}
