/* eslint-disable max-len */
const programName = 'Desigual'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 884 208">
      <title>{programName}</title>
      <g fill="#09120f">
        <path d="m51.72 0.95c9.5-0.33 19.01-0.16 28.5-0.06 0.2 52.78 0.04 105.57 0.08 158.36-9.41 0.11-18.83 0-28.24 0.07-0.28-14.11 0.32-28.24-0.38-42.33 0.05-38.68-0.04-77.36 0.04-116.04z" />
        <path d="m491.48 1.74c6.53-2.56 14.54-0.77 18.92 4.82 5.66 6.55 5.05 17.5-1.62 23.13-7.51 7.12-21.31 4.73-25.91-4.57-4.68-8.4-0.33-19.98 8.61-23.38z" />
        <path d="m789.55 21.7c14.48-7.74 31.11-10.76 47.43-10.49 15.67 0.07 31.35-0.12 47.02 0.1v147.59c-16.68 0.24-33.37 0.03-50.05 0.11-14.97 0.37-30.1-2.78-43.51-9.48-14.5-7.32-26.75-19.4-33.31-34.35-8.17-18.59-8.16-40.57-0.11-59.19 6.41-14.79 18.29-26.9 32.53-34.29m29.49 18.71c-6.88 1.64-13.63 4.24-19.29 8.54-8.92 6.28-14.88 16.3-16.76 26.99-1.87 11.55-0.41 24.06 6.03 34.03 6.03 9.65 16.11 16.33 27.01 19.23 12.28 3.65 25.21 2.15 37.81 2.56 0.36-30.99 0.06-61.99 0.16-92.98-11.66 0.33-23.49-1.02-34.96 1.63z" />
        <path d="m673.88 45.82c15.17-2.26 31.34 1.92 43.09 11.9 21.37 17.32 27.2 50.28 14.05 74.25-7.83 14.82-22.55 25.7-38.98 28.98-16.85 3.47-35.4 0.49-49.47-9.72-2.32-1.93-5.08-3.75-6.52-6.44 4.61-6.59 9.59-12.98 14.64-19.26 10.8 11.79 29.93 15.41 43.83 7.08 7.71-4.58 12.54-12.85 14.36-21.47-27.24-0.34-54.5-0.01-81.75-0.17-2.22-14.17 0.64-29.27 8.61-41.29 8.41-13.03 22.78-21.91 38.14-23.86m-7.5 28.48c-5.93 4.47-9.36 11.57-10.73 18.73 17.19-0.09 34.38 0.04 51.57-0.06 1.82 0.3 0.76-1.61 0.51-2.43-2.35-8.02-7.77-15.46-15.56-18.88-8.28-3.53-18.47-2.7-25.79 2.64z" />
        <path d="m152.95 46.37c16.07-3.16 33.78 1.33 45.55 12.93 10.46 9.67 16.22 23.63 17.55 37.65 1.69 16.77-2.47 34.58-13.59 47.54-14.26 17.25-40.85 23.54-60.79 12.78-4.58-2.19-8.02-5.95-11.83-9.16-0.1 3.7-0.07 7.39 0 11.08-9.54 0.17-19.11 0.21-28.65-0.04 0.02-36.77 0.04-73.55-0.01-110.32 9.54-0.23 19.12-0.24 28.67 0.02-0.09 3.37-0.11 6.74-0.02 10.11 6.37-6.21 14.25-11.05 23.12-12.59m-0.3 26.53c-6.4 1.39-12.28 5.14-16.18 10.4-9.64 12.7-8.83 32.35 2.34 43.88 5.63 5.96 13.99 9.23 22.18 8.44 9.97-0.67 19.1-7.24 23.39-16.19 6.35-12.67 4.33-29.65-6.3-39.43-6.5-6.56-16.5-8.99-25.43-7.1z" />
        <path d="m384.27 58.93c8.43-8.75 20.73-13.06 32.76-12.74 13.4-0.47 26.77 5.13 36.16 14.63 10.72 10.72 16.3 26.08 15.83 41.15 0.34 17.11-6.94 34.71-20.64 45.3-16.37 12.86-41.49 14.25-58.35 1.58-1.97-1.55-3.75-3.31-5.69-4.88-0.07 8.16-0.81 16.89 3.45 24.26 4.62 8.73 14.77 12.91 24.25 13.18 12.44 0.72 24.77-3.53 34.79-10.78 4.66 7.5 9.83 14.69 14.32 22.29-13.34 10.92-31.19 14.99-48.14 14.31-14.18 0.74-28.98-3.06-40.07-12.18-12.74-10.27-17.41-27.24-17.49-43.01-0.02-32.02 0-64.03-0.01-96.05 0-2.46-0.16-4.93 0.22-7.37 9.54 0.2 19.09-0.02 28.63 0.11 0.03 3.4 0.03 6.8-0.02 10.2m22.4 13.85c-7.74 1.52-14.7 6.57-18.52 13.47-6.8 11.92-4.7 28.57 5.81 37.69 10.36 9.45 27.92 9.01 37.86-0.86 11.82-11.02 11.79-31.83 0.11-42.95-6.48-6.55-16.3-9.14-25.26-7.35z" />
        <path d="m559.23 46.61c13.64-2.23 28.53-1.54 40.64 5.76 6.46 4.14 11.86 10.33 13.97 17.82 2.96 10.35 0.6 22.65-7.7 29.94-9.45 8.46-22.21 11.3-33.94 15.16-5.13 1.73-10.59 3.54-14.38 7.61-3.75 4.4-1.86 11.59 3.04 14.3 5.84 3.19 12.84 2.77 19.23 1.87 8.96-1.35 17.25-6 23.34-12.66 0.99-1.35 2.08 0.67 2.79 1.3 4.61 5.47 9.56 10.66 13.97 16.29-10.1 11.49-25.25 17.37-40.28 18.4-14.8 1.02-31.16-1.29-42.45-11.75-12.74-11.43-13.35-34.06-0.31-45.48 11.26-9.66 26.52-11.32 39.96-16.36 4.04-1.64 8.87-3.94 9.81-8.66 1.08-5.14-3.29-9.5-7.92-10.72-12.31-3.09-26.27 1.23-34.27 11.22-5.6-5.36-10.85-11.09-16.13-16.77 7.01-9.99 18.9-15.29 30.63-17.27z" />
        <path d="m483.27 48.39c9.48 0.1 18.97 0.09 28.46 0 0.15 36.83-0.03 73.67 0.1 110.5-9.54 0.2-19.09 0.13-28.63 0.04 0.06-36.84-0.08-73.69 0.07-110.54z" />
        <path d="m231.02 48.7c9.45-0.06 18.91 0.09 28.36-0.08 0.44 17.42 0.06 34.88 0.19 52.32 0.14 5.63-0.59 11.43 1.09 16.91 1.56 5.72 5.05 11.15 10.25 14.2 8.42 5.05 20.26 4.11 27.37-2.84 5.13-4.97 7.62-12.2 7.73-19.24-0.01-20.43-0.03-40.85 0.01-61.28 9.45-0.05 18.91 0.11 28.36-0.09 0.42 20.48 0.05 40.97 0.19 61.46 0.04 13.6-4.08 27.82-13.97 37.56-10.11 10.56-25.26 14.85-39.56 14.37-13.33-0.16-27.05-4.81-36.39-14.61-9.42-9.52-13.64-23.16-13.64-36.35 0-20.78-0.03-41.56 0.01-62.33z" />
        <path d="m14.47 125.67c6.02-1.45 12.82 0.1 17.2 4.61 7.82 7.07 7.51 21-0.85 27.54-8.8 7.86-24.52 4.31-28.93-6.7-1.85-5.63-2.1-12.53 1.55-17.54 2.48-3.93 6.52-6.83 11.03-7.91z" />
      </g>
    </svg>
  ),
}
