/* eslint-disable max-len */
const programName = 'Kellfri'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 649 158">
      <title>{programName}</title>
      <path fill="#132B44" fillRule="evenodd" d="M196.24 114.004l.08-.004H261l-.011.072h-64.761c-.13.76-.2 1.213-.2 2.132 0 10.46 8.508 17.124 18.999 17.124 6.818 0 12.795-2.984 16.147-8.363h26.974v.053h.011v18.62C246.68 151.667 232.23 158 214.031 158c-12.516 0-54.634-3.037-56.131-53.902 0-.128-.992-12.814 5.871-25.495 2.795-5.168 5.974-9.46 9.665-12.934 9.454-9.28 22.494-15.015 36.884-15.015 28.882 0 51.708 22.48 51.708 50.955 0 3.706-.403 8.529-1.157 12.013h-64.564l-1.244.068h1.23c-.02.11-.037.215-.054.314zM269.966 155v-29.204h9.101V32.97H268V3h56.354v122.796H332V155h-62.033zm73.651-.886v-29.125h9.05V32.416h-11.01V2.596l56.038-.068v122.461h7.61v29.125h-61.688zm73.003.188v-29.407h8.077V79.056h-8.247V51.941h8.94l-.01-2.694c-.193-17.3 2.762-26.901 13.14-36.306C454.297-1.358 480.878.139 485.73.324v34.963c-5.42-.222-9.231 1.43-12.126 4.187-3.867 3.671-3.05 11.797-2.974 12.154l.14.666h15.71v26.91h-16.607v45.691h7.81v29.407H416.62zm79.451-.302v-30.644h7.675V79.073H496V52h50.036v12.333l1.494-1.75c11.217-13.152 27.765-10.129 31.47-9.93v32.62c-13.56-.15-22.555.92-27.526 6.71-5.525 6.449-4.786 13.576-4.858 19.867-.015 1.273.05 9.662.05 10.669v.837h7.99V154h-58.584zM618.998 1C631.683 1 642 11.092 642 23.502 642 35.908 631.683 46 618.998 46 606.317 46 596 35.908 596 23.502 596 11.092 606.317 1 618.998 1zm-29.98 153.305v-30.133h8.244V79.355h-8.53V52.75h52.383v71.421h7.629v30.133h-59.725zm-389.14-61.758H221.6c-.286-7.276-5.34-11.44-10.86-11.44-5.524 0-10.574 4.164-10.86 11.44zM0 125.967h9.606V32.051H0V2.63h65.947V32.05h-9.421v27.714H68.38l22.203-27.714V2.212h58.242v29.84h-8.15L107.27 73.307l1.335.422c1.512.635 3.028 1.692 4.548 3.175 2.597 2.328 4.196 5.925 7.009 12.06l16.732 32.349c1.516 3.171 4.514 4.653 9.497 4.653h7.565v28.38h-31.889c-12.993 0-21.05-2.114-26.191-11.214l-22.494-43.59c-1.731-3.596-7.119-4.231-12.967-4.231h-3.89v30.655h8.948v28.38H0v-28.38z" />
    </svg>
  ),
}
