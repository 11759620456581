/* eslint-disable max-len */
const programName = 'Gant'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 138 16">
      <title>{programName}</title>
      <path fillRule="evenodd" clipRule="evenodd" d="M34.148 15.562h2.542l4.603-3.99h20.033l4.603 3.99h2.542L51.288 0l-17.14 15.562zm8.81-5.655l8.33-7.452 8.329 7.452H42.959zM74.565.482l26.828 13.063V.482h1.797v15.08h-1.885L74.477 2.41v13.15H72.68V.483h1.884zm33.316 1.666V.482h29.939v1.666h-14.071v13.414h-1.841V2.148H107.88zM29.02 9.074H13.895V7.452h17.052v5.83C27.222 15.123 22.619 16 16.964 16 8.854 16 0 13.194 0 7.978 0 2.718 8.986.044 16.614.044c6.18 0 10.87 1.359 13.545 2.85L29.107 4.34c-2.543-1.447-7.014-2.63-12.493-2.63-6.225 0-14.466 1.928-14.466 6.268 0 4.296 8.066 6.4 14.773 6.4 5.698 0 9.468-.92 12.098-2.104v-3.2z" fill="currentColor" />
    </svg>
  ),
}
