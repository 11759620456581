/* eslint-disable max-len */
const programName = 'LensOn'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 466.218 97.374">
      <title>{programName}</title>
      <path className="st0" d="M2.375 11.894c0-1.107.907-2.104 2.153-2.104h10.427c1.134 0 2.154.997 2.154 2.104V74.03h28.9c1.245 0 2.152.997 2.152 2.105v9.082a2.128 2.128 0 01-2.153 2.104H4.527c-1.246 0-2.153-.996-2.153-2.103V11.894M59.607 11.894c0-1.107.907-2.104 2.154-2.104h46.127c1.247 0 2.154.997 2.154 2.104v9.083a2.128 2.128 0 01-2.153 2.104H74.34v18.166h27.994c1.134 0 2.153.997 2.153 2.104v9.193c0 1.218-1.02 2.104-2.153 2.104H74.34V74.03h33.547c1.247 0 2.154.997 2.154 2.105v9.082a2.128 2.128 0 01-2.153 2.104H61.76c-1.246 0-2.153-.996-2.153-2.103V11.894M208.105 76.22l4.09-6.81c.91-1.538 2.84-1.538 3.863-.77.568.33 9.77 6.81 17.154 6.81 5.907 0 10.337-3.734 10.337-8.457 0-5.6-4.886-9.446-14.428-13.18-10.68-4.174-21.358-10.765-21.358-23.725 0-9.775 7.498-21.09 25.56-21.09 11.588 0 20.45 5.713 22.72 7.36 1.137.66 1.478 2.527.682 3.625l-4.316 6.26c-.908 1.32-2.613 2.197-3.976 1.32-.91-.55-9.542-6.042-15.79-6.042-6.476 0-9.998 4.174-9.998 7.69 0 5.16 4.204 8.676 13.406 12.3 11.02 4.284 23.742 10.655 23.742 24.824 0 11.314-10.11 21.748-26.128 21.748-14.315 0-22.72-6.48-24.993-8.567-1.02-.99-1.59-1.538-.568-3.295M193.226 85.228V11.895c0-1.158-.968-2.105-2.152-2.105h-10.427a2.128 2.128 0 00-2.153 2.104v45.743h-.114l-45.46-47.204c-.404-.42-.97-.66-1.56-.66h-2.97c-1.183 0-2.142.94-2.142 2.095v73.35c0 1.156.968 2.103 2.152 2.103h10.314c1.246 0 2.153-.996 2.153-2.103V37.59h.113l45.704 49.047c.406.436.98.684 1.585.684h2.816c1.183 0 2.14-.936 2.14-2.092M464.252 85.228V11.895c0-1.158-.97-2.105-2.16-2.105h-10.45c-1.25 0-2.16.997-2.16 2.104v45.743h-.113L403.8 10.433c-.407-.42-.97-.66-1.564-.66h-2.98c-1.183 0-2.145.94-2.145 2.095V85.22c0 1.156.97 2.1 2.155 2.1h10.342c1.248 0 2.158-.996 2.158-2.103V37.59h.113l45.813 49.047c.406.436.983.684 1.587.684h2.824c1.187 0 2.148-.936 2.148-2.092" />
      <ellipse fill="#f50" cx="326.227" cy="48.734" rx="13.973" ry="13.624" />
      <path fill="#f50" d="M384.753 51.86c-3.326 5.08-10.45 15.153-20.037 24.297L350.974 62.76a27.236 27.236 0 003.88-14.026c0-15.414-12.815-27.91-28.627-27.91-15.81 0-28.628 12.496-28.628 27.91 0 15.415 12.816 27.912 28.627 27.912a29.044 29.044 0 0014.386-3.784l12.85 12.53c-8.22 5.66-17.513 9.668-27.354 9.668-28.062 0-51.678-32.562-58.646-43.2a6.031 6.031 0 010-6.64c6.968-10.638 30.584-43.2 58.645-43.2s51.676 32.562 58.643 43.2a6.031 6.031 0 010 6.64z" />
    </svg>
  ),
}
