/* eslint-disable max-len */
const programName = 'Rituals'
export default {
  programName,
  logo: (
    <svg x="0px" y="0px" viewBox="0 0 1014.9 100">
      <g id="icomoon-ignore" transform="translate(0,-412)" />
      <g>
        <path
          id="path5"
          className="st0"
          d="M87.2,32.7c0-18.7-12.6-32.5-33-32.5H10.6V49H0v16.6h10.6v30
h16.7v-30H48l19.2,30h21.4l-22.9-34C78.1,57.5,87.2,46.9,87.2,32.7L87.2,32.7z M27.9,48.9v-32h25.4c12.1,0,17.2,7.6,17.2,15.8
c0,8.6-5.6,16.3-17.2,16.3L27.9,48.9L27.9,48.9z"
        />
        <path id="path7" className="st0" d="M132,95.9h16.7V0.5H132V95.9z" />
        <path id="path9" className="st0" d="M221.7,95.5h16.7V16.9h26.9V0.2h-70v16.7h26.9v78.6H221.7z" />
        <path
          id="path11"
          className="st0"
          d="M311.6,0.2V57c0,22.4,16.3,41.1,40.6,41.1s40.6-18.7,40.6-41.1
V0.2h-17.2v52.7c0,17.7-10.6,27.9-23.9,27.9s-23.9-10.6-23.9-27.9V0.2H311.6L311.6,0.2z"
        />
        <path
          id="path13"
          className="st0"
          d="M448.1,0.2V16h20.7l-33,79.6h17.7l7.1-17.7h38.6l7.1,17.7h18.2
L486.1,0.2H448.1L448.1,0.2z M466.8,61.9l13.3-34l13.3,34H466.8L466.8,61.9z"
        />
        <path
          id="path15"
          className="st0"
          d="M639.5,56.1h-16.7v22.7h-36V0.2H570v95.4h69.5V56.1L639.5,56.1z"
        />
        <path
          id="path17"
          className="st0"
          d="M707,27.9c0-5.6,5.1-12.6,19.2-12.6c8.6,0,16.3,3,24.9,11.1
l12.6-11.6C754.6,5.1,742.3,0,727.2,0c-20.7,0-37.5,11.6-37.5,28.9c0,39.1,58.9,18.2,58.9,39.6c0,9.1-9.1,14.3-21.9,14.3
c-10.6,0-20.2-5.6-26.9-12.6L687,82.3C698.7,95,713.4,100,726.2,100c20.7,0,40.1-12.1,40.1-32C765.8,27.9,707,49.4,707,27.9
L707,27.9z"
        />
        <path
          id="path19"
          className="st0"
          d="M834.8,66.7c-8.1,0-14.8,6.6-14.8,15.3
c0,8.1,6.6,15.3,14.8,15.3c8.1,0,14.8-6.6,14.8-15.3S843,66.7,834.8,66.7z"
        />
        <path
          id="path21"
          className="st0"
          d="M917.4,66.7c-8.1,0-14.8,6.6-14.8,15.3
c0,8.1,6.6,15.3,14.8,15.3c8.1,0,14.8-6.6,14.8-15.3S925.5,66.7,917.4,66.7z"
        />
        <path
          id="path23"
          className="st0"
          d="M1000.2,66.7c-8.1,0-14.8,6.6-14.8,15.3
c0,8.1,6.6,15.3,14.8,15.3c8.1,0,14.8-6.6,14.8-15.3S1008.3,66.7,1000.2,66.7z"
        />
      </g>
    </svg>
  ),
}
