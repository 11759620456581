/* eslint-disable max-len */
const programName = 'The Body Shop'
export default {
  programName,
  logo: (
    <svg x="0px" y="0px" viewBox="0 0 652 652">
      <g>
        <path
          className="st0"
          d="M325.4,577c1.1,0,2.3,0,3.4,0c1.1,0,2.3-0.1,3.4-0.1c24.8-0.6,49.1-4.8,72.6-12.6
c47.3-15.8,86.9-42.9,118.2-81.7c38.2-47.4,57-101.8,56.7-162.7c-0.1-19.7-2.4-39.2-7-58.4c-10.8-44.7-32.2-83.7-64.2-116.7
c-23.5-24.3-50.9-43.1-81.8-56.9c-10.2-4.6-20.5-8.9-31.7-10.5c-5.3-0.7-10.6-0.5-15.5,2c-11.5,6.1-12.2,23.4-1.1,31
c3.8,2.6,8,4.8,12.3,6.6c13.7,6,27.7,11.4,40.9,18.6c41.5,22.7,73.4,54.7,94.7,97c21,41.8,27.7,86.1,21.2,132.3
c-3.4,24.3-10.6,47.4-21.8,69.2c-23.2,45.2-57.7,79.3-103.5,101.5c-40.7,19.7-83.7,26-128.4,19.8c-24.3-3.3-47.5-10.6-69.4-21.8
c-43.6-22.3-76.6-55.3-99-98.8c-17.5-34-25.4-70.3-24.4-108.5c0.4-14.1,2-27.9,5-41.6c12.1-53.6,40.2-97,83.8-130.3
c18.9-14.5,40.1-25,62.1-33.9c6.9-2.8,13.8-6.1,20.2-10c6.6-4,9.3-12.3,7.5-19.9c-1.9-8-8.7-13.2-17.5-13.5
c-8.2-0.3-15.9,1.9-23.5,4.7c-38.8,14.6-72.5,36.8-100.6,67.3c-37.6,40.7-59.3,88.9-65.6,143.8c-3,26.1-2.2,52.2,3,78
C87.2,429.9,116,479,162,517.6C209.2,557.1,264,576.4,325.4,577 M121.8,327.4c0,1.5-0.2,3.1,0.1,4.5c0.2,0.9,1,2.3,1.8,2.4
c0.8,0.2,2-0.7,2.8-1.4c0.4-0.4,0.5-1.3,0.8-2c1-3.6,2-7.3,3.1-10.9c11.8-40.1,33.4-73.8,66.4-99.9c15.4-12.2,32.5-21.7,50.4-29.9
c4.9-2.2,8.7-5.5,11.3-10.2c6.3-11.4,0.5-27.5-16-27.6c-5.8,0-11.1,2-16.1,4.8c-38.3,21.4-66.5,52.2-85.5,91.5
C128.9,273.6,122.6,299.8,121.8,327.4 M528.8,327.1c-0.3-12.3-1.8-24.4-4.5-36.4c-7.4-32.4-22-61.2-43.4-86.6
c-15.9-18.9-34.6-34.7-56.4-46.6c-5.5-3-11.2-5.1-17.6-4.9c-9.5,0.3-16.9,7.4-17.2,16.9c-0.3,9.8,4.8,16.3,13.2,20.6
c5.2,2.6,10.5,5,15.6,7.7c18.7,9.6,35.9,21.5,51,36.3c24.2,23.8,40.5,52.2,50.2,84.5c1.4,4.5,2.6,9.1,3.9,13.6
c0.4,1.6,1.5,2.6,3.1,2.4c1.7-0.2,2-1.6,2-3.1C528.8,330,528.8,328.5,528.8,327.1 M248,348.3c-0.1,16.4,5.4,27.4,15.7,34.3
c8.2,5.5,17.4,7.2,27.1,6.6c9.5-0.6,18-3.7,24.8-10.5c9.1-8.9,12-20.1,11-32.5c-0.8-8.6-3.8-16.3-9.7-22.7
c-12.4-13.5-37.7-14.8-52.3-5C253,326.3,248.3,337.6,248,348.3 M343.7,387.6c8.1,0,16,0.1,23.9,0c3.7-0.1,7.5-0.3,11.2-0.8
c22-3.3,35.3-22.5,30.9-44.3c-3.4-16.9-15.9-27.6-33.7-28.4c-10.4-0.4-20.8-0.2-31.2-0.2c-0.3,0-0.7,0.1-1.1,0.2V387.6z
 M180.8,387.5c0.4,0.1,0.8,0.1,1.1,0.1c8.8,0,17.5,0.1,26.3,0c5.5-0.1,10.8-0.9,15.9-3.3c12.1-5.7,15-21.8,5.4-30.8
c-2.9-2.7-6.5-3.9-10.7-4.8c1.2-0.5,2-0.8,2.7-1.2c10.6-4.7,13.7-18.4,6-26.8c-4.2-4.6-9.7-6.5-15.6-6.7c-10-0.3-19.9-0.2-29.9-0.2
c-0.4,0-0.8,0.2-1.2,0.3V387.5z M363.8,478.4c0.9-0.1,1.7-0.1,2.6-0.2c18.1-0.9,32.6-12.8,34.6-31.4c1.2-11.5-1.1-22.2-9.4-30.8
c-7-7.4-15.9-10.4-25.9-10.7c-12.3-0.4-23,3.1-30.9,13c-5.3,6.6-7.6,14.2-7.8,22.6c-0.3,11.4,3,21.4,11.8,29
C346.1,475.9,354.6,478.2,363.8,478.4 M256.7,407v69.5h12.3v-30.4h32.4v30.4h12.3V407h-12.4v27.4H269V407H256.7z M415.7,406.6v70
H428v-26.9c2.6,0,5,0.1,7.5,0c4.3-0.3,8.6-0.6,12.8-1.4c5.9-1.1,11.1-3.7,13.9-9.4c3.1-6.3,3.5-12.9,0.9-19.4
c-3-7.4-9.3-11.1-16.7-11.8C436.2,406.7,426,406.9,415.7,406.6 M196.9,460.7c0,3.9-0.1,7.5,0.1,11c0,0.8,0.7,2,1.4,2.3
c10.1,4.9,20.6,6,31.1,1.5c7.4-3.2,11.8-9,12.3-17.3c0.5-8.6-3.4-14.8-11-18.6c-2.9-1.4-5.9-2.5-8.9-3.8c-3-1.3-6.1-2.4-9.1-3.8
c-2.7-1.4-4.6-3.5-4.4-6.8c0.2-3.3,2.3-5.3,5.1-6.6c3.2-1.5,6.6-1.9,10.1-1.4c4.4,0.6,8.8,1.7,13.2,2.5v-11.3
c-7.1-2.7-14.3-3.8-21.7-2.4c-5.8,1.1-10.8,3.5-14.8,8.1c-6.6,7.7-6.6,22,5.2,27.9c3,1.5,6.2,2.6,9.3,3.9c3.6,1.5,7.4,2.8,10.7,4.8
c5,3.1,5.2,9.6,0.5,13.3c-1.5,1.2-3.5,2.1-5.4,2.5c-7.8,1.6-14.9-0.5-21.6-4.5C198.4,461.6,197.8,461.2,196.9,460.7 M308.8,267.5
h29.3V295H349v-62.1h-11v24.6h-29.3v-24.7c-3,0-5.7,0.1-8.4-0.1c-2-0.1-2.5,0.6-2.5,2.5c0.1,19.2,0,38.3,0.1,57.5
c0,0.8,0.1,1.5,0.1,2.3h10.8V267.5z M376.4,267.8h28.3c0-2.6-0.1-5.1,0-7.5c0.1-1.6-0.5-2.1-2.1-2c-7.9,0.1-15.8,0-23.7,0h-2.4
v-15.5h29.7v-9.9h-40.9v62.1h42.6v-10.2h-31.5V267.8z M456.4,387.5v-2.4c0-9-0.1-18.1,0-27.1c0-1.3,0.5-2.8,1.2-3.9
c8.2-12.7,16.5-25.3,24.8-38c0.4-0.6,0.8-1.2,1.3-2.2c-4.5,0-8.6,0.1-12.7,0c-1.6-0.1-2.6,0.4-3.4,1.9
c-5.2,8.7-10.5,17.2-15.8,25.8c-0.4,0.7-0.9,1.3-1.4,2.1c-0.6-0.9-1-1.4-1.4-2.1c-5-8-10.3-15.9-15.1-24c-1.7-2.8-3.5-4-6.8-3.7
c-3.6,0.3-7.2,0.1-11.2,0.1c0.6,1,0.9,1.6,1.3,2.2c8.3,12.7,16.7,25.4,24.9,38.2c0.7,1.1,1.1,2.6,1.1,4c0.1,8.9,0.1,17.8,0.1,26.7
v2.6H456.4z M268.6,242.8h19.1v-10h-49.5V243h19.3v52h11V242.8z M573.3,549.9c0-14.6-11.8-26.4-26.4-26.4
c-14.7-0.1-26.5,11.8-26.6,26.5c-0.1,14.6,11.9,26.5,26.5,26.5C561.5,576.4,573.3,564.6,573.3,549.9"
        />
        <path d="M325.4,577c-61.4-0.6-116.2-19.9-163.3-59.4C116,479,87.2,429.9,75.4,370.9c-5.2-25.8-6-51.9-3-78
c6.3-55,28-103.1,65.6-143.8c28.1-30.5,61.9-52.7,100.6-67.3c7.5-2.9,15.3-5,23.4-4.7c8.8,0.3,15.6,5.5,17.5,13.5
c1.8,7.5-0.9,15.8-7.5,19.9c-6.4,3.9-13.2,7.2-20.2,10c-22,9-43.1,19.4-62.1,33.9c-43.6,33.3-71.7,76.7-83.9,130.3
c-3.1,13.6-4.6,27.5-5,41.6c-1,38.3,6.9,74.5,24.4,108.5c22.3,43.5,55.4,76.5,98.9,98.8c21.9,11.2,45.1,18.5,69.4,21.8
c44.7,6.1,87.7-0.2,128.4-19.8c45.8-22.2,80.3-56.2,103.5-101.5c11.2-21.8,18.4-44.9,21.8-69.2c6.5-46.3-0.3-90.5-21.2-132.3
c-21.2-42.3-53.2-74.4-94.7-97c-13.2-7.2-27.2-12.6-40.9-18.6c-4.3-1.9-8.5-4-12.3-6.6c-11.1-7.7-10.4-25,1.1-31
c4.9-2.6,10.2-2.8,15.5-2c11.2,1.5,21.5,5.9,31.8,10.4c30.8,13.8,58.3,32.6,81.8,57c32,33.1,53.4,72,64.2,116.7
c4.6,19.2,6.9,38.6,7,58.4c0.3,60.9-18.5,115.3-56.7,162.7c-31.3,38.8-70.9,65.9-118.2,81.7c-23.6,7.8-47.8,12-72.6,12.6
c-1.1,0-2.3,0.1-3.4,0.2C327.7,577,326.5,577,325.4,577"
        />
        <path d="M121.8,327.4c0.7-27.5,7.1-53.8,19-78.6c19-39.3,47.3-70.1,85.5-91.5c5-2.8,10.3-4.8,16.1-4.8c16.5,0.1,22.3,16.2,16,27.6
c-2.6,4.7-6.5,8-11.3,10.2c-17.8,8.2-34.9,17.7-50.4,29.9c-33,26.1-54.6,59.7-66.4,99.8c-1.1,3.7-2.1,7.3-3.1,11
c-0.2,0.7-0.3,1.5-0.8,2c-0.8,0.7-2,1.6-2.8,1.4c-0.8-0.2-1.6-1.5-1.8-2.4C121.6,330.5,121.8,328.9,121.8,327.4"
        />
        <path d="M528.8,327.1v4.3c0,1.4-0.4,2.9-2,3.1c-1.6,0.2-2.7-0.8-3.1-2.4c-1.3-4.5-2.6-9.1-3.9-13.6c-9.8-32.3-26.1-60.7-50.3-84.4
c-15.1-14.8-32.2-26.6-50.9-36.3c-5.2-2.6-10.5-5.1-15.6-7.7c-8.4-4.3-13.5-10.8-13.2-20.6c0.3-9.5,7.7-16.6,17.2-16.9
c6.4-0.2,12.1,2,17.6,4.9c21.8,11.9,40.5,27.7,56.4,46.6c21.4,25.4,36,54.2,43.4,86.6C527,302.7,528.4,314.8,528.8,327.1"
        />
        <path d="M248,348.3c0.3-10.7,5-22,16.5-29.7c14.6-9.9,39.9-8.5,52.3,5c6,6.4,9,14.1,9.7,22.7c1.1,12.4-1.8,23.6-11,32.5
c-6.8,6.7-15.4,9.8-24.8,10.5c-9.7,0.6-18.9-1.1-27.1-6.6C253.4,375.7,247.9,364.6,248,348.3 M261.5,350.3c0.3,2.1,0.3,4.3,0.7,6.4
c4.8,23.5,29.3,25.2,40.7,16.2c7.1-5.6,10-13.3,10.2-22.1c0.5-17.6-13.2-29.7-30.6-26.9C270.1,325.9,261.6,336.6,261.5,350.3"
        />
        <path d="M343.7,387.6V314c0.4-0.1,0.8-0.2,1.1-0.2c10.4,0,20.8-0.2,31.2,0.2c17.7,0.7,30.2,11.5,33.6,28.4c4.4,21.8-9,41-30.9,44.3
c-3.7,0.6-7.4,0.8-11.1,0.9C359.7,387.7,351.8,387.6,343.7,387.6 M356.7,324.8v51c7.1,1.5,20.5,0.1,26.7-2.8
c6.8-3.2,11.5-8.2,13-15.8c0.6-3.2,0.8-6.4,0.6-9.6c-0.8-11.7-7.7-19.6-19.2-21.5C370.9,325,363.9,325.2,356.7,324.8"
        />
        <path d="M180.8,387.5v-73.3c0.4-0.1,0.8-0.3,1.2-0.3c10,0,19.9-0.1,29.9,0.2c6,0.2,11.5,2.1,15.6,6.6c7.7,8.5,4.6,22.2-6,26.8
c-0.8,0.3-1.6,0.7-2.7,1.1c4.2,0.9,7.8,2,10.7,4.8c9.5,8.9,6.6,25-5.5,30.8c-5,2.4-10.4,3.2-15.9,3.3c-8.8,0.1-17.5,0-26.3,0
C181.6,387.6,181.3,387.5,180.8,387.5 M193.3,376.6c5.4,0,10.7,0.2,16-0.1c2.5-0.1,5.1-0.8,7.4-1.8c4.3-1.8,6.2-5.7,5.8-10.5
c-0.4-4.4-2.8-7.3-7.4-8.4c-7.2-1.6-14.5-0.6-21.9-0.9V376.6z M193.3,344.1c4.9,0,9.5,0.1,14.2,0c1.3,0,2.7-0.3,4-0.7
c4.8-1.4,7.3-4.8,7.2-9.4c-0.2-4.6-2.7-8-7.8-8.6c-5.8-0.7-11.7-0.7-17.6-0.9V344.1z"
        />
        <path d="M363.8,478.4c-9.2-0.1-17.7-2.5-24.9-8.6c-8.8-7.7-12.1-17.7-11.8-29.1c0.2-8.3,2.4-16,7.8-22.6c7.9-9.8,18.7-13.3,30.9-13
c10,0.3,18.9,3.4,25.9,10.7c8.3,8.7,10.6,19.3,9.4,30.9c-2,18.6-16.5,30.6-34.6,31.4C365.6,478.3,364.7,478.3,363.8,478.4
 M363.9,466.9c5,0,9.4-1.2,13.3-3.7c8.8-5.7,13.1-17.1,10.3-28.2c-2.2-9-7.8-15.1-16.8-17.6c-9.1-2.5-17.4-0.6-24,6.5
c-5.9,6.4-7.4,14.2-6.1,22.6C342.3,458.7,351.8,466.9,363.9,466.9"
        />
        <polygon points="256.7,407 269,407 269,434.5 301.3,434.5 301.3,407 313.7,407 313.7,476.5 301.4,476.5 301.4,446.1 269,446.1
269,476.6 256.7,476.6 "
        />
        <path d="M415.6,406.6c10.4,0.3,20.6,0.1,30.7,1.1c7.4,0.7,13.7,4.4,16.7,11.8c2.6,6.5,2.2,13.1-0.9,19.4c-2.8,5.7-8,8.3-13.9,9.4
c-4.2,0.8-8.5,1-12.8,1.3c-2.4,0.2-4.8,0-7.5,0v26.9h-12.3V406.6z M428.1,417.7v21.7c3.1,0,6.1,0.2,9.1-0.1
c2.7-0.2,5.6-0.5,8.2-1.4c4.7-1.7,7.1-6.6,6.2-11.8c-0.8-5-3.8-8.1-9.1-8.4C437.8,417.5,433.1,417.7,428.1,417.7"
        />
        <path d="M196.9,460.7c0.9,0.6,1.5,0.9,2.1,1.3c6.7,4,13.8,6,21.6,4.5c1.9-0.4,3.9-1.3,5.4-2.5c4.6-3.6,4.5-10.2-0.5-13.3
c-3.3-2-7.2-3.2-10.7-4.8c-3.1-1.3-6.3-2.4-9.3-3.9c-11.8-5.9-11.7-20.2-5.1-27.9c3.9-4.6,9-7,14.8-8.1c7.5-1.4,14.7-0.3,21.7,2.4
v11.3c-4.4-0.9-8.8-1.9-13.2-2.5c-3.4-0.5-6.9-0.1-10.1,1.4c-2.8,1.4-4.9,3.3-5.1,6.6c-0.2,3.3,1.7,5.5,4.4,6.8
c2.9,1.5,6,2.6,9.1,3.8c3,1.3,6,2.3,8.9,3.8c7.6,3.8,11.5,9.9,11,18.6c-0.5,8.3-4.9,14.1-12.2,17.3c-10.5,4.5-21,3.4-31.2-1.5
c-0.7-0.3-1.3-1.5-1.4-2.3C196.8,468.2,196.9,464.6,196.9,460.7"
        />
        <path d="M308.7,267.5v27.4h-10.8c-0.1-0.8-0.1-1.5-0.1-2.3c0-19.2,0-38.3-0.1-57.5c0-2,0.5-2.6,2.5-2.5c2.7,0.2,5.4,0.1,8.4,0.1
v24.7H338v-24.6h10.9V295h-10.9v-27.5H308.7z"
        />
        <path d="M376.4,267.8v16.9h31.5v10.2h-42.6v-62.1h40.9v10h-29.8v15.5h2.5c7.9,0,15.8,0,23.7,0c1.6,0,2.2,0.4,2.1,2
c-0.1,2.4,0,4.9,0,7.5H376.4z"
        />
        <path d="M456.4,387.5h-13v-2.6c0-8.9,0.1-17.8,0-26.7c0-1.3-0.4-2.8-1.2-4c-8.3-12.7-16.6-25.4-24.9-38.2c-0.4-0.6-0.8-1.2-1.4-2.2
c4,0,7.6,0.3,11.2-0.1c3.3-0.3,5.1,0.9,6.8,3.7c4.8,8.1,10,16,15.1,24c0.4,0.6,0.9,1.3,1.4,2.1c0.6-0.9,1-1.5,1.4-2.1
c5.3-8.6,10.6-17.2,15.8-25.9c0.9-1.4,1.8-1.9,3.4-1.9c4.1,0.1,8.2,0,12.7,0c-0.6,0.9-0.9,1.6-1.4,2.2c-8.3,12.7-16.6,25.3-24.8,38
c-0.7,1.1-1.2,2.6-1.2,3.9c-0.1,9-0.1,18.1-0.1,27.1V387.5z"
        />
        <polygon points="268.6,242.8 268.6,294.9 257.6,294.9 257.6,243 238.2,243 238.2,232.9 287.7,232.9 287.7,242.8 " />
        <path d="M573.3,549.9c0,14.7-11.8,26.5-26.5,26.6c-14.7,0-26.6-12-26.5-26.5c0.1-14.7,12-26.5,26.6-26.5
C561.5,523.5,573.3,535.3,573.3,549.9 M568.5,550c0-12.3-9.6-22.2-21.5-22.3c-12.1-0.1-21.9,9.8-21.9,22.1
c-0.1,12.3,9.7,22.2,21.7,22.2C558.8,572.1,568.5,562.2,568.5,550"
        />
        <path
          className="no-fill"
          d="M261.5,350.3c0.2-13.7,8.6-24.4,21-26.4c17.4-2.8,31.2,9.3,30.6,26.9c-0.3,8.8-3.1,16.4-10.2,22.1
c-11.4,9-35.9,7.4-40.7-16.2C261.8,354.6,261.7,352.5,261.5,350.3"
        />
        <path
          className="st0"
          d="M356.7,324.8c7.2,0.4,14.2,0.3,21,1.4c11.6,1.9,18.4,9.8,19.2,21.5c0.2,3.2,0,6.5-0.6,9.6
c-1.4,7.5-6.1,12.6-13,15.8c-6.2,2.9-19.6,4.3-26.7,2.8V324.8z"
        />
        <path
          className="st0"
          d="M193.3,376.6v-21.7c7.4,0.3,14.7-0.8,21.9,0.9c4.5,1,6.9,4,7.3,8.4c0.4,4.8-1.6,8.7-5.8,10.5
c-2.3,1-4.9,1.7-7.4,1.8C204,376.8,198.7,376.6,193.3,376.6"
        />
        <path
          className="st0"
          d="M193.3,344.1v-19.7c6,0.3,11.8,0.2,17.6,0.9c5,0.6,7.6,4,7.8,8.6c0.2,4.6-2.4,8-7.2,9.4
c-1.3,0.4-2.7,0.7-4,0.7C202.8,344.2,198.2,344.1,193.3,344.1"
        />
        <path
          className="st0"
          d="M363.9,466.9c-12.2,0-21.6-8.2-23.4-20.4c-1.2-8.4,0.2-16.2,6.1-22.6c6.6-7.1,14.9-9,24-6.5
c9,2.5,14.6,8.6,16.8,17.6c2.8,11.1-1.5,22.5-10.3,28.2C373.3,465.7,369,466.9,363.9,466.9"
        />
        <path
          className="st0"
          d="M428.1,417.7c5,0,9.7-0.2,14.4,0.1c5.3,0.3,8.4,3.4,9.1,8.4c0.8,5.2-1.6,10.1-6.2,11.8
c-2.6,0.9-5.4,1.2-8.2,1.4c-3,0.3-6,0.1-9.1,0.1V417.7z"
        />
        <path
          className="st0"
          d="M568.5,550c0,12.2-9.7,22.1-21.7,22.1c-12.1,0-21.9-10-21.8-22.2c0.1-12.3,9.9-22.1,21.9-22.1
C558.9,527.8,568.5,537.8,568.5,550 M541,552.8c3.4-0.9,5.6-0.2,7.2,2.9c1.3,2.6,3.1,4.9,4.7,7.2c2,3,2.4,3.2,6.2,2.3
c-2.8-4.3-5.6-8.5-8.4-12.8c0.9-0.3,1.6-0.4,2.3-0.6c4-1.2,6.1-4.2,5.9-8.6c-0.2-4.4-2.4-7.7-6.6-8.1c-5.2-0.4-10.6-0.1-15.9-0.1
v30.4h4.6V552.8z"
        />
        <path d="M541,552.8v12.6h-4.6V535c5.4,0,10.6-0.3,15.9,0.1c4.2,0.4,6.4,3.6,6.6,8c0.2,4.4-1.9,7.4-5.9,8.6
c-0.7,0.2-1.4,0.4-2.3,0.6c2.9,4.3,5.6,8.5,8.4,12.8c-3.9,0.9-4.3,0.8-6.2-2.3c-1.6-2.4-3.4-4.7-4.7-7.2
C546.6,552.6,544.3,552,541,552.8 M541.1,548.5c3.3-0.2,6.5-0.3,9.6-0.7c2.3-0.3,3.3-2,3.4-4.3c0-2.2-1.2-3.9-3.5-4.2
c-3.1-0.4-6.2-0.4-9.5-0.6V548.5z"
        />
        <path
          className="st0"
          d="M541.1,548.5v-9.8c3.3,0.2,6.4,0.2,9.5,0.6c2.3,0.3,3.5,2,3.5,4.2c0,2.3-1.1,4-3.4,4.3
C547.6,548.2,544.5,548.3,541.1,548.5"
        />
      </g>
    </svg>),
}
