/* eslint-disable max-len */
const programName = 'Ugg'
export default {
  programName,
  logo: (
    <svg viewBox="5.3 259.7 598.2 272.5">
      <title>{programName}</title>
      <g transform="matrix(1.25 0 0 -1.25 0 765)">
        <defs>
          <path id="a" d="M-470.8-258h1429V846.2h-1429z" />
        </defs>
        <g fill="#000">
          <path d="M474.717 347.993c1.2 0 2.2.1 2.2 1.5 0 1.2-1 1.4-2 1.4h-2v-2.9h1.8zm-1.8-5.2h-1.4v9.2h3.5c2.2 0 3.2-.8 3.2-2.7 0-1.6-1-2.4-2.4-2.5l2.7-4h-1.4l-2.5 4h-1.6v-4zm1.8-2.1c3.6 0 6.5 2.9 6.5 6.7s-2.9 6.7-6.5 6.7c-3.7 0-6.6-2.9-6.6-6.7.1-3.8 2.9-6.7 6.6-6.7m-8.1 6.7c0 4.6 3.7 8 8.2 8 4.4 0 8.1-3.3 8.1-8s-3.7-8.1-8.1-8.1c-4.5.1-8.2 3.5-8.2 8.1m-341.67-52.233c0 28.8 7.2 52.5 23.3 71.2v12c0 2.3.5 4 1.4 5.2.9 1.2 2.7 2.2 5.1 2.9v3.6h-40.9v-3.6c2.4-.7 4.2-1.7 5.1-2.9.9-1.2 1.4-3 1.4-5.3v-76c0-8.8-.5-15.2-1.3-19.5-.9-4.2-2.5-7.7-4.8-10.5-6.5-7.6-17.7-11.4-33.5-11.4-15.7 0-26.9 3.8-33.4 11.4-2.4 2.8-4.2 6.4-5 10.5-.9 4.2-1.3 10.7-1.3 19.5v76.1c0 2.3.5 4 1.5 5.3s2.7 2.2 5.1 2.9v3.6h-43.4v-3.6c2.4-.7 4.2-1.7 5.1-2.9s1.4-2.9 1.4-5.2v-85.4c0-10.7 1.3-20 3.9-27.7 2.7-7.9 7.4-14 14.2-18.6 11.8-7.9 28.6-11.8 50.8-11.8 24.2 0 41.9 4.5 53 13.5-5.9 13.8-7.7 29.4-7.7 46.7m347.2 9.325c1 1.3 2.9 2.2 5.5 3v3.6h-72.5c-2.3 0-4 .6-5.2 1.6-1.2 1-2.1 3-2.9 5.9h-3.6v-37h3.6c.7 2.8 1.7 4.7 2.9 5.8 1.2 1 2.9 1.6 5.2 1.6h37.9v-21.5s-6.8-13.4-50.3-15.6c-25.8-1.4-42.1 10.4-42.1 10.4-.1-.7-.1-.3-.1-1v-29.5s13.9-6.5 43-6.5c27.3 0 58.3 8 84.4 30.1v2.9c-2.8.8-4.6 1.7-5.7 3s-1.5 3-1.5 5.4v32.4c-.2 2.4.4 4.2 1.4 5.4m-116.8 47.7c10.5 9.2 23.8 13.9 39.7 13.9 7.9 0 16.2-1.3 24.9-3.7 8.8-2.5 15.5-5.5 20.1-9 2.1-1.6 3.5-3 4-4 .6-1.2.9-2.9 1-5.4l3.2-1.7 17.9 37.8-3.1 1.7c-2.1-1.7-3.9-2.7-5.5-2.7-2.2 0-5.8 1-10.9 3.1-9.8 3.9-18.6 6.7-26.3 8.4-7.7 1.7-15.9 2.1-24.7 2.5-32.9 1.6-50.3-8.7-50.3-8.7l-25.1-54-10.4 3.4s-.1 5.1-.8 5.4c-2.4-2.1-4.4-13.2-5.1-27.7-.1-3.2.5-12.2.5-12.2h33s-.8 7.2-.8 11.7c.3 17 6.8 30.7 18.7 41.2" />
          <path d="M309.762 265.512h-48.6c-3 0-5.2-.7-6.7-2.2-1.5-1.5-2.8-4-3.7-7.7h-4.6v49.3h4.6c.9-3.8 2.2-6.5 3.7-7.9 1.5-1.4 3.7-2.1 6.7-2.1h93.2v-4.8c-3.5-1-5.8-2.4-7.2-4-1.4-1.6-2.1-4-2.1-7.3v-43.3c0-3.2.7-5.7 2-7.3 1.4-1.6 3.7-3 7.3-4v-3.7c-37.3-22.9-73.4-34.3-108.4-34.3-31.8 0-57.6 8.1-77.7 24.2-25.2 20.3-37.9 48.6-37.9 84.9 0 33.7 11.2 60.5 33.7 80.5 21.2 18.9 49.5 28.4 84.9 28.4 11.2 0 21.8-1.2 31.8-3.3 9.9-2.2 21.2-5.9 33.8-11.2 6.5-2.8 12.2-4.7 15-4.7 2 0 4.4 1.2 7 3.6l4-2.3-22.7-50-4.4 1.7c-.1 3.3-1.6 6.4-2.3 7.9-.8 1.5-2.5 3.3-5.2 5.4-5.9 4.7-14.4 8.8-25.8 12-11.3 3.3-22.1 5-32.1 5-20.6 0-37.5-6.1-51.2-18.5-15.2-14-22.9-32.4-22.9-55.3 0-22.2 7.4-40.1 22.3-53.8 13.6-12.6 31.6-18.8 53.9-18.8 10.9 0 21.6 1.4 32.1 4 10.5 2.8 19.6 6.5 27.4 11.1v28.5z" />
        </g>
      </g>
    </svg>
  ),
}
