/* eslint-disable max-len */
const programName = 'Svenskt Kosttillskott'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 269.6 50">
      <title>{programName}</title>
      <path className="st0" d="M147.2 21.7c0 0-3 0-6.8 0s-3.8 3.2-3.8 3.2h11.6c1 0 1.8-0.1 2.3-0.2 0.6-0.1 1.2-0.5 1.8-1.1 1-0.9 1.4-1.9 1.4-3.1v-2.3c0-1.2-0.5-2.3-1.4-3.1 -0.9-0.8-2.2-1.2-3.6-1.2h-5.1c-2.1 0-3.1-0.6-3.1-1.9v-0.8c0-0.6 0.3-1 1-1.4 0.5-0.2 1.2-0.4 2.1-0.4h10.3l7.1 14c0.5 1 1.4 1.5 2.6 1.5 1.2 0 2.1-0.5 2.6-1.5L175 6.1h-4l-7.3 14.6 -7.3-14.4h-4l0 0h-9.7c-1.7 0-3 0.3-3.8 0.8 -1.4 0.8-2 1.9-2 3.3V13c0 1.3 0.6 2.3 1.8 3.1 1.1 0.7 2.4 1.1 4 1.1h4.8c1.7 0 2.5 0.6 2.5 1.9 0 1-0.1 1.6-0.4 2C149 21.4 148.3 21.7 147.2 21.7z" />
      <path className="st0" d="M233 13.8h-5c-2.1 0-3.1-0.6-3.1-1.9V11c0-0.6 0.3-1 0.9-1.4 0.5-0.2 1.1-0.4 2.1-0.4h9.6c0 0-0.3-3.2-4.2-3.2s-6.4 0-6.4 0c-1.7 0-3 0.3-3.8 0.8 -1.4 0.8-2 1.9-2 3.3v2.5c0 1.3 0.6 2.3 1.8 3.1 1.1 0.7 2.4 1.1 4 1.1h4.8c1.7 0 2.5 0.6 2.5 1.9 0 0.9-0.1 1.6-0.4 2 -0.4 0.5-1.1 0.7-2.2 0.7h-14.2v-9.7c0-3.8-2-5.6-5.9-5.6h-6.8c-3.9 0-5.9 1.9-5.9 5.6v9.7h-15.6c-1.1 0-1.9-0.1-2.4-0.4 -0.7-0.4-1-1.1-1-2.1v-0.6l15-4.9v-2.3c0-3.3-1.9-5-5.8-5h-6.8c-4.1 0-6.2 1.6-6.2 4.7v8.8c0 1.8 0.6 3.1 1.9 4 1 0.7 2.6 1.1 4.7 1.1h20.1l0 0 0 0V13c0-1.9 1.1-2.8 3.3-2.8h4.3c2.2 0 3.3 0.9 3.3 2.8v11.7l0 0 0 0h19.1c1 0 1.8-0.1 2.3-0.2 0.6-0.1 1.2-0.5 1.8-1.1 1-0.9 1.4-1.9 1.4-3.1V18c0-1.2-0.5-2.3-1.4-3.1C235.7 14.2 234.5 13.8 233 13.8zM179.6 11.7c0-1.6 1.2-2.4 3.6-2.4h4.3c2.1 0 3.1 0.7 3.1 2.1v0.3l-11 3.6L179.6 11.7 179.6 11.7z" />
      <rect x="240.7" className="st0" width="3.9" height="24.7" />
      <path className="st0" d="M258.1 6.2h-4.7l-7.8 7.6c-0.5 0.5-0.8 1-0.8 1.4 0 0.4 0.2 0.8 0.7 1.2l8.3 8.2h4.8l-9.6-9.4L258.1 6.2z" />
      <path className="st0" d="M269.5 6.5c-1.9 0-4.3 0-4.3 0V0h-3.9v24.7h3.9V9.5h4.4L269.5 6.5z" />
      <path className="st0" d="M146.9 33.2h-2.6l-4.4 4.3c-0.3 0.3-0.4 0.6-0.4 0.8s0.1 0.5 0.4 0.7l4.6 4.6h2.7l-5.4-5.3L146.9 33.2z" />
      <rect x="137.1" y="29.7" className="st0" width="2.2" height="13.9" />
      <path className="st0" d="M157.8 33.7c-0.5-0.4-1.3-0.5-2.3-0.5h-3.8c-1 0-1.8 0.2-2.4 0.5 -0.7 0.5-1.1 1.2-1.1 2.1v5.2c0 0.8 0.3 1.4 0.9 1.9s1.4 0.7 2.6 0.7h3.8c1 0 1.8-0.2 2.3-0.5 0.7-0.5 1.1-1.1 1.1-2v-5.2C158.9 34.9 158.5 34.2 157.8 33.7zM156.6 40.6c0 0.4-0.2 0.8-0.5 1s-0.6 0.3-1.1 0.3h-2.9c-0.5 0-0.9-0.1-1.2-0.3 -0.4-0.2-0.5-0.6-0.5-1v-4.2c0-0.5 0.2-0.8 0.5-1 0.3-0.2 0.7-0.3 1.3-0.3h2.6c0.6 0 1 0.1 1.3 0.3 0.3 0.2 0.5 0.6 0.5 1L156.6 40.6 156.6 40.6z" />
      <path className="st0" d="M175.6 33.2c-0.4 0-0.6 0-0.7 0v-3.5h-2.2v3.5h-8.6c-1 0-1.7 0.2-2.1 0.5 -0.8 0.5-1.1 1.1-1.1 1.9v1.4c0 0.7 0.3 1.3 1 1.7 0.6 0.4 1.3 0.6 2.3 0.6h2.7c0.9 0 1.4 0.4 1.4 1.1 0 0.5-0.1 0.9-0.2 1.1 -0.2 0.3-0.6 0.4-1.2 0.4 0 0-1.7 0-3.7 0s-2.2 1.8-2.2 1.8h6.5c0.6 0 1 0 1.3-0.1 0.4-0.1 0.7-0.3 1-0.6 0.5-0.5 0.8-1.1 0.8-1.7v-1.3c0-0.7-0.3-1.3-0.8-1.7 -0.5-0.5-1.2-0.7-2-0.7h-3.1c-1.2 0-1.8-0.4-1.8-1.1v-0.4c0-0.3 0.2-0.6 0.5-0.8 0.3-0.1 0.6-0.2 1.2-0.2h8v8.6h2.2v-8.6h2.8C177.6 34.9 177.3 33.2 175.6 33.2z" />
      <path className="st0" d="M182.1 33.2c-0.2 0-0.4 0-0.5 0v-3.5h-2.2v13.9h2.2v-8.6h2.8C184.3 34.9 183.9 33.2 182.1 33.2z" />
      <rect x="186.2" y="29.8" className="st0" width="2.1" height="1.8" />
      <rect x="186" y="33.2" className="st0" width="2.2" height="10.4" />
      <rect x="190.4" y="29.7" className="st0" width="2.2" height="13.9" />
      <rect x="194.9" y="29.7" className="st0" width="2.2" height="13.9" />
      <path className="st0" d="M201.5 35.1c0.3-0.1 0.6-0.2 1.2-0.2h5.7c0 0 0-1.8-2.4-1.8s-3.9 0-3.9 0c-1 0-1.7 0.2-2.1 0.5 -0.8 0.5-1.1 1.1-1.1 1.9V37c0 0.7 0.3 1.3 1 1.7 0.6 0.4 1.3 0.6 2.3 0.6h2.7c1 0 1.4 0.4 1.4 1.1 0 0.5-0.1 0.9-0.2 1.1 -0.2 0.3-0.6 0.4-1.2 0.4 0 0-1.4 0-3.7 0 -2.3 0-2.2 1.8-2.2 1.8h6.5c0.6 0 1 0 1.3-0.1 0.4-0.1 0.7-0.3 1-0.6 0.5-0.5 0.8-1.1 0.8-1.7V40c0-0.7-0.3-1.3-0.8-1.7 -0.5-0.5-1.2-0.7-2-0.7h-2.9c-1.2 0-1.7-0.4-1.7-1.1V36C201 35.6 201.2 35.3 201.5 35.1z" />
      <rect x="210.4" y="29.7" className="st0" width="2.2" height="13.9" />
      <path className="st0" d="M220.2 33.2h-2.6l-4.4 4.3c-0.3 0.3-0.4 0.6-0.4 0.8s0.1 0.5 0.4 0.7l4.7 4.6h2.7l-5.4-5.3L220.2 33.2z" />
      <path className="st0" d="M230.7 33.7c-0.5-0.4-1.3-0.5-2.3-0.5h-3.8c-1.1 0-1.8 0.2-2.4 0.5 -0.7 0.5-1.1 1.2-1.1 2.1v5.2c0 0.8 0.3 1.4 0.9 1.9s1.4 0.7 2.6 0.7h3.8c1 0 1.8-0.2 2.3-0.5 0.7-0.5 1.1-1.1 1.1-2v-5.2C231.8 34.9 231.4 34.2 230.7 33.7zM229.6 40.6c0 0.4-0.2 0.8-0.5 1s-0.6 0.3-1.1 0.3H225c-0.5 0-0.9-0.1-1.2-0.3 -0.4-0.2-0.5-0.6-0.5-1v-4.2c0-0.5 0.2-0.8 0.5-1 0.3-0.2 0.7-0.3 1.3-0.3h2.6c0.6 0 1 0.1 1.3 0.3 0.3 0.2 0.5 0.6 0.5 1L229.6 40.6 229.6 40.6z" />
      <path className="st0" d="M236.1 33.3c-0.3 0-0.5 0-0.6 0v-3.6h-2.2v13.9h2.2V35h2.8C238.2 35.1 238 33.3 236.1 33.3z" />
      <path className="st0" d="M243.2 33.3c-0.2 0-0.4 0-0.6 0v-3.6h-2.2v13.9h2.2V35h2.8C245.4 35.1 245.2 33.3 243.2 33.3z" />
      <path className="st0" d="M245.7 41.1c-0.3 0-0.6 0.1-0.8 0.4 -0.2 0.3-0.3 0.6-0.3 0.9s0.1 0.6 0.3 0.9c0.2 0.2 0.5 0.4 0.9 0.4 0.3 0 0.6-0.1 0.8-0.4 0.2-0.2 0.3-0.5 0.3-0.9 0-0.3-0.1-0.6-0.3-0.9C246.3 41.2 246.1 41.1 245.7 41.1z" />
      <path className="st0" d="M251.2 35.2c0.2-0.1 0.6-0.2 1.1-0.2h5.6c0 0 0-1.7-2.5-1.7 -2.5 0-3.7 0-3.7 0 -0.9 0-1.6 0.1-2.1 0.4 -0.7 0.5-1.1 1.1-1.1 1.8v1.3c0 0.7 0.3 1.2 1 1.7 0.6 0.4 1.3 0.6 2.2 0.6h2.6c0.9 0 1.4 0.3 1.4 1 0 0.5-0.1 0.9-0.2 1.1 -0.2 0.3-0.6 0.4-1.2 0.4 0 0-1.8 0-3.7 0 -1.9 0-2 1.7-2 1.7h6.3c0.5 0 1 0 1.2-0.1 0.3-0.1 0.7-0.3 1-0.6 0.5-0.5 0.8-1.1 0.8-1.7v-1.2c0-0.7-0.3-1.2-0.8-1.7 -0.5-0.4-1.2-0.7-2-0.7h-2.8c-1.1 0-1.7-0.3-1.7-1v-0.4C250.7 35.7 250.8 35.4 251.2 35.2z" />
      <path className="st0" d="M266.4 33.3h-3.7c-2.2 0-3.4 0.8-3.4 2.5v4.7c0 1 0.3 1.7 1 2.2 0.5 0.4 1.4 0.6 2.5 0.6h6.6c0 0-0.1-1.7-2.4-1.7 -2.3 0-3.9 0-3.9 0 -0.6 0-1-0.1-1.3-0.2 -0.4-0.2-0.5-0.6-0.5-1.1v-0.3l8.1-2.7V36C269.5 34.2 268.5 33.3 266.4 33.3zM267.3 36.4l-5.9 2v-2c0-0.9 0.6-1.3 1.9-1.3h2.3c1.1 0 1.7 0.4 1.7 1.1V36.4z" />
      <path className="st0" d="M29.3 47.3c10 2.1 22.1 3 35 2.6C99.2 49 127.2 38.6 126.9 26c0-0.2 0-0.4 0-0.6 0-0.2 0-0.4-0.1-0.6 -0.5-3.2-3.2-6.2-6.7-8.8 -2.9-2-6.7-3.7-11.3-5.3 -7.4-2.5-22-4.2-34.4-2.1 -10 1.7-21.7 7.1-1.5 12.4C117.9 32.6 77.5 47.9 29.3 47.3z" />
      <path className="st1" d="M102.2 3.1C96 1 86-0.2 70.8 0.1 31.2 0.9-0.3 12.6 0 24.3c0.1 3.3 2.9 5.8 5.8 7.9 2.7 1.8 6.4 3.3 10.8 4.7 6.7 1.8 18.3 3 27.7 1.6 7.3-1.1 16.2-4.1 15.8-7.8 -0.2-1.8-2.5-3.7-8.3-5.6C13.9 12.4 60.5-1.1 102.2 3.1z" />
    </svg>
  ),
}
