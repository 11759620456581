/* eslint-disable max-len */
const programName = 'JD Sports'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 7.9851253 7.9854824">
      <title>{programName}</title>
      <path d="m97.372135 168.28701c.207081-1.20509-1.141942-1.02799-1.141942-1.02799l-.337608 2.0514s1.272117.19896 1.47955-1.02341m.899936.0162c-.154869.93627-.906992 1.86796-2.109258 1.86796h-1.274939l.618772-3.74827h1.273881c1.198739 0 1.645002.93663 1.491544 1.88031m-2.938992-1.87219c-.628649 4.23157-2.700866 3.84386-2.700866 3.84386l.117122-.89535c1.444272.0176 1.689806-2.94851 1.689806-2.94851zm3.909484 1.92475c0-2.13183-1.728611-3.86045-3.860447-3.86045-2.132189 0-3.860095 1.72862-3.860095 3.86045 0 2.13184 1.727906 3.86045 3.860095 3.86045 2.131836 0 3.860447-1.72861 3.860447-3.86045" transform="translate(-91.389729 -164.36303)" />
    </svg>
  ),
}
