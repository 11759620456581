/* eslint-disable max-len */
const programName = 'Reima'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 660 150">
      <title>{programName}</title>
      <path className="cls-1" d="M84.08,49.9a48.18,48.18,0,0,0-14.2-2.22c-26,0-29.46,25.34-29.46,47.09v50.64H0V4.89H40.43v19.9H41c7-8.49,20-20,33.54-20.34a54.75,54.75,0,0,1,18.77,3Z" transform="translate(0)" />
      <path className="cls-1" d="M136.95,81.59c0,17.75,6.93,37.78,28,37.78,13.28,0,21.66-8,27.15-19.18l37.25,6C219.54,134.26,194.7,150,165,150c-43.89,0-70.75-30.91-70.75-73.56S117,.31,162.94.31c46.49,0,67.28,34.06,67.28,76.42v4.86Zm55.16-24.9c-2-14.31-11.26-28.33-26.86-28.33-16.17,0-25.12,13.74-27.15,28.33Z" transform="translate(0)" />
      <rect className="cls-1" x="244.67" y="3.97" width="40.43" height="140.53" />
      <path className="cls-1" d="M345,17.48h.58C353.33,6.32,367.2.31,380.19.31c17.62,0,29.89,6.27,40.11,21.08C429.45,8.61,445.74.31,462.2.31c36.1,0,45,26.9,45,57.53v87.58H466.82V71.58c0-13.74.86-39.21-19.06-39.21-21.95,0-21.66,29.48-21.66,44.08v69H385.68V74.15c0-13.74,1.73-41.79-19.35-41.79C343.81,32.37,345,61.56,345,76.44v69H304.53V4.89H345Z" transform="translate(0)" />
      <path className="cls-1" d="M660,145.11H620.15V132.23h-.58a44.3,44.3,0,0,1-35.93,17.46c-41.59,0-65.27-37.5-65.27-75.56,0-37.21,24-74.13,64.68-74.13,14.73,0,27.27,6.3,37.09,17.75V4.58H660Zm-37-70.41c0-18.89-11.75-39.21-32.55-39.21S558.14,55.81,558.14,74.7s11.47,39.5,32.26,39.5,32.55-20.61,32.55-39.5" transform="translate(0)" />
    </svg>
  ),
}
