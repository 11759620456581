/* eslint-disable max-len */
const programName = 'Elon'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 485 129">
      <title>{programName}</title>
      <path d="M321.39 65.01c0 18.13-12.9 30.86-30.68 30.86-16.91 0-30.16-12.38-30.16-30.86 0-17.78 13.25-30.51 30.16-30.51s30.68 12.2 30.68 30.51M290.71 1.37c-35.56 0-63.28 27.72-63.28 63.63s28.07 63.98 63.28 63.98c36.09 0 63.8-28.41 63.8-63.98 0-35.38-27.02-63.63-63.8-63.63m136.68 0c-36.09 0-57.18 24.76-57.18 60.67v61.36c0 1.57 1.22 2.79 2.79 2.79h27.54c1.57 0 2.79-1.22 2.79-2.79V61.34c0-16.91 7.15-26.85 24.06-26.85 16.74 0 24.06 9.94 24.06 28.07v60.84c0 1.57 1.22 2.79 2.79 2.79h27.54c1.57 0 2.79-1.22 2.79-2.79V62.04c0-35.56-21.1-60.67-57.18-60.67M172.34 4.16H144.8c-1.57 0-2.79 1.22-2.79 2.79V123.4c0 1.57 1.22 2.79 2.79 2.79h73.57c1.57 0 2.79-1.22 2.79-2.79V97.6c0-1.57-1.22-2.79-2.79-2.79h-43.23V6.95c-.01-1.57-1.23-2.79-2.8-2.79M34.62 56.46c1.05-12.72 13.6-24.58 29.64-24.58 11.68 0 21.97 5.93 24.58 14.99l-54.22 9.59zm3.48 25.28l82.63-14.64a2.641 2.641 0 002.27-2.62C121.95 27.7 97.37 1.37 64.77 1.37 28.16 1.37.44 28.74.44 64.65c0 36.43 27.89 64.33 65.2 64.33 20.05 0 39.75-8.02 55.44-23.71 1.05-1.22 1.05-2.96 0-4.01L104 84.18c-1.05-1.05-2.61-1.22-3.83-.17-8.02 6.8-20.4 12.9-33.3 12.9-13.6 0-23.19-5.23-28.77-15.17" />
    </svg>
  ),
}
