/* eslint-disable max-len */
const programName = 'Mini Rodini'
export default {
  programName,
  logo: (
    <svg viewBox="0 0 172 19">
      <title>{programName}</title>
      <defs>
        <polygon id="path-1" points="10.207 18.638 10.207 0.218 5.47305 0.218 0.7391 0.218 0.7391 18.638 10.207 18.638" />
      </defs>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Assets" transform="translate(-339.000000, -196.000000)">
          <g id="Page-1" transform="translate(339.000000, 195.000000)">
            <g id="logo" transform="translate(0.000000, 1.000000)" fill="#000000">
              <path d="M0,17.8265 L1.213,17.8265 L1.213,6.2445 L0,6.2445 L0,5.6505 L7.972,5.6505 L7.972,7.5075 C9.21,6.0375 10.81,5.2885 12.461,5.2885 C14.524,5.2885 15.814,5.9335 17.026,7.3785 C18.24,6.0125 19.735,5.2885 21.464,5.2885 C23.838,5.2885 26.263,6.8115 26.263,9.4165 L26.263,17.8265 L27.553,17.8265 L27.553,18.4205 L18.678,18.4205 L18.678,17.8265 L19.4,17.8265 L19.4,7.7135 C19.4,7.2745 19.117,7.0435 18.678,7.0435 C18.187,7.0435 17.621,7.5585 17.182,8.3845 L17.182,17.8265 L17.956,17.8265 L17.956,18.4205 L9.469,18.4205 L9.469,17.8265 L10.319,17.8265 L10.319,7.7135 C10.319,7.1465 10.036,7.0435 9.675,7.0435 C9.159,7.0435 8.435,7.7135 8.1,8.3845 L8.1,17.8265 L8.926,17.8265 L8.926,18.4205 L0,18.4205 L0,17.8265 Z" id="Fill-1" />
              <path d="M29.8457,6.244 L28.7367,6.244 L28.7367,5.65 L36.7337,5.65 L36.7337,17.827 L38.1017,17.827 L38.1017,18.42 L28.6337,18.42 L28.6337,17.827 L29.8457,17.827 L29.8457,6.244 Z M29.9747,2.09 C29.9747,0.645 31.8067,0 33.2507,0 C34.6957,0 36.5537,0.645 36.5537,2.09 C36.5537,3.535 34.6957,4.206 33.2507,4.206 C31.8067,4.206 29.9747,3.535 29.9747,2.09 L29.9747,2.09 Z" id="Fill-3" />
              <path d="M39.2353,17.8265 L40.4483,17.8265 L40.4483,6.2445 L39.2353,6.2445 L39.2353,5.6505 L47.2073,5.6505 L47.2073,7.6625 C48.3683,6.2175 49.8893,5.2885 51.6183,5.2885 C54.3003,5.2885 56.4173,6.6565 56.4173,9.1325 L56.4173,17.8265 L57.6803,17.8265 L57.6803,18.4205 L48.7043,18.4205 L48.7043,17.8265 L49.5543,17.8265 L49.5543,7.8175 C49.5543,7.3015 49.3473,7.1205 48.9353,7.1205 C48.4703,7.1205 47.9043,7.6625 47.3363,8.3845 L47.3363,17.8265 L48.1613,17.8265 L48.1613,18.4205 L39.2353,18.4205 L39.2353,17.8265 Z" id="Fill-5" />
              <path d="M60.0009,6.244 L58.8929,6.244 L58.8929,5.65 L66.8899,5.65 L66.8899,17.827 L68.2569,17.827 L68.2569,18.42 L58.7889,18.42 L58.7889,17.827 L60.0009,17.827 L60.0009,6.244 Z M60.1299,2.09 C60.1299,0.645 61.9619,0 63.4059,0 C64.8519,0 66.7089,0.645 66.7089,2.09 C66.7089,3.535 64.8519,4.206 63.4059,4.206 C61.9619,4.206 60.1299,3.535 60.1299,2.09 L60.1299,2.09 Z" id="Fill-7" />
              <path d="M77.8252,17.8265 L79.0392,17.8265 L79.0392,6.2445 L77.8252,6.2445 L77.8252,5.6505 L85.7982,5.6505 L85.7982,7.4565 C86.7002,6.0125 87.9122,5.1605 89.9512,5.1605 C91.4732,5.1605 93.2542,6.1915 93.2542,8.3585 C93.2542,9.9845 91.9642,11.1705 90.3392,11.1705 C88.6622,11.1705 87.4232,10.0095 87.4232,8.3845 C87.4232,7.2235 88.1712,6.2445 89.3052,5.8065 C87.4482,5.8065 85.9272,7.7915 85.9272,9.4945 L85.9272,17.8265 L87.6032,17.8265 L87.6032,18.4205 L77.8252,18.4205 L77.8252,17.8265 Z" id="Fill-9" />
              <path d="M94.0517,12.0739 C94.0517,7.2489 98.7207,5.2889 102.7967,5.2889 C106.6667,5.2889 110.9237,7.6629 110.9237,11.9959 C110.9237,16.5879 106.4877,18.7819 102.4617,18.7819 C98.6177,18.7819 94.0517,16.4079 94.0517,12.0739 M103.6997,16.2789 L103.6997,7.7399 C103.6997,6.2949 103.0807,5.8819 102.4877,5.8819 C101.8947,5.8819 101.2767,6.2949 101.2767,7.7399 L101.2767,16.2789 C101.2767,17.7239 101.8947,18.1879 102.4877,18.1879 C103.0807,18.1879 103.6997,17.7239 103.6997,16.2789" id="Fill-11" />
              <path d="M122.4023,16.847 C121.3703,18.111 120.4423,18.782 118.5853,18.782 C115.2043,18.782 112.6763,15.582 112.6763,11.971 C112.6763,8.875 114.6113,5.289 118.8153,5.289 C120.1333,5.289 121.2153,5.65 122.2733,6.45 L122.2733,0.955 L120.7763,0.955 L120.7763,0.362 L129.1363,0.362 L129.1363,17.827 L130.3733,17.827 L130.3733,18.42 L122.4023,18.42 L122.4023,16.847 Z M122.2733,7.43 C121.8863,6.966 121.4233,6.579 120.9053,6.579 C120.3123,6.579 119.9003,6.863 119.9003,7.843 L119.9003,15.918 C119.9003,16.769 120.3393,17.13 120.8023,17.13 C121.3703,17.13 122.0933,16.331 122.2733,15.634 L122.2733,7.43 Z" id="Fill-13" />
              <path d="M132.7968,6.244 L131.6868,6.244 L131.6868,5.65 L139.6858,5.65 L139.6858,17.827 L141.0518,17.827 L141.0518,18.42 L131.5838,18.42 L131.5838,17.827 L132.7968,17.827 L132.7968,6.244 Z M132.9258,2.09 C132.9258,0.645 134.7568,0 136.2028,0 C137.6468,0 139.5048,0.645 139.5048,2.09 C139.5048,3.535 137.6468,4.206 136.2028,4.206 C134.7568,4.206 132.9258,3.535 132.9258,2.09 L132.9258,2.09 Z" id="Fill-15" />
              <path d="M162.9521,6.244 L161.8431,6.244 L161.8431,5.65 L169.8401,5.65 L169.8401,17.827 L171.2071,17.827 L171.2071,18.42 L161.7391,18.42 L161.7391,17.827 L162.9521,17.827 L162.9521,6.244 Z M163.0811,2.09 C163.0811,0.645 164.9121,0 166.3581,0 C167.8031,0 169.6601,0.645 169.6601,2.09 C169.6601,3.535 167.8031,4.206 166.3581,4.206 C164.9121,4.206 163.0811,3.535 163.0811,2.09 L163.0811,2.09 Z" id="Fill-19" />
              <path d="M142.1865,17.8265 L143.3985,17.8265 L143.3985,6.2445 L142.1865,6.2445 L142.1865,5.6505 L150.1575,5.6505 L150.1575,7.6625 C151.3185,6.2175 152.8405,5.2885 154.5695,5.2885 C157.2525,5.2885 159.3685,6.6565 159.3685,9.1325 L159.3685,17.8265 L160.6325,17.8265 L160.6325,18.4205 L151.6545,18.4205 L151.6545,17.8265 L152.5055,17.8265 L152.5055,7.8175 C152.5055,7.3015 152.2985,7.1205 151.8865,7.1205 C151.4225,7.1205 150.8545,7.6625 150.2865,8.3845 L150.2865,17.8265 L151.1125,17.8265 L151.1125,18.4205 L142.1865,18.4205 L142.1865,17.8265 Z" id="Fill-17" />
            </g>
            <g id="Group-21" transform="translate(161.000000, 0.782000)">
              <mask id="mask-2" fill="white">
                <use href="#path-1" />
              </mask>
              <g id="Clip-20" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
}
